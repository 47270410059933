import React from 'react';
import { FileContentPreviewer } from 'libs/xtra-custom-booth-design/components/shared/XtraDropZoneDialog/FilePreviewer/fileContentPreviewers/fileContentPreviewer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { Link } from '@material-ui/core';
import {
  ContentPreviewerContainer, FileInfoContainer, FileNameContainer, PreviewArea,
} from 'libs/xtra-custom-booth-design/components/shared/XtraDropZoneDialog/FilePreviewer/style';
import { FormattedMessage } from 'react-intl';

const NoPreviewFileContentPreviewer: FileContentPreviewer = ({ filePreview, direction }) => (
  <ContentPreviewerContainer direction={direction}>
    <PreviewArea>
      <FontAwesomeIcon icon={faFile} size="5x" />
    </PreviewArea>
    <FileInfoContainer>
      <table>
        <tr>
          <th>
            <FormattedMessage id="common.file_name" defaultMessage="File Name" />
            :
          </th>
          <td>
            <Link color="primary" target="_blank" href={filePreview.fileUrl}>
              <FileNameContainer>
                {filePreview?.filename}
              </FileNameContainer>
            </Link>
          </td>
        </tr>
      </table>
    </FileInfoContainer>
  </ContentPreviewerContainer>
);

export default NoPreviewFileContentPreviewer;
