// eslint-disable-next-line import/prefer-default-export
export enum BoothMasterElementType {
  BoothDividerMasterElement = 'BoothDividerMasterElement',
  BoothFileMasterElement = 'BoothFileMasterElement',
  BoothImageMasterElement = 'BoothImageMasterElement',
  BoothRichtextMasterElement = 'BoothRichtextMasterElement',
  BoothQuestionMasterElement = 'BoothQuestionMasterElement',
  BoothVideoMasterElement = 'BoothVideoMasterElement',

  BoothRootMasterElement = 'BoothRootMasterElement',
  BoothTabMasterElement = 'BoothTabMasterElement',
}
