import React, { FC, useContext, useMemo } from 'react';
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@material-ui/core';
import ISO6391 from 'iso-639-1';
import { CMSUILocalizationContext } from 'components/localization/CMSUILocalizationContext';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import cmsSelectors from 'redux/cmsSelectors';

const SystemLocaleField: FC = () => {
  const { locale, setLocale } = useContext(CMSUILocalizationContext);

  const selectLocale = (newLocale) => {
    setLocale(newLocale);
  };

  const localeFormatter = (localeCode: string) => `${ISO6391.getName(localeCode.split('_')[0].split('-')[0])}(${localeCode})`;

  const rawAvailableLocales = useSelector(cmsSelectors.selectAvailableUILocales);
  const availableLocales = useMemo(() => {
    if (rawAvailableLocales.includes('en')) return rawAvailableLocales;
    return ['en', ...rawAvailableLocales];
  }, [rawAvailableLocales]);

  return (
    <FormControl fullWidth>
      <InputLabel>
        <FormattedMessage
          id="settings.system_language"
          defaultMessage="System Language"
        />
      </InputLabel>
      <Select
        value={locale}
        onChange={(e) => selectLocale(e.target.value)}
      >
        {availableLocales.map((availableLocale) => (
          <MenuItem key={availableLocale} value={availableLocale}>{localeFormatter(availableLocale)}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SystemLocaleField;
