import createInternalAddBatchedDebouncedUpdateRequestEpic
  from 'utils/redux-update-model/createInternalAddBatchedDebouncedUpdateRequestEpic';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';

const internalAddBatchedDebouncedUpdateEventRequestEpic = createInternalAddBatchedDebouncedUpdateRequestEpic(
  eventEditActions.internalAddBatchedDebouncedUpdateEventRequest,
  eventEditActions.executingTasks.wrapWithExecutingTaskAction(() => createExecutingTask({
    name: 'Update event',
    tags: [ExecutingTaskCommonTag.Mutation],
  })),
);

export default internalAddBatchedDebouncedUpdateEventRequestEpic;
