import React, { useContext } from 'react';
import { DesignEditorDockerComponent } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/designEditorDockerEntry';
import { ContentLocalizationContext } from 'components/localization/ContentLocalizationContext';
import ContentLocaleSelector from 'components/localization/ContentLocaleSelector';
import { DockerCommonInnerContent } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/style';

const DesignEditorLocaleDocker: DesignEditorDockerComponent = (props) => {
  const { locale, setLocale } = useContext(ContentLocalizationContext);

  return (
    <DockerCommonInnerContent>
      <ContentLocaleSelector />
    </DockerCommonInnerContent>
  );
};

export default DesignEditorLocaleDocker;
