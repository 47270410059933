import React, { FC, RefObject, useContext } from 'react';
import { useCanvasStyle } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/DesignEditorInstanceCanvas/positioning/canvasPositioningHooks';
import DesignElementPresenterSizingContainer from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/DesignEditorInstanceCanvas/DesignElementPresenter/DesignElementPresenterSizingContainer';
import { useSelector } from 'react-redux';
import DesignEditorInstanceContext from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';

export interface DesignEditorPositionedCanvasProps {
  style?: any;
  className: string;
  canvasRef?: RefObject<HTMLElement>;
}

const DesignEditorPositionedCanvas: FC<any> = ({
  children, style = {}, className, canvasRef,
}) => {
  const { selectors } = useContext(DesignEditorInstanceContext);
  const canvasStyle = { ...useCanvasStyle(), ...style };
  const aspectRatio = useSelector(selectors.selectCanvasAspectRatio).dimension;
  return (
    <div style={canvasStyle} className={className} ref={canvasRef}>
      <DesignElementPresenterSizingContainer aspectRatio={aspectRatio}>
        {children}
      </DesignElementPresenterSizingContainer>
    </div>
  );
};

export default React.memo(DesignEditorPositionedCanvas);
