import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, tap,
} from 'rxjs/operators';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import cmsSelectors from 'redux/cmsSelectors';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import produce from 'immer';
import { of } from 'rxjs';
import { BoothMasterElementType } from 'admin-data/event/EventEdit/typenameEnums';
import { BoothMasterElement, BoothQuestionMasterElement } from 'models';
import { localizableFieldDefaultLocalePatcher } from 'admin-data/booth/BoothEdit/redux/epics/queries/utils';

const addTabBodyMasterElementEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.mutations.addTabBodyMasterElement.match),
  mergeMap(({
    payload: {
      orderingPosition, typename, parentElementId, tabId,
    },
  }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const locale = eventEditSelectors.selectEditLocale(state$.value);
    const eventId = eventEditSelectors.selectEventId(state$.value);
    const originalEventFactory = () => eventEditSelectors.selectEvent(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.mutations.addTabBodyMasterElement(),
        ExecutingTaskCommonTag.Mutation],
    });

    const requestFactories = {
      [BoothMasterElementType.BoothRichtextMasterElement]: sdk.boothRichtextMasterElementCreate,
      [BoothMasterElementType.BoothVideoMasterElement]: sdk.boothVideoMasterElementCreate,
      [BoothMasterElementType.BoothQuestionMasterElement]: sdk.boothQuestionMasterElementCreate,
      [BoothMasterElementType.BoothImageMasterElement]: sdk.boothImageMasterElementCreate,
      [BoothMasterElementType.BoothFileMasterElement]: sdk.boothFileMasterElementCreate,
      [BoothMasterElementType.BoothDividerMasterElement]: sdk.boothDividerMasterElementCreate,
    };
    const createRequest = () => {
      switch (typename) {
        case BoothMasterElementType.BoothQuestionMasterElement:
          return requestFactories[BoothMasterElementType.BoothQuestionMasterElement]({
            input: {
              orderingPosition,
              parentElementId: parentElementId ?? tabId,
              eventId,
              masterControlledAnswer: true,
              masterControlledQuestion: true,
            },
            locales: [locale],
          });
        default:
          return requestFactories[typename]({
            input: { orderingPosition, parentElementId: parentElementId ?? tabId, eventId },
            locales: [locale],
          });
      }
    };

    const requests = createRequest().pipe(
      throwCommonError(),
      tap((resp) => localizableFieldDefaultLocalePatcher([locale], resp)),
      map((resp) => eventEditActions.internalUpdateEvent(produce(originalEventFactory(), ((draft) => {
        const createdElement = Object.values(resp)[0].element as BoothMasterElement;
        const tab = draft.rootMasterElement.tabs.nodes.find((it) => it.id === tabId);

        if (parentElementId == null || parentElementId === tabId) {
          tab.body.nodes.splice(orderingPosition, 0, createdElement);
        } else {
          const parentElement = tab.body.nodes.find((it) => it.id === parentElementId);

          switch (parentElement.__typename) {
            case BoothMasterElementType.BoothQuestionMasterElement:
              (parentElement as BoothQuestionMasterElement).answer = createdElement;
              break;
            default:
              throw new Error(`No handler for adding child into parent element ${parentElement.__typename}`);
          }
        }
      })))),
      catchErrorToPopup(state$.value.intl),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return of(eventEditActions.internalAddSequencedRequest(requests));
  }),
);

export default addTabBodyMasterElementEpic;
