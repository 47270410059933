import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, takeUntil,
} from 'rxjs/operators';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import { concat, defer, of } from 'rxjs';
import eventSettingEditSelectors from 'admin-data/event/EventEdit/redux/eventSettingEditSelectors';
import { Products } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { EVENT_FIRESTORE_API } from 'config';

const fetchEventsProductsEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.fetchProducts.match),
  mergeMap(() => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const getEventUrl = `${EVENT_FIRESTORE_API}?event_domain=${domain}`;
    const fetchProducts = defer(() => fetch(getEventUrl));
    return concat(
      of(eventSettingEditActions.internalUpdateLoadingProductSetting(true)),
      fetchProducts.pipe(
        mergeMap((resp) => resp.json() as Promise<{ products: Products }>),
        map(({ products }) => {
          const currentProducts = eventSettingEditSelectors.selectEventProducts(state$.value);
          return eventSettingEditActions.internalUpdateProductSetting({ ...currentProducts, ...products });
        }),
      ),
      of(eventSettingEditActions.internalUpdateLoadingProductSetting(false)),
    ).pipe(
      takeUntil(action$.pipe(filter(eventSettingEditActions.fetchProducts.match))),
    );
  }),
);

export default fetchEventsProductsEpic;
