import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { concat, defer, of } from 'rxjs';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import { LobbyTemplate } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import { FIRESTORE_ENDPOINT } from 'config';

const fetchLobbyTemplateEpic: Epic = (action$) => action$.pipe(
  filter(eventSettingEditActions.fetchLobbyTemplate.match),
  mergeMap(() => {
    const fetchUrl = `${FIRESTORE_ENDPOINT}/lobbyTemplate`;
    const fetchLobbyTemplate = defer(() => fetch(fetchUrl))
      .pipe(
        mergeMap((resp) => resp.json() as Promise<LobbyTemplate[]>),
        mergeMap((templates) => of(eventSettingEditActions.setLobbyTemplates(templates))),
      );
    return concat(
      of(eventSettingEditActions.areLobbyTemplatesLoading(true)),
      fetchLobbyTemplate,
      of(eventSettingEditActions.areLobbyTemplatesLoading(false)),
    );
  }),
);

export default fetchLobbyTemplateEpic;
