import { popupsInitialState, PopupsState } from 'utils/xtra-popups/popupsState';
import { hintsInitialStateFactory, HintsState } from 'utils/xtra-hints/hintsState';

export interface CMSAuthState {
  role: AuthRole;
}

export interface CMSState {
  /**
   * e.g. boothid, eventid, to index the auth token
   */
  sdkAccount: string;
  popups: PopupsState;
  translations: { [locale: string]: any }
  auth: CMSAuthState;
  settingDialogOpen: boolean;
  hints: HintsState;
}

export enum AuthRole {
  Admin,
  Other
}

export const cmsInitialState: CMSState = {
  sdkAccount: null,
  popups: popupsInitialState,
  translations: null,
  settingDialogOpen: false,
  hints: hintsInitialStateFactory('cmsHints'),
  auth: {
    role: AuthRole.Other,
  },
};
