import axios from 'axios';
import { CMS_PRODUCT_API_ENDPOINT } from 'config';
import { defer, of } from 'rxjs';
import { EVENT_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { eventEditMutationCompleteObservable } from 'admin-data/event/EventEdit/redux/epic/utils';
import { createStandardEventEditRequestBundle } from 'admin-data/event/EventEdit/redux/createStandardEventEditRequestBundle';
import { map } from 'rxjs/operators';
import { ProductCategory } from 'models-product';

const bundleEventProductCategoryGetList = createStandardEventEditRequestBundle<void, ProductCategory[]>({
  actionName: `${EVENT_EDIT_ACTIONS_NAME}/product/category/getList`,
  preConditionObservableFactory: eventEditMutationCompleteObservable,

  requestFactory: ({
    eventDomain, locale, authToken,
  }) => defer(() => axios.get(`${CMS_PRODUCT_API_ENDPOINT}/categories?domain=${eventDomain}&locale=${locale}`, {})).pipe(map((resp) => resp.data)),

  requestResponseMapper: ({
    response, createUpdateEventEditAction,
  }) => of(createUpdateEventEditAction((eventEditState) => {
    if (eventEditState.event.productSettings == null) eventEditState.event.productSettings = {} as any;
    eventEditState.event.productSettings.categories = response ?? [];
  })),
});

export default bundleEventProductCategoryGetList;
