import { Epic } from 'redux-observable';
import { filter, ignoreElements, mergeMap } from 'rxjs/operators';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import { concat, of } from 'rxjs';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { boothEditExecutingTasksTagCreator } from 'admin-data/booth/BoothEdit/redux/boothEditExecutingTasks';
import produce from 'immer';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import cmsSelectors from 'redux/cmsSelectors';

const deleteContentTabBodyElementEpic: Epic = (action$, state$) => action$.pipe(
  filter(boothEditActions.mutations.deleteContentTabBodyElement.match),
  mergeMap(({ payload: elementId }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const boothId = boothEditSelectors.selectBoothId(state$.value);
    const originalBoothFactory = () => boothEditSelectors.selectBooth(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [
        boothEditExecutingTasksTagCreator.mutations.deleteContentTabBodyElement(),
        ExecutingTaskCommonTag.Mutation],
    });

    const request = sdk.boothContentElementDelete({ input: { boothId, elementId } }).pipe(
      throwCommonError(),
      ignoreElements(),
      catchErrorToPopup(state$.value.intl),
      boothEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return concat(
      of(boothEditActions.internalUpsertBooth(produce(originalBoothFactory(), (draft) => {
        draft.rootContentElement.tabs.nodes
          .filter((tab) => tab.body != null)
          .forEach((tab) => {
            const index = tab.body.nodes.findIndex((el) => el.id === elementId);
            tab.body.nodes.splice(index, 1);
          });
      }))),
      request,
    );
  }),
);

export default deleteContentTabBodyElementEpic;
