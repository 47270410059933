import { Epic } from 'redux-observable';
import {
  concatMap, filter, first, map, mergeMap, takeUntil, toArray,
} from 'rxjs/operators';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import {
  concat, from, Observable, of,
} from 'rxjs';
import cmsSelectors from 'redux/cmsSelectors';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import { v4 as uuidv4 } from 'uuid';
import {
  BULK_TASKS_PROGRESS_DIALOG_POPUP_KEY, BulkTaskProgress, BulkTasksProgressDialogData, BulkTaskState,
} from 'utils/xtra-popups/popupsTypes/bulkTasksProgressDialogData';
import { ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import cmsActions from 'redux/cmsActions';
import produce from 'immer';
import { Booth, BoothTier, Event } from 'models';
import { fetchAllPage } from 'utils/requestsUtils';
import {
  flow, keyBy, mapValues, range,
} from 'lodash';
import { Popup } from 'utils/xtra-popups/popup';
import { WritableDraft } from 'immer/dist/types/types-external';

const pageSize = 50;

const convertToCSV = (rows: string[][]) => {
  const content = rows.map((row) => row.map((it) => it?.replaceAll(',', ' ')).join(',')).join('\n');
  return `data:text/csv;charset=utf-8,${content}`;
};

const downloadBoothsAsCSV = (locale: string, booths: Booth[]) => {
  const header = ['id', `name_${locale}`, `tier_name_${locale}`, 'cmsUrl'];
  const rows = booths.map((booth) => [booth.id, booth.name.values[0].value, booth.tier.name.values[0].value, booth.cmsUrl]);

  const csvDataUrl = encodeURI(convertToCSV([header, ...rows]));

  const downloadEl = document.createElement('a');
  downloadEl.target = '_blank';
  downloadEl.href = csvDataUrl;
  downloadEl.download = 'booths.csv';
  downloadEl.click();
};

const createFetchTierTaskProgress = () => ({
  id: uuidv4(),
  name: 'Fetch booth tiers list',
  progress: -1,
  tags: [ExecutingTaskCommonTag.Mutation],
  state: BulkTaskState.InProgress,
  error: [],
} as BulkTaskProgress);

const createFetchTierBoothsListPageTaskProgress = (tier: BoothTier, page: number) => ({
  id: uuidv4(),
  name: `Fetch ${tier.name.values[0].value} booths list page ${page}`,
  progress: -1,
  tags: [ExecutingTaskCommonTag.Mutation],
  state: BulkTaskState.Waiting,
  error: [],
} as BulkTaskProgress);

const bulkActionDialogInitialStateFactory = () => {
  const id = uuidv4();
  return ({
    id,
    type: BULK_TASKS_PROGRESS_DIALOG_POPUP_KEY,
    data: {
      title: 'Export all booths as CSV',
      tasksProgress: [createFetchTierTaskProgress()],
      progress: -1,
      cancellable: true,
      finished: false,
      cancelAction: cmsActions.popups.createPopups.confirmationDialog(
        'Confirm cancel action', 'Are you sure to cancel the action?',
        cmsActions.popups.bulkTasks.cancel(id),
      ),
    },
  } as Popup<BulkTasksProgressDialogData>);
};

const exportAllBoothsEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.fragments.booths.exportAllBooths.match),
  mergeMap(() => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const eventId = eventEditSelectors.selectEventId(state$.value);
    const locales = [eventEditSelectors.selectEditLocale(state$.value)];

    let lastDialog = bulkActionDialogInitialStateFactory();

    const cancelAction$ = action$.pipe(
      filter(cmsActions.popups.bulkTasks.cancel.match),
      filter(({ payload: dialogId }) => dialogId === lastDialog.id),
      first(),
    );

    const fetchAllBoothTiersList = fetchAllPage(
      (after) => sdk.eventEditGetBoothTiersList({ eventId, locales, after }),
      (resp) => (resp.node as Event).boothTiers.pageInfo,
    ).pipe(map((resp) => (resp.node as Event).boothTiers.nodes));

    const createUpdateDialogStatusAction = (recipe: (draft: WritableDraft<typeof lastDialog>) => void) => {
      lastDialog = produce(lastDialog, recipe);
      return cmsActions.popups.upsertPopup(lastDialog);
    };

    return concat(
      of(cmsActions.popups.upsertPopup(lastDialog)),

      fetchAllBoothTiersList.pipe(
        mergeMap((tiers) => {
          const tierIdPagesTasksListMap: Record<string, BulkTaskProgress[]> = flow(
            (it: BoothTier[]) => it.map((tier) => ({
              tier,
              pageTasks: range(0, Math.max(1, Math.ceil(tier.booths.totalCount / pageSize))).map((index) => createFetchTierBoothsListPageTaskProgress(tier, index + 1)),
            })),
            (it) => keyBy(it, ({ tier }) => tier.id),
            (it) => mapValues(it, ({ pageTasks }) => pageTasks),
          )(tiers);

          const pagesTasksArr = Object.values(tierIdPagesTasksListMap).flatMap((it) => it);

          const taskIdIndexMap = flow(
            (it: typeof pagesTasksArr) => it.map((task, index) => ({ task, index })),
            (it) => keyBy(it, ({ task }) => task.id),
            (it) => mapValues(it, ({ index }) => index + 1),
          )(pagesTasksArr);

          return concat(
            of(createUpdateDialogStatusAction((draft) => {
              draft.data.progress = 0;
              draft.data.tasksProgress[0].state = BulkTaskState.Done;
              draft.data.tasksProgress.push(...pagesTasksArr);
            })),
            new Observable((subscriber) => {
              from(tiers).pipe(
                concatMap((tier) => {
                  let page = 0;
                  const getCurrentTaskIndex = () => taskIdIndexMap[tierIdPagesTasksListMap[tier.id]?.[page - 1]?.id];
                  return fetchAllPage(
                    (after) => {
                      page += 1;
                      subscriber.next(createUpdateDialogStatusAction((dialog) => {
                        const tasksProgress = dialog.data.tasksProgress[getCurrentTaskIndex()];
                        if (tasksProgress != null) {
                          tasksProgress.state = BulkTaskState.InProgress;
                        }
                      }));
                      return sdk.eventEditGetBoothsExportListByTierId({
                        tierId: tier.id, locales, after, pageSize,
                      });
                    },
                    (resp) => {
                      subscriber.next(createUpdateDialogStatusAction((dialog) => {
                        const tasksProgress = dialog.data.tasksProgress[getCurrentTaskIndex()];
                        if (tasksProgress != null) {
                          tasksProgress.state = BulkTaskState.Done;
                        }
                      }));
                      return (resp.node as BoothTier).booths.pageInfo;
                    },
                  ).pipe(
                    map((resp) => (resp.node as BoothTier).booths.nodes),
                    takeUntil(cancelAction$),
                  );
                }),
                toArray(),
                takeUntil(cancelAction$),
              ).subscribe((tiersBooths: Booth[][]) => {
                subscriber.next(createUpdateDialogStatusAction((dialog) => {
                  dialog.data.finished = true;
                }));
                subscriber.complete();
                downloadBoothsAsCSV(locales[0], (tiersBooths.flatMap((tierBooths) => tierBooths.flatMap((booths) => booths))));
              });
            }),
          );
        }),
      ),
    ).pipe(
      takeUntil(cancelAction$),
    );
  }),
);

export default exportAllBoothsEpic;
