import { filter, mergeMap } from 'rxjs/operators';
import designEditorActions from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorActions';
import { Epic } from 'redux-observable';
import { concat, of } from 'rxjs';
import { DesignEditorInstanceState } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceState';

const openDesignEpic: Epic = (action$, state$) => action$.pipe(
  filter(designEditorActions.openDesign.match),
  // do request to fetch the design design
  mergeMap(({ payload: designId }) => {
    const canvasViewConfigInitialState = { widthScale: 1200, positionX: 0, positionY: 0 };
    const instanceInitializing = of(designEditorActions.internalUpsertEditorInstance({
      isLoading: true,
      design: { id: designId },
      unsavedChanges: [],
      selectedLayerIds: [],
      canvasViewConfig: canvasViewConfigInitialState,
      toolConfig: {
        transform: {
          aspectRatioLock: false,
        },
      },
    } as DesignEditorInstanceState));

    return concat(
      instanceInitializing,
      of(designEditorActions.setActiveInstance(designId)),
    );
  }),
);

export default openDesignEpic;
