import { Epic } from 'redux-observable';
import {
  bufferTime, debounceTime, filter, groupBy, ignoreElements, map, mergeMap,
} from 'rxjs/operators';
import { concat, EMPTY, Observable } from 'rxjs';
import _ from 'lodash';
import { Action } from 'redux';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import produce from 'immer';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import eventSettingEditSelectors from 'admin-data/event/EventEdit/redux/eventSettingEditSelectors';

const internalAddBatchedDebouncedUpdateEventSettingRequestEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.internalAddBatchedDebouncedUpdateEventSettingRequestEpic.match),
  map(({ payload }) => payload),

  // Gruop them by identifier
  groupBy((update) => update.batchIdentifier, (it) => it, (it) => it.pipe(debounceTime(1000))),
  mergeMap((groupped) => {
    const taskFactory = () => createExecutingTask({ name: 'Update event setting', tags: [ExecutingTaskCommonTag.Mutation] });

    const requests = concat(
      // Buffer the requests, and then combine them all as a one request
      groupped.pipe(
        bufferTime(5000),
        mergeMap((buffered) => {
          if (buffered.length === 0) return EMPTY;

          const latestEventSettingFields = eventSettingEditSelectors.selectEventSettings(state$.value);

          // combine all request
          const allUpdatedEventSettingFields = produce(
            latestEventSettingFields,
            (draft) => {
              buffered.forEach((next) => {
                Object.entries(next.fields).forEach(([key, value]) => {
                  _.set(draft, key, value);
                });
              });
            },
          );

          const partialUpdatedEventSettingFields = {};
          buffered.forEach((next) => {
            Object.keys(next.fields).forEach((source) => {
              const arrayRegex = new RegExp(/\[[^\]]*\]/gi);
              const arrayRegexMatches = Array.from(source.matchAll(arrayRegex));
              if (arrayRegexMatches.length) {
                const firstArrayIndexObject = arrayRegexMatches.shift();
                const arrayParentSource = source.substring(0, firstArrayIndexObject.index);
                partialUpdatedEventSettingFields[arrayParentSource] = _.get(allUpdatedEventSettingFields, arrayParentSource);
              } else {
                partialUpdatedEventSettingFields[source] = _.get(allUpdatedEventSettingFields, source);
              }
            });
          });

          const request = produce(buffered[0], (draft) => {
            draft.fields = partialUpdatedEventSettingFields;
          });

          return request.requestFnProvider(null)({ input: request }).pipe(ignoreElements());
        }),
      ) as Observable<Action>,
    );

    return requests.pipe(eventSettingEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory));
  }),
);

export default internalAddBatchedDebouncedUpdateEventSettingRequestEpic;
