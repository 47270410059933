import { Epic } from 'redux-observable';
import { filter, ignoreElements, mergeMap } from 'rxjs/operators';
import eventEditBoothsActions from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/eventEditBoothsActions';
import { concat, merge, of } from 'rxjs';
import eventEditBoothsSelectors from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/eventEditBoothsSelectors';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { v4 as uuidv4 } from 'uuid';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import cmsSelectors from 'redux/cmsSelectors';

const reorderBoothEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditBoothsActions.reorderBooth.match),
  mergeMap(({ payload }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const booths = eventEditBoothsSelectors.selectBoothsList(state$.value);
    const eventId = eventEditSelectors.selectEventId(state$.value);

    const newBooths = [...booths];
    const { source, dest } = payload;

    const updatingBooth = booths[source];

    newBooths.splice(dest, 0, newBooths.splice(source, 1)[0]);
    const updateBoothsListAction = eventEditBoothsActions.internalUpdateBoothsList(newBooths);

    const taskId = uuidv4();

    const requestAction = eventEditActions.internalAddSequencedRequest(concat(
      sdk.boothReorder({
        input: {
          eventId,
          boothId: updatingBooth.id,
          rowOrderPosition: dest,
        },
      }).pipe(
        throwCommonError(),
        ignoreElements(),
        catchErrorToPopup(state$.value.intl),
      ),
      of(eventEditActions.executingTasks.removeExecutingTasks(taskId)),
    ));

    return merge(
      of(eventEditActions.executingTasks.upsertExecutingTasks({ id: taskId, name: 'Reorder booth', progress: -1 })),
      of(updateBoothsListAction),
      of(requestAction),
    );
  }),
);

export default reorderBoothEpic;
