import React, {
  FC, useCallback, useContext, useMemo,
} from 'react';
import { SizeControlPoint } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/DesignEditorInstanceCanvas/overlay-controls/DesignEditorTransformOverlay/style';
import { BoothDesignMasterElement } from 'models';
import { BoundingBox } from 'libs/xtra-custom-booth-design/utils/boundingBox';
import useMouseDrag, { MouseDragEvent } from 'libs/xtra-custom-booth-design/utils/useMouseDrag';
import { batch, useDispatch, useSelector } from 'react-redux';
import DesignEditorInstanceContext
  from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';

export type ControlPointPosition = 'n' | 'ne' | 'e' | 'se' | 's' | 'sw' | 'w' | 'nw';

export interface DesignEditorTransformSizeControlPointProps {
  position: ControlPointPosition;
  selectedLayers: BoothDesignMasterElement[];
  boundingBox: BoundingBox;
}

export const DESIGN_EDITOR_TRANSFORM_CONTROL_POINT_CONFIGS = {
  n: {
    cursor: 'ns-resize',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    offsetXMultiplier: 0,
    offsetYMultiplier: -1,
    offsetMultiplier: (offsetXRatio, offsetYRatio) => ({ xRatio: 0, yRatio: -offsetYRatio }),
    basePositionExtractor: (boundingBox: BoundingBox) => ({ x: 0, y: boundingBox.b.y }),
  },
  s: {
    cursor: 'ns-resize',
    bottom: '0',
    left: '50%',
    transform: 'translate(-50%, 50%)',
    offsetXMultiplier: 0,
    offsetYMultiplier: 1,
    offsetMultiplier: (offsetXRatio, offsetYRatio) => ({ xRatio: 0, yRatio: offsetYRatio }),
    basePositionExtractor: (boundingBox: BoundingBox) => ({ x: 0, y: boundingBox.a.y }),
  },
  e: {
    cursor: 'ew-resize',
    top: '50%',
    right: '0',
    transform: 'translate(50%, -50%)',
    offsetXMultiplier: 1,
    offsetYMultiplier: 0,
    offsetMultiplier: (offsetXRatio, offsetYRatio) => ({ xRatio: offsetXRatio, yRatio: 0 }),
    basePositionExtractor: (boundingBox: BoundingBox) => ({ x: boundingBox.a.x, y: 0 }),
  },
  w: {
    cursor: 'ew-resize',
    top: '50%',
    left: '0',
    transform: 'translate(-50%, -50%)',
    offsetXMultiplier: -1,
    offsetYMultiplier: 0,
    offsetMultiplier: (offsetXRatio, offsetYRatio) => ({ xRatio: -offsetXRatio, yRatio: 0 }),
    basePositionExtractor: (boundingBox: BoundingBox) => ({ x: boundingBox.b.x, y: 0 }),
  },
  ne: {
    cursor: 'nesw-resize',
    top: '0',
    right: '0',
    transform: 'translate(50%, -50%)',
    offsetXMultiplier: 1,
    offsetYMultiplier: -1,
    offsetMultiplier: (offsetXRatio, offsetYRatio) => ({ xRatio: offsetXRatio, yRatio: -offsetYRatio }),
    basePositionExtractor: (boundingBox: BoundingBox) => ({ x: boundingBox.a.x, y: boundingBox.b.y }),
  },
  se: {
    cursor: 'nwse-resize',
    bottom: '0',
    right: '0',
    transform: 'translate(50%, 50%)',
    offsetXMultiplier: 1,
    offsetYMultiplier: 1,
    offsetMultiplier: (offsetXRatio, offsetYRatio) => ({ xRatio: offsetXRatio, yRatio: offsetYRatio }),
    basePositionExtractor: (boundingBox: BoundingBox) => ({ x: boundingBox.a.x, y: boundingBox.a.y }),
  },
  sw: {
    cursor: 'nesw-resize',
    bottom: '0',
    left: '0',
    transform: 'translate(-50%, 50%)',
    offsetXMultiplier: -1,
    offsetYMultiplier: 1,
    offsetMultiplier: (offsetXRatio, offsetYRatio) => ({ xRatio: -offsetXRatio, yRatio: offsetYRatio }),
    basePositionExtractor: (boundingBox: BoundingBox) => ({ x: boundingBox.b.x, y: boundingBox.a.y }),
  },
  nw: {
    cursor: 'nwse-resize',
    top: '0',
    left: '0',
    transform: 'translate(-50%, -50%)',
    offsetXMultiplier: -1,
    offsetYMultiplier: -1,
    offsetMultiplier: (offsetXRatio, offsetYRatio) => ({ xRatio: -offsetXRatio, yRatio: -offsetYRatio }),
    basePositionExtractor: (boundingBox: BoundingBox) => ({ x: boundingBox.b.x, y: boundingBox.b.y }),
  },
};

interface MouseDragStateType {
  selectedLayers: BoothDesignMasterElement[],
  boundingBox: BoundingBox;
}

const DesignEditorTransformSizeControlPoint: FC<DesignEditorTransformSizeControlPointProps> = ({
  position, selectedLayers, boundingBox,
}) => {
  const config = useMemo(() => DESIGN_EDITOR_TRANSFORM_CONTROL_POINT_CONFIGS[position], [position]);
  const dispatch = useDispatch();
  const { selectors, actions } = useContext(DesignEditorInstanceContext);
  const { widthScale, heightScale } = useSelector(selectors.selectCanvasViewConfig);
  const { aspectRatioLock } = useSelector(selectors.selectToolConfig).transform;

  const onMouseMove = useCallback(({ draggingOffset, beforeStartDragState }: MouseDragEvent<MouseDragStateType>) => {
    const originalBoundingBoxSize = {
      width: beforeStartDragState.boundingBox.b.x - beforeStartDragState.boundingBox.a.x,
      height: beforeStartDragState.boundingBox.b.y - beforeStartDragState.boundingBox.a.y,
    };
    const aspectRatio = originalBoundingBoxSize.width / originalBoundingBoxSize.height;

    const draggedXRatio = (draggingOffset.x / widthScale) * 100;
    const draggedYRatio = (draggingOffset.y / heightScale) * 100;
    let xyRatio;
    if (aspectRatioLock) {
      let draggedMinRatio;
      if (config.offsetXMultiplier === 0) draggedMinRatio = draggedYRatio * config.offsetYMultiplier;
      else if (config.offsetYMultiplier === 0) draggedMinRatio = draggedXRatio * config.offsetXMultiplier;
      else draggedMinRatio = Math.min(draggedXRatio * config.offsetXMultiplier, draggedYRatio * config.offsetYMultiplier);

      xyRatio = {
        xRatio: (draggedMinRatio * (draggedMinRatio === draggedYRatio * config.offsetYMultiplier ? aspectRatio : 1)) / originalBoundingBoxSize.width,
        yRatio: (draggedMinRatio / (draggedMinRatio === draggedXRatio * config.offsetXMultiplier ? aspectRatio : 1)) / originalBoundingBoxSize.height,
      };
    } else {
      xyRatio = config.offsetMultiplier(
        draggedXRatio / originalBoundingBoxSize.width,
        draggedYRatio / originalBoundingBoxSize.height,
      );
    }
    const { xRatio, yRatio } = xyRatio;
    const { x: baseX, y: baseY } = config.basePositionExtractor(beforeStartDragState.boundingBox);

    const changedLayers = beforeStartDragState.selectedLayers.map((layer) => {
      const newLayer = ({
        ...layer,
        width: { ...layer.width, dimension: layer.width.dimension * Math.abs(1 + xRatio) },
        height: { ...layer.height, dimension: layer.height.dimension * Math.abs(1 + yRatio) },
        positionX: {
          ...layer.positionX,
          dimension: (layer.positionX.dimension - baseX) * (1 + xRatio * Math.abs(config.offsetXMultiplier)) + baseX,
        },
        positionY: {
          ...layer.positionY,
          dimension: (layer.positionY.dimension - baseY) * (1 + yRatio * Math.abs(config.offsetYMultiplier)) + baseY,
        },
      } as BoothDesignMasterElement);
      return actions.updateLayer(newLayer);
    });

    batch(() => changedLayers.forEach((layer) => dispatch(layer)));
  }, [actions, aspectRatioLock, config, dispatch, heightScale, widthScale]);

  const memoStartDragState = useMemo(() => ({ selectedLayers, boundingBox }), [boundingBox, selectedLayers]);
  const { startDrag } = useMouseDrag<MouseDragStateType>({ onMouseMove, state: memoStartDragState });
  const style = useMemo(() => config, [config]);
  return (
    <SizeControlPoint style={style} onMouseDown={startDrag} />
  );
};

export default React.memo(DesignEditorTransformSizeControlPoint);
