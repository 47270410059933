import _ from 'lodash';
import { defer, Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal-compatibility';
import * as UpChunk from 'utils/upchunk';
import axios from 'axios';
import { map, mergeMap } from 'rxjs/operators';
import { FIRESTORE_ENDPOINT, INITIATE_UPLOAD_ENDPOINT } from 'config';
import { calculateChecksum } from '../active-storage';

export type FirebaseUploadProgress = {
  uploadPercentage: number,
  isCompleted: boolean,
  fileURL: string,
  onCancel: () => void,
}

const CHUNK_SIZE_MB = 10;
const CHUNK_SIZE_KB = 1024 * CHUNK_SIZE_MB;

const getNonGoogleUrl = (url: string): string => url.replace('//storage.googleapis.com/', '//');

/**
 * Emit progress event when in progress
 * Emit true when completed
 * @param file
 * @param url
 * @param headers
 */
const directFirebaseUpload = (file: File, url: string, headers: Record<string, string>) => defer(() => new Observable<FirebaseUploadProgress>((subscriber) => {
  const urlObject = new URL(url);
  const uploadedFileURL = getNonGoogleUrl(urlObject.origin + urlObject.pathname);
  axios.post(INITIATE_UPLOAD_ENDPOINT, {
    initiateUploadUrl: url,
    headers,
  }).then((response) => {
    const { uploadUrl } = response.data;
    const lowercaseHeaders = _.mapKeys(headers, (__, key) => key.toLowerCase());
    const upload = UpChunk.createUpload({
      endpoint: getNonGoogleUrl(uploadUrl),
      file,
      chunkSize: CHUNK_SIZE_KB,
      headers: {
        'Content-MD5': lowercaseHeaders['content-md5'],
        'Content-Type': file.type,
      },
    });

    // subscribe to events
    upload.on('error', (err) => {
      console.error(err);
      subscriber.error(err);
    });

    upload.on('progress', (progress) => {
      subscriber.next({
        onCancel: () => upload.abort(),
        isCompleted: false,
        uploadPercentage: progress.detail,
        fileURL: uploadedFileURL,
      });
    });

    upload.on('success', () => {
      subscriber.next({
        onCancel: () => {},
        isCompleted: true,
        uploadPercentage: 100,
        fileURL: uploadedFileURL,
      });
      subscriber.complete();
    });
  }).catch((e) => {
    console.error('axios POST error');
    subscriber.error(e);
  });
}));

interface FirebaseFilePayload {
  directUploadUrl: string;
  uploadFileName: string;
  headers: Record<string, string>;
}

interface FirebaseFileRequest {
  fileName: string;
  contentLength: number;
  checksum: string;
  eventId: string;
}

const createFirebaseCredentialRequest = (fileData: FirebaseFileRequest) => fetch(`${FIRESTORE_ENDPOINT}/upload/generateSignedUrl`, {
  method: 'POST',
  body: JSON.stringify({
    ...fileData,
    version: 'v2',
  }),
  headers: {
    'content-type': 'application/json',
  },
}).then((response) => response.json()).then((payload) => payload.data as FirebaseFilePayload);

const createFirebaseCredentials = (file: File, eventId: string): Observable<FirebaseFilePayload> => calculateChecksum(file).pipe(
  map((checksum) => ({
    fileName: file.name,
    checksum,
    contentLength: file.size,
    eventId,
  })),
  mergeMap((fileMeta) => fromPromise(createFirebaseCredentialRequest(fileMeta))),
);

const replaceFileName = (file: File, newFileName: string) => {
  const data = new FormData();
  data.append('file', file, newFileName);
  return data.get('file') as File;
};

/**
 * Upload a file using ActiveStorage approch
 * @return An cold observable which will emit xhr progress event, and complete once file uploaded
 */
const firebaseUploadFile = (file: File, eventId: string): Observable<FirebaseUploadProgress> => createFirebaseCredentials(file, eventId).pipe(
  mergeMap(({ uploadFileName, directUploadUrl, headers }) => {
    const updatedFile = replaceFileName(file, uploadFileName);
    return directFirebaseUpload(updatedFile, directUploadUrl, headers);
  }),
);

export default firebaseUploadFile;
