import { Epic } from 'redux-observable';
import {
  catchError, filter, map, mergeMap, takeWhile, tap,
} from 'rxjs/operators';
import { merge, of, throwError } from 'rxjs';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import firebaseUploadFile, { FirebaseUploadProgress } from 'utils/firebase-storage';
import { createAction } from '@reduxjs/toolkit';
import { ExecutingTasksActions } from 'utils/xtra-executing-tasks/createExecutingTasksActions';
import { catchErrorToPopup, ERROR_POPUP_TYPES } from 'redux/mutationFailedPipe';

export interface UploadFirebaseFilePayload {
  title: string;
  file: File;
  eventId: string;
  /**
   * Use to identify is the upload repeated, latest upload action will cancel the previous one
   * null to allow repeating upload
   */
  identifier?: string;
  /**
   * Callback when file uploaded
   */
  callback?: (fileUrl: string) => void;
}

export const createUploadFirebaseFileAction = (prefix: string) => createAction<UploadFirebaseFilePayload>(`${prefix}/uploadFirebaseFile`);

export type UploadFirebaseFileAction = ReturnType<typeof createUploadFirebaseFileAction>

export const createUploadFirebaseFileEpic: (uploadFirebaseAction: UploadFirebaseFileAction, executingTasksActions: ExecutingTasksActions) => Epic = (uploadFirebaseAction, executingTasksActions) => (action$, state$) => action$.pipe(
  filter(uploadFirebaseAction.match),
  mergeMap(({
    payload: {
      callback, file, identifier, title, eventId,
    },
  }) => {
    const executingTask = createExecutingTask({
      name: title, tags: [ExecutingTaskCommonTag.Mutation],
    });

    const overlappedUploadFileAction$ = action$.pipe(
      filter(uploadFirebaseAction.match),
      filter(({ payload: { identifier: anotherIdentifier } }) => identifier !== null && anotherIdentifier === identifier),
    );
    const cancelAction$ = action$.ofType<{ id: string }>('TIMED_ACTION_CANCEL')
      .pipe(filter(({ id }) => id === executingTask.id));

    const stopSignal$ = merge(overlappedUploadFileAction$, cancelAction$)
      .pipe(map((signal) => ({ isStopSignal: true, signal })));

    let onCancel: () => void = () => {};
    return merge(
      firebaseUploadFile(file, eventId).pipe(map((progress) => ({ isStopSignal: false, progress }))),
      stopSignal$,
    ).pipe(
      // Stop (inclusive) after received the stop signal
      takeWhile((signal) => !signal.isStopSignal, true),
      tap((signal) => signal.isStopSignal && onCancel()),

      mergeMap((signal) => {
        // Map the stop signal to "action remove" when canceling
        if (signal.isStopSignal === true) return of(executingTasksActions.removeExecutingTasks(executingTask.id));

        return of((signal as any).progress as FirebaseUploadProgress).pipe(
          tap((progress) => {
            onCancel = progress.onCancel;
          }),

          // Call callback when the upload is completed
          tap((progress) => progress.isCompleted && callback(progress.fileURL)),

          // Map the progress to "update progress action"
          map((progress) => (progress.isCompleted ? executingTasksActions.removeExecutingTasks(executingTask.id)
            : executingTasksActions.upsertExecutingTasks({
              ...executingTask,
              progress: progress.isCompleted ? 1 : progress.uploadPercentage / 100,
            }))),
        );
      }),
      catchError((error) => throwError({ type: ERROR_POPUP_TYPES.UploadFailedError, raw: error })),
      catchErrorToPopup(state$.value.intl),
      executingTasksActions.wrapWithExecutingTaskAction(() => executingTask),
    );
  }),
);
