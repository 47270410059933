import axios from 'axios';
import { defer, of } from 'rxjs';
import { EVENT_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { eventEditMutationCompleteObservable } from 'admin-data/event/EventEdit/redux/epic/utils';
import { createStandardEventEditRequestBundle } from 'admin-data/event/EventEdit/redux/createStandardEventEditRequestBundle';
import { map } from 'rxjs/operators';
import { EVENT_FIRESTORE_API, FIRESTORE_ENDPOINT } from 'config';
import { EventSettings, LiveStreamSetting } from 'admin-data/event/EventEdit/redux/eventSettingEditState';

const bundleEventEventSettingsLiveStreamGet = createStandardEventEditRequestBundle<string, EventSettings>({
  actionName: `${EVENT_EDIT_ACTIONS_NAME}/eventSettings/liveStream/bundleEventEventSettingsLiveStreamGet`,
  preConditionObservableFactory: eventEditMutationCompleteObservable,

  requestFactory: ({
    eventDomain, locale, authToken, payload,
  }) => defer(() => axios.get(`${EVENT_FIRESTORE_API}?event_domain=${eventDomain}`, {}))
    .pipe(map((resp) => resp.data)),

  requestResponseMapper: ({
    response, createUpdateEventEditAction,
  }) => of(createUpdateEventEditAction((eventEditState) => {
    eventEditState.fragments.eventSettings.liveStream = response?.liveStream ?? {
      boothIdLiveStreamPeriodsMap: {},
    };
    eventEditState.fragments.eventSettings.startTime = response?.startTime;
    eventEditState.fragments.eventSettings.endTime = response?.endTime;
  })),
});

export default bundleEventEventSettingsLiveStreamGet;
