import { Action, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { Draft } from 'immer';
import { HintsAction } from 'utils/xtra-hints/createHintsActions';
import { HintsState } from 'utils/xtra-hints/hintsState';

// eslint-disable-next-line import/prefer-default-export
export const injectHintsExtraReducer = <S>(
  builder: ActionReducerMapBuilder<S>,
  actions: HintsAction,
  stateGetter: (state: Draft<S>, action: Action<HintsAction[keyof HintsAction]>) => HintsState,
  localStoragePrefix: string,
) => {
  builder.addCase(actions.upsertCurrentHint, (state, action) => {
    const hintsState = stateGetter(state, action as any);

    if (action.payload == null) {
      hintsState.showingHint = null;
    } else if (
      (hintsState?.showingHint?.key === action?.payload?.key)
      || (hintsState.hideAllHints !== true && hintsState.hideTemporaryHintKeys[action.payload.key] !== true)
    ) {
      hintsState.showingHint = {
        ...action.payload,
        page: action.payload.page ?? 0,
      };
      hintsState.hideTemporaryHintKeys[action.payload.key] = true;
    }
  });

  builder.addCase(actions.updateHideAllHints, (state, action) => {
    const hintsState = stateGetter(state, action as any);
    hintsState.hideAllHints = action.payload;
    localStorage.setItem(`${localStoragePrefix}/hideAllHints`, hintsState.hideAllHints ? 'true' : 'false');
  });
};
