import { Epic } from 'redux-observable';
import {
  filter, ignoreElements, map, mergeMap,
} from 'rxjs/operators';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import cmsSelectors from 'redux/cmsSelectors';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { boothEditExecutingTasksTagCreator } from 'admin-data/booth/BoothEdit/redux/boothEditExecutingTasks';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import produce from 'immer';
import { BoothContentElement } from 'models';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import { concat, of } from 'rxjs';
import { IntlShape } from 'react-intl';

const deleteCategroyEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.mutations.deleteCategory.match),
  mergeMap(({ payload: categoryId }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const locale = eventEditSelectors.selectEditLocale(state$.value);
    const eventId = eventEditSelectors.selectEventId(state$.value);
    const originalEventFactory = () => eventEditSelectors.selectEvent(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.mutations.deleteCategory(),
        ExecutingTaskCommonTag.Mutation],
    });

    const requests = sdk.boothTaxonomyDelete({
      input: { taxonomyId: categoryId, eventId },
    }).pipe(
      throwCommonError(),
      ignoreElements(),
      catchErrorToPopup(state$.value.intl),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return concat(
      of(eventEditActions.internalUpdateEvent(produce(originalEventFactory(), (draft) => {
        const index = draft.boothTaxonomies.nodes.findIndex((node) => node.id === categoryId);
        draft.boothTaxonomies.nodes.splice(index, 1);
      }))),
      of(eventEditActions.internalAddSequencedRequest(requests)),
    );
  }),
);

export default deleteCategroyEpic;
