import { createSelector } from 'reselect';
import { EVENT_EDIT_STORE_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { EventEditState } from 'admin-data/event/EventEdit/redux/eventEditState';

const selectEventEdit = ((state) => state[EVENT_EDIT_STORE_NAME] as EventEditState);
const selectFragment = createSelector(selectEventEdit, (eventEdit) => eventEdit.fragments.boothsV2);
const selectPageNumber = createSelector(selectFragment, (fragment) => fragment.pageNumber);
const selectPageSize = createSelector(selectFragment, (fragment) => fragment.pageSize);
const selectCurrentTierId = createSelector(selectFragment, (fragment) => fragment.currentTierId);
const selectBoothsList = createSelector(selectFragment, (fragment) => fragment.pagedBoothsList);
const selectSelectedBooths = createSelector(selectFragment, (fragment) => fragment.selectedBooths);

const eventEditBoothsV2Selectors = {
  selectFragment,
  selectBoothsList,
  selectSelectedBooths,
  selectPageNumber,
  selectPageSize,
  selectCurrentTierId,
};

export default eventEditBoothsV2Selectors;
