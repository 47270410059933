import createUpdateFieldsEpic from 'utils/redux-update-model/createUpdateFieldsEpic';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import eventEditStateUpdateParser from 'admin-data/event/EventEdit/redux/updateParsers/eventEditStateUpdateParser';

const updateEventStateFieldsEpic = createUpdateFieldsEpic(
  eventEditActions.updateFields,
  eventEditActions.internalUpdateFields,
  eventEditActions.internalAddBatchedDebouncedUpdateEventRequest,
  (state$, field) => {
    const result = eventEditStateUpdateParser(
      state$, {
        eventId: eventEditSelectors.selectEventId(state$.value),
        originalSource: field.source,
      }, field.source, field.value,
    );
    if (result != null) result.lessDebounce = field.lessDebounce;
    return result;
  },
);

export default updateEventStateFieldsEpic;
