import { Epic } from 'redux-observable';
import { filter, map, mergeMap } from 'rxjs/operators';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import cmsSelectors from 'redux/cmsSelectors';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { boothEditExecutingTasksTagCreator } from 'admin-data/booth/BoothEdit/redux/boothEditExecutingTasks';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import produce from 'immer';
import { BoothContentElement, BoothSupercategory } from 'models';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import { of } from 'rxjs';

const addSupercategoryEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.mutations.addSupercategory.match),
  mergeMap(({ payload: { parentId } }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const locale = eventEditSelectors.selectEditLocale(state$.value);
    const eventId = eventEditSelectors.selectEventId(state$.value);
    const originalEventFactory = () => eventEditSelectors.selectEvent(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.mutations.addSupercategory(),
        ExecutingTaskCommonTag.Mutation],
    });

    const request = sdk.boothSupercategoryCreate({
      input: { parentSupercategoryId: parentId, eventId, name: { values: [] } },
      locales: [locale],
    }).pipe(
      throwCommonError(),
      map((resp) => eventEditActions.internalUpdateEvent(produce(originalEventFactory(), ((draft) => {
        draft.boothTaxonomies.nodes.push(resp.boothSupercategoryCreate.boothSupercategory as BoothSupercategory);
      })))),
      catchErrorToPopup(state$.value.intl),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return of(eventEditActions.internalAddSequencedRequest(request));
  }),
);

export default addSupercategoryEpic;
