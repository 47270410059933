import { ProductCategory } from 'models-product';

export const findProductCategoryContainerAndIndex = (categories: ProductCategory[], id): [ProductCategory[], number] => {
  const result = categories.findIndex((category) => category.id === id);
  if (result !== -1) return [categories, result];

  return categories?.map((category) => findProductCategoryContainerAndIndex(category.subcategories ?? [], id))
    ?.find((it) => it != null);
};

export const findProductCategory = (categories: ProductCategory[], id): ProductCategory => categories?.find((it) => it.id === id)
  ?? (categories ?? []).map((category) => findProductCategory(category.subcategories, id)).find((it) => it != null);
