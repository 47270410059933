// React Stuffs
import React, { FC } from 'react';
// Material UI
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import useSpecialPageStyles from './useSpecialPageStyles';
// Matierial UI Icons
// Source Files
const errorImageUrl = 'https://spa-assets.eventxtra.com/assets/error_image.svg';

interface NotFoundPageProps {
  error?: string
}

const NotFoundPage: FC<NotFoundPageProps> = ({ error }) => {
  const classes = useSpecialPageStyles();
  const onHelpClick = () => {
    window.$zopim(() => {
      window.$zopim.livechat.window.show();
    });
  };

  return (
    <div>
      <Container component="main" maxWidth="sm" className={classes.container}>
        <img src={errorImageUrl} width="90%" alt="background" />
        <div className={classes.text}>
          <Typography variant="h4">
            Oops
          </Typography>
          <Typography variant="h6" className={classes.noBold} style={{ marginTop: '0.5rem' }}>
            {error ?? 'We cannot find the page you are looking for.'}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0.5rem' }}>
            <Typography variant="h6" className={classes.noBold}>
              <Link onClick={onHelpClick} underline="none" className={classes.link}>
                Need Help?
              </Link>
            </Typography>
          </div>
        </div>
      </Container>
      <div className={classes.footer}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Typography variant="body2" className={classes.linkRight}>
            <Link href="https://eventx.io/" underline="none">
              Looking for virtual event?
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
