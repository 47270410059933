import { request } from 'graphql-request';
import { DirectUpload, Mutation } from 'models';
import { FileChecksum } from '@rails/activestorage/src/file_checksum';
import { BlobUpload } from '@rails/activestorage/src/blob_upload';
import { defer, Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/internal-compatibility';
import { API_ENDPOINT } from 'config';

const credentialRequestMutation = `
  mutation createCredential($filename: String!, $contentType: String!, $checksum: String!, $byteSize: Int!) {
      initiateDirectUpload(input: { filename: $filename, contentType: $contentType, checksum: $checksum, byteSize: $byteSize }){
        directUpload {
          url
          headers
          signedBlobId
        }
      }
  }
`;

interface FileMeta {
  checksum: string;
  filename: string;
  contentType: string;
  byteSize: number;
}

const createCredentialRequest = (fileMeta: FileMeta) => request<Pick<Mutation, 'initiateDirectUpload'>>(API_ENDPOINT, credentialRequestMutation, { ...fileMeta });

export const calculateChecksum = (file: File) => new Observable<string>((subscriber) => {
  FileChecksum.create(file, (error, checksum) => {
    if (error) {
      subscriber.error(error);
    } else {
      subscriber.next(checksum);
      subscriber.complete();
    }
  });
});

const createCredential = (file: File) => calculateChecksum(file).pipe(
  map((checksum) => ({
    checksum,
    filename: file.name,
    contentType: file.type,
    byteSize: file.size,
  })),
  mergeMap((fileMeta) => fromPromise(createCredentialRequest(fileMeta))),
  map((data) => data.initiateDirectUpload.directUpload),
  tap((directUploadData: DirectUpload) => console.log(directUploadData)),
);

export interface DirectUploadProgress {
  isCompleted: boolean;
  xhr: XMLHttpRequest;
  progressEvent?: ProgressEvent;
}

/**
 * Emit progress event when in progress
 * Emit true when completed
 * @param file
 * @param url
 * @param headers
 */
const directUpload = (file: File, url: string, headers: any) => defer(() => new Observable<DirectUploadProgress>((subscriber) => {
  const upload = new BlobUpload({ file, directUploadData: { url, headers: { ...headers, 'Cache-Control': 'public,max-age=3600,s-maxage=31536000' } } });
  (upload.xhr as XMLHttpRequest).upload.onprogress = (progressEvent) => {
    subscriber.next({ isCompleted: false, xhr: upload.xhr, progressEvent });
  };
  upload.create((error) => {
    if (error) subscriber.error(error);
    else {
      subscriber.next({ isCompleted: true, xhr: upload.xhr });
      subscriber.complete();
    }
  });
}));

export type ActiveStorageUploadProgress = { signedBlobId: string, progressEvent?: ProgressEvent, isCompleted: boolean, xhr: XMLHttpRequest }

/**
 * Upload a file using ActiveStorage approch
 * @return An cold observable which will emit xhr progress event, and complete once file uploaded
 */
function activeStorageUploadFile(file: File): Observable<ActiveStorageUploadProgress> {
  return createCredential(file).pipe(
    mergeMap(({ url, headers, signedBlobId }) => directUpload(file, url, JSON.parse(headers)).pipe(
      map((uploadProgress: ActiveStorageUploadProgress) => (({ ...uploadProgress, signedBlobId }))),
    )),
  );
}

export default activeStorageUploadFile;
