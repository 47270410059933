import { Epic } from 'redux-observable';
import {
  filter, ignoreElements, map, mergeMap,
} from 'rxjs/operators';
import {
  concat, EMPTY, merge, of,
} from 'rxjs';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import {
  catchErrorToPopup,
  throwCommonError,
} from 'redux/mutationFailedPipe';
import cmsSelectors from 'redux/cmsSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import produce from 'immer';

const deleteBoothTierEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.fragments.booths.mutations.deleteBoothTier.match),
  mergeMap(({ payload: id }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);

    const originalEventEditFactory = () => eventEditSelectors.selectEventEdit(state$.value);

    const eventId = eventEditSelectors.selectEventId(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.fragments.booths.mutations.deleteBoothTier(),
        ExecutingTaskCommonTag.Mutation],
    });

    const internalDeleteAction = eventEditActions.internalUpdate(produce(originalEventEditFactory(), ((draft) => {
      const index = draft.event.boothTiers.nodes.findIndex((it) => it.id === id);
      draft.event.boothTiers.nodes.splice(index, 1);
      draft.event.boothTiers.totalCount -= 1;

      if (eventEditSelectors.fragments.booths.selectCurrentTierId(state$.value) === id) {
        draft.fragments.boothsV2.currentTierId = draft.event.boothTiers.nodes[0]?.id;
      }
    })));

    const requests = sdk.boothTierDelete({
      input: { eventId, boothTierId: id },
    }).pipe(
      throwCommonError(),
      ignoreElements(),
      catchErrorToPopup(state$.value.intl),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return concat(
      of(internalDeleteAction),
      requests,
    );
  }),
);

export default deleteBoothTierEpic;
