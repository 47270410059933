import { useTheme } from '@material-ui/core';
import React from 'react';

const DesignEditorScrollbarThumb = ({ style, ...props }) => (
  <div
    {...props}
    style={{
      ...style, backgroundColor: '#6FE2E2', width: '4px', opacity: '0.5',
    }}
  />
);

export default DesignEditorScrollbarThumb;
