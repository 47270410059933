import React from 'react';
import { DesignEditorDockerFramesInfo } from 'libs/xtra-custom-booth-design/types/DesignEditorDockerInfo';
import { DockerTypes } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/dockersMap';

// eslint-disable-next-line import/prefer-default-export
export const defaultDockersLayout: DesignEditorDockerFramesInfo = {
  left: {
    id: 'left',
    type: 'fixed',
    data: {
      position: 'left',
    },
    dockerIds: [DockerTypes.CanvasAttributes, DockerTypes.Toolbox, DockerTypes.Layers],
  },
  right: {
    id: 'right',
    type: 'fixed',
    data: {
      position: 'right',
    },
    dockerIds: [DockerTypes.Locale, DockerTypes.Transform, DockerTypes.LayerAttributes],
  },
};
