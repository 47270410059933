import React from 'react';
import NeedRefreshPage from 'special-pages/NeedRefreshPage';

class XtraErrorBoundary extends React.Component<unknown, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    if (hasError) {
      // You can render any custom fallback UI
      return <NeedRefreshPage />;
    }
    const { children } = this.props;
    return children;
  }
}

export default XtraErrorBoundary;
