import React, {
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel,
} from '@material-ui/core';
import { HintType } from 'utils/xtra-hints/hintType';
import { useDispatch, useSelector } from 'react-redux';
import cmsSelectors from 'redux/cmsSelectors';
import cmsActions from 'redux/cmsActions';
import produce from 'immer';
import styled from 'styled-components';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

export interface XtraHintDialogProps {
  hintsMap: Record<string, HintType>
}

const StyledDialogActions = styled(DialogActions)`
  justify-content: space-between;
`;

const StyledDialogActionsButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  
  & > *:not(:last-child){
    margin-right: 8px;
  }
`;

const StyledTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  & > *:not(:last-child){
    margin-right: 16px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  
  & img{
    width: 100%;
    max-width: 500px;
    padding: 8px 0;
    margin: 8px auto;
  }
`;

const XtraHintDefaultTitle: FC = () => (
  <StyledTitleRow>
    <FontAwesomeIcon icon={faLightbulb} />
    <div>
      Do you know?
    </div>
  </StyledTitleRow>
);

const XtraHintDialog: FC<XtraHintDialogProps> = ({ hintsMap }) => {
  const showingHints = useSelector(cmsSelectors.hints.selectShowingHint);
  const showingHintType = useMemo(() => hintsMap[showingHints?.key], [hintsMap, showingHints?.key]);

  const Title = useMemo(() => showingHintType?.titleFactory?.call(showingHints?.data) ?? XtraHintDefaultTitle, [showingHintType, showingHints?.data]);
  const contents = useMemo(() => showingHintType?.contentsFactory(showingHints?.data), [showingHintType, showingHints?.data]);
  const Content = useMemo(() => contents?.[showingHints?.page], [contents, showingHints?.page]);

  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    dispatch(cmsActions.hints.upsertCurrentHint(null));
  }, [dispatch]);

  const isLastPage = showingHints?.page >= contents?.length - 1;

  const onHideAllHints = useCallback(() => {
    dispatch(cmsActions.updateSettingDialogOpen(true));
  }, [dispatch]);

  const onNextPage = useCallback(() => {
    dispatch(cmsActions.hints.upsertCurrentHint(produce(showingHints, (draft) => {
      draft.page += 1;
    })));
  }, [dispatch, showingHints]);

  return showingHintType != null && (
    <Dialog open fullWidth maxWidth="sm">
      <DialogTitle><Title /></DialogTitle>
      <StyledDialogContent>
        <Content />
      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={onHideAllHints}>
          <FormattedMessage id="hints.hide_all_hints" defaultMessage="Hide all hints" />
        </Button>
        <StyledDialogActionsButtons>
          <Button onClick={onClose} color="primary" variant={isLastPage ? 'contained' : 'text'} autoFocus={isLastPage}>
            {isLastPage
              ? <FormattedMessage id="common.ok" defaultMessage="OK" />
              : <FormattedMessage id="common.close" defaultMessage="Close" />}
          </Button>
          {!isLastPage && (
            <Button onClick={onNextPage} color="primary" variant="contained" autoFocus>
              <FormattedMessage id="common.next" defaultMessage="Next" />
            </Button>
          )}
        </StyledDialogActionsButtons>
      </StyledDialogActions>
    </Dialog>
  );
};

export default XtraHintDialog;
