import { Button, IconButton, Snackbar } from '@material-ui/core';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cmsSelectors from 'redux/cmsSelectors';
import styled from 'styled-components';
import { Popup } from 'utils/xtra-popups/popup';
import { timer } from 'rxjs';
import cmsActions from 'redux/cmsActions';
import { Close } from '@material-ui/icons';

const XtraSnackbar: FC<{ popup: Popup<string> }> = React.memo(({ popup }) => {
  const [open, setOpen] = useState(true);
  const dispatch = useDispatch();
  const onClose = useCallback(() => {
    setOpen(false);
    timer(1000).subscribe(() => {
      dispatch(cmsActions.popups.removePopup(popup.id));
    });
  }, [dispatch, popup.id]);

  const message = popup.data;

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={20000}
      onClose={onClose}
      message={message}
      action={(
        <>
          <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
            <Close fontSize="small" />
          </IconButton>
        </>
      )}
    />
  );
});

const XtraSnackbarPopups: FC = () => {
  const popups = useSelector(cmsSelectors.popups.selectPopupsByType('SnackBar')) as Popup<string>[];
  return (
    <>
      {popups.map((popup) => <XtraSnackbar popup={popup} key={popup.id} />)}
    </>
  );
};

export default XtraSnackbarPopups;
