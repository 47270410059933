import {
  bufferToggle, debounce, debounceTime,
  groupBy, map, mergeMap, throttleTime,
} from 'rxjs/operators';
import { DebouncedRequest, rawDesignEditorInstanceActions } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceActions';
import { DesignIdWrappedPayload } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designIdWrappedPayload';
import { Epic } from 'redux-observable';
import { timer } from 'rxjs';

const internalAddDebouncedRequestEpic: Epic = (action$, state$) => action$
  .ofType(rawDesignEditorInstanceActions.internalAddDebouncedRequest).pipe(
    map((action) => action.payload as DesignIdWrappedPayload<DebouncedRequest>),
    groupBy((payload) => payload.instanceDesignId, (it) => it, () => timer(10000)),
    mergeMap((instanceGroups) => instanceGroups.pipe(
      groupBy((instanceGroup) => instanceGroup.data.debounceKey, (it) => it, () => timer(10000)),
      mergeMap((debounceGroup) => debounceGroup.pipe(
        debounceTime(500),
        mergeMap((debouncedRequest) => debouncedRequest.data.requestFactory()),
      )),
    )),
  );

export default internalAddDebouncedRequestEpic;
