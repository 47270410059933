import { combineEpics } from 'redux-observable';
import designEditorInstanceActionEpics from 'libs/xtra-custom-booth-design/redux/design-editor-instance/epics';
import designEditorActionEpics from 'libs/xtra-custom-booth-design/redux/design-editor/epics';
import { createUploadTaskEpic } from 'utils/xtra-upload-tasks/createUploadTaskEpic';
import { DESIGN_EDITOR_ACTION_PREFIX } from 'libs/xtra-custom-booth-design/redux/designEditorVars';

const designEditorEpics = combineEpics(
  ...designEditorActionEpics,
  ...designEditorInstanceActionEpics,
);

export default designEditorEpics;
