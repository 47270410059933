import { BOOTH_EDIT_STORE_NAME } from 'admin-data/booth/BoothEdit/redux/boothEditVars';
import { createSelector, OutputSelector } from 'reselect';
import { Booth } from 'models';
import { parseSelector, SourceSelector } from 'utils/dummyProxy';
import _ from 'lodash';
import { createExecutingTasksSelectors } from 'utils/xtra-executing-tasks';
import { BoothEditState } from 'admin-data/booth/BoothEdit/redux/boothEditState';

const selectBoothEditState = (state) => state[BOOTH_EDIT_STORE_NAME] as BoothEditState;

const selectLocale = createSelector(selectBoothEditState, (state) => state.locale);

const selectBooth = createSelector(selectBoothEditState, (state) => state.booth);
const selectBoothContentTabList = createSelector(selectBooth, (booth) => booth?.rootContentElement?.tabs?.nodes ?? []);
const selectBoothId = createSelector(selectBooth, (booth) => booth?.id);

const selectBoothEventSettings = createSelector(selectBoothEditState, (state) => state.eventSettings);

const selectBoothEvent = createSelector(selectBooth, (booth) => booth?.event);
const selectBoothEventLocales = createSelector(selectBoothEvent, (event) => event?.locales);

const selectBoothLegacyDesignElements = createSelector(selectBooth, (booth) => booth?.designElements?.nodes);

const selectBoothLegacyDesignElementsIdMap = createSelector(selectBoothLegacyDesignElements, (elements) => _.keyBy(elements, (element) => element.id));

const selectBoothLegacyDesignElementById = (elementId) => createSelector(selectBoothLegacyDesignElementsIdMap, (idMap) => idMap[elementId]);

const selectInternalField = <T extends any>(source: string | SourceSelector<BoothEditState, T>): OutputSelector<any, T, (res: BoothEditState) => T> => {
  const rawSource = typeof source === 'function' ? (parseSelector(source)) : source;
  if (rawSource === 'booth' || rawSource?.startsWith('booth.')) throw new Error('Selecting booth fields with useBoothEditInternalStateReduxBindAdapter is not allowed');
  return createSelector(selectBoothEditState, (state) => _.get(state, rawSource) as T);
};

const selectBoothField = <T extends any>(source: string | SourceSelector<Booth, T>): OutputSelector<any, T, (res: Booth) => T> => {
  const rawSource = typeof source === 'function' ? (parseSelector(source)) : source;
  return createSelector(selectBooth, (booth) => _.get(booth, rawSource) as T);
};

const selectExecutingTasks = createSelector(selectBoothEditState, (state) => state.executingTasks);

const boothEditSelectors = {
  selectField: selectInternalField,
  selectBoothEditState,
  selectBoothId,
  selectBoothEventSettings,
  selectBooth,
  selectBoothField,
  selectLocale,
  selectBoothEvent,
  selectBoothEventLocales,
  selectBoothContentTabList,
  selectBoothLegacyDesignElements,
  selectBoothLegacyDesignElementById,
  executingTasks: createExecutingTasksSelectors(selectExecutingTasks),
};

export default boothEditSelectors;
