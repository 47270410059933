import { TypeBoundedSource } from 'utils/redux-field-bind/reduxFieldBind';

export type SourceSelector<T extends any, R extends any> = (object: T) => R

const dummyProxyHandler = (prefix?: string) => ({
  get(target, prop) {
    if (prop === 'toString') return () => prefix;

    const source = prop === parseInt(prop, 10)
      ? `${prefix}[${prop}]` // must have prefix
      : `${prefix == null ? '' : `${prefix}.`}${prop}`;
    return new Proxy({}, dummyProxyHandler(source));
  },
});

const dummyProxy = <T extends any>() => new Proxy({}, dummyProxyHandler()) as unknown as T;

export function parseSelector<T, U>(selector: string | SourceSelector<T, U>) {
  return (typeof selector === 'function' ? (selector as SourceSelector<T, U>)(dummyProxy()).toString() : selector);
}

export function parseCombinedSelector<T, U, O>(prefix: string | SourceSelector<T, U>, selector: string | SourceSelector<U, O>) {
  return `${parseSelector(prefix)}.${parseSelector(selector)}`;
}

export function parseTypedSubselector<U, O>(typeBoundedPrefix: TypeBoundedSource<U>, selector: string | SourceSelector<U, O>) {
  return parseCombinedSelector(typeBoundedPrefix.source, selector);
}

export default dummyProxy;
