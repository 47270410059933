import { createPopupsActions } from 'utils/xtra-popups/createPopupsActions';
import { CMS_ACTIONS_NAME } from 'redux/cmsVars';
import { createAction } from '@reduxjs/toolkit';
import { AuthRole } from 'redux/cmsState';
import { createHintsAction } from 'utils/xtra-hints/createHintsActions';

const cmsActions = {
  updateAuthRole: createAction<AuthRole>(`${CMS_ACTIONS_NAME}/updateAuthRole`),
  setSDKAccount: createAction<string>(`${CMS_ACTIONS_NAME}/setSDKAccount`),
  popups: createPopupsActions(CMS_ACTIONS_NAME),
  fetchTranslations: createAction(`${CMS_ACTIONS_NAME}/fetchTranslations`),
  internalUpdateTranslations: createAction<{ [locale: string]: any }>(`${CMS_ACTIONS_NAME}/_updateTranslations`),
  updateSettingDialogOpen: createAction<boolean>(` ${CMS_ACTIONS_NAME}/updateSettingDialogOpen`),

  hints: createHintsAction(CMS_ACTIONS_NAME),
};

export default cmsActions;
