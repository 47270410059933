import axios from 'axios';
import { CMS_PRODUCT_API_ENDPOINT } from 'config';
import { defer, of } from 'rxjs';
import { EVENT_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { createStandardEventEditRequestBundle } from 'admin-data/event/EventEdit/redux/createStandardEventEditRequestBundle';
import { map, mergeMap } from 'rxjs/operators';
import { ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { ProductCategory } from 'models-product';
import {
  findProductCategory, findProductCategoryContainerAndIndex,
} from 'admin-data/event/EventEdit/redux/requestBundle/product/category/utils';

interface BundleEventProductCategoryReorderPayload {
  categoryId: string,
  destination: {
    parentId: string,
    moveToPositionOriginalCategoryId: string,
    rowOrderSort: number,
  }
}

const bundleEventProductCategoryReorder = createStandardEventEditRequestBundle<BundleEventProductCategoryReorderPayload>({
  actionName: `${EVENT_EDIT_ACTIONS_NAME}/product/category/reorder`,
  additionalTaskTags: [ExecutingTaskCommonTag.Mutation],
  isSequenced: true,

  preRequestActionsObservableFactory: ({
    payload, state$, createUpdateEventEditAction,
  }) => of(createUpdateEventEditAction((eventEdit) => {
    const [fromContainer, fromIndex] = findProductCategoryContainerAndIndex(eventEdit.event.productSettings.categories, payload.categoryId);
    const toContainer = payload.destination.parentId == null
      ? eventEdit.event.productSettings.categories
      : findProductCategory(eventEdit.event.productSettings.categories, payload.destination.parentId).subcategories;

    let toIndex = 0;
    if (payload.destination.moveToPositionOriginalCategoryId) {
      const [verifyToContainer, afterIndex] = findProductCategoryContainerAndIndex(eventEdit.event.productSettings.categories, payload.destination.moveToPositionOriginalCategoryId);
      if (toContainer !== verifyToContainer) throw new Error('The destination category and afterCategory.parent are not the same');
      toIndex = afterIndex;
    }
    const category = fromContainer.splice(fromIndex, 1)[0];
    toContainer.splice(toIndex, 0, category);
  })),

  requestFactory({
    eventDomain, locale, authToken, payload, eventEditFactory,
  }) {
    const parentCategoryId = payload.destination.parentId == null
      ? null
      : findProductCategory(eventEditFactory().event.productSettings.categories, payload.destination.parentId).id;

    const toPositionCategory = findProductCategory(eventEditFactory().event.productSettings.categories, payload.destination.moveToPositionOriginalCategoryId);
    const toPositionCategorySort$ = toPositionCategory == null ? of(0)
      : defer(() => axios.get(`${CMS_PRODUCT_API_ENDPOINT}/categories/${toPositionCategory.id}`)).pipe(
        map((resp) => (resp.data as ProductCategory).sort),
      );

    return toPositionCategorySort$.pipe(
      mergeMap((toCategorySort) => axios.put(`${CMS_PRODUCT_API_ENDPOINT}/categories/${payload.categoryId}`,
        {
          parentCategory: parentCategoryId,
          sort: toCategorySort,
          rowOrderSort: payload.destination.rowOrderSort,
        } as Partial<ProductCategory>,
        { headers: { 'x-eventxtra-admin': true } })),
      map((resp) => resp.data),
    );
  },
});

export default bundleEventProductCategoryReorder;
