import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { DesignEditorDockerComponent } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/designEditorDockerEntry';
import DesignEditorInstanceContext
  from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';
import Scrollbars from 'react-custom-scrollbars';
import { DockerCommonInnerContent } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/style';
import DesignEditorImageButtonMasterElementAttributesSetting
  from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayerAttributesDocker/layerTypes/DesignEditorImageButtonMasterElementAttributesSetting';
import { LayerElementType } from 'libs/xtra-custom-booth-design/types/DesignEditorInstance';
import DesignEditorContentAreaMasterElementAttributesSetting
  from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayerAttributesDocker/layerTypes/DesignEditorConntentAreaMasterElementAttributesSetting';
import DesignEditorImageMasterElementAttributesSetting
  from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayerAttributesDocker/layerTypes/DesignEditorImageMasterElementAttributesSetting';
import DesignEditorColorFillMasterElementAttributesSetting
  from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayerAttributesDocker/layerTypes/DesignEditorColorFillMasterElementAttributesSetting';
import DesignEditorScrollbarThumb from 'libs/xtra-custom-booth-design/components/shared/DesignEditorScrollbarThumb';

const settingType = {
  [LayerElementType.BoothDesignImageButtonMasterElement]: { SettingContentComponent: DesignEditorImageButtonMasterElementAttributesSetting },
  [LayerElementType.BoothDesignContentAreaMasterElement]: { SettingContentComponent: DesignEditorContentAreaMasterElementAttributesSetting },
  [LayerElementType.BoothDesignImageMasterElement]: { SettingContentComponent: DesignEditorImageMasterElementAttributesSetting },
  [LayerElementType.BoothDesignColorFillMasterElement]: { SettingContentComponent: DesignEditorColorFillMasterElementAttributesSetting },
};

const DesignEditorLayerAttributesDocker: DesignEditorDockerComponent = (props) => {
  const { actions, selectors, designId } = useContext(DesignEditorInstanceContext);

  const selectedLayers = useSelector(selectors.selectSelectedLayers);
  const enableSettings = selectedLayers.length === 1;
  // eslint-disable-next-line no-underscore-dangle
  const SettingContentComponent = !enableSettings ? null : settingType[selectedLayers[0].__typename]?.SettingContentComponent;

  return (
    <Scrollbars style={{ height: 400 }} renderThumbVertical={DesignEditorScrollbarThumb}>
      <DockerCommonInnerContent>
        {SettingContentComponent == null ? null : <SettingContentComponent />}
      </DockerCommonInnerContent>
    </Scrollbars>
  );
};

DesignEditorLayerAttributesDocker.whyDidYouRender = true;

export default React.memo(DesignEditorLayerAttributesDocker);
