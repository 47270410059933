import { DesignEditorState } from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorState';
import { DESIGN_EDITOR_STORE_NAME } from 'libs/xtra-custom-booth-design/redux/designEditorVars';
import { createSelector } from 'reselect';
import { BoothDesignMasterElement } from 'models';
import { BoundingBox } from 'libs/xtra-custom-booth-design/utils/boundingBox';
import { elementToBoundingBox } from 'libs/xtra-custom-booth-design/utils/element2DUtils';

const createDesignEditorInstanceSelectors = (designId: string) => {
  const selectInstance = (state) => (state[DESIGN_EDITOR_STORE_NAME] as DesignEditorState).instances[designId];
  const selectIsLoading = createSelector(selectInstance, (instance) => instance.isLoading);
  const selectDesign = createSelector(selectInstance, (instance) => instance.design);
  const selectCanvas = createSelector(selectDesign, (design) => design.canvas);
  const selectCanvasAspectRatio = createSelector(selectCanvas, (canvas) => canvas.aspectRatio);

  const selectLayers = createSelector(selectDesign, (design) => design.canvas.layers.nodes);

  const selectLayersIdMap = createSelector(selectDesign, (design) => design.canvas.layers.nodes
    .reduce((idMap, layer) => {
      idMap[layer.id] = layer;
      return idMap;
    }, {}) as { [id: string]: BoothDesignMasterElement });

  const selectLayerById = (layerId) => createSelector(selectLayersIdMap, (idMap) => idMap[layerId]);
  const selectLayersId = createSelector(selectDesign, (instance) => instance.canvas.layers.nodes.map((layer) => layer.id));

  const selectSelectedLayersId = createSelector(selectInstance, (instance) => instance.selectedLayerIds);
  const selectSelectedLayers = createSelector(
    [selectSelectedLayersId, selectLayersIdMap],
    (selectedLayerIds, layersIdMap) => selectedLayerIds.map((id) => layersIdMap[id]),
  );

  const selectSelectedLayerBoundingBox = createSelector(selectSelectedLayers, (selectedLayers) => {
    if (selectedLayers.length === 0) return null;
    const boundingBoxes = selectedLayers.map(elementToBoundingBox);
    return {
      a: {
        x: Math.min(...boundingBoxes.map((it) => it.a.x)),
        y: Math.min(...boundingBoxes.map((it) => it.a.y)),
      },
      b: {
        x: Math.max(...boundingBoxes.map((it) => it.b.x)),
        y: Math.max(...boundingBoxes.map((it) => it.b.y)),
      },
    } as BoundingBox;
  });

  const selectCanvasViewConfig = createSelector(selectInstance, selectCanvasAspectRatio, (instance, aspectRatio) => ({
    ...instance.canvasViewConfig,
    heightScale: instance.canvasViewConfig.widthScale / (aspectRatio.dimension / 100),
  }));

  const selectToolConfig = createSelector(selectInstance, (instance) => instance.toolConfig);

  return {
    selectInstance,
    selectIsLoading,
    selectDesign,
    selectLayers,
    selectLayersId,
    selectSelectedLayersId,
    selectSelectedLayers,
    selectSelectedLayerBoundingBox,
    selectLayersIdMap,
    selectLayerById,
    selectCanvasViewConfig,
    selectCanvas,
    selectCanvasAspectRatio,
    selectToolConfig,
  };
};

export type DesignEditorInstanceSelectors = ReturnType<typeof createDesignEditorInstanceSelectors>;

export default createDesignEditorInstanceSelectors;
