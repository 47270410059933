import { filter, map, mergeMap } from 'rxjs/operators';
import designEditorActions from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorActions';
import { Epic } from 'redux-observable';
import designEditorSelectors from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorSelectors';
import { DESIGN_EDITOR_STORE_NAME } from 'libs/xtra-custom-booth-design/redux/designEditorVars';
import { DesignEditorState } from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorState';
import { concat, merge, of } from 'rxjs';

/**
 * Close design epic
 * flush all unsaved changes then remove the instance from state
 */
const closeDesignEpic: Epic = (action$, state$) => action$
  .pipe(
    filter(designEditorActions.closeDesign.match),
    // TODO: mergeMap((instace) => instace.flushUnsavedChanges()),
    mergeMap(({ payload: designId }) => {
      const instance = designEditorSelectors.selectDesignInstanceByDesignId(designId)(state$.value);

      return concat(
        of(designEditorActions.internalRemoveEditorInstance(instance.design.id)),
        of(designEditorActions.setActiveInstance(designEditorSelectors.selectDesignInstances(state$.value)
          .find((otherInstance) => otherInstance.design.id !== instance.design.id)?.design?.id)),
      );
    }),
  );

export default closeDesignEpic;
