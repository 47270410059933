import { Epic } from 'redux-observable';
import { filter, map, mergeMap } from 'rxjs/operators';
import designEditorActions from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorActions';
import designEditorSelectors from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorSelectors';
import { concat, EMPTY, of } from 'rxjs';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import { v4 as uuidv4 } from 'uuid';
import { ExecutingTask } from 'utils/xtra-executing-tasks';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import cmsSelectors from 'redux/cmsSelectors';
import produce from 'immer';
import { merge as _merge } from 'lodash';

const renameDesignEpic: Epic = (action$, state$) => action$.pipe(
  filter(designEditorActions.renameDesign.match),
  mergeMap(({ payload: { designId: boothDesignId, name } }) => {
    const eventId = eventEditSelectors.selectEvent(state$.value).id;

    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);

    const taskFactory = () => ({
      id: uuidv4(),
      name: 'Rename booth design',
      progress: -1,
    } as ExecutingTask);

    const request = sdk.boothDesignUpdate({ input: { eventId, boothDesignId, name } }).pipe(
      throwCommonError(),
      map(() => designEditorActions.internalUpdateDesignsList(
        produce(designEditorSelectors.selectDesignEditorDesignsList(state$.value), (draft) => {
          _merge(draft.find((design) => design.id === boothDesignId), { name });
        }),
      )),
      catchErrorToPopup(state$.value.intl),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return concat(
      request,
      of(designEditorActions.loadDesignsList()),
    );
  }),
);

export default renameDesignEpic;
