// eslint-disable-next-line import/prefer-default-export
export enum LayerElementType {
  BoothDesignImageButtonMasterElement = 'BoothDesignImageButtonMasterElement',
  BoothDesignContentAreaMasterElement = 'BoothDesignContentAreaMasterElement',
  BoothDesignImageMasterElement = 'BoothDesignImageMasterElement',
  BoothDesignColorFillMasterElement = 'BoothDesignColorFillMasterElement',
  BoothDesignNetworkingWidgetMasterElement = 'BoothDesignNetworkingWidgetMasterElement',

  BoothDesignImageButtonElement = 'BoothDesignImageButtonElement',
  BoothDesignContentAreaElement = 'BoothDesignContentAreaElement',
  BoothDesignColorFillElement = 'BoothDesignColorFillElement',
  BoothDesignImageElement = 'BoothDesignImageElement',
  BoothDesignNetworkingWidgetElement = 'BoothDesignNetworkingWidgetElement',
}
