import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { concat, defer, EMPTY, of } from 'rxjs';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import { FIRESTORE_ENDPOINT } from 'config';

const updateBannerAssetEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.updateBannerAsset.match),
  mergeMap(({ payload }) => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.fragments.events.mutations.updateBannerAsset(),
        ExecutingTaskCommonTag.Mutation,
      ],
    });

    const putUrl = `${FIRESTORE_ENDPOINT}/bannerAsset`;
    const updateBannerAsset = defer(() => fetch(putUrl, {
      method: 'PUT',
      body: JSON.stringify({ ...payload, eventDomain: domain }),
      headers: { 'Content-Type': 'application/json' },
    })).pipe(
      mergeMap((resp) => resp.json() as Promise<{ success: boolean; bannerAssetId: string; }>),
      mergeMap(({ success }) => (success ? of(eventSettingEditActions.internalUpdateAsset(payload.updateItem)) : EMPTY)),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );
    return concat(
      updateBannerAsset,
      of(payload.callback),
    );
  }),
);

export default updateBannerAssetEpic;
