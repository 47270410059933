import { createSelector, Selector } from 'reselect';
import { HintsState } from 'utils/xtra-hints/hintsState';

// eslint-disable-next-line import/prefer-default-export
export const createHintsSelectors = (selector: Selector<any, HintsState>) => {
  const selectShowingHint = createSelector(selector, ((hintsState) => hintsState.showingHint));
  const selectHideAllHints = createSelector(selector, (hintsState) => hintsState.hideAllHints);

  return {
    selectShowingHint,
    selectHideAllHints,
  };
};
