import styled from 'styled-components';
import { ToggleButton } from '@material-ui/lab';

// eslint-disable-next-line import/prefer-default-export
export const ToolboxIconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 12px;
`;

export const StyledToolToggleButton = styled(ToggleButton)`
  width: 40px;
  height: 40px;
  color: white;
  border: none;
  margin: 4px;
`;
