import { createSelector, Selector } from 'reselect';
import { PopupsState } from 'utils/xtra-popups/popupsState';
import { Popup } from 'utils/xtra-popups/popup';

// eslint-disable-next-line import/prefer-default-export
export const createPopupsSelectors = (selector: Selector<any, PopupsState>) => {
  const selectPopupsIdMap = createSelector(selector, (state) => state.popupsIdMap);
  const selectPopupsTypeMap = createSelector(selector, (state) => state.popupsTypeMap);
  return {
    selectPopups: createSelector(selectPopupsIdMap, (popupsIdMap) => Object.values(popupsIdMap)),
    selectPopupsIdMap,
    selectPopupsByType: <T>(tag) => createSelector([selectPopupsIdMap, selectPopupsTypeMap],
      (popupsIdMap, popupsTypeMap) => Object.keys(popupsTypeMap[tag] ?? []).map((id) => popupsIdMap[id]) as Popup<T>[]),
  };
};

export type CreatePopupsSelectors = ReturnType<typeof createPopupsSelectors>
