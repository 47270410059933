import { recursiveFlattenBundlesObjects } from 'utils/xtra-request-bundle/createStandardRequestBundle';
import { combineEpics } from 'redux-observable';
import bundleEventProductCategoryGetList
  from 'admin-data/event/EventEdit/redux/requestBundle/product/category/bundleEventProductCategoryGetList';
import bundleEventProductCategoryCreate
  from 'admin-data/event/EventEdit/redux/requestBundle/product/category/bundleEventProductCategoryCreate';
import bundleEventProductCategoryDelete
  from 'admin-data/event/EventEdit/redux/requestBundle/product/category/bundleEventProductCategoryDelete';
import bundleEventProductCategoryReorder
  from 'admin-data/event/EventEdit/redux/requestBundle/product/category/bundleEventProductCategoryReorder';

import bundleEventWebinarAuditoriumAssetGetList
  from 'admin-data/event/EventEdit/redux/requestBundle/auditorium/asset/bundleEventWebinarAuditoriumAssetGetList';
import bundleEventWebinarAuditoriumAssetCreate
  from 'admin-data/event/EventEdit/redux/requestBundle/auditorium/asset/bundleEventWebinarAuditoriumAssetCreate';
import bundleEventWebinarAuditoriumAssetDelete
  from 'admin-data/event/EventEdit/redux/requestBundle/auditorium/asset/bundleEventWebinarAuditoriumAssetDelete';

import bundleEventWebinarAuditoriumAssetReorder from 'admin-data/event/EventEdit/redux/requestBundle/auditorium/asset/bundleEventWebinarAuditoriumAssetReorder';

import bundleEventAuditoriumGet from 'admin-data/event/EventEdit/redux/requestBundle/auditorium/bundleEventAuditoriumGet';
import bundleEventAuditoriumGetList from 'admin-data/event/EventEdit/redux/requestBundle/auditorium/bundleEventAuditoriumGetList';
import bundleEventAuditoriumCreate from 'admin-data/event/EventEdit/redux/requestBundle/auditorium/bundleEventAuditoriumCreate';
import bundleEventAuditoriumUpdate from 'admin-data/event/EventEdit/redux/requestBundle/auditorium/bundleEventAuditoriumUpdate';
import bundleEventAuditoriumDelete from 'admin-data/event/EventEdit/redux/requestBundle/auditorium/bundleEventAuditoriumDelete';

import bundleEventEventSettingsLiveStreamGet from 'admin-data/event/EventEdit/redux/requestBundle/eventSettings/liveStream/bundleEventEventSettingsLiveStreamGet';

export const eventEditRequests = {
  eventSettings: {
    liveStream: {
      get: bundleEventEventSettingsLiveStreamGet,
    },
  },
  auditorium: {
    get: bundleEventAuditoriumGet,
    getList: bundleEventAuditoriumGetList,
    create: bundleEventAuditoriumCreate,
    update: bundleEventAuditoriumUpdate,
    delete: bundleEventAuditoriumDelete,
  },
  webinar: {
    auditorium: {
      asset: {
        getList: bundleEventWebinarAuditoriumAssetGetList,
        create: bundleEventWebinarAuditoriumAssetCreate,
        delete: bundleEventWebinarAuditoriumAssetDelete,
        reorder: bundleEventWebinarAuditoriumAssetReorder,
      },
    },
  },

  products: {
    category: {
      getList: bundleEventProductCategoryGetList,
      create: bundleEventProductCategoryCreate,
      delete: bundleEventProductCategoryDelete,
      reorder: bundleEventProductCategoryReorder,
    },
  },
};

export const eventEditRequestsEpics = combineEpics(...recursiveFlattenBundlesObjects(eventEditRequests).map((bundle) => bundle.epic));
