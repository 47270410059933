import { Action, createAction } from '@reduxjs/toolkit';
import { ExecutingTask } from 'utils/xtra-executing-tasks/executingTask';
import {
  concat, Observable, of, OperatorFunction,
} from 'rxjs';

// eslint-disable-next-line import/prefer-default-export
/**
 * @T Extra info data on action
 */
export const createExecutingTasksActions = (actionPrefix) => {
  const upsertExecutingTasks = createAction<ExecutingTask>(`${actionPrefix}/upsertExecutingTasks`);
  const finishExecutingTasks = createAction<{ task: string | ExecutingTask, error?: any[] }>(`${actionPrefix}/finishExecutingTasks`);
  /**
   * @deprecated use finishExecutingTasks
   */
  const removeExecutingTasks = (task: string | ExecutingTask) => finishExecutingTasks({ task });

  return ({
    upsertExecutingTasks,
    removeExecutingTasks,
    finishExecutingTasks,
    // rxjs operator
    // prepend "upsertExecutingTasks" and append "removeExecutingTasks"
    wrapWithExecutingTaskAction: (taskFactory: () => ExecutingTask) => <T>(asyncContent: Observable<T>) => {
      const task = taskFactory();
      return concat(
        of(upsertExecutingTasks(task)),
        asyncContent,
        of(removeExecutingTasks(task)),
      );
    },
  });
};

export type ExecutingTasksActionsWrapper = OperatorFunction<Action, Action>

export type ExecutingTasksActions = ReturnType<typeof createExecutingTasksActions>;
