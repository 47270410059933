import { HintType } from 'utils/xtra-hints/hintType';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const hintBoothEditRepresentativesHorizontalScrollableElement: HintType<'hintBoothEditRepresentativesHorizontalScrollableElement'> = {
  key: 'hintBoothEditRepresentativesHorizontalScrollableElement',
  contentsFactory: () => [
    () => (
      <>
        <p>
          <FormattedMessage
            id="booth.representatives.hints.hori_scrollable_0"
            defaultMessage="Representatives list is horizontally scrollable."
          />
        </p>
        <p>
          <FormattedMessage
            id="event.representatives.hints.hori_scrollable_1"
            defaultMessage="Scrollbar may not be shown by default on MacOS, but you can scroll it with touchpad when the content is overflowing."
          />
        </p>
        <img
          src="https://cms-virtual-assets.eventxtra.com/hints/booth_edit_representatives_list_horizontal_scrollable_v2.gif"
          alt="horizontally scrollable"
        />
      </>
    ),
  ],
};

export default hintBoothEditRepresentativesHorizontalScrollableElement;
