import { StateObservable } from 'redux-observable';
import { parseSelector, SourceSelector } from 'utils/dummyProxy';
import _ from 'lodash';
import { tryOrNull } from 'libs/xtra-custom-booth-design/utils/fp';
import { CmsSDKObservables } from 'utils/auth';
import { Observable } from 'rxjs';
import { Action } from 'redux';

type ParseAttributes<T> = {
  originalSource: string
} & T

type UpdateParser<A> = (state$: StateObservable<any>, attributes: ParseAttributes<A>, source: string, value: any) => BatchedDebouncedUpdateRequest<any, any>

interface UpdateParserBuilder<T, A = unknown> {
  subParser<U>(selector: SourceSelector<T, U>, content: (builder: UpdateParserBuilder<U, any>) => void)

  nodesHandlerByIndex<U extends { id: string }>(
    selector: SourceSelector<T, U[]>,
    handler: (state$: StateObservable<any>, attributes: ParseAttributes<A>, index: number, source: string, value: any) => BatchedDebouncedUpdateRequest<any, any>,
  )

  handler<U>(selector: SourceSelector<T, U>, handler: (state$: StateObservable<any>, attributes: ParseAttributes<A>, source: string, value: any) => BatchedDebouncedUpdateRequest<any, any>)

  ignore<U>(selector: SourceSelector<T, U>)

  fallback(handler: (state$: StateObservable<any>, attributes: ParseAttributes<A>, source: string, value: any) => BatchedDebouncedUpdateRequest<any, any>)

  getParser(): UpdateParser<A>
}

const chopHeadingDot = (source: string) => (source.startsWith('.') ? source.substring(1) : source);

/**
 * Extract first param as index
 */
const extractIndexFromSource = (source: string) => {
  if (source.startsWith('[')) {
    const [indexStr, ...followingSourceArr] = source.substring(1).split(']');
    const followingSource = chopHeadingDot(_.join(followingSourceArr, ']'));
    const index = tryOrNull(() => parseInt(indexStr, 10)) as number;
    if (indexStr !== index.toString()) throw new Error(`Illegal index format: ${source}`);
    return { index, followingSource };
  }
  const [indexStr, ...followingSourceArr] = source.split('.');
  const followingSource = _.join(followingSourceArr, '.');
  const index = tryOrNull(() => parseInt(indexStr, 10)) as number;
  if (indexStr !== index.toString()) throw new Error(`Illegal index format: ${source}`);
  return { index, followingSource };
};

const chopSourcePrefix = (source: string, prefix: string) => chopHeadingDot(source.substring(prefix.length));

export const createUpdateParserBuilder = <O extends any, NA = unknown>(content: (builder: UpdateParserBuilder<O, NA>) => void) => {
  function innerCreateUpdateParserBuilder<T, A>(): UpdateParserBuilder<T, A> {
    const handlerMap: { [prefixSource: string]: UpdateParser<A> } = {};
    let fallback: (state$: StateObservable<any>, attributes: ParseAttributes<A>, source: string, value: any) => BatchedDebouncedUpdateRequest<any, any> = null;

    function updateParser(state$: StateObservable<any>, attributes: ParseAttributes<A>, source: string, value: any): BatchedDebouncedUpdateRequest<any, any> {
      const [prefixSource, handler] = Object.entries(handlerMap)
        .find(([testingPrefixSource]) => source.startsWith(`${testingPrefixSource}.`) || source.startsWith(`${testingPrefixSource}[`) || source.endsWith(testingPrefixSource)) ?? [];
      if (handler == null) {
        if (fallback == null) {
          throw new Error(`No field update parser for ${source} in ${attributes.originalSource}, available prefix: ${Object.keys(handlerMap)}`);
        }
        return fallback(state$, attributes, source, value);
      }
      return handler(state$, attributes, chopSourcePrefix(source, prefixSource), value);
    }

    return {
      subParser: <U>(selector: SourceSelector<T, U>, subContent: (builder: UpdateParserBuilder<U, A>) => void) => {
        const subParserPrefix = parseSelector(selector);
        const subParserBuilder = innerCreateUpdateParserBuilder<U, A>();
        subContent(subParserBuilder);
        handlerMap[subParserPrefix] = subParserBuilder.getParser();
      },
      nodesHandlerByIndex: <U extends { id: string }>(selector: SourceSelector<T, U[]>, handler: (state$: StateObservable<any>, attributes: ParseAttributes<A>, index: number, source: string, value: any) => BatchedDebouncedUpdateRequest<any, any>) => {
        const subHandlerPrefix = parseSelector(selector);
        handlerMap[subHandlerPrefix] = (state$: StateObservable<any>, attributes: ParseAttributes<A>, source: string, value: any): BatchedDebouncedUpdateRequest<any, any> => {
          const { index, followingSource } = extractIndexFromSource(source);
          return handler(state$, attributes, index, followingSource, value);
        };
      },
      handler: <U>(selector: SourceSelector<T, U>, handler: (state$: StateObservable<any>, attributes: ParseAttributes<A>, source: string, value: any) => BatchedDebouncedUpdateRequest<any, any>) => {
        const subHandlerPrefix = parseSelector(selector);
        handlerMap[subHandlerPrefix] = handler;
      },
      ignore: <U>(selector: SourceSelector<T, U>) => {
        const subHandlerPrefix = parseSelector(selector);
        handlerMap[subHandlerPrefix] = () => null;
      },
      fallback(handler: (state$: StateObservable<any>, attributes: ParseAttributes<A>, source: string, value: any) => BatchedDebouncedUpdateRequest<any, any>) {
        fallback = handler;
      },
      getParser: () => updateParser,
    };
  }

  const builder = innerCreateUpdateParserBuilder<O, NA>();
  content(builder);
  return builder.getParser();
};

export interface BatchedDebouncedUpdateRequest<T, R> {
  /**
   * Provide the request function from sdk
   */
  requestFnProvider: (sdk: CmsSDKObservables) => (param: { input: T }) => Observable<R>;
  /**
   * Map the resp to action, e.g. update the data if needed
   */
  postRequestAction?: (resp: R) => Observable<Action>;
  /**
   * The update which have the same identifier will be batched
   */
  batchIdentifier: string;
  /**
   * Batched field updates
   */
  fields: { [source: string]: any };
  /**
   * Use for apply attributes
   * @param input
   */
  inputModifier: (input: T) => T;
  lessDebounce?: boolean;
}
