import React, {
  FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { Popup } from 'utils/xtra-popups/popup';
import UnDrawProcessing from 'assets/img/common/undraw/undraw_processing_qj6a.svg';
import {
  BulkTaskProgress,
  BulkTasksProgressDialogData,
  BulkTaskState,
} from 'utils/xtra-popups/popupsTypes/bulkTasksProgressDialogData';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Icon, LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import cmsActions from 'redux/cmsActions';
import {
  Done, Error, Schedule, Sync,
} from '@material-ui/icons';
import styled from 'styled-components';

const StyledText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledDone = styled(Done)`
  color: #009818
`;

const StyledError = styled(Error)`
  color: #d70000
`;

const StyledInProgress = styled(Sync)`
  color: #ffe247
`;

const StyledItem = styled.div`
  display: flex;
  width: 100%;
  overflow: hidden;
  padding: 4px;
`;

const StyledIcon = styled(Icon)`
  margin-right: 8px;
`;

const XtraBulkTaskProgressRow: FC<{ task: BulkTaskProgress }> = React.memo(({ task }) => {
  const icon = useMemo(() => {
    switch (task.state) {
      case BulkTaskState.Waiting:
        return <Schedule />;
      case BulkTaskState.InProgress:
        return <StyledInProgress />;
      case BulkTaskState.Done:
        return <StyledDone />;
      case BulkTaskState.Error:
        return <StyledError />;
      default:
        return <div />;
    }
  }, [task.state]);
  return (
    <StyledItem>
      <StyledIcon>{icon}</StyledIcon>
      <StyledText>{task.name}</StyledText>
    </StyledItem>
  );
});

const StyledList = styled(List)`
  overflow-y: auto;
  overflow-x: hidden;
`;

const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  
  & > * {
    flex: 0 0 50%;
  }
`;

const StyledProgressContainer = styled.div`
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledDialog = styled(Dialog)`
  & .MuiPaper-root {
    max-height: 600px;
    height: 100%;
  }
`;

const StyledRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
`;

const StyledPercentageText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledImg = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 32px;
`;

const StyledLinearProgress = styled(LinearProgress)`
  width: 100%;
`;

export interface XtraBulkTasksProgressDialogProps {
  popup: Popup<BulkTasksProgressDialogData>
}

const XtraBulkTasksProgressDialog: FC<XtraBulkTasksProgressDialogProps> = ({ popup: { id, data } }) => {
  const dispatch = useDispatch();

  const listRef = useRef<HTMLUListElement>();

  const { cancelled } = data;

  const [open, setOpen] = useState(true);
  const onCancel = useCallback(() => {
    dispatch(data.cancelAction);
  }, [data.cancelAction, dispatch]);

  useEffect(() => {
    if (open && cancelled) {
      setOpen(false);
      setTimeout(() => dispatch(cmsActions.popups.removePopup(id)), 1000);
    }
  }, [cancelled, dispatch, id, open]);

  const onDone = useCallback(() => {
    if (data.doneAction != null) dispatch(data.doneAction);
    setOpen(false);
    setTimeout(() => dispatch(cmsActions.popups.removePopup(id)), 1000);
  }, [data.doneAction, dispatch, id]);

  const errorTasks = useMemo(() => data.tasksProgress.filter((it) => it.state === BulkTaskState.Error), [data.tasksProgress]);
  const endedTasks = useMemo(() => data.tasksProgress.filter((it) => it.state === BulkTaskState.Error || it.state === BulkTaskState.Done), [data.tasksProgress]);
  const inProgressTasks = useMemo(() => data.tasksProgress.filter((it) => it.state === BulkTaskState.InProgress), [data.tasksProgress]);

  const endedPercentage = useMemo(() => (Math.round(((endedTasks.length) / data.tasksProgress.length) * 100)), [data.tasksProgress.length, endedTasks.length]);

  useEffect(() => {
    if (listRef.current != null) {
      listRef.current.scrollTo({ top: listRef.current.scrollHeight });
    }
  }, [endedTasks, inProgressTasks]);

  return (
    <StyledDialog open={open} maxWidth="md" fullWidth>
      <DialogTitle>{data.title}</DialogTitle>
      <StyledDialogContent>
        <StyledList dense ref={listRef}>
          {endedTasks.map((task) => <XtraBulkTaskProgressRow key={task.id} task={task} />)}
          {inProgressTasks.map((task) => <XtraBulkTaskProgressRow key={task.id} task={task} />)}
        </StyledList>
        <StyledRightContainer>
          <StyledImg src={UnDrawProcessing} alt="processing" />
          <StyledProgressContainer>
            {
              !data.finished ? (
                <>
                  <StyledPercentageText>
                    {`${endedPercentage}%`}
                  </StyledPercentageText>
                  <StyledLinearProgress variant="determinate" value={endedPercentage} />
                </>
              ) : (
                <>
                  {`Tasks finished. ${errorTasks.length === 0 ? 'All tasks executed successfully.' : `${errorTasks.length} tasks failed.`}`}
                </>
              )
            }
          </StyledProgressContainer>
        </StyledRightContainer>
      </StyledDialogContent>
      <DialogActions>
        {
          data.finished ? (
            <Button onClick={onDone} color="primary" variant="contained" autoFocus>
              Done
            </Button>
          ) : (
            data.cancelAction && (
              <Button onClick={onCancel} color="primary" autoFocus>
                Cancel
              </Button>
            )
          )
        }
      </DialogActions>
    </StyledDialog>
  );
};

export default XtraBulkTasksProgressDialog;
