import axios from 'axios';
import { defer, of } from 'rxjs';
import { EVENT_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { createStandardEventEditRequestBundle } from 'admin-data/event/EventEdit/redux/createStandardEventEditRequestBundle';
import { ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { map } from 'rxjs/operators';
import { FIRESTORE_ENDPOINT } from 'config';
import { Auditorium } from 'extra-models/auditorium';

const bundleEventAuditoriumUpdate = createStandardEventEditRequestBundle<Auditorium, Auditorium>({
  actionName: `${EVENT_EDIT_ACTIONS_NAME}/auditorium/bundleEventAuditoriumUpdate`,
  additionalTaskTags: [ExecutingTaskCommonTag.Mutation],
  isSequenced: true,

  requestFactory: ({
    eventDomain, locale, authToken, payload,
  }) => defer(() => axios.put(`${FIRESTORE_ENDPOINT}/events/${eventDomain}/auditoriums/${payload.id}`, {
    /* data, e.g. payload */
  }, {})).pipe(map((resp) => resp.data)),

  requestResponseMapper: ({
    response, createUpdateEventEditAction,
  }) => of(createUpdateEventEditAction((eventEditState) => {
    /* What to do after response? */
  })),
});

export default bundleEventAuditoriumUpdate;
