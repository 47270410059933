import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, reduce,
} from 'rxjs/operators';
import { concat, merge, of } from 'rxjs';
import { Event } from 'models';
import { fromPromise } from 'rxjs/internal-compatibility';
import { getAvailableDesigns } from 'admin-data/booth/BoothEdit/BoothFragments/BoothLegacyDesignFragment/template-selector/BoothLegacyTemplateSelector';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { EventEditBoothsFragmentBoothCreationConfigs } from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/eventEditBoothsFramgnetState';
import cmsSelectors from 'redux/cmsSelectors';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';

const boothCreationInitialState = {
  booths: null,
  boothsCreateProgress: null,
  configs: null,
  show: true,
};

const boothCreationInitializeEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.fragments.booths.boothCreation.initialize.match),
  mergeMap(() => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const { locales, id: eventId } = eventEditSelectors.selectEvent(state$.value);

    const fetchAvailableOptions = merge(
      sdk.getHallList({ eventId, locales })
        .pipe(map(({ node }) => ({
          availableHalls: (node as Event).halls.nodes,
          hallId: (node as Event).halls.nodes[0]?.id,
        } as EventEditBoothsFragmentBoothCreationConfigs))),

      sdk.getTierList({ eventId, locales })
        .pipe(map(({ node }) => ({
          availableBoothTiers: (node as Event).boothTiers.nodes,
          boothTierId: (node as Event).boothTiers.nodes[0]?.id,
        } as EventEditBoothsFragmentBoothCreationConfigs))),

      fromPromise(getAvailableDesigns(eventId))
        .pipe(map(((availableDesigns) => ({
          availableDesigns,
          appliedDesignCodename: availableDesigns[0],
        } as EventEditBoothsFragmentBoothCreationConfigs)))),
    );

    return concat(
      of(eventEditActions.fragments.booths.boothCreation.updateCreation(boothCreationInitialState)),
      fetchAvailableOptions.pipe(
        reduce((configs, next) => ({ ...configs, ...next }), {} as EventEditBoothsFragmentBoothCreationConfigs),
        map((configs) => eventEditActions.fragments.booths.boothCreation.updateCreation({
          ...boothCreationInitialState,
          configs: { ...configs, amount: 1 },
        })),
      ),
    );
  }),
);

export default boothCreationInitializeEpic;
