import { Epic } from 'redux-observable';
import { filter, map, mergeMap } from 'rxjs/operators';
import { rawDesignEditorInstanceActions } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceActions';
import designEditorSelectors from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorSelectors';
import { concat, defer, of } from 'rxjs';
import designEditorActions from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorActions';
import produce from 'immer';
import cmsSelectors from 'redux/cmsSelectors';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { BoothDesign } from 'models';
import { eventEditMutationCompleteObservable } from 'admin-data/event/EventEdit/redux/epic/utils';

const queryDesignEpic: Epic = (action$, state$) => action$.pipe(
  filter(rawDesignEditorInstanceActions.queryDesign.match),
  mergeMap(({ payload: { instanceDesignId } }) => {
    const getCurrentDesignEditorInstanceState = () => designEditorSelectors.selectDesignInstanceByDesignId(instanceDesignId)(state$.value);

    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const locales = [eventEditSelectors.selectEditLocale(state$.value)];

    const request = sdk.designEditorGetboothDesignById({
      id: instanceDesignId,
      locales,
    }).pipe(
      map((resp) => designEditorActions.internalUpsertEditorInstance(
        produce(getCurrentDesignEditorInstanceState(), (draft) => {
          draft.design = resp.node as BoothDesign;
        }),
      )),
    );

    return concat(
      defer(() => of(designEditorActions.internalUpsertEditorInstance(
        produce(getCurrentDesignEditorInstanceState(), (draft) => {
          draft.isLoading = true;
        }),
      ))),
      eventEditMutationCompleteObservable(action$, state$),
      request,
      defer(() => of(designEditorActions.internalUpsertEditorInstance(
        produce(getCurrentDesignEditorInstanceState(), (draft) => {
          draft.isLoading = false;
        }),
      ))),
    );
  }),
);

export default queryDesignEpic;
