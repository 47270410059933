import {
  BoothCategoryUpdateInput, BoothSupercategoryUpdateInput, Event, EventUpdateInput,
} from 'models';
import { createUpdateParserBuilder } from 'utils/redux-field-bind/updateParser/updateParserBuilder';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import eventRootMasterElementTabUpdateParser
  from 'admin-data/event/EventEdit/redux/updateParsers/masterTab/eventRootMasterElementTabUpdateParser';
import eventBoothTierUpdateParser
  from 'admin-data/event/EventEdit/redux/updateParsers/boothTier/eventBoothTierUpdateParser';
import { get } from 'lodash';
import { ProductCategory } from 'models-product';
import { from } from 'rxjs';
import axios from 'axios';
import { CMS_PRODUCT_API_ENDPOINT } from 'config';

const eventUpdateParser = createUpdateParserBuilder<Event, { eventId: string }>((builder0) => {
  builder0.fallback(((state$, attributes, source, value) => {
    const eventId = eventEditSelectors.selectEvent(state$.value).id;

    return {
      requestFnProvider: (sdk) => sdk.eventUpdate,
      batchIdentifier: `eventUpdate-${eventId}`,
      fields: { [source]: value },
      inputModifier: (input: EventUpdateInput) => ({
        ...input,
        id: eventId,
      } as EventUpdateInput),
    };
  }));

  builder0.nodesHandlerByIndex((event) => event.rootMasterElement.tabs.nodes,
    ((state$, attributes, index, source, value) => {
      const tabMasterElement = eventEditSelectors.selectEvent(state$.value).rootMasterElement.tabs.nodes[index];
      return eventRootMasterElementTabUpdateParser(state$, {
        ...attributes,
        tabMasterElement,
      }, source, value);
    }));

  builder0.nodesHandlerByIndex((event) => event.boothTaxonomies.nodes,
    ((state$, attributes, index, source, value) => {
      const taxonomy = eventEditSelectors.selectEvent(state$.value).boothTaxonomies.nodes[index];

      if (taxonomy.__typename === 'BoothSupercategory') {
        return {
          requestFnProvider: (sdk) => sdk.boothSupercategoryUpdate,
          batchIdentifier: `boothSupercategoryUpdate-${taxonomy.id}`,
          fields: { [source]: value },
          inputModifier: (input: BoothSupercategoryUpdateInput) => ({
            ...input,
            supercategoryId: taxonomy.id,
            eventId: attributes.eventId,
          } as BoothSupercategoryUpdateInput),
        };
      }

      return {
        requestFnProvider: (sdk) => sdk.boothCategoryUpdate,
        batchIdentifier: `boothCategoryUpdate-${taxonomy.id}`,
        fields: { [source]: value },
        inputModifier: (input: BoothCategoryUpdateInput) => ({
          ...input,
          categoryId: taxonomy.id,
          eventId: attributes.eventId,
        } as BoothCategoryUpdateInput),
      };
    }));

  builder0.nodesHandlerByIndex((event) => event.boothTiers.nodes,
    (state$, attributes, index, source, value) => {
      const boothTier = eventEditSelectors.selectEvent(state$.value).boothTiers.nodes[index];
      return eventBoothTierUpdateParser(state$,
        {
          ...attributes,
          boothTier,
        }, source, value);
    });

  builder0.handler((event) => event.productSettings.categories,
    (state$, attributes, source, value) => {
      const regexGroups = /(.+)\.(parentCategory|label(?:\..*)?|)$/.exec(source);
      if (regexGroups == null) throw new Error(`Illegal product category update source: ${source}`);
      const [, categorySource, categoryFieldSource] = regexGroups ?? [];
      const event = eventEditSelectors.selectEvent(state$.value);
      const productCategory = get(event.productSettings.categories, categorySource) as ProductCategory;

      return {
        requestFnProvider: () => (param) => from(
          axios.put(`${CMS_PRODUCT_API_ENDPOINT}/categories/${productCategory.id}`, param.input, {
            headers: {
              'x-eventxtra-admin': true,
            },
          }),
        ),
        batchIdentifier: `productCategoryUpdate-${productCategory.id}`,
        fields: { [categoryFieldSource]: value },
        inputModifier: (input: any) => input,
      };
    });
});

export default eventUpdateParser;
