import { Epic } from 'redux-observable';
import {
  filter, mergeMap, takeUntil,
} from 'rxjs/operators';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import { concat, defer, of } from 'rxjs';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { BannerContainerResponse, BannerContainer, BannerAsset } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import { FIRESTORE_ENDPOINT } from 'config';

const fetchBannerAssetsEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.fetchBannerAssets.match),
  mergeMap(() => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const fetchUrl = `${FIRESTORE_ENDPOINT}/bannerAssets?event_domain=${domain}`;
    const fetchBannerAssets = defer(() => fetch(fetchUrl));
    return concat(
      of(eventSettingEditActions.areBannerAssetsLoading(true)),
      fetchBannerAssets.pipe(
        mergeMap((resp) => resp.json() as Promise<{ bannerAssets: BannerContainerResponse[] }>),
        mergeMap(({ bannerAssets }) => {
          const bannerAssetArr: BannerAsset[] = bannerAssets.reduce((accumulator, { bannerAsset }) => {
            bannerAsset.forEach((asset) => {
              accumulator.push(asset);
            });
            return accumulator;
          }, []);
          const bannerAssetContainerEntities: BannerContainer[] = bannerAssets.map((container) => ({
            ...container,
            bannerAsset: container.bannerAsset.map((asset) => asset.id),
          }));
          return [
            eventSettingEditActions.setBannerAssets(bannerAssetArr),
            eventSettingEditActions.setBannerAssetContainers(bannerAssetContainerEntities),
          ];
        }),
      ),
      of(eventSettingEditActions.areBannerAssetsLoading(false)),
    ).pipe(
      takeUntil(action$.pipe(filter(eventSettingEditActions.fetchBannerAssets.match))),
    );
  }),
);

export default fetchBannerAssetsEpic;
