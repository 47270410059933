import { useContext, useMemo } from 'react';
import { ContentLocalizationContext } from 'components/localization/ContentLocalizationContext';

type Localizable<T> = { values?: { locale: string, value?: T }[] };
export const extractByLocale = <T extends any>(localizable: Localizable<T>, locale: string, fallbackLocale?: string): T => {
  if (localizable == null) return null;
  return (
    localizable.values?.find((entry) => entry.locale === locale)
    ?? (fallbackLocale == null
      ? null
      : localizable.values?.find((entry) => entry.locale === fallbackLocale))
  )?.value;
};

export const useExtractByLocale = <T extends any>(localizable: Localizable<T>, locale: string, fallbackLocale?: string) => useMemo(() => extractByLocale(localizable, locale, fallbackLocale), [fallbackLocale, locale, localizable]);

export const useExtractByContentLocale = <T extends any>(localizable: Localizable<T>, fallback = false) => {
  const { locale, defaultLocale } = useContext(ContentLocalizationContext);
  return useExtractByLocale(localizable, locale, fallback ? defaultLocale : null);
};
