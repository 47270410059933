import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, tap,
} from 'rxjs/operators';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import { localizableFieldDefaultLocalePatcher, mapToSimpleMergeBoothAction, boothEditMutationCompleteObservable } from 'admin-data/booth/BoothEdit/redux/epics/queries/utils';
import { createExecutingTask } from 'utils/xtra-executing-tasks/executingTask';
import { boothEditExecutingTasksTagCreator } from 'admin-data/booth/BoothEdit/redux/boothEditExecutingTasks';
import { fetchAllPage } from 'utils/requestsUtils';
import { Booth } from 'models';
import { concat } from 'rxjs';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import cmsSelectors from 'redux/cmsSelectors';

const defaultDesignElementKindPatcher = ({ node }) => {
  (node as Booth).designElements.nodes.forEach((it) => {
    // eslint-disable-next-line prefer-destructuring
    if (it.kind == null) it.kind = it.validKinds[0];
  });
};

const queryLegacyDesignElementsEpic: Epic = (action$, state$) => action$.pipe(
  filter(boothEditActions.queries.queryLegacyDesignElements.match),
  mergeMap(() => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const boothId = boothEditSelectors.selectBoothId(state$.value);
    const originalBoothFactory = () => boothEditSelectors.selectBooth(state$.value);
    const locale = boothEditSelectors.selectLocale(state$.value);

    const taskFactory = () => createExecutingTask({ tags: [boothEditExecutingTasksTagCreator.queries.queryLegacyDesignElements()] });

    const requests = fetchAllPage(
      (after) => sdk.boothEditGetLegacyDesignElements({ boothId, locales: [locale], after }),
      (resp) => (resp.node as Booth).designElements.pageInfo,
    ).pipe(
      throwCommonError(),
      tap((resp) => localizableFieldDefaultLocalePatcher([locale], resp)),
      tap(defaultDesignElementKindPatcher),
      map(mapToSimpleMergeBoothAction(originalBoothFactory)),
      catchErrorToPopup(state$.value.intl),
    );

    return concat(
      boothEditMutationCompleteObservable(action$, state$),
      requests,
    ).pipe(boothEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory));
  }),
);

export default queryLegacyDesignElementsEpic;
