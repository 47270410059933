export interface WebinarTrack {
  id: string;
  color: string;
  sort: number;
  translations: {
    [locale: string]: {
      name: string;
      language: string;
    };
  };
}

export enum WebinarKind {
  Live = 'time_framed',
  OnDemand = 'on_demand'
}

export interface WebinarContact {
  contact: string;
  type: 'email' | 'phone';
}

export interface WebinarSpeaker {
  id: string;
  sort: number;
  profile_picture_url: string;
  translations: {
    [locale: string]: {
      name: string;
      company: string;
      title: string;
      bio: string;
    };
  };
  contacts: WebinarContact[];
}

export interface Webinar {
  id: string;
  sort: number;
  status: string;
  webinar_day: string;
  start_time: string;
  end_time: string;
  timezone: string;
  kind: WebinarKind;
  speakers: string[];
  track: string;
  translations: {
    [locale: string]: {
      title: string;
      description: string;
      url: string;
    };
  };
  auditoriumId?: string;
}
