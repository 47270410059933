import { Epic } from 'redux-observable';
import {
  bufferTime,
  debounce,
  debounceTime,
  filter, first, ignoreElements, map, mergeMap, switchMap, take, takeUntil,
} from 'rxjs/operators';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import cmsSelectors from 'redux/cmsSelectors';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { createExecutingTask } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import {
  eventEditMutationCompleteObservable,
} from 'admin-data/event/EventEdit/redux/epic/utils';
import {
  concat, defer, from, interval, Observable, of,
} from 'rxjs';
import { Action } from 'redux';
import { BoothTier } from 'models';
import produce from 'immer';
import { fetchAllEntitiesByOffset } from 'utils/requestsUtils';

const triggerAction = eventEditActions.fragments.booths.queries.queryPagedBoothsListByCurrentTierId;

const queryPagedBoothsListByCurrentTierIdEpic: Epic = (action$, state$) => action$.pipe(
  filter(triggerAction.match),
  mergeMap(() => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const locales = [eventEditSelectors.selectEditLocale(state$.value)];
    const tierId = eventEditSelectors.fragments.booths.selectCurrentTierId(state$.value);
    const originalEventEditFactory = () => eventEditSelectors.selectEventEdit(state$.value);

    const pageSize = eventEditSelectors.fragments.booths.selectPageSize(state$.value);
    const pageNumber = eventEditSelectors.fragments.booths.selectPageNumber(state$.value);
    const offset = pageNumber * pageSize;

    const taskFactory = () => createExecutingTask({ tags: [eventEditExecutingTasksTagCreator.fragments.booths.queries.queryPagedBoothsListByCurrentTierId()] });

    const requests: Observable<Action> = fetchAllEntitiesByOffset(
      (after, windowSize) => sdk.eventEditGetBoothsListByTierId({
        locales,
        tierId,
        pageSize: windowSize,
        after,
      }),
      (resp) => (resp.node as BoothTier).booths.totalCount,
      (resp) => (resp.node as BoothTier).booths.nodes,
      offset,
      pageSize,
      40,
      2,
    ).pipe(
      take(pageSize), // avoid excess items
      bufferTime(500),
      mergeMap((booths) => defer(() => [
        eventEditActions.internalUpdate(produce(originalEventEditFactory(), (draft) => {
          draft.fragments.boothsV2.pagedBoothsList.push(...booths);
        })),
      ])),
      takeUntil(action$.pipe(filter(triggerAction.match), first())), // fetch until another fetch started
    ).pipe(eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory));

    return concat(
      eventEditMutationCompleteObservable(action$, state$), // wait all mutation complete
      defer(() => of(eventEditActions.internalUpdate(produce(originalEventEditFactory(), (draft) => {
        draft.fragments.boothsV2.pagedBoothsList = [];
      })))),
      requests,
    );
  }),
);

export default queryPagedBoothsListByCurrentTierIdEpic;
