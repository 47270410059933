import styled from 'styled-components';
import DesignEditorPositionedCanvas from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/DesignEditorInstanceCanvas/positioning/DesignEditorPositionedCanvas';

export const NoPointerEventCanvas = styled(DesignEditorPositionedCanvas)`
  pointer-events: none;
`;

// eslint-disable-next-line import/prefer-default-export
export const SizeControlPoint = styled.div`
  position: absolute;
  border: black 1px solid;
  width: 10px;
  height: 10px;
`;

export const TransformBorder = styled.div`
  position: absolute;
  transform: translate(-50%,-50%);
  border: 1px solid black;
  cursor: move;
  pointer-events: all;
`;

export const TransformBorderInner = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const SizingWidthParameter = styled.div`
  position:absolute;
  left: 50%;
  top: -4px;
  transform: translate(-50%,-100%);
  color: #5a5a5a;
`;

export const SizingHeightParameter = styled.div`
  position:absolute;
  left: -4px;
  top: 50%;
  transform: translate(-100%,-50%);
  color: #5a5a5a;
`;
