import React, { FC, useCallback, useContext, useMemo } from 'react';
import { AspectRatioFieldFormControl } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorTransformDocker/style';
import { IconButton, Input, InputAdornment, InputLabel, Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import DesignEditorInstanceContext from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';
import produce from 'immer';
import { faExpandAlt, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import MuiSizeFaIcon from 'components/MuiSizeFaIcon';
import { DockerCommonInnerContent } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/style';
import { to2dp } from 'libs/xtra-custom-booth-design/utils/maths';
import { BoothDesignColorFillMasterElement, BoothDesignImageButtonMasterElement, BoothDesignImageMasterElement, BoothDesignMasterElement } from 'models';
import { LayerElementType } from 'libs/xtra-custom-booth-design/types/DesignEditorInstance';
import { concat, of } from 'rxjs';
import { ContentLocalizationContext } from 'components/localization/ContentLocalizationContext';
import { extractByLocale } from 'utils/localizableUtil';
import { useObservable } from 'react-use';
import { fetchImageAspectRatio } from 'utils/mediaUtils';
import { FormattedMessage, useIntl } from 'react-intl';

const useLayerContentAspectRatio = (layer: BoothDesignMasterElement) => {
  const { locale } = useContext(ContentLocalizationContext);
  const targetFileUrl = useMemo(() => {
    switch (layer?.__typename) {
      case LayerElementType.BoothDesignImageMasterElement:
        return extractByLocale((layer as BoothDesignImageMasterElement).image, locale)?.originalImageUrl;
      case LayerElementType.BoothDesignColorFillMasterElement:
        return extractByLocale((layer as BoothDesignColorFillMasterElement).maskImage, locale)?.originalImageUrl;
      case LayerElementType.BoothDesignImageButtonMasterElement:
        return extractByLocale((layer as BoothDesignImageButtonMasterElement).normalStateImage, locale)?.originalImageUrl;
      default:
        return null;
    }
  }, [layer, locale]);
  return useMemo(() => (targetFileUrl === null ? of(0) : fetchImageAspectRatio(targetFileUrl)), [targetFileUrl]);
};

const DesignEditorAspectRatioSettings: FC = () => {
  const { actions, selectors, designId } = useContext(DesignEditorInstanceContext);
  const dispatch = useDispatch();
  const selectedLayersBoundingBox = useSelector(selectors.selectSelectedLayerBoundingBox);
  const selectedLayers = useSelector(selectors.selectSelectedLayers);

  const width = useMemo(() => selectedLayersBoundingBox.b.x - selectedLayersBoundingBox.a.x, [selectedLayersBoundingBox]);
  const height = useMemo(() => selectedLayersBoundingBox.b.y - selectedLayersBoundingBox.a.y, [selectedLayersBoundingBox]);

  const canvasAspectRatio = useSelector(selectors.selectCanvasAspectRatio).dimension;

  const aspectRatio = useMemo(() => `${to2dp((width / height) * (canvasAspectRatio / 100) * 100)}%`, [height, width]);

  const toolConfig = useSelector(selectors.selectToolConfig);
  const { aspectRatioLock } = toolConfig.transform;

  const toggleAspectRatioLock = useCallback(() => {
    dispatch(actions.updateToolConfig(produce(toolConfig, (draft) => {
      draft.transform.aspectRatioLock = !aspectRatioLock;
    })));
  }, [actions, aspectRatioLock, dispatch, toolConfig]);

  const firstLayer = useMemo(() => selectedLayers[0], [selectedLayers]);
  const firstLayerCurrentAspectRatio = useMemo(() => (firstLayer.width.dimension / firstLayer.height.dimension) * 100 * (canvasAspectRatio / 100), [canvasAspectRatio, firstLayer.height.dimension, firstLayer.width.dimension]);
  const firstLayerContentAspectRatioObservable = useLayerContentAspectRatio(firstLayer);
  const firstLayerContentAspectRatio = useObservable(useMemo(() => concat(of(0), firstLayerContentAspectRatioObservable), [firstLayerContentAspectRatioObservable]), 0);

  const setFirstLayerAspectRatio = useCallback((targetAspectRatio: number) => {
    dispatch(actions.updateLayer(produce(firstLayer, (draft) => {
      const offsetAspectRatio = targetAspectRatio - firstLayerCurrentAspectRatio;
      if (offsetAspectRatio > 0) draft.width.dimension = (firstLayer.height.dimension * targetAspectRatio) / (canvasAspectRatio / 100);
      else if (offsetAspectRatio < 0) draft.height.dimension = (firstLayer.width.dimension / targetAspectRatio) * (canvasAspectRatio / 100);
    })));
  }, [actions, canvasAspectRatio, dispatch, firstLayer, firstLayerCurrentAspectRatio]);

  const applyContentAspectRatio = useCallback(() => {
    setFirstLayerAspectRatio(firstLayerContentAspectRatio);
  }, [firstLayerContentAspectRatio, setFirstLayerAspectRatio]);

  const intl = useIntl();

  return (
    <DockerCommonInnerContent>
      <AspectRatioFieldFormControl fullWidth>
        <InputLabel>
          <FormattedMessage id="event.design.common.aspect_ratio" defaultMessage="Aspect Ratio" />
        </InputLabel>
        <Input
          value={aspectRatio}
          disabled
          endAdornment={(
            <InputAdornment position="end">
              {selectedLayers.length === 1 && firstLayerContentAspectRatio !== 0 && (
                <Tooltip title={intl.formatMessage({ id: 'event.design.tool.transform.apply_content_aspect_ratio', defaultMessage: 'Apply content aspect ratio' })} arrow>
                  <IconButton onClick={applyContentAspectRatio} edge="end" size="small">
                    <MuiSizeFaIcon icon={faExpandAlt} />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                title={aspectRatioLock
                  ? intl.formatMessage({ id: 'event.design.tool.transform.unlock_aspect_ratio', defaultMessage: 'Unlock aspect ratio' })
                  : intl.formatMessage({ id: 'event.design.tool.transform.lock_aspect_ratio', defaultMessage: 'Lock aspect ratio' })}
                arrow
              >
                <IconButton
                  onClick={toggleAspectRatioLock}
                  edge="end"
                  size="small"
                >
                  <MuiSizeFaIcon icon={aspectRatioLock ? faLock : faLockOpen} />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          )}
        />
      </AspectRatioFieldFormControl>
    </DockerCommonInnerContent>
  );
};

export default React.memo(DesignEditorAspectRatioSettings);
