const SORT_DIFF_CONST = 65536;

const computeSort = (
  sourceIndex: number,
  destIndex: number,
  sortValues: number[],
) => {
  if (sourceIndex > destIndex) {
    return !destIndex
      ? sortValues[destIndex] - SORT_DIFF_CONST
      : Math.floor(sortValues[destIndex - 1] * 0.5 + sortValues[destIndex] * 0.5);
  }
  if (sourceIndex < destIndex) {
    return destIndex === sortValues.length - 1
      ? sortValues[destIndex] + SORT_DIFF_CONST
      : Math.floor(sortValues[destIndex + 1] * 0.5 + sortValues[destIndex] * 0.5);
  }
  return sortValues.length >= 3 && sourceIndex > 0
    ? sortValues[sourceIndex - 1] * 0.5 + sortValues[sourceIndex + 1] * 0.5
    : sortValues[sourceIndex];
};

export default computeSort;
