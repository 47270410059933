import {
  BoothTier, BoothTierUpdateInput,
} from 'models';
import {
  createUpdateParserBuilder,
} from 'utils/redux-field-bind/updateParser/updateParserBuilder';

const eventBoothTierUpdateParser = createUpdateParserBuilder<BoothTier, { eventId: string, boothTier: BoothTier }>((builder0) => {
  builder0.fallback(
    (state$, attributes, source, value) => ({
      requestFnProvider: (sdk) => sdk.boothTierUpdate,
      batchIdentifier: `boothTierUpdate-${attributes.boothTier.id}`,
      fields: { [source]: value },
      inputModifier: (input: BoothTierUpdateInput) => {
        const modified = ({
          ...input,
          eventId: attributes.eventId,
          boothTierId: attributes.boothTier.id,
        } as BoothTierUpdateInput);

        if (modified.scale?.dimension !== undefined) modified.scale.unit = attributes.boothTier.scale.unit;

        return modified;
      },
    }),
  );
});

export default eventBoothTierUpdateParser;
