import { createSlice } from '@reduxjs/toolkit';
import { DESIGN_EDITOR_STORE_NAME } from 'libs/xtra-custom-booth-design/redux/designEditorVars';
import designEditorActions from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorActions';
import {
  designEditorInitialState,
  DesignEditorState,
} from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorState';
import { BoothDesignMasterElementConnection, DisplayMeasurementUnit, Event } from 'models';
import { rawDesignEditorInstanceActions } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceActions';
import KeyMapType from 'libs/xtra-custom-booth-design/keymap/keyMapType';
import { tryOrNull } from 'libs/xtra-custom-booth-design/utils/fp';
import { defaultDockersLayout } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/defaultDockersLayout';
import CanvasTools
  from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/DesignEditorInstanceCanvas/canvasTools';
import { injectExecutingTasksExtraReducer } from 'utils/xtra-executing-tasks';

const dockersFramesPersistent = (dockersFrames) => {
  localStorage.setItem('design-editor/dockersFrames', JSON.stringify(dockersFrames));
};

const TODO = () => {
  throw new Error('TODO');
};

const designEditorSlice = createSlice({
  name: DESIGN_EDITOR_STORE_NAME,
  initialState: designEditorInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(designEditorActions.setEvent, (state, action) => {
      state.event = action.payload;
    });
    builder.addCase(designEditorActions.setActiveInstance, (state, action) => {
      state.activeInstanceDesignId = action.payload;
    });

    builder.addCase(designEditorActions.updateDockerFrameDockerIds, (state, action) => {
      state.dockersFrames[action.payload.dockerFrameId].dockerIds = action.payload.dockersIds as any;
      dockersFramesPersistent(state.dockersFrames);
    });

    builder.addCase(designEditorActions.updateDockerFrameInfoData, (state, action) => {
      state.dockersFrames[action.payload.dockerFrameId].data = action.payload.data;
      dockersFramesPersistent(state.dockersFrames);
    });

    builder.addCase(designEditorActions.changeSelectedCanvasTool, (state, action) => {
      state.selectedCanvasTool = action.payload;
    });

    builder.addCase(designEditorActions.internalRemoveEditorInstance, (state, action) => {
      delete state.instances[action.payload];
    });
    builder.addCase(designEditorActions.internalUpsertEditorInstance, (state, action) => {
      state.instances[action.payload.design.id] = action.payload;
    });

    builder.addCase(designEditorActions.internalUpdateDesignsListLoading, (state, action) => {
      state.boothDesignsListLoading = action.payload;
    });
    builder.addCase(designEditorActions.internalUpdateDesignsList, (state, action) => {
      state.boothDesignsList = action.payload;
    });

    // Instances actions ==============================

    builder.addCase(rawDesignEditorInstanceActions.internalUpdateCanvas, (state, action) => {
      state.instances[action.payload.instanceDesignId].design.canvas = action.payload.data;
    });

    builder.addCase(rawDesignEditorInstanceActions.internalUpdateLayers, (state, action) => {
      state.instances[action.payload.instanceDesignId].design.canvas.layers.nodes = action.payload.data;
    });

    builder.addCase(rawDesignEditorInstanceActions.internalUpdateLayer, (state, action) => {
      const layers = state.instances[action.payload.instanceDesignId].design.canvas.layers.nodes;
      const replacing = layers.findIndex((layer) => layer.id === action.payload.data.id);
      layers[replacing] = action.payload.data;
    });

    builder.addCase(rawDesignEditorInstanceActions.updateSelectedLayerIds, (state, action) => {
      state.instances[action.payload.instanceDesignId].selectedLayerIds = action.payload.data;
    });

    builder.addCase(rawDesignEditorInstanceActions.updateCanvasViewConfig, (state, action) => {
      state.instances[action.payload.instanceDesignId].canvasViewConfig = action.payload.data;
    });

    builder.addCase(rawDesignEditorInstanceActions.updateToolConfig, (state, action) => {
      state.instances[action.payload.instanceDesignId].toolConfig = action.payload.data;
    });
  },
});

export default designEditorSlice.reducer;
