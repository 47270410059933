import {
  BoothDividerMasterElement,
  BoothDividerMasterElementUpdateInput, BoothFileMasterElement,
  BoothFileMasterElementUpdateInput,
  BoothImageMasterElement,
  BoothImageMasterElementUpdateInput,
  BoothMasterElement,
  BoothQuestionMasterElement,
  BoothQuestionMasterElementUpdateInput, BoothRichtextMasterElement,
  BoothRichtextMasterElementUpdateInput,
  BoothVideoMasterElement,
  BoothVideoMasterElementUpdateInput,
} from 'models';
import {
  createUpdateParserBuilder,
} from 'utils/redux-field-bind/updateParser/updateParserBuilder';
import { BoothMasterElementType } from 'admin-data/event/EventEdit/typenameEnums';

const boothDividerMasterElementParser = createUpdateParserBuilder<BoothDividerMasterElement, { eventId: string, masterElement: BoothDividerMasterElement }>((builder0) => {
  builder0.fallback(((state$, attributes, source, value) => ({
    requestFnProvider: (sdk) => sdk.boothDividerMasterElementUpdate,
    batchIdentifier: `boothDividerMasterElementUpdate-${attributes.masterElement.id}`,
    fields: { [source]: value },
    inputModifier: (input: BoothDividerMasterElementUpdateInput) => ({
      ...input,
      eventId: attributes.eventId,
      elementId: attributes.masterElement.id,
    } as BoothDividerMasterElementUpdateInput),
  })));
});

const boothFileMasterElementParser = createUpdateParserBuilder<BoothFileMasterElement, { eventId: string, masterElement: BoothFileMasterElement }>((builder0) => {
  builder0.fallback(((state$, attributes, source, value) => ({
    requestFnProvider: (sdk) => sdk.boothFileMasterElementUpdate,
    batchIdentifier: `boothFileMasterElementUpdate-${attributes.masterElement.id}`,
    fields: { [source]: value },
    inputModifier: (input: BoothFileMasterElementUpdateInput) => ({
      ...input,
      eventId: attributes.eventId,
      elementId: attributes.masterElement.id,
    } as BoothFileMasterElementUpdateInput),
  })));
});

const boothImageMasterElementParser = createUpdateParserBuilder<BoothImageMasterElement, { eventId: string, masterElement: BoothImageMasterElement }>((builder0) => {
  builder0.fallback(((state$, attributes, source, value) => ({
    requestFnProvider: (sdk) => sdk.boothImageMasterElementUpdate,
    batchIdentifier: `boothImageMasterElementUpdate-${attributes.masterElement.id}`,
    fields: { [source]: value },
    inputModifier: (input: BoothImageMasterElementUpdateInput) => ({
      ...input,
      eventId: attributes.eventId,
      elementId: attributes.masterElement.id,
    } as BoothImageMasterElementUpdateInput),
  })));
});

const boothRichtextMasterElementParser = createUpdateParserBuilder<BoothRichtextMasterElement, { eventId: string, masterElement: BoothRichtextMasterElement }>((builder0) => {
  builder0.fallback(((state$, attributes, source, value) => ({
    requestFnProvider: (sdk) => sdk.boothRichtextMasterElementUpdate,
    batchIdentifier: `boothRichtextMasterElementUpdate-${attributes.masterElement.id}`,
    fields: { [source]: value },
    inputModifier: (input: BoothRichtextMasterElementUpdateInput) => ({
      ...input,
      eventId: attributes.eventId,
      elementId: attributes.masterElement.id,
    } as BoothRichtextMasterElementUpdateInput),
  })));
});

const boothQuestionMasterElementParser = createUpdateParserBuilder<BoothQuestionMasterElement, { eventId: string, masterElement: BoothQuestionMasterElement }>((builder0) => {
  builder0.fallback(((state$, attributes, source, value) => ({
    requestFnProvider: (sdk) => sdk.boothQuestionMasterElementUpdate,
    batchIdentifier: `boothQuestionMasterElementUpdate-${attributes.masterElement.id}`,
    fields: { [source]: value },
    inputModifier: (input: BoothQuestionMasterElementUpdateInput) => ({
      ...input,
      eventId: attributes.eventId,
      elementId: attributes.masterElement.id,
    } as BoothQuestionMasterElementUpdateInput),
  })));

  builder0.handler((questionMasterElement) => questionMasterElement.answer,
    // eslint-disable-next-line no-use-before-define
    (state$, attributes, source, value) => eventMasterElementUpdateParser(
      state$, {
        ...attributes,
        masterElement: attributes.masterElement.answer,
      }, source, value,
    ));
});

const boothVideoMasterElementParser = createUpdateParserBuilder<BoothVideoMasterElement, { eventId: string, masterElement: BoothVideoMasterElement }>((builder0) => {
  builder0.fallback(((state$, attributes, source, value) => ({
    requestFnProvider: (sdk) => sdk.boothVideoMasterElementUpdate,
    batchIdentifier: `boothVideoMasterElementUpdate-${attributes.masterElement.id}`,
    fields: { [source]: value },
    inputModifier: (input: BoothVideoMasterElementUpdateInput) => ({
      ...input,
      eventId: attributes.eventId,
      elementId: attributes.masterElement.id,
    } as BoothVideoMasterElementUpdateInput),
  })));
});

const eventMasterElementUpdateParser = createUpdateParserBuilder<BoothMasterElement, { eventId: string, masterElement: BoothMasterElement }>((builder0) => {
  const masterElementUpdateParsers = {
    [BoothMasterElementType.BoothDividerMasterElement]: boothDividerMasterElementParser,
    [BoothMasterElementType.BoothFileMasterElement]: boothFileMasterElementParser,
    [BoothMasterElementType.BoothImageMasterElement]: boothImageMasterElementParser,
    [BoothMasterElementType.BoothRichtextMasterElement]: boothRichtextMasterElementParser,
    [BoothMasterElementType.BoothQuestionMasterElement]: boothQuestionMasterElementParser,
    [BoothMasterElementType.BoothVideoMasterElement]: boothVideoMasterElementParser,
  };

  builder0.fallback(((state$, attributes, source, value) => {
    const parser = masterElementUpdateParsers[attributes.masterElement.__typename];

    if (parser == null) throw new Error(`No design v2 element update parser for ${attributes.masterElement.__typename}`);

    return parser(state$, attributes, source, value);
  }));
});

export default eventMasterElementUpdateParser;
