import { ActionReducerMapBuilder, createEntityAdapter } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import _ from 'lodash';
import { injectExecutingTasksExtraReducer } from 'utils/xtra-popups';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import { EventEditState } from 'admin-data/event/EventEdit/redux/eventEditState';
import { BannerAsset, BannerContainer, LobbyTemplate } from 'admin-data/event/EventEdit/redux/eventSettingEditState';

export const bannerAssetAdapter = createEntityAdapter<BannerAsset>();
export const bannerAssetContainerAdapter = createEntityAdapter<BannerContainer>();
export const lobbyTemplateAdapter = createEntityAdapter<LobbyTemplate>();

const eventSettingEditExtraReducer = (builder: ActionReducerMapBuilder<EventEditState>) => {
  builder.addCase(eventSettingEditActions.internalUpdateLandingPage, (state, action) => {
    state.fragments.eventSettings.landing = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateLoadingLanding, (state, action) => {
    state.fragments.eventSettings.fetchingState.landing = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateLoadingHall, (state, action) => {
    state.fragments.eventSettings.fetchingState.hall = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateHallPage, (state, action) => {
    state.fragments.eventSettings.hall = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateLobbyPage, (state, action) => {
    state.fragments.eventSettings.lobby = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateLoadingLobby, (state, action) => {
    state.fragments.eventSettings.fetchingState.lobby = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateGeneralSettings, (state, action) => {
    state.fragments.eventSettings.generalSetting = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateLoadingGeneralSettings, (state, action) => {
    state.fragments.eventSettings.fetchingState.generalSetting = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateChatroomConfig, (state, action) => {
    state.fragments.eventSettings.chatroomConfig = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateLoadingChatroomConfig, (state, action) => {
    state.fragments.eventSettings.fetchingState.chatroomConfig = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateFeatures, (state, action) => {
    state.fragments.eventSettings.featuresToggle = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateLoadingFeatures, (state, action) => {
    state.fragments.eventSettings.fetchingState.featuresToggle = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateFooter, (state, action) => {
    state.fragments.eventSettings.footer = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateLoadingFooter, (state, action) => {
    state.fragments.eventSettings.fetchingState.footer = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateAppbar, (state, action) => {
    state.fragments.eventSettings.appBarConfig = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateLoadingAppbar, (state, action) => {
    state.fragments.eventSettings.fetchingState.appBarConfig = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateProductSetting, (state, action) => {
    state.fragments.eventSettings.products = action.payload;
  });
  builder.addCase(eventSettingEditActions.internalUpdateLoadingProductSetting, (state, action) => {
    state.fragments.eventSettings.fetchingState.products = action.payload;
  });
  builder.addCase(eventSettingEditActions.setBannerAssets, (state, action) => {
    bannerAssetAdapter.setAll(state.fragments.eventSettings.bannerAssets, action.payload);
  });
  builder.addCase(eventSettingEditActions.setBannerAssetContainers, (state, action) => {
    bannerAssetContainerAdapter.setAll(state.fragments.eventSettings.bannerAssetContainers, action.payload);
  });
  builder.addCase(eventSettingEditActions.areBannerAssetsLoading, (state, action) => {
    state.fragments.eventSettings.fetchingState.bannerAssets = action.payload;
  });
  builder.addCase(eventSettingEditActions.internalDeleteAsset, (state, action) => {
    bannerAssetAdapter.removeOne(state.fragments.eventSettings.bannerAssets, action.payload.banner_asset_id);
    bannerAssetContainerAdapter.updateOne(state.fragments.eventSettings.bannerAssetContainers, {
      id: action.payload.banner_assets_id,
      changes: {
        bannerAsset: state.fragments.eventSettings.bannerAssetContainers.entities[action.payload.banner_assets_id].bannerAsset.filter((id) => id !== action.payload.banner_asset_id),
      },
    });
  });
  builder.addCase(eventSettingEditActions.internalAddAsset, (state, action) => {
    bannerAssetAdapter.addOne(state.fragments.eventSettings.bannerAssets, action.payload.asset);
    bannerAssetContainerAdapter.updateOne(state.fragments.eventSettings.bannerAssetContainers, {
      id: action.payload.bannerContainerId,
      changes: {
        bannerAsset: [...state.fragments.eventSettings.bannerAssetContainers.entities[action.payload.bannerContainerId].bannerAsset, action.payload.asset.id],
      },
    });
  });
  builder.addCase(eventSettingEditActions.internalUpdateAsset, (state, action) => {
    bannerAssetAdapter.updateOne(state.fragments.eventSettings.bannerAssets, {
      id: action.payload.id,
      changes: action.payload,
    });
  });
  builder.addCase(eventSettingEditActions.internalUpdateBannerAssetSort, (state, action) => {
    bannerAssetAdapter.updateMany(state.fragments.eventSettings.bannerAssets, action.payload.map(({ id, sort }) => ({
      id,
      changes: { desktopOrder: sort },
    })));
  });
  builder.addCase(eventSettingEditActions.setLobbyTemplates, (state, action) => {
    lobbyTemplateAdapter.upsertMany(state.fragments.eventSettings.lobbyTemplate, action.payload);
  });
  builder.addCase(eventSettingEditActions.areLobbyTemplatesLoading, (state, action) => {
    state.fragments.eventSettings.fetchingState.lobbyTemplates = action.payload;
  });
  builder.addCase(eventSettingEditActions.customPagesLoading, (state, action) => {
    state.fragments.eventSettings.fetchingState.customPage = action.payload;
  });
  builder.addCase(eventSettingEditActions.setCustomPages, (state, action) => {
    state.fragments.eventSettings.customPage = action.payload;
  });
  builder.addCase(eventSettingEditActions.setMaxCustomTabs, (state, action) => {
    state.fragments.eventSettings.maxCustomTabs = action.payload;
  });
  builder.addCase(eventSettingEditActions.pageNamesLoading, (state, action) => {
    state.fragments.eventSettings.fetchingState.pageNames = action.payload;
  });
  builder.addCase(eventSettingEditActions.setPageNames, (state, action) => {
    state.fragments.eventSettings.pageNames = action.payload;
  });
  builder.addCase(eventSettingEditActions.logosLoading, (state, action) => {
    state.fragments.eventSettings.fetchingState.logos = action.payload;
  });
  builder.addCase(eventSettingEditActions.setLogos, (state, action) => {
    state.fragments.eventSettings.logos = action.payload;
  });

  builder.addCase(eventSettingEditActions.internalUpdateEventSettingFields, (state, action) => {
    action.payload.fields.forEach((field) => {
      try {
        _.set(state.fragments.eventSettings, field.source, field.value);
      } catch (e) {
        console.error(e);
        throw new Error(`Cannot set field ${field.source}}`);
      }
    });
  });

  injectExecutingTasksExtraReducer(builder, eventSettingEditActions.executingTasks, (state) => state.executingTasks);
};

export default eventSettingEditExtraReducer;
