import React, { FC, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DesignEditorDockerComponent } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/designEditorDockerEntry';
import DesignEditorInstanceContext from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';
import Scrollbars from 'react-custom-scrollbars';
import { DockerCommonInnerContent } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/style';
import { StyledFormControl } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayerAttributesDocker/styles';
import {
  Input, InputAdornment, InputLabel, Typography,
} from '@material-ui/core';
import { BoothDesignCanvasMasterElement } from 'models';
import { tryOrNull } from 'libs/xtra-custom-booth-design/utils/fp';
import { FormattedMessage } from 'react-intl';

const isEnter = (e) => {
  if (e.key === undefined) return true;
  return e.key === 'Enter';
};

const DesignEditorCanvasAttributesFields: FC<Partial<BoothDesignCanvasMasterElement>> = ({ aspectRatio, maxWidth }) => {
  const dispatch = useDispatch();
  const { actions } = useContext(DesignEditorInstanceContext);

  const changeAspectRatio = useCallback((e) => {
    const newValue = tryOrNull(() => parseFloat(e.target.value));
    dispatch(actions.updateCanvas({ aspectRatio: { ...aspectRatio, dimension: newValue }, maxWidth }));
  }, [actions, aspectRatio, dispatch, maxWidth]);

  const changeMaxWidth = useCallback((e) => {
    dispatch(actions.updateCanvas({ maxWidth: { ...maxWidth, dimension: e.target.value }, aspectRatio }));
  }, [actions, aspectRatio, dispatch, maxWidth]);

  return (
    <>
      <StyledFormControl fullWidth>
        <InputLabel>
          <FormattedMessage id="event.design.common.aspect_ratio" defaultMessage="Aspect Ratio" />
        </InputLabel>
        <Input
          color="primary"
          type="number"
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          value={aspectRatio.dimension}
          onChange={changeAspectRatio}
        />
      </StyledFormControl>
      <StyledFormControl fullWidth>
        <InputLabel>
          <FormattedMessage id="event.design.common.max_width" defaultMessage="Max Width" />
        </InputLabel>
        <Input
          color="primary"
          value={maxWidth.dimension}
          type="number"
          endAdornment={<InputAdornment position="end">px</InputAdornment>}
          onChange={changeMaxWidth}
        />
      </StyledFormControl>
    </>
  );
};

const DesignEditorCanvasAttributesFieldsMemo = React.memo(DesignEditorCanvasAttributesFields);

const DesignEditorCanvasAttributesDocker: DesignEditorDockerComponent = (props) => {
  const { actions, selectors, designId } = useContext(DesignEditorInstanceContext);

  const { aspectRatio, maxWidth } = useSelector(selectors.selectCanvas);

  // eslint-disable-next-line no-underscore-dangle
  return (
    <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={1000}>
      <DockerCommonInnerContent>
        <DesignEditorCanvasAttributesFieldsMemo aspectRatio={aspectRatio} maxWidth={maxWidth} />
      </DockerCommonInnerContent>
    </Scrollbars>
  );
};

DesignEditorCanvasAttributesDocker.whyDidYouRender = true;

export default React.memo(DesignEditorCanvasAttributesDocker);
