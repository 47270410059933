import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, tap,
} from 'rxjs/operators';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import cmsSelectors from 'redux/cmsSelectors';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { createExecutingTask } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import {
  eventEditMutationCompleteObservable, mapToSimpleMergeEventAction,
} from 'admin-data/event/EventEdit/redux/epic/utils';
import {
  concat, defer, EMPTY, merge, Observable, of,
} from 'rxjs';
import { Action } from 'redux';
import { fetchAllPage } from 'utils/requestsUtils';
import { Event } from 'models';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';

const queryBoothTiersListEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.fragments.booths.queries.queryBoothTiersList.match),
  mergeMap(() => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const locales = [eventEditSelectors.selectEditLocale(state$.value)];
    const eventId = eventEditSelectors.selectEventId(state$.value);
    const originalEventFactory = () => eventEditSelectors.selectEventEdit(state$.value).event;

    const taskFactory = () => createExecutingTask({ tags: [eventEditExecutingTasksTagCreator.fragments.booths.queries.queryBoothTiersList()] });

    const requests: Observable<Action> = fetchAllPage(
      ((after) => sdk.eventEditGetBoothTiersList({ eventId, locales, after })),
      (resp) => (resp.node as Event).boothTiers.pageInfo,
    ).pipe(
      throwCommonError(),
      catchErrorToPopup(state$.value.intl),
      mergeMap((resp) => {
        const boothTiers = ((resp as any).node as Event).boothTiers.nodes;
        return concat(
          (boothTiers.some((tier) => tier.id === eventEditSelectors.fragments.booths.selectCurrentTierId(state$.value))
            ? EMPTY : of(eventEditActions.fragments.booths.setCurrentTierId(boothTiers[0]?.id))),
          of(mapToSimpleMergeEventAction(originalEventFactory)(resp as { node: any })),
        );
      }),
    );

    return concat(
      eventEditMutationCompleteObservable(action$, state$), // wait all mutation complete
      requests,
    ).pipe(eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory));
  }),
);

export default queryBoothTiersListEpic;
