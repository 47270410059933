import { Epic } from 'redux-observable';
import {
  filter, ignoreElements, map, mergeMap,
} from 'rxjs/operators';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import cmsSelectors from 'redux/cmsSelectors';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import produce from 'immer';
import { concat, Observable, of } from 'rxjs';
import { BoothMasterElementType } from 'admin-data/event/EventEdit/typenameEnums';

const reorderTabMasterElementEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.mutations.reorderTabMasterElement.match),
  mergeMap(({ payload: { id, orderingPosition } }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const eventId = eventEditSelectors.selectEventId(state$.value);
    const originalEventFactory = () => eventEditSelectors.selectEvent(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.mutations.reorderTabMasterElement(),
        ExecutingTaskCommonTag.Mutation],
    });

    const internalReorderAction = eventEditActions.internalUpdateEvent(produce(originalEventFactory(), ((draft) => {
      const originalIndex = draft.rootMasterElement.tabs.nodes.findIndex((tab) => tab.id === id);
      draft.rootMasterElement.tabs.nodes.splice(orderingPosition, 0, draft.rootMasterElement.tabs.nodes.splice(originalIndex, 1)[0]);
    })));

    const requests = sdk.boothTabMasterElementUpdate({
      input: { eventId, elementId: id, orderingPosition },
    }).pipe(
      throwCommonError(),
      ignoreElements(),
      catchErrorToPopup(state$.value.intl),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return concat(
      of(internalReorderAction),
      of(eventEditActions.internalAddSequencedRequest(requests)),
    );
  }),
);

export default reorderTabMasterElementEpic;
