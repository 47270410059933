import { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import {
  concat, defer, EMPTY, Observable, of,
} from 'rxjs';
import { Action } from 'redux';
import { ExecutingTasksActions } from 'utils/xtra-executing-tasks/createExecutingTasksActions';
import { ExecutingTasksSelectors } from 'utils/xtra-executing-tasks';
import { ActionCreatorWithOptionalPayload, createAction, PayloadActionCreator } from '@reduxjs/toolkit';
import { filter, ignoreElements, mergeMap } from 'rxjs/operators';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import {
  DependencyList, useCallback, useEffect, useLayoutEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

export interface StandardRequestMapperResource {
  action$: ActionsObservable<any>,
  state$: StateObservable<any>,
}

export interface StandardRequestBundleOptions<P, R, T, M = StandardRequestMapperResource> {
  actionName: T;
  additionalTaskTags?: (string | ExecutingTaskCommonTag)[];
  preConditionObservableFactory?: (action$: ActionsObservable<any>, state$: StateObservable<any>) => Observable<any>;
  preRequestActionsObservableFactory?: (resources: M & { payload: P }) => Observable<Action>;
  isSequenced?: boolean;
  requestFactory: (resources: M & { payload: P }) => Observable<R>;
  /**
   * Null to ignore result
   */
  requestResponseMapper?: (resources: M & { payload: P, response: R }) => Observable<Action>;
  executingTasksActionsProvider: () => ExecutingTasksActions
  executingTasksSelectorsProvider: () => ExecutingTasksSelectors
  sequencedRequestActionProvider: () => ActionCreatorWithOptionalPayload<Observable<Action>>
}

export interface BaseRequestBundle<P, R, T extends string> {
  __isXtraRequestBundle: true;
  rawActionCreator: PayloadActionCreator<StandardRequestActionPayload<P>, T>;
  epic: Epic;
  useIsComplete: (needInitialize?: boolean) => boolean;
  usePageQuery: (actionPayloadCreator?: (() => P) | P, deps?: DependencyList) => boolean;
  options: StandardRequestBundleOptions<P, R, T>;
}

export interface RequestBundle<P, R, T extends string> {
  __isXtraRequestBundle: true;
  rawActionCreator: PayloadActionCreator<StandardRequestActionPayload<P>, T>;
  epic: Epic;
  useIsComplete: (needInitialize?: boolean) => boolean;
  usePageQuery: (actionPayloadCreator?: (() => P) | P, deps?: DependencyList) => boolean;
  options: StandardRequestBundleOptions<P, R, T>;
}

export interface RequestReceipt {
  dispatcherId?: string
  uniqueId?: string
  requestBundle: RequestBundle<any, any, any>
}

const requestIdTagPrefix = 'RequestId_';
const dispatcherIdTagPrefix = 'DispatcherId_';

export const useIsRequesting = (requestReceipt: RequestReceipt) => {
  const selectHasExecutingTasksByTag = useMemo(() => (requestReceipt == null ? () => () => null : requestReceipt.requestBundle.options.executingTasksSelectorsProvider().selectHasExecutingTasksByTag), [requestReceipt]);
  const selector = useMemo(() => selectHasExecutingTasksByTag(`${requestIdTagPrefix}${requestReceipt?.uniqueId}`), [requestReceipt?.uniqueId, selectHasExecutingTasksByTag]);
  return useSelector(selector);
};

type RequestDispatcher = (<P>(requestBundle: RequestBundle<P, any, any>, payloadData: P) => RequestReceipt) & {
  dispatcherId: string
}

export const useIsRequestDispatcherRequesting = (requestDispatcher: RequestDispatcher, executingTasksSelectorsProvider: () => ExecutingTasksSelectors) => {
  const selectHasExecutingTasksByTag = useMemo(() => (requestDispatcher == null ? () => () => null : executingTasksSelectorsProvider().selectHasExecutingTasksByTag), [executingTasksSelectorsProvider, requestDispatcher]);
  const selector = useMemo(() => selectHasExecutingTasksByTag(`${dispatcherIdTagPrefix}${requestDispatcher?.dispatcherId}`), [requestDispatcher?.dispatcherId, selectHasExecutingTasksByTag]);
  return useSelector(selector);
};

export const useRequestDispatch = () => {
  const dispatch = useDispatch();
  const dispatcherId: string = useMemo(() => uuidv4(), []);

  const actionCreator = useCallback(<P>(requestBundle: RequestBundle<P, any, any>, payloadData: P) => {
    const uniqueId: string = uuidv4();
    return {
      action: requestBundle.rawActionCreator({ uniqueId, dispatcherId, payloadData }),
      uniqueId,
    };
  }, [dispatcherId]);

  const mappedDispatch = useCallback(<P>(requestBundle: RequestBundle<P, any, any>, payloadData: P) => {
    const { action, uniqueId } = actionCreator(requestBundle, payloadData);
    dispatch(action);
    return { uniqueId, dispatcherId, requestBundle } as RequestReceipt;
  }, [actionCreator, dispatch, dispatcherId]);
  (mappedDispatch as any).dispatcherId = dispatcherId;

  return mappedDispatch as RequestDispatcher;
};

export const useRequestDispatchHook = <P>(requestBundle: RequestBundle<P, any, any>) => {
  const requestDispatch = useRequestDispatch();

  const dispatch = useCallback((payloadData: P) => {
    requestDispatch(requestBundle, payloadData);
  }, [requestBundle, requestDispatch]);

  const isRequesting = useIsRequestDispatcherRequesting(requestDispatch, requestBundle.options.executingTasksSelectorsProvider);

  const actionCreator = useCallback((payloadData: P) => {
    const uniqueId: string = uuidv4();
    return {
      action: requestBundle.rawActionCreator({
        uniqueId,
        dispatcherId: (requestDispatch as any).dispatcherId,
        payloadData,
      }),
      uniqueId,
    };
  }, [requestBundle, requestDispatch]);

  return {
    dispatch,
    isRequesting,
    actionCreator,
  };
};

type RequestDispatchHook = ReturnType<typeof useRequestDispatchHook>;

export interface StandardRequestActionPayload<P> {
  payloadData: P
  /**
   * Null if not dispatch from a request action dispatcher
   */
  dispatcherId?: string
  uniqueId?: string
}

export const createStandardRequestBundle = <P = void, R = any, T extends string = string, >(options: StandardRequestBundleOptions<P, R, T>) => {
  const rawActionCreator: PayloadActionCreator<StandardRequestActionPayload<P>, T> = createAction<StandardRequestActionPayload<P>, T>(options.actionName);

  const epic: Epic = (action$, state$) => action$.pipe(
    filter(rawActionCreator.match),
    mergeMap(({ payload }) => {
      const { payloadData, uniqueId, dispatcherId } = payload;
      const mainRequest$ = defer(() => options.requestFactory({ payload: payloadData as P, action$, state$ }).pipe(
        throwCommonError(),
        options.requestResponseMapper == null ? ignoreElements() : mergeMap((response) => options.requestResponseMapper({
          response, action$, state$, payload: payloadData as P,
        })),
        catchErrorToPopup(state$.value.intl),
      ));

      const precondition$ = defer(() => options.preConditionObservableFactory?.call(null, action$, state$) ?? EMPTY) as Observable<Action>;

      const preRequestActions$ = defer(() => options.preRequestActionsObservableFactory?.call(null, {
        payload: payloadData as P, action$, state$,
      }) ?? EMPTY) as Observable<Action>;

      const sendRequestTaskFactory = () => createExecutingTask({ tags: [options.actionName, `${requestIdTagPrefix}${uniqueId}`, `${dispatcherIdTagPrefix}${dispatcherId}`, ...(options.additionalTaskTags ?? [])].filter((it) => it != null) });

      const async$ = concat(
        mainRequest$,
      ).pipe(options.executingTasksActionsProvider().wrapWithExecutingTaskAction(sendRequestTaskFactory));

      return concat(
        preRequestActions$,
        precondition$,
        options.isSequenced ? of(options.sequencedRequestActionProvider()(async$)) : async$,
      );
    }),
  );

  const useIsComplete = (needInitialize = false) => {
    const [initialized, setInitialized] = useState(false);
    const isExecuting = useSelector(options.executingTasksSelectorsProvider().selectHasExecutingTasksByTag(options.actionName));
    useEffect(() => {
      if (isExecuting) setInitialized(true);
    }, [isExecuting]);
    return (!needInitialize || initialized) && !isExecuting;
  };

  const usePageQuery = (actionPayloadCreator?: (() => P) | P, deps: DependencyList = []) => {
    const isComplete = useIsComplete(true);
    const dispatch = useDispatch();

    useLayoutEffect(() => {
      dispatch(rawActionCreator({ payloadData: typeof actionPayloadCreator === 'function' ? actionPayloadCreator.call(null) : actionPayloadCreator }));
    }, [actionPayloadCreator, dispatch, ...deps]);

    return isComplete;
  };

  return {
    options,
    __isXtraRequestBundle: true,
    rawActionCreator,
    epic,
    useIsComplete,
    usePageQuery,
  } as RequestBundle<P, R, T>;
};

export const recursiveFlattenBundlesObjects = (obj: any | RequestBundle<any, any, any>) => (
  obj.__isXtraRequestBundle
    ? [obj] as RequestBundle<any, any, any>[]
    : Object.values(obj).flatMap((value) => recursiveFlattenBundlesObjects(value) as RequestBundle<any, any, any>[])
) as RequestBundle<any, any, any>[];
