import axios from 'axios';
import { CMS_PRODUCT_API_ENDPOINT } from 'config';
import { defer, of } from 'rxjs';
import { EVENT_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { createStandardEventEditRequestBundle } from 'admin-data/event/EventEdit/redux/createStandardEventEditRequestBundle';
import { map } from 'rxjs/operators';
import { ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { findProductCategoryContainerAndIndex } from 'admin-data/event/EventEdit/redux/requestBundle/product/category/utils';

const bundleEventProductCategoryDelete = createStandardEventEditRequestBundle<string>({
  actionName: `${EVENT_EDIT_ACTIONS_NAME}/product/category/delete`,
  additionalTaskTags: [ExecutingTaskCommonTag.Mutation],
  isSequenced: true,

  requestFactory({
    eventDomain, locale, authToken, payload,
  }) {
    return defer(() => axios.delete(`${CMS_PRODUCT_API_ENDPOINT}/categories/${payload}`, {
      headers: {
        'x-eventxtra-admin': true,
      },
    })).pipe(map((resp) => resp.data));
  },

  requestResponseMapper: ({ payload, response, createUpdateEventEditAction }) => of(createUpdateEventEditAction((eventEditState) => {
    const [result, index] = findProductCategoryContainerAndIndex(eventEditState.event.productSettings.categories, payload);
    result.splice(index, 1);
  })),
});

export default bundleEventProductCategoryDelete;
