import { GraphQLClient } from 'graphql-request';
import { getSdk } from 'models';
import { defer, Observable } from 'rxjs';
import { API_ENDPOINT } from 'config';

/**
 * Just use as a type token, do not use this sdk
 * @deprecated
 */
const sdkTypeToken = getSdk(new GraphQLClient(API_ENDPOINT));
export type CmsSDK = typeof sdkTypeToken;

let unauthorized = false;

export const getAuthToken = (account: string) => {
  if (account == null) throw 'getAuthToken Account cannot be null';
  const authToken = new URLSearchParams(window.location.search).get('auth_token') || localStorage.getItem(`auth_token_${account}`);
  const legacyAuthToken = localStorage.getItem('auth_token');
  if (authToken == null && legacyAuthToken != null) {
    localStorage.setItem(`auth_token_${account}`, localStorage.getItem('auth_token'));
    localStorage.removeItem('auth_token');
    return legacyAuthToken;
  }
  localStorage.setItem(`auth_token_${account}`, authToken);
  localStorage.removeItem('auth_token');
  return authToken;
};

/**
 * @param account the unique identifier of the auth_token, e.g. booth id, event id
 */
export const createAuthorizedSDK = (account: string) => {
  if (account == null) throw 'SDK Account cannot be null';
  const authToken = getAuthToken(account);

  if (authToken == null && !unauthorized) {
    unauthorized = true;
    window.alert('Unauthorized');
    window.location.href = 'https://eventx.io';
  }
  const client = new GraphQLClient(API_ENDPOINT, { headers: { Authorization: `Bearer ${authToken}` } });
  return getSdk(client);
};

export type ExtractPromiseType<T> = T extends PromiseLike<infer U> ? U : T

export const sdkToObservables = (sdk: CmsSDK) => (sdk == null ? null : Object.entries(sdk).reduce((mapped: CmsSDKObservables, [key, value]) => {
  mapped[key] = (...params) => defer(() => value(params[0]));
  return mapped;
}, {} as CmsSDKObservables));

export type CmsSDKObservables = { [key in keyof CmsSDK]: ((...param: Parameters<CmsSDK[key]>) => Observable<ExtractPromiseType<ReturnType<CmsSDK[key]>>>) }

export const createAuthorizedSDKObservables = (account: string) => sdkToObservables(createAuthorizedSDK(account));
