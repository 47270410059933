import { ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';

export enum BoothEditExecutingTasksTag {
  QueryBoothBasicInfo
}

export const boothEditExecutingTasksTagCreator = {
  queries: {
    queryBoothInfo: () => 'boothEdit/queries/queryBoothBasicInfo',
    queryContentTab: (tabId: string) => `boothEdit/queries/queryContentTab/${tabId}`,
    queryContentTabsList: () => 'boothEdit/queries/queryContentTabsList',
    queryLegacyDesignElements: () => 'boothEdit/queries/queryLegacyDesignElements',

    queryEventDesignsList: () => 'boothEdit/queries/queryEventDesignList',
    queryDesignCanvasLayersList: () => 'boothEdit/queries/queryDesignCanvasLayersList',
    queryRepresentativesList: () => 'boothEdit/queries/queryRepresentativesList',

    initializing: () => 'boothEdit/queries/initializing',
  },
  mutations: {
    updateBoothDesign: () => 'boothEdit/mutations/updateBoothDesign',
    deleteContentTabBodyElement: () => 'boothEdit/mutations/deleteContentTabBodyElement',
    addContentTabBodyElement: () => 'boothEdit/mutations/addContentTabBodyElement',
  },
};
