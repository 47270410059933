import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, takeUntil,
} from 'rxjs/operators';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import { concat, defer, of } from 'rxjs';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { EVENT_FIRESTORE_API } from 'config';

const fetchEventPageNamesEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.fetchPageNames.match),
  mergeMap(() => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const getEventUrl = `${EVENT_FIRESTORE_API}?event_domain=${domain}`;
    const fetchPageNames = defer(() => fetch(getEventUrl));
    return concat(
      of(eventSettingEditActions.pageNamesLoading(true)),
      fetchPageNames.pipe(
        mergeMap((resp) => resp.json() as Promise<{ pageNames: any }>),
        map(({ pageNames }) => eventSettingEditActions.setPageNames(pageNames)),
      ),
      of(eventSettingEditActions.pageNamesLoading(false)),
    ).pipe(
      takeUntil(action$.pipe(filter(eventSettingEditActions.fetchPageNames.match))),
    );
  }),
);

export default fetchEventPageNamesEpic;
