import styled from 'styled-components';

export const FileNameLabel = styled.a`
`;

export const ContentPreviewerContainer = styled.div<{ direction: 'column' | 'row' }>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  & > * {
    flex-basis: ${({ direction }) => (direction === 'row' ? '50%' : 'auto')};
  }
  max-height: inherit;
`;

export const FileInfoContainer = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FileNameContainer = styled.div`
  overflow-wrap: anywhere;
`;

export const PreviewArea = styled.div`
  flex-shrink: 1;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-height: inherit;
`;
