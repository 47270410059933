import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, takeUntil,
} from 'rxjs/operators';
import designEditorActions from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorActions';
import { fetchAllPage } from 'utils/requestsUtils';
import { Event } from 'models';
import { concat, of } from 'rxjs';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import cmsSelectors from 'redux/cmsSelectors';

const loadDesignsListEpic: Epic = (action$, state$) => action$.pipe(
  filter(designEditorActions.loadDesignsList.match),
  mergeMap(() => {
    const { id: eventId } = eventEditSelectors.selectEvent(state$.value);
    const locales = [eventEditSelectors.selectEditLocale(state$.value)];

    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const request = fetchAllPage(
      (after) => sdk.designEditorGetBoothDesignsListByEventId({ eventId, locales, after }),
      (resp) => (resp.node as Event).boothDesigns.pageInfo,
    ).pipe(
      map((resp) => (resp.node as Event).boothDesigns.nodes),
      map((boothDesigns) => designEditorActions.internalUpdateDesignsList(boothDesigns)),
    );

    return concat(
      of(designEditorActions.internalUpdateDesignsList([])),
      of(designEditorActions.internalUpdateDesignsListLoading(true)),
      request,
      of(designEditorActions.internalUpdateDesignsListLoading(false)),
    ).pipe(
      takeUntil(action$.pipe(filter(designEditorActions.loadDesignsList.match))),
    );
  }),
);

export default loadDesignsListEpic;
