import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { concat, defer, EMPTY, of } from 'rxjs';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import { SortResponsePayload } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import computeSort from 'utils/computeSort';
import eventSettingEditSelectors from '../../eventSettingEditSelectors';
import { FIRESTORE_ENDPOINT } from 'config';

const updateBannerAssetSortEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.updateBannerAssetSort.match),
  mergeMap(({ payload }) => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const assets = eventSettingEditSelectors.selectContainerAssetsByAssetId(payload.containerId)(state$.value);
    const computedSort = computeSort(
      payload.sourceIndex,
      payload.destIndex,
      assets.map((asset) => asset.desktopOrder),
    );
    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.fragments.events.mutations.updateBannerAssetSort(),
        ExecutingTaskCommonTag.Mutation,
      ],
    });
    const sortUrl = `${FIRESTORE_ENDPOINT}/bannerAsset/updateSort/${domain}`;
    const sortBannerAsset = defer(() => fetch(sortUrl, {
      method: 'PATCH',
      body: JSON.stringify({ ...payload }),
      headers: { 'Content-Type': 'application/json' },
    })).pipe(
      mergeMap((resp) => resp.json() as Promise<{ success: boolean; data: SortResponsePayload[]; }>),
      mergeMap(({ success, data }) => (success
        ? of(eventSettingEditActions.internalUpdateBannerAssetSort(data))
        : EMPTY)),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );
    return concat(
      of(eventSettingEditActions.internalUpdateBannerAssetSort([{ id: assets[payload.sourceIndex].id, sort: computedSort }])),
      sortBannerAsset,
    );
  }),
);

export default updateBannerAssetSortEpic;
