import {
  concatMap, filter, groupBy, map, mergeMap,
} from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { rawDesignEditorInstanceActions } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceActions';
import { DesignIdWrappedPayload } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designIdWrappedPayload';
import { Epic } from 'redux-observable';
import { Action } from 'redux';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';

const internalAddSequencedRequestEpic: Epic = (action$, state$) => action$
  .pipe(
    filter(eventEditActions.internalAddSequencedRequest.match),
    concatMap(({ payload }) => payload),
  );

export default internalAddSequencedRequestEpic;
