import { combineEpics } from 'redux-observable';
import eventEditBoothsEpics
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/epic';
import initializeEventEpic from 'admin-data/event/EventEdit/redux/epic/queries/initializeEventEpic';
import internalAddSequencedRequestEpic from 'admin-data/event/EventEdit/redux/epic/internalAddSequencedRequestEpic';
import queryMasterTabsListEpic from 'admin-data/event/EventEdit/redux/epic/queries/queryMasterTabsListEpic';
import updateEventFieldsEpic from 'admin-data/event/EventEdit/redux/epic/updateEventFieldsEpic';
import internalAddBatchedDebouncedUpdateEventRequestEpic
  from 'admin-data/event/EventEdit/redux/epic/internalAddBatchedDebouncedUpdateEventRequestEpic';
import queryBoothTaxonomiesEpic from 'admin-data/event/EventEdit/redux/epic/queries/queryBoothTaxonomiesEpic';
import addCategoryEpic from 'admin-data/event/EventEdit/redux/epic/mutations/addCategoryEpic';
import addSupercategoryEpic from 'admin-data/event/EventEdit/redux/epic/mutations/addSupercategoryEpic';
import deleteCategroyEpic from 'admin-data/event/EventEdit/redux/epic/mutations/deleteCategoryEpic';
import addTabBodyMasterElementEpic from 'admin-data/event/EventEdit/redux/epic/mutations/addTabBodyMasterElementEpic';
import deleteTabBodyMasterElementEpic
  from 'admin-data/event/EventEdit/redux/epic/mutations/deleteTabBodyMasterElementEpic';
import reorderTabBodyMasterElementEpic
  from 'admin-data/event/EventEdit/redux/epic/mutations/reorderTabBodyMasterElementEpic';
import internalAddBatchedDebouncedUpdateEventSettingRequestEpic
  from 'admin-data/event/EventEdit/redux/firebase/epic/internalAddBatchedDebouncedUpdateEventSettingRequestEpic';
import fetchLandingPageEpic from 'admin-data/event/EventEdit/redux/firebase/epic/fetchLandingPageEpic';
import updateEventSettingFieldsEpic from 'admin-data/event/EventEdit/redux/firebase/epic/updateEventSettingFieldsEpic';
import fetchEventSettingLobbyEpic from 'admin-data/event/EventEdit/redux/firebase/epic/fetchEventLobbyEpic';
import queryMasterTabByIdEpic from 'admin-data/event/EventEdit/redux/epic/queries/queryMasterTabByIdEpic';
import addTabMasterElementEpic from 'admin-data/event/EventEdit/redux/epic/mutations/addTabMasterElementEpic';
import deleteTabMasterElementEpic from 'admin-data/event/EventEdit/redux/epic/mutations/deleteTabMasterElementEpic';
import fetchEventSettingHallEpic from 'admin-data/event/EventEdit/redux/firebase/epic/fetchEventSettingHallEpic';
import reorderCategoryEpic from 'admin-data/event/EventEdit/redux/epic/mutations/reorderCategoryEpic';
import reorderTabMasterElementEpic from 'admin-data/event/EventEdit/redux/epic/mutations/reorderTabMasterElementEpic';
import fetchEventSettingGeneralEpic from 'admin-data/event/EventEdit/redux/firebase/epic/fetchEventSettingGeneralEpic';
import fetchChatroomSettingsEpic from 'admin-data/event/EventEdit/redux/firebase/epic/fetchChatroomSettingsEpic';
import fetchEventSettingFeaturesEpic from 'admin-data/event/EventEdit/redux/firebase/epic/fetchEventFeatures';
import fetchEventFooterEpic from 'admin-data/event/EventEdit/redux/firebase/epic/fetchEventFooterEpic';
import fetchEventAppbarEpic from 'admin-data/event/EventEdit/redux/firebase/epic/fetchEventAppbarEpic';
import fetchEventProductsEpic from 'admin-data/event/EventEdit/redux/firebase/epic/fetchEventProductsEpic';
import fetchBannerAssetsEpic from 'admin-data/event/EventEdit/redux/firebase/epic/fetchBannerAssetsEpic';
import eventEditBoothsV2Epics
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/epic';
import { createUploadFileEpic } from 'utils/xtra-upload-actions/uploadFile';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import { createUploadFirebaseFileEpic } from 'utils/xtra-upload-actions/uploadFirebaseFile';
import deleteBannerAssetEpic from 'admin-data/event/EventEdit/redux/firebase/epic/deleteBannerAssetEpic';
import addBannerAssetEpic from 'admin-data/event/EventEdit/redux/firebase/epic/addBannerAssetEpic';
import updateBannerAssetEpic from 'admin-data/event/EventEdit/redux/firebase/epic/updateBannerAssetEpic';
import updateBannerAssetSortEpic from 'admin-data/event/EventEdit/redux/firebase/epic/updateBannerAssetSortEpic';
import fetchLobbyTemplateEpic from 'admin-data/event/EventEdit/redux/firebase/epic/fetchLobbyTemplateEpic';
import createTemplateEpic from 'admin-data/event/EventEdit/redux/firebase/epic/createTemplateEpic';
import fetchEventCustomPageEpic from 'admin-data/event/EventEdit/redux/firebase/epic/fetchEventCustomPageEpic';
import fetchEventPageNamesEpic from 'admin-data/event/EventEdit/redux/firebase/epic/fetchEventPageNames';
import { eventEditRequestsEpics } from 'admin-data/event/EventEdit/redux/requestBundle';
import fetchEventSettingGeneralV2Epic
  from 'admin-data/event/EventEdit/redux/firebase/epic/fetchEventSettingGeneralV2Epic';
import updateEventStateFieldsEpic from 'admin-data/event/EventEdit/redux/epic/updateEventStateFieldsEpic';
import setupAuditoriumEpic from 'admin-data/event/EventEdit/redux/firebase/epic/setupAuditoriumEpic';
import fetchEventLogoEpic from 'admin-data/event/EventEdit/redux/firebase/epic/fetchEventLogoEpic';

const eventEditEpics = combineEpics(
  eventEditBoothsEpics,

  updateEventFieldsEpic,
  updateEventStateFieldsEpic,
  internalAddBatchedDebouncedUpdateEventRequestEpic,

  initializeEventEpic,
  queryMasterTabsListEpic,
  queryMasterTabByIdEpic,
  queryBoothTaxonomiesEpic,

  // Mutations
  addTabMasterElementEpic,
  deleteTabMasterElementEpic,
  reorderTabMasterElementEpic,

  addTabBodyMasterElementEpic,
  deleteTabBodyMasterElementEpic,
  reorderTabBodyMasterElementEpic,

  addCategoryEpic,
  addSupercategoryEpic,
  deleteCategroyEpic,
  reorderCategoryEpic,

  internalAddSequencedRequestEpic,
  fetchLandingPageEpic,
  fetchEventSettingHallEpic,
  fetchEventSettingLobbyEpic,
  fetchEventSettingGeneralEpic,
  fetchChatroomSettingsEpic,
  fetchEventSettingFeaturesEpic,
  fetchEventFooterEpic,
  fetchEventAppbarEpic,
  fetchEventProductsEpic,
  fetchEventSettingGeneralV2Epic,
  updateEventSettingFieldsEpic,
  internalAddBatchedDebouncedUpdateEventSettingRequestEpic,

  eventEditBoothsV2Epics,

  createUploadFileEpic(eventEditActions.uploadFile, eventEditActions.executingTasks),
  createUploadFirebaseFileEpic(eventEditActions.uploadFirebaseFile, eventEditActions.executingTasks),

  fetchBannerAssetsEpic,
  deleteBannerAssetEpic,
  addBannerAssetEpic,
  updateBannerAssetEpic,
  updateBannerAssetSortEpic,

  fetchLobbyTemplateEpic,
  createTemplateEpic,
  fetchEventCustomPageEpic,
  fetchEventPageNamesEpic,
  setupAuditoriumEpic,

  eventEditRequestsEpics,
  fetchEventLogoEpic,
);

export default eventEditEpics;
