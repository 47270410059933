import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import useSpecialPageStyles from './useSpecialPageStyles';

const errorImageUrl = 'https://spa-assets.eventxtra.com/assets/error_image.svg';

const NeedRefreshPage: FC = () => {
  const classes = useSpecialPageStyles();

  return (
    <div>
      <Container component="main" maxWidth="sm" className={classes.container}>
        <img src={errorImageUrl} width="90%" alt="background" />
        <div className={classes.text}>
          <Typography variant="h4">
            Oops
          </Typography>
          <Typography variant="h6" className={classes.noBold} style={{ marginTop: '0.5rem' }}>
            We are having issues loading your page at the moment. Want to try again?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0.5rem' }}>
            <Typography variant="h6" className={classes.noBold}>
              <Link onClick={() => window.location.reload()} underline="none" className={classes.link}>
                Refresh Page
              </Link>
            </Typography>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NeedRefreshPage;
