import { combineEpics } from 'redux-observable';
import initializeByBoothIdEpic from 'admin-data/booth/BoothEdit/redux/epics/initializeByBoothIdEpic';
import updateBoothFieldsEpic from 'admin-data/booth/BoothEdit/redux/epics/updateBoothFieldsEpic';
import internalAddDebouncedRequestEpic from 'admin-data/booth/BoothEdit/redux/epics/internalAddDebouncedRequestEpic';
import internalAddBatchedDebouncedUpdateBoothRequestEpic
  from 'admin-data/booth/BoothEdit/redux/epics/internalAddBatchedDebouncedUpdateBoothRequestEpic';
import queryBoothInfoEpic from 'admin-data/booth/BoothEdit/redux/epics/queries/queryBoothInfoEpic';
import queryContentTabEpic from 'admin-data/booth/BoothEdit/redux/epics/queries/queryContentTabEpic';
import queryContentTabsListEpic from 'admin-data/booth/BoothEdit/redux/epics/queries/queryContentTabsListEpic';
import queryLegacyDesignElementsEpic
  from 'admin-data/booth/BoothEdit/redux/epics/queries/queryLegacyDesignElementsEpic';
import updateBoothDesignEpic from 'admin-data/booth/BoothEdit/redux/epics/mutations/updateBoothDesignEpic';
import queryEventDesignsListEpic from 'admin-data/booth/BoothEdit/redux/epics/queries/queryEventDesignsListEpic';
import queryDesignCanvasEpic from 'admin-data/booth/BoothEdit/redux/epics/queries/queryDesignCanvasEpic';
import deleteContentTabBodyElementEpic
  from 'admin-data/booth/BoothEdit/redux/epics/mutations/deleteContentTabBodyElementEpic';
import addContentTabBodyElementEpic
  from 'admin-data/booth/BoothEdit/redux/epics/mutations/addContentTabBodyElementEpic';
import { createUploadFileEpic } from 'utils/xtra-upload-actions/uploadFile';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import queryRepresentativesListEpic from 'admin-data/booth/BoothEdit/redux/epics/queries/queryRepresentativesListEpic';
import confirmAddContentTabBodyElementEpic
  from 'admin-data/booth/BoothEdit/redux/epics/mutations/addBoothRepresentativeEpic';
import exportAllBoothRepresentativesEpic
  from 'admin-data/booth/BoothEdit/redux/epics/queries/exportAllBoothRepresentativesEpic';
import deleteBoothRepresentativeEpic
  from 'admin-data/booth/BoothEdit/redux/epics/mutations/deleteBoothRepresentativeEpic';
import { boothEditRequestsEpics } from 'admin-data/booth/BoothEdit/redux/requestBundle';

const boothEditActionEpics = combineEpics(
  queryBoothInfoEpic,
  queryContentTabEpic,
  queryContentTabsListEpic,
  queryLegacyDesignElementsEpic,
  queryEventDesignsListEpic,
  queryDesignCanvasEpic,
  queryRepresentativesListEpic,
  exportAllBoothRepresentativesEpic,

  updateBoothDesignEpic,
  deleteContentTabBodyElementEpic,
  addContentTabBodyElementEpic,
  confirmAddContentTabBodyElementEpic,
  deleteBoothRepresentativeEpic,

  initializeByBoothIdEpic,
  updateBoothFieldsEpic,
  internalAddDebouncedRequestEpic,
  internalAddBatchedDebouncedUpdateBoothRequestEpic,

  boothEditRequestsEpics,
  createUploadFileEpic(boothEditActions.uploadFile, boothEditActions.executingTasks),
);

export default boothEditActionEpics;
