// export const simpleTagCreator = <Tag extends string>(prefix: string, x: Tag): Record<Tag, () => string> => ({ [x]: () => `${prefix}/${x}` }) as Record<Tag, any>;

// eslint-disable-next-line import/prefer-default-export
export const eventEditExecutingTasksTagCreator = {
  queries: {
    queryMasterTabsList: () => 'eventEdit/queries/queryRootMasterElement',
    queryMasterTabById: () => 'eventEdit/queries/queryMasterTabById',

    queryBoothTaxonomiesList: () => 'eventEdit/queries/queryBoothTaxonomiesList',
  },
  mutations: {
    addTabMasterElement: () => 'eventEdit/mutations/addTabMasterElement',
    deleteTabMasterElement: () => 'eventEdit/mutations/deleteTabMasterElement',
    reorderTabMasterElement: () => 'eventEdit/mutations/reorderTabMasterElement',

    addTabBodyMasterElement: () => 'eventEdit/mutations/addTabBodyMasterElement',
    deleteTabBodyMasterElement: () => 'eventEdit/mutations/deleteTabBodyMasterElement',
    reorderTabBodyMasterElement: () => 'eventEdit/mutations/reorderTabBodyMasterElement',

    addCategory: () => 'eventEdit/mutations/addCategory',
    addSupercategory: () => 'eventEdit/mutations/addSupercategory',
    deleteCategory: () => 'eventEdit/mutations/deleteCategory',
    reorderCategory: () => 'eventEdit/mutations/reorderCategory',
  },
  fragments: {
    booths: {
      queries: {
        queryBoothTiersList: () => 'eventEdit/booths/queries/queryBoothTiersList',
        queryPagedBoothsListByCurrentTierId: () => 'eventEdit/booths/queries/queryPagedBoothsListByCurrentTierId',
      },
      mutations: {
        samePageReorderBooth: () => 'eventEdit/booths/mutations/samePageReorder',
        directMoveBooth: () => 'eventEdit/booths/mutations/directMoveBooth',
        updateBooth: () => 'eventEdit/booths/mutations/updateBooth',
        addBooth: () => 'eventEdit/booths/mutations/addBooth',

        addBoothTier: () => 'eventEdit/booths/mutations/addBoothTier',
        reorderBoothTier: () => 'eventEdit/booths/mutations/reorderBoothTier',
        updateBoothTier: () => 'eventEdit/booths/mutations/updateBoothTier',
        deleteBoothTier: () => 'eventEdit/booths/mutations/deleteBoothTier',

      },
    },
    events: {
      mutations: {
        deleteBannerAsset: (id: string) => `eventEdit/events/mutations/delete${id}`,
        addBannerAsset: () => 'eventEdit/events/mutations/add',
        updateBannerAsset: () => 'eventEdit/events/mutations/update',
        updateBannerAssetSort: () => 'eventEdit/events/mutations/bannerAsset/sort',
        createLobbyTemplate: () => 'eventEdit/events/mutations/bannerAsset/createLobbyTemplate',
      },
    },
    eventGeneralSettings: {
      queries: {
        fetchGeneralSettings: () => 'eventEdit/eventGeneralSettings/queries/fetchGeneralSettings',
      },
    },
  },
};
