import { Epic } from 'redux-observable';
import {
  filter, ignoreElements, mergeMap,
} from 'rxjs/operators';
import {
  concat, of,
} from 'rxjs';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import {
  catchErrorToPopup,
  throwCommonError,
} from 'redux/mutationFailedPipe';
import cmsSelectors from 'redux/cmsSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import produce from 'immer';
import { BoothTier } from 'models';

const addBoothTierEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.fragments.booths.mutations.addBoothTier.match),
  mergeMap(() => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const eventId = eventEditSelectors.selectEventId(state$.value);
    const locales = [eventEditSelectors.selectEditLocale(state$.value)];

    const originalEventEditFactory = () => eventEditSelectors.selectEventEdit(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.fragments.booths.mutations.addBoothTier(),
        ExecutingTaskCommonTag.Mutation],
    });

    const requests = sdk.boothTierCreate({
      input: {
        eventId,
        name: { values: [{ locale: locales[0], value: 'Unnamed tier' }] },
      },
      locales,
    }).pipe(
      throwCommonError(),
      mergeMap((resp) => of(eventEditActions.internalUpdate(produce(originalEventEditFactory(), ((draft) => {
        draft.event.boothTiers.nodes.push(resp.boothTierCreate.boothTier as BoothTier);
        draft.event.boothTiers.totalCount += 1;
      }))))),
      catchErrorToPopup(state$.value.intl),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return concat(
      of(eventEditActions.internalAddSequencedRequest(requests)),
    );
  }),
);

export default addBoothTierEpic;
