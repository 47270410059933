import { UploadTask } from 'utils/xtra-upload-tasks/uploadTask';
import { createAction } from '@reduxjs/toolkit';

// eslint-disable-next-line import/prefer-default-export
export const createUploadTasksActions = (actionPrefix) => ({
  startUploadTasks: createAction<UploadTask>(`${actionPrefix}/upsertUploadTasks`),
  cancelUploadTasks: createAction<string>(`${actionPrefix}/cancelUploadTasks`),
});

export type UploadTasksActions = ReturnType<typeof createUploadTasksActions>;
