import { Epic } from 'redux-observable';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import { map, mergeMap } from 'rxjs/operators';
import {
  concat, defer, merge, of,
} from 'rxjs';
import { Booth } from 'models';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import { createExecutingTask } from 'utils/xtra-executing-tasks/executingTask';
import { boothEditExecutingTasksTagCreator } from 'admin-data/booth/BoothEdit/redux/boothEditExecutingTasks';
import { mapToSimpleMergeBoothAction } from 'admin-data/booth/BoothEdit/redux/epics/queries/utils';
import cmsSelectors from 'redux/cmsSelectors';
import { EventSettings } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import produce from 'immer';
import { EVENT_FIRESTORE_API } from 'config';

const initializeByBoothIdEpic: Epic = (action$, state$) => action$.ofType(boothEditActions.initializeByBoothId).pipe(
  map((action) => action.payload as string),
  mergeMap((boothId) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const originalBoothFactory = () => boothEditSelectors.selectBooth(state$.value);
    const originalBoothEditStateFactory = () => boothEditSelectors.selectBoothEditState(state$.value);

    const requests = merge(
      sdk.getBoothEditEventLocales({ boothId }).pipe(
        map(mapToSimpleMergeBoothAction(originalBoothFactory)),
        mergeMap((mergeAction) => {
          const { domain } = mergeAction.payload.event;
          const getEventUrl = `${EVENT_FIRESTORE_API}?event_domain=${domain}`;
          const fetchEventSettings = defer(() => fetch(getEventUrl)).pipe(mergeMap((it) => it.json() as Promise<EventSettings>));

          const previousLocale = localStorage.getItem('previousContentLocale');
          const locale = mergeAction.payload.event.locales.indexOf(previousLocale) === -1 ? mergeAction.payload.event.defaultLocale : previousLocale;
          localStorage.setItem('previousContentLocale', locale);

          return concat(
            of(boothEditActions.setLocale(locale)),
            of(mergeAction),
            merge(
              sdk.getBoothBasicInfo({ boothId, locales: mergeAction.payload.event.locales })
                .pipe(
                  map(mapToSimpleMergeBoothAction(originalBoothFactory)),
                ),
              fetchEventSettings.pipe(
                map((eventSettings) => boothEditActions.internalUpdate(produce(originalBoothEditStateFactory(), (draft) => {
                  draft.eventSettings = eventSettings;
                }))),
              ),
            ),
          );
        }),
      ),
    );

    const taskFactory = () => createExecutingTask({ tags: [boothEditExecutingTasksTagCreator.queries.initializing()] });

    return concat(
      of(boothEditActions.internalResetInitialState()),
      concat(
        of(boothEditActions.internalUpsertBooth({ id: boothId } as Booth)),
        requests,
      ).pipe(boothEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory)),
    );
  }),
);

export default initializeByBoothIdEpic;
