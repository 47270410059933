import { createAction } from '@reduxjs/toolkit';
import { Booth } from 'models';
import { Observable } from 'rxjs';
import { Action } from 'redux';
import { DebouncedRequest } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceActions';
import { BOOTH_EDIT_ACTION_PREFIX } from 'admin-data/booth/BoothEdit/redux/boothEditVars';
import { BatchedDebouncedUpdateRequest } from 'utils/redux-field-bind/updateParser/updateParserBuilder';
import { createExecutingTasksActions } from 'utils/xtra-executing-tasks/createExecutingTasksActions';
import { FieldsUpdatePayload } from 'utils/redux-field-bind/reduxBindAdapter';
import { createUploadFileAction } from 'utils/xtra-upload-actions/uploadFile';
import { BoothEditState } from 'admin-data/booth/BoothEdit/redux/boothEditState';

export const boothEditActions = {
  initializeByBoothId: createAction<string>(`${BOOTH_EDIT_ACTION_PREFIX}/initializeByBoothId`),
  updateBoothFields: createAction<FieldsUpdatePayload>(`${BOOTH_EDIT_ACTION_PREFIX}/updateBoothFields`),

  queries: {
    queryEventLocales: createAction(`${BOOTH_EDIT_ACTION_PREFIX}/queries/queryEventLocales`),
    queryBoothInfo: createAction(`${BOOTH_EDIT_ACTION_PREFIX}/queries/queryBoothInfo`),

    queryContentTabsList: createAction(`${BOOTH_EDIT_ACTION_PREFIX}/queries/queryContentTabsList`),
    queryContentTabById: createAction<string>(`${BOOTH_EDIT_ACTION_PREFIX}/queries/queryContentTabById`),

    queryEventDesignsList: createAction(`${BOOTH_EDIT_ACTION_PREFIX}/queries/queryEventDesignsList`),
    queryDesign: createAction<string>(`${BOOTH_EDIT_ACTION_PREFIX}/queries/queryDesignCanvasLayersList`),
    queryDesignCanvas: createAction<string>(`${BOOTH_EDIT_ACTION_PREFIX}/queries/queryDesignCanvas`),

    queryRepresentativesList: createAction(`${BOOTH_EDIT_ACTION_PREFIX}/queries/queryRepresentativesList`),

    /**
     * Query all v1 design elements
     */
    queryLegacyDesignElements: createAction<string>(`${BOOTH_EDIT_ACTION_PREFIX}/queries/queryLegacyDesignElements`),

    exportAllBoothRepresentatives: createAction(`${BOOTH_EDIT_ACTION_PREFIX}/queries/exportAllBoothRepresentatives`),
  },

  // special mutations, which is not handling by booth update parser
  mutations: {
    updateBoothDesign: createAction<string>(`${BOOTH_EDIT_ACTION_PREFIX}/mutations/updateBoothDesign`),
    deleteContentTabBodyElement: createAction<string>(`${BOOTH_EDIT_ACTION_PREFIX}/mutations/deleteContentTabBodyElement`),
    addContentTabBodyElement: createAction<{ parentElementId: string }>(`${BOOTH_EDIT_ACTION_PREFIX}/mutations/addContentTabBodyElement`),
    confirmAddBoothRepresentative: createAction(`${BOOTH_EDIT_ACTION_PREFIX}/mutations/confirmAddBoothRepresentative`),
    deleteBoothRepresentative: createAction<string>(`${BOOTH_EDIT_ACTION_PREFIX}/mutations/deleteBoothRepresentative`),
  },

  setLocale: createAction<string>(`${BOOTH_EDIT_ACTION_PREFIX}/setLocale`),

  executingTasks: createExecutingTasksActions(BOOTH_EDIT_ACTION_PREFIX),
  uploadFile: createUploadFileAction(BOOTH_EDIT_ACTION_PREFIX),

  // internal actions, should be called by epics
  internalUpdateFields: createAction<FieldsUpdatePayload>(`${BOOTH_EDIT_ACTION_PREFIX}/_updateFields`),
  internalUpdateBoothFields: createAction<FieldsUpdatePayload>(`${BOOTH_EDIT_ACTION_PREFIX}/_updateBoothFields`),
  internalAddBatchedDebouncedUpdateBoothRequest: createAction<BatchedDebouncedUpdateRequest<any, any>>(`${BOOTH_EDIT_ACTION_PREFIX}/_addBatchedDebouncedUpdateBoothRequest`),
  internalResetInitialState: createAction<string>(`${BOOTH_EDIT_ACTION_PREFIX}/_resetInitialState`),
  internalUpsertBooth: createAction<Booth>(`${BOOTH_EDIT_ACTION_PREFIX}/_upsertBooth`),
  internalUpdate: createAction<BoothEditState>(`${BOOTH_EDIT_ACTION_PREFIX}/_update`),
  /**
   * The requests that will run sequentially
   * useful for add/delete request
   */
  internalAddSequencedRequest: createAction<Observable<Action>>(`${BOOTH_EDIT_ACTION_PREFIX}/_addSequencedRequest`),
  /**
   * The requests that will be debounce
   * the request should do null checking to avoid the object be deleted before save request debounced
   * useful for sync object properties request
   */
  internalAddDebouncedRequest: createAction<DebouncedRequest>(`${BOOTH_EDIT_ACTION_PREFIX}/_addDebouncedRequest`),

};
