import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, repeat,
} from 'rxjs/operators';
import { concat, of } from 'rxjs';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import eventEditBoothsSelectors
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/eventEditBoothsSelectors';
import { Booth, BoothCreateInput } from 'models';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import _ from 'lodash';

export const BOOTH_CREATE_LIMIT = 100;
const boothCreationCreateEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.fragments.booths.boothCreation.create.match),
  mergeMap(() => {
    const { id: eventId } = eventEditSelectors.selectEvent(state$.value);

    const {
      hallId, boothTierId, appliedDesignCodename, amount, design,
    } = eventEditBoothsSelectors.selectBoothCreation(state$.value).configs;

    return concat(
      of(eventEditActions.fragments.booths.mutations.addBooths(_.range(0, amount).map((index) => (
        {
          eventId, hallId, boothTierId, appliedDesignCodename, designId: design?.id,
        } as BoothCreateInput
      )))),
      of(eventEditActions.fragments.booths.boothCreation.close()),
    );
  }),
);

export default boothCreationCreateEpic;
