import { Epic } from 'redux-observable';
import reorderLayerEpic from 'libs/xtra-custom-booth-design/redux/design-editor-instance/epics/reorderLayerEpic';
import addLayerEpic from 'libs/xtra-custom-booth-design/redux/design-editor-instance/epics/addLayerEpic';
import deleteLayerByIdEpic from 'libs/xtra-custom-booth-design/redux/design-editor-instance/epics/deleteLayerByIdEpic';
import updateLayerFieldsEpic
  from 'libs/xtra-custom-booth-design/redux/design-editor-instance/epics/updateLayerFieldsEpic';
import updateLayersEpic from 'libs/xtra-custom-booth-design/redux/design-editor-instance/epics/updateLayersEpic';
import updateLayerEpic from 'libs/xtra-custom-booth-design/redux/design-editor-instance/epics/updateLayerEpic';
import closeDesignEpic from 'libs/xtra-custom-booth-design/redux/design-editor/epics/closeDesignEpic';
import createDesignEpic from 'libs/xtra-custom-booth-design/redux/design-editor/epics/createDesignEpic';
import openDesignEpic from 'libs/xtra-custom-booth-design/redux/design-editor/epics/openDesignEpic';
import loadDesignsListEpic from 'libs/xtra-custom-booth-design/redux/design-editor/epics/loadDesignsListEpic';
import deleteDesignEpic from 'libs/xtra-custom-booth-design/redux/design-editor/epics/deleteDesignEpic';
import { DESIGN_EDITOR_ACTION_PREFIX } from 'libs/xtra-custom-booth-design/redux/designEditorVars';
import { createUploadTaskEpic } from 'utils/xtra-upload-tasks/createUploadTaskEpic';
import renameDesignEpic from 'libs/xtra-custom-booth-design/redux/design-editor/epics/renameDesignEpic';

const designEditorActionEpics: Epic[] = [
  closeDesignEpic,
  createDesignEpic,
  openDesignEpic,
  loadDesignsListEpic,
  deleteDesignEpic,
  createUploadTaskEpic(DESIGN_EDITOR_ACTION_PREFIX),
  renameDesignEpic,
];

export default designEditorActionEpics;
