import React, { FC, useMemo } from 'react';
import { StyledCanvasContent, StyledCanvasContentInner, StyledCanvasInner } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/DesignEditorInstanceCanvas/DesignElementPresenter/style';

export interface DesignElementPresenterSizingContainerProps {
  aspectRatio: number
}

const DesignElementPresenterSizingContainer: FC<DesignElementPresenterSizingContainerProps> = ({ aspectRatio, children }) => {
  const canvasPaddingTop = useMemo(() => `${(100.0 / aspectRatio) * 100}%`, [aspectRatio]);
  const canvasInnerStyle = useMemo(() => ({
    paddingTop: canvasPaddingTop,
  }), [canvasPaddingTop]);

  return (
    <StyledCanvasInner style={canvasInnerStyle}>
      <StyledCanvasContent>
        <StyledCanvasContentInner>
          {children}
        </StyledCanvasContentInner>
      </StyledCanvasContent>
    </StyledCanvasInner>
  );
};

export default DesignElementPresenterSizingContainer;
