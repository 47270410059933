import axios from 'axios';
import { defer, merge, of } from 'rxjs';
import { EVENT_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { createStandardEventEditRequestBundle } from 'admin-data/event/EventEdit/redux/createStandardEventEditRequestBundle';
import { ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { FIRESTORE_ENDPOINT } from 'config';
import { Auditorium } from 'extra-models/auditorium';

const bundleEventWebinarAuditoriumAssetDelete = createStandardEventEditRequestBundle<string>({
  actionName: `${EVENT_EDIT_ACTIONS_NAME}/auditorium/asset/bundleEventAuditoriumAssetDelete`,
  additionalTaskTags: [ExecutingTaskCommonTag.Mutation],
  isSequenced: true,

  preRequestActionsObservableFactory: ({
    payload,
    createUpdateEventEditAction,
  }) => of(createUpdateEventEditAction((eventEditState) => {
    const editingContainerKey = eventEditState.fragments.auditorium.auditoriumEdit.selectedContainerKey;
    const containerAssets = (eventEditState.fragments.auditorium.auditoriumEdit.editingAuditorium.assets[editingContainerKey] as any).assets;
    const deletingIndex = containerAssets.findIndex((it) => it.id === payload);
    containerAssets.splice(deletingIndex, 1);
  })),

  requestFactory({
    eventDomain, locale, authToken, payload: assetId, eventEditFactory,
  }) {
    const editingContainerKey = eventEditFactory().fragments.auditorium.auditoriumEdit.selectedContainerKey;
    const {
      id: auditoriumId,
      assets,
    } = eventEditFactory().fragments.auditorium.auditoriumEdit.editingAuditorium;

    return defer(() => merge(
      axios.delete(`${FIRESTORE_ENDPOINT}/events/${eventDomain}/auditoriums/asset/${assetId}`, {}),
      axios.patch(`${FIRESTORE_ENDPOINT}/events/${eventDomain}/auditoriums/${auditoriumId}`, {
        assets: {
          [editingContainerKey]: {
            assetIds: (assets[editingContainerKey] as any).assets.map((it) => it.id),
          },
        },
      } as Auditorium, {}),
    ));
  },

  requestResponseMapper: ({
    response, createUpdateEventEditAction,
  }) => of(createUpdateEventEditAction((eventEditState) => {
    /* What to do after response? */
  })),
});

export default bundleEventWebinarAuditoriumAssetDelete;
