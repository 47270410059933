import styled from 'styled-components';
import XtraDropZoneV2 from 'components/file-dropzone/XtraDropZoneV2';

export const StyledDropZoneDialogContent = styled.div`
  min-height: 400px;
  max-height: calc(100vh - 300px);
  overflow: hidden;
  display: flex;
`;

export const StyledXtraDropZoneV2 = styled(XtraDropZoneV2)`
  height: 100%;
  min-height: 400px;
  max-height: calc(100vh - 300px);
`;
