import { Action, createAction } from '@reduxjs/toolkit';
import { EVENT_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { Booth } from 'models';
import { EventEditBoothsFragmentBoothCreation } from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/eventEditBoothsFramgnetState';
import { Observable } from 'rxjs';

const ACTIONS_PREFIX = `${EVENT_EDIT_ACTIONS_NAME}/booths`;

export interface ReorderBoothPayload {
  /**
   * Source booth index
   */
  source: number;
  dest: number;
}

const eventEditBoothsActions = {
  fetchBoothsList: createAction(`${ACTIONS_PREFIX}/fetchBoothsList`),

  reorderBooth: createAction<ReorderBoothPayload>(`${ACTIONS_PREFIX}/reorderBooth`),

  internalUpdateBoothsList: createAction<Booth[]>(`${ACTIONS_PREFIX}/_updateBoothsList`),
  internalUpdateLoadingBooths: createAction<boolean>(`${ACTIONS_PREFIX}/_updateLoadingBooths`),

};

export default eventEditBoothsActions;
