import { createPopupsSelectors } from 'utils/xtra-popups/createPopupsSelectors';
import { CMS_STORE_NAME } from 'redux/cmsVars';
import { createSelector } from 'reselect';
import { createAuthorizedSDKObservables } from 'utils/auth';
import { CMSState } from 'redux/cmsState';
import { createHintsSelectors } from 'utils/xtra-hints/createHintsSelectors';

const selectCms = (state) => state[CMS_STORE_NAME] as CMSState;
const selectPopupsState = createSelector(selectCms, (cms) => cms.popups);
const selectAvailableUILocales = createSelector(selectCms, (cms) => Object.keys(cms.translations));
const selectUITranslation = (locale) => createSelector(selectCms, (cms) => cms.translations[locale]);
const selectIsTranslationLoaded = createSelector(selectCms, (cms) => cms.translations != null);
const selectAuthorizedSDKObservables = createSelector(selectCms, (cms) => createAuthorizedSDKObservables(cms.sdkAccount));
const selectSDKAccount = createSelector(selectCms, (cms) => cms.sdkAccount);
const selectAuth = createSelector(selectCms, (cms) => cms.auth);
const selectSettingDialogOpen = createSelector(selectCms, (cms) => cms.settingDialogOpen);
const selectHintsState = createSelector(selectCms, (cms) => cms.hints);

const cmsSelectors = {
  popups: createPopupsSelectors(selectPopupsState),
  hints: createHintsSelectors(selectHintsState),
  selectAvailableUILocales,
  selectUITranslation,
  selectIsTranslationLoaded,
  selectAuthorizedSDKObservables,
  selectSDKAccount,
  selectAuth,
  selectSettingDialogOpen,
};

export default cmsSelectors;
