import { Action } from 'redux';
import { ExecutingTask } from 'utils/xtra-executing-tasks';

export enum BulkTaskState {
  Waiting,
  InProgress,
  Done,
  Error,
}

export type BulkTaskProgress = (ExecutingTask & { state: BulkTaskState, error?: string[] })

export interface BulkTasksProgressDialogData {
  title: string;
  // description: string,
  tasksProgress: BulkTaskProgress[];
  /**
   * Automatically calculate from tasks progress if undefined
   * otherwise, -1, or 0~1
   */
  progress?: number;
  finished: boolean;
  doneAction?: Action;
  cancelAction?: Action;
  cancelled?: boolean;
}

export const BULK_TASKS_PROGRESS_DIALOG_POPUP_KEY = 'BulkTasksProgressDialog';
