import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DesignEditorDockerComponent } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/designEditorDockerEntry';
import DesignEditorInstanceContext from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';
import {
  Checkbox, FormControlLabel, Input, InputLabel, MenuItem, Select,
} from '@material-ui/core';
import { useLayerFieldBind, useLayerLocalizableFieldSource, useReverseBooleanFieldBind } from 'libs/xtra-custom-booth-design/utils/useLayerFieldBind';
import { TreeView } from '@material-ui/lab';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import { StyledFormControl, StyledTreeItem } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayerAttributesDocker/styles';
import { BoothDesignButtonActionType, Image } from 'models';
import DesignEditorLayerAttributeFileField from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayerAttributesDocker/fields/DesignEditorLayerAttributeFileField';
import { useIntl } from 'react-intl';

/** @see BoothDesignImageButtonMasterElement */
const DesignEditorImageMasterElementAttributesSetting: DesignEditorDockerComponent = (props) => {
  const { actions, selectors, designId } = useContext(DesignEditorInstanceContext);
  const selectedLayersId = useSelector(selectors.selectSelectedLayersId);
  if (selectedLayersId.length !== 1) throw new Error('Selected layer must be 1');
  const layerId = useMemo(() => selectedLayersId[0], [selectedLayersId]);

  /** @see Image */
  const imageSource = useLayerLocalizableFieldSource(layerId, 'image');
  const imageField = useMemo(() => ({ objectId: layerId, fieldName: 'image' }), [layerId]);
  const image = useLayerFieldBind<Image>(layerId, `${imageSource}`);

  const [expanded, setExpanded] = React.useState<string[]>(['appearance', 'click-action']);
  const handleToggle = (event: React.ChangeEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const intl = useIntl();

  return (
    <>
      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        expanded={expanded}
        onNodeToggle={handleToggle}
      >

        <StyledTreeItem nodeId="appearance" label={intl.formatMessage({ id: 'event.design.attributes.appearance', defaultMessage: 'Appearance' })}>
          <DesignEditorLayerAttributeFileField
            fieldBind={image}
            fileField={imageField}
            accept="image/*"
            label={intl.formatMessage({ id: 'common.image', defaultMessage: 'Image' })}
            emptyText={intl.formatMessage({ id: 'common.transparent', defaultMessage: 'Transparent' })}
          />
        </StyledTreeItem>
      </TreeView>
    </>
  );
};

export default React.memo(DesignEditorImageMasterElementAttributesSetting);
