import { Action, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { PopupsState } from 'utils/xtra-popups/popupsState';
import { Draft } from 'immer';
import { PopupsActions } from 'utils/xtra-popups/createPopupsActions';

// eslint-disable-next-line import/prefer-default-export
export const injectPopupsExtraReducer = <S>(
  builder: ActionReducerMapBuilder<S>,
  actions: PopupsActions,
  stateGetter: (state: Draft<S>, action: Action<PopupsActions[keyof PopupsActions]>) => PopupsState,
) => {
  builder.addCase(actions.upsertPopup, (state, action) => {
    const popupsState = stateGetter(state, action as any);
    popupsState.popupsIdMap[action.payload.id] = action.payload;

    if (popupsState.popupsIdMap[action.payload.type] === undefined) popupsState.popupsTypeMap[action.payload.type] = {};
    popupsState.popupsTypeMap[action.payload.type][action.payload.id] = null;
  });

  builder.addCase(actions.removePopup, (state, action) => {
    const popupId = action.payload;
    const popupsState = stateGetter(state, action as any);
    const task = popupsState.popupsIdMap[popupId];
    if (task == null) return;
    delete popupsState.popupsIdMap[popupId];

    delete popupsState.popupsTypeMap[task.type][popupId];
    if (Object.values(popupsState.popupsTypeMap[task.type]).length === 0) delete popupsState.popupsTypeMap[task.type];
  });
};
