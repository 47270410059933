import { useReduxBindAdapter } from 'utils/redux-field-bind/reduxBindAdapter';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import { Booth } from 'models';

const useBoothEditReduxBindAdapter = () => useReduxBindAdapter<Booth>(
  boothEditSelectors.selectLocale,
  (source) => boothEditSelectors.selectBoothField(source),
  (payload) => boothEditActions.updateBoothFields(payload),
  boothEditActions.uploadFile,
);

export default useBoothEditReduxBindAdapter;
