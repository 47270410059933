import { useSelector } from 'react-redux';
import React, { useCallback, useContext, useMemo } from 'react';
import DesignEditorInstanceContext from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';

export const useCanvasStyle: () => any = () => {
  const { selectors } = useContext(DesignEditorInstanceContext);
  const { widthScale, positionX, positionY } = useSelector(selectors.selectCanvasViewConfig);
  return useMemo(() => ({
    width: `${widthScale}px`,
    left: `${positionX}px`,
    top: `${positionY}px`,
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
  }), [positionX, positionY, widthScale]);
};

export const useCanvasRelativePositionCalculator = (ref: React.RefObject<HTMLElement>) => {
  const { selectors } = useContext(DesignEditorInstanceContext);
  const { widthScale, heightScale } = useSelector(selectors.selectCanvasViewConfig);
  const calculateRelativePercentagePosition = useCallback((clientX: number, clientY: number) => {
    const x = ((clientX - ref.current.getBoundingClientRect().x) / widthScale) * 100;
    const y = ((clientY - ref.current.getBoundingClientRect().y) / heightScale) * 100;

    return { x, y };
  }, [heightScale, ref, widthScale]);
  return { calculateRelativePercentagePosition };
};
