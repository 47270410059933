import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { defer, EMPTY, of } from 'rxjs';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import queryString from 'query-string';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import { FIRESTORE_ENDPOINT } from 'config';

const deleteBannerAssetEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.deleteBannerAsset.match),
  mergeMap(({ payload: { banner_asset_id, banner_assets_id } }) => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const params = { event_domain: domain, banner_asset_id, banner_assets_id };

    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.fragments.events.mutations.deleteBannerAsset(banner_asset_id),
        ExecutingTaskCommonTag.Mutation,
      ],
    });

    const internalDeleteAction = eventSettingEditActions.internalDeleteAsset({ banner_asset_id, banner_assets_id });

    const deleteUrl = queryString.stringifyUrl({ url: `${FIRESTORE_ENDPOINT}/bannerAsset`, query: params });
    const deleteBannerAsset = defer(() => fetch(deleteUrl, { method: 'DELETE' }))
      .pipe(
        mergeMap((resp) => resp.json() as Promise<{ success: boolean; bannerAssetId: string; }>),
        mergeMap(({ success }) => (success ? of(internalDeleteAction) : EMPTY)),
        eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
      );
    return deleteBannerAsset;
  }),
);

export default deleteBannerAssetEpic;
