import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DesignEditorDockerComponent } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/designEditorDockerEntry';
import DesignEditorInstanceContext from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';
import {
  Checkbox, FormControlLabel, Input, InputLabel, MenuItem, Select,
} from '@material-ui/core';
import { useLayerFieldBind, useLayerLocalizableFieldSource, useReverseBooleanFieldBind } from 'libs/xtra-custom-booth-design/utils/useLayerFieldBind';
import { TreeView } from '@material-ui/lab';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import { StyledFormControl, StyledTreeItem } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayerAttributesDocker/styles';
import { BoothDesignButtonActionType, Image } from 'models';
import DesignEditorLayerAttributeFileField from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayerAttributesDocker/fields/DesignEditorLayerAttributeFileField';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMuiUrlValidationWarning } from 'libs/xtra-custom-booth-design/utils/useMuiUrlValidationWarning';

/** @see BoothDesignImageButtonMasterElement */
const DesignEditorImageButtonMasterElementAttributesSetting: DesignEditorDockerComponent = (props) => {
  const { actions, selectors, designId } = useContext(DesignEditorInstanceContext);
  const selectedLayersId = useSelector(selectors.selectSelectedLayersId);
  if (selectedLayersId.length !== 1) throw new Error('Selected layer must be 1');
  const layerId = useMemo(() => selectedLayersId[0], [selectedLayersId]);

  /** @see string */
  const keyName = useLayerFieldBind<string>(layerId, 'keyName');

  /** @see Image */
  const normalStateImageSource = useLayerLocalizableFieldSource(layerId, 'normalStateImage');
  const normalStateImageField = useMemo(() => ({ objectId: layerId, fieldName: 'normalStateImage' }), [layerId]);
  const normalStateImage = useLayerFieldBind<Image>(layerId, `${normalStateImageSource}`);

  /** @see Image */
  const hoverStateImageSource = useLayerLocalizableFieldSource(layerId, 'hoverStateImage');
  const hoverStateImageField = useMemo(() => ({ objectId: layerId, fieldName: 'hoverStateImage' }), [layerId]);
  const hoverStateImage = useLayerFieldBind<Image>(layerId, `${hoverStateImageSource}`);

  /** @see boolean */
  const masterControlledAction = useReverseBooleanFieldBind(useLayerFieldBind<boolean>(layerId, 'masterControlledAction'));

  /** @see BoothDesignButtonActionType */
  const actionType = useLayerFieldBind<BoothDesignButtonActionType>(layerId, 'actionType');

  /** @see string */
  const actionExternalLinkUrl = useLayerFieldBind<string>(layerId, 'actionExternalLinkUrl');

  const actionLocalizableExternalLinkUrlSource = useLayerLocalizableFieldSource(layerId, 'actionLocalizableExternalLinkUrl');
  const actionLocalizableExternalLinkUrl = useLayerFieldBind<string>(layerId, actionLocalizableExternalLinkUrlSource);

  const actionExternalLinkUrlWarning = useMuiUrlValidationWarning(actionExternalLinkUrl.value);

  const [expanded, setExpanded] = React.useState<string[]>(['appearance', 'click-action']);
  const handleToggle = (event: React.ChangeEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  const intl = useIntl();

  return (
    <>
      <StyledFormControl fullWidth>
        <InputLabel>
          <FormattedMessage id="event.design.common.layer_name" defaultMessage="Layer Name" />
        </InputLabel>
        <Input color="primary" value={keyName.value} onChange={keyName.onChange} />
      </StyledFormControl>

      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        expanded={expanded}
        onNodeToggle={handleToggle}
      >

        <StyledTreeItem nodeId="appearance" label={intl.formatMessage({ id: 'event.design.attributes.appearance', defaultMessage: 'Appearance' })}>
          <DesignEditorLayerAttributeFileField
            fieldBind={normalStateImage}
            fileField={normalStateImageField}
            accept="image/*"
            label={intl.formatMessage({ id: 'event.design.tool.image_button.normal_state_image', defaultMessage: 'Normal state image' })}
            emptyText={intl.formatMessage({ id: 'common.transparent', defaultMessage: 'Transparent' })}
          />

          <DesignEditorLayerAttributeFileField
            fieldBind={hoverStateImage}
            fileField={hoverStateImageField}
            accept="image/*"
            label={intl.formatMessage({ id: 'event.design.tool.image_button.hover_state_image', defaultMessage: 'Hover state image' })}
            emptyText={intl.formatMessage({ id: 'event.design.tool.image_button.hover_state_image.inherit', defaultMessage: 'Inherit from normal state' })}
          />
        </StyledTreeItem>

        <StyledTreeItem nodeId="click-action" label={intl.formatMessage({ id: 'event.design.tool.image_button.click_action', defaultMessage: 'Click Action' })}>
          <FormControlLabel
            control={(
              <Checkbox
                color="primary"
                checked={masterControlledAction.value}
                onChange={masterControlledAction.onChange}
              />
            )}
            label={intl.formatMessage({ id: 'event.design.tool.image_button.allow_modify_action', defaultMessage: 'Allow Exhibitor Modify Action' })}
          />

          <StyledFormControl fullWidth>
            <InputLabel>
              <FormattedMessage id="booth.design.element.image_button.action_type" defaultMessage="Action Type" />
            </InputLabel>
            <Select
              value={actionType.value}
              onChange={actionType.onChange}
            >
              {
                Object.entries(BoothDesignButtonActionType).map(([name, value]) => (
                  <MenuItem key={value} value={value}>{name}</MenuItem>
                ))
              }
            </Select>
          </StyledFormControl>

          {
            actionType.value === BoothDesignButtonActionType.ExternalLink && (
              <StyledFormControl fullWidth>
                <InputLabel>
                  <FormattedMessage id="event.design.tool.image_button.external_link_url" defaultMessage="External Link Url" />
                </InputLabel>
                <Input
                  color="primary"
                  value={actionExternalLinkUrl.value}
                  onChange={actionExternalLinkUrl.onChange}
                  {...actionExternalLinkUrlWarning}
                />
              </StyledFormControl>
            )
          }

          {
            actionType.value === BoothDesignButtonActionType.LocalizableExternalLink && (
              <StyledFormControl fullWidth>
                <InputLabel>
                  <FormattedMessage id="event.design.tool.image_button.localizable_external_link_url" defaultMessage="External Link Url (Localizable)" />
                </InputLabel>
                <Input
                  color="primary"
                  value={actionLocalizableExternalLinkUrl.value}
                  onChange={actionLocalizableExternalLinkUrl.onChange}
                  {...actionExternalLinkUrlWarning}
                />
              </StyledFormControl>
            )
          }

        </StyledTreeItem>
      </TreeView>
    </>
  );
};

export default React.memo(DesignEditorImageButtonMasterElementAttributesSetting);
