import { EVENT_EDIT_STORE_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { createSelector, OutputSelector } from 'reselect';
import _ from 'lodash';
import { createExecutingTasksSelectors } from 'utils/xtra-executing-tasks';
import { parseSelector, SourceSelector } from 'utils/dummyProxy';
import { Event } from 'models';
import eventEditBoothsV2Selectors
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/eventEditBoothsV2Selectors';
import { EventEditState } from 'admin-data/event/EventEdit/redux/eventEditState';

const selectEventEdit = ((state) => state[EVENT_EDIT_STORE_NAME] as EventEditState);
const selectEvent = createSelector(selectEventEdit, (eventEdit) => eventEdit.event);
const selectEventRootMasterElement = createSelector(selectEvent, (event) => event.rootMasterElement);
const selectEventMasterTabsIdMap = createSelector(selectEventRootMasterElement, (rootMasterElement) => _.keyBy(rootMasterElement.tabs.nodes, ((tab) => tab.id)));
const selectEventMasterTabById = (tabId) => createSelector(selectEventMasterTabsIdMap, (idMap) => idMap[tabId]);
const selectEventId = createSelector(selectEvent, (event) => event.id);
const selectEventName = createSelector(selectEvent, (event) => event.name);
const selectEventDomain = createSelector(selectEvent, (event) => event.domain);
const selectEventFeatures = createSelector(selectEventEdit, (eventEdit) => eventEdit.event.features);
const selectEditLocale = createSelector(selectEventEdit, (eventEdit) => eventEdit.locale);
const selectExecutingTasksState = createSelector(selectEventEdit, (eventEdit) => eventEdit.executingTasks);
const selectIsEventNotFound = createSelector(selectEventEdit, (eventEdit) => eventEdit.isEventNotFound);

/**
 * @deprecated
 */
const selectEventEditField = <T extends any>(source: string | SourceSelector<Event, T>): OutputSelector<any, T, (res: Event) => T> => {
  const rawSource = typeof source === 'function' ? (parseSelector(source)) : source;
  return createSelector(selectEvent, (event) => _.get(event, rawSource) as T);
};

const selectField = <T extends any>(source: string | SourceSelector<EventEditState, T>): OutputSelector<any, T, (res: EventEditState) => T> => {
  const rawSource = typeof source === 'function' ? (parseSelector(source)) : source;
  return createSelector(selectEventEdit, (event) => _.get(event, rawSource) as T);
};

const eventEditSelectors = {
  selectEventEdit,
  selectEventEditField,
  selectField,
  selectEventRootMasterElement,
  selectEvent,
  selectEventId,
  selectEventName,
  selectEventDomain,
  selectEditLocale,
  executingTasks: createExecutingTasksSelectors(selectExecutingTasksState),
  selectIsEventNotFound,
  selectEventFeatures,
  selectEventMasterTabsIdMap,
  selectEventMasterTabById,
  fragments: {
    booths: eventEditBoothsV2Selectors,
  },
};

export default eventEditSelectors;
