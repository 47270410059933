import { DebouncedRequest, rawDesignEditorInstanceActions } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceActions';
import { filter, ignoreElements, mergeMap } from 'rxjs/operators';
import { concat, of } from 'rxjs';
import { Epic } from 'redux-observable';
import { ExecutingTask } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceState';
import { v4 as uuidv4 } from 'uuid';
import createDesignEditorInstanceSelectors from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceSelectors';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import cmsSelectors from 'redux/cmsSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';

const updateCanvasEpic: Epic = (action$, state$) => action$
  .pipe(
    filter(rawDesignEditorInstanceActions.updateCanvas.match),
    mergeMap(({ payload: { instanceDesignId, data: { aspectRatio, maxWidth } } }) => {
      const { selectCanvas } = createDesignEditorInstanceSelectors(instanceDesignId);
      const oldCanvas = selectCanvas(state$.value);
      const newCanvas = { ...oldCanvas, aspectRatio, maxWidth };
      const executingTask: ExecutingTask = createExecutingTask({
        name: 'Updating canvas',
        progress: -1,
        tags: [ExecutingTaskCommonTag.Mutation],
      });

      const debouncedRequest = {
        debounceKey: oldCanvas.id,
        requestFactory: () => {
          const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);

          const request = sdk.designEditorCanvasUpdate({
            input: {
              boothDesignId: instanceDesignId,
              elementId: oldCanvas.id,
              aspectRatio,
              maxWidth,
            },
          });

          return concat(
            of(eventEditActions.executingTasks.upsertExecutingTasks(executingTask)),
            request.pipe(
              throwCommonError(),
              ignoreElements(),
              catchErrorToPopup(state$.value.intl),
            ),
            of(eventEditActions.executingTasks.removeExecutingTasks(executingTask.id)),
          );
        },
      } as DebouncedRequest;

      return concat(
        of(rawDesignEditorInstanceActions.internalUpdateCanvas({
          instanceDesignId,
          data: newCanvas,
        })),
        of(rawDesignEditorInstanceActions.internalAddDebouncedRequest({ instanceDesignId, data: debouncedRequest })),
      );
    }),
  );

export default updateCanvasEpic;
