import { Epic } from 'redux-observable';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import {
  BULK_TASKS_PROGRESS_DIALOG_POPUP_KEY,
  BulkTasksProgressDialogData,
  BulkTaskState,
} from 'utils/xtra-popups/popupsTypes/bulkTasksProgressDialogData';
import { createExecutingTask } from 'utils/xtra-executing-tasks/executingTask';
import cmsActions from 'redux/cmsActions';
import { Popup } from 'utils/xtra-popups/popup';
import { v4 as uuidv4 } from 'uuid';
import {
  concat, defer, merge, of,
} from 'rxjs';
import axios from 'axios';
import { EVENT_FIRESTORE_API, FIRESTORE_ENDPOINT } from 'config';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { EventSettings } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import produce, { Draft } from 'immer';
import eventSettingEditSelectors from 'admin-data/event/EventEdit/redux/eventSettingEditSelectors';
import { initialAuditorium } from 'admin-data/event/EventEdit/redux/requestBundle/auditorium/bundleEventAuditoriumCreate';

const bulkActionDialogInitialStateFactory = () => {
  const id = uuidv4();
  return ({
    id,
    type: BULK_TASKS_PROGRESS_DIALOG_POPUP_KEY,
    data: {
      title: 'Setup auditorium',
      tasksProgress: [],
      progress: -1,
      cancellable: false,
      finished: false,
    },
  } as Popup<BulkTasksProgressDialogData>);
};

const setupAuditoriumEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.setupAuditorium.match),
  mergeMap(() => {
    const eventDomain = eventEditSelectors.selectEventDomain(state$.value);
    let lastDialog = bulkActionDialogInitialStateFactory();

    const createUpdateLastDialogAction = ((content: (draft: Draft<Popup<BulkTasksProgressDialogData>>) => void) => {
      lastDialog = produce(lastDialog, content);
      return cmsActions.popups.upsertPopup(lastDialog);
    });

    const toggleFeatureOn$ = concat(
      defer(() => of(createUpdateLastDialogAction((draft) => {
        draft.data.tasksProgress.push({
          ...createExecutingTask({ name: 'Enable auditorium' }),
          state: BulkTaskState.InProgress,
        });
      }))),
      defer(() => axios.put(`${EVENT_FIRESTORE_API}?event_domain=${eventDomain}`, {
        'featuresToggle.auditoriumWebinar': true,
      })).pipe(mergeMap(() => merge(
        defer(() => of(createUpdateLastDialogAction((draft) => {
          draft.data.progress = 1;
          draft.data.finished = true;
          draft.data.tasksProgress[draft.data.tasksProgress.length - 1].state = BulkTaskState.Done;
        }))),
        of(eventSettingEditActions.internalUpdateFeatures(produce(eventSettingEditSelectors.selectEventEdit(state$.value).fragments.eventSettings.featuresToggle, (draft) => {
          draft.auditoriumWebinar = true;
        }))),
      ))),
    );

    const createDefaultAuditorium$ = concat(
      defer(() => of(createUpdateLastDialogAction((draft) => {
        draft.data.progress = 0.2;
        draft.data.tasksProgress[draft.data.tasksProgress.length - 1].state = BulkTaskState.Done;
        draft.data.tasksProgress.push({
          ...createExecutingTask({ name: 'Create default auditorium' }),
          state: BulkTaskState.InProgress,
        });
      }))),
      defer(() => axios.post(`${FIRESTORE_ENDPOINT}/events/${eventDomain}/auditoriums`, {
        ...initialAuditorium,
        isDefault: true,
      }, {})).pipe(mergeMap(() => of(createUpdateLastDialogAction((draft) => {
        draft.data.progress = 0.6;
        draft.data.tasksProgress[draft.data.tasksProgress.length - 1].state = BulkTaskState.Done;
      })))),
    );

    const checkDefaultAuditorium$ = concat(
      defer(() => of(createUpdateLastDialogAction((draft) => {
        draft.data.tasksProgress.push({
          ...createExecutingTask({ name: 'Check is default auditorium exist' }),
          state: BulkTaskState.InProgress,
        });
      }))),
      defer(() => axios.get(`${FIRESTORE_ENDPOINT}/events/${eventDomain}/webinars/default/auditorium`)).pipe(mergeMap(() => of(createUpdateLastDialogAction((draft) => {
        draft.data.progress = 0.2;
        draft.data.tasksProgress[draft.data.tasksProgress.length - 1].state = BulkTaskState.Done;
      })))),
    );

    return concat(
      of(cmsActions.popups.upsertPopup(lastDialog)),
      defer(() => checkDefaultAuditorium$).pipe(
        catchError((err) => defer(() => createDefaultAuditorium$)),
      ),
      toggleFeatureOn$,
    );
  }),
);

export default setupAuditoriumEpic;
