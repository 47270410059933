import { Epic } from 'redux-observable';
import {
  filter, ignoreElements, map, mergeMap,
} from 'rxjs/operators';
import {
  concat, merge, of,
} from 'rxjs';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import {
  catchErrorToPopup,
  throwCommonError,
} from 'redux/mutationFailedPipe';
import cmsSelectors from 'redux/cmsSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import produce from 'immer';

const directMoveBoothEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.fragments.booths.mutations.directMoveBooth.match),
  mergeMap(({ payload: { id, tierId, orderingPosition } }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.fragments.booths.mutations.directMoveBooth(),
        ExecutingTaskCommonTag.Mutation],
    });

    const requests = sdk.boothUpdate({ input: { tierId, id, orderingPosition } }).pipe(
      throwCommonError(),
      ignoreElements(),
      catchErrorToPopup(state$.value.intl),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    // Cannot do prediction and internal update, because cross page/tier move is possible
    // Therefore we fetch the page again after direct move
    return concat(
      requests,
      of(eventEditActions.fragments.booths.queries.queryBoothTiersList),
    );
  }),
);

export default directMoveBoothEpic;
