import styled from 'styled-components';
import { List, ListItem, ListItemText } from '@material-ui/core';

export const StyledLayersList = styled(List)`
  position: relative;
`;

export const StyledLayerIcon = styled.div`
  display: inline-block;
  min-width: 32px;
`;

export const StyledListItem = styled(ListItem)<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  &.Mui-selected{
    background: #7d7d7d;
  }
  &.Mui-selected:hover{
    background: #a7a7a7;
  }
`;

export const StyledLayerText = styled(ListItemText)`
  & > span{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    width: 100%;
  }
`;

export const StyledLayerActions = styled.div`
  margin: -12px;
`;
