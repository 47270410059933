import { createUpdateParserBuilder } from 'utils/redux-field-bind/updateParser/updateParserBuilder';
import { AuditoriumAsset, AuditoriumAssetsContainerPosition, PositionalAssetData } from 'extra-models/auditorium';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { from } from 'rxjs';
import axios from 'axios';
import { FIRESTORE_ENDPOINT } from 'config';

const webinarAuditoriumAssetUpdateParser = createUpdateParserBuilder<AuditoriumAsset, {
  eventId: string, asset: AuditoriumAsset,
}>((builder0) => {
  builder0.fallback(((state$, attributes, source, value) => {
    const eventDomain = eventEditSelectors.selectEventEdit(state$.value).event.domain;
    return ({
      requestFnProvider: () => (param) => from(
        axios.patch(`${FIRESTORE_ENDPOINT}/events/${eventDomain}/auditoriums/asset/${attributes.asset.id}`, param.input),
      ),
      batchIdentifier: `webinarAuditoriumAssetUpdate-${attributes.asset.id}`,
      fields: { [source]: value },
      inputModifier: (input: any) => input,
    });
  }));
});

const webinarEditingAuditoriumAssetContainerUpdateParser = createUpdateParserBuilder<PositionalAssetData, {
  eventId: string, containerKey: AuditoriumAssetsContainerPosition
}>((builder0) => {
  builder0.nodesHandlerByIndex((state) => state.assets,
    (state$, attributes, index, source, value) => {
      const auditoriumId = eventEditSelectors.selectEventEdit(state$.value).fragments.auditorium.auditoriumEdit.editingAuditorium.id;

      const container = eventEditSelectors.selectEventEdit(state$.value).fragments.auditorium.auditoriumEdit.editingAuditorium.assets[attributes.containerKey];
      const asset = container.assets[index];
      return webinarAuditoriumAssetUpdateParser(state$, { ...attributes, asset }, source, value);
    });
});

export default webinarEditingAuditoriumAssetContainerUpdateParser;
