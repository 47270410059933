import { Epic } from 'redux-observable';
import {
  filter, first, mergeMap, takeUntil,
} from 'rxjs/operators';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import {
  concat, defer, Observable, of,
} from 'rxjs';
import cmsSelectors from 'redux/cmsSelectors';
import { v4 as uuidv4 } from 'uuid';
import {
  BULK_TASKS_PROGRESS_DIALOG_POPUP_KEY, BulkTaskProgress, BulkTasksProgressDialogData, BulkTaskState,
} from 'utils/xtra-popups/popupsTypes/bulkTasksProgressDialogData';
import { ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import cmsActions from 'redux/cmsActions';
import { Attendee, Booth } from 'models';
import { fetchAllPage } from 'utils/requestsUtils';
import { Popup } from 'utils/xtra-popups/popup';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import produce from 'immer';
import { range } from 'lodash';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';

const pageSize = 30;

const convertToCSV = (rows: string[][]) => {
  const content = rows.map((row) => row.map((it) => it?.replaceAll(',', ' ')).join(',')).join('\n');
  return `data:text/csv;charset=utf-8,${content}`;
};

const downloadRepresentativesAsCSV = (locale: string, attendees: Attendee[]) => {
  const header = ['first name', 'last name', 'email', 'company', 'title', 'access url'];
  const rows = attendees.map((attendee) => [attendee.firstName, attendee.lastName, attendee.email, attendee.company, attendee.title, attendee.vexpoAccessUrl]);

  const csvDataUrl = encodeURI(convertToCSV([header, ...rows]));

  const downloadEl = document.createElement('a');
  downloadEl.target = '_blank';
  downloadEl.href = csvDataUrl;
  downloadEl.download = 'booth_representatives.csv';
  downloadEl.click();
};

const bulkActionDialogInitialStateFactory = () => {
  const id = uuidv4();
  return ({
    id,
    type: BULK_TASKS_PROGRESS_DIALOG_POPUP_KEY,
    data: {
      title: 'Export all booth representatives as CSV',
      tasksProgress: [{
        id: uuidv4(),
        name: 'Fetch booth representatives list page 1',
        progress: -1,
        tags: [ExecutingTaskCommonTag.Mutation],
        state: BulkTaskState.InProgress,
        error: [],
      } as BulkTaskProgress],
      progress: -1,
      cancellable: true,
      finished: false,
      cancelAction: cmsActions.popups.createPopups.confirmationDialog(
        'Confirm cancel action', 'Are you sure to cancel the action?',
        cmsActions.popups.bulkTasks.cancel(id),
      ),
    },
  } as Popup<BulkTasksProgressDialogData>);
};

const exportAllBoothRepresentativesEpic: Epic = (action$, state$) => action$.pipe(
  filter(boothEditActions.queries.exportAllBoothRepresentatives.match),
  mergeMap(() => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const locales = [eventEditSelectors.selectEditLocale(state$.value)];

    let lastDialog = bulkActionDialogInitialStateFactory();

    const cancelAction$ = action$.pipe(
      filter(cmsActions.popups.bulkTasks.cancel.match),
      filter(({ payload: dialogId }) => dialogId === lastDialog.id),
      first(),
    );

    return concat(
      of(cmsActions.popups.upsertPopup(lastDialog)),
      defer(() => {
        let totalPages = 1;

        const boothId = boothEditSelectors.selectBoothEditState(state$.value).booth.id;

        return new Observable((subscriber) => {
          let fetchedPage = 0;
          let isFirstPage = true;
          fetchAllPage(
            (after) => {
              lastDialog = produce(lastDialog, (draft) => {
                draft.data.tasksProgress[fetchedPage].state = BulkTaskState.InProgress;
              });
              subscriber.next(cmsActions.popups.upsertPopup(lastDialog));
              return sdk.boothEditGetRepresentativesList({ boothId, after, deleted: false });
            },
            (resp) => {
              totalPages = Math.ceil((resp.node as Booth).representatives.totalCount) / pageSize;

              if (isFirstPage) {
                isFirstPage = false;
                lastDialog = produce(lastDialog, (draft) => {
                  draft.data.tasksProgress = (range(0, totalPages).map((pageIndex) => ({
                    id: uuidv4(),
                    name: `Fetch booth representatives list page ${pageIndex + 1}`,
                    progress: -1,
                    state: BulkTaskState.Waiting,
                    error: [],
                  })));
                });
              }

              lastDialog = produce(lastDialog, (draft) => {
                draft.data.tasksProgress[fetchedPage].state = BulkTaskState.Done;
              });
              fetchedPage += 1;
              subscriber.next(cmsActions.popups.upsertPopup(lastDialog));

              return (resp.node as Booth).representatives.pageInfo;
            },
          ).pipe(takeUntil(cancelAction$))
            .subscribe((resp) => {
              lastDialog = produce(lastDialog, (draft) => {
                draft.data.finished = true;
              });

              subscriber.next(cmsActions.popups.upsertPopup(lastDialog));
              subscriber.complete();

              downloadRepresentativesAsCSV(locales[0], (resp.node as Booth).representatives.nodes);
            });
        });
      }),
    );
  }),
);

export default exportAllBoothRepresentativesEpic;
