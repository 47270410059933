import { Epic } from 'redux-observable';
import { filter, map, mergeMap } from 'rxjs/operators';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { boothEditExecutingTasksTagCreator } from 'admin-data/booth/BoothEdit/redux/boothEditExecutingTasks';
import produce from 'immer';
import { BoothContentElement } from 'models';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import cmsSelectors from 'redux/cmsSelectors';

const addContentTabBodyElementEpic: Epic = (action$, state$) => action$.pipe(
  filter(boothEditActions.mutations.addContentTabBodyElement.match),
  mergeMap(({ payload: { parentElementId } }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const boothId = boothEditSelectors.selectBoothId(state$.value);
    const originalBoothFactory = () => boothEditSelectors.selectBooth(state$.value);
    const locale = boothEditSelectors.selectLocale(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [
        boothEditExecutingTasksTagCreator.mutations.addContentTabBodyElement(),
        ExecutingTaskCommonTag.Mutation],
    });

    return sdk.boothFileContentElementCreate({ input: { boothId, parentElementId }, locales: [locale] }).pipe(
      throwCommonError(),
      map((resp) => boothEditActions.internalUpsertBooth(produce(originalBoothFactory(), ((draft) => {
        draft.rootContentElement.tabs.nodes.find((tab) => tab.id === parentElementId).body.nodes.push(resp.boothFileContentElementCreate.element as unknown as BoothContentElement);
      })))),
      catchErrorToPopup(state$.value.intl),
      boothEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );
  }),
);

export default addContentTabBodyElementEpic;
