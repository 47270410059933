import axios from 'axios';
import { defer, of } from 'rxjs';
import { EVENT_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { createStandardEventEditRequestBundle } from 'admin-data/event/EventEdit/redux/createStandardEventEditRequestBundle';
import { ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { map } from 'rxjs/operators';
import { FIRESTORE_ENDPOINT } from 'config';
import { internalUpsertWebinar, selectWebinarById } from 'admin-data/webinar/redux/webinars';

const bundleEventAuditoriumDelete = createStandardEventEditRequestBundle<string>({
  actionName: `${EVENT_EDIT_ACTIONS_NAME}/auditorium/bundleEventAuditoriumDelete`,
  additionalTaskTags: [ExecutingTaskCommonTag.Mutation],
  isSequenced: true,

  requestFactory: ({
    eventDomain, locale, authToken, payload,
  }) => defer(() => axios.delete(`${FIRESTORE_ENDPOINT}/events/${eventDomain}/auditoriums/${payload}`, {}))
    .pipe(map((resp) => resp.data)),

  requestResponseMapper: ({
    response, createUpdateEventEditAction, payload: webinarId, state$,
  }) => of(createUpdateEventEditAction((eventEditState) => {
    const internalUpdateWebinar$ = of(internalUpsertWebinar({
      ...selectWebinarById(state$.value, webinarId),
      auditoriumId: null,
    }));
  })),
});

export default bundleEventAuditoriumDelete;
