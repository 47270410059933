import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, takeUntil,
} from 'rxjs/operators';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import { concat, defer, of } from 'rxjs';
import eventSettingEditSelectors from 'admin-data/event/EventEdit/redux/eventSettingEditSelectors';
import { HallPage } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { EVENT_FIRESTORE_API } from 'config';

const fetchEventSettingHallEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.fetchHallPage.match),
  mergeMap(() => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const getEventUrl = `${EVENT_FIRESTORE_API}?event_domain=${domain}`;
    const fetchHallPage = defer(() => fetch(getEventUrl));

    return concat(
      of(eventSettingEditActions.internalUpdateLoadingHall(true)),
      fetchHallPage.pipe(
        mergeMap((resp) => resp.json() as Promise<{ hall: HallPage }>),
        map(({ hall }) => {
          const currentHallPage = eventSettingEditSelectors.selectEventHallPage(state$.value);
          return eventSettingEditActions.internalUpdateHallPage({ ...currentHallPage, ...hall });
        }),
      ),
      of(eventSettingEditActions.internalUpdateLoadingHall(false)),
    ).pipe(
      takeUntil(action$.pipe(filter(eventSettingEditActions.fetchHallPage.match))), // fetch until another fetch started
    );
  }),
);

export default fetchEventSettingHallEpic;
