import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DesignEditorDockerComponent } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/designEditorDockerEntry';
import DesignEditorInstanceContext
  from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';
import {
  Checkbox, FormControlLabel, Input, InputLabel,
} from '@material-ui/core';
import {
  useLayerFieldBind,
  useLayerLocalizableFieldSource,
  useReverseBooleanFieldBind,
} from 'libs/xtra-custom-booth-design/utils/useLayerFieldBind';
import { TreeView } from '@material-ui/lab';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import {
  StyledFormControl,
  StyledTreeItem,
} from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayerAttributesDocker/styles';
import { Image } from 'models';
import DesignEditorLayerAttributeFileField
  from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayerAttributesDocker/fields/DesignEditorLayerAttributeFileField';
import { ColorResult, SketchPicker } from 'react-color';
import { FormattedMessage, useIntl } from 'react-intl';

/** @see BoothDesignColorFillMasterElement */
const DesignEditorColorFillMasterElementAttributesSetting: DesignEditorDockerComponent = (props) => {
  const { actions, selectors, designId } = useContext(DesignEditorInstanceContext);
  const selectedLayersId = useSelector(selectors.selectSelectedLayersId);
  if (selectedLayersId.length !== 1) throw new Error('Selected layer must be 1');
  const layerId = useMemo(() => selectedLayersId[0], [selectedLayersId]);
  const intl = useIntl();

  /** @see string */
  const keyName = useLayerFieldBind<string>(layerId, 'keyName');

  /** @see Image */
  const maskImageSource = useLayerLocalizableFieldSource(layerId, 'maskImage');
  const maskImageField = useMemo(() => ({ objectId: layerId, fieldName: 'maskImage' }), [layerId]);
  const maskImage = useLayerFieldBind<Image>(layerId, `${maskImageSource}`);

  /** @see string */
  const fillColor = useLayerFieldBind<string>(layerId, 'fillColor');
  const onFillColorChange = useMemo(() => (color: ColorResult) => fillColor.change(color.hex), [fillColor]);

  /** @see boolean */
  const masterControlledFillColor = useReverseBooleanFieldBind(useLayerFieldBind<boolean>(layerId, 'masterControlledFillColor'));

  const [expanded, setExpanded] = React.useState<string[]>(['appearance', 'click-action']);
  const handleToggle = (event: React.ChangeEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  return (
    <>
      <StyledFormControl fullWidth>
        <InputLabel>
          <FormattedMessage id="event.design.common.layer_name" defaultMessage="Layer Name" />
        </InputLabel>
        <Input color="primary" value={keyName.value} onChange={keyName.onChange} />
      </StyledFormControl>

      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        expanded={expanded}
        onNodeToggle={handleToggle}
      >

        <StyledTreeItem
          nodeId="appearance"
          label={intl.formatMessage({
            id: 'event.design.attributes.appearance',
            defaultMessage: 'Appearance',
          })}
        >
          <DesignEditorLayerAttributeFileField
            fieldBind={maskImage}
            fileField={maskImageField}
            accept="image/*"
            label={intl.formatMessage({ id: 'event.design.tool.color_fill.mask_image', defaultMessage: 'Mask Image' })}
            emptyText={intl.formatMessage({ id: 'common.transparent', defaultMessage: 'Transparent' })}
            uploadHints="* ColorFill works best with grayscale image"
          />
          <FormControlLabel
            control={(
              <Checkbox
                color="primary"
                checked={masterControlledFillColor.value}
                onChange={masterControlledFillColor.onChange}
              />
            )}
            label={intl.formatMessage({
              id: 'event.design.tool.color_fill.allow_modify_fill_color',
              defaultMessage: 'Allow Exhibitor Modify Fill Color',
            })}
          />
          <SketchPicker
            disableAlpha
            color={fillColor.value ?? '#000'}
            onChange={onFillColorChange}
          />
        </StyledTreeItem>
      </TreeView>
    </>
  );
};

export default React.memo(DesignEditorColorFillMasterElementAttributesSetting);
