import createUpdateFieldsEpic from 'utils/redux-update-model/createUpdateFieldsEpic';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import eventUpdateParser from 'admin-data/event/EventEdit/redux/updateParsers/eventUpdateParser';

/** @deprecated */
const updateEventFieldsEpic = createUpdateFieldsEpic(
  eventEditActions.updateEventFields,
  eventEditActions.internalUpdateEventFields,
  eventEditActions.internalAddBatchedDebouncedUpdateEventRequest,
  (state$, field) => ({
    ...eventUpdateParser(
      state$, {
        eventId: eventEditSelectors.selectEventId(state$.value),
        originalSource: field.source,
      }, field.source, field.value,
    ),
    lessDebounce: field.lessDebounce,
  }),
);

export default updateEventFieldsEpic;
