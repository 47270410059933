import { Epic } from 'redux-observable';
import {
  filter, first, map, mergeMap, takeUntil,
} from 'rxjs/operators';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import { concat, defer } from 'rxjs';
import { EventSettings } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import produce from 'immer';
import { ExecutingTask } from 'utils/xtra-executing-tasks';
import { createExecutingTask } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import { eventEditMutationCompleteObservable } from 'admin-data/event/EventEdit/redux/epic/utils';
import { EVENT_FIRESTORE_API } from 'config';

const fetchEventSettingGeneralV2Epic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.fetchGeneralSettingsV2.match),
  mergeMap(() => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const getEventUrl = `${EVENT_FIRESTORE_API}?event_domain=${domain}`;
    const fetchGeneralSettings = defer(() => fetch(getEventUrl));

    const executingTaskFactory: () => ExecutingTask = () => createExecutingTask({
      tags: [eventEditExecutingTasksTagCreator.fragments.eventGeneralSettings.queries.fetchGeneralSettings()],
    });

    const request = fetchGeneralSettings.pipe(
      mergeMap((resp) => resp.json() as Promise<EventSettings>),
      map((eventSettings) => eventEditActions.internalUpdate(produce(
        eventEditSelectors.selectEventEdit(state$.value), (draft) => {
          draft.fragments.eventSettings.name = eventSettings.name;
          draft.fragments.eventSettings.startTime = eventSettings.startTime;
          draft.fragments.eventSettings.endTime = eventSettings.endTime;
          draft.fragments.eventSettings.landing.timeZone = eventSettings.landing.timeZone;
          draft.fragments.eventSettings.availableLocales = eventSettings.availableLocales;
          draft.fragments.eventSettings.featuresToggle = eventSettings.featuresToggle;
          draft.fragments.eventSettings.themeColor = eventSettings.themeColor;
        },
      ))),
      takeUntil(action$.pipe(filter(eventSettingEditActions.fetchGeneralSettingsV2.match), first())), // fetch until another fetch started
    );

    return concat(
      eventEditMutationCompleteObservable(action$, state$),
      request,
    ).pipe(eventEditActions.executingTasks.wrapWithExecutingTaskAction(executingTaskFactory));
  }),
);

export default fetchEventSettingGeneralV2Epic;
