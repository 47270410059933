import hintEventEditBoothDesignCreateElement from 'hints/event/hintEventEditBoothDesignCreateElement';
import hintEventEditBoothDesignTransformReorderElement from 'hints/event/hintEventEditBoothDesignTransformReorderElement';
import hintBoothEditRepresentativesHorizontalScrollableElement from 'hints/booth/hintBoothEditRepresentativesHorizontalScrollableElement';
import hintEventEditWebinarAuditoriumEdit from 'hints/event/hintEventEditWebinarAuditoriumEdit';

const cmsHintsMap = {
  [hintEventEditBoothDesignCreateElement.key]: hintEventEditBoothDesignCreateElement,
  [hintEventEditBoothDesignTransformReorderElement.key]: hintEventEditBoothDesignTransformReorderElement,
  [hintEventEditWebinarAuditoriumEdit.key]: hintEventEditWebinarAuditoriumEdit,
  [hintBoothEditRepresentativesHorizontalScrollableElement.key]: hintBoothEditRepresentativesHorizontalScrollableElement,
};

export default cmsHintsMap;
