import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { EVENT_FIRESTORE_API } from 'config';

const updateEventSettingFieldsEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.updateEventSettingFields.match),
  mergeMap((action) => action.payload.fields),
  mergeMap((field) => [
    ...(field.skipInternalUpdate ? [] : [eventSettingEditActions.internalUpdateEventSettingFields({ fields: [field] })]),
    ...(field.skipExternalUpdate ? [] : [eventSettingEditActions.internalAddBatchedDebouncedUpdateEventSettingRequestEpic({
      requestFnProvider: (_) => ({ input: { fields } }) => {
        const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);

        return from(fetch(`${EVENT_FIRESTORE_API}?event_domain=${domain}`, {
          body: JSON.stringify(fields),
          headers: {
            'content-type': 'application/json',
          },
          method: 'PUT',
        }));
      },
      batchIdentifier: 'eventSettingRequest',
      fields: {
        [field.source]: field.value,
      },
      inputModifier: (input) => input,
    })]),
  ]),
);

export default updateEventSettingFieldsEpic;
