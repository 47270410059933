import { createSelector } from 'reselect';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';

const selectFragment = createSelector(eventEditSelectors.selectEventEdit, (eventEdit) => eventEdit.fragments.booths);
const selectBoothsList = createSelector(selectFragment, (fragment) => fragment.boothsList);
const selectBoothCreation = createSelector(selectFragment, (fragment) => fragment.boothCreation);
const selectLoadingBooths = createSelector(selectFragment, (fragment) => fragment.loadingBooths);

const eventEditBoothsSelectors = {
  selectFragment,
  selectBoothsList,
  selectBoothCreation,
  selectLoadingBooths,
};

export default eventEditBoothsSelectors;
