import { ExecutingTask } from 'utils/xtra-executing-tasks/executingTask';

interface ExecutingTaskIdMap {
  [taskId: string]: ExecutingTask
}

interface FinishedTaskIdMap {
  [taskId: string]: ExecutingTask
}

export interface ExecutingTasksState {
  tasksIdMap: ExecutingTaskIdMap
  tasksTagMap: { [tag: string]: ExecutingTaskIdMap }

  finishedTasksIdMap: FinishedTaskIdMap
  finishedTasksTagMap: { [tag: string]: FinishedTaskIdMap }

}

export const executingTasksInitialState: ExecutingTasksState = {
  tasksIdMap: {},
  tasksTagMap: {},
  finishedTasksIdMap: {},
  finishedTasksTagMap: {},
};
