import { Action, createAction } from '@reduxjs/toolkit';
import { EVENT_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { BoothRootMasterElement, Event } from 'models';
import { Observable } from 'rxjs';
import { FieldsUpdatePayload } from 'utils/redux-field-bind/reduxBindAdapter';
import { createExecutingTasksActions } from 'utils/xtra-executing-tasks';
import { BatchedDebouncedUpdateRequest } from 'utils/redux-field-bind/updateParser/updateParserBuilder';
import { BoothMasterElementType } from 'admin-data/event/EventEdit/typenameEnums';
import eventEditBoothsV2Actions
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/eventEditBoothsV2Actions';
import { EventEditState } from 'admin-data/event/EventEdit/redux/eventEditState';
import { createUploadFileAction } from 'utils/xtra-upload-actions/uploadFile';
import { createUploadFirebaseFileAction } from 'utils/xtra-upload-actions/uploadFirebaseFile';

interface AddTabBodyMasterElementActionPayload {
  typename: BoothMasterElementType;
  orderingPosition: number;
  tabId: string;
  parentElementId?: string;
}

interface ReorderTabBodyMasterElementActionPayload {
  id: string;
  tabId: string;
  orderingPosition: number;
}

interface DeleteTabBodyMasterElementActionPayload {
  id: string;
  tabId: string;
}

const eventEditActions = {
  // initializeByEventId: createAction<string>(`${EVENT_EDIT_ACTIONS_NAME}/initializeByEventId`),
  updateFields: createAction<FieldsUpdatePayload>(`${EVENT_EDIT_ACTIONS_NAME}/updateFields`),
  /**
   * Fetch the event details
   * For EventEdit initialize / update event state
   */
  updateEventFields: createAction<FieldsUpdatePayload>(`${EVENT_EDIT_ACTIONS_NAME}/updateEventFields`),
  updateEditLocale: createAction<string>(`${EVENT_EDIT_ACTIONS_NAME}/updateEditLocale`),

  queries: {
    initializeEvent: createAction<string>(`${EVENT_EDIT_ACTIONS_NAME}/queries/initializeEvent`),
    queryMaserTabsList: createAction(`${EVENT_EDIT_ACTIONS_NAME}/queries/queryMasterTabsList`),
    queryMasterTabById: createAction<string>(`${EVENT_EDIT_ACTIONS_NAME}/queries/queryMasterTabById`),
    queryBoothTaxonomiesList: createAction(`${EVENT_EDIT_ACTIONS_NAME}/queries/queryBoothTaxonomiesList`),
  },

  mutations: {
    // master content tabs
    addTabMasterElement: createAction<string>(`${EVENT_EDIT_ACTIONS_NAME}/mutations/addTabMasterElement`),
    deleteTabMasterElement: createAction<string>(`${EVENT_EDIT_ACTIONS_NAME}/mutations/deleteTabMasterElement`),
    reorderTabMasterElement: createAction<{ id: string, orderingPosition: number }>(`${EVENT_EDIT_ACTIONS_NAME}/mutations/reorderTabMasterElement`),

    addTabBodyMasterElement: createAction<AddTabBodyMasterElementActionPayload>(`${EVENT_EDIT_ACTIONS_NAME}/mutations/addTabBodyMasterElement`),
    deleteTabBodyMasterElement: createAction<DeleteTabBodyMasterElementActionPayload>(`${EVENT_EDIT_ACTIONS_NAME}/mutations/deleteTabBodyMasterElement`),
    reorderTabBodyMasterElement: createAction<ReorderTabBodyMasterElementActionPayload>(`${EVENT_EDIT_ACTIONS_NAME}/mutations/reorderTabBodyMasterElement`),

    // categories
    deleteCategory: createAction<string>(`${EVENT_EDIT_ACTIONS_NAME}/mutations/deleteCategory`),
    addCategory: createAction<{ parentId: string }>(`${EVENT_EDIT_ACTIONS_NAME}/mutations/addCategory`),
    addSupercategory: createAction<{ parentId: string }>(`${EVENT_EDIT_ACTIONS_NAME}/mutations/addSupercategory`),
    reorderCategory: createAction<{ id: string, sameParentOrderingPosition: number }>(`${EVENT_EDIT_ACTIONS_NAME}/mutations/reorderCategory`),

  },

  internalUpdateIsEventNotFound: createAction<boolean>(`${EVENT_EDIT_ACTIONS_NAME}/_updateIsEventNotFound`),

  internalResetInitialState: createAction(`${EVENT_EDIT_ACTIONS_NAME}/_resetInitialState`),
  internalUpdate: createAction<EventEditState>(`${EVENT_EDIT_ACTIONS_NAME}/_update`),
  /** @deprecated use internalUpdate(edit.event) directly  */
  internalUpdateEvent: createAction<Event>(`${EVENT_EDIT_ACTIONS_NAME}/_updateEvent`),
  /** @deprecated use internalUpdate(edit.event.rootMasterElement) directly  */
  internalUpdateEventRootMasterElement: createAction<BoothRootMasterElement>(`${EVENT_EDIT_ACTIONS_NAME}/_updateEventRootMasterElement`),
  /** @deprecated use internalUpdate directly  */
  internalUpdateEventFields: createAction<FieldsUpdatePayload>(`${EVENT_EDIT_ACTIONS_NAME}/_updateEventFields`),
  internalUpdateFields: createAction<FieldsUpdatePayload>(`${EVENT_EDIT_ACTIONS_NAME}/_updateFields`),

  internalAddSequencedRequest: createAction<Observable<Action>>(`${EVENT_EDIT_ACTIONS_NAME}/_addSequencedRequest`),
  internalAddBatchedDebouncedUpdateEventRequest: createAction<BatchedDebouncedUpdateRequest<any, any>>(`${EVENT_EDIT_ACTIONS_NAME}/_addBatchedDebouncedUpdateEventRequest`),

  executingTasks: createExecutingTasksActions(EVENT_EDIT_ACTIONS_NAME),
  uploadFile: createUploadFileAction(EVENT_EDIT_ACTIONS_NAME),
  uploadFirebaseFile: createUploadFirebaseFileAction(EVENT_EDIT_ACTIONS_NAME),

  fragments: {
    booths: eventEditBoothsV2Actions,
  },
};

export default eventEditActions;
