import React, { useCallback, useLayoutEffect, Suspense } from 'react';
import createAdminStore from 'admin-data/createAdminStore';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';
import CMSUILocalizationContextProvider from 'components/localization/CMSUILocalizationContext';
import NotFoundPage from 'special-pages/NotFoundPage';
import routes from 'admin-data/routes';
import XtraSnackbarPopups from 'components/popups/XtraSnackbarPopups';
import { createMuiTheme, StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import XtraConfirmationDialogPopups from 'components/popups/XtraConfirmationDialogPopups';
import cmsSelectors from 'redux/cmsSelectors';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import cmsActions from 'redux/cmsActions';
import XtraBulkTasksProgressDialogPopups from 'components/popups/XtraBulkTasksProgressDialogPopups';
import SettingsDialog from 'components/popups/dialogs/settings-dialog/SettingsDialog';
import XtraHintDialog from 'utils/xtra-hints/components/XtraHintDialog';
import cmsHintsMap from 'hints';
import { Route, Router, Switch } from 'react-router';
import XtraErrorBoundary from 'components/XtraErrorBoundary';

const history = createBrowserHistory();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#cf2f2f',
    },
  },
});

const StyledProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LocalizedApp = () => {
  const isTranslationLoaded = useSelector(cmsSelectors.selectIsTranslationLoaded);
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(cmsActions.fetchTranslations());
  }, [dispatch]);

  const settingOpen = useSelector(cmsSelectors.selectSettingDialogOpen);
  const onSettingClose = useCallback(() => {
    dispatch(cmsActions.updateSettingDialogOpen(false));
  }, [dispatch]);

  return !isTranslationLoaded ? <StyledProgressContainer><CircularProgress /></StyledProgressContainer> : (
    <CMSUILocalizationContextProvider>
      <Router history={history}>
        <XtraErrorBoundary>
          <Suspense fallback={<div />}>
            <Switch>
              {routes.map((route) => route)}
              <Route component={NotFoundPage} />
            </Switch>
          </Suspense>
        </XtraErrorBoundary>
      </Router>
      <SettingsDialog open={settingOpen} onClose={onSettingClose} />
      <XtraSnackbarPopups />
      <XtraBulkTasksProgressDialogPopups />
      <XtraConfirmationDialogPopups />
      <XtraHintDialog hintsMap={cmsHintsMap} />
    </CMSUILocalizationContextProvider>
  );
};

const App = () => {
  const adminStore = createAdminStore({ history });

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={adminStore}>
          <LocalizedApp />
        </Provider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
