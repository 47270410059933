import React, { useCallback, useState } from 'react';
import {
  ImageFileContentPreviewer as ImagePreviewer
} from 'libs/xtra-custom-booth-design/components/shared/XtraDropZoneDialog/FilePreviewer/fileContentPreviewers/fileContentPreviewer';
import { Link } from '@material-ui/core';
import {
  ContentPreviewerContainer, FileInfoContainer, FileNameContainer, PreviewArea,
} from 'libs/xtra-custom-booth-design/components/shared/XtraDropZoneDialog/FilePreviewer/style';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const StyledImg = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-height: inherit;
`;

const ImageFileContentPreviewer: ImagePreviewer = ({ filePreview, direction }) => {
  const [dimensions, setDimensions] = useState(null);

  const onImageLoad = useCallback((e) => {
    setDimensions(`${e.target.naturalWidth} x ${e.target.naturalHeight}`);
  }, []);
  return (
    <ContentPreviewerContainer direction={direction}>
      <PreviewArea>
        <StyledImg onLoad={onImageLoad} src={filePreview.originalImageUrl || filePreview.fileUrl} alt="preview image" />
      </PreviewArea>
      <FileInfoContainer>
        <table>
          <tr>
            <th>
              <FormattedMessage id="common.file_name" defaultMessage="File Name" />
              :
            </th>
            <td>
              <Link color="primary" target="_blank" download={filePreview.filename} href={filePreview.fileUrl}>
                <FileNameContainer>
                  {filePreview.filename}
                </FileNameContainer>
              </Link>
            </td>
          </tr>
          <tr>
            <th>
              <FormattedMessage id="shared.dropzone.preview.image_resolution" defaultMessage="Image Resolution" />
              :
            </th>
            <td>{dimensions}</td>
          </tr>
        </table>
      </FileInfoContainer>
    </ContentPreviewerContainer>
  );
};

export default ImageFileContentPreviewer;
