import styled from 'styled-components';
import { TreeItem } from '@material-ui/lab';
import { FormControl } from '@material-ui/core';

export const StyledTreeItem = styled(TreeItem)`
  & > ul[class^="MuiTreeItem-group-"], & > ul[class*=" MuiTreeItem-group-"] {
    padding-bottom: 8px;
  }
`;

export const StyledFormControl = styled(FormControl)`
  margin: 8px 0;
`;
