import {
  faCommentDots,
  faFillDrip,
  faHandshake,
  faImage,
  faLink,
  faMousePointer,
  faPhotoVideo,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import React, { ReactElement } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IntlShape } from 'react-intl';

enum CanvasTools {
  Normal = 'Normal',
  CreateImageButton = 'CreateImageButton',
  CreateColorFill = 'CreateColorFill',
  CreateContentArea = 'CreateContentArea',
  CreateImage = 'CreateImage',
  CreateNetworkingWidget = 'CreateNetworkingWidget'
}

export interface ToolboxItem {
  Icon: (() => ReactElement) | IconDefinition;
  tool?: CanvasTools;
  title: string;
  disabled?: boolean;
}

export const TOOLBOX_ITEMS_CREATOR: (intl: IntlShape) => { [toolType: string]: ToolboxItem } = (intl) => [
  {
    Icon: faMousePointer,
    tool: CanvasTools.Normal,
    title: intl.formatMessage({ id: 'event.design.tool.normal', defaultMessage: 'Normal Mode' }),
  },
  {
    Icon: faImage,
    tool: CanvasTools.CreateImage,
    title: intl.formatMessage({ id: 'event.design.tool.image', defaultMessage: 'Create Image' }),
    disabled: false,
  },
  {
    Icon: faPhotoVideo,
    tool: CanvasTools.CreateContentArea,
    title: intl.formatMessage({ id: 'event.design.tool.content_area', defaultMessage: 'Create Content Area' }),
    disabled: false,
  },
  {
    Icon: () => (
      <>
        <FontAwesomeIcon icon={faImage} />
        <FontAwesomeIcon icon={faLink} transform="shrink-2 right-4 down-4" />
      </>
    ),
    tool: CanvasTools.CreateImageButton,
    title: intl.formatMessage({ id: 'event.design.tool.image_button', defaultMessage: 'Create Image Button' }),
  },
  {
    Icon: faFillDrip,
    tool: CanvasTools.CreateColorFill,
    title: intl.formatMessage({ id: 'event.design.tool.color_fill', defaultMessage: 'Create Color Fill' }),
    disabled: false,
  },
  {
    Icon: faCommentDots,
    tool: CanvasTools.CreateNetworkingWidget,
    title: intl.formatMessage({
      id: 'event.design.tool.networking_widget',
      defaultMessage: 'Create Networking Widget',
    }),
  },
].reduce((obj, next) => {
  obj[next.tool] = next;
  return obj;
}, {});

export default CanvasTools;
