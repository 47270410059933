import { InputLabel, Menu, MenuItem } from '@material-ui/core';
import React, { FC, useCallback, useContext } from 'react';
import DesignEditorInstanceContext
  from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';
import { useDispatch } from 'react-redux';
import { DesignEditorLayerInfo } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayersDocker/DesignEditorLayersList';
import { FormattedMessage } from 'react-intl';

interface DesignEditorLayersListItemMenu {
  layer: DesignEditorLayerInfo;
  anchorPosition: { mouseX?: number; mouseY?: number; }
  open: boolean;
  onClose: () => any;
}

const DesignEditorLayersListItemMenu: FC<DesignEditorLayersListItemMenu> = ({
  layer, anchorPosition, open, onClose,
}) => {
  const { actions, selectors, designId } = useContext(DesignEditorInstanceContext);
  const dispatch = useDispatch();

  const onDelete = useCallback(() => {
    dispatch(actions.deleteLayerById(layer.id));
    onClose();
  }, [actions, dispatch, layer.id, onClose]);

  return (
    <Menu
      keepMounted
      open={open}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={{ top: anchorPosition.mouseY, left: anchorPosition.mouseX }}
    >
      <MenuItem onClick={onDelete}>
        <FormattedMessage id="common.delete" defaultMessage="Delete" />
      </MenuItem>
    </Menu>
  );
};

export default DesignEditorLayersListItemMenu;
