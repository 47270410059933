import React, {
  FC, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { DimensionFieldFormControl } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorTransformDocker/style';
import { Input, InputAdornment, InputLabel } from '@material-ui/core';
import { batch, useDispatch, useSelector } from 'react-redux';
import DesignEditorInstanceContext from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';
import { DESIGN_EDITOR_TRANSFORM_CONTROL_POINT_CONFIGS } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/DesignEditorInstanceCanvas/overlay-controls/DesignEditorTransformOverlay/DesignEditorTransformSizeControlPoint';
import _ from 'lodash';
import { tryOrNull } from 'libs/xtra-custom-booth-design/utils/fp';
import { to2dp } from 'libs/xtra-custom-booth-design/utils/maths';
import { BoothDesignMasterElement } from 'models';
import {
  BorderBottom, BorderLeft, BorderRight, BorderTop,
} from '@material-ui/icons';
import styled from 'styled-components';

const configs = {
  a: {
    x: { ...DESIGN_EDITOR_TRANSFORM_CONTROL_POINT_CONFIGS.w, source: 'a.x' },
    y: { ...DESIGN_EDITOR_TRANSFORM_CONTROL_POINT_CONFIGS.n, source: 'a.y' },
  },
  b: {
    x: { ...DESIGN_EDITOR_TRANSFORM_CONTROL_POINT_CONFIGS.e, source: 'b.x' },
    y: { ...DESIGN_EDITOR_TRANSFORM_CONTROL_POINT_CONFIGS.s, source: 'b.y' },
  },
};

export interface DesignEditorTransformDimensionFieldProps {
  position: 'a' | 'b';
  dimension: 'x' | 'y';
  label: string;
}

const isEnter = (e) => {
  if (e.key === undefined) return true;
  return e.key === 'Enter';
};

const StyledIconContainer = styled.div`
  padding-right: 8px;
  color: rgba(255, 255, 255, 0.7);
`;

const DesignEditorTransformDimensionField: FC<DesignEditorTransformDimensionFieldProps> = ({ position, dimension, label }) => {
  const { actions, selectors, designId } = useContext(DesignEditorInstanceContext);
  const selectedLayersBoundingBox = useSelector(selectors.selectSelectedLayerBoundingBox);
  const dispatch = useDispatch();

  const source = useMemo(() => `${position}.${dimension}`, [dimension, position]);

  const { aspectRatioLock } = useSelector(selectors.selectToolConfig).transform;
  const selectedLayers = useSelector(selectors.selectSelectedLayers);
  const [tmpValue, setTmpValue] = useState(null);

  useEffect(() => setTmpValue(
    selectedLayersBoundingBox == null
      ? null
      : selectedLayersBoundingBox[position][dimension].toFixed(2),
  ), [dimension, position, selectedLayersBoundingBox]);

  const [lastChangeIsFromKeyboard, setLastChangeIsFromKeyboard] = useState(false);

  const tryToCommitChange = useCallback((committingValue?: any) => {
    const newValue = committingValue ?? tryOrNull(() => parseFloat(tmpValue));
    if (newValue == null) return;

    const originalBoundingBoxSize = {
      width: selectedLayersBoundingBox.b.x - selectedLayersBoundingBox.a.x,
      height: selectedLayersBoundingBox.b.y - selectedLayersBoundingBox.a.y,
    };

    const draggedXRatio = dimension === 'x' ? newValue - _.get(selectedLayersBoundingBox, source) : 0;
    const draggedYRatio = dimension === 'y' ? newValue - _.get(selectedLayersBoundingBox, source) : 0;

    let { xRatio, yRatio } = configs[position][dimension].offsetMultiplier(draggedXRatio / Math.max(0.1, originalBoundingBoxSize.width), draggedYRatio / Math.max(0.1, originalBoundingBoxSize.height));

    if (aspectRatioLock) {
      const aspectRatio = originalBoundingBoxSize.width / originalBoundingBoxSize.height;
      if (dimension === 'x') {
        yRatio = xRatio / aspectRatio;
      } else {
        xRatio = yRatio * aspectRatio;
      }
    }
    const { x: baseX, y: baseY } = configs[position][dimension].basePositionExtractor(selectedLayersBoundingBox);

    const changedLayers = selectedLayers.map((layer) => {
      const newLayer = ({
        ...layer,
        width: { ...layer.width, dimension: Math.max(0.0001, to2dp(layer.width.dimension * Math.abs(1 + xRatio))) },
        height: { ...layer.height, dimension: Math.max(0.0001, to2dp(layer.height.dimension * Math.abs(1 + yRatio))) },
        positionX: { ...layer.positionX, dimension: to2dp((layer.positionX.dimension - baseX) * (1 + xRatio) + baseX) },
        positionY: { ...layer.positionY, dimension: to2dp((layer.positionY.dimension - baseY) * (1 + yRatio) + baseY) },
      } as BoothDesignMasterElement);
      return actions.updateLayer(newLayer);
    });

    batch(() => changedLayers.forEach((layer) => dispatch(layer)));
  }, [actions, aspectRatioLock, dimension, dispatch, position, selectedLayers, selectedLayersBoundingBox, source, tmpValue]);

  const onFieldChange = useCallback((e: any) => {
    setTmpValue(e.target.value);
    if (!lastChangeIsFromKeyboard) tryToCommitChange(e.target.value);
  }, [lastChangeIsFromKeyboard, tryToCommitChange]);

  const onChange = useCallback((e) => onFieldChange(e), [onFieldChange]);
  const onKeyDown = useCallback((e) => {
    if (isEnter(e)) tryToCommitChange();
    else setLastChangeIsFromKeyboard(true);
  }, [tryToCommitChange]);
  const onCommit = useCallback(() => tryToCommitChange(), [tryToCommitChange]);

  const resetLastChange = useCallback(() => {
    setLastChangeIsFromKeyboard(false);
  }, []);

  const icon = useMemo(() => {
    switch (true) {
      case position === 'a' && dimension === 'x':
        return <BorderLeft />;
      case position === 'b' && dimension === 'x':
        return <BorderRight />;
      case position === 'a' && dimension === 'y':
        return <BorderTop />;
      case position === 'b' && dimension === 'y':
        return <BorderBottom />;
      default:
        return null;
    }
  }, [dimension, position]);

  return (
    <DimensionFieldFormControl>
      <InputLabel>{label}</InputLabel>
      <Input
        color="primary"
        type="number"
        value={tmpValue}
        startAdornment={<StyledIconContainer>{icon}</StyledIconContainer>}
        endAdornment={<InputAdornment position="end">%</InputAdornment>}
        onChange={onChange}
        onBlur={onCommit}
        onKeyDown={onKeyDown}
        onMouseDown={resetLastChange}
        onWheel={resetLastChange}
      />
    </DimensionFieldFormControl>
  );
};

export default React.memo(DesignEditorTransformDimensionField);
