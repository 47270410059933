import { Epic } from 'redux-observable';
import {
  catchError, filter, map, mergeMap, skip, startWith, takeWhile, tap,
} from 'rxjs/operators';
import activeStorageUploadFile, { ActiveStorageUploadProgress } from 'utils/active-storage';
import { v4 as uuidv4 } from 'uuid';
import { EMPTY, merge, of } from 'rxjs';
import { createUploadTasksActions, UploadTasksActions } from 'utils/xtra-upload-tasks/createUploadTasksActions';

// eslint-disable-next-line import/prefer-default-export
export const createUploadTaskEpic: (actionPrefix: string) => Epic = (actionPrefix) => {
  const actions = createUploadTasksActions(actionPrefix);
  return (action$) => action$.pipe(
    filter(actions.startUploadTasks.match),
    mergeMap(({
      payload: {
        identifier, file, onComplete, onCancel, onError, onProgress,
      },
    }) => {
      const id = uuidv4();

      const overlappedUploadFileAction$ = action$
        .pipe(
          filter(actions.startUploadTasks.match),
          filter(({ payload: { identifier: anotherIdentifier } }) => identifier !== null && anotherIdentifier === identifier),
        );
      const cancelAction$ = action$
        .pipe(
          filter(actions.cancelUploadTasks.match),
          filter(({ payload: cancellingId }) => cancellingId === id),
        );

      const stopSignal$ = merge(overlappedUploadFileAction$, cancelAction$)
        .pipe(map((signal) => ({ isStopSignal: true, signal })));

      let xhr: XMLHttpRequest = null;

      return merge(
        activeStorageUploadFile(file).pipe(map((progress) => ({ isStopSignal: false, progress }))),
        stopSignal$,
      ).pipe(
        // Stop (inclusive) after received the stop signal
        takeWhile((signal) => !signal.isStopSignal, true),
        tap((signal) => signal.isStopSignal && xhr?.abort()),

        mergeMap((signal) => {
          // Map the stop signal to "action remove" when canceling
          if (signal.isStopSignal === true) return onCancel?.call(null) ?? EMPTY;

          return of((signal as any).progress as ActiveStorageUploadProgress).pipe(
            tap((progress) => {
              xhr = progress.xhr;
            }),

            // Map the progress to "update progress action"
            mergeMap((progress) => (
              progress.isCompleted
                ? onComplete?.call(null, progress.signedBlobId) ?? EMPTY
                : onProgress?.call(null, id, progress.progressEvent) ?? EMPTY
            )),

            // Log the err and map to "update action failed state" when error
            catchError((err) => {
              console.error(err);
              return onError?.call(null, null, err) ?? EMPTY;
            }),
          );
        }),
      );
    }),
  );
};
