import { Epic } from 'redux-observable';
import {
  catchError, filter, map, mergeMap, takeWhile, tap,
} from 'rxjs/operators';
import activeStorageUploadFile, { ActiveStorageUploadProgress } from 'utils/active-storage';
import { merge, of, throwError } from 'rxjs';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { createAction } from '@reduxjs/toolkit';
import { ExecutingTasksActions } from 'utils/xtra-executing-tasks/createExecutingTasksActions';
import { catchErrorToPopup, ERROR_POPUP_TYPES } from 'redux/mutationFailedPipe';

export interface UploadFilePayload {
  title: string;
  file: File
  /**
   * Use to identify is the upload repeated, latest upload action will cancel the previous one
   * null to allow repeating upload
   */
  identifier?: string;
  /**
   * Callback when file uploaded
   */
  callback?: (signedBlobId: string) => void;
}

export const createUploadFileAction = (prefix: string) => createAction<UploadFilePayload>(`${prefix}/uploadFile`);

export type UploadFileAction = ReturnType<typeof createUploadFileAction>

export const createUploadFileEpic: (uploadAction: UploadFileAction, executingTasksActions: ExecutingTasksActions) => Epic = (uploadAction, executingTasksActions) => (action$, state$) => action$.pipe(
  filter(uploadAction.match),
  mergeMap(({
    payload: {
      callback, file, identifier, title,
    },
  }) => {
    const executingTask = createExecutingTask({
      name: title, tags: [ExecutingTaskCommonTag.Mutation],
    });

    const overlappedUploadFileAction$ = action$.pipe(
      filter(uploadAction.match),
      filter(({ payload: { identifier: anotherIdentifier } }) => identifier !== null && anotherIdentifier === identifier),
    );

    const cancelAction$ = action$.ofType<{ id: string }>('TIMED_ACTION_CANCEL')
      .pipe(filter(({ id }) => id === executingTask.id));

    const stopSignal$ = merge(overlappedUploadFileAction$, cancelAction$)
      .pipe(map((signal) => ({ isStopSignal: true, signal })));

    let xhr: XMLHttpRequest = null;

    return merge(
      activeStorageUploadFile(file).pipe(map((progress) => ({ isStopSignal: false, progress }))),
      stopSignal$,
    ).pipe(
      // Stop (inclusive) after received the stop signal
      takeWhile((signal) => !signal.isStopSignal, true),
      tap((signal) => signal.isStopSignal && xhr?.abort()),

      mergeMap((signal) => {
        // Map the stop signal to "action remove" when canceling
        if (signal.isStopSignal === true) return of(executingTasksActions.removeExecutingTasks(executingTask.id));

        return of((signal as any).progress as ActiveStorageUploadProgress).pipe(
          tap((progress) => {
            xhr = progress.xhr;
          }),

          // Call callback when the upload is completed
          tap((progress) => progress.isCompleted && callback(progress.signedBlobId)),

          // Map the progress to "update progress action"
          map((progress) => (progress.isCompleted ? executingTasksActions.removeExecutingTasks(executingTask.id)
            : executingTasksActions.upsertExecutingTasks({
              ...executingTask,
              progress: progress.isCompleted ? 1 : progress.progressEvent.loaded / progress.progressEvent.total,
            }))),
        );
      }),
      catchError((error) => {
        console.log(error);
        return throwError({ type: ERROR_POPUP_TYPES.UploadFailedError, raw: error });
      }),
      catchErrorToPopup(state$.value.intl),
      executingTasksActions.wrapWithExecutingTaskAction(() => executingTask),
    );
  }),
);
