import {
  DebouncedRequest,
  rawDesignEditorInstanceActions,
} from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceActions';
import { ignoreElements, map, mergeMap } from 'rxjs/operators';
import { DesignIdWrappedPayload } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designIdWrappedPayload';
import { concat, EMPTY, of } from 'rxjs';
import {
  BoothDesignColorFillMasterElement,
  BoothDesignColorFillMasterElementUpdateInput,
  BoothDesignContentAreaMasterElement,
  BoothDesignContentAreaMasterElementUpdateInput,
  BoothDesignImageButtonMasterElement,
  BoothDesignImageButtonMasterElementUpdateInput,
  BoothDesignImageMasterElement,
  BoothDesignImageMasterElementUpdateInput,
  BoothDesignMasterElement,
  BoothDesignNetworkingWidgetMasterElement,
  BoothDesignNetworkingWidgetMasterElementUpdateInput,
} from 'models';
import { Epic } from 'redux-observable';
import { ExecutingTask } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceState';
import createDesignEditorInstanceSelectors
  from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceSelectors';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import { LayerElementType } from 'libs/xtra-custom-booth-design/types/DesignEditorInstance';
import _ from 'lodash';
import { Dictionary } from '@reduxjs/toolkit';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import { convertAllLocalizableFieldsChangeInput, eraseTypename } from 'utils/modelUtils';
import cmsSelectors from 'redux/cmsSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';

const eraseKey = new Set(['id', '__typename', 'zOrder', 'parentElementId']);
const convertLayerElementToUpdateInput = <T extends Dictionary<any>>(input: T) => _.flow([
  (it) => _.pickBy(it, (value, key) => !eraseKey.has(key)) as T,
  convertAllLocalizableFieldsChangeInput,
  (it) => eraseTypename(it, -1),
])(input);

/**
 * Update layer epic
 */
const updateLayerEpic: Epic = (action$, state$) => action$
  .ofType(rawDesignEditorInstanceActions.updateLayer).pipe(
    map((action) => action.payload as DesignIdWrappedPayload<BoothDesignMasterElement>),
    mergeMap(({ instanceDesignId, data }) => {
      const { selectLayerById } = createDesignEditorInstanceSelectors(instanceDesignId);
      const executingTask: ExecutingTask = createExecutingTask({
        name: 'Updating layer',
        progress: -1,
        tags: [ExecutingTaskCommonTag.Mutation],
      });

      const debouncedRequest = {
        debounceKey: data.id,
        // return empty if deleted before send request
        requestFactory: () => {
          const layer = selectLayerById(data.id)(state$.value);
          if (layer == null) return EMPTY; // e.g. layer be deleted before update

          const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);

          const commonInput = {
            elementId: layer.id,
            boothDesignId: instanceDesignId,
          };

          const request = {
            [LayerElementType.BoothDesignImageButtonMasterElement]: sdk.designEditorLayerImageButtonUpdate({
              input: {
                ...commonInput,
                ...convertLayerElementToUpdateInput(layer as BoothDesignImageButtonMasterElement),
              } as BoothDesignImageButtonMasterElementUpdateInput,
            }),

            [LayerElementType.BoothDesignImageMasterElement]: sdk.designEditorLayerImageUpdate({
              input: {
                ...commonInput,
                ...convertLayerElementToUpdateInput(layer as BoothDesignImageMasterElement),
              } as BoothDesignImageMasterElementUpdateInput,
            }),

            [LayerElementType.BoothDesignContentAreaMasterElement]: sdk.designEditorLayerContentAreaUpdate({
              input: {
                ...commonInput,
                ...convertLayerElementToUpdateInput(layer as BoothDesignContentAreaMasterElement),
              } as BoothDesignContentAreaMasterElementUpdateInput,
            }),

            [LayerElementType.BoothDesignColorFillMasterElement]: sdk.designEditorLayerColorFillUpdate({
              input: {
                ...commonInput,
                ...convertLayerElementToUpdateInput(layer as BoothDesignColorFillMasterElement),
              } as BoothDesignColorFillMasterElementUpdateInput,
            }),

            [LayerElementType.BoothDesignNetworkingWidgetMasterElement]: sdk.designEditorLayerNetworkingWidgetUpdate({
              input: {
                ...commonInput,
                ...convertLayerElementToUpdateInput(layer as BoothDesignNetworkingWidgetMasterElement),
              } as BoothDesignNetworkingWidgetMasterElementUpdateInput,
            }),
          };

          return concat(
            of(eventEditActions.executingTasks.upsertExecutingTasks(executingTask)),
            request[layer.__typename].pipe(
              throwCommonError(),
              ignoreElements(),
              catchErrorToPopup(state$.value.intl),
            ),
            of(eventEditActions.executingTasks.removeExecutingTasks(executingTask.id)),
          );
        },
      } as DebouncedRequest;

      return concat(
        of(rawDesignEditorInstanceActions.internalUpdateLayer({ instanceDesignId, data })),
        of(rawDesignEditorInstanceActions.internalAddDebouncedRequest({ instanceDesignId, data: debouncedRequest })),
      );
    }),
  );

export default updateLayerEpic;
