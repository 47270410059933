// eslint-disable-next-line import/prefer-default-export
/**
 * @param object
 * @param recursiveDepth negative to inifinty recursive
 */
export function eraseTypename<T>(object?: T, recursiveDepth = 0): Pick<T, Exclude<keyof T, '__typename'>> {
  let erased = object as any;
  // eslint-disable-next-line no-underscore-dangle
  if (erased?.__typename != null) {
    const { __typename, ...rest } = object as any;
    erased = { ...rest };
  }

  return recursiveDepth === 0 || typeof erased !== 'object' || erased == null ? erased : Object.entries(erased).reduce((recursiveResult, [key, value]) => {
    // eslint-disable-next-line no-plusplus
    recursiveResult[key] = eraseTypename(value, recursiveDepth--);
    return recursiveResult;
  }, Array.isArray(erased) ? [] : {}) as T;
}

export const extractLocalizableFileChangeInput = (file: { values?: Array<{ locale: string, value?: { signedBlobId?: string } }> }) => ({
  values: (file.values)
    ?.filter((localizableImage) => !!localizableImage?.value?.signedBlobId || (!!localizableImage?.value && localizableImage.value.signedBlobId === null))
    ?.map(({ locale, value }) => ({ locale, value: value.signedBlobId })),
});

const FILEABLE_TYPENAMES = new Set(['File', 'Video', 'Image']);

/**
 * @param object
 * @param recursiveDepth negative to inifinty recursive
 */
export function convertAllLocalizableFieldsChangeInput<T>(object?: T, recursiveDepth = -1): T {
  // eslint-disable-next-line no-underscore-dangle
  if ((object as any)?.signedBlobId != null || FILEABLE_TYPENAMES.has((object as any)?.__typename)) {
    return (object as any)?.signedBlobId;
  }
  if ((object as any)?.value?.signedBlobId != null || FILEABLE_TYPENAMES.has((object as any)?.value?.__typename)) {
    const value = (object as any)?.value?.signedBlobId;
    return value == null ? undefined : { ...object, value };
  }

  return recursiveDepth === 0 || typeof object !== 'object' || object == null ? object : Object.entries(object).reduce((recursiveResult, [key, value]) => {
    // eslint-disable-next-line no-plusplus
    recursiveResult[key] = convertAllLocalizableFieldsChangeInput(value, recursiveDepth--);
    return recursiveResult;
  }, Array.isArray(object) ? [] : {}) as T;
}
