import React, {
  FC, useCallback, useContext, useState,
} from 'react';
import { FieldBind } from 'libs/xtra-custom-booth-design/utils/useLayerFieldBind';
import {
  IconButton, Input, InputAdornment, InputLabel,
} from '@material-ui/core';
import { Fileable, QueryLocalizableFileableByArgs } from 'models';
import XtraDropZoneDialog from 'libs/xtra-custom-booth-design/components/shared/XtraDropZoneDialog';
import { ContentLocalizationContext } from 'components/localization/ContentLocalizationContext';
import { useDispatch, useSelector } from 'react-redux';
import MuiSizeFaIcon from 'components/MuiSizeFaIcon';
import { faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { StyledFormControl } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayerAttributesDocker/styles';
import designEditorActions from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorActions';
import DesignEditorInstanceContext
  from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';
import { concat, of } from 'rxjs';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import { v4 as uuidv4 } from 'uuid';

export interface DesignEditorLayerAttributeFileFieldProps<T extends Fileable> {
  fieldBind: FieldBind<T>;
  fileField: QueryLocalizableFileableByArgs;
  accept?: string | string[];
  label: string;
  emptyText: string;
  uploadHints?: string;
}

const DesignEditorLayerAttributeFileField: FC<DesignEditorLayerAttributeFileFieldProps<Fileable>> = ({
  fieldBind, accept, label, emptyText, fileField, uploadHints,
}) => {
  const [open, setOpen] = useState(false);
  const { locale } = useContext(ContentLocalizationContext);
  const { actions, selectors } = useContext(DesignEditorInstanceContext);
  const selectedLayersId = useSelector(selectors.selectSelectedLayersId);
  const dispatch = useDispatch();

  const openUploadDialog = useCallback(() => setOpen(true), []);
  const onDialogCancel = useCallback(() => setOpen(false), []);
  const onDialogUpload = useCallback((file: File, preview: Fileable) => {
    const id = uuidv4();
    dispatch(eventEditActions.executingTasks.upsertExecutingTasks({ id, name: 'Uploading file', progress: -1 }));
    dispatch(designEditorActions.uploading.startUploadTasks({
      identifier: fieldBind.source,
      file,
      onComplete: (signedBlobId) => concat(
        of(actions.updateLayerFields({
          layerId: selectedLayersId[0],
          fields: [
            { source: `${fieldBind.source}`, value: preview },
            { source: `${fieldBind.source}.signedBlobId`, value: signedBlobId },
          ],
        })),
        of(eventEditActions.executingTasks.removeExecutingTasks(id)),
      ),
    }));
    setOpen(false);
  }, [actions, dispatch, fieldBind, selectedLayersId]);

  const onClear = useCallback(() => {
    fieldBind.change(null);
  }, [fieldBind]);

  return (
    <StyledFormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Input
        value={fieldBind?.value?.filename ?? emptyText}
        disabled
        endAdornment={(
          <InputAdornment position="end">
            <IconButton onClick={onClear} size="small" disabled={fieldBind?.value?.filename == null}>
              <MuiSizeFaIcon icon={faTrash} />
            </IconButton>
            <IconButton onClick={openUploadDialog} size="small">
              <MuiSizeFaIcon icon={faUpload} />
            </IconButton>
          </InputAdornment>
        )}
      />
      <XtraDropZoneDialog
        title={label}
        open={open}
        onCancel={onDialogCancel}
        onUpload={onDialogUpload}
        accept={accept}
        previousFile={fieldBind.value}
        previousFileField={fileField}
        uploadHints={uploadHints}
      />
    </StyledFormControl>
  );
};

export default React.memo(DesignEditorLayerAttributeFileField);
