import { Epic } from 'redux-observable';
import { filter, map, mergeMap } from 'rxjs/operators';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import cmsSelectors from 'redux/cmsSelectors';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import produce from 'immer';

const deleteBoothRepresentativeEpic: Epic = (action$, state$) => action$.pipe(
  filter(boothEditActions.mutations.deleteBoothRepresentative.match),
  mergeMap(({ payload: representativeId }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const boothId = boothEditSelectors.selectBoothId(state$.value);
    const originalBoothFactory = () => boothEditSelectors.selectBooth(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [ExecutingTaskCommonTag.Mutation],
    });

    return sdk.boothEditRepresentativeDelete({ input: { boothId, representativeId } }).pipe(
      throwCommonError(),
      map((resp) => boothEditActions.internalUpsertBooth(produce(originalBoothFactory(), ((draft) => {
        const index = draft.representatives.nodes.findIndex((it) => it.id === representativeId);
        draft.representatives.nodes.splice(index, 1);
      })))),
      catchErrorToPopup(state$.value.intl),
      boothEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );
  }),
);

export default deleteBoothRepresentativeEpic;
