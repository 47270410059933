import { Draggable } from 'react-beautiful-dnd';
import MuiSizeFaIcon from 'components/MuiSizeFaIcon';
import { faStickyNote } from '@fortawesome/free-solid-svg-icons';
import React, {
  FC, MouseEvent, useCallback, useContext,
} from 'react';
import { DesignEditorLayerInfo } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayersDocker/DesignEditorLayersList';
import {
  StyledLayerActions, StyledLayerIcon, StyledLayerText, StyledListItem,
} from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayersDocker/style';
import { useSelector } from 'react-redux';
import DesignEditorInstanceContext from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';
import { IconButton, ListItemSecondaryAction } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import DesignEditorLayersListItemMenu from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayersDocker/DesignEditorLayersListItemMenu';
import { selectorArrayItemEqFn } from 'utils/selectorUtils';

interface DesignEditorLayersListItemProps {
  layer: DesignEditorLayerInfo;
  index: number;
  onClickLayer: (e: MouseEvent, clickedLayerId: string) => any;
}

const DesignEditorLayersListItem: FC<DesignEditorLayersListItemProps> = ({ layer, index, onClickLayer }) => {
  const { actions, selectors, designId } = useContext(DesignEditorInstanceContext);
  const selectedLayerIds = useSelector(selectors.selectSelectedLayersId, selectorArrayItemEqFn);

  const [anchorPosition, setAnchorPosition] = React.useState<{ mouseX?: number; mouseY?: number; }>({ mouseX: null, mouseY: null });

  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const onLayerMouseDown = useCallback((e: MouseEvent) => {
    if (e.button === 2) {
      // e.preventDefault();
      setAnchorPosition({ mouseX: e.clientX - 2, mouseY: e.clientY - 4 });
      setIsMenuOpen(true);
    } else {
      onClickLayer(e, layer.id);
    }
  }, [layer, onClickLayer]);

  const onMenuButtonClick = useCallback((e: MouseEvent) => {
    setAnchorPosition({ mouseX: e.clientX - 2, mouseY: e.clientY - 4 });
    setIsMenuOpen(true);
  }, []);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  return (
    <Draggable key={layer.id} draggableId={layer.id} index={index}>
      {(draggableProvided, draggableSnapshot) => (
        <>
          <StyledListItem
            selected={selectedLayerIds.indexOf(layer.id) !== -1}
            onMouseDown={onLayerMouseDown}
            button
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...draggableProvided.dragHandleProps}
            style={draggableProvided.draggableProps.style}
          >
            <StyledLayerIcon>
              <MuiSizeFaIcon icon={faStickyNote} />
            </StyledLayerIcon>
            <StyledLayerText primary={layer.name} />
            <StyledLayerActions>
              <IconButton onClick={onMenuButtonClick} size="small">
                <MoreVert />
              </IconButton>
            </StyledLayerActions>
          </StyledListItem>
          <DesignEditorLayersListItemMenu open={isMenuOpen} onClose={closeMenu} anchorPosition={anchorPosition} layer={layer} />
        </>
      )}
    </Draggable>
  );
};

export default DesignEditorLayersListItem;
