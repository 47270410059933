import styled from 'styled-components';
import transparentGrid from 'assets/img/custom-design/transparent-grid.png';

export const DesignBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("${transparentGrid}");
  background-repeat: repeat;
  background-size: 32px;
  image-rendering: pixelated;
  image-rendering: -moz-crisp-edges;
  image-rendering: crisp-edges;
`;

export const StyledCanvasInner = styled.div`
  position: relative;
`;

export const StyledCanvasContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const StyledCanvasContentInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
