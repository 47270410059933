import {
  Observable, of, pipe, UnaryFunction,
} from 'rxjs';
import cmsActions from 'redux/cmsActions';
import { Action } from 'redux';
import { catchError, map } from 'rxjs/operators';
import { createIntlFromRaw } from 'utils/intl-creator';
import { mapValues } from 'lodash';

const ERROR_POPUP_MAP = {
  MutationFailedError: (err, intlRaw: { locale: string, message: any }) => of(cmsActions.popups.createPopups.snackbar(createIntlFromRaw(intlRaw)
    .formatMessage({
      id: 'error.mutation_failed',
      defaultMessage: 'Failed to update, reason: {error}',
    }, { error: err.message }))),
  UploadFailedError: (err, intlRaw: { locale: string, message: any }) => of(cmsActions.popups.createPopups.snackbar(createIntlFromRaw(intlRaw)
    .formatMessage({
      id: 'error.upload_failed',
      defaultMessage: 'Failed to upload, please retry again or resize your file',
    }))),
};

export const ERROR_POPUP_TYPES = mapValues(ERROR_POPUP_MAP, (_, key) => key) as any as ({ [key in (keyof typeof ERROR_POPUP_MAP)]: key });

export const throwCommonError = <T>() => map((resp: T) => {
  if (resp != null && typeof resp === 'object' && Object.values(resp).find((mutation: any) => mutation?.success === false) != null) {
    // eslint-disable-next-line no-throw-literal
    throw {
      message: `${(Object.values(resp).find((it: any) => !it.success) as any)?.errors?.map((error) => error.message)}`,
      type: ERROR_POPUP_TYPES.MutationFailedError,
    };
  }

  return resp as T;
});

// eslint-disable-next-line import/prefer-default-export
export const catchErrorToPopup: <T>(intlRaw: { locale: string, message: any }) => UnaryFunction<Observable<T>, Observable<T | Action>> = (intlRaw) => pipe(
  catchError((err) => {
    console.error(err);
    return (ERROR_POPUP_MAP[err.type]?.call(null, err, intlRaw) ?? of(cmsActions.popups.createPopups.snackbar(createIntlFromRaw(intlRaw)
      .formatMessage({ id: 'error.unknown', defaultMessage: 'Unknown Error' })))) as Observable<Action>;
  }),
);
