import {
  createAsyncThunk, createEntityAdapter, createSlice, createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { translationsToLocaleMap } from './helpers';
import { WebinarTrack } from './types';
import { FIRESTORE_ENDPOINT } from 'config';

export const fetchWebinarTracks = createAsyncThunk(
  'webinar/get_tracks',
  async (_: void, { getState }) => {
    const { eventEdit: { event: { domain } } } = (getState() as any);
    const { data } = await axios.get(`${FIRESTORE_ENDPOINT}/tracks`, {
      params: { event_domain: domain || 'virtual.2vanx.com' },
      headers: { 'vexpo-xtra-cms': 'true' },
    });
    return (data as WebinarTrack[]);
  },
);

export const addWebinarTrack = createAsyncThunk(
  'webinar/add_track',
  async (body: any, { getState }) => {
    const { eventEdit: { event: { domain } } } = (getState() as any);
    const { data } = await axios.post(`${FIRESTORE_ENDPOINT}/tracks?event_domain=${domain || 'virtual.2vanx.com'}`, body);
    if (data.success) {
      body.translations = translationsToLocaleMap(body.translations);
      return ({ ...body, id: data.id } as WebinarTrack);
    }
    return ({} as WebinarTrack);
  },
);

export const updateWebinarTrack = createAsyncThunk(
  'webinar/update_track',
  async (body: WebinarTrack, { getState }) => {
    const { eventEdit: { event: { domain } } } = (getState() as any);
    const { data } = await axios.put(`${FIRESTORE_ENDPOINT}/tracks?event_domain=${domain || 'virtual.2vanx.com'}`, body);
    if (data.success) {
      body.translations = translationsToLocaleMap(body.translations);
      return { id: body.id, changes: body };
    }
    return { id: null, changes: ({} as WebinarTrack) };
  },
);

export const deleteWebinarTrack = createAsyncThunk(
  'webinar/delete_track',
  async (id: string, { getState }) => {
    const { eventEdit: { event: { domain } } } = (getState() as any);
    const { data } = await axios.delete(`${FIRESTORE_ENDPOINT}/tracks?event_domain=${domain || 'virtual.2vanx.com'}&id=${id}`);
    if (data.success) {
      return id;
    }
    return '';
  },
);

const tracksAdapter = createEntityAdapter<WebinarTrack>();

export const {
  selectEntities: selectWebinarTrackEntities,
  selectAll: selectAllWebinarTracks,
  selectById: selectWebinarTrackById,
} = tracksAdapter.getSelectors((state: any) => state.tracks);

export const selectAllWebinarTracksInOrder = createSelector(
  selectAllWebinarTracks,
  (tracks) => tracks.sort((a, b) => a.sort - b.sort),
);

const emptyTrackTranslation = {
  name: '',
};

export const selectWebinarTrackIdByKeyword = (keyword, locale) => {
  const lowerCaseKeyword = keyword.toLowerCase();
  return createSelector(
    selectAllWebinarTracksInOrder,
    (tracks) => tracks.filter((track) => {
      const content = track.translations?.[locale] || emptyTrackTranslation;
      return content.name.toLowerCase().includes(lowerCaseKeyword);
    }).map((track) => track.id),
  );
};

const reorderWebinarTrack = createAsyncThunk(
  'webinar/reorder_track_api',
  async (body: any, { getState }) => {
    const { eventEdit: { event: { domain } } } = (getState() as any);
    const { data } = await axios.put(`${FIRESTORE_ENDPOINT}/tracks/updateSort?event_domain=${domain || 'virtual.2vanx.com'}`, body);
    const [id] = Object.keys(body);
    if (data.success) {
      return { id, changes: { sort: body[id] } };
    }
    throw new Error('Failed to update sort');
  },
);

export const reorderTrack = createAsyncThunk(
  'webinar/track_reorder',
  async (movement: number[], { getState, dispatch }) => {
    const [currIdx, destIdx] = movement;
    const allTracks = selectAllWebinarTracksInOrder(getState());
    const { id } = allTracks[currIdx];
    let newNumber = 0;
    if (currIdx > destIdx) { // Move Up
      newNumber = !destIdx ? allTracks[destIdx].sort - 65536 : Math.floor((allTracks[destIdx - 1].sort + allTracks[destIdx].sort) / 2);
    } else if (currIdx < destIdx) { // Move down
      newNumber = destIdx === allTracks.length - 1 ? allTracks[destIdx].sort + 65536 : Math.floor((allTracks[destIdx].sort + allTracks[destIdx + 1].sort) / 2);
    } else { // Equal
      newNumber = allTracks.length >= 3 ? (allTracks[currIdx - 1].sort + allTracks[currIdx + 1].sort) / 2 : allTracks[currIdx].sort;
    }
    dispatch(reorderWebinarTrack({ [id]: newNumber }));
    return { id, changes: { sort: newNumber } };
  },
);

const restructureGetPayload = (payload) => payload.map(({
  id, color,
  translations, sort,
}) => ({
  id,
  color,
  sort,
  translations: translationsToLocaleMap(translations),
}));

export const trackSlice = createSlice({
  name: 'track',
  initialState: tracksAdapter.getInitialState({ isLoading: true, isReordering: false, isError: false }),
  reducers: {
    resetError(state) {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWebinarTracks.pending, (state: any) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWebinarTracks.fulfilled, (state: any, action) => {
      const restructured = restructureGetPayload(action.payload);
      tracksAdapter.upsertMany(state, restructured || []);
      state.isLoading = false;
    });
    builder.addCase(fetchWebinarTracks.rejected, (state: any) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addWebinarTrack.fulfilled, (state, action: any) => {
      tracksAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(updateWebinarTrack.fulfilled, (state, action: any) => {
      tracksAdapter.updateOne(state, action.payload);
    });
    builder.addCase(deleteWebinarTrack.fulfilled, (state, action: any) => {
      tracksAdapter.removeOne(state, action.payload);
    });
    builder.addCase(reorderTrack.pending, (state) => {
      state.isReordering = true;
    });
    builder.addCase(reorderTrack.fulfilled, (state, action: any) => {
      tracksAdapter.updateOne(state, action.payload);
    });
    builder.addCase(reorderWebinarTrack.fulfilled, (state) => {
      state.isReordering = false;
    });
    builder.addCase(reorderWebinarTrack.rejected, (state) => {
      state.isReordering = false;
    });
  },
});

export const { resetError } = trackSlice.actions;
export default trackSlice.reducer;
