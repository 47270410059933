import React, {
  FC, ReactNode, useCallback, useMemo, useState,
} from 'react';
import { Button, Typography } from '@material-ui/core';
import { Fileable, Image, QueryLocalizableFileableByArgs } from 'models';
import Dropzone from 'react-dropzone';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import FilePreviewer from 'libs/xtra-custom-booth-design/components/shared/XtraDropZoneDialog/FilePreviewer';
import styled from 'styled-components';
import { Delete } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import cmsActions from 'redux/cmsActions';
import { useTimeout } from 'react-use';
import { CMS_GENERAL_FILE_SIZE_LIMIT, CMS_GENERAL_VIDEO_SIZE_LIMIT } from 'config';

const StyledDropArea = styled.div<{ readonly?: boolean, vertical?: boolean }>`
  width: 100%;
  height: 300px;
  border: ${({ readonly }) => (readonly ? 'none' : 'darkgray 2px dashed')};
  padding: 20px 10px;
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  align-items: center;
  justify-content: space-around;
  overflow: hidden;
  margin: 16px 0;
  position: relative;
  cursor: ${({ readonly }) => (readonly ? 'auto' : 'pointer')};
`;

const StyledUploadMessageArea = styled.div<{ limitWidth?: boolean }>`
  width: ${({ limitWidth }) => (limitWidth ? '150px' : 'unset')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * {
    margin: 4px;
  }
`;

const StyledTextCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > * :not(:last-child) {
    margin-bottom: 8px;
  }
`;

const StyledDropZoneDialogPreviewArea = styled.div`
  height: 100%;
  width: 100%;
  max-height: 400px;
  flex: 1 1 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledPreviewContainer = styled.div`
  margin: 16px 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.01);
  cursor: default;
  max-height: inherit;
  font-size: 12px;
`;

export interface XtraDropZoneV2FileSizeLimit {
  type: string;
  limit: number;
}

export type XtraDropZoneV2FileSizeLimitFn = (accept: string[], file: File, intl: IntlShape) => XtraDropZoneV2FileSizeLimit;

const defaultFileSizeLimitFn: XtraDropZoneV2FileSizeLimitFn = (accept, file, intlShape) => {
  if (file.type.startsWith('video/')) {
    return {
      type: intlShape.formatMessage({ id: 'common.video', defaultMessage: 'Video' }),
      limit: CMS_GENERAL_VIDEO_SIZE_LIMIT,
    };
  }
  return {
    type: intlShape.formatMessage({ id: 'common.general_file', defaultMessage: 'General file' }),
    limit: CMS_GENERAL_FILE_SIZE_LIMIT,
  };
};

export interface XtraDropZoneV2Props {
  onUpload: (file: File, preview: Fileable) => any;

  file: Fileable;
  fileField?: QueryLocalizableFileableByArgs;
  locale?: string;

  accept?: string | string[];

  showDelete?: boolean;

  uploadHints?: ReactNode;

  className?: string;
  readonly?: boolean;

  vertical?: boolean;
  previewDirection?: 'row' | 'column';

  fileSizeLimitFn?: XtraDropZoneV2FileSizeLimitFn;
}

const XtraDropZoneV2: FC<XtraDropZoneV2Props> = ({
  onUpload, file, fileField, accept,
  locale, showDelete = false, uploadHints, className, readonly,
  vertical, fileSizeLimitFn = defaultFileSizeLimitFn, previewDirection = 'row',
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [shakeInit, setShakeInit] = useState(false);
  const [getIsStopShaking, cancelShake, resetShake] = useTimeout(500);

  const onDrop = useCallback((files: File[]) => {
    if (files.length !== 1) return;
    const uploadedFile = files[0];
    const fileSizeLimit = fileSizeLimitFn((typeof accept === 'string' ? [accept] : accept) ?? [], uploadedFile, intl);
    if (fileSizeLimit != null && uploadedFile.size > fileSizeLimit.limit) {
      dispatch(cmsActions.popups.createPopups.snackbar(intl.formatMessage({
        id: 'shared.dropzone.file_limit.message',
        defaultMessage: 'The file size limit of {type} is {size}MiB, please reduce your file size and upload again',
      }, {
        type: fileSizeLimit.type,
        size: fileSizeLimit.limit / 1024 / 1024,
      })));
      setShakeInit(true);
      resetShake();
    } else {
      const preview: Partial<Fileable> = {
        contentType: uploadedFile.type,
        fileUrl: URL.createObjectURL(uploadedFile),
        filename: uploadedFile.name,
        isUploading: true,
      };
      if (preview.contentType.startsWith('image/')) {
        (preview as Image).originalImageUrl = preview.fileUrl;
      }
      onUpload(uploadedFile, preview as Fileable);
    }
  }, [accept, dispatch, fileSizeLimitFn, intl, onUpload, resetShake]);

  const onPreviewClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const onDelete = useCallback((e) => {
    e.stopPropagation();
    onUpload(null, null);
  }, [onUpload]);

  const hasFile = useMemo(() => file != null && file.contentType != null, [file]);

  const previewer = useMemo(() => (
    <StyledDropZoneDialogPreviewArea>
      {
        !hasFile ? (
          <StyledTextCenter>
            {readonly
              ? (
                <>
                  <div>
                    <FormattedMessage id="shared.dropzone.preview.no_file" defaultMessage="No file" />
                  </div>
                  <div>
                    <FormattedMessage
                      id="shared.dropzone.preview.organizer_disabled_upload"
                      defaultMessage="Organizer disabled file upload for this field"
                    />
                  </div>
                </>
              )
              : (
                <FormattedMessage
                  id="shared.dropzone.preview.preview_after_upload"
                  defaultMessage="Preview the file after upload"
                />
              )}
          </StyledTextCenter>
        ) : (
          <StyledPreviewContainer onClick={onPreviewClick}>
            <FilePreviewer file={file} fileField={fileField} locale={locale} direction={previewDirection} />
          </StyledPreviewContainer>
        )
      }
    </StyledDropZoneDialogPreviewArea>
  ), [file, fileField, hasFile, locale, onPreviewClick, previewDirection, readonly]);

  return readonly ? <StyledDropArea readonly>{previewer}</StyledDropArea> : (
    <Dropzone onDrop={onDrop} multiple={false} accept={accept}>
      {({ getRootProps, getInputProps }) => (
        <StyledDropArea
          {...getRootProps()}
          className={`${className} ${shakeInit && getIsStopShaking() === false ? 'shake-hard shake-constant' : ''}`}
          vertical={vertical}
        >
          <input {...getInputProps()} />
          <StyledUploadMessageArea limitWidth={!vertical}>
            <Typography variant="caption" align="center">
              <FormattedMessage
                id="common.drag_and_drop_hints"
                description="Drag and drop a file here to upload"
                defaultMessage="Drag and drop a file here to upload"
              />
            </Typography>
            <Typography variant="caption" align="center">
              {uploadHints && (<div>{uploadHints}</div>)}
            </Typography>
            <Button
              color="primary"
              variant="contained"
              startIcon={<FontAwesomeIcon icon={faUpload} />}
            >
              <FormattedMessage
                id="common.upload"
                defaultMessage="Upload"
              />
            </Button>
            {showDelete && hasFile && (
              <Button
                onClick={onDelete}
                variant="outlined"
                startIcon={<Delete />}
              >
                <FormattedMessage
                  id="common.delete"
                  defaultMessage="Delete"
                />
              </Button>
            )}
          </StyledUploadMessageArea>
          {previewer}
        </StyledDropArea>
      )}
    </Dropzone>
  );
};

export default XtraDropZoneV2;
