import { createSlice } from '@reduxjs/toolkit';
import { DESIGN_EDITOR_STORE_NAME } from 'libs/xtra-custom-booth-design/redux/designEditorVars';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import _ from 'lodash';
import { injectExecutingTasksExtraReducer } from 'utils/xtra-executing-tasks';
import { boothEditInitialState } from 'admin-data/booth/BoothEdit/redux/boothEditState';

const boothEditSlice = createSlice({
  name: DESIGN_EDITOR_STORE_NAME,
  initialState: boothEditInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(boothEditActions.internalUpdate, (state, action) => action.payload);

    builder.addCase(boothEditActions.internalUpsertBooth, (state, action) => {
      state.booth = action.payload;
    });

    builder.addCase(boothEditActions.internalResetInitialState, (state) => {
      state.booth = null;
      state.locale = null;
    });

    builder.addCase(boothEditActions.internalUpdateFields, (state, action) => {
      action.payload.fields.forEach((field) => _.set(state, field.source, field.value));
    });

    builder.addCase(boothEditActions.internalUpdateBoothFields, (state, action) => {
      action.payload.fields.forEach((field) => {
        try {
          _.set(state.booth, field.source, field.value);
        } catch (e) {
          console.error(e);
          throw new Error(`Cannot set field ${field.source}}`);
        }
      });
    });

    builder.addCase(boothEditActions.setLocale, (state, action) => {
      state.locale = action.payload;
      localStorage.setItem('previousContentLocale', action.payload);
    });

    injectExecutingTasksExtraReducer(builder, boothEditActions.executingTasks, (state) => state.executingTasks);
  },
});

const boothEditReducer = boothEditSlice.reducer;

export default boothEditReducer;
