import axios from 'axios';
import { defer, from, of } from 'rxjs';
import { EVENT_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { eventEditMutationCompleteObservable } from 'admin-data/event/EventEdit/redux/epic/utils';
import { createStandardEventEditRequestBundle } from 'admin-data/event/EventEdit/redux/createStandardEventEditRequestBundle';
import { map, mergeMap } from 'rxjs/operators';
import { FIRESTORE_ENDPOINT } from 'config';
import { Auditorium } from 'extra-models/auditorium';

const bundleEventAuditoriumGet = createStandardEventEditRequestBundle<string, Auditorium>({
  actionName: `${EVENT_EDIT_ACTIONS_NAME}/auditorium/bundleEventAuditoriumGet`,
  preConditionObservableFactory: eventEditMutationCompleteObservable,

  requestFactory: ({
    eventDomain, locale, authToken, payload,
  }) => defer(() => {
    const idObservable = payload !== 'default'
      ? of(payload)
      : from(axios.get(`${FIRESTORE_ENDPOINT}/events/${eventDomain}/auditoriums/?default=true`))
        .pipe(map((resp) => resp.data.result[0].id as string));

    return idObservable.pipe(
      mergeMap((id) => from(axios.get(`${FIRESTORE_ENDPOINT}/events/${eventDomain}/auditoriums/${id}?attachAssets=true`))),
      map((resp) => resp.data.result),
    );
  }),

  requestResponseMapper: ({
    response, createUpdateEventEditAction,
  }) => of(createUpdateEventEditAction((eventEditState) => {
    eventEditState.fragments.auditorium.auditoriumEdit.editingAuditorium = response;
    eventEditState.fragments.auditorium.auditoriumEdit.selectedContainerKey = null;
  })),
});

export default bundleEventAuditoriumGet;
