import { Epic, StateObservable } from 'redux-observable';
import { filter, first, mergeMap } from 'rxjs/operators';
import {
  concat, defer, EMPTY, of,
} from 'rxjs';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { FieldsUpdatePayload } from 'utils/redux-field-bind/reduxBindAdapter';
import { FieldUpdate } from 'utils/redux-field-bind/fieldUpdate';
import { BatchedDebouncedUpdateRequest } from 'utils/redux-field-bind/updateParser/updateParserBuilder';

type UpdateFieldsRequestEpicFactory = (
  epicTriggerAction: ActionCreatorWithOptionalPayload<FieldsUpdatePayload>,
  internalUpdateAction: ActionCreatorWithOptionalPayload<FieldsUpdatePayload>,
  externalUpdateAction: ActionCreatorWithOptionalPayload<BatchedDebouncedUpdateRequest<any, any>>,
  batchedDebouncedRequestCreator: (state$: StateObservable<any>, field: FieldUpdate<any>) => BatchedDebouncedUpdateRequest<any, any>,
) => Epic

const createUpdateFieldsEpic: UpdateFieldsRequestEpicFactory = (
  epicTriggerActionCreator,
  internalUpdateActionCreator,
  externalDebouncedUpdateActionCreator,
  batchedDebouncedRequestCreator,
) => (action$, state$) => action$.pipe(
  filter(epicTriggerActionCreator.match),
  mergeMap((action) => action.payload.fields),
  mergeMap((field: FieldUpdate<any>) => {
    const internalUpdateAction = of(internalUpdateActionCreator({ fields: [field] }));
    const externalUpdateAction = defer(() => {
      const batchedDebouncedRequest = batchedDebouncedRequestCreator(state$, field);
      return batchedDebouncedRequest == null ? EMPTY : of(externalDebouncedUpdateActionCreator(batchedDebouncedRequest));
    });

    switch (true) {
      case field.skipInternalUpdate && field.skipExternalUpdate:
        return EMPTY;
      case field.skipInternalUpdate && !field.skipExternalUpdate:
        return externalUpdateAction;
      case !field.skipInternalUpdate && field.skipExternalUpdate:
        return internalUpdateAction;
      case !field.skipInternalUpdate && !field.skipExternalUpdate:
        return concat(
          internalUpdateAction,
          action$.pipe(
            first(), // when the internal update action success
            mergeMap(() => externalUpdateAction),
          ),
        );
      default:
        throw new Error('?');
    }
  }),
);

export default createUpdateFieldsEpic;
