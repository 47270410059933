import axios from 'axios';
import { CMS_PRODUCT_API_ENDPOINT } from 'config';
import { defer, of } from 'rxjs';
import { EVENT_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { createStandardEventEditRequestBundle } from 'admin-data/event/EventEdit/redux/createStandardEventEditRequestBundle';
import { map } from 'rxjs/operators';
import { ProductCategory } from 'models-product';
import { ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { LocalizableMap } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import { useRequestDispatchHook } from 'utils/xtra-request-bundle/createStandardRequestBundle';

function findCategoryById(categories: ProductCategory[], id): ProductCategory {
  const result = categories.find((category) => `${category.id}` === id);
  if (result != null) return result;
  return categories.flatMap((category) => findCategoryById(category.subcategories ?? [], id))[0];
}

interface ProductCategoryCreatePayload extends Partial<ProductCategory> {
  level: number;
  label: LocalizableMap<string>;
  sort: number;
}

const bundleEventProductCategoryCreate = createStandardEventEditRequestBundle<ProductCategoryCreatePayload, ProductCategory>({
  actionName: `${EVENT_EDIT_ACTIONS_NAME}/product/category/create`,
  additionalTaskTags: [ExecutingTaskCommonTag.Mutation],
  isSequenced: true,

  requestFactory({
    eventDomain, locale, authToken, payload,
  }) {
    return defer(() => axios.post(`${CMS_PRODUCT_API_ENDPOINT}/categories`, {
      domain: eventDomain,
      products: [],
      subcategories: [],
      ...payload,
    }, {
      headers: {
        'x-eventxtra-admin': true,
      },
    })).pipe(map((resp) => resp.data));
  },

  requestResponseMapper: ({ payload, response, createUpdateEventEditAction }) => of(createUpdateEventEditAction((eventEditState) => {
    if (eventEditState.event.productSettings == null) eventEditState.event.productSettings = {} as any;
    if (eventEditState.event.productSettings.categories == null) eventEditState.event.productSettings.categories = [];

    if (!response.parentCategory) {
      eventEditState.event.productSettings.categories.push(response);
    } else {
      const parent = findCategoryById(eventEditState.event.productSettings.categories, payload.parentCategory);
      if (parent != null && parent.subcategories == null) parent.subcategories = [];

      (parent?.subcategories ?? eventEditState.event.productSettings.categories).push(response);
    }
  })),
});

export default bundleEventProductCategoryCreate;
