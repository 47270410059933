import { Epic } from 'redux-observable';
import {
  filter, ignoreElements, mergeMap,
} from 'rxjs/operators';
import {
  concat, of,
} from 'rxjs';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import {
  catchErrorToPopup,
  throwCommonError,
} from 'redux/mutationFailedPipe';
import cmsSelectors from 'redux/cmsSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import produce from 'immer';

const samePageReorderBoothEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.fragments.booths.mutations.samePageReorderBooth.match),
  mergeMap(({ payload: { id, pageIndex } }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);

    const originalEventEditFactory = () => eventEditSelectors.selectEventEdit(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.fragments.booths.mutations.samePageReorderBooth(),
        ExecutingTaskCommonTag.Mutation],
    });

    const boothOriginalIndex = originalEventEditFactory().fragments.boothsV2.pagedBoothsList.findIndex((it) => it.id === id);

    const internalReorderAction = eventEditActions.internalUpdate(produce(originalEventEditFactory(), ((draft) => {
      draft.fragments.boothsV2.pagedBoothsList.splice(pageIndex, 0, draft.fragments.boothsV2.pagedBoothsList.splice(boothOriginalIndex, 1)[0]);
    })));

    const { pageSize, pageNumber } = eventEditSelectors.fragments.booths.selectFragment(state$.value);
    const orderingPosition = pageSize * pageNumber + pageIndex;

    const requests = sdk.boothUpdate({ input: { id, orderingPosition } }).pipe(
      throwCommonError(),
      ignoreElements(),
      catchErrorToPopup(state$.value.intl),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return concat(
      of(internalReorderAction),
      of(eventEditActions.internalAddSequencedRequest(requests)),
    );
  }),
);

export default samePageReorderBoothEpic;
