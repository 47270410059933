import { Epic } from 'redux-observable';
import {
  filter, groupBy, map, mergeMap,
} from 'rxjs/operators';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';

const internalAddDebouncedRequestEpic: Epic = (action$, state$) => action$.pipe(
  filter(boothEditActions.internalAddDebouncedRequest.match),
  groupBy(({ payload }) => payload.debounceKey),
);

export default internalAddDebouncedRequestEpic;
