import { Epic } from 'redux-observable';
import { rawDesignEditorInstanceActions, UpdateLayerFieldsPayload } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceActions';
import { map, mergeMap } from 'rxjs/operators';
import { DesignIdWrappedPayload } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designIdWrappedPayload';
import createDesignEditorInstanceSelectors from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceSelectors';
import produce from 'immer';
import _ from 'lodash';
import { of } from 'rxjs';

const updateLayerFieldsEpic: Epic = (action$, state$) => action$
  .ofType(rawDesignEditorInstanceActions.updateLayerFields).pipe(
    map((action) => action.payload as DesignIdWrappedPayload<UpdateLayerFieldsPayload>),
    mergeMap(({ instanceDesignId, data: { layerId, fields } }) => {
      const { selectLayerById } = createDesignEditorInstanceSelectors(instanceDesignId);
      const layer = selectLayerById(layerId)(state$.value);
      const newLayer = produce(layer, (draft) => fields.forEach(({ source, value }) => _.set(draft, source, value)));
      return of(rawDesignEditorInstanceActions.updateLayer({ instanceDesignId, data: newLayer }));
    }),
  );

export default updateLayerFieldsEpic;
