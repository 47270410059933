import React, { useContext, useMemo } from 'react';
import { DesignEditorDockerComponent } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/designEditorDockerEntry';
import { ToolboxIconsContainer } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorCanvasToolboxDocker/style';
import { TOOLBOX_ITEMS_CREATOR } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/DesignEditorInstanceCanvas/canvasTools';
import DesignEditorToolboxIconButton
  from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorCanvasToolboxDocker/DesignEditorToolboxIconButton';
import { useIntl } from 'react-intl';

const DesignEditorCanvasToolboxDocker: DesignEditorDockerComponent = (props) => {
  const intl = useIntl();
  const items = useMemo(() => Object.values(TOOLBOX_ITEMS_CREATOR(intl)), [intl]);

  return (
    <ToolboxIconsContainer>
      {items.map((item) => (
        <DesignEditorToolboxIconButton toolboxItem={item} key={item.tool} />
      ))}
    </ToolboxIconsContainer>
  );
};

export default DesignEditorCanvasToolboxDocker;
