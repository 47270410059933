import { GraphQLClient } from 'graphql-request';
import { print } from 'graphql';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** six-digit hexadecimal string representing red, green, blue components of a web color prefixed by hash symbol # */
  Color: string;
  /** Decimal number representing dimension of display measurement */
  DisplayMeasurementDimension: any;
  /** An URL without the schema and query */
  DomainName: any;
  /** An email address */
  Email: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
  /** A language code in IANA Language Subtag Registry */
  Locale: string;
  /** Signed blob ID generated via `createDirectUpload` mutation */
  SignedBlobID: any;
  /** Standard error key path, could be either a String or Int */
  StandardErrorPathSegment: any;
  Upload: any;
}

export interface AbilityQueryable {
  currentAbilityCanPerform?: Maybe<Scalars['Boolean']>;
}


export interface AbilityQueryableCurrentAbilityCanPerformArgs {
  action: Scalars['String'];
}

/** Autogenerated input type of AddContact */
export interface AddContactInput {
  userUuid: Scalars['String'];
  contactUuid: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of AddContact */
export interface AddContactPayload {
  __typename?: 'AddContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<Attendee>;
  success: Scalars['Boolean'];
}

/** Autogenerated input type of AdminEventCreate */
export interface AdminEventCreateInput {
  /** EMS access token */
  emsAccessToken: Scalars['ID'];
  /** EMS event gid */
  emsEventGid: Scalars['ID'];
  /** name of the event */
  name: LocalizableStringInput;
  /** domain of the event */
  domain: Scalars['DomainName'];
  /** supported locales of the event */
  locales: Array<Scalars['Locale']>;
  /** enabled features of the event */
  features: Array<EventFeature>;
  /** start time of the event */
  startAt: Scalars['ISO8601DateTime'];
  /** end time of the event */
  endAt: Scalars['ISO8601DateTime'];
  /** business matching start time of the event */
  businessMatchingStartAt: Scalars['ISO8601DateTime'];
  /** business matching end time of the event */
  businessMatchingEndAt: Scalars['ISO8601DateTime'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of AdminEventCreate */
export interface AdminEventCreatePayload {
  __typename?: 'AdminEventCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** The `Event` object created */
  event: Event;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface Attendee extends AbilityQueryable {
  __typename?: 'Attendee';
  company?: Maybe<Scalars['String']>;
  currentAbilityCanPerform?: Maybe<Scalars['Boolean']>;
  customAttendeeFields?: Maybe<Scalars['JSON']>;
  email?: Maybe<Scalars['String']>;
  emsAttendeeGid?: Maybe<Scalars['String']>;
  enrichment?: Maybe<Scalars['JSON']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  rongcloudToken?: Maybe<Scalars['String']>;
  /** @deprecated same query object */
  rongcloudUser?: Maybe<Attendee>;
  /**
   * same as uuid
   * @deprecated use `uuid` instead
   */
  rongcloudUserId?: Maybe<Scalars['String']>;
  /** can only be obtained through query `attendeeByUniqueToken` */
  tickets: AttendeeTicketConnection;
  title?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
  vexpoAccessUrl?: Maybe<Scalars['String']>;
  vexpoChatTag?: Maybe<Scalars['String']>;
  vexpoChatUserDescription?: Maybe<Scalars['String']>;
}


export interface AttendeeCurrentAbilityCanPerformArgs {
  action: Scalars['String'];
}


export interface AttendeeTicketsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** The connection type for Attendee. */
export interface AttendeeConnection {
  __typename?: 'AttendeeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AttendeeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Attendee>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface AttendeeEdge {
  __typename?: 'AttendeeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Attendee>;
}

export interface AttendeeTicket {
  __typename?: 'AttendeeTicket';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

/** The connection type for AttendeeTicket. */
export interface AttendeeTicketConnection {
  __typename?: 'AttendeeTicketConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AttendeeTicketEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AttendeeTicket>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface AttendeeTicketEdge {
  __typename?: 'AttendeeTicketEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AttendeeTicket>;
}

/** Autogenerated input type of AttendeeUpdate */
export interface AttendeeUpdateInput {
  /** ID of the attendee */
  attendeeId: Scalars['ID'];
  /** The updated first name of the attendee */
  firstName?: Maybe<Scalars['String']>;
  /** The updated last name of the attendee */
  lastName?: Maybe<Scalars['String']>;
  /** The updated email of the attendee */
  email?: Maybe<Scalars['Email']>;
  /** The updated company of the attendee */
  company?: Maybe<Scalars['String']>;
  /** The updated title of the attendee */
  title?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of AttendeeUpdate */
export interface AttendeeUpdatePayload {
  __typename?: 'AttendeeUpdatePayload';
  /** The updated attendee */
  attendee: Attendee;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface Booth extends Node, AbilityQueryable {
  __typename?: 'Booth';
  appliedDesignCodename?: Maybe<Scalars['String']>;
  appointmentBookingUrl?: Maybe<Scalars['String']>;
  boothDesign?: Maybe<BoothDesign>;
  boothDesignCanvas?: Maybe<BoothDesignCanvasElement>;
  boothNumber?: Maybe<Scalars['String']>;
  categories: BoothCategoryConnection;
  cmsUrl?: Maybe<Scalars['String']>;
  currentAbilityCanPerform?: Maybe<Scalars['Boolean']>;
  description: LocalizableString;
  designElements: BoothDesignElementConnection;
  event: Event;
  exhibitionName: LocalizableString;
  hall: Hall;
  id: Scalars['ID'];
  logo: LocalizableImage;
  /** To indicate whether meetup function is enabled */
  meetupEnabled?: Maybe<Scalars['Boolean']>;
  meetupExhibitorManagementUrl?: Maybe<Scalars['String']>;
  name: LocalizableString;
  previewUrl?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  reportToken?: Maybe<Scalars['String']>;
  representatives?: Maybe<AttendeeConnection>;
  rootContentElement: BoothRootContentElement;
  rowOrder?: Maybe<Scalars['String']>;
  tier: BoothTier;
  website?: Maybe<Scalars['String']>;
}


export interface BoothCategoriesArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface BoothCurrentAbilityCanPerformArgs {
  action: Scalars['String'];
}


export interface BoothDesignElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface BoothRepresentativesArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  deleted?: Maybe<Scalars['Boolean']>;
}

export interface BoothCategory extends Node, BoothTaxonomy {
  __typename?: 'BoothCategory';
  /** ID of the object. */
  id: Scalars['ID'];
  name: LocalizableString;
  parentSupercategory?: Maybe<BoothSupercategory>;
  /** @deprecated Use `parentSupercategory` instead */
  supercategory?: Maybe<BoothSupercategory>;
}

/** The connection type for BoothCategory. */
export interface BoothCategoryConnection {
  __typename?: 'BoothCategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothCategoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothCategory>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** Autogenerated input type of BoothCategoryCreate */
export interface BoothCategoryCreateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of parent `BoothSupercategory` */
  parentSupercategoryId?: Maybe<Scalars['ID']>;
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** Name of `BoothCategory` */
  name: LocalizableStringInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothCategoryCreate */
export interface BoothCategoryCreatePayload {
  __typename?: 'BoothCategoryCreatePayload';
  /** The `BoothCategory` object created */
  boothCategory: BoothCategory;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothCategoryEdge {
  __typename?: 'BoothCategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothCategory>;
}

/** Autogenerated input type of BoothCategoryUpdate */
export interface BoothCategoryUpdateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of `BoothCategory` */
  categoryId: Scalars['ID'];
  /** ID of parent `BoothSupercategory` */
  parentSupercategoryId?: Maybe<Scalars['ID']>;
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** Name of `BoothCategory` */
  name?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothCategoryUpdate */
export interface BoothCategoryUpdatePayload {
  __typename?: 'BoothCategoryUpdatePayload';
  /** The `BoothCategory` object updated */
  boothCategory: BoothCategory;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** The connection type for Booth. */
export interface BoothConnection {
  __typename?: 'BoothConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Booth>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface BoothContentElement {
  booth: Booth;
  childElements: BoothContentElementConnection;
  event: Event;
  id: Scalars['ID'];
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  rawElement: BoothContentElement;
}


export interface BoothContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** The connection type for BoothContentElement. */
export interface BoothContentElementConnection {
  __typename?: 'BoothContentElementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothContentElementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothContentElement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** Autogenerated input type of BoothContentElementDelete */
export interface BoothContentElementDeleteInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothContentElementDelete */
export interface BoothContentElementDeletePayload {
  __typename?: 'BoothContentElementDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothContentElement` object deleted */
  element: BoothContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothContentElementEdge {
  __typename?: 'BoothContentElementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothContentElement>;
}

/** Autogenerated input type of BoothCreate */
export interface BoothCreateInput {
  /** ID of the booth */
  eventId: Scalars['ID'];
  /** ID of the hall */
  hallId: Scalars['ID'];
  /** ID of the booth tier */
  boothTierId: Scalars['ID'];
  /** ID of the booth design */
  designId?: Maybe<Scalars['ID']>;
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** name of the booth design */
  appliedDesignCodename?: Maybe<Scalars['String']>;
  /** Publish status of the booth */
  published?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothCreate */
export interface BoothCreatePayload {
  __typename?: 'BoothCreatePayload';
  /** The `Booth` object created */
  booth: Booth;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesign extends Node {
  __typename?: 'BoothDesign';
  canvas?: Maybe<BoothDesignCanvasMasterElement>;
  event?: Maybe<Event>;
  id: Scalars['ID'];
  name: LocalizableString;
}

/** Action type of booth design button */
export enum BoothDesignButtonActionType {
  /** Chat */
  Chat = 'CHAT',
  /** Meetup */
  Meetup = 'MEETUP',
  /** External link */
  ExternalLink = 'EXTERNAL_LINK',
  /** Localizable External link */
  LocalizableExternalLink = 'LOCALIZABLE_EXTERNAL_LINK',
  /** Contact Us */
  ContactUs = 'CONTACT_US',
  /** File Tab */
  FileTab = 'FILE_TAB',
  /** Live Stream */
  LiveStream = 'LIVE_STREAM',
  /** Video Call */
  VideoCall = 'VIDEO_CALL'
}

export interface BoothDesignCanvasElement extends Node, BoothV2DesignElement {
  __typename?: 'BoothDesignCanvasElement';
  aspectRatio?: Maybe<DisplayMeasurement>;
  booth: Booth;
  height?: Maybe<DisplayMeasurement>;
  /** ID of the object. */
  id: Scalars['ID'];
  layers: BoothV2DesignElementConnection;
  masterElement?: Maybe<BoothDesignMasterElement>;
  maxWidth?: Maybe<DisplayMeasurement>;
  positionX?: Maybe<DisplayMeasurement>;
  positionY?: Maybe<DisplayMeasurement>;
  /** Preview of BoothDesignCanvasElement in PNG image file format */
  previewImage?: Maybe<Image>;
  rawElement?: Maybe<BoothV2DesignElement>;
  width?: Maybe<DisplayMeasurement>;
}


export interface BoothDesignCanvasElementLayersArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

export interface BoothDesignCanvasMasterElement extends BoothDesignMasterElement {
  __typename?: 'BoothDesignCanvasMasterElement';
  aspectRatio: DisplayMeasurement;
  event: Event;
  height: DisplayMeasurement;
  id: Scalars['ID'];
  layers: BoothDesignMasterElementConnection;
  maxWidth: DisplayMeasurement;
  parentElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  width: DisplayMeasurement;
}


export interface BoothDesignCanvasMasterElementLayersArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothDesignCanvasMasterElementUpdate */
export interface BoothDesignCanvasMasterElementUpdateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** Height of element */
  height?: Maybe<DisplayMeasurementInput>;
  /** Width of element */
  width?: Maybe<DisplayMeasurementInput>;
  /** X-position of element */
  positionX?: Maybe<DisplayMeasurementInput>;
  /** Y-position of element */
  positionY?: Maybe<DisplayMeasurementInput>;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Aspect ratio of element */
  aspectRatio?: Maybe<DisplayMeasurementInput>;
  /** Max width of element */
  maxWidth?: Maybe<DisplayMeasurementInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignCanvasMasterElementUpdate */
export interface BoothDesignCanvasMasterElementUpdatePayload {
  __typename?: 'BoothDesignCanvasMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignCanvasMasterElement` object updated */
  element: BoothDesignCanvasMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesignColorFillElement extends Node, BoothV2DesignElement, BoothDesignCustomizableElement {
  __typename?: 'BoothDesignColorFillElement';
  booth: Booth;
  fillColor?: Maybe<Scalars['Color']>;
  height?: Maybe<DisplayMeasurement>;
  /** ID of the object. */
  id: Scalars['ID'];
  keyName?: Maybe<Scalars['String']>;
  maskImage: LocalizableImage;
  masterElement?: Maybe<BoothDesignMasterElement>;
  positionX?: Maybe<DisplayMeasurement>;
  positionY?: Maybe<DisplayMeasurement>;
  rawElement?: Maybe<BoothV2DesignElement>;
  width?: Maybe<DisplayMeasurement>;
}

/** Autogenerated input type of BoothDesignColorFillElementUpdate */
export interface BoothDesignColorFillElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  fillColor?: Maybe<Scalars['Color']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignColorFillElementUpdate */
export interface BoothDesignColorFillElementUpdatePayload {
  __typename?: 'BoothDesignColorFillElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignColorFillElement` object created */
  element: BoothDesignColorFillElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesignColorFillMasterElement extends BoothDesignMasterElement, BoothDesignCustomizableMasterElement {
  __typename?: 'BoothDesignColorFillMasterElement';
  event: Event;
  fillColor?: Maybe<Scalars['Color']>;
  height: DisplayMeasurement;
  id: Scalars['ID'];
  keyName?: Maybe<Scalars['String']>;
  maskImage: LocalizableImage;
  masterControlledFillColor: Scalars['Boolean'];
  parentElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  width: DisplayMeasurement;
}

/** Autogenerated input type of BoothDesignColorFillMasterElementCreate */
export interface BoothDesignColorFillMasterElementCreateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** Height of element */
  height: DisplayMeasurementInput;
  /** Width of element */
  width: DisplayMeasurementInput;
  /** X-position of element */
  positionX: DisplayMeasurementInput;
  /** Y-position of element */
  positionY: DisplayMeasurementInput;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  fillColor?: Maybe<Scalars['Color']>;
  /** Mask image */
  maskImage?: Maybe<LocalizableFileInput>;
  /** To indicate whether the fill color will be mirrored by its child elements */
  masterControlledFillColor?: Maybe<Scalars['Boolean']>;
  /** Key name of element */
  keyName: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignColorFillMasterElementCreate */
export interface BoothDesignColorFillMasterElementCreatePayload {
  __typename?: 'BoothDesignColorFillMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignColorFillMasterElement` object created */
  element: BoothDesignColorFillMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothDesignColorFillMasterElementUpdate */
export interface BoothDesignColorFillMasterElementUpdateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** Height of element */
  height?: Maybe<DisplayMeasurementInput>;
  /** Width of element */
  width?: Maybe<DisplayMeasurementInput>;
  /** X-position of element */
  positionX?: Maybe<DisplayMeasurementInput>;
  /** Y-position of element */
  positionY?: Maybe<DisplayMeasurementInput>;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  fillColor?: Maybe<Scalars['Color']>;
  /** Mask image */
  maskImage?: Maybe<LocalizableFileInput>;
  /** To indicate whether the fill color will be mirrored by its child elements */
  masterControlledFillColor?: Maybe<Scalars['Boolean']>;
  /** Updated key name */
  keyName?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignColorFillMasterElementUpdate */
export interface BoothDesignColorFillMasterElementUpdatePayload {
  __typename?: 'BoothDesignColorFillMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignColorFillMasterElement` object updated */
  element: BoothDesignColorFillMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** The connection type for BoothDesign. */
export interface BoothDesignConnection {
  __typename?: 'BoothDesignConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothDesignEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothDesign>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface BoothDesignContentAreaElement extends Node, BoothV2DesignElement, BoothDesignCustomizableElement {
  __typename?: 'BoothDesignContentAreaElement';
  acceptedContentTypes: Array<BoothDesignContentType>;
  booth: Booth;
  contentImage: LocalizableImage;
  contentPdf: LocalizableFile;
  contentRichtextHtml: LocalizableString;
  contentRichtextRaw: LocalizableString;
  contentType?: Maybe<BoothDesignContentType>;
  contentVideo: LocalizableVideo;
  height?: Maybe<DisplayMeasurement>;
  /** ID of the object. */
  id: Scalars['ID'];
  keyName?: Maybe<Scalars['String']>;
  masterElement?: Maybe<BoothDesignMasterElement>;
  positionX?: Maybe<DisplayMeasurement>;
  positionY?: Maybe<DisplayMeasurement>;
  rawElement?: Maybe<BoothV2DesignElement>;
  thumbnailImage: LocalizableImage;
  width?: Maybe<DisplayMeasurement>;
}

/** Autogenerated input type of BoothDesignContentAreaElementUpdate */
export interface BoothDesignContentAreaElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** List of accepted content types */
  contentType?: Maybe<BoothDesignContentType>;
  /** Richtext raw content value when contentType is RICHTEXT */
  contentRichtextRaw?: Maybe<LocalizableStringInput>;
  /** Richtext html content value when contentType is RICHTEXT */
  contentRichtextHtml?: Maybe<LocalizableStringInput>;
  /** Content image value when contentType is IMAGE */
  contentImage?: Maybe<LocalizableFileInput>;
  /** Content video value when contentType is VIDEO */
  contentVideo?: Maybe<LocalizableFileInput>;
  /** Content pdf value when contentType is PDF */
  contentPdf?: Maybe<LocalizableFileInput>;
  /** Thumbnail image value */
  thumbnailImage?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignContentAreaElementUpdate */
export interface BoothDesignContentAreaElementUpdatePayload {
  __typename?: 'BoothDesignContentAreaElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignContentAreaElement` object created */
  element: BoothDesignContentAreaElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesignContentAreaMasterElement extends BoothDesignMasterElement, BoothDesignCustomizableMasterElement {
  __typename?: 'BoothDesignContentAreaMasterElement';
  acceptedContentTypes: Array<BoothDesignContentType>;
  event: Event;
  height: DisplayMeasurement;
  id: Scalars['ID'];
  keyName?: Maybe<Scalars['String']>;
  parentElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  width: DisplayMeasurement;
}

/** Autogenerated input type of BoothDesignContentAreaMasterElementCreate */
export interface BoothDesignContentAreaMasterElementCreateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** Height of element */
  height: DisplayMeasurementInput;
  /** Width of element */
  width: DisplayMeasurementInput;
  /** X-position of element */
  positionX: DisplayMeasurementInput;
  /** Y-position of element */
  positionY: DisplayMeasurementInput;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  /** List of accepted content types */
  acceptedContentTypes?: Maybe<Array<BoothDesignContentType>>;
  /** Key name of element */
  keyName: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignContentAreaMasterElementCreate */
export interface BoothDesignContentAreaMasterElementCreatePayload {
  __typename?: 'BoothDesignContentAreaMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignContentAreaMasterElement` object created */
  element: BoothDesignContentAreaMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothDesignContentAreaMasterElementUpdate */
export interface BoothDesignContentAreaMasterElementUpdateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** Height of element */
  height?: Maybe<DisplayMeasurementInput>;
  /** Width of element */
  width?: Maybe<DisplayMeasurementInput>;
  /** X-position of element */
  positionX?: Maybe<DisplayMeasurementInput>;
  /** Y-position of element */
  positionY?: Maybe<DisplayMeasurementInput>;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  /** List of accepted content types */
  acceptedContentTypes?: Maybe<Array<BoothDesignContentType>>;
  /** Updated key name */
  keyName?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignContentAreaMasterElementUpdate */
export interface BoothDesignContentAreaMasterElementUpdatePayload {
  __typename?: 'BoothDesignContentAreaMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignContentAreaMasterElement` object updated */
  element: BoothDesignContentAreaMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Available content types for booth design */
export enum BoothDesignContentType {
  /** Image */
  Image = 'IMAGE',
  /** Richtext */
  Richtext = 'RICHTEXT',
  /** Video */
  Video = 'VIDEO',
  /** PDF */
  Pdf = 'PDF'
}

/** Autogenerated input type of BoothDesignCreate */
export interface BoothDesignCreateInput {
  /** ID of the booth */
  eventId: Scalars['ID'];
  /** name of the booth design */
  name: LocalizableStringInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignCreate */
export interface BoothDesignCreatePayload {
  __typename?: 'BoothDesignCreatePayload';
  /** The `BoothDesign` object created */
  boothDesign: BoothDesign;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesignCustomizableElement {
  keyName?: Maybe<Scalars['String']>;
}

export interface BoothDesignCustomizableMasterElement {
  keyName?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of BoothDesignDelete */
export interface BoothDesignDeleteInput {
  /** ID of the booth */
  eventId: Scalars['ID'];
  /** ID of the `BoothDesign` object to update */
  boothDesignId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignDelete */
export interface BoothDesignDeletePayload {
  __typename?: 'BoothDesignDeletePayload';
  /** The `BoothDesign` object deleted */
  boothDesign: BoothDesign;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothDesignEdge {
  __typename?: 'BoothDesignEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothDesign>;
}

export interface BoothDesignElement extends Node {
  __typename?: 'BoothDesignElement';
  actionUrl?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['Color']>;
  contentRichtextHtml: LocalizableString;
  contentRichtextRaw: LocalizableString;
  id: Scalars['ID'];
  image: LocalizableImage;
  kind?: Maybe<BoothDesignElementKind>;
  name: Scalars['String'];
  pdf: LocalizableFile;
  thumbnailImage: LocalizableImage;
  validKinds: Array<BoothDesignElementKind>;
  video: LocalizableVideo;
}

/** The connection type for BoothDesignElement. */
export interface BoothDesignElementConnection {
  __typename?: 'BoothDesignElementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothDesignElementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothDesignElement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface BoothDesignElementEdge {
  __typename?: 'BoothDesignElementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothDesignElement>;
}

/** Kind of BoothDesignElement */
export enum BoothDesignElementKind {
  /** Color */
  Color = 'COLOR',
  /** Image */
  Image = 'IMAGE',
  /** Richtext */
  Richtext = 'RICHTEXT',
  /** Video */
  Video = 'VIDEO',
  /** PDF */
  Pdf = 'PDF'
}

/** Autogenerated input type of BoothDesignElementUpdate */
export interface BoothDesignElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** name of the design element to update */
  name: Scalars['String'];
  /** Updated kind of the booth design element */
  kind?: Maybe<BoothDesignElementKind>;
  /** Updated color value */
  color?: Maybe<Scalars['Color']>;
  /** Updated video file value */
  video?: Maybe<LocalizableFileInput>;
  /** Updated image file value */
  image?: Maybe<LocalizableFileInput>;
  /** Updated pdf file value */
  pdf?: Maybe<LocalizableFileInput>;
  /** Updated thumbnail image file value */
  thumbnailImage?: Maybe<LocalizableFileInput>;
  /** Updated raw richtext content value */
  contentRichtextRaw?: Maybe<LocalizableStringInput>;
  /** Updated HTML richtext content value */
  contentRichtextHtml?: Maybe<LocalizableStringInput>;
  /** Action URL */
  actionUrl?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignElementUpdate */
export interface BoothDesignElementUpdatePayload {
  __typename?: 'BoothDesignElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignElement` object after update */
  designElement: BoothDesignElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesignImageButtonElement extends Node, BoothV2DesignElement, BoothDesignCustomizableElement {
  __typename?: 'BoothDesignImageButtonElement';
  actionExternalLinkUrl?: Maybe<Scalars['String']>;
  actionLocalizableExternalLinkUrl?: Maybe<LocalizableString>;
  actionType?: Maybe<BoothDesignButtonActionType>;
  booth: Booth;
  height?: Maybe<DisplayMeasurement>;
  hoverStateImage: LocalizableImage;
  /** ID of the object. */
  id: Scalars['ID'];
  keyName?: Maybe<Scalars['String']>;
  masterElement?: Maybe<BoothDesignMasterElement>;
  normalStateImage: LocalizableImage;
  positionX?: Maybe<DisplayMeasurement>;
  positionY?: Maybe<DisplayMeasurement>;
  rawElement?: Maybe<BoothV2DesignElement>;
  width?: Maybe<DisplayMeasurement>;
}

/** Autogenerated input type of BoothDesignImageButtonElementUpdate */
export interface BoothDesignImageButtonElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** Button action type */
  actionType?: Maybe<BoothDesignButtonActionType>;
  /** Link of redirection on button click */
  actionExternalLinkUrl?: Maybe<Scalars['String']>;
  /** Localiable link of redirection on button click */
  actionLocalizableExternalLinkUrl?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignImageButtonElementUpdate */
export interface BoothDesignImageButtonElementUpdatePayload {
  __typename?: 'BoothDesignImageButtonElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignImageButtonElement` object updated */
  element: BoothDesignImageButtonElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesignImageButtonMasterElement extends BoothDesignMasterElement, BoothDesignCustomizableMasterElement {
  __typename?: 'BoothDesignImageButtonMasterElement';
  actionExternalLinkUrl?: Maybe<Scalars['String']>;
  actionLocalizableExternalLinkUrl?: Maybe<LocalizableString>;
  actionType?: Maybe<BoothDesignButtonActionType>;
  event: Event;
  height: DisplayMeasurement;
  hoverStateImage: LocalizableImage;
  id: Scalars['ID'];
  keyName?: Maybe<Scalars['String']>;
  masterControlledAction: Scalars['Boolean'];
  normalStateImage: LocalizableImage;
  parentElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  width: DisplayMeasurement;
}

/** Autogenerated input type of BoothDesignImageButtonMasterElementCreate */
export interface BoothDesignImageButtonMasterElementCreateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** Height of element */
  height: DisplayMeasurementInput;
  /** Width of element */
  width: DisplayMeasurementInput;
  /** X-position of element */
  positionX: DisplayMeasurementInput;
  /** Y-position of element */
  positionY: DisplayMeasurementInput;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  /** The image to be shown when the button is in normal state */
  normalStateImage: LocalizableFileInput;
  /** The image to be shown when the button is in hover state */
  hoverStateImage: LocalizableFileInput;
  /** To indicate whether the button behaviour will be mirrored by its child elements */
  masterControlledAction?: Maybe<Scalars['Boolean']>;
  /** Button action type */
  actionType: BoothDesignButtonActionType;
  /** Link of redirection on button click */
  actionExternalLinkUrl?: Maybe<Scalars['String']>;
  /** Localiable link of redirection on button click */
  actionLocalizableExternalLinkUrl?: Maybe<LocalizableStringInput>;
  /** Key name of element */
  keyName: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignImageButtonMasterElementCreate */
export interface BoothDesignImageButtonMasterElementCreatePayload {
  __typename?: 'BoothDesignImageButtonMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignImageButtonMasterElement` object created */
  element: BoothDesignImageButtonMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothDesignImageButtonMasterElementUpdate */
export interface BoothDesignImageButtonMasterElementUpdateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** Height of element */
  height?: Maybe<DisplayMeasurementInput>;
  /** Width of element */
  width?: Maybe<DisplayMeasurementInput>;
  /** X-position of element */
  positionX?: Maybe<DisplayMeasurementInput>;
  /** Y-position of element */
  positionY?: Maybe<DisplayMeasurementInput>;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  /** The image to be shown when the button is in normal state */
  normalStateImage: LocalizableFileInput;
  /** The image to be shown when the button is in hover state */
  hoverStateImage: LocalizableFileInput;
  /** To indicate whether the button behaviour will be mirrored by its child elements */
  masterControlledAction?: Maybe<Scalars['Boolean']>;
  /** Button action type */
  actionType?: Maybe<BoothDesignButtonActionType>;
  /** Link of redirection on button click */
  actionExternalLinkUrl?: Maybe<Scalars['String']>;
  /** Localiable link of redirection on button click */
  actionLocalizableExternalLinkUrl?: Maybe<LocalizableStringInput>;
  /** Updated key name */
  keyName?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignImageButtonMasterElementUpdate */
export interface BoothDesignImageButtonMasterElementUpdatePayload {
  __typename?: 'BoothDesignImageButtonMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignImageButtonMasterElement` object updated */
  element: BoothDesignImageButtonMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesignImageElement extends Node, BoothV2DesignElement {
  __typename?: 'BoothDesignImageElement';
  booth: Booth;
  height?: Maybe<DisplayMeasurement>;
  /** ID of the object. */
  id: Scalars['ID'];
  image: LocalizableImage;
  masterElement?: Maybe<BoothDesignMasterElement>;
  positionX?: Maybe<DisplayMeasurement>;
  positionY?: Maybe<DisplayMeasurement>;
  rawElement?: Maybe<BoothV2DesignElement>;
  width?: Maybe<DisplayMeasurement>;
}

export interface BoothDesignImageMasterElement extends BoothDesignMasterElement {
  __typename?: 'BoothDesignImageMasterElement';
  event: Event;
  height: DisplayMeasurement;
  id: Scalars['ID'];
  image: LocalizableImage;
  parentElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  width: DisplayMeasurement;
}

/** Autogenerated input type of BoothDesignImageMasterElementCreate */
export interface BoothDesignImageMasterElementCreateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** Height of element */
  height: DisplayMeasurementInput;
  /** Width of element */
  width: DisplayMeasurementInput;
  /** X-position of element */
  positionX: DisplayMeasurementInput;
  /** Y-position of element */
  positionY: DisplayMeasurementInput;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Element image */
  image?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignImageMasterElementCreate */
export interface BoothDesignImageMasterElementCreatePayload {
  __typename?: 'BoothDesignImageMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignImageMasterElement` object created */
  element: BoothDesignImageMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothDesignImageMasterElementUpdate */
export interface BoothDesignImageMasterElementUpdateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** Height of element */
  height?: Maybe<DisplayMeasurementInput>;
  /** Width of element */
  width?: Maybe<DisplayMeasurementInput>;
  /** X-position of element */
  positionX?: Maybe<DisplayMeasurementInput>;
  /** Y-position of element */
  positionY?: Maybe<DisplayMeasurementInput>;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Updated image */
  image?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignImageMasterElementUpdate */
export interface BoothDesignImageMasterElementUpdatePayload {
  __typename?: 'BoothDesignImageMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignImageMasterElement` object updated */
  element: BoothDesignImageMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDesignMasterElement {
  event: Event;
  height: DisplayMeasurement;
  id: Scalars['ID'];
  parentElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  width: DisplayMeasurement;
}

/** The connection type for BoothDesignMasterElement. */
export interface BoothDesignMasterElementConnection {
  __typename?: 'BoothDesignMasterElementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothDesignMasterElementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothDesignMasterElement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** Autogenerated input type of BoothDesignMasterElementDelete */
export interface BoothDesignMasterElementDeleteInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignMasterElementDelete */
export interface BoothDesignMasterElementDeletePayload {
  __typename?: 'BoothDesignMasterElementDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignMasterElement` object deleted */
  element: BoothDesignMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothDesignMasterElementEdge {
  __typename?: 'BoothDesignMasterElementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothDesignMasterElement>;
}

export interface BoothDesignNetworkingWidgetElement extends Node, BoothV2DesignElement {
  __typename?: 'BoothDesignNetworkingWidgetElement';
  booth: Booth;
  height?: Maybe<DisplayMeasurement>;
  /** ID of the object. */
  id: Scalars['ID'];
  masterElement?: Maybe<BoothDesignMasterElement>;
  positionX?: Maybe<DisplayMeasurement>;
  positionY?: Maybe<DisplayMeasurement>;
  rawElement?: Maybe<BoothV2DesignElement>;
  width?: Maybe<DisplayMeasurement>;
}

export interface BoothDesignNetworkingWidgetMasterElement extends BoothDesignMasterElement {
  __typename?: 'BoothDesignNetworkingWidgetMasterElement';
  event: Event;
  height: DisplayMeasurement;
  id: Scalars['ID'];
  parentElement?: Maybe<BoothDesignMasterElement>;
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  width: DisplayMeasurement;
}

/** Autogenerated input type of BoothDesignNetworkingWidgetMasterElementCreate */
export interface BoothDesignNetworkingWidgetMasterElementCreateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** Height of element */
  height: DisplayMeasurementInput;
  /** Width of element */
  width: DisplayMeasurementInput;
  /** X-position of element */
  positionX: DisplayMeasurementInput;
  /** Y-position of element */
  positionY: DisplayMeasurementInput;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignNetworkingWidgetMasterElementCreate */
export interface BoothDesignNetworkingWidgetMasterElementCreatePayload {
  __typename?: 'BoothDesignNetworkingWidgetMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignNetworkingWidgetMasterElement` object created */
  element: BoothDesignNetworkingWidgetMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothDesignNetworkingWidgetMasterElementUpdate */
export interface BoothDesignNetworkingWidgetMasterElementUpdateInput {
  /** ID of the booth design */
  boothDesignId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** Height of element */
  height?: Maybe<DisplayMeasurementInput>;
  /** Width of element */
  width?: Maybe<DisplayMeasurementInput>;
  /** X-position of element */
  positionX?: Maybe<DisplayMeasurementInput>;
  /** Y-position of element */
  positionY?: Maybe<DisplayMeasurementInput>;
  /** Z-order of element */
  zOrder?: Maybe<Scalars['Int']>;
  /** Updated parent element ID */
  parentElementId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignNetworkingWidgetMasterElementUpdate */
export interface BoothDesignNetworkingWidgetMasterElementUpdatePayload {
  __typename?: 'BoothDesignNetworkingWidgetMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDesignNetworkingWidgetMasterElement` object updated */
  element: BoothDesignNetworkingWidgetMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothDesignUpdate */
export interface BoothDesignUpdateInput {
  /** ID of the booth */
  eventId: Scalars['ID'];
  /** ID of the `BoothDesign` object to update */
  boothDesignId: Scalars['ID'];
  /** the updated name of the booth design */
  name: LocalizableStringInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDesignUpdate */
export interface BoothDesignUpdatePayload {
  __typename?: 'BoothDesignUpdatePayload';
  /** The `BoothDesign` object updated */
  boothDesign: BoothDesign;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDividerContentElement extends Node, BoothContentElement {
  __typename?: 'BoothDividerContentElement';
  booth: Booth;
  childElements: BoothContentElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  rawElement: BoothContentElement;
}


export interface BoothDividerContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothDividerContentElementCreate */
export interface BoothDividerContentElementCreateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDividerContentElementCreate */
export interface BoothDividerContentElementCreatePayload {
  __typename?: 'BoothDividerContentElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDividerContentElement` object created */
  element: BoothDividerContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothDividerContentElementUpdate */
export interface BoothDividerContentElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDividerContentElementUpdate */
export interface BoothDividerContentElementUpdatePayload {
  __typename?: 'BoothDividerContentElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDividerContentElement` object updated */
  element: BoothDividerContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothDividerMasterElement extends Node, BoothMasterElement {
  __typename?: 'BoothDividerMasterElement';
  childElements: BoothMasterElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  parentElement?: Maybe<BoothMasterElement>;
}


export interface BoothDividerMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothDividerMasterElementCreate */
export interface BoothDividerMasterElementCreateInput {
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDividerMasterElementCreate */
export interface BoothDividerMasterElementCreatePayload {
  __typename?: 'BoothDividerMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDividerMasterElement` object created */
  element: BoothDividerMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothDividerMasterElementUpdate */
export interface BoothDividerMasterElementUpdateInput {
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothDividerMasterElementUpdate */
export interface BoothDividerMasterElementUpdatePayload {
  __typename?: 'BoothDividerMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothDividerMasterElement` object updated */
  element: BoothDividerMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothEdge {
  __typename?: 'BoothEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Booth>;
}

export interface BoothFileContentElement extends Node, BoothContentElement {
  __typename?: 'BoothFileContentElement';
  booth: Booth;
  childElements: BoothContentElementConnection;
  event: Event;
  file: LocalizableFile;
  /** ID of the object. */
  id: Scalars['ID'];
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  rawElement: BoothContentElement;
}


export interface BoothFileContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothFileContentElementCreate */
export interface BoothFileContentElementCreateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** File value */
  file?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothFileContentElementCreate */
export interface BoothFileContentElementCreatePayload {
  __typename?: 'BoothFileContentElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothFileContentElement` object created */
  element: BoothFileContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothFileContentElementUpdate */
export interface BoothFileContentElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** File value */
  file?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothFileContentElementUpdate */
export interface BoothFileContentElementUpdatePayload {
  __typename?: 'BoothFileContentElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothFileContentElement` object updated */
  element: BoothFileContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothFileMasterElement extends Node, BoothMasterElement {
  __typename?: 'BoothFileMasterElement';
  childElements: BoothMasterElementConnection;
  event: Event;
  file: LocalizableFile;
  /** ID of the object. */
  id: Scalars['ID'];
  masterControlledFile: Scalars['Boolean'];
  parentElement?: Maybe<BoothMasterElement>;
}


export interface BoothFileMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothFileMasterElementCreate */
export interface BoothFileMasterElementCreateInput {
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** ID of the event */
  eventId: Scalars['ID'];
  /** To indicate whether the file content will be mirrored by its content elements */
  masterControlledFile?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** File value */
  file?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothFileMasterElementCreate */
export interface BoothFileMasterElementCreatePayload {
  __typename?: 'BoothFileMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothFileMasterElement` object created */
  element: BoothFileMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothFileMasterElementUpdate */
export interface BoothFileMasterElementUpdateInput {
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** To indicate whether the file content will be mirrored by its content elements */
  masterControlledFile?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** File value */
  file?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothFileMasterElementUpdate */
export interface BoothFileMasterElementUpdatePayload {
  __typename?: 'BoothFileMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothFileMasterElement` object updated */
  element: BoothFileMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothImageContentElement extends Node, BoothContentElement {
  __typename?: 'BoothImageContentElement';
  booth: Booth;
  childElements: BoothContentElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  image: LocalizableImage;
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  rawElement: BoothContentElement;
}


export interface BoothImageContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothImageContentElementCreate */
export interface BoothImageContentElementCreateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Image file value */
  image?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothImageContentElementCreate */
export interface BoothImageContentElementCreatePayload {
  __typename?: 'BoothImageContentElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothImageContentElement` object created */
  element: BoothImageContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothImageContentElementUpdate */
export interface BoothImageContentElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Image file value */
  image?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothImageContentElementUpdate */
export interface BoothImageContentElementUpdatePayload {
  __typename?: 'BoothImageContentElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothImageContentElement` object updated */
  element: BoothImageContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothImageMasterElement extends Node, BoothMasterElement {
  __typename?: 'BoothImageMasterElement';
  childElements: BoothMasterElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  image: LocalizableImage;
  masterControlledImage: Scalars['Boolean'];
  parentElement?: Maybe<BoothMasterElement>;
}


export interface BoothImageMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothImageMasterElementCreate */
export interface BoothImageMasterElementCreateInput {
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** ID of the event */
  eventId: Scalars['ID'];
  /** To indicate whether the image content will be mirrored by its content elements */
  masterControlledImage?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Image file value */
  image?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothImageMasterElementCreate */
export interface BoothImageMasterElementCreatePayload {
  __typename?: 'BoothImageMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothImageMasterElement` object created */
  element: BoothImageMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothImageMasterElementUpdate */
export interface BoothImageMasterElementUpdateInput {
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** To indicate whether the image content will be mirrored by its content elements */
  masterControlledImage?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Image file value */
  image?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothImageMasterElementUpdate */
export interface BoothImageMasterElementUpdatePayload {
  __typename?: 'BoothImageMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothImageMasterElement` object updated */
  element: BoothImageMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothMasterElement {
  childElements: BoothMasterElementConnection;
  event: Event;
  id: Scalars['ID'];
  parentElement?: Maybe<BoothMasterElement>;
}


export interface BoothMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** The connection type for BoothMasterElement. */
export interface BoothMasterElementConnection {
  __typename?: 'BoothMasterElementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothMasterElementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothMasterElement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** Autogenerated input type of BoothMasterElementDelete */
export interface BoothMasterElementDeleteInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothMasterElementDelete */
export interface BoothMasterElementDeletePayload {
  __typename?: 'BoothMasterElementDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothMasterElement` object deleted */
  element: BoothMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothMasterElementEdge {
  __typename?: 'BoothMasterElementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothMasterElement>;
}

export interface BoothQuestionContentElement extends Node, BoothContentElement {
  __typename?: 'BoothQuestionContentElement';
  answer?: Maybe<BoothContentElement>;
  booth: Booth;
  childElements: BoothContentElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  question: LocalizableString;
  rawElement: BoothContentElement;
}


export interface BoothQuestionContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothQuestionContentElementCreate */
export interface BoothQuestionContentElementCreateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Question value */
  question?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothQuestionContentElementCreate */
export interface BoothQuestionContentElementCreatePayload {
  __typename?: 'BoothQuestionContentElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothQuestionContentElement` object created */
  element: BoothQuestionContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothQuestionContentElementUpdate */
export interface BoothQuestionContentElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Question value */
  question?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothQuestionContentElementUpdate */
export interface BoothQuestionContentElementUpdatePayload {
  __typename?: 'BoothQuestionContentElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothQuestionContentElement` object updated */
  element: BoothQuestionContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothQuestionMasterElement extends Node, BoothMasterElement {
  __typename?: 'BoothQuestionMasterElement';
  answer?: Maybe<BoothMasterElement>;
  childElements: BoothMasterElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterControlledAnswer: Scalars['Boolean'];
  masterControlledQuestion: Scalars['Boolean'];
  parentElement?: Maybe<BoothMasterElement>;
  question: LocalizableString;
}


export interface BoothQuestionMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothQuestionMasterElementCreate */
export interface BoothQuestionMasterElementCreateInput {
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** ID of the event */
  eventId: Scalars['ID'];
  /** To indicate whether the question content will be mirrored by its content elements */
  masterControlledQuestion?: Maybe<Scalars['Boolean']>;
  /** To indicate whether the answer content will be mirrored by its content elements */
  masterControlledAnswer?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Question value */
  question?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothQuestionMasterElementCreate */
export interface BoothQuestionMasterElementCreatePayload {
  __typename?: 'BoothQuestionMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothQuestionMasterElement` object created */
  element: BoothQuestionMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothQuestionMasterElementUpdate */
export interface BoothQuestionMasterElementUpdateInput {
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** To indicate whether the question content will be mirrored by its content elements */
  masterControlledQuestion?: Maybe<Scalars['Boolean']>;
  /** To indicate whether the answer content will be mirrored by its content elements */
  masterControlledAnswer?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Question value */
  question?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothQuestionMasterElementUpdate */
export interface BoothQuestionMasterElementUpdatePayload {
  __typename?: 'BoothQuestionMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothQuestionMasterElement` object updated */
  element: BoothQuestionMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothReorder */
export interface BoothReorderInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the booth */
  boothId: Scalars['ID'];
  rowOrderPosition: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothReorder */
export interface BoothReorderPayload {
  __typename?: 'BoothReorderPayload';
  /** The `Booth` object after reordered */
  booth: Booth;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothRepresentativeCreate */
export interface BoothRepresentativeCreateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** First name of booth representative */
  firstName: Scalars['String'];
  /** Last name of booth representative */
  lastName?: Maybe<Scalars['String']>;
  /** Email of booth representative */
  email: Scalars['Email'];
  /** Company of the booth representative */
  company?: Maybe<Scalars['String']>;
  /** Title of the booth representative */
  title?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothRepresentativeCreate */
export interface BoothRepresentativeCreatePayload {
  __typename?: 'BoothRepresentativeCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** The created representative */
  representative: Attendee;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothRepresentativeDelete */
export interface BoothRepresentativeDeleteInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the representative */
  representativeId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothRepresentativeDelete */
export interface BoothRepresentativeDeletePayload {
  __typename?: 'BoothRepresentativeDeletePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** The representative object deleted */
  representative: Attendee;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothRepresentativeUpdate */
export interface BoothRepresentativeUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the representative */
  representativeId: Scalars['ID'];
  /** The updated first name of the representative */
  firstName?: Maybe<Scalars['String']>;
  /** The updated last name of the representative */
  lastName?: Maybe<Scalars['String']>;
  /** The updated email of the representative */
  email?: Maybe<Scalars['Email']>;
  /** The updated company of the representative */
  company?: Maybe<Scalars['String']>;
  /** The updated title of the representative */
  title?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothRepresentativeUpdate */
export interface BoothRepresentativeUpdatePayload {
  __typename?: 'BoothRepresentativeUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** The updated representation */
  representative: Attendee;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothRichtextContentElement extends Node, BoothContentElement {
  __typename?: 'BoothRichtextContentElement';
  booth: Booth;
  childElements: BoothContentElementConnection;
  contentRichtextHtml: LocalizableString;
  contentRichtextRaw: LocalizableString;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  rawElement: BoothContentElement;
}


export interface BoothRichtextContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothRichtextContentElementCreate */
export interface BoothRichtextContentElementCreateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Richtext raw content value */
  contentRichtextRaw?: Maybe<LocalizableStringInput>;
  /** Richtext html content value */
  contentRichtextHtml?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothRichtextContentElementCreate */
export interface BoothRichtextContentElementCreatePayload {
  __typename?: 'BoothRichtextContentElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothRichtextContentElement` object created */
  element: BoothRichtextContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothRichtextContentElementUpdate */
export interface BoothRichtextContentElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Richtext raw content value */
  contentRichtextRaw?: Maybe<LocalizableStringInput>;
  /** Richtext html content value */
  contentRichtextHtml?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothRichtextContentElementUpdate */
export interface BoothRichtextContentElementUpdatePayload {
  __typename?: 'BoothRichtextContentElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothRichtextContentElement` object updated */
  element: BoothRichtextContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothRichtextMasterElement extends Node, BoothMasterElement {
  __typename?: 'BoothRichtextMasterElement';
  childElements: BoothMasterElementConnection;
  contentRichtextHtml: LocalizableString;
  contentRichtextRaw: LocalizableString;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterControlledContent: Scalars['Boolean'];
  parentElement?: Maybe<BoothMasterElement>;
}


export interface BoothRichtextMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothRichtextMasterElementCreate */
export interface BoothRichtextMasterElementCreateInput {
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** ID of the event */
  eventId: Scalars['ID'];
  /** To indicate whether the title content will be mirrored by its content elements */
  masterControlledContent?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Richtext raw content value */
  contentRichtextRaw?: Maybe<LocalizableStringInput>;
  /** Richtext html content value */
  contentRichtextHtml?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothRichtextMasterElementCreate */
export interface BoothRichtextMasterElementCreatePayload {
  __typename?: 'BoothRichtextMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothRichtextMasterElement` object created */
  element: BoothRichtextMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothRichtextMasterElementUpdate */
export interface BoothRichtextMasterElementUpdateInput {
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** To indicate whether the title content will be mirrored by its content elements */
  masterControlledContent?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Richtext raw content value */
  contentRichtextRaw?: Maybe<LocalizableStringInput>;
  /** Richtext html content value */
  contentRichtextHtml?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothRichtextMasterElementUpdate */
export interface BoothRichtextMasterElementUpdatePayload {
  __typename?: 'BoothRichtextMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothRichtextMasterElement` object updated */
  element: BoothRichtextMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothRootContentElement extends Node, BoothContentElement {
  __typename?: 'BoothRootContentElement';
  booth: Booth;
  childElements: BoothContentElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  rawElement: BoothContentElement;
  tabs: BoothTabContentElementConnection;
}


export interface BoothRootContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface BoothRootContentElementTabsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

export interface BoothRootMasterElement extends Node, BoothMasterElement {
  __typename?: 'BoothRootMasterElement';
  childElements: BoothMasterElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterControlledTabs: Scalars['Boolean'];
  parentElement?: Maybe<BoothMasterElement>;
  tabs: BoothTabMasterElementConnection;
}


export interface BoothRootMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface BoothRootMasterElementTabsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

export interface BoothSupercategory extends Node, BoothTaxonomy {
  __typename?: 'BoothSupercategory';
  /** ID of the object. */
  id: Scalars['ID'];
  name: LocalizableString;
  parentSupercategory?: Maybe<BoothSupercategory>;
  subcategories: BoothTaxonomyConnection;
}


export interface BoothSupercategorySubcategoriesArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothSupercategoryCreate */
export interface BoothSupercategoryCreateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of parent `BoothSupercategory` */
  parentSupercategoryId?: Maybe<Scalars['ID']>;
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** Name of `BoothSupercategory` */
  name: LocalizableStringInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothSupercategoryCreate */
export interface BoothSupercategoryCreatePayload {
  __typename?: 'BoothSupercategoryCreatePayload';
  /** The `BoothSupercategory` object created */
  boothSupercategory: BoothSupercategory;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothSupercategoryUpdate */
export interface BoothSupercategoryUpdateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of target `BoothSupercategory` */
  supercategoryId: Scalars['ID'];
  /** ID of parent `BoothSupercategory` */
  parentSupercategoryId?: Maybe<Scalars['ID']>;
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** Name of `BoothSupercategory` */
  name?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothSupercategoryUpdate */
export interface BoothSupercategoryUpdatePayload {
  __typename?: 'BoothSupercategoryUpdatePayload';
  /** The `BoothSupercategory` object updated */
  boothSupercategory: BoothSupercategory;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothTabContentElement extends Node, BoothContentElement {
  __typename?: 'BoothTabContentElement';
  body: BoothContentElementConnection;
  booth: Booth;
  childElements: BoothContentElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  rawElement: BoothContentElement;
  title: LocalizableString;
}


export interface BoothTabContentElementBodyArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface BoothTabContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** The connection type for BoothTabContentElement. */
export interface BoothTabContentElementConnection {
  __typename?: 'BoothTabContentElementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothTabContentElementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothTabContentElement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** Autogenerated input type of BoothTabContentElementCreate */
export interface BoothTabContentElementCreateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Title content value */
  title?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothTabContentElementCreate */
export interface BoothTabContentElementCreatePayload {
  __typename?: 'BoothTabContentElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothTabContentElement` object created */
  element: BoothTabContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothTabContentElementEdge {
  __typename?: 'BoothTabContentElementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothTabContentElement>;
}

/** Autogenerated input type of BoothTabContentElementUpdate */
export interface BoothTabContentElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Title content value */
  title?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothTabContentElementUpdate */
export interface BoothTabContentElementUpdatePayload {
  __typename?: 'BoothTabContentElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothTabContentElement` object updated */
  element: BoothTabContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothTabMasterElement extends Node, BoothMasterElement {
  __typename?: 'BoothTabMasterElement';
  body: BoothMasterElementConnection;
  childElements: BoothMasterElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterControlledBody: Scalars['Boolean'];
  masterControlledTitle: Scalars['Boolean'];
  parentElement?: Maybe<BoothMasterElement>;
  title: LocalizableString;
}


export interface BoothTabMasterElementBodyArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface BoothTabMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** The connection type for BoothTabMasterElement. */
export interface BoothTabMasterElementConnection {
  __typename?: 'BoothTabMasterElementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothTabMasterElementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothTabMasterElement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** Autogenerated input type of BoothTabMasterElementCreate */
export interface BoothTabMasterElementCreateInput {
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** ID of the event */
  eventId: Scalars['ID'];
  /** To indicate whether the title content will be mirrored by its content elements */
  masterControlledTitle?: Maybe<Scalars['Boolean']>;
  /** To indicate whether the body content will be mirrored by its content elements */
  masterControlledBody?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Title content value */
  title?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothTabMasterElementCreate */
export interface BoothTabMasterElementCreatePayload {
  __typename?: 'BoothTabMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothTabMasterElement` object created */
  element: BoothTabMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothTabMasterElementEdge {
  __typename?: 'BoothTabMasterElementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothTabMasterElement>;
}

/** Autogenerated input type of BoothTabMasterElementUpdate */
export interface BoothTabMasterElementUpdateInput {
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** To indicate whether the title content will be mirrored by its content elements */
  masterControlledTitle?: Maybe<Scalars['Boolean']>;
  /** To indicate whether the body content will be mirrored by its content elements */
  masterControlledBody?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Title content value */
  title?: Maybe<LocalizableStringInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothTabMasterElementUpdate */
export interface BoothTabMasterElementUpdatePayload {
  __typename?: 'BoothTabMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothTabMasterElement` object updated */
  element: BoothTabMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothTaxonomy {
  id: Scalars['ID'];
  name: LocalizableString;
  parentSupercategory?: Maybe<BoothSupercategory>;
}

/** The connection type for BoothTaxonomy. */
export interface BoothTaxonomyConnection {
  __typename?: 'BoothTaxonomyConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothTaxonomyEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothTaxonomy>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** Autogenerated input type of BoothTaxonomyDelete */
export interface BoothTaxonomyDeleteInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of `BoothTaxonomy` */
  taxonomyId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothTaxonomyDelete */
export interface BoothTaxonomyDeletePayload {
  __typename?: 'BoothTaxonomyDeletePayload';
  /** The `BoothTaxonomy` object deleted */
  boothTaxonomy: BoothTaxonomy;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothTaxonomyEdge {
  __typename?: 'BoothTaxonomyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothTaxonomy>;
}

export interface BoothTier extends Node {
  __typename?: 'BoothTier';
  booths: BoothConnection;
  id: Scalars['ID'];
  name: LocalizableString;
  nameVisible: Scalars['Boolean'];
  scale: DisplayMeasurement;
}


export interface BoothTierBoothsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** The connection type for BoothTier. */
export interface BoothTierConnection {
  __typename?: 'BoothTierConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothTierEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothTier>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** Autogenerated input type of BoothTierCreate */
export interface BoothTierCreateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** name of the booth tier */
  name: LocalizableStringInput;
  /** (Deprecated) assign orderingPosition instead */
  rowOrderPosition?: Maybe<Scalars['Int']>;
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** The sacle value of booth thumbnail size */
  scale?: Maybe<DisplayMeasurementInput>;
  /** Whether the booth tier name is displayed in hall page */
  nameVisible?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothTierCreate */
export interface BoothTierCreatePayload {
  __typename?: 'BoothTierCreatePayload';
  /** The `BoothTier` object created */
  boothTier: BoothTier;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothTierDelete */
export interface BoothTierDeleteInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the `BoothTier` object to update */
  boothTierId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothTierDelete */
export interface BoothTierDeletePayload {
  __typename?: 'BoothTierDeletePayload';
  /** The `BoothTier` object deleted */
  boothTier: BoothTier;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BoothTierEdge {
  __typename?: 'BoothTierEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothTier>;
}

/** Autogenerated input type of BoothTierUpdate */
export interface BoothTierUpdateInput {
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the `BoothTier` object to update */
  boothTierId: Scalars['ID'];
  /** The updated name of the booth tier */
  name?: Maybe<LocalizableStringInput>;
  /** (Deprecated) assign orderingPosition instead */
  rowOrderPosition?: Maybe<Scalars['Int']>;
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** The sacle value of booth thumbnail size */
  scale?: Maybe<DisplayMeasurementInput>;
  /** Whether the booth tier name is displayed in hall page */
  nameVisible?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothTierUpdate */
export interface BoothTierUpdatePayload {
  __typename?: 'BoothTierUpdatePayload';
  /** The `BoothTier` object updated */
  boothTier: BoothTier;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothUpdate */
export interface BoothUpdateInput {
  /** ID of the booth */
  id: Scalars['ID'];
  /** Updated hall ID */
  hallId?: Maybe<Scalars['String']>;
  /** Updated booth number */
  boothNumber?: Maybe<Scalars['String']>;
  /** Updated booth tier */
  tierId?: Maybe<Scalars['ID']>;
  /** Updated booth design */
  designId?: Maybe<Scalars['ID']>;
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** Updated website URL */
  website?: Maybe<Scalars['String']>;
  /** Updated name value */
  name?: Maybe<LocalizableStringInput>;
  /** Updated description value */
  description?: Maybe<LocalizableStringInput>;
  /** Updated logo file value */
  logo?: Maybe<LocalizableFileInput>;
  /** Updated exhibition name value */
  exhibitionName?: Maybe<LocalizableStringInput>;
  /** Updated design code */
  designCodename?: Maybe<Scalars['String']>;
  /** Updated 'meetupEnabled' value */
  meetupEnabled?: Maybe<Scalars['Boolean']>;
  /** Updated ids of categories */
  categories?: Maybe<Array<Scalars['ID']>>;
  /** Update publish status of the booth */
  published?: Maybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothUpdate */
export interface BoothUpdatePayload {
  __typename?: 'BoothUpdatePayload';
  /** The `Booth` object after update */
  booth?: Maybe<Booth>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothV2DesignElement {
  booth: Booth;
  height?: Maybe<DisplayMeasurement>;
  id: Scalars['ID'];
  masterElement?: Maybe<BoothDesignMasterElement>;
  positionX?: Maybe<DisplayMeasurement>;
  positionY?: Maybe<DisplayMeasurement>;
  rawElement?: Maybe<BoothV2DesignElement>;
  width?: Maybe<DisplayMeasurement>;
}

/** The connection type for BoothV2DesignElement. */
export interface BoothV2DesignElementConnection {
  __typename?: 'BoothV2DesignElementConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BoothV2DesignElementEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BoothV2DesignElement>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface BoothV2DesignElementEdge {
  __typename?: 'BoothV2DesignElementEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BoothV2DesignElement>;
}

export interface BoothVideoContentElement extends Node, BoothContentElement {
  __typename?: 'BoothVideoContentElement';
  booth: Booth;
  childElements: BoothContentElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterElement?: Maybe<BoothMasterElement>;
  parentElement?: Maybe<BoothContentElement>;
  rawElement: BoothContentElement;
  videoFile: LocalizableVideo;
}


export interface BoothVideoContentElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothVideoContentElementCreate */
export interface BoothVideoContentElementCreateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Video file value */
  videoFile?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothVideoContentElementCreate */
export interface BoothVideoContentElementCreatePayload {
  __typename?: 'BoothVideoContentElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothVideoContentElement` object created */
  element: BoothVideoContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothVideoContentElementUpdate */
export interface BoothVideoContentElementUpdateInput {
  /** ID of the booth */
  boothId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Video file value */
  videoFile?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothVideoContentElementUpdate */
export interface BoothVideoContentElementUpdatePayload {
  __typename?: 'BoothVideoContentElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothVideoContentElement` object updated */
  element: BoothVideoContentElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface BoothVideoMasterElement extends Node, BoothMasterElement {
  __typename?: 'BoothVideoMasterElement';
  childElements: BoothMasterElementConnection;
  event: Event;
  /** ID of the object. */
  id: Scalars['ID'];
  masterControlledVideoFile: Scalars['Boolean'];
  parentElement?: Maybe<BoothMasterElement>;
  videoFile: LocalizableVideo;
}


export interface BoothVideoMasterElementChildElementsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of BoothVideoMasterElementCreate */
export interface BoothVideoMasterElementCreateInput {
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** ID of the event */
  eventId: Scalars['ID'];
  /** To indicate whether the video content will be mirrored by its content elements */
  masterControlledVideoFile?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Video file value */
  videoFile?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothVideoMasterElementCreate */
export interface BoothVideoMasterElementCreatePayload {
  __typename?: 'BoothVideoMasterElementCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothVideoMasterElement` object created */
  element: BoothVideoMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of BoothVideoMasterElementUpdate */
export interface BoothVideoMasterElementUpdateInput {
  /** Assign new ordering position */
  orderingPosition?: Maybe<Scalars['Int']>;
  /** ID of the event */
  eventId: Scalars['ID'];
  /** ID of the element */
  elementId: Scalars['ID'];
  /** To indicate whether the video content will be mirrored by its content elements */
  masterControlledVideoFile?: Maybe<Scalars['Boolean']>;
  /** ID of the parent element */
  parentElementId?: Maybe<Scalars['ID']>;
  /** Video file value */
  videoFile?: Maybe<LocalizableFileInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of BoothVideoMasterElementUpdate */
export interface BoothVideoMasterElementUpdatePayload {
  __typename?: 'BoothVideoMasterElementUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `BoothVideoMasterElement` object updated */
  element: BoothVideoMasterElement;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}


/** Autogenerated input type of ConfirmationEmailResend */
export interface ConfirmationEmailResendInput {
  eventToken?: Maybe<Scalars['String']>;
  eventDomain?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of ConfirmationEmailResend */
export interface ConfirmationEmailResendPayload {
  __typename?: 'ConfirmationEmailResendPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Represents direct upload credentials */
export interface DirectUpload {
  __typename?: 'DirectUpload';
  /** HTTP request headers (JSON-encoded) */
  headers: Scalars['String'];
  /** Created blob record signed ID */
  signedBlobId: Scalars['SignedBlobID'];
  /** Upload URL */
  url: Scalars['String'];
}

export interface DisplayMeasurement {
  __typename?: 'DisplayMeasurement';
  dimension?: Maybe<Scalars['DisplayMeasurementDimension']>;
  unit: DisplayMeasurementUnit;
}


/** Input value of DisplayMeasurement */
export interface DisplayMeasurementInput {
  unit: DisplayMeasurementUnit;
  dimension?: Maybe<Scalars['DisplayMeasurementDimension']>;
}

export enum DisplayMeasurementUnit {
  /** Unit in pixel */
  Pixel = 'PIXEL',
  /** Unit in percentage */
  Percent = 'PERCENT'
}



export interface Event extends Node, AbilityQueryable {
  __typename?: 'Event';
  attendeeByUniqueToken?: Maybe<Attendee>;
  attendeesByCustomFieldVexpoRole?: Maybe<AttendeeConnection>;
  attendeesByKeyword?: Maybe<AttendeeConnection>;
  attendeesByUuids?: Maybe<AttendeeConnection>;
  booth?: Maybe<Booth>;
  boothDesigns: BoothDesignConnection;
  boothTaxonomies: BoothTaxonomyConnection;
  boothTiers: BoothTierConnection;
  booths: BoothConnection;
  boothsByCategories: BoothConnection;
  boothsByFilter?: Maybe<BoothConnection>;
  cmsUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  currentAbilityCanPerform?: Maybe<Scalars['Boolean']>;
  defaultLocale: Scalars['Locale'];
  domain?: Maybe<Scalars['String']>;
  features: Array<EventFeature>;
  halls: HallConnection;
  id: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  meetupEventId?: Maybe<Scalars['String']>;
  name?: Maybe<LocalizableString>;
  reportToken?: Maybe<Scalars['String']>;
  rootMasterElement: BoothRootMasterElement;
  searchByKeyword: SearchResultConnection;
  vexpoEventUrl?: Maybe<Scalars['String']>;
}


export interface EventAttendeeByUniqueTokenArgs {
  uniqueToken: Scalars['String'];
}


export interface EventAttendeesByCustomFieldVexpoRoleArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  vexpoRole?: Maybe<Scalars['String']>;
}


export interface EventAttendeesByKeywordArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
}


export interface EventAttendeesByUuidsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  uuids: Array<Scalars['String']>;
}


export interface EventBoothArgs {
  id: Scalars['ID'];
}


export interface EventBoothDesignsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface EventBoothTaxonomiesArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface EventBoothTiersArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface EventBoothsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface EventBoothsByCategoriesArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
}


export interface EventBoothsByFilterArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  published?: Maybe<Scalars['Boolean']>;
}


export interface EventCurrentAbilityCanPerformArgs {
  action: Scalars['String'];
}


export interface EventHallsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface EventSearchByKeywordArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
}

/** Available features for `Event` */
export enum EventFeature {
  /** Product page */
  ProductPage = 'PRODUCT_PAGE',
  /** Exhibitor report */
  ExhibitorReport = 'EXHIBITOR_REPORT',
  /** V2 booth design elements */
  V2DesignElement = 'V2_DESIGN_ELEMENT',
  /** Attendee filtering */
  AttendeeFiltering = 'ATTENDEE_FILTERING',
  /** Reverse the order of layers on mobile */
  ReversedLayerOrder = 'REVERSED_LAYER_ORDER',
  /** SKIP_EMS_ATTENDEE_LOOKUP */
  SkipEmsAttendeeLookup = 'SKIP_EMS_ATTENDEE_LOOKUP'
}

/** Autogenerated input type of EventUpdate */
export interface EventUpdateInput {
  /** ID of the event */
  id: Scalars['ID'];
  /** Updated name of the event */
  name?: Maybe<LocalizableStringInput>;
  /** Updated domain of the event */
  domain?: Maybe<Scalars['DomainName']>;
  /** Updated features of the event */
  features?: Maybe<Array<EventFeature>>;
  /** Updated locales of the event */
  locales?: Maybe<Array<Scalars['Locale']>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of EventUpdate */
export interface EventUpdatePayload {
  __typename?: 'EventUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** The `Event` object after update */
  event: Event;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface File extends Fileable {
  __typename?: 'File';
  contentType: Scalars['String'];
  fileUrl: Scalars['String'];
  filename: Scalars['String'];
}

export interface Fileable {
  contentType: Scalars['String'];
  fileUrl: Scalars['String'];
  filename: Scalars['String'];
}

/** Autogenerated input type of GroupChatJoinAsync */
export interface GroupChatJoinAsyncInput {
  rongcloudUserId: Scalars['ID'];
  groupId: Scalars['String'];
  domain: Scalars['String'];
  autoJoinMessageRequired?: Maybe<Scalars['Boolean']>;
  autoJoinMessage?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of GroupChatJoinAsync */
export interface GroupChatJoinAsyncPayload {
  __typename?: 'GroupChatJoinAsyncPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  job?: Maybe<GroupChatJoinJob>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of GroupChatJoin */
export interface GroupChatJoinInput {
  uniqueToken: Scalars['String'];
  groupId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

export interface GroupChatJoinJob extends Node, Job {
  __typename?: 'GroupChatJoinJob';
  /** ID of the object. */
  id: Scalars['ID'];
  result: GroupChatJoinResult;
  status: JobStatus;
}

/** Autogenerated return type of GroupChatJoin */
export interface GroupChatJoinPayload {
  __typename?: 'GroupChatJoinPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  rongcloudGroup?: Maybe<RongcloudGroup>;
  success: Scalars['Boolean'];
}

export interface GroupChatJoinResult extends JobResult {
  __typename?: 'GroupChatJoinResult';
  job: Job;
  success: Scalars['Boolean'];
}

/** Autogenerated input type of GroupVideoCallRoomJoinResponse */
export interface GroupVideoCallRoomJoinResponseInput {
  roomToken: Scalars['String'];
  inviterId: Scalars['String'];
  participantId: Scalars['String'];
  isApproved: Scalars['Boolean'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of GroupVideoCallRoomJoinResponse */
export interface GroupVideoCallRoomJoinResponsePayload {
  __typename?: 'GroupVideoCallRoomJoinResponsePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface Hall extends Node {
  __typename?: 'Hall';
  booths: BoothConnection;
  boothsByCategories: BoothConnection;
  boothsByFilter?: Maybe<BoothConnection>;
  id: Scalars['ID'];
  name: LocalizableString;
}


export interface HallBoothsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}


export interface HallBoothsByCategoriesArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
}


export interface HallBoothsByFilterArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  published?: Maybe<Scalars['Boolean']>;
}

/** The connection type for Hall. */
export interface HallConnection {
  __typename?: 'HallConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<HallEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Hall>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface HallEdge {
  __typename?: 'HallEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Hall>;
}

export enum ImService {
  /** RongCloud IM Service */
  Rongcloud = 'RONGCLOUD',
  /** Twilio Conversation IM Service */
  Twilio = 'TWILIO'
}


export interface Image extends Fileable {
  __typename?: 'Image';
  contentType: Scalars['String'];
  fileUrl: Scalars['String'];
  filename: Scalars['String'];
  originalImageUrl: Scalars['String'];
}

/** File information required to prepare a direct upload */
export interface InitiateDirectUploadInput {
  /** Original file name */
  filename: Scalars['String'];
  /** File size (bytes) */
  byteSize: Scalars['Int'];
  /** MD5 file checksum as base64 */
  checksum: Scalars['String'];
  /** File content type */
  contentType: Scalars['String'];
}

/** Autogenerated return type of InitiateDirectUpload */
export interface InitiateDirectUploadPayload {
  __typename?: 'InitiateDirectUploadPayload';
  directUpload: DirectUpload;
}


export interface Job {
  id: Scalars['ID'];
  result: JobResult;
  status: JobStatus;
}

export interface JobResult {
  job: Job;
}

/** Possible value types for `status` of an `Job` */
export enum JobStatus {
  /** Pending */
  Pending = 'PENDING',
  /** Working */
  Working = 'WORKING',
  /** Completed */
  Completed = 'COMPLETED',
  /** Failed */
  Failed = 'FAILED'
}


export interface LocalizableFile {
  __typename?: 'LocalizableFile';
  values?: Maybe<Array<LocalizableFileValue>>;
}


export interface LocalizableFileValuesArgs {
  locales: Array<Scalars['Locale']>;
}

/** Container for multiple file translations values */
export interface LocalizableFileInput {
  values?: Maybe<Array<LocalizableFileInputValue>>;
}

/** Container for single file translation value */
export interface LocalizableFileInputValue {
  value?: Maybe<Scalars['SignedBlobID']>;
  locale: Scalars['Locale'];
}

export interface LocalizableFileValue {
  __typename?: 'LocalizableFileValue';
  locale: Scalars['Locale'];
  value?: Maybe<File>;
}

/** Localizable file-like objects */
export type LocalizableFileable = LocalizableFile | LocalizableImage | LocalizableVideo;

export interface LocalizableImage {
  __typename?: 'LocalizableImage';
  values?: Maybe<Array<LocalizableImageValue>>;
}


export interface LocalizableImageValuesArgs {
  locales: Array<Scalars['Locale']>;
}

export interface LocalizableImageValue {
  __typename?: 'LocalizableImageValue';
  locale: Scalars['Locale'];
  value?: Maybe<Image>;
}

export interface LocalizableString {
  __typename?: 'LocalizableString';
  values?: Maybe<Array<LocalizableStringValue>>;
}


export interface LocalizableStringValuesArgs {
  locales: Array<Scalars['Locale']>;
}

/** Container for multiple string translations values */
export interface LocalizableStringInput {
  values?: Maybe<Array<LocalizableStringInputValue>>;
}

/** Container for single string translation value */
export interface LocalizableStringInputValue {
  value?: Maybe<Scalars['String']>;
  locale: Scalars['Locale'];
}

export interface LocalizableStringValue {
  __typename?: 'LocalizableStringValue';
  locale: Scalars['Locale'];
  value?: Maybe<Scalars['String']>;
}

export interface LocalizableVideo {
  __typename?: 'LocalizableVideo';
  values?: Maybe<Array<LocalizableVideoValue>>;
}


export interface LocalizableVideoValuesArgs {
  locales: Array<Scalars['Locale']>;
}

export interface LocalizableVideoValue {
  __typename?: 'LocalizableVideoValue';
  locale: Scalars['Locale'];
  value?: Maybe<Video>;
}

export interface Mutation {
  __typename?: 'Mutation';
  addContact?: Maybe<AddContactPayload>;
  adminEventCreate?: Maybe<AdminEventCreatePayload>;
  attendeeUpdate?: Maybe<AttendeeUpdatePayload>;
  boothCategoryCreate?: Maybe<BoothCategoryCreatePayload>;
  boothCategoryUpdate?: Maybe<BoothCategoryUpdatePayload>;
  boothContentElementDelete?: Maybe<BoothContentElementDeletePayload>;
  boothCreate?: Maybe<BoothCreatePayload>;
  boothDesignCanvasMasterElementUpdate?: Maybe<BoothDesignCanvasMasterElementUpdatePayload>;
  boothDesignColorFillElementUpdate?: Maybe<BoothDesignColorFillElementUpdatePayload>;
  boothDesignColorFillMasterElementCreate?: Maybe<BoothDesignColorFillMasterElementCreatePayload>;
  boothDesignColorFillMasterElementUpdate?: Maybe<BoothDesignColorFillMasterElementUpdatePayload>;
  boothDesignContentAreaElementUpdate?: Maybe<BoothDesignContentAreaElementUpdatePayload>;
  boothDesignContentAreaMasterElementCreate?: Maybe<BoothDesignContentAreaMasterElementCreatePayload>;
  boothDesignContentAreaMasterElementUpdate?: Maybe<BoothDesignContentAreaMasterElementUpdatePayload>;
  boothDesignCreate?: Maybe<BoothDesignCreatePayload>;
  boothDesignDelete?: Maybe<BoothDesignDeletePayload>;
  boothDesignElementUpdate?: Maybe<BoothDesignElementUpdatePayload>;
  boothDesignImageButtonElementUpdate?: Maybe<BoothDesignImageButtonElementUpdatePayload>;
  boothDesignImageButtonMasterElementCreate?: Maybe<BoothDesignImageButtonMasterElementCreatePayload>;
  boothDesignImageButtonMasterElementUpdate?: Maybe<BoothDesignImageButtonMasterElementUpdatePayload>;
  boothDesignImageMasterElementCreate?: Maybe<BoothDesignImageMasterElementCreatePayload>;
  boothDesignImageMasterElementUpdate?: Maybe<BoothDesignImageMasterElementUpdatePayload>;
  boothDesignMasterElementDelete?: Maybe<BoothDesignMasterElementDeletePayload>;
  boothDesignNetworkingWidgetMasterElementCreate?: Maybe<BoothDesignNetworkingWidgetMasterElementCreatePayload>;
  boothDesignNetworkingWidgetMasterElementUpdate?: Maybe<BoothDesignNetworkingWidgetMasterElementUpdatePayload>;
  boothDesignUpdate?: Maybe<BoothDesignUpdatePayload>;
  boothDividerContentElementCreate?: Maybe<BoothDividerContentElementCreatePayload>;
  boothDividerContentElementUpdate?: Maybe<BoothDividerContentElementUpdatePayload>;
  boothDividerMasterElementCreate?: Maybe<BoothDividerMasterElementCreatePayload>;
  boothDividerMasterElementUpdate?: Maybe<BoothDividerMasterElementUpdatePayload>;
  boothFileContentElementCreate?: Maybe<BoothFileContentElementCreatePayload>;
  boothFileContentElementUpdate?: Maybe<BoothFileContentElementUpdatePayload>;
  boothFileMasterElementCreate?: Maybe<BoothFileMasterElementCreatePayload>;
  boothFileMasterElementUpdate?: Maybe<BoothFileMasterElementUpdatePayload>;
  boothImageContentElementCreate?: Maybe<BoothImageContentElementCreatePayload>;
  boothImageContentElementUpdate?: Maybe<BoothImageContentElementUpdatePayload>;
  boothImageMasterElementCreate?: Maybe<BoothImageMasterElementCreatePayload>;
  boothImageMasterElementUpdate?: Maybe<BoothImageMasterElementUpdatePayload>;
  boothMasterElementDelete?: Maybe<BoothMasterElementDeletePayload>;
  boothQuestionContentElementCreate?: Maybe<BoothQuestionContentElementCreatePayload>;
  boothQuestionContentElementUpdate?: Maybe<BoothQuestionContentElementUpdatePayload>;
  boothQuestionMasterElementCreate?: Maybe<BoothQuestionMasterElementCreatePayload>;
  boothQuestionMasterElementUpdate?: Maybe<BoothQuestionMasterElementUpdatePayload>;
  /** @deprecated call boothUpdate with orderingPosition instead */
  boothReorder?: Maybe<BoothReorderPayload>;
  boothRepresentativeCreate?: Maybe<BoothRepresentativeCreatePayload>;
  boothRepresentativeDelete?: Maybe<BoothRepresentativeDeletePayload>;
  boothRepresentativeUpdate?: Maybe<BoothRepresentativeUpdatePayload>;
  boothRichtextContentElementCreate?: Maybe<BoothRichtextContentElementCreatePayload>;
  boothRichtextContentElementUpdate?: Maybe<BoothRichtextContentElementUpdatePayload>;
  boothRichtextMasterElementCreate?: Maybe<BoothRichtextMasterElementCreatePayload>;
  boothRichtextMasterElementUpdate?: Maybe<BoothRichtextMasterElementUpdatePayload>;
  boothSupercategoryCreate?: Maybe<BoothSupercategoryCreatePayload>;
  boothSupercategoryUpdate?: Maybe<BoothSupercategoryUpdatePayload>;
  boothTabContentElementCreate?: Maybe<BoothTabContentElementCreatePayload>;
  boothTabContentElementUpdate?: Maybe<BoothTabContentElementUpdatePayload>;
  boothTabMasterElementCreate?: Maybe<BoothTabMasterElementCreatePayload>;
  boothTabMasterElementUpdate?: Maybe<BoothTabMasterElementUpdatePayload>;
  boothTaxonomyDelete?: Maybe<BoothTaxonomyDeletePayload>;
  boothTierCreate?: Maybe<BoothTierCreatePayload>;
  boothTierDelete?: Maybe<BoothTierDeletePayload>;
  boothTierUpdate?: Maybe<BoothTierUpdatePayload>;
  boothUpdate?: Maybe<BoothUpdatePayload>;
  boothVideoContentElementCreate?: Maybe<BoothVideoContentElementCreatePayload>;
  boothVideoContentElementUpdate?: Maybe<BoothVideoContentElementUpdatePayload>;
  boothVideoMasterElementCreate?: Maybe<BoothVideoMasterElementCreatePayload>;
  boothVideoMasterElementUpdate?: Maybe<BoothVideoMasterElementUpdatePayload>;
  confirmationEmailResend?: Maybe<ConfirmationEmailResendPayload>;
  eventUpdate?: Maybe<EventUpdatePayload>;
  groupChatJoin?: Maybe<GroupChatJoinPayload>;
  groupChatJoinAsync?: Maybe<GroupChatJoinAsyncPayload>;
  groupVideoCallRoomJoinResponse?: Maybe<GroupVideoCallRoomJoinResponsePayload>;
  /** Initiate direct file upload */
  initiateDirectUpload?: Maybe<InitiateDirectUploadPayload>;
  pushNotification?: Maybe<PushNotificationPayload>;
  registerEventxtraAttendeeAsync?: Maybe<RegisterEventxtraAttendeeAsyncPayload>;
  registerRongcloudServiceAsync?: Maybe<RegisterRongcloudServiceAsyncPayload>;
  removeContact?: Maybe<RemoveContactPayload>;
  sendSmsLoginCode?: Maybe<SendSmsLoginCodePayload>;
  uploadFile?: Maybe<UploadFilePayload>;
  v2GroupVideoCallRoomCreate?: Maybe<V2GroupVideoCallRoomCreatePayload>;
  verifySmsLoginCode?: Maybe<VerifySmsLoginCodePayload>;
  videoCallParticipantAccept?: Maybe<VideoCallParticipantAcceptPayload>;
  videoCallParticipantJoin?: Maybe<VideoCallParticipantJoinPayload>;
  videoCallParticipantQuit?: Maybe<VideoCallParticipantQuitPayload>;
  videoCallRoomCreate?: Maybe<VideoCallRoomCreatePayload>;
  videoCallTokenCreate?: Maybe<VideoCallTokenCreatePayload>;
}


export interface MutationAddContactArgs {
  input: AddContactInput;
}


export interface MutationAdminEventCreateArgs {
  input: AdminEventCreateInput;
}


export interface MutationAttendeeUpdateArgs {
  input: AttendeeUpdateInput;
}


export interface MutationBoothCategoryCreateArgs {
  input: BoothCategoryCreateInput;
}


export interface MutationBoothCategoryUpdateArgs {
  input: BoothCategoryUpdateInput;
}


export interface MutationBoothContentElementDeleteArgs {
  input: BoothContentElementDeleteInput;
}


export interface MutationBoothCreateArgs {
  input: BoothCreateInput;
}


export interface MutationBoothDesignCanvasMasterElementUpdateArgs {
  input: BoothDesignCanvasMasterElementUpdateInput;
}


export interface MutationBoothDesignColorFillElementUpdateArgs {
  input: BoothDesignColorFillElementUpdateInput;
}


export interface MutationBoothDesignColorFillMasterElementCreateArgs {
  input: BoothDesignColorFillMasterElementCreateInput;
}


export interface MutationBoothDesignColorFillMasterElementUpdateArgs {
  input: BoothDesignColorFillMasterElementUpdateInput;
}


export interface MutationBoothDesignContentAreaElementUpdateArgs {
  input: BoothDesignContentAreaElementUpdateInput;
}


export interface MutationBoothDesignContentAreaMasterElementCreateArgs {
  input: BoothDesignContentAreaMasterElementCreateInput;
}


export interface MutationBoothDesignContentAreaMasterElementUpdateArgs {
  input: BoothDesignContentAreaMasterElementUpdateInput;
}


export interface MutationBoothDesignCreateArgs {
  input: BoothDesignCreateInput;
}


export interface MutationBoothDesignDeleteArgs {
  input: BoothDesignDeleteInput;
}


export interface MutationBoothDesignElementUpdateArgs {
  input: BoothDesignElementUpdateInput;
}


export interface MutationBoothDesignImageButtonElementUpdateArgs {
  input: BoothDesignImageButtonElementUpdateInput;
}


export interface MutationBoothDesignImageButtonMasterElementCreateArgs {
  input: BoothDesignImageButtonMasterElementCreateInput;
}


export interface MutationBoothDesignImageButtonMasterElementUpdateArgs {
  input: BoothDesignImageButtonMasterElementUpdateInput;
}


export interface MutationBoothDesignImageMasterElementCreateArgs {
  input: BoothDesignImageMasterElementCreateInput;
}


export interface MutationBoothDesignImageMasterElementUpdateArgs {
  input: BoothDesignImageMasterElementUpdateInput;
}


export interface MutationBoothDesignMasterElementDeleteArgs {
  input: BoothDesignMasterElementDeleteInput;
}


export interface MutationBoothDesignNetworkingWidgetMasterElementCreateArgs {
  input: BoothDesignNetworkingWidgetMasterElementCreateInput;
}


export interface MutationBoothDesignNetworkingWidgetMasterElementUpdateArgs {
  input: BoothDesignNetworkingWidgetMasterElementUpdateInput;
}


export interface MutationBoothDesignUpdateArgs {
  input: BoothDesignUpdateInput;
}


export interface MutationBoothDividerContentElementCreateArgs {
  input: BoothDividerContentElementCreateInput;
}


export interface MutationBoothDividerContentElementUpdateArgs {
  input: BoothDividerContentElementUpdateInput;
}


export interface MutationBoothDividerMasterElementCreateArgs {
  input: BoothDividerMasterElementCreateInput;
}


export interface MutationBoothDividerMasterElementUpdateArgs {
  input: BoothDividerMasterElementUpdateInput;
}


export interface MutationBoothFileContentElementCreateArgs {
  input: BoothFileContentElementCreateInput;
}


export interface MutationBoothFileContentElementUpdateArgs {
  input: BoothFileContentElementUpdateInput;
}


export interface MutationBoothFileMasterElementCreateArgs {
  input: BoothFileMasterElementCreateInput;
}


export interface MutationBoothFileMasterElementUpdateArgs {
  input: BoothFileMasterElementUpdateInput;
}


export interface MutationBoothImageContentElementCreateArgs {
  input: BoothImageContentElementCreateInput;
}


export interface MutationBoothImageContentElementUpdateArgs {
  input: BoothImageContentElementUpdateInput;
}


export interface MutationBoothImageMasterElementCreateArgs {
  input: BoothImageMasterElementCreateInput;
}


export interface MutationBoothImageMasterElementUpdateArgs {
  input: BoothImageMasterElementUpdateInput;
}


export interface MutationBoothMasterElementDeleteArgs {
  input: BoothMasterElementDeleteInput;
}


export interface MutationBoothQuestionContentElementCreateArgs {
  input: BoothQuestionContentElementCreateInput;
}


export interface MutationBoothQuestionContentElementUpdateArgs {
  input: BoothQuestionContentElementUpdateInput;
}


export interface MutationBoothQuestionMasterElementCreateArgs {
  input: BoothQuestionMasterElementCreateInput;
}


export interface MutationBoothQuestionMasterElementUpdateArgs {
  input: BoothQuestionMasterElementUpdateInput;
}


export interface MutationBoothReorderArgs {
  input: BoothReorderInput;
}


export interface MutationBoothRepresentativeCreateArgs {
  input: BoothRepresentativeCreateInput;
}


export interface MutationBoothRepresentativeDeleteArgs {
  input: BoothRepresentativeDeleteInput;
}


export interface MutationBoothRepresentativeUpdateArgs {
  input: BoothRepresentativeUpdateInput;
}


export interface MutationBoothRichtextContentElementCreateArgs {
  input: BoothRichtextContentElementCreateInput;
}


export interface MutationBoothRichtextContentElementUpdateArgs {
  input: BoothRichtextContentElementUpdateInput;
}


export interface MutationBoothRichtextMasterElementCreateArgs {
  input: BoothRichtextMasterElementCreateInput;
}


export interface MutationBoothRichtextMasterElementUpdateArgs {
  input: BoothRichtextMasterElementUpdateInput;
}


export interface MutationBoothSupercategoryCreateArgs {
  input: BoothSupercategoryCreateInput;
}


export interface MutationBoothSupercategoryUpdateArgs {
  input: BoothSupercategoryUpdateInput;
}


export interface MutationBoothTabContentElementCreateArgs {
  input: BoothTabContentElementCreateInput;
}


export interface MutationBoothTabContentElementUpdateArgs {
  input: BoothTabContentElementUpdateInput;
}


export interface MutationBoothTabMasterElementCreateArgs {
  input: BoothTabMasterElementCreateInput;
}


export interface MutationBoothTabMasterElementUpdateArgs {
  input: BoothTabMasterElementUpdateInput;
}


export interface MutationBoothTaxonomyDeleteArgs {
  input: BoothTaxonomyDeleteInput;
}


export interface MutationBoothTierCreateArgs {
  input: BoothTierCreateInput;
}


export interface MutationBoothTierDeleteArgs {
  input: BoothTierDeleteInput;
}


export interface MutationBoothTierUpdateArgs {
  input: BoothTierUpdateInput;
}


export interface MutationBoothUpdateArgs {
  input: BoothUpdateInput;
}


export interface MutationBoothVideoContentElementCreateArgs {
  input: BoothVideoContentElementCreateInput;
}


export interface MutationBoothVideoContentElementUpdateArgs {
  input: BoothVideoContentElementUpdateInput;
}


export interface MutationBoothVideoMasterElementCreateArgs {
  input: BoothVideoMasterElementCreateInput;
}


export interface MutationBoothVideoMasterElementUpdateArgs {
  input: BoothVideoMasterElementUpdateInput;
}


export interface MutationConfirmationEmailResendArgs {
  input: ConfirmationEmailResendInput;
}


export interface MutationEventUpdateArgs {
  input: EventUpdateInput;
}


export interface MutationGroupChatJoinArgs {
  input: GroupChatJoinInput;
}


export interface MutationGroupChatJoinAsyncArgs {
  input: GroupChatJoinAsyncInput;
}


export interface MutationGroupVideoCallRoomJoinResponseArgs {
  input: GroupVideoCallRoomJoinResponseInput;
}


export interface MutationInitiateDirectUploadArgs {
  input: InitiateDirectUploadInput;
}


export interface MutationPushNotificationArgs {
  input: PushNotificationInput;
}


export interface MutationRegisterEventxtraAttendeeAsyncArgs {
  input: RegisterEventxtraAttendeeAsyncInput;
}


export interface MutationRegisterRongcloudServiceAsyncArgs {
  input: RegisterRongcloudServiceAsyncInput;
}


export interface MutationRemoveContactArgs {
  input: RemoveContactInput;
}


export interface MutationSendSmsLoginCodeArgs {
  input: SendSmsLoginCodeInput;
}


export interface MutationUploadFileArgs {
  input: UploadFileInput;
}


export interface MutationV2GroupVideoCallRoomCreateArgs {
  input: V2GroupVideoCallRoomCreateInput;
}


export interface MutationVerifySmsLoginCodeArgs {
  input: VerifySmsLoginCodeInput;
}


export interface MutationVideoCallParticipantAcceptArgs {
  input: VideoCallParticipantAcceptInput;
}


export interface MutationVideoCallParticipantJoinArgs {
  input: VideoCallParticipantJoinInput;
}


export interface MutationVideoCallParticipantQuitArgs {
  input: VideoCallParticipantQuitInput;
}


export interface MutationVideoCallRoomCreateArgs {
  input: VideoCallRoomCreateInput;
}


export interface MutationVideoCallTokenCreateArgs {
  input: VideoCallTokenCreateInput;
}

/** An object with an ID. */
export interface Node {
  /** ID of the object. */
  id: Scalars['ID'];
}

/** Information about pagination in a connection. */
export interface PageInfo {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of PushNotification */
export interface PushNotificationInput {
  eventToken?: Maybe<Scalars['String']>;
  eventDomain?: Maybe<Scalars['String']>;
  uniqueToken: Scalars['String'];
  message: Scalars['String'];
  sender: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of PushNotification */
export interface PushNotificationPayload {
  __typename?: 'PushNotificationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface Query {
  __typename?: 'Query';
  attendeeByUniqueToken?: Maybe<Attendee>;
  attendeesByEventToken?: Maybe<AttendeeConnection>;
  contactsByUuid?: Maybe<UserContactGroupConnection>;
  eventByDomain?: Maybe<Event>;
  eventByToken?: Maybe<Event>;
  fetchAttendeeInformation?: Maybe<Array<Attendee>>;
  localizableFileableBy?: Maybe<LocalizableFileable>;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>;
  rongcloudGroup?: Maybe<RongcloudGroup>;
  videoCallRoomByToken?: Maybe<VideoCallRoom>;
}


export interface QueryAttendeeByUniqueTokenArgs {
  eventToken: Scalars['String'];
  uniqueToken: Scalars['String'];
}


export interface QueryAttendeesByEventTokenArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  eventToken: Scalars['String'];
  keyword?: Maybe<Scalars['String']>;
}


export interface QueryContactsByUuidArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  uuid: Scalars['String'];
  keyword?: Maybe<Scalars['String']>;
}


export interface QueryEventByDomainArgs {
  domain: Scalars['String'];
}


export interface QueryEventByTokenArgs {
  eventToken: Scalars['String'];
}


export interface QueryFetchAttendeeInformationArgs {
  eventToken: Scalars['String'];
  ids: Array<Scalars['String']>;
}


export interface QueryLocalizableFileableByArgs {
  objectId: Scalars['ID'];
  fieldName: Scalars['String'];
}


export interface QueryNodeArgs {
  id: Scalars['ID'];
}


export interface QueryNodesArgs {
  ids: Array<Scalars['ID']>;
}


export interface QueryRongcloudGroupArgs {
  rongcloudId: Scalars['String'];
}


export interface QueryVideoCallRoomByTokenArgs {
  token: Scalars['String'];
}

/** Autogenerated input type of RegisterEventxtraAttendeeAsync */
export interface RegisterEventxtraAttendeeAsyncInput {
  eventId: Scalars['ID'];
  uniqueToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of RegisterEventxtraAttendeeAsync */
export interface RegisterEventxtraAttendeeAsyncPayload {
  __typename?: 'RegisterEventxtraAttendeeAsyncPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  job?: Maybe<RegisterEventxtraAttendeeJob>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface RegisterEventxtraAttendeeJob extends Node, Job {
  __typename?: 'RegisterEventxtraAttendeeJob';
  /** ID of the object. */
  id: Scalars['ID'];
  result: RegisterEventxtraAttendeeResult;
  status: JobStatus;
}

export interface RegisterEventxtraAttendeeResult extends JobResult {
  __typename?: 'RegisterEventxtraAttendeeResult';
  accessToken?: Maybe<Scalars['String']>;
  attendee?: Maybe<Attendee>;
  job: Job;
}

/** Autogenerated input type of RegisterRongcloudServiceAsync */
export interface RegisterRongcloudServiceAsyncInput {
  rongcloudUserId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of RegisterRongcloudServiceAsync */
export interface RegisterRongcloudServiceAsyncPayload {
  __typename?: 'RegisterRongcloudServiceAsyncPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  job?: Maybe<RegisterRongcloudServiceJob>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface RegisterRongcloudServiceJob extends Node, Job {
  __typename?: 'RegisterRongcloudServiceJob';
  /** ID of the object. */
  id: Scalars['ID'];
  result: RegisterRongcloudServiceResult;
  status: JobStatus;
}

export interface RegisterRongcloudServiceResult extends JobResult {
  __typename?: 'RegisterRongcloudServiceResult';
  job: Job;
  rongcloudToken?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of RemoveContact */
export interface RemoveContactInput {
  userUuid: Scalars['String'];
  contactUuid: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of RemoveContact */
export interface RemoveContactPayload {
  __typename?: 'RemoveContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
}

/** RongcloudGroup */
export interface RongcloudGroup {
  __typename?: 'RongcloudGroup';
  rongcloudUsers: AttendeeConnection;
}


/** RongcloudGroup */
export interface RongcloudGroupRongcloudUsersArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  keyword?: Maybe<Scalars['String']>;
}

export interface SearchResult extends Node {
  __typename?: 'SearchResult';
  id: Scalars['ID'];
  searchable: Searchable;
}

/** The connection type for SearchResult. */
export interface SearchResultConnection {
  __typename?: 'SearchResultConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SearchResultEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SearchResult>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface SearchResultEdge {
  __typename?: 'SearchResultEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SearchResult>;
}

/** Searchable objects */
export type Searchable = Booth;

/** Autogenerated input type of SendSmsLoginCode */
export interface SendSmsLoginCodeInput {
  eventId: Scalars['ID'];
  /** Target phone number for sending SMS login code */
  phoneNumber: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of SendSmsLoginCode */
export interface SendSmsLoginCodePayload {
  __typename?: 'SendSmsLoginCodePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface SharedAttachment {
  __typename?: 'SharedAttachment';
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  fileUrl: Scalars['String'];
  id: Scalars['Int'];
}


/** Standard error information */
export interface StandardError {
  __typename?: 'StandardError';
  details: Array<StandardErrorDetail>;
  message: Scalars['String'];
  path: Array<Scalars['StandardErrorPathSegment']>;
  type?: Maybe<Scalars['String']>;
}

/** Standard error detail */
export interface StandardErrorDetail {
  __typename?: 'StandardErrorDetail';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
}



/** Autogenerated input type of UploadFile */
export interface UploadFileInput {
  eventToken?: Maybe<Scalars['String']>;
  eventDomain?: Maybe<Scalars['String']>;
  /** Upload file */
  file: Scalars['Upload'];
  uniqueToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of UploadFile */
export interface UploadFilePayload {
  __typename?: 'UploadFilePayload';
  attachment?: Maybe<SharedAttachment>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface UserContactGroup {
  __typename?: 'UserContactGroup';
  contact?: Maybe<Attendee>;
  createdAt: Scalars['String'];
}

/** The connection type for UserContactGroup. */
export interface UserContactGroupConnection {
  __typename?: 'UserContactGroupConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserContactGroupEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<UserContactGroup>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface UserContactGroupEdge {
  __typename?: 'UserContactGroupEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<UserContactGroup>;
}

/** Autogenerated input type of V2GroupVideoCallRoomCreate */
export interface V2GroupVideoCallRoomCreateInput {
  boothId: Scalars['ID'];
  participantId: Scalars['ID'];
  imService?: Maybe<ImService>;
  conversationId?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of V2GroupVideoCallRoomCreate */
export interface V2GroupVideoCallRoomCreatePayload {
  __typename?: 'V2GroupVideoCallRoomCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  roomAdmins?: Maybe<AttendeeConnection>;
  roomMember?: Maybe<VideoCallRoomMember>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
  /** The `VideoCallRoom` object after create */
  videoCallRoom?: Maybe<VideoCallRoom>;
}


/** Autogenerated return type of V2GroupVideoCallRoomCreate */
export interface V2GroupVideoCallRoomCreatePayloadRoomAdminsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of VerifySmsLoginCode */
export interface VerifySmsLoginCodeInput {
  eventId: Scalars['ID'];
  /** Phone number for SMS login code */
  phoneNumber: Scalars['String'];
  /** SMS login code to verify */
  code: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of VerifySmsLoginCode */
export interface VerifySmsLoginCodePayload {
  __typename?: 'VerifySmsLoginCodePayload';
  /** attendee unique token after verification is successful */
  attendeeUniqueToken?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface Video extends Fileable {
  __typename?: 'Video';
  /** Cloudflare HLS url */
  cfHlsUrl?: Maybe<Scalars['String']>;
  /** Cloudflare state */
  cfState?: Maybe<VideoState>;
  /** Cloudflare video ID */
  cfVideoId?: Maybe<Scalars['String']>;
  contentType: Scalars['String'];
  fileUrl: Scalars['String'];
  filename: Scalars['String'];
  playbackUrl: Scalars['String'];
}

/** Autogenerated input type of VideoCallParticipantAccept */
export interface VideoCallParticipantAcceptInput {
  participantId: Scalars['String'];
  roomToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of VideoCallParticipantAccept */
export interface VideoCallParticipantAcceptPayload {
  __typename?: 'VideoCallParticipantAcceptPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of VideoCallParticipantJoin */
export interface VideoCallParticipantJoinInput {
  participantId: Scalars['String'];
  roomToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of VideoCallParticipantJoin */
export interface VideoCallParticipantJoinPayload {
  __typename?: 'VideoCallParticipantJoinPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

/** Autogenerated input type of VideoCallParticipantQuit */
export interface VideoCallParticipantQuitInput {
  participantId: Scalars['String'];
  roomToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of VideoCallParticipantQuit */
export interface VideoCallParticipantQuitPayload {
  __typename?: 'VideoCallParticipantQuitPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
}

export interface VideoCallRoom extends Node {
  __typename?: 'VideoCallRoom';
  id: Scalars['ID'];
  imService?: Maybe<ImService>;
  kind?: Maybe<VideoCallRoomKind>;
  participants: AttendeeConnection;
  status?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
}


export interface VideoCallRoomParticipantsArgs {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}

/** Autogenerated input type of VideoCallRoomCreate */
export interface VideoCallRoomCreateInput {
  inviterId: Scalars['String'];
  inviteeId: Scalars['String'];
  conversationId?: Maybe<Scalars['String']>;
  imService?: Maybe<ImService>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of VideoCallRoomCreate */
export interface VideoCallRoomCreatePayload {
  __typename?: 'VideoCallRoomCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
  /** The `VideoCallRoom` object after create */
  videoCallRoom: VideoCallRoom;
}

/** Possible value types for `kind` of a `VideoCallRoom` */
export enum VideoCallRoomKind {
  /** private */
  Private = 'PRIVATE',
  /** group */
  Group = 'GROUP'
}

/** VideoCallRoomMember */
export interface VideoCallRoomMember extends Node {
  __typename?: 'VideoCallRoomMember';
  id: Scalars['ID'];
  isInviter: Scalars['Boolean'];
  status?: Maybe<Scalars['String']>;
  twilioToken?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of VideoCallTokenCreate */
export interface VideoCallTokenCreateInput {
  participantId: Scalars['String'];
  roomToken: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
}

/** Autogenerated return type of VideoCallTokenCreate */
export interface VideoCallTokenCreatePayload {
  __typename?: 'VideoCallTokenCreatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Errors occured during the operation */
  errors: Array<StandardError>;
  participant: Attendee;
  roomMember?: Maybe<VideoCallRoomMember>;
  /** Whether the operation is successful */
  success: Scalars['Boolean'];
  videoCallRoom?: Maybe<VideoCallRoom>;
}

/** Possible value types for `state` of a `Video` */
export enum VideoState {
  /** Downloading */
  Downloading = 'DOWNLOADING',
  /** Inprogress */
  Inprogress = 'INPROGRESS',
  /** Ready */
  Ready = 'READY',
  /** Error */
  Error = 'ERROR'
}

export type BoothEditGetBoothDesignQueryVariables = Exact<{
  boothId: Scalars['ID'];
}>;


export type BoothEditGetBoothDesignQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & { boothDesign?: Maybe<(
      { __typename?: 'BoothDesign' }
      & Pick<BoothDesign, 'id'>
    )> }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type BoothEditGetBoothDesignCanvasQueryVariables = Exact<{
  boothId: Scalars['ID'];
}>;


export type BoothEditGetBoothDesignCanvasQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & { boothDesignCanvas?: Maybe<(
      { __typename?: 'BoothDesignCanvasElement' }
      & Pick<BoothDesignCanvasElement, 'id'>
      & { aspectRatio?: Maybe<(
        { __typename?: 'DisplayMeasurement' }
        & DisplayMeasurementFragmentFragment
      )>, maxWidth?: Maybe<(
        { __typename?: 'DisplayMeasurement' }
        & DisplayMeasurementFragmentFragment
      )>, positionX?: Maybe<(
        { __typename?: 'DisplayMeasurement' }
        & DisplayMeasurementFragmentFragment
      )>, positionY?: Maybe<(
        { __typename?: 'DisplayMeasurement' }
        & DisplayMeasurementFragmentFragment
      )> }
    )> }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type BoothEditGetBoothDesignCanvasLayersListQueryVariables = Exact<{
  boothId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  after?: Maybe<Scalars['String']>;
}>;


export type BoothEditGetBoothDesignCanvasLayersListQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & { boothDesignCanvas?: Maybe<(
      { __typename?: 'BoothDesignCanvasElement' }
      & { layers: (
        { __typename?: 'BoothV2DesignElementConnection' }
        & { nodes?: Maybe<Array<Maybe<{ __typename?: 'BoothDesignCanvasElement' } | (
          { __typename?: 'BoothDesignColorFillElement' }
          & BoothDesignColorFillElementFragmentFragment
        ) | (
          { __typename?: 'BoothDesignContentAreaElement' }
          & BoothDesignContentAreaElementFragmentFragment
        ) | (
          { __typename?: 'BoothDesignImageButtonElement' }
          & BoothDesignImageButtonElementFragmentFragment
        ) | (
          { __typename?: 'BoothDesignImageElement' }
          & BoothDesignImageElementFragmentFragment
        ) | (
          { __typename?: 'BoothDesignNetworkingWidgetElement' }
          & BoothDesignNetworkingWidgetElementFragmentFragment
        )>>>, pageInfo: (
          { __typename?: 'PageInfo' }
          & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
        ) }
      ) }
    )> }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type BoothEditGetBoothDesignsListQueryVariables = Exact<{
  boothId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  after?: Maybe<Scalars['String']>;
}>;


export type BoothEditGetBoothDesignsListQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & { event: (
      { __typename?: 'Event' }
      & { boothDesigns: (
        { __typename?: 'BoothDesignConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'BoothDesign' }
          & Pick<BoothDesign, 'id'>
          & { name: (
            { __typename?: 'LocalizableString' }
            & LocalizableStringFragmentFragment
          ) }
        )>>>, pageInfo: (
          { __typename?: 'PageInfo' }
          & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
        ) }
      ) }
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type BoothEditGetBoothCategoriesListQueryVariables = Exact<{
  boothId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
}>;


export type BoothEditGetBoothCategoriesListQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & { categories: (
      { __typename?: 'BoothCategoryConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BoothCategory' }
        & Pick<BoothCategory, 'id'>
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type BoothEditGetEventTaxonomiesListQueryVariables = Exact<{
  boothId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  after?: Maybe<Scalars['String']>;
}>;


export type BoothEditGetEventTaxonomiesListQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & { event: (
      { __typename?: 'Event' }
      & { boothTaxonomies: (
        { __typename?: 'BoothTaxonomyConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename: 'BoothCategory' }
          & Pick<BoothCategory, 'id'>
          & { name: (
            { __typename?: 'LocalizableString' }
            & LocalizableStringFragmentFragment
            & LocalizableStringFragmentFragment
          ), parentSupercategory?: Maybe<(
            { __typename?: 'BoothSupercategory' }
            & Pick<BoothSupercategory, 'id'>
          )> }
        ) | (
          { __typename: 'BoothSupercategory' }
          & Pick<BoothSupercategory, 'id'>
          & { name: (
            { __typename?: 'LocalizableString' }
            & LocalizableStringFragmentFragment
            & LocalizableStringFragmentFragment
          ) }
        )>>>, pageInfo: (
          { __typename?: 'PageInfo' }
          & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
        ) }
      ) }
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type BoothEditGetLegacyDesignElementsQueryVariables = Exact<{
  boothId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  after?: Maybe<Scalars['String']>;
}>;


export type BoothEditGetLegacyDesignElementsQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & { designElements: (
      { __typename?: 'BoothDesignElementConnection' }
      & { pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
      & BoothDesignElementsFragmentFragment
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type BoothEditGetContentElementTabQueryVariables = Exact<{
  tabId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
}>;


export type BoothEditGetContentElementTabQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | (
    { __typename?: 'BoothTabContentElement' }
    & Pick<BoothTabContentElement, 'id'>
    & { title: (
      { __typename?: 'LocalizableString' }
      & LocalizableStringFragmentFragment
    ), masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | (
      { __typename?: 'BoothTabMasterElement' }
      & Pick<BoothTabMasterElement, 'masterControlledTitle' | 'masterControlledBody'>
    ) | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | (
      { __typename?: 'BoothTabContentElement' }
      & { title: (
        { __typename?: 'LocalizableString' }
        & LocalizableStringFragmentFragment
      ) }
    ) | { __typename?: 'BoothVideoContentElement' } }
  ) | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type BoothEditGetContentElementTabBodyNodesQueryVariables = Exact<{
  tabId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  after?: Maybe<Scalars['String']>;
}>;


export type BoothEditGetContentElementTabBodyNodesQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | (
    { __typename?: 'BoothTabContentElement' }
    & { body: (
      { __typename?: 'BoothContentElementConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BoothDividerContentElement' }
        & BoothContentElementFragment_BoothDividerContentElement_Fragment
      ) | (
        { __typename?: 'BoothFileContentElement' }
        & BoothContentElementFragment_BoothFileContentElement_Fragment
      ) | (
        { __typename?: 'BoothImageContentElement' }
        & BoothContentElementFragment_BoothImageContentElement_Fragment
      ) | (
        { __typename?: 'BoothQuestionContentElement' }
        & BoothContentElementFragment_BoothQuestionContentElement_Fragment
      ) | (
        { __typename?: 'BoothRichtextContentElement' }
        & BoothContentElementFragment_BoothRichtextContentElement_Fragment
      ) | (
        { __typename?: 'BoothRootContentElement' }
        & BoothContentElementFragment_BoothRootContentElement_Fragment
      ) | (
        { __typename?: 'BoothTabContentElement' }
        & BoothContentElementFragment_BoothTabContentElement_Fragment
      ) | (
        { __typename?: 'BoothVideoContentElement' }
        & BoothContentElementFragment_BoothVideoContentElement_Fragment
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
    ) }
  ) | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type BoothDesignColorFillElementUpdateMutationVariables = Exact<{
  input: BoothDesignColorFillElementUpdateInput;
}>;


export type BoothDesignColorFillElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignColorFillElementUpdate?: Maybe<(
    { __typename?: 'BoothDesignColorFillElementUpdatePayload' }
    & Pick<BoothDesignColorFillElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothDesignContentAreaElementUpdateMutationVariables = Exact<{
  input: BoothDesignContentAreaElementUpdateInput;
}>;


export type BoothDesignContentAreaElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignContentAreaElementUpdate?: Maybe<(
    { __typename?: 'BoothDesignContentAreaElementUpdatePayload' }
    & Pick<BoothDesignContentAreaElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothDesignImageButtonElementUpdateMutationVariables = Exact<{
  input: BoothDesignImageButtonElementUpdateInput;
}>;


export type BoothDesignImageButtonElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignImageButtonElementUpdate?: Maybe<(
    { __typename?: 'BoothDesignImageButtonElementUpdatePayload' }
    & Pick<BoothDesignImageButtonElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothEditGetRepresentativesListQueryVariables = Exact<{
  boothId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
}>;


export type BoothEditGetRepresentativesListQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & { representatives?: Maybe<(
      { __typename?: 'AttendeeConnection' }
      & Pick<AttendeeConnection, 'totalCount'>
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Attendee' }
        & AttendeeFragmentFragment
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
    )> }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type BoothEditRepresentativeAddMutationVariables = Exact<{
  input: BoothRepresentativeCreateInput;
}>;


export type BoothEditRepresentativeAddMutation = (
  { __typename?: 'Mutation' }
  & { boothRepresentativeCreate?: Maybe<(
    { __typename?: 'BoothRepresentativeCreatePayload' }
    & Pick<BoothRepresentativeCreatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )>, representative: (
      { __typename?: 'Attendee' }
      & AttendeeFragmentFragment
    ) }
  )> }
);

export type BoothEditRepresentativeDeleteMutationVariables = Exact<{
  input: BoothRepresentativeDeleteInput;
}>;


export type BoothEditRepresentativeDeleteMutation = (
  { __typename?: 'Mutation' }
  & { boothRepresentativeDelete?: Maybe<(
    { __typename?: 'BoothRepresentativeDeletePayload' }
    & Pick<BoothRepresentativeDeletePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type DesignEditorCanvasUpdateMutationVariables = Exact<{
  input: BoothDesignCanvasMasterElementUpdateInput;
}>;


export type DesignEditorCanvasUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignCanvasMasterElementUpdate?: Maybe<(
    { __typename?: 'BoothDesignCanvasMasterElementUpdatePayload' }
    & Pick<BoothDesignCanvasMasterElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type DesignEditorGetboothDesignByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
}>;


export type DesignEditorGetboothDesignByIdQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | (
    { __typename?: 'BoothDesign' }
    & Pick<BoothDesign, 'id'>
    & { name: (
      { __typename?: 'LocalizableString' }
      & LocalizableStringFragmentFragment
    ), canvas?: Maybe<(
      { __typename?: 'BoothDesignCanvasMasterElement' }
      & Pick<BoothDesignCanvasMasterElement, 'id'>
      & { aspectRatio: (
        { __typename?: 'DisplayMeasurement' }
        & DisplayMeasurementFragmentFragment
      ), height: (
        { __typename?: 'DisplayMeasurement' }
        & DisplayMeasurementFragmentFragment
      ), maxWidth: (
        { __typename?: 'DisplayMeasurement' }
        & DisplayMeasurementFragmentFragment
      ), positionX: (
        { __typename?: 'DisplayMeasurement' }
        & DisplayMeasurementFragmentFragment
      ), positionY: (
        { __typename?: 'DisplayMeasurement' }
        & DisplayMeasurementFragmentFragment
      ), layers: (
        { __typename?: 'BoothDesignMasterElementConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'BoothDesignCanvasMasterElement' }
          & Pick<BoothDesignCanvasMasterElement, 'id'>
        ) | (
          { __typename?: 'BoothDesignColorFillMasterElement' }
          & Pick<BoothDesignColorFillMasterElement, 'id'>
          & BoothDesignColorFillMasterElementFragmentFragment
        ) | (
          { __typename?: 'BoothDesignContentAreaMasterElement' }
          & Pick<BoothDesignContentAreaMasterElement, 'id'>
          & BoothDesignContentAreaMasterElementFragmentFragment
        ) | (
          { __typename?: 'BoothDesignImageButtonMasterElement' }
          & Pick<BoothDesignImageButtonMasterElement, 'id'>
          & BoothDesignImageButtonMasterElementFragmentFragment
        ) | (
          { __typename?: 'BoothDesignImageMasterElement' }
          & Pick<BoothDesignImageMasterElement, 'id'>
          & BoothDesignImageMasterElementFragmentFragment
        ) | (
          { __typename?: 'BoothDesignNetworkingWidgetMasterElement' }
          & Pick<BoothDesignNetworkingWidgetMasterElement, 'id'>
          & BoothDesignNetworkingWidgetMasterElementFragmentFragment
        )>>> }
      ) }
    )> }
  ) | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type DesignEditorGetBoothDesignsListByEventIdQueryVariables = Exact<{
  eventId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  after?: Maybe<Scalars['String']>;
}>;


export type DesignEditorGetBoothDesignsListByEventIdQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | (
    { __typename?: 'Event' }
    & { boothDesigns: (
      { __typename?: 'BoothDesignConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BoothDesign' }
        & Pick<BoothDesign, 'id'>
        & { name: (
          { __typename?: 'LocalizableString' }
          & LocalizableStringFragmentFragment
        ) }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
    ) }
  ) | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type DesignEditorLayerDeleteByIdMutationVariables = Exact<{
  input: BoothDesignMasterElementDeleteInput;
}>;


export type DesignEditorLayerDeleteByIdMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignMasterElementDelete?: Maybe<(
    { __typename?: 'BoothDesignMasterElementDeletePayload' }
    & Pick<BoothDesignMasterElementDeletePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type DesignEditorLayerImageButtonUpdateMutationVariables = Exact<{
  input: BoothDesignImageButtonMasterElementUpdateInput;
}>;


export type DesignEditorLayerImageButtonUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignImageButtonMasterElementUpdate?: Maybe<(
    { __typename?: 'BoothDesignImageButtonMasterElementUpdatePayload' }
    & Pick<BoothDesignImageButtonMasterElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type DesignEditorLayerImageButtonCreateMutationVariables = Exact<{
  input: BoothDesignImageButtonMasterElementCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type DesignEditorLayerImageButtonCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignImageButtonMasterElementCreate?: Maybe<(
    { __typename?: 'BoothDesignImageButtonMasterElementCreatePayload' }
    & Pick<BoothDesignImageButtonMasterElementCreatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )>, element: (
      { __typename?: 'BoothDesignImageButtonMasterElement' }
      & BoothDesignImageButtonMasterElementFragmentFragment
    ) }
  )> }
);

export type DesignEditorLayerContentAreaUpdateMutationVariables = Exact<{
  input: BoothDesignContentAreaMasterElementUpdateInput;
}>;


export type DesignEditorLayerContentAreaUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignContentAreaMasterElementUpdate?: Maybe<(
    { __typename?: 'BoothDesignContentAreaMasterElementUpdatePayload' }
    & Pick<BoothDesignContentAreaMasterElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type DesignEditorLayerContentAreaCreateMutationVariables = Exact<{
  input: BoothDesignContentAreaMasterElementCreateInput;
}>;


export type DesignEditorLayerContentAreaCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignContentAreaMasterElementCreate?: Maybe<(
    { __typename?: 'BoothDesignContentAreaMasterElementCreatePayload' }
    & Pick<BoothDesignContentAreaMasterElementCreatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )>, element: (
      { __typename?: 'BoothDesignContentAreaMasterElement' }
      & BoothDesignContentAreaMasterElementFragmentFragment
    ) }
  )> }
);

export type DesignEditorLayerImageUpdateMutationVariables = Exact<{
  input: BoothDesignImageMasterElementUpdateInput;
}>;


export type DesignEditorLayerImageUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignImageMasterElementUpdate?: Maybe<(
    { __typename?: 'BoothDesignImageMasterElementUpdatePayload' }
    & Pick<BoothDesignImageMasterElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type DesignEditorLayerImageCreateMutationVariables = Exact<{
  input: BoothDesignImageMasterElementCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type DesignEditorLayerImageCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignImageMasterElementCreate?: Maybe<(
    { __typename?: 'BoothDesignImageMasterElementCreatePayload' }
    & Pick<BoothDesignImageMasterElementCreatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )>, element: (
      { __typename?: 'BoothDesignImageMasterElement' }
      & BoothDesignImageMasterElementFragmentFragment
    ) }
  )> }
);

export type DesignEditorLayerColorFillUpdateMutationVariables = Exact<{
  input: BoothDesignColorFillMasterElementUpdateInput;
}>;


export type DesignEditorLayerColorFillUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignColorFillMasterElementUpdate?: Maybe<(
    { __typename?: 'BoothDesignColorFillMasterElementUpdatePayload' }
    & Pick<BoothDesignColorFillMasterElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type DesignEditorLayerColorFillCreateMutationVariables = Exact<{
  input: BoothDesignColorFillMasterElementCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type DesignEditorLayerColorFillCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignColorFillMasterElementCreate?: Maybe<(
    { __typename?: 'BoothDesignColorFillMasterElementCreatePayload' }
    & Pick<BoothDesignColorFillMasterElementCreatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )>, element: (
      { __typename?: 'BoothDesignColorFillMasterElement' }
      & BoothDesignColorFillMasterElementFragmentFragment
    ) }
  )> }
);

export type DesignEditorLayerNetworkingWidgetUpdateMutationVariables = Exact<{
  input: BoothDesignNetworkingWidgetMasterElementUpdateInput;
}>;


export type DesignEditorLayerNetworkingWidgetUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignNetworkingWidgetMasterElementUpdate?: Maybe<(
    { __typename?: 'BoothDesignNetworkingWidgetMasterElementUpdatePayload' }
    & Pick<BoothDesignNetworkingWidgetMasterElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type DesignEditorLayerNetworkingWidgetCreateMutationVariables = Exact<{
  input: BoothDesignNetworkingWidgetMasterElementCreateInput;
}>;


export type DesignEditorLayerNetworkingWidgetCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignNetworkingWidgetMasterElementCreate?: Maybe<(
    { __typename?: 'BoothDesignNetworkingWidgetMasterElementCreatePayload' }
    & Pick<BoothDesignNetworkingWidgetMasterElementCreatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )>, element: (
      { __typename?: 'BoothDesignNetworkingWidgetMasterElement' }
      & BoothDesignNetworkingWidgetMasterElementFragmentFragment
    ) }
  )> }
);

export type BoothTierCreateMutationVariables = Exact<{
  input: BoothTierCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type BoothTierCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothTierCreate?: Maybe<(
    { __typename?: 'BoothTierCreatePayload' }
    & Pick<BoothTierCreatePayload, 'success'>
    & { boothTier: (
      { __typename?: 'BoothTier' }
      & BoothTierFragmentFragment
    ), errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothTierDeleteMutationVariables = Exact<{
  input: BoothTierDeleteInput;
}>;


export type BoothTierDeleteMutation = (
  { __typename?: 'Mutation' }
  & { boothTierDelete?: Maybe<(
    { __typename?: 'BoothTierDeletePayload' }
    & Pick<BoothTierDeletePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothTierUpdateMutationVariables = Exact<{
  input: BoothTierUpdateInput;
}>;


export type BoothTierUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothTierUpdate?: Maybe<(
    { __typename?: 'BoothTierUpdatePayload' }
    & Pick<BoothTierUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type EventEditBoothCreateMutationVariables = Exact<{
  input: BoothCreateInput;
}>;


export type EventEditBoothCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothCreate?: Maybe<(
    { __typename?: 'BoothCreatePayload' }
    & Pick<BoothCreatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type EventEditGetBoothTiersListQueryVariables = Exact<{
  eventId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  after?: Maybe<Scalars['String']>;
}>;


export type EventEditGetBoothTiersListQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | (
    { __typename?: 'Event' }
    & { boothTiers: (
      { __typename?: 'BoothTierConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BoothTier' }
        & Pick<BoothTier, 'id' | 'nameVisible'>
        & { name: (
          { __typename?: 'LocalizableString' }
          & LocalizableStringFragmentFragment
        ), scale: (
          { __typename?: 'DisplayMeasurement' }
          & DisplayMeasurementFragmentFragment
        ), booths: (
          { __typename?: 'BoothConnection' }
          & Pick<BoothConnection, 'totalCount'>
        ) }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
      ) }
    ) }
  ) | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type EventEditGetBoothsExportListByTierIdQueryVariables = Exact<{
  tierId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  after?: Maybe<Scalars['String']>;
  pageSize: Scalars['Int'];
}>;


export type EventEditGetBoothsExportListByTierIdQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | (
    { __typename?: 'BoothTier' }
    & { booths: (
      { __typename?: 'BoothConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Booth' }
        & Pick<Booth, 'id' | 'cmsUrl'>
        & { name: (
          { __typename?: 'LocalizableString' }
          & LocalizableStringFragmentFragment
        ), tier: (
          { __typename?: 'BoothTier' }
          & { name: (
            { __typename?: 'LocalizableString' }
            & LocalizableStringFragmentFragment
          ) }
        ) }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
    ) }
  ) | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type EventEditGetBoothsListQueryVariables = Exact<{
  eventId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  pageSize: Scalars['Int'];
  locales: Array<Scalars['Locale']>;
}>;


export type EventEditGetBoothsListQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | (
    { __typename?: 'Event' }
    & { booths: (
      { __typename?: 'BoothConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Booth' }
        & Pick<Booth, 'id' | 'cmsUrl'>
        & { name: (
          { __typename?: 'LocalizableString' }
          & LocalizableStringFragmentFragment
        ), tier: (
          { __typename?: 'BoothTier' }
          & { name: (
            { __typename?: 'LocalizableString' }
            & LocalizableStringFragmentFragment
          ) }
        ) }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
      ) }
    ) }
  ) | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type EventEditGetBoothsListByTierIdQueryVariables = Exact<{
  tierId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  after?: Maybe<Scalars['String']>;
  pageSize: Scalars['Int'];
}>;


export type EventEditGetBoothsListByTierIdQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | (
    { __typename?: 'BoothTier' }
    & { booths: (
      { __typename?: 'BoothConnection' }
      & Pick<BoothConnection, 'totalCount'>
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Booth' }
        & Pick<Booth, 'id' | 'published' | 'cmsUrl'>
        & { name: (
          { __typename?: 'LocalizableString' }
          & LocalizableStringFragmentFragment
        ), logo: (
          { __typename?: 'LocalizableImage' }
          & LocalizableImageFragmentFragment
        ), tier: (
          { __typename?: 'BoothTier' }
          & Pick<BoothTier, 'id'>
        ), boothDesign?: Maybe<(
          { __typename?: 'BoothDesign' }
          & Pick<BoothDesign, 'id'>
        )> }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
    ) }
  ) | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type EventEditGetBoothsListTotalCountQueryVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type EventEditGetBoothsListTotalCountQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | (
    { __typename?: 'Event' }
    & { booths: (
      { __typename?: 'BoothConnection' }
      & Pick<BoothConnection, 'totalCount'>
    ) }
  ) | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type EventEditGetBoothsNameListByIdsQueryVariables = Exact<{
  boothIds: Array<Scalars['ID']>;
  locales: Array<Scalars['Locale']>;
}>;


export type EventEditGetBoothsNameListByIdsQuery = (
  { __typename?: 'Query' }
  & { nodes: Array<Maybe<(
    { __typename?: 'Booth' }
    & Pick<Booth, 'id'>
    & { name: (
      { __typename?: 'LocalizableString' }
      & LocalizableStringFragmentFragment
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }>> }
);

export type EventEditSearchBoothQueryVariables = Exact<{
  eventId: Scalars['ID'];
  keyword?: Maybe<Scalars['String']>;
  locales: Array<Scalars['Locale']>;
}>;


export type EventEditSearchBoothQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | (
    { __typename?: 'Event' }
    & { searchByKeyword: (
      { __typename?: 'SearchResultConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'SearchResult' }
        & { searchable: (
          { __typename?: 'Booth' }
          & Pick<Booth, 'id'>
          & { name: (
            { __typename?: 'LocalizableString' }
            & LocalizableStringFragmentFragment
          ) }
        ) }
      )>>> }
    ) }
  ) | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type EventEditGetBoothTaxonomiesListQueryVariables = Exact<{
  eventId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  after?: Maybe<Scalars['String']>;
}>;


export type EventEditGetBoothTaxonomiesListQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | (
    { __typename?: 'Event' }
    & { boothTaxonomies: (
      { __typename?: 'BoothTaxonomyConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename: 'BoothCategory' }
        & Pick<BoothCategory, 'id'>
        & { name: (
          { __typename?: 'LocalizableString' }
          & LocalizableStringFragmentFragment
        ), parentSupercategory?: Maybe<(
          { __typename?: 'BoothSupercategory' }
          & Pick<BoothSupercategory, 'id'>
        )> }
      ) | (
        { __typename: 'BoothSupercategory' }
        & Pick<BoothSupercategory, 'id'>
        & { name: (
          { __typename?: 'LocalizableString' }
          & LocalizableStringFragmentFragment
        ), parentSupercategory?: Maybe<(
          { __typename?: 'BoothSupercategory' }
          & Pick<BoothSupercategory, 'id'>
        )> }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
    ) }
  ) | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

type NonQuestionChildFragment_BoothDividerMasterElement_Fragment = (
  { __typename: 'BoothDividerMasterElement' }
  & Pick<BoothDividerMasterElement, 'id'>
  & BoothDividerMasterElementFragmentFragment
);

type NonQuestionChildFragment_BoothFileMasterElement_Fragment = (
  { __typename: 'BoothFileMasterElement' }
  & Pick<BoothFileMasterElement, 'id'>
  & BoothFileMasterElementFragmentFragment
);

type NonQuestionChildFragment_BoothImageMasterElement_Fragment = (
  { __typename: 'BoothImageMasterElement' }
  & Pick<BoothImageMasterElement, 'id'>
  & BoothImageMasterElementFragmentFragment
);

type NonQuestionChildFragment_BoothQuestionMasterElement_Fragment = (
  { __typename: 'BoothQuestionMasterElement' }
  & Pick<BoothQuestionMasterElement, 'id'>
);

type NonQuestionChildFragment_BoothRichtextMasterElement_Fragment = (
  { __typename: 'BoothRichtextMasterElement' }
  & Pick<BoothRichtextMasterElement, 'id'>
  & BoothRichtextMasterElementFragmentFragment
);

type NonQuestionChildFragment_BoothRootMasterElement_Fragment = (
  { __typename: 'BoothRootMasterElement' }
  & Pick<BoothRootMasterElement, 'id'>
);

type NonQuestionChildFragment_BoothTabMasterElement_Fragment = (
  { __typename: 'BoothTabMasterElement' }
  & Pick<BoothTabMasterElement, 'id'>
);

type NonQuestionChildFragment_BoothVideoMasterElement_Fragment = (
  { __typename: 'BoothVideoMasterElement' }
  & Pick<BoothVideoMasterElement, 'id'>
  & BoothVideoMasterElementFragmentFragment
);

export type NonQuestionChildFragmentFragment = NonQuestionChildFragment_BoothDividerMasterElement_Fragment | NonQuestionChildFragment_BoothFileMasterElement_Fragment | NonQuestionChildFragment_BoothImageMasterElement_Fragment | NonQuestionChildFragment_BoothQuestionMasterElement_Fragment | NonQuestionChildFragment_BoothRichtextMasterElement_Fragment | NonQuestionChildFragment_BoothRootMasterElement_Fragment | NonQuestionChildFragment_BoothTabMasterElement_Fragment | NonQuestionChildFragment_BoothVideoMasterElement_Fragment;

export type EventEditGetMasterTabByIdQueryVariables = Exact<{
  tabId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  after?: Maybe<Scalars['String']>;
}>;


export type EventEditGetMasterTabByIdQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename: 'Booth' }
    & Pick<Booth, 'id'>
  ) | (
    { __typename: 'BoothCategory' }
    & Pick<BoothCategory, 'id'>
  ) | (
    { __typename: 'BoothDesign' }
    & Pick<BoothDesign, 'id'>
  ) | (
    { __typename: 'BoothDesignCanvasElement' }
    & Pick<BoothDesignCanvasElement, 'id'>
  ) | (
    { __typename: 'BoothDesignColorFillElement' }
    & Pick<BoothDesignColorFillElement, 'id'>
  ) | (
    { __typename: 'BoothDesignContentAreaElement' }
    & Pick<BoothDesignContentAreaElement, 'id'>
  ) | (
    { __typename: 'BoothDesignElement' }
    & Pick<BoothDesignElement, 'id'>
  ) | (
    { __typename: 'BoothDesignImageButtonElement' }
    & Pick<BoothDesignImageButtonElement, 'id'>
  ) | (
    { __typename: 'BoothDesignImageElement' }
    & Pick<BoothDesignImageElement, 'id'>
  ) | (
    { __typename: 'BoothDesignNetworkingWidgetElement' }
    & Pick<BoothDesignNetworkingWidgetElement, 'id'>
  ) | (
    { __typename: 'BoothDividerContentElement' }
    & Pick<BoothDividerContentElement, 'id'>
  ) | (
    { __typename: 'BoothDividerMasterElement' }
    & Pick<BoothDividerMasterElement, 'id'>
  ) | (
    { __typename: 'BoothFileContentElement' }
    & Pick<BoothFileContentElement, 'id'>
  ) | (
    { __typename: 'BoothFileMasterElement' }
    & Pick<BoothFileMasterElement, 'id'>
  ) | (
    { __typename: 'BoothImageContentElement' }
    & Pick<BoothImageContentElement, 'id'>
  ) | (
    { __typename: 'BoothImageMasterElement' }
    & Pick<BoothImageMasterElement, 'id'>
  ) | (
    { __typename: 'BoothQuestionContentElement' }
    & Pick<BoothQuestionContentElement, 'id'>
  ) | (
    { __typename: 'BoothQuestionMasterElement' }
    & Pick<BoothQuestionMasterElement, 'id'>
  ) | (
    { __typename: 'BoothRichtextContentElement' }
    & Pick<BoothRichtextContentElement, 'id'>
  ) | (
    { __typename: 'BoothRichtextMasterElement' }
    & Pick<BoothRichtextMasterElement, 'id'>
  ) | (
    { __typename: 'BoothRootContentElement' }
    & Pick<BoothRootContentElement, 'id'>
  ) | (
    { __typename: 'BoothRootMasterElement' }
    & Pick<BoothRootMasterElement, 'id'>
  ) | (
    { __typename: 'BoothSupercategory' }
    & Pick<BoothSupercategory, 'id'>
  ) | (
    { __typename: 'BoothTabContentElement' }
    & Pick<BoothTabContentElement, 'id'>
  ) | (
    { __typename: 'BoothTabMasterElement' }
    & Pick<BoothTabMasterElement, 'masterControlledBody' | 'masterControlledTitle' | 'id'>
    & { title: (
      { __typename?: 'LocalizableString' }
      & LocalizableStringFragmentFragment
    ), body: (
      { __typename?: 'BoothMasterElementConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BoothDividerMasterElement' }
        & NonQuestionChildFragment_BoothDividerMasterElement_Fragment
      ) | (
        { __typename?: 'BoothFileMasterElement' }
        & NonQuestionChildFragment_BoothFileMasterElement_Fragment
      ) | (
        { __typename?: 'BoothImageMasterElement' }
        & NonQuestionChildFragment_BoothImageMasterElement_Fragment
      ) | (
        { __typename?: 'BoothQuestionMasterElement' }
        & Pick<BoothQuestionMasterElement, 'masterControlledQuestion' | 'masterControlledAnswer'>
        & { question: (
          { __typename?: 'LocalizableString' }
          & LocalizableStringFragmentFragment
        ), answer?: Maybe<(
          { __typename?: 'BoothDividerMasterElement' }
          & NonQuestionChildFragment_BoothDividerMasterElement_Fragment
        ) | (
          { __typename?: 'BoothFileMasterElement' }
          & NonQuestionChildFragment_BoothFileMasterElement_Fragment
        ) | (
          { __typename?: 'BoothImageMasterElement' }
          & NonQuestionChildFragment_BoothImageMasterElement_Fragment
        ) | (
          { __typename?: 'BoothQuestionMasterElement' }
          & NonQuestionChildFragment_BoothQuestionMasterElement_Fragment
        ) | (
          { __typename?: 'BoothRichtextMasterElement' }
          & NonQuestionChildFragment_BoothRichtextMasterElement_Fragment
        ) | (
          { __typename?: 'BoothRootMasterElement' }
          & NonQuestionChildFragment_BoothRootMasterElement_Fragment
        ) | (
          { __typename?: 'BoothTabMasterElement' }
          & NonQuestionChildFragment_BoothTabMasterElement_Fragment
        ) | (
          { __typename?: 'BoothVideoMasterElement' }
          & NonQuestionChildFragment_BoothVideoMasterElement_Fragment
        )> }
        & NonQuestionChildFragment_BoothQuestionMasterElement_Fragment
      ) | (
        { __typename?: 'BoothRichtextMasterElement' }
        & NonQuestionChildFragment_BoothRichtextMasterElement_Fragment
      ) | (
        { __typename?: 'BoothRootMasterElement' }
        & NonQuestionChildFragment_BoothRootMasterElement_Fragment
      ) | (
        { __typename?: 'BoothTabMasterElement' }
        & NonQuestionChildFragment_BoothTabMasterElement_Fragment
      ) | (
        { __typename?: 'BoothVideoMasterElement' }
        & NonQuestionChildFragment_BoothVideoMasterElement_Fragment
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
    ) }
  ) | (
    { __typename: 'BoothTier' }
    & Pick<BoothTier, 'id'>
  ) | (
    { __typename: 'BoothVideoContentElement' }
    & Pick<BoothVideoContentElement, 'id'>
  ) | (
    { __typename: 'BoothVideoMasterElement' }
    & Pick<BoothVideoMasterElement, 'id'>
  ) | (
    { __typename: 'Event' }
    & Pick<Event, 'id'>
  ) | (
    { __typename: 'GroupChatJoinJob' }
    & Pick<GroupChatJoinJob, 'id'>
  ) | (
    { __typename: 'Hall' }
    & Pick<Hall, 'id'>
  ) | (
    { __typename: 'RegisterEventxtraAttendeeJob' }
    & Pick<RegisterEventxtraAttendeeJob, 'id'>
  ) | (
    { __typename: 'RegisterRongcloudServiceJob' }
    & Pick<RegisterRongcloudServiceJob, 'id'>
  ) | (
    { __typename: 'SearchResult' }
    & Pick<SearchResult, 'id'>
  ) | (
    { __typename: 'VideoCallRoom' }
    & Pick<VideoCallRoom, 'id'>
  ) | (
    { __typename: 'VideoCallRoomMember' }
    & Pick<VideoCallRoomMember, 'id'>
  )> }
);

export type EventEditGetMasterTabsListQueryVariables = Exact<{
  eventId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  after?: Maybe<Scalars['String']>;
}>;


export type EventEditGetMasterTabsListQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | (
    { __typename: 'Event' }
    & Pick<Event, 'id'>
    & { rootMasterElement: (
      { __typename: 'BoothRootMasterElement' }
      & Pick<BoothRootMasterElement, 'id' | 'masterControlledTabs'>
      & { tabs: (
        { __typename?: 'BoothTabMasterElementConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'BoothTabMasterElement' }
          & Pick<BoothTabMasterElement, 'id' | 'masterControlledTitle' | 'masterControlledBody'>
          & { title: (
            { __typename?: 'LocalizableString' }
            & { values?: Maybe<Array<(
              { __typename?: 'LocalizableStringValue' }
              & Pick<LocalizableStringValue, 'locale' | 'value'>
            )>> }
          ) }
        )>>>, pageInfo: (
          { __typename?: 'PageInfo' }
          & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
        ) }
      ) }
    ) }
  ) | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type AttendeeFragmentFragment = (
  { __typename?: 'Attendee' }
  & Pick<Attendee, 'id' | 'firstName' | 'lastName' | 'email' | 'company' | 'title' | 'vexpoAccessUrl' | 'vexpoChatTag'>
);

export type BoothQuestionContentRawElementFragmentFragment = (
  { __typename: 'BoothQuestionContentElement' }
  & Pick<BoothQuestionContentElement, 'id'>
  & { question: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ), answer?: Maybe<{ __typename?: 'BoothDividerContentElement' } | (
    { __typename?: 'BoothFileContentElement' }
    & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | (
      { __typename?: 'BoothFileMasterElement' }
      & BoothFileMasterElementFragmentFragment
    ) | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | (
      { __typename?: 'BoothFileContentElement' }
      & BoothFileContentRawElementFragmentFragment
    ) | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothVideoContentElement' } }
    & BoothFileContentRawElementFragmentFragment
  ) | (
    { __typename?: 'BoothImageContentElement' }
    & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | (
      { __typename?: 'BoothImageMasterElement' }
      & BoothImageMasterElementFragmentFragment
    ) | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | (
      { __typename?: 'BoothImageContentElement' }
      & BoothImageContentRawElementFragmentFragment
    ) | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothVideoContentElement' } }
    & BoothImageContentRawElementFragmentFragment
  ) | { __typename?: 'BoothQuestionContentElement' } | (
    { __typename?: 'BoothRichtextContentElement' }
    & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | (
      { __typename?: 'BoothRichtextMasterElement' }
      & BoothRichtextMasterElementFragmentFragment
    ) | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | (
      { __typename?: 'BoothRichtextContentElement' }
      & BoothRichtextContentRawElementFragmentFragment
    ) | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothVideoContentElement' } }
    & BoothRichtextContentRawElementFragmentFragment
  ) | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | (
    { __typename?: 'BoothVideoContentElement' }
    & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | (
      { __typename?: 'BoothVideoMasterElement' }
      & BoothVideoMasterElementFragmentFragment
    )>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | (
      { __typename?: 'BoothVideoContentElement' }
      & BoothVideoContentRawElementFragmentFragment
    ) }
    & BoothVideoContentRawElementFragmentFragment
  )> }
);

export type BoothQuestionMasterElementFragmentFragment = (
  { __typename: 'BoothQuestionMasterElement' }
  & Pick<BoothQuestionMasterElement, 'id' | 'masterControlledAnswer' | 'masterControlledQuestion'>
  & { question: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ), answer?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | (
    { __typename?: 'BoothFileMasterElement' }
    & BoothFileMasterElementFragmentFragment
  ) | (
    { __typename?: 'BoothImageMasterElement' }
    & BoothImageMasterElementFragmentFragment
  ) | { __typename?: 'BoothQuestionMasterElement' } | (
    { __typename?: 'BoothRichtextMasterElement' }
    & BoothRichtextMasterElementFragmentFragment
  ) | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | (
    { __typename?: 'BoothVideoMasterElement' }
    & BoothVideoMasterElementFragmentFragment
  )> }
);

export type BoothRichtextContentRawElementFragmentFragment = (
  { __typename: 'BoothRichtextContentElement' }
  & Pick<BoothRichtextContentElement, 'id'>
  & { contentRichtextHtml: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ) }
);

export type BoothRichtextMasterElementFragmentFragment = (
  { __typename: 'BoothRichtextMasterElement' }
  & Pick<BoothRichtextMasterElement, 'id' | 'masterControlledContent'>
  & { contentRichtextHtml: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ) }
);

export type BoothVideoContentRawElementFragmentFragment = (
  { __typename: 'BoothVideoContentElement' }
  & Pick<BoothVideoContentElement, 'id'>
  & { videoFile: (
    { __typename?: 'LocalizableVideo' }
    & LocalizableVideoFragmentFragment
  ) }
);

export type BoothVideoMasterElementFragmentFragment = (
  { __typename: 'BoothVideoMasterElement' }
  & Pick<BoothVideoMasterElement, 'id' | 'masterControlledVideoFile'>
  & { videoFile: (
    { __typename?: 'LocalizableVideo' }
    & LocalizableVideoFragmentFragment
  ) }
);

export type BoothFileContentRawElementFragmentFragment = (
  { __typename: 'BoothFileContentElement' }
  & Pick<BoothFileContentElement, 'id'>
  & { file: (
    { __typename?: 'LocalizableFile' }
    & LocalizableFileFragmentFragment
  ) }
);

export type BoothFileMasterElementFragmentFragment = (
  { __typename: 'BoothFileMasterElement' }
  & Pick<BoothFileMasterElement, 'id' | 'masterControlledFile'>
  & { file: (
    { __typename?: 'LocalizableFile' }
    & LocalizableFileFragmentFragment
  ) }
);

export type BoothDividerContentRawElementFragmentFragment = (
  { __typename: 'BoothDividerContentElement' }
  & Pick<BoothDividerContentElement, 'id'>
);

export type BoothDividerMasterElementFragmentFragment = (
  { __typename: 'BoothDividerMasterElement' }
  & Pick<BoothDividerMasterElement, 'id'>
);

export type BoothImageContentRawElementFragmentFragment = (
  { __typename: 'BoothImageContentElement' }
  & Pick<BoothImageContentElement, 'id'>
  & { image: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ) }
);

export type BoothImageMasterElementFragmentFragment = (
  { __typename: 'BoothImageMasterElement' }
  & Pick<BoothImageMasterElement, 'id' | 'masterControlledImage'>
  & { image: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ) }
);

export type BoothFileContentElementFragmentFragment = (
  { __typename?: 'BoothFileContentElement' }
  & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | (
    { __typename?: 'BoothFileMasterElement' }
    & BoothFileMasterElementFragmentFragment
  ) | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | (
    { __typename?: 'BoothFileContentElement' }
    & BoothFileContentRawElementFragmentFragment
  ) | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothVideoContentElement' } }
  & BoothFileContentRawElementFragmentFragment
);

export type BoothImageContentElementFragmentFragment = (
  { __typename?: 'BoothImageContentElement' }
  & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | (
    { __typename?: 'BoothImageMasterElement' }
    & BoothImageMasterElementFragmentFragment
  ) | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | (
    { __typename?: 'BoothImageContentElement' }
    & BoothImageContentRawElementFragmentFragment
  ) | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothVideoContentElement' } }
  & BoothImageContentRawElementFragmentFragment
);

export type BoothQuestionContentElementFragmentFragment = (
  { __typename?: 'BoothQuestionContentElement' }
  & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageMasterElement' } | (
    { __typename?: 'BoothQuestionMasterElement' }
    & BoothQuestionMasterElementFragmentFragment
  ) | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothImageContentElement' } | (
    { __typename?: 'BoothQuestionContentElement' }
    & BoothQuestionContentRawElementFragmentFragment
  ) | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothVideoContentElement' } }
  & BoothQuestionContentRawElementFragmentFragment
);

export type BoothRichtextContentElementFragmentFragment = (
  { __typename?: 'BoothRichtextContentElement' }
  & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | (
    { __typename?: 'BoothRichtextMasterElement' }
    & BoothRichtextMasterElementFragmentFragment
  ) | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | (
    { __typename?: 'BoothRichtextContentElement' }
    & BoothRichtextContentRawElementFragmentFragment
  ) | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothVideoContentElement' } }
  & BoothRichtextContentRawElementFragmentFragment
);

export type BoothVideoContentElementFragmentFragment = (
  { __typename?: 'BoothVideoContentElement' }
  & { masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | (
    { __typename?: 'BoothVideoMasterElement' }
    & BoothVideoMasterElementFragmentFragment
  )>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | (
    { __typename?: 'BoothVideoContentElement' }
    & BoothVideoContentRawElementFragmentFragment
  ) }
  & BoothVideoContentRawElementFragmentFragment
);

export type BoothDividerContentElementFragmentFragment = (
  { __typename?: 'BoothDividerContentElement' }
  & { masterElement?: Maybe<(
    { __typename?: 'BoothDividerMasterElement' }
    & BoothDividerMasterElementFragmentFragment
  ) | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothVideoMasterElement' }>, rawElement: (
    { __typename?: 'BoothDividerContentElement' }
    & BoothDividerContentRawElementFragmentFragment
  ) | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothVideoContentElement' } }
  & BoothDividerContentRawElementFragmentFragment
);

type BoothContentElementFragment_BoothDividerContentElement_Fragment = (
  { __typename: 'BoothDividerContentElement' }
  & Pick<BoothDividerContentElement, 'id'>
  & BoothDividerContentElementFragmentFragment
);

type BoothContentElementFragment_BoothFileContentElement_Fragment = (
  { __typename: 'BoothFileContentElement' }
  & Pick<BoothFileContentElement, 'id'>
  & BoothFileContentElementFragmentFragment
);

type BoothContentElementFragment_BoothImageContentElement_Fragment = (
  { __typename: 'BoothImageContentElement' }
  & Pick<BoothImageContentElement, 'id'>
  & BoothImageContentElementFragmentFragment
);

type BoothContentElementFragment_BoothQuestionContentElement_Fragment = (
  { __typename: 'BoothQuestionContentElement' }
  & Pick<BoothQuestionContentElement, 'id'>
  & BoothQuestionContentElementFragmentFragment
);

type BoothContentElementFragment_BoothRichtextContentElement_Fragment = (
  { __typename: 'BoothRichtextContentElement' }
  & Pick<BoothRichtextContentElement, 'id'>
  & BoothRichtextContentElementFragmentFragment
);

type BoothContentElementFragment_BoothRootContentElement_Fragment = (
  { __typename: 'BoothRootContentElement' }
  & Pick<BoothRootContentElement, 'id'>
);

type BoothContentElementFragment_BoothTabContentElement_Fragment = (
  { __typename: 'BoothTabContentElement' }
  & Pick<BoothTabContentElement, 'id'>
);

type BoothContentElementFragment_BoothVideoContentElement_Fragment = (
  { __typename: 'BoothVideoContentElement' }
  & Pick<BoothVideoContentElement, 'id'>
  & BoothVideoContentElementFragmentFragment
);

export type BoothContentElementFragmentFragment = BoothContentElementFragment_BoothDividerContentElement_Fragment | BoothContentElementFragment_BoothFileContentElement_Fragment | BoothContentElementFragment_BoothImageContentElement_Fragment | BoothContentElementFragment_BoothQuestionContentElement_Fragment | BoothContentElementFragment_BoothRichtextContentElement_Fragment | BoothContentElementFragment_BoothRootContentElement_Fragment | BoothContentElementFragment_BoothTabContentElement_Fragment | BoothContentElementFragment_BoothVideoContentElement_Fragment;

export type BoothDesignElementFramgmentFragment = (
  { __typename?: 'BoothDesignElement' }
  & Pick<BoothDesignElement, 'id' | 'name' | 'validKinds' | 'kind' | 'color' | 'actionUrl'>
  & { image: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ), video: (
    { __typename?: 'LocalizableVideo' }
    & LocalizableVideoFragmentFragment
  ), pdf: (
    { __typename?: 'LocalizableFile' }
    & LocalizableFileFragmentFragment
  ), contentRichtextHtml: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ), thumbnailImage: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ) }
);

export type BoothDesignElementsFragmentFragment = (
  { __typename?: 'BoothDesignElementConnection' }
  & { nodes?: Maybe<Array<Maybe<(
    { __typename?: 'BoothDesignElement' }
    & BoothDesignElementFramgmentFragment
  )>>> }
);

export type BoothTierFragmentFragment = (
  { __typename?: 'BoothTier' }
  & Pick<BoothTier, 'id' | 'nameVisible'>
  & { name: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ), scale: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), booths: (
    { __typename?: 'BoothConnection' }
    & Pick<BoothConnection, 'totalCount'>
  ) }
);

export type LocalizableVideoFragmentFragment = (
  { __typename: 'LocalizableVideo' }
  & { values?: Maybe<Array<(
    { __typename?: 'LocalizableVideoValue' }
    & Pick<LocalizableVideoValue, 'locale'>
    & { value?: Maybe<(
      { __typename: 'Video' }
      & Pick<Video, 'filename' | 'fileUrl' | 'playbackUrl' | 'contentType'>
    )> }
  )>> }
);

export type LocalizableFileFragmentFragment = (
  { __typename: 'LocalizableFile' }
  & { values?: Maybe<Array<(
    { __typename?: 'LocalizableFileValue' }
    & Pick<LocalizableFileValue, 'locale'>
    & { value?: Maybe<(
      { __typename: 'File' }
      & Pick<File, 'filename' | 'fileUrl' | 'contentType'>
    )> }
  )>> }
);

export type LocalizableImageFragmentFragment = (
  { __typename: 'LocalizableImage' }
  & { values?: Maybe<Array<(
    { __typename?: 'LocalizableImageValue' }
    & Pick<LocalizableImageValue, 'locale'>
    & { value?: Maybe<(
      { __typename: 'Image' }
      & Pick<Image, 'filename' | 'originalImageUrl' | 'fileUrl' | 'contentType'>
    )> }
  )>> }
);

export type LocalizableStringFragmentFragment = (
  { __typename: 'LocalizableString' }
  & { values?: Maybe<Array<(
    { __typename?: 'LocalizableStringValue' }
    & Pick<LocalizableStringValue, 'locale' | 'value'>
  )>> }
);

export type DisplayMeasurementFragmentFragment = (
  { __typename?: 'DisplayMeasurement' }
  & Pick<DisplayMeasurement, 'dimension' | 'unit'>
);

type BoothTaxonomyFragment_BoothCategory_Fragment = (
  { __typename: 'BoothCategory' }
  & Pick<BoothCategory, 'id'>
  & { name: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ), parentSupercategory?: Maybe<(
    { __typename?: 'BoothSupercategory' }
    & Pick<BoothSupercategory, 'id'>
  )> }
);

type BoothTaxonomyFragment_BoothSupercategory_Fragment = (
  { __typename: 'BoothSupercategory' }
  & Pick<BoothSupercategory, 'id'>
  & { name: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ), parentSupercategory?: Maybe<(
    { __typename?: 'BoothSupercategory' }
    & Pick<BoothSupercategory, 'id'>
  )> }
);

export type BoothTaxonomyFragmentFragment = BoothTaxonomyFragment_BoothCategory_Fragment | BoothTaxonomyFragment_BoothSupercategory_Fragment;

export type BoothDesignColorFillMasterElementFragmentFragment = (
  { __typename?: 'BoothDesignColorFillMasterElement' }
  & Pick<BoothDesignColorFillMasterElement, 'keyName' | 'fillColor' | 'masterControlledFillColor'>
  & { maskImage: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ) }
  & BoothDesignMasterElementFragment_BoothDesignColorFillMasterElement_Fragment
);

export type BoothDesignColorFillRawElementFragmentFragment = (
  { __typename?: 'BoothDesignColorFillElement' }
  & Pick<BoothDesignColorFillElement, 'keyName' | 'fillColor'>
  & { maskImage: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ) }
  & BoothV2DesignElementFragment_BoothDesignColorFillElement_Fragment
);

export type BoothDesignColorFillElementFragmentFragment = (
  { __typename?: 'BoothDesignColorFillElement' }
  & Pick<BoothDesignColorFillElement, 'keyName' | 'fillColor'>
  & { masterElement?: Maybe<{ __typename?: 'BoothDesignCanvasMasterElement' } | (
    { __typename?: 'BoothDesignColorFillMasterElement' }
    & BoothDesignColorFillMasterElementFragmentFragment
  ) | { __typename?: 'BoothDesignContentAreaMasterElement' } | { __typename?: 'BoothDesignImageButtonMasterElement' } | { __typename?: 'BoothDesignImageMasterElement' } | { __typename?: 'BoothDesignNetworkingWidgetMasterElement' }>, maskImage: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ), rawElement?: Maybe<{ __typename?: 'BoothDesignCanvasElement' } | (
    { __typename?: 'BoothDesignColorFillElement' }
    & BoothDesignColorFillRawElementFragmentFragment
  ) | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' }> }
  & BoothV2DesignElementFragment_BoothDesignColorFillElement_Fragment
);

export type BoothDesignContentAreaMasterElementFragmentFragment = (
  { __typename?: 'BoothDesignContentAreaMasterElement' }
  & Pick<BoothDesignContentAreaMasterElement, 'keyName' | 'acceptedContentTypes'>
  & BoothDesignMasterElementFragment_BoothDesignContentAreaMasterElement_Fragment
);

export type BoothDesignContentAreaRawElementFragmentFragment = (
  { __typename?: 'BoothDesignContentAreaElement' }
  & Pick<BoothDesignContentAreaElement, 'keyName' | 'acceptedContentTypes' | 'contentType'>
  & { contentImage: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ), contentPdf: (
    { __typename?: 'LocalizableFile' }
    & LocalizableFileFragmentFragment
  ), contentRichtextHtml: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ), contentVideo: (
    { __typename?: 'LocalizableVideo' }
    & LocalizableVideoFragmentFragment
  ), thumbnailImage: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ) }
  & BoothV2DesignElementFragment_BoothDesignContentAreaElement_Fragment
);

export type BoothDesignContentAreaElementFragmentFragment = (
  { __typename?: 'BoothDesignContentAreaElement' }
  & Pick<BoothDesignContentAreaElement, 'keyName' | 'acceptedContentTypes' | 'contentType'>
  & { contentImage: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ), contentPdf: (
    { __typename?: 'LocalizableFile' }
    & LocalizableFileFragmentFragment
  ), contentRichtextHtml: (
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  ), contentVideo: (
    { __typename?: 'LocalizableVideo' }
    & LocalizableVideoFragmentFragment
  ), thumbnailImage: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ), masterElement?: Maybe<{ __typename?: 'BoothDesignCanvasMasterElement' } | { __typename?: 'BoothDesignColorFillMasterElement' } | (
    { __typename?: 'BoothDesignContentAreaMasterElement' }
    & BoothDesignContentAreaMasterElementFragmentFragment
  ) | { __typename?: 'BoothDesignImageButtonMasterElement' } | { __typename?: 'BoothDesignImageMasterElement' } | { __typename?: 'BoothDesignNetworkingWidgetMasterElement' }>, rawElement?: Maybe<{ __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | (
    { __typename?: 'BoothDesignContentAreaElement' }
    & BoothDesignContentAreaRawElementFragmentFragment
  ) | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' }> }
  & BoothV2DesignElementFragment_BoothDesignContentAreaElement_Fragment
);

type BoothDesignMasterElementFragment_BoothDesignCanvasMasterElement_Fragment = (
  { __typename: 'BoothDesignCanvasMasterElement' }
  & Pick<BoothDesignCanvasMasterElement, 'id'>
  & { height: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), width: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), positionX: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), positionY: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ) }
);

type BoothDesignMasterElementFragment_BoothDesignColorFillMasterElement_Fragment = (
  { __typename: 'BoothDesignColorFillMasterElement' }
  & Pick<BoothDesignColorFillMasterElement, 'id'>
  & { height: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), width: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), positionX: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), positionY: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ) }
);

type BoothDesignMasterElementFragment_BoothDesignContentAreaMasterElement_Fragment = (
  { __typename: 'BoothDesignContentAreaMasterElement' }
  & Pick<BoothDesignContentAreaMasterElement, 'id'>
  & { height: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), width: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), positionX: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), positionY: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ) }
);

type BoothDesignMasterElementFragment_BoothDesignImageButtonMasterElement_Fragment = (
  { __typename: 'BoothDesignImageButtonMasterElement' }
  & Pick<BoothDesignImageButtonMasterElement, 'id'>
  & { height: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), width: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), positionX: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), positionY: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ) }
);

type BoothDesignMasterElementFragment_BoothDesignImageMasterElement_Fragment = (
  { __typename: 'BoothDesignImageMasterElement' }
  & Pick<BoothDesignImageMasterElement, 'id'>
  & { height: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), width: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), positionX: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), positionY: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ) }
);

type BoothDesignMasterElementFragment_BoothDesignNetworkingWidgetMasterElement_Fragment = (
  { __typename: 'BoothDesignNetworkingWidgetMasterElement' }
  & Pick<BoothDesignNetworkingWidgetMasterElement, 'id'>
  & { height: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), width: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), positionX: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ), positionY: (
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  ) }
);

export type BoothDesignMasterElementFragmentFragment = BoothDesignMasterElementFragment_BoothDesignCanvasMasterElement_Fragment | BoothDesignMasterElementFragment_BoothDesignColorFillMasterElement_Fragment | BoothDesignMasterElementFragment_BoothDesignContentAreaMasterElement_Fragment | BoothDesignMasterElementFragment_BoothDesignImageButtonMasterElement_Fragment | BoothDesignMasterElementFragment_BoothDesignImageMasterElement_Fragment | BoothDesignMasterElementFragment_BoothDesignNetworkingWidgetMasterElement_Fragment;

type BoothV2DesignRawElementFragment_BoothDesignCanvasElement_Fragment = (
  { __typename: 'BoothDesignCanvasElement' }
  & Pick<BoothDesignCanvasElement, 'id'>
  & { height?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, width?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionX?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionY?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )> }
);

type BoothV2DesignRawElementFragment_BoothDesignColorFillElement_Fragment = (
  { __typename: 'BoothDesignColorFillElement' }
  & Pick<BoothDesignColorFillElement, 'id'>
  & { height?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, width?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionX?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionY?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )> }
);

type BoothV2DesignRawElementFragment_BoothDesignContentAreaElement_Fragment = (
  { __typename: 'BoothDesignContentAreaElement' }
  & Pick<BoothDesignContentAreaElement, 'id'>
  & { height?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, width?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionX?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionY?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )> }
);

type BoothV2DesignRawElementFragment_BoothDesignImageButtonElement_Fragment = (
  { __typename: 'BoothDesignImageButtonElement' }
  & Pick<BoothDesignImageButtonElement, 'id'>
  & { height?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, width?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionX?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionY?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )> }
);

type BoothV2DesignRawElementFragment_BoothDesignImageElement_Fragment = (
  { __typename: 'BoothDesignImageElement' }
  & Pick<BoothDesignImageElement, 'id'>
  & { height?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, width?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionX?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionY?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )> }
);

type BoothV2DesignRawElementFragment_BoothDesignNetworkingWidgetElement_Fragment = (
  { __typename: 'BoothDesignNetworkingWidgetElement' }
  & Pick<BoothDesignNetworkingWidgetElement, 'id'>
  & { height?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, width?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionX?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionY?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )> }
);

export type BoothV2DesignRawElementFragmentFragment = BoothV2DesignRawElementFragment_BoothDesignCanvasElement_Fragment | BoothV2DesignRawElementFragment_BoothDesignColorFillElement_Fragment | BoothV2DesignRawElementFragment_BoothDesignContentAreaElement_Fragment | BoothV2DesignRawElementFragment_BoothDesignImageButtonElement_Fragment | BoothV2DesignRawElementFragment_BoothDesignImageElement_Fragment | BoothV2DesignRawElementFragment_BoothDesignNetworkingWidgetElement_Fragment;

type BoothV2DesignElementFragment_BoothDesignCanvasElement_Fragment = (
  { __typename: 'BoothDesignCanvasElement' }
  & Pick<BoothDesignCanvasElement, 'id'>
  & { height?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, width?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionX?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionY?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, rawElement?: Maybe<(
    { __typename?: 'BoothDesignCanvasElement' }
    & BoothV2DesignRawElementFragment_BoothDesignCanvasElement_Fragment
  ) | (
    { __typename?: 'BoothDesignColorFillElement' }
    & BoothV2DesignRawElementFragment_BoothDesignColorFillElement_Fragment
  ) | (
    { __typename?: 'BoothDesignContentAreaElement' }
    & BoothV2DesignRawElementFragment_BoothDesignContentAreaElement_Fragment
  ) | (
    { __typename?: 'BoothDesignImageButtonElement' }
    & BoothV2DesignRawElementFragment_BoothDesignImageButtonElement_Fragment
  ) | (
    { __typename?: 'BoothDesignImageElement' }
    & BoothV2DesignRawElementFragment_BoothDesignImageElement_Fragment
  ) | (
    { __typename?: 'BoothDesignNetworkingWidgetElement' }
    & BoothV2DesignRawElementFragment_BoothDesignNetworkingWidgetElement_Fragment
  )> }
);

type BoothV2DesignElementFragment_BoothDesignColorFillElement_Fragment = (
  { __typename: 'BoothDesignColorFillElement' }
  & Pick<BoothDesignColorFillElement, 'id'>
  & { height?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, width?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionX?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionY?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, rawElement?: Maybe<(
    { __typename?: 'BoothDesignCanvasElement' }
    & BoothV2DesignRawElementFragment_BoothDesignCanvasElement_Fragment
  ) | (
    { __typename?: 'BoothDesignColorFillElement' }
    & BoothV2DesignRawElementFragment_BoothDesignColorFillElement_Fragment
  ) | (
    { __typename?: 'BoothDesignContentAreaElement' }
    & BoothV2DesignRawElementFragment_BoothDesignContentAreaElement_Fragment
  ) | (
    { __typename?: 'BoothDesignImageButtonElement' }
    & BoothV2DesignRawElementFragment_BoothDesignImageButtonElement_Fragment
  ) | (
    { __typename?: 'BoothDesignImageElement' }
    & BoothV2DesignRawElementFragment_BoothDesignImageElement_Fragment
  ) | (
    { __typename?: 'BoothDesignNetworkingWidgetElement' }
    & BoothV2DesignRawElementFragment_BoothDesignNetworkingWidgetElement_Fragment
  )> }
);

type BoothV2DesignElementFragment_BoothDesignContentAreaElement_Fragment = (
  { __typename: 'BoothDesignContentAreaElement' }
  & Pick<BoothDesignContentAreaElement, 'id'>
  & { height?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, width?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionX?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionY?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, rawElement?: Maybe<(
    { __typename?: 'BoothDesignCanvasElement' }
    & BoothV2DesignRawElementFragment_BoothDesignCanvasElement_Fragment
  ) | (
    { __typename?: 'BoothDesignColorFillElement' }
    & BoothV2DesignRawElementFragment_BoothDesignColorFillElement_Fragment
  ) | (
    { __typename?: 'BoothDesignContentAreaElement' }
    & BoothV2DesignRawElementFragment_BoothDesignContentAreaElement_Fragment
  ) | (
    { __typename?: 'BoothDesignImageButtonElement' }
    & BoothV2DesignRawElementFragment_BoothDesignImageButtonElement_Fragment
  ) | (
    { __typename?: 'BoothDesignImageElement' }
    & BoothV2DesignRawElementFragment_BoothDesignImageElement_Fragment
  ) | (
    { __typename?: 'BoothDesignNetworkingWidgetElement' }
    & BoothV2DesignRawElementFragment_BoothDesignNetworkingWidgetElement_Fragment
  )> }
);

type BoothV2DesignElementFragment_BoothDesignImageButtonElement_Fragment = (
  { __typename: 'BoothDesignImageButtonElement' }
  & Pick<BoothDesignImageButtonElement, 'id'>
  & { height?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, width?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionX?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionY?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, rawElement?: Maybe<(
    { __typename?: 'BoothDesignCanvasElement' }
    & BoothV2DesignRawElementFragment_BoothDesignCanvasElement_Fragment
  ) | (
    { __typename?: 'BoothDesignColorFillElement' }
    & BoothV2DesignRawElementFragment_BoothDesignColorFillElement_Fragment
  ) | (
    { __typename?: 'BoothDesignContentAreaElement' }
    & BoothV2DesignRawElementFragment_BoothDesignContentAreaElement_Fragment
  ) | (
    { __typename?: 'BoothDesignImageButtonElement' }
    & BoothV2DesignRawElementFragment_BoothDesignImageButtonElement_Fragment
  ) | (
    { __typename?: 'BoothDesignImageElement' }
    & BoothV2DesignRawElementFragment_BoothDesignImageElement_Fragment
  ) | (
    { __typename?: 'BoothDesignNetworkingWidgetElement' }
    & BoothV2DesignRawElementFragment_BoothDesignNetworkingWidgetElement_Fragment
  )> }
);

type BoothV2DesignElementFragment_BoothDesignImageElement_Fragment = (
  { __typename: 'BoothDesignImageElement' }
  & Pick<BoothDesignImageElement, 'id'>
  & { height?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, width?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionX?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionY?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, rawElement?: Maybe<(
    { __typename?: 'BoothDesignCanvasElement' }
    & BoothV2DesignRawElementFragment_BoothDesignCanvasElement_Fragment
  ) | (
    { __typename?: 'BoothDesignColorFillElement' }
    & BoothV2DesignRawElementFragment_BoothDesignColorFillElement_Fragment
  ) | (
    { __typename?: 'BoothDesignContentAreaElement' }
    & BoothV2DesignRawElementFragment_BoothDesignContentAreaElement_Fragment
  ) | (
    { __typename?: 'BoothDesignImageButtonElement' }
    & BoothV2DesignRawElementFragment_BoothDesignImageButtonElement_Fragment
  ) | (
    { __typename?: 'BoothDesignImageElement' }
    & BoothV2DesignRawElementFragment_BoothDesignImageElement_Fragment
  ) | (
    { __typename?: 'BoothDesignNetworkingWidgetElement' }
    & BoothV2DesignRawElementFragment_BoothDesignNetworkingWidgetElement_Fragment
  )> }
);

type BoothV2DesignElementFragment_BoothDesignNetworkingWidgetElement_Fragment = (
  { __typename: 'BoothDesignNetworkingWidgetElement' }
  & Pick<BoothDesignNetworkingWidgetElement, 'id'>
  & { height?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, width?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionX?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, positionY?: Maybe<(
    { __typename?: 'DisplayMeasurement' }
    & DisplayMeasurementFragmentFragment
  )>, rawElement?: Maybe<(
    { __typename?: 'BoothDesignCanvasElement' }
    & BoothV2DesignRawElementFragment_BoothDesignCanvasElement_Fragment
  ) | (
    { __typename?: 'BoothDesignColorFillElement' }
    & BoothV2DesignRawElementFragment_BoothDesignColorFillElement_Fragment
  ) | (
    { __typename?: 'BoothDesignContentAreaElement' }
    & BoothV2DesignRawElementFragment_BoothDesignContentAreaElement_Fragment
  ) | (
    { __typename?: 'BoothDesignImageButtonElement' }
    & BoothV2DesignRawElementFragment_BoothDesignImageButtonElement_Fragment
  ) | (
    { __typename?: 'BoothDesignImageElement' }
    & BoothV2DesignRawElementFragment_BoothDesignImageElement_Fragment
  ) | (
    { __typename?: 'BoothDesignNetworkingWidgetElement' }
    & BoothV2DesignRawElementFragment_BoothDesignNetworkingWidgetElement_Fragment
  )> }
);

export type BoothV2DesignElementFragmentFragment = BoothV2DesignElementFragment_BoothDesignCanvasElement_Fragment | BoothV2DesignElementFragment_BoothDesignColorFillElement_Fragment | BoothV2DesignElementFragment_BoothDesignContentAreaElement_Fragment | BoothV2DesignElementFragment_BoothDesignImageButtonElement_Fragment | BoothV2DesignElementFragment_BoothDesignImageElement_Fragment | BoothV2DesignElementFragment_BoothDesignNetworkingWidgetElement_Fragment;

export type BoothDesignImageButtonMasterElementFragmentFragment = (
  { __typename?: 'BoothDesignImageButtonMasterElement' }
  & Pick<BoothDesignImageButtonMasterElement, 'masterControlledAction' | 'actionExternalLinkUrl' | 'actionType' | 'keyName'>
  & { actionLocalizableExternalLinkUrl?: Maybe<(
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  )>, hoverStateImage: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ), normalStateImage: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ) }
  & BoothDesignMasterElementFragment_BoothDesignImageButtonMasterElement_Fragment
);

export type BoothDesignImageButtonRawElementFragmentFragment = (
  { __typename?: 'BoothDesignImageButtonElement' }
  & Pick<BoothDesignImageButtonElement, 'actionExternalLinkUrl' | 'actionType' | 'keyName'>
  & { actionLocalizableExternalLinkUrl?: Maybe<(
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  )>, hoverStateImage: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ), normalStateImage: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ) }
  & BoothV2DesignElementFragment_BoothDesignImageButtonElement_Fragment
);

export type BoothDesignImageButtonElementFragmentFragment = (
  { __typename?: 'BoothDesignImageButtonElement' }
  & Pick<BoothDesignImageButtonElement, 'actionExternalLinkUrl' | 'actionType' | 'keyName'>
  & { actionLocalizableExternalLinkUrl?: Maybe<(
    { __typename?: 'LocalizableString' }
    & LocalizableStringFragmentFragment
  )>, hoverStateImage: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ), normalStateImage: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ), masterElement?: Maybe<{ __typename?: 'BoothDesignCanvasMasterElement' } | { __typename?: 'BoothDesignColorFillMasterElement' } | { __typename?: 'BoothDesignContentAreaMasterElement' } | (
    { __typename?: 'BoothDesignImageButtonMasterElement' }
    & BoothDesignImageButtonMasterElementFragmentFragment
  ) | { __typename?: 'BoothDesignImageMasterElement' } | { __typename?: 'BoothDesignNetworkingWidgetMasterElement' }>, rawElement?: Maybe<{ __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | (
    { __typename?: 'BoothDesignImageButtonElement' }
    & BoothDesignImageButtonRawElementFragmentFragment
  ) | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' }> }
  & BoothV2DesignElementFragment_BoothDesignImageButtonElement_Fragment
);

export type BoothDesignImageMasterElementFragmentFragment = (
  { __typename?: 'BoothDesignImageMasterElement' }
  & { image: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ) }
  & BoothDesignMasterElementFragment_BoothDesignImageMasterElement_Fragment
);

export type BoothDesignImageRawElementFragmentFragment = (
  { __typename?: 'BoothDesignImageElement' }
  & { image: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ) }
  & BoothV2DesignElementFragment_BoothDesignImageElement_Fragment
);

export type BoothDesignImageElementFragmentFragment = (
  { __typename?: 'BoothDesignImageElement' }
  & { image: (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ), masterElement?: Maybe<{ __typename?: 'BoothDesignCanvasMasterElement' } | { __typename?: 'BoothDesignColorFillMasterElement' } | { __typename?: 'BoothDesignContentAreaMasterElement' } | { __typename?: 'BoothDesignImageButtonMasterElement' } | (
    { __typename?: 'BoothDesignImageMasterElement' }
    & BoothDesignImageMasterElementFragmentFragment
  ) | { __typename?: 'BoothDesignNetworkingWidgetMasterElement' }>, rawElement?: Maybe<{ __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignImageButtonElement' } | (
    { __typename?: 'BoothDesignImageElement' }
    & BoothDesignImageRawElementFragmentFragment
  ) | { __typename?: 'BoothDesignNetworkingWidgetElement' }> }
  & BoothV2DesignElementFragment_BoothDesignImageElement_Fragment
);

export type BoothDesignNetworkingWidgetMasterElementFragmentFragment = (
  { __typename?: 'BoothDesignNetworkingWidgetMasterElement' }
  & BoothDesignMasterElementFragment_BoothDesignNetworkingWidgetMasterElement_Fragment
);

export type BoothDesignNetworkingWidgetRawElementFragmentFragment = (
  { __typename?: 'BoothDesignNetworkingWidgetElement' }
  & BoothV2DesignElementFragment_BoothDesignNetworkingWidgetElement_Fragment
);

export type BoothDesignNetworkingWidgetElementFragmentFragment = (
  { __typename?: 'BoothDesignNetworkingWidgetElement' }
  & { masterElement?: Maybe<{ __typename?: 'BoothDesignCanvasMasterElement' } | { __typename?: 'BoothDesignColorFillMasterElement' } | { __typename?: 'BoothDesignContentAreaMasterElement' } | { __typename?: 'BoothDesignImageButtonMasterElement' } | (
    { __typename?: 'BoothDesignImageMasterElement' }
    & BoothDesignImageMasterElementFragmentFragment
  ) | { __typename?: 'BoothDesignNetworkingWidgetMasterElement' }>, rawElement?: Maybe<{ __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignImageButtonElement' } | (
    { __typename?: 'BoothDesignImageElement' }
    & BoothDesignImageRawElementFragmentFragment
  ) | { __typename?: 'BoothDesignNetworkingWidgetElement' }> }
  & BoothV2DesignElementFragment_BoothDesignNetworkingWidgetElement_Fragment
);

export type BoothDesignElementUpdateMutationVariables = Exact<{
  input: BoothDesignElementUpdateInput;
}>;


export type BoothDesignElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignElementUpdate?: Maybe<(
    { __typename?: 'BoothDesignElementUpdatePayload' }
    & Pick<BoothDesignElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothContentElementDeleteMutationVariables = Exact<{
  input: BoothContentElementDeleteInput;
}>;


export type BoothContentElementDeleteMutation = (
  { __typename?: 'Mutation' }
  & { boothContentElementDelete?: Maybe<(
    { __typename?: 'BoothContentElementDeletePayload' }
    & Pick<BoothContentElementDeletePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothDividerContentElementCreateMutationVariables = Exact<{
  input: BoothDividerContentElementCreateInput;
}>;


export type BoothDividerContentElementCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothDividerContentElementCreate?: Maybe<(
    { __typename?: 'BoothDividerContentElementCreatePayload' }
    & Pick<BoothDividerContentElementCreatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothDividerContentElementUpdateMutationVariables = Exact<{
  input: BoothDividerContentElementUpdateInput;
}>;


export type BoothDividerContentElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothDividerContentElementUpdate?: Maybe<(
    { __typename?: 'BoothDividerContentElementUpdatePayload' }
    & Pick<BoothDividerContentElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothDividerMasterElementCreateMutationVariables = Exact<{
  input: BoothDividerMasterElementCreateInput;
}>;


export type BoothDividerMasterElementCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothDividerMasterElementCreate?: Maybe<(
    { __typename?: 'BoothDividerMasterElementCreatePayload' }
    & Pick<BoothDividerMasterElementCreatePayload, 'success'>
    & { element: (
      { __typename?: 'BoothDividerMasterElement' }
      & BoothDividerMasterElementFragmentFragment
    ), errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothDividerMasterElementUpdateMutationVariables = Exact<{
  input: BoothDividerMasterElementUpdateInput;
}>;


export type BoothDividerMasterElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothDividerMasterElementUpdate?: Maybe<(
    { __typename?: 'BoothDividerMasterElementUpdatePayload' }
    & Pick<BoothDividerMasterElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothFileContentElementCreateMutationVariables = Exact<{
  input: BoothFileContentElementCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type BoothFileContentElementCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothFileContentElementCreate?: Maybe<(
    { __typename?: 'BoothFileContentElementCreatePayload' }
    & Pick<BoothFileContentElementCreatePayload, 'success'>
    & { element: (
      { __typename?: 'BoothFileContentElement' }
      & BoothFileContentElementFragmentFragment
    ), errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothFileContentElementUpdateMutationVariables = Exact<{
  input: BoothFileContentElementUpdateInput;
}>;


export type BoothFileContentElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothFileContentElementUpdate?: Maybe<(
    { __typename?: 'BoothFileContentElementUpdatePayload' }
    & Pick<BoothFileContentElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothFileMasterElementCreateMutationVariables = Exact<{
  input: BoothFileMasterElementCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type BoothFileMasterElementCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothFileMasterElementCreate?: Maybe<(
    { __typename?: 'BoothFileMasterElementCreatePayload' }
    & Pick<BoothFileMasterElementCreatePayload, 'success'>
    & { element: (
      { __typename?: 'BoothFileMasterElement' }
      & BoothFileMasterElementFragmentFragment
    ), errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothFileMasterElementUpdateMutationVariables = Exact<{
  input: BoothFileMasterElementUpdateInput;
}>;


export type BoothFileMasterElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothFileMasterElementUpdate?: Maybe<(
    { __typename?: 'BoothFileMasterElementUpdatePayload' }
    & Pick<BoothFileMasterElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothImageContentElementCreateMutationVariables = Exact<{
  input: BoothImageContentElementCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type BoothImageContentElementCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothImageContentElementCreate?: Maybe<(
    { __typename?: 'BoothImageContentElementCreatePayload' }
    & Pick<BoothImageContentElementCreatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothImageContentElementUpdateMutationVariables = Exact<{
  input: BoothImageContentElementUpdateInput;
}>;


export type BoothImageContentElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothImageContentElementUpdate?: Maybe<(
    { __typename?: 'BoothImageContentElementUpdatePayload' }
    & Pick<BoothImageContentElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothImageMasterElementCreateMutationVariables = Exact<{
  input: BoothImageMasterElementCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type BoothImageMasterElementCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothImageMasterElementCreate?: Maybe<(
    { __typename?: 'BoothImageMasterElementCreatePayload' }
    & Pick<BoothImageMasterElementCreatePayload, 'success'>
    & { element: (
      { __typename?: 'BoothImageMasterElement' }
      & BoothImageMasterElementFragmentFragment
    ), errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothImageMasterElementUpdateMutationVariables = Exact<{
  input: BoothImageMasterElementUpdateInput;
}>;


export type BoothImageMasterElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothImageMasterElementUpdate?: Maybe<(
    { __typename?: 'BoothImageMasterElementUpdatePayload' }
    & Pick<BoothImageMasterElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothMasterElementDeleteMutationVariables = Exact<{
  input: BoothMasterElementDeleteInput;
}>;


export type BoothMasterElementDeleteMutation = (
  { __typename?: 'Mutation' }
  & { boothMasterElementDelete?: Maybe<(
    { __typename?: 'BoothMasterElementDeletePayload' }
    & Pick<BoothMasterElementDeletePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothQuestionContentElementCreateMutationVariables = Exact<{
  input: BoothQuestionContentElementCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type BoothQuestionContentElementCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothQuestionContentElementCreate?: Maybe<(
    { __typename?: 'BoothQuestionContentElementCreatePayload' }
    & Pick<BoothQuestionContentElementCreatePayload, 'success'>
    & { element: (
      { __typename?: 'BoothQuestionContentElement' }
      & BoothQuestionContentElementFragmentFragment
    ), errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothQuestionContentElementUpdateMutationVariables = Exact<{
  input: BoothQuestionContentElementUpdateInput;
}>;


export type BoothQuestionContentElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothQuestionContentElementUpdate?: Maybe<(
    { __typename?: 'BoothQuestionContentElementUpdatePayload' }
    & Pick<BoothQuestionContentElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothQuestionMasterElementCreateMutationVariables = Exact<{
  input: BoothQuestionMasterElementCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type BoothQuestionMasterElementCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothQuestionMasterElementCreate?: Maybe<(
    { __typename?: 'BoothQuestionMasterElementCreatePayload' }
    & Pick<BoothQuestionMasterElementCreatePayload, 'success'>
    & { element: (
      { __typename?: 'BoothQuestionMasterElement' }
      & BoothQuestionMasterElementFragmentFragment
    ), errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothQuestionMasterElementUpdateMutationVariables = Exact<{
  input: BoothQuestionMasterElementUpdateInput;
}>;


export type BoothQuestionMasterElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothQuestionMasterElementUpdate?: Maybe<(
    { __typename?: 'BoothQuestionMasterElementUpdatePayload' }
    & Pick<BoothQuestionMasterElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothRichtextContentElementCreateMutationVariables = Exact<{
  input: BoothRichtextContentElementCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type BoothRichtextContentElementCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothRichtextContentElementCreate?: Maybe<(
    { __typename?: 'BoothRichtextContentElementCreatePayload' }
    & Pick<BoothRichtextContentElementCreatePayload, 'success'>
    & { element: (
      { __typename?: 'BoothRichtextContentElement' }
      & BoothRichtextContentElementFragmentFragment
    ), errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothRichtextContentElementUpdateMutationVariables = Exact<{
  input: BoothRichtextContentElementUpdateInput;
}>;


export type BoothRichtextContentElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothRichtextContentElementUpdate?: Maybe<(
    { __typename?: 'BoothRichtextContentElementUpdatePayload' }
    & Pick<BoothRichtextContentElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothRichtextMasterElementCreateMutationVariables = Exact<{
  input: BoothRichtextMasterElementCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type BoothRichtextMasterElementCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothRichtextMasterElementCreate?: Maybe<(
    { __typename?: 'BoothRichtextMasterElementCreatePayload' }
    & Pick<BoothRichtextMasterElementCreatePayload, 'success'>
    & { element: (
      { __typename?: 'BoothRichtextMasterElement' }
      & BoothRichtextMasterElementFragmentFragment
    ), errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothRichtextMasterElementUpdateMutationVariables = Exact<{
  input: BoothRichtextMasterElementUpdateInput;
}>;


export type BoothRichtextMasterElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothRichtextMasterElementUpdate?: Maybe<(
    { __typename?: 'BoothRichtextMasterElementUpdatePayload' }
    & Pick<BoothRichtextMasterElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothTabContentElementCreateMutationVariables = Exact<{
  input: BoothTabContentElementCreateInput;
}>;


export type BoothTabContentElementCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothTabContentElementCreate?: Maybe<(
    { __typename?: 'BoothTabContentElementCreatePayload' }
    & Pick<BoothTabContentElementCreatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothTabContentElementUpdateMutationVariables = Exact<{
  input: BoothTabContentElementUpdateInput;
}>;


export type BoothTabContentElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothTabContentElementUpdate?: Maybe<(
    { __typename?: 'BoothTabContentElementUpdatePayload' }
    & Pick<BoothTabContentElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothTabMasterElementCreateMutationVariables = Exact<{
  input: BoothTabMasterElementCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type BoothTabMasterElementCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothTabMasterElementCreate?: Maybe<(
    { __typename?: 'BoothTabMasterElementCreatePayload' }
    & Pick<BoothTabMasterElementCreatePayload, 'success'>
    & { element: (
      { __typename?: 'BoothTabMasterElement' }
      & Pick<BoothTabMasterElement, 'id' | 'masterControlledTitle' | 'masterControlledBody'>
      & { title: (
        { __typename?: 'LocalizableString' }
        & LocalizableStringFragmentFragment
      ) }
    ), errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothTabMasterElementUpdateMutationVariables = Exact<{
  input: BoothTabMasterElementUpdateInput;
}>;


export type BoothTabMasterElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothTabMasterElementUpdate?: Maybe<(
    { __typename?: 'BoothTabMasterElementUpdatePayload' }
    & Pick<BoothTabMasterElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothVideoContentElementCreateMutationVariables = Exact<{
  input: BoothVideoContentElementCreateInput;
}>;


export type BoothVideoContentElementCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothVideoContentElementCreate?: Maybe<(
    { __typename?: 'BoothVideoContentElementCreatePayload' }
    & Pick<BoothVideoContentElementCreatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothVideoContentElementUpdateMutationVariables = Exact<{
  input: BoothVideoContentElementUpdateInput;
}>;


export type BoothVideoContentElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothVideoContentElementUpdate?: Maybe<(
    { __typename?: 'BoothVideoContentElementUpdatePayload' }
    & Pick<BoothVideoContentElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothVideoMasterElementCreateMutationVariables = Exact<{
  input: BoothVideoMasterElementCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type BoothVideoMasterElementCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothVideoMasterElementCreate?: Maybe<(
    { __typename?: 'BoothVideoMasterElementCreatePayload' }
    & Pick<BoothVideoMasterElementCreatePayload, 'success'>
    & { element: (
      { __typename?: 'BoothVideoMasterElement' }
      & BoothVideoMasterElementFragmentFragment
    ), errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothVideoMasterElementUpdateMutationVariables = Exact<{
  input: BoothVideoMasterElementUpdateInput;
}>;


export type BoothVideoMasterElementUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothVideoMasterElementUpdate?: Maybe<(
    { __typename?: 'BoothVideoMasterElementUpdatePayload' }
    & Pick<BoothVideoMasterElementUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type ConfirmationEmailResendMutationVariables = Exact<{
  input: ConfirmationEmailResendInput;
}>;


export type ConfirmationEmailResendMutation = (
  { __typename?: 'Mutation' }
  & { confirmationEmailResend?: Maybe<(
    { __typename?: 'ConfirmationEmailResendPayload' }
    & Pick<ConfirmationEmailResendPayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type GroupChatJoinMutationVariables = Exact<{
  input: GroupChatJoinInput;
}>;


export type GroupChatJoinMutation = (
  { __typename?: 'Mutation' }
  & { groupChatJoin?: Maybe<(
    { __typename?: 'GroupChatJoinPayload' }
    & Pick<GroupChatJoinPayload, 'success'>
  )> }
);

export type PushNotificationMutationVariables = Exact<{
  input: PushNotificationInput;
}>;


export type PushNotificationMutation = (
  { __typename?: 'Mutation' }
  & { pushNotification?: Maybe<(
    { __typename?: 'PushNotificationPayload' }
    & Pick<PushNotificationPayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type UploadFileMutationVariables = Exact<{
  input: UploadFileInput;
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadFile?: Maybe<(
    { __typename?: 'UploadFilePayload' }
    & Pick<UploadFilePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothUpdateMutationVariables = Exact<{
  input: BoothUpdateInput;
}>;


export type BoothUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothUpdate?: Maybe<(
    { __typename?: 'BoothUpdatePayload' }
    & Pick<BoothUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothSupercategoryCreateMutationVariables = Exact<{
  input: BoothSupercategoryCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type BoothSupercategoryCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothSupercategoryCreate?: Maybe<(
    { __typename?: 'BoothSupercategoryCreatePayload' }
    & Pick<BoothSupercategoryCreatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )>, boothSupercategory: (
      { __typename?: 'BoothSupercategory' }
      & BoothTaxonomyFragment_BoothSupercategory_Fragment
    ) }
  )> }
);

export type BoothSupercategoryUpdateMutationVariables = Exact<{
  input: BoothSupercategoryUpdateInput;
}>;


export type BoothSupercategoryUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothSupercategoryUpdate?: Maybe<(
    { __typename?: 'BoothSupercategoryUpdatePayload' }
    & Pick<BoothSupercategoryUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothCategoryCreateMutationVariables = Exact<{
  input: BoothCategoryCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type BoothCategoryCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothCategoryCreate?: Maybe<(
    { __typename?: 'BoothCategoryCreatePayload' }
    & Pick<BoothCategoryCreatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )>, boothCategory: (
      { __typename?: 'BoothCategory' }
      & BoothTaxonomyFragment_BoothCategory_Fragment
    ) }
  )> }
);

export type BoothCategoryUpdateMutationVariables = Exact<{
  input: BoothCategoryUpdateInput;
}>;


export type BoothCategoryUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothCategoryUpdate?: Maybe<(
    { __typename?: 'BoothCategoryUpdatePayload' }
    & Pick<BoothCategoryUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothTaxonomyDeleteMutationVariables = Exact<{
  input: BoothTaxonomyDeleteInput;
}>;


export type BoothTaxonomyDeleteMutation = (
  { __typename?: 'Mutation' }
  & { boothTaxonomyDelete?: Maybe<(
    { __typename?: 'BoothTaxonomyDeletePayload' }
    & Pick<BoothTaxonomyDeletePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothDesignUpdateMutationVariables = Exact<{
  input: BoothDesignUpdateInput;
}>;


export type BoothDesignUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignUpdate?: Maybe<(
    { __typename?: 'BoothDesignUpdatePayload' }
    & Pick<BoothDesignUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothRepresentativeUpdateMutationVariables = Exact<{
  input: BoothRepresentativeUpdateInput;
}>;


export type BoothRepresentativeUpdateMutation = (
  { __typename?: 'Mutation' }
  & { boothRepresentativeUpdate?: Maybe<(
    { __typename?: 'BoothRepresentativeUpdatePayload' }
    & Pick<BoothRepresentativeUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type EventUpdateMutationVariables = Exact<{
  input: EventUpdateInput;
}>;


export type EventUpdateMutation = (
  { __typename?: 'Mutation' }
  & { eventUpdate?: Maybe<(
    { __typename?: 'EventUpdatePayload' }
    & Pick<EventUpdatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothDesignCreateMutationVariables = Exact<{
  input: BoothDesignCreateInput;
}>;


export type BoothDesignCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignCreate?: Maybe<(
    { __typename?: 'BoothDesignCreatePayload' }
    & Pick<BoothDesignCreatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )>, boothDesign: (
      { __typename?: 'BoothDesign' }
      & Pick<BoothDesign, 'id'>
    ) }
  )> }
);

export type BoothDesignDeleteMutationVariables = Exact<{
  input: BoothDesignDeleteInput;
}>;


export type BoothDesignDeleteMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignDelete?: Maybe<(
    { __typename?: 'BoothDesignDeletePayload' }
    & Pick<BoothDesignDeletePayload, 'success'>
    & { errors: Array<(
      { __typename: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothDesignImageButtonMasterElementCreateMutationVariables = Exact<{
  input: BoothDesignImageButtonMasterElementCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type BoothDesignImageButtonMasterElementCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothDesignImageButtonMasterElementCreate?: Maybe<(
    { __typename?: 'BoothDesignImageButtonMasterElementCreatePayload' }
    & Pick<BoothDesignImageButtonMasterElementCreatePayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )>, element: (
      { __typename?: 'BoothDesignImageButtonMasterElement' }
      & BoothDesignImageButtonMasterElementFragmentFragment
    ) }
  )> }
);

export type BoothCreateMutationVariables = Exact<{
  input: BoothCreateInput;
  locales: Array<Scalars['Locale']>;
}>;


export type BoothCreateMutation = (
  { __typename?: 'Mutation' }
  & { boothCreate?: Maybe<(
    { __typename?: 'BoothCreatePayload' }
    & Pick<BoothCreatePayload, 'success'>
    & { booth: (
      { __typename?: 'Booth' }
      & Pick<Booth, 'id' | 'cmsUrl'>
      & { name: (
        { __typename?: 'LocalizableString' }
        & LocalizableStringFragmentFragment
      ) }
    ), errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type BoothReorderMutationVariables = Exact<{
  input: BoothReorderInput;
}>;


export type BoothReorderMutation = (
  { __typename?: 'Mutation' }
  & { boothReorder?: Maybe<(
    { __typename?: 'BoothReorderPayload' }
    & Pick<BoothReorderPayload, 'success'>
    & { errors: Array<(
      { __typename?: 'StandardError' }
      & Pick<StandardError, 'message'>
    )> }
  )> }
);

export type GetBoothBasicInfoQueryVariables = Exact<{
  boothId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetBoothBasicInfoQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename: 'Booth' }
    & Pick<Booth, 'id' | 'appliedDesignCodename' | 'meetupExhibitorManagementUrl' | 'reportToken' | 'meetupEnabled' | 'previewUrl'>
    & { hall: (
      { __typename: 'Hall' }
      & Pick<Hall, 'id'>
    ), boothDesign?: Maybe<(
      { __typename?: 'BoothDesign' }
      & Pick<BoothDesign, 'id'>
    )>, name: (
      { __typename?: 'LocalizableString' }
      & LocalizableStringFragmentFragment
    ), event: (
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'features'>
      & { name?: Maybe<(
        { __typename?: 'LocalizableString' }
        & LocalizableStringFragmentFragment
      )> }
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothCategoriesQueryVariables = Exact<{
  boothId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
}>;


export type GetBoothCategoriesQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & { categories: (
      { __typename?: 'BoothCategoryConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename: 'BoothCategory' }
        & Pick<BoothCategory, 'id'>
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
      ) }
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothDesignElementsQueryVariables = Exact<{
  boothId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetBoothDesignElementsQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & { designElements: (
      { __typename?: 'BoothDesignElementConnection' }
      & BoothDesignElementsFragmentFragment
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothRootContentElementTabsQueryVariables = Exact<{
  boothId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetBoothRootContentElementTabsQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & { rootContentElement: (
      { __typename: 'BoothRootContentElement' }
      & { tabs: (
        { __typename?: 'BoothTabContentElementConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'BoothTabContentElement' }
          & Pick<BoothTabContentElement, 'id'>
          & { title: (
            { __typename?: 'LocalizableString' }
            & LocalizableStringFragmentFragment
          ), masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | (
            { __typename?: 'BoothTabMasterElement' }
            & Pick<BoothTabMasterElement, 'masterControlledTitle' | 'masterControlledBody'>
          ) | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | (
            { __typename?: 'BoothTabContentElement' }
            & { title: (
              { __typename?: 'LocalizableString' }
              & LocalizableStringFragmentFragment
            ) }
          ) | { __typename?: 'BoothVideoContentElement' } }
        )>>> }
      ) }
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothRootContentElementTabsBodyNodesIdListQueryVariables = Exact<{
  tabId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
}>;


export type GetBoothRootContentElementTabsBodyNodesIdListQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | (
    { __typename?: 'BoothTabContentElement' }
    & { body: (
      { __typename?: 'BoothContentElementConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BoothDividerContentElement' }
        & Pick<BoothDividerContentElement, 'id'>
      ) | (
        { __typename?: 'BoothFileContentElement' }
        & Pick<BoothFileContentElement, 'id'>
      ) | (
        { __typename?: 'BoothImageContentElement' }
        & Pick<BoothImageContentElement, 'id'>
      ) | (
        { __typename?: 'BoothQuestionContentElement' }
        & Pick<BoothQuestionContentElement, 'id'>
      ) | (
        { __typename?: 'BoothRichtextContentElement' }
        & Pick<BoothRichtextContentElement, 'id'>
      ) | (
        { __typename?: 'BoothRootContentElement' }
        & Pick<BoothRootContentElement, 'id'>
      ) | (
        { __typename?: 'BoothTabContentElement' }
        & Pick<BoothTabContentElement, 'id'>
      ) | (
        { __typename?: 'BoothVideoContentElement' }
        & Pick<BoothVideoContentElement, 'id'>
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
    ) }
  ) | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothContentElementByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetBoothContentElementByIdQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | (
    { __typename?: 'BoothDividerContentElement' }
    & BoothContentElementFragment_BoothDividerContentElement_Fragment
  ) | { __typename?: 'BoothDividerMasterElement' } | (
    { __typename?: 'BoothFileContentElement' }
    & BoothContentElementFragment_BoothFileContentElement_Fragment
  ) | { __typename?: 'BoothFileMasterElement' } | (
    { __typename?: 'BoothImageContentElement' }
    & BoothContentElementFragment_BoothImageContentElement_Fragment
  ) | { __typename?: 'BoothImageMasterElement' } | (
    { __typename?: 'BoothQuestionContentElement' }
    & BoothContentElementFragment_BoothQuestionContentElement_Fragment
  ) | { __typename?: 'BoothQuestionMasterElement' } | (
    { __typename?: 'BoothRichtextContentElement' }
    & BoothContentElementFragment_BoothRichtextContentElement_Fragment
  ) | { __typename?: 'BoothRichtextMasterElement' } | (
    { __typename?: 'BoothRootContentElement' }
    & BoothContentElementFragment_BoothRootContentElement_Fragment
  ) | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | (
    { __typename?: 'BoothTabContentElement' }
    & BoothContentElementFragment_BoothTabContentElement_Fragment
  ) | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | (
    { __typename?: 'BoothVideoContentElement' }
    & BoothContentElementFragment_BoothVideoContentElement_Fragment
  ) | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothContentElementsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']>;
  locales: Array<Scalars['Locale']>;
}>;


export type GetBoothContentElementsByIdsQuery = (
  { __typename?: 'Query' }
  & { nodes: Array<Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | (
    { __typename?: 'BoothDividerContentElement' }
    & BoothContentElementFragment_BoothDividerContentElement_Fragment
  ) | { __typename?: 'BoothDividerMasterElement' } | (
    { __typename?: 'BoothFileContentElement' }
    & BoothContentElementFragment_BoothFileContentElement_Fragment
  ) | { __typename?: 'BoothFileMasterElement' } | (
    { __typename?: 'BoothImageContentElement' }
    & BoothContentElementFragment_BoothImageContentElement_Fragment
  ) | { __typename?: 'BoothImageMasterElement' } | (
    { __typename?: 'BoothQuestionContentElement' }
    & BoothContentElementFragment_BoothQuestionContentElement_Fragment
  ) | { __typename?: 'BoothQuestionMasterElement' } | (
    { __typename?: 'BoothRichtextContentElement' }
    & BoothContentElementFragment_BoothRichtextContentElement_Fragment
  ) | { __typename?: 'BoothRichtextMasterElement' } | (
    { __typename?: 'BoothRootContentElement' }
    & BoothContentElementFragment_BoothRootContentElement_Fragment
  ) | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | (
    { __typename?: 'BoothTabContentElement' }
    & BoothContentElementFragment_BoothTabContentElement_Fragment
  ) | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | (
    { __typename?: 'BoothVideoContentElement' }
    & BoothContentElementFragment_BoothVideoContentElement_Fragment
  ) | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }>> }
);

export type GetBoothLocalesQueryVariables = Exact<{
  boothId: Scalars['ID'];
}>;


export type GetBoothLocalesQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & { event: (
      { __typename?: 'Event' }
      & Pick<Event, 'locales'>
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothDesignElementsIdListQueryVariables = Exact<{
  boothId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
}>;


export type GetBoothDesignElementsIdListQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & { designElements: (
      { __typename?: 'BoothDesignElementConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BoothDesignElement' }
        & Pick<BoothDesignElement, 'id'>
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothDesignElementQueryVariables = Exact<{
  id: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetBoothDesignElementQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | (
    { __typename?: 'BoothDesignElement' }
    & BoothDesignElementFramgmentFragment
  ) | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothDesignElementsByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']>;
  locales: Array<Scalars['Locale']>;
}>;


export type GetBoothDesignElementsByIdsQuery = (
  { __typename?: 'Query' }
  & { nodes: Array<Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | (
    { __typename?: 'BoothDesignElement' }
    & BoothDesignElementFramgmentFragment
  ) | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }>> }
);

export type GetBoothEventTaxonomiesListQueryVariables = Exact<{
  boothId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
  locales: Array<Scalars['Locale']>;
}>;


export type GetBoothEventTaxonomiesListQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & { event: (
      { __typename?: 'Event' }
      & { boothTaxonomies: (
        { __typename?: 'BoothTaxonomyConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename: 'BoothCategory' }
          & Pick<BoothCategory, 'id'>
          & { name: (
            { __typename?: 'LocalizableString' }
            & LocalizableStringFragmentFragment
            & LocalizableStringFragmentFragment
          ), supercategory?: Maybe<(
            { __typename?: 'BoothSupercategory' }
            & Pick<BoothSupercategory, 'id'>
          )> }
        ) | (
          { __typename: 'BoothSupercategory' }
          & Pick<BoothSupercategory, 'id'>
          & { name: (
            { __typename?: 'LocalizableString' }
            & LocalizableStringFragmentFragment
            & LocalizableStringFragmentFragment
          ) }
        )>>>, pageInfo: (
          { __typename?: 'PageInfo' }
          & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
        ) }
      ) }
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothEventSupercategorySubcategoriesQueryVariables = Exact<{
  id: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
}>;


export type GetBoothEventSupercategorySubcategoriesQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | (
    { __typename?: 'BoothSupercategory' }
    & Pick<BoothSupercategory, 'id'>
    & { subcategories: (
      { __typename?: 'BoothTaxonomyConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BoothCategory' }
        & Pick<BoothCategory, 'id'>
      ) | (
        { __typename?: 'BoothSupercategory' }
        & Pick<BoothSupercategory, 'id'>
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
    ) }
  ) | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetEventBasicInfoQueryVariables = Exact<{
  eventId: Scalars['ID'];
}>;


export type GetEventBasicInfoQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'features' | 'locales' | 'defaultLocale' | 'domain' | 'reportToken' | 'meetupEventId'>
  ) | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetEventDetailsQueryVariables = Exact<{
  eventId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetEventDetailsQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | (
    { __typename?: 'Event' }
    & { name?: Maybe<(
      { __typename?: 'LocalizableString' }
      & LocalizableStringFragmentFragment
    )> }
  ) | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetNodeFileFieldQueryVariables = Exact<{
  objectId: Scalars['ID'];
  fieldName: Scalars['String'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetNodeFileFieldQuery = (
  { __typename?: 'Query' }
  & { localizableFileableBy?: Maybe<(
    { __typename?: 'LocalizableFile' }
    & LocalizableFileFragmentFragment
  ) | (
    { __typename?: 'LocalizableImage' }
    & LocalizableImageFragmentFragment
  ) | (
    { __typename?: 'LocalizableVideo' }
    & LocalizableVideoFragmentFragment
  )> }
);

export type GetBoothEditEventLocalesQueryVariables = Exact<{
  boothId: Scalars['ID'];
}>;


export type GetBoothEditEventLocalesQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & { event: (
      { __typename?: 'Event' }
      & Pick<Event, 'defaultLocale' | 'locales' | 'domain'>
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothEditBasicDataQueryVariables = Exact<{
  boothId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetBoothEditBasicDataQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & Pick<Booth, 'id' | 'reportToken' | 'previewUrl'>
    & { name: (
      { __typename?: 'LocalizableString' }
      & LocalizableStringFragmentFragment
    ), event: (
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'features'>
      & { name?: Maybe<(
        { __typename?: 'LocalizableString' }
        & LocalizableStringFragmentFragment
      )> }
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothEditContentElementTabQueryVariables = Exact<{
  tabId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetBoothEditContentElementTabQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | (
    { __typename?: 'BoothTabContentElement' }
    & Pick<BoothTabContentElement, 'id'>
    & { title: (
      { __typename?: 'LocalizableString' }
      & LocalizableStringFragmentFragment
    ), masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | (
      { __typename?: 'BoothTabMasterElement' }
      & Pick<BoothTabMasterElement, 'masterControlledTitle' | 'masterControlledBody'>
    ) | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | (
      { __typename?: 'BoothTabContentElement' }
      & { title: (
        { __typename?: 'LocalizableString' }
        & LocalizableStringFragmentFragment
      ) }
    ) | { __typename?: 'BoothVideoContentElement' } }
  ) | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothEditContentElementTabBodyNodesQueryVariables = Exact<{
  tabId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  after?: Maybe<Scalars['String']>;
}>;


export type GetBoothEditContentElementTabBodyNodesQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | (
    { __typename?: 'BoothTabContentElement' }
    & { body: (
      { __typename?: 'BoothContentElementConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BoothDividerContentElement' }
        & Pick<BoothDividerContentElement, 'id'>
      ) | (
        { __typename?: 'BoothFileContentElement' }
        & Pick<BoothFileContentElement, 'id'>
      ) | (
        { __typename?: 'BoothImageContentElement' }
        & Pick<BoothImageContentElement, 'id'>
      ) | (
        { __typename?: 'BoothQuestionContentElement' }
        & Pick<BoothQuestionContentElement, 'id'>
      ) | (
        { __typename?: 'BoothRichtextContentElement' }
        & Pick<BoothRichtextContentElement, 'id'>
      ) | (
        { __typename?: 'BoothRootContentElement' }
        & Pick<BoothRootContentElement, 'id'>
      ) | (
        { __typename?: 'BoothTabContentElement' }
        & Pick<BoothTabContentElement, 'id'>
      ) | (
        { __typename?: 'BoothVideoContentElement' }
        & Pick<BoothVideoContentElement, 'id'>
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
    ) }
  ) | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothEditContentElementQueryVariables = Exact<{
  id: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetBoothEditContentElementQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | (
    { __typename?: 'BoothDividerContentElement' }
    & BoothContentElementFragment_BoothDividerContentElement_Fragment
  ) | { __typename?: 'BoothDividerMasterElement' } | (
    { __typename?: 'BoothFileContentElement' }
    & BoothContentElementFragment_BoothFileContentElement_Fragment
  ) | { __typename?: 'BoothFileMasterElement' } | (
    { __typename?: 'BoothImageContentElement' }
    & BoothContentElementFragment_BoothImageContentElement_Fragment
  ) | { __typename?: 'BoothImageMasterElement' } | (
    { __typename?: 'BoothQuestionContentElement' }
    & BoothContentElementFragment_BoothQuestionContentElement_Fragment
  ) | { __typename?: 'BoothQuestionMasterElement' } | (
    { __typename?: 'BoothRichtextContentElement' }
    & BoothContentElementFragment_BoothRichtextContentElement_Fragment
  ) | { __typename?: 'BoothRichtextMasterElement' } | (
    { __typename?: 'BoothRootContentElement' }
    & BoothContentElementFragment_BoothRootContentElement_Fragment
  ) | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | (
    { __typename?: 'BoothTabContentElement' }
    & BoothContentElementFragment_BoothTabContentElement_Fragment
  ) | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | (
    { __typename?: 'BoothVideoContentElement' }
    & BoothContentElementFragment_BoothVideoContentElement_Fragment
  ) | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothEditContentElementTabListQueryVariables = Exact<{
  boothId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetBoothEditContentElementTabListQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & { rootContentElement: (
      { __typename: 'BoothRootContentElement' }
      & { tabs: (
        { __typename?: 'BoothTabContentElementConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'BoothTabContentElement' }
          & Pick<BoothTabContentElement, 'id'>
          & { title: (
            { __typename?: 'LocalizableString' }
            & LocalizableStringFragmentFragment
          ), masterElement?: Maybe<{ __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootMasterElement' } | (
            { __typename?: 'BoothTabMasterElement' }
            & Pick<BoothTabMasterElement, 'masterControlledTitle' | 'masterControlledBody'>
          ) | { __typename?: 'BoothVideoMasterElement' }>, rawElement: { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRootContentElement' } | (
            { __typename?: 'BoothTabContentElement' }
            & { title: (
              { __typename?: 'LocalizableString' }
              & LocalizableStringFragmentFragment
            ) }
          ) | { __typename?: 'BoothVideoContentElement' } }
        )>>> }
      ) }
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothEditInfoQueryVariables = Exact<{
  boothId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetBoothEditInfoQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'Booth' }
    & Pick<Booth, 'website' | 'boothNumber'>
    & { logo: (
      { __typename?: 'LocalizableImage' }
      & LocalizableImageFragmentFragment
    ), name: (
      { __typename?: 'LocalizableString' }
      & LocalizableStringFragmentFragment
    ), exhibitionName: (
      { __typename?: 'LocalizableString' }
      & LocalizableStringFragmentFragment
    ), description: (
      { __typename?: 'LocalizableString' }
      & LocalizableStringFragmentFragment
    ), tier: (
      { __typename?: 'BoothTier' }
      & { name: (
        { __typename?: 'LocalizableString' }
        & LocalizableStringFragmentFragment
      ) }
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothIdListByEventIdQueryVariables = Exact<{
  eventId: Scalars['ID'];
  after?: Maybe<Scalars['String']>;
}>;


export type GetBoothIdListByEventIdQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | (
    { __typename?: 'Event' }
    & { booths: (
      { __typename?: 'BoothConnection' }
      & Pick<BoothConnection, 'totalCount'>
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Booth' }
        & Pick<Booth, 'id'>
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
    ) }
  ) | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetBoothRowsDetailByIdsQueryVariables = Exact<{
  boothIds: Array<Scalars['ID']>;
  locales: Array<Scalars['Locale']>;
}>;


export type GetBoothRowsDetailByIdsQuery = (
  { __typename?: 'Query' }
  & { nodes: Array<Maybe<(
    { __typename?: 'Booth' }
    & Pick<Booth, 'id' | 'cmsUrl' | 'rowOrder'>
    & { name: (
      { __typename?: 'LocalizableString' }
      & LocalizableStringFragmentFragment
    ), logo: (
      { __typename?: 'LocalizableImage' }
      & LocalizableImageFragmentFragment
    ) }
  ) | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }>> }
);

export type GetBoothCountQueryVariables = Exact<{
  eventId: Scalars['ID'];
  locales: Array<Maybe<Scalars['Locale']>>;
}>;


export type GetBoothCountQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | (
    { __typename?: 'Event' }
    & { booths: (
      { __typename?: 'BoothConnection' }
      & Pick<BoothConnection, 'totalCount'>
    ) }
  ) | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetHallListQueryVariables = Exact<{
  eventId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetHallListQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | (
    { __typename?: 'Event' }
    & { halls: (
      { __typename?: 'HallConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'Hall' }
        & Pick<Hall, 'id'>
        & { name: (
          { __typename?: 'LocalizableString' }
          & LocalizableStringFragmentFragment
        ) }
      )>>> }
    ) }
  ) | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type GetTierListQueryVariables = Exact<{
  eventId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
}>;


export type GetTierListQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | (
    { __typename?: 'Event' }
    & { boothTiers: (
      { __typename?: 'BoothTierConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BoothTier' }
        & Pick<BoothTier, 'id'>
        & { name: (
          { __typename?: 'LocalizableString' }
          & LocalizableStringFragmentFragment
        ) }
      )>>> }
    ) }
  ) | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type BoothDesignSelectingGetBoothDesignLayersListByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  after?: Maybe<Scalars['String']>;
}>;


export type BoothDesignSelectingGetBoothDesignLayersListByIdQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | (
    { __typename?: 'BoothDesign' }
    & { canvas?: Maybe<(
      { __typename?: 'BoothDesignCanvasMasterElement' }
      & { layers: (
        { __typename?: 'BoothDesignMasterElementConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'BoothDesignCanvasMasterElement' }
          & Pick<BoothDesignCanvasMasterElement, 'id'>
        ) | (
          { __typename?: 'BoothDesignColorFillMasterElement' }
          & Pick<BoothDesignColorFillMasterElement, 'id'>
          & BoothDesignColorFillMasterElementFragmentFragment
        ) | (
          { __typename?: 'BoothDesignContentAreaMasterElement' }
          & Pick<BoothDesignContentAreaMasterElement, 'id'>
          & BoothDesignContentAreaMasterElementFragmentFragment
        ) | (
          { __typename?: 'BoothDesignImageButtonMasterElement' }
          & Pick<BoothDesignImageButtonMasterElement, 'id'>
          & BoothDesignImageButtonMasterElementFragmentFragment
        ) | (
          { __typename?: 'BoothDesignImageMasterElement' }
          & Pick<BoothDesignImageMasterElement, 'id'>
          & BoothDesignImageMasterElementFragmentFragment
        ) | (
          { __typename?: 'BoothDesignNetworkingWidgetMasterElement' }
          & Pick<BoothDesignNetworkingWidgetMasterElement, 'id'>
          & BoothDesignNetworkingWidgetMasterElementFragmentFragment
        )>>>, pageInfo: (
          { __typename?: 'PageInfo' }
          & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
        ) }
      ) }
    )> }
  ) | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | { __typename?: 'Event' } | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export type BoothDesignSelectingGetBoothDesignsNameListQueryVariables = Exact<{
  eventId: Scalars['ID'];
  locales: Array<Scalars['Locale']>;
  after?: Maybe<Scalars['String']>;
}>;


export type BoothDesignSelectingGetBoothDesignsNameListQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<{ __typename?: 'Booth' } | { __typename?: 'BoothCategory' } | { __typename?: 'BoothDesign' } | { __typename?: 'BoothDesignCanvasElement' } | { __typename?: 'BoothDesignColorFillElement' } | { __typename?: 'BoothDesignContentAreaElement' } | { __typename?: 'BoothDesignElement' } | { __typename?: 'BoothDesignImageButtonElement' } | { __typename?: 'BoothDesignImageElement' } | { __typename?: 'BoothDesignNetworkingWidgetElement' } | { __typename?: 'BoothDividerContentElement' } | { __typename?: 'BoothDividerMasterElement' } | { __typename?: 'BoothFileContentElement' } | { __typename?: 'BoothFileMasterElement' } | { __typename?: 'BoothImageContentElement' } | { __typename?: 'BoothImageMasterElement' } | { __typename?: 'BoothQuestionContentElement' } | { __typename?: 'BoothQuestionMasterElement' } | { __typename?: 'BoothRichtextContentElement' } | { __typename?: 'BoothRichtextMasterElement' } | { __typename?: 'BoothRootContentElement' } | { __typename?: 'BoothRootMasterElement' } | { __typename?: 'BoothSupercategory' } | { __typename?: 'BoothTabContentElement' } | { __typename?: 'BoothTabMasterElement' } | { __typename?: 'BoothTier' } | { __typename?: 'BoothVideoContentElement' } | { __typename?: 'BoothVideoMasterElement' } | (
    { __typename?: 'Event' }
    & { boothDesigns: (
      { __typename?: 'BoothDesignConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'BoothDesign' }
        & Pick<BoothDesign, 'id'>
        & { name: (
          { __typename?: 'LocalizableString' }
          & LocalizableStringFragmentFragment
        ), canvas?: Maybe<(
          { __typename?: 'BoothDesignCanvasMasterElement' }
          & Pick<BoothDesignCanvasMasterElement, 'id'>
          & { aspectRatio: (
            { __typename?: 'DisplayMeasurement' }
            & DisplayMeasurementFragmentFragment
          ), height: (
            { __typename?: 'DisplayMeasurement' }
            & DisplayMeasurementFragmentFragment
          ), maxWidth: (
            { __typename?: 'DisplayMeasurement' }
            & DisplayMeasurementFragmentFragment
          ), positionX: (
            { __typename?: 'DisplayMeasurement' }
            & DisplayMeasurementFragmentFragment
          ), positionY: (
            { __typename?: 'DisplayMeasurement' }
            & DisplayMeasurementFragmentFragment
          ) }
        )> }
      )>>>, pageInfo: (
        { __typename?: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ) }
    ) }
  ) | { __typename?: 'GroupChatJoinJob' } | { __typename?: 'Hall' } | { __typename?: 'RegisterEventxtraAttendeeJob' } | { __typename?: 'RegisterRongcloudServiceJob' } | { __typename?: 'SearchResult' } | { __typename?: 'VideoCallRoom' } | { __typename?: 'VideoCallRoomMember' }> }
);

export const LocalizableStringFragmentFragmentDoc = gql`
    fragment LocalizableStringFragment on LocalizableString {
  __typename
  values(locales: $locales) {
    locale
    value
  }
}
    `;
export const BoothRichtextMasterElementFragmentFragmentDoc = gql`
    fragment BoothRichtextMasterElementFragment on BoothRichtextMasterElement {
  id
  __typename
  masterControlledContent
  contentRichtextHtml {
    ...LocalizableStringFragment
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const LocalizableFileFragmentFragmentDoc = gql`
    fragment LocalizableFileFragment on LocalizableFile {
  __typename
  values(locales: $locales) {
    locale
    value {
      __typename
      filename
      fileUrl
      contentType
    }
  }
}
    `;
export const BoothFileMasterElementFragmentFragmentDoc = gql`
    fragment BoothFileMasterElementFragment on BoothFileMasterElement {
  id
  __typename
  masterControlledFile
  file {
    ...LocalizableFileFragment
  }
}
    ${LocalizableFileFragmentFragmentDoc}`;
export const LocalizableImageFragmentFragmentDoc = gql`
    fragment LocalizableImageFragment on LocalizableImage {
  __typename
  values(locales: $locales) {
    locale
    value {
      __typename
      filename
      originalImageUrl
      fileUrl
      contentType
    }
  }
}
    `;
export const BoothImageMasterElementFragmentFragmentDoc = gql`
    fragment BoothImageMasterElementFragment on BoothImageMasterElement {
  id
  __typename
  masterControlledImage
  image {
    ...LocalizableImageFragment
  }
}
    ${LocalizableImageFragmentFragmentDoc}`;
export const LocalizableVideoFragmentFragmentDoc = gql`
    fragment LocalizableVideoFragment on LocalizableVideo {
  __typename
  values(locales: $locales) {
    locale
    value {
      __typename
      filename
      fileUrl
      playbackUrl
      contentType
    }
  }
}
    `;
export const BoothVideoMasterElementFragmentFragmentDoc = gql`
    fragment BoothVideoMasterElementFragment on BoothVideoMasterElement {
  id
  __typename
  masterControlledVideoFile
  videoFile {
    ...LocalizableVideoFragment
  }
}
    ${LocalizableVideoFragmentFragmentDoc}`;
export const BoothDividerMasterElementFragmentFragmentDoc = gql`
    fragment BoothDividerMasterElementFragment on BoothDividerMasterElement {
  id
  __typename
}
    `;
export const NonQuestionChildFragmentFragmentDoc = gql`
    fragment NonQuestionChildFragment on BoothMasterElement {
  id
  __typename
  ...BoothRichtextMasterElementFragment
  ...BoothFileMasterElementFragment
  ...BoothImageMasterElementFragment
  ...BoothVideoMasterElementFragment
  ...BoothDividerMasterElementFragment
}
    ${BoothRichtextMasterElementFragmentFragmentDoc}
${BoothFileMasterElementFragmentFragmentDoc}
${BoothImageMasterElementFragmentFragmentDoc}
${BoothVideoMasterElementFragmentFragmentDoc}
${BoothDividerMasterElementFragmentFragmentDoc}`;
export const AttendeeFragmentFragmentDoc = gql`
    fragment AttendeeFragment on Attendee {
  id
  firstName
  lastName
  email
  company
  title
  vexpoAccessUrl
  vexpoChatTag
}
    `;
export const BoothFileContentRawElementFragmentFragmentDoc = gql`
    fragment BoothFileContentRawElementFragment on BoothFileContentElement {
  id
  __typename
  file {
    ...LocalizableFileFragment
  }
}
    ${LocalizableFileFragmentFragmentDoc}`;
export const BoothFileContentElementFragmentFragmentDoc = gql`
    fragment BoothFileContentElementFragment on BoothFileContentElement {
  ...BoothFileContentRawElementFragment
  masterElement {
    ...BoothFileMasterElementFragment
  }
  rawElement {
    ...BoothFileContentRawElementFragment
  }
}
    ${BoothFileContentRawElementFragmentFragmentDoc}
${BoothFileMasterElementFragmentFragmentDoc}`;
export const BoothImageContentRawElementFragmentFragmentDoc = gql`
    fragment BoothImageContentRawElementFragment on BoothImageContentElement {
  id
  __typename
  image {
    ...LocalizableImageFragment
  }
}
    ${LocalizableImageFragmentFragmentDoc}`;
export const BoothImageContentElementFragmentFragmentDoc = gql`
    fragment BoothImageContentElementFragment on BoothImageContentElement {
  ...BoothImageContentRawElementFragment
  masterElement {
    ...BoothImageMasterElementFragment
  }
  rawElement {
    ...BoothImageContentRawElementFragment
  }
}
    ${BoothImageContentRawElementFragmentFragmentDoc}
${BoothImageMasterElementFragmentFragmentDoc}`;
export const BoothRichtextContentRawElementFragmentFragmentDoc = gql`
    fragment BoothRichtextContentRawElementFragment on BoothRichtextContentElement {
  id
  __typename
  contentRichtextHtml {
    ...LocalizableStringFragment
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const BoothVideoContentRawElementFragmentFragmentDoc = gql`
    fragment BoothVideoContentRawElementFragment on BoothVideoContentElement {
  id
  __typename
  videoFile {
    ...LocalizableVideoFragment
  }
}
    ${LocalizableVideoFragmentFragmentDoc}`;
export const BoothQuestionContentRawElementFragmentFragmentDoc = gql`
    fragment BoothQuestionContentRawElementFragment on BoothQuestionContentElement {
  id
  __typename
  question {
    ...LocalizableStringFragment
  }
  answer {
    ... on BoothRichtextContentElement {
      ...BoothRichtextContentRawElementFragment
      masterElement {
        ...BoothRichtextMasterElementFragment
      }
      rawElement {
        ...BoothRichtextContentRawElementFragment
      }
    }
    ... on BoothVideoContentElement {
      ...BoothVideoContentRawElementFragment
      masterElement {
        ...BoothVideoMasterElementFragment
      }
      rawElement {
        ...BoothVideoContentRawElementFragment
      }
    }
    ... on BoothFileContentElement {
      ...BoothFileContentRawElementFragment
      masterElement {
        ...BoothFileMasterElementFragment
      }
      rawElement {
        ...BoothFileContentRawElementFragment
      }
    }
    ... on BoothImageContentElement {
      ...BoothImageContentRawElementFragment
      masterElement {
        ...BoothImageMasterElementFragment
      }
      rawElement {
        ...BoothImageContentRawElementFragment
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}
${BoothRichtextContentRawElementFragmentFragmentDoc}
${BoothRichtextMasterElementFragmentFragmentDoc}
${BoothVideoContentRawElementFragmentFragmentDoc}
${BoothVideoMasterElementFragmentFragmentDoc}
${BoothFileContentRawElementFragmentFragmentDoc}
${BoothFileMasterElementFragmentFragmentDoc}
${BoothImageContentRawElementFragmentFragmentDoc}
${BoothImageMasterElementFragmentFragmentDoc}`;
export const BoothQuestionMasterElementFragmentFragmentDoc = gql`
    fragment BoothQuestionMasterElementFragment on BoothQuestionMasterElement {
  id
  __typename
  masterControlledAnswer
  masterControlledQuestion
  question {
    ...LocalizableStringFragment
  }
  answer {
    ...BoothRichtextMasterElementFragment
    ...BoothImageMasterElementFragment
    ...BoothFileMasterElementFragment
    ...BoothVideoMasterElementFragment
  }
}
    ${LocalizableStringFragmentFragmentDoc}
${BoothRichtextMasterElementFragmentFragmentDoc}
${BoothImageMasterElementFragmentFragmentDoc}
${BoothFileMasterElementFragmentFragmentDoc}
${BoothVideoMasterElementFragmentFragmentDoc}`;
export const BoothQuestionContentElementFragmentFragmentDoc = gql`
    fragment BoothQuestionContentElementFragment on BoothQuestionContentElement {
  ...BoothQuestionContentRawElementFragment
  masterElement {
    ...BoothQuestionMasterElementFragment
  }
  rawElement {
    ...BoothQuestionContentRawElementFragment
  }
}
    ${BoothQuestionContentRawElementFragmentFragmentDoc}
${BoothQuestionMasterElementFragmentFragmentDoc}`;
export const BoothRichtextContentElementFragmentFragmentDoc = gql`
    fragment BoothRichtextContentElementFragment on BoothRichtextContentElement {
  ...BoothRichtextContentRawElementFragment
  masterElement {
    ...BoothRichtextMasterElementFragment
  }
  rawElement {
    ...BoothRichtextContentRawElementFragment
  }
}
    ${BoothRichtextContentRawElementFragmentFragmentDoc}
${BoothRichtextMasterElementFragmentFragmentDoc}`;
export const BoothVideoContentElementFragmentFragmentDoc = gql`
    fragment BoothVideoContentElementFragment on BoothVideoContentElement {
  ...BoothVideoContentRawElementFragment
  masterElement {
    ...BoothVideoMasterElementFragment
  }
  rawElement {
    ...BoothVideoContentRawElementFragment
  }
}
    ${BoothVideoContentRawElementFragmentFragmentDoc}
${BoothVideoMasterElementFragmentFragmentDoc}`;
export const BoothDividerContentRawElementFragmentFragmentDoc = gql`
    fragment BoothDividerContentRawElementFragment on BoothDividerContentElement {
  id
  __typename
}
    `;
export const BoothDividerContentElementFragmentFragmentDoc = gql`
    fragment BoothDividerContentElementFragment on BoothDividerContentElement {
  ...BoothDividerContentRawElementFragment
  masterElement {
    ...BoothDividerMasterElementFragment
  }
  rawElement {
    ...BoothDividerContentRawElementFragment
  }
}
    ${BoothDividerContentRawElementFragmentFragmentDoc}
${BoothDividerMasterElementFragmentFragmentDoc}`;
export const BoothContentElementFragmentFragmentDoc = gql`
    fragment BoothContentElementFragment on BoothContentElement {
  id
  __typename
  ...BoothFileContentElementFragment
  ...BoothImageContentElementFragment
  ...BoothQuestionContentElementFragment
  ...BoothRichtextContentElementFragment
  ...BoothVideoContentElementFragment
  ...BoothDividerContentElementFragment
}
    ${BoothFileContentElementFragmentFragmentDoc}
${BoothImageContentElementFragmentFragmentDoc}
${BoothQuestionContentElementFragmentFragmentDoc}
${BoothRichtextContentElementFragmentFragmentDoc}
${BoothVideoContentElementFragmentFragmentDoc}
${BoothDividerContentElementFragmentFragmentDoc}`;
export const BoothDesignElementFramgmentFragmentDoc = gql`
    fragment BoothDesignElementFramgment on BoothDesignElement {
  id
  name
  validKinds
  kind
  color
  image {
    ...LocalizableImageFragment
  }
  video {
    ...LocalizableVideoFragment
  }
  pdf {
    ...LocalizableFileFragment
  }
  contentRichtextHtml {
    ...LocalizableStringFragment
  }
  thumbnailImage {
    ...LocalizableImageFragment
  }
  actionUrl
}
    ${LocalizableImageFragmentFragmentDoc}
${LocalizableVideoFragmentFragmentDoc}
${LocalizableFileFragmentFragmentDoc}
${LocalizableStringFragmentFragmentDoc}`;
export const BoothDesignElementsFragmentFragmentDoc = gql`
    fragment BoothDesignElementsFragment on BoothDesignElementConnection {
  nodes {
    ...BoothDesignElementFramgment
  }
}
    ${BoothDesignElementFramgmentFragmentDoc}`;
export const DisplayMeasurementFragmentFragmentDoc = gql`
    fragment DisplayMeasurementFragment on DisplayMeasurement {
  dimension
  unit
}
    `;
export const BoothTierFragmentFragmentDoc = gql`
    fragment BoothTierFragment on BoothTier {
  id
  name {
    ...LocalizableStringFragment
  }
  nameVisible
  scale {
    ...DisplayMeasurementFragment
  }
  booths {
    totalCount
  }
}
    ${LocalizableStringFragmentFragmentDoc}
${DisplayMeasurementFragmentFragmentDoc}`;
export const BoothTaxonomyFragmentFragmentDoc = gql`
    fragment BoothTaxonomyFragment on BoothTaxonomy {
  __typename
  name {
    ...LocalizableStringFragment
  }
  id
  parentSupercategory {
    id
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const BoothV2DesignRawElementFragmentFragmentDoc = gql`
    fragment BoothV2DesignRawElementFragment on BoothV2DesignElement {
  __typename
  id
  height {
    ...DisplayMeasurementFragment
  }
  width {
    ...DisplayMeasurementFragment
  }
  positionX {
    ...DisplayMeasurementFragment
  }
  positionY {
    ...DisplayMeasurementFragment
  }
}
    ${DisplayMeasurementFragmentFragmentDoc}`;
export const BoothV2DesignElementFragmentFragmentDoc = gql`
    fragment BoothV2DesignElementFragment on BoothV2DesignElement {
  __typename
  id
  height {
    ...DisplayMeasurementFragment
  }
  width {
    ...DisplayMeasurementFragment
  }
  positionX {
    ...DisplayMeasurementFragment
  }
  positionY {
    ...DisplayMeasurementFragment
  }
  rawElement {
    ...BoothV2DesignRawElementFragment
  }
}
    ${DisplayMeasurementFragmentFragmentDoc}
${BoothV2DesignRawElementFragmentFragmentDoc}`;
export const BoothDesignMasterElementFragmentFragmentDoc = gql`
    fragment BoothDesignMasterElementFragment on BoothDesignMasterElement {
  __typename
  id
  height {
    ...DisplayMeasurementFragment
  }
  width {
    ...DisplayMeasurementFragment
  }
  positionX {
    ...DisplayMeasurementFragment
  }
  positionY {
    ...DisplayMeasurementFragment
  }
}
    ${DisplayMeasurementFragmentFragmentDoc}`;
export const BoothDesignColorFillMasterElementFragmentFragmentDoc = gql`
    fragment BoothDesignColorFillMasterElementFragment on BoothDesignColorFillMasterElement {
  ...BoothDesignMasterElementFragment
  keyName
  fillColor
  masterControlledFillColor
  maskImage {
    ...LocalizableImageFragment
  }
}
    ${BoothDesignMasterElementFragmentFragmentDoc}
${LocalizableImageFragmentFragmentDoc}`;
export const BoothDesignColorFillRawElementFragmentFragmentDoc = gql`
    fragment BoothDesignColorFillRawElementFragment on BoothDesignColorFillElement {
  ...BoothV2DesignElementFragment
  keyName
  fillColor
  maskImage {
    ...LocalizableImageFragment
  }
}
    ${BoothV2DesignElementFragmentFragmentDoc}
${LocalizableImageFragmentFragmentDoc}`;
export const BoothDesignColorFillElementFragmentFragmentDoc = gql`
    fragment BoothDesignColorFillElementFragment on BoothDesignColorFillElement {
  ...BoothV2DesignElementFragment
  keyName
  fillColor
  masterElement {
    ...BoothDesignColorFillMasterElementFragment
  }
  maskImage {
    ...LocalizableImageFragment
  }
  rawElement {
    ...BoothDesignColorFillRawElementFragment
  }
}
    ${BoothV2DesignElementFragmentFragmentDoc}
${BoothDesignColorFillMasterElementFragmentFragmentDoc}
${LocalizableImageFragmentFragmentDoc}
${BoothDesignColorFillRawElementFragmentFragmentDoc}`;
export const BoothDesignContentAreaMasterElementFragmentFragmentDoc = gql`
    fragment BoothDesignContentAreaMasterElementFragment on BoothDesignContentAreaMasterElement {
  ...BoothDesignMasterElementFragment
  keyName
  acceptedContentTypes
}
    ${BoothDesignMasterElementFragmentFragmentDoc}`;
export const BoothDesignContentAreaRawElementFragmentFragmentDoc = gql`
    fragment BoothDesignContentAreaRawElementFragment on BoothDesignContentAreaElement {
  ...BoothV2DesignElementFragment
  keyName
  acceptedContentTypes
  contentType
  contentImage {
    ...LocalizableImageFragment
  }
  contentPdf {
    ...LocalizableFileFragment
  }
  contentRichtextHtml {
    ...LocalizableStringFragment
  }
  contentVideo {
    ...LocalizableVideoFragment
  }
  thumbnailImage {
    ...LocalizableImageFragment
  }
}
    ${BoothV2DesignElementFragmentFragmentDoc}
${LocalizableImageFragmentFragmentDoc}
${LocalizableFileFragmentFragmentDoc}
${LocalizableStringFragmentFragmentDoc}
${LocalizableVideoFragmentFragmentDoc}`;
export const BoothDesignContentAreaElementFragmentFragmentDoc = gql`
    fragment BoothDesignContentAreaElementFragment on BoothDesignContentAreaElement {
  ...BoothV2DesignElementFragment
  keyName
  acceptedContentTypes
  contentType
  contentImage {
    ...LocalizableImageFragment
  }
  contentPdf {
    ...LocalizableFileFragment
  }
  contentRichtextHtml {
    ...LocalizableStringFragment
  }
  contentVideo {
    ...LocalizableVideoFragment
  }
  thumbnailImage {
    ...LocalizableImageFragment
  }
  masterElement {
    ...BoothDesignContentAreaMasterElementFragment
  }
  rawElement {
    ...BoothDesignContentAreaRawElementFragment
  }
}
    ${BoothV2DesignElementFragmentFragmentDoc}
${LocalizableImageFragmentFragmentDoc}
${LocalizableFileFragmentFragmentDoc}
${LocalizableStringFragmentFragmentDoc}
${LocalizableVideoFragmentFragmentDoc}
${BoothDesignContentAreaMasterElementFragmentFragmentDoc}
${BoothDesignContentAreaRawElementFragmentFragmentDoc}`;
export const BoothDesignImageButtonMasterElementFragmentFragmentDoc = gql`
    fragment BoothDesignImageButtonMasterElementFragment on BoothDesignImageButtonMasterElement {
  ...BoothDesignMasterElementFragment
  masterControlledAction
  actionExternalLinkUrl
  actionLocalizableExternalLinkUrl {
    ...LocalizableStringFragment
  }
  actionType
  hoverStateImage {
    ...LocalizableImageFragment
  }
  normalStateImage {
    ...LocalizableImageFragment
  }
  keyName
}
    ${BoothDesignMasterElementFragmentFragmentDoc}
${LocalizableStringFragmentFragmentDoc}
${LocalizableImageFragmentFragmentDoc}`;
export const BoothDesignImageButtonRawElementFragmentFragmentDoc = gql`
    fragment BoothDesignImageButtonRawElementFragment on BoothDesignImageButtonElement {
  ...BoothV2DesignElementFragment
  actionExternalLinkUrl
  actionLocalizableExternalLinkUrl {
    ...LocalizableStringFragment
  }
  actionType
  hoverStateImage {
    ...LocalizableImageFragment
  }
  normalStateImage {
    ...LocalizableImageFragment
  }
  keyName
}
    ${BoothV2DesignElementFragmentFragmentDoc}
${LocalizableStringFragmentFragmentDoc}
${LocalizableImageFragmentFragmentDoc}`;
export const BoothDesignImageButtonElementFragmentFragmentDoc = gql`
    fragment BoothDesignImageButtonElementFragment on BoothDesignImageButtonElement {
  ...BoothV2DesignElementFragment
  actionExternalLinkUrl
  actionLocalizableExternalLinkUrl {
    ...LocalizableStringFragment
  }
  actionType
  hoverStateImage {
    ...LocalizableImageFragment
  }
  normalStateImage {
    ...LocalizableImageFragment
  }
  keyName
  masterElement {
    ...BoothDesignImageButtonMasterElementFragment
  }
  rawElement {
    ...BoothDesignImageButtonRawElementFragment
  }
}
    ${BoothV2DesignElementFragmentFragmentDoc}
${LocalizableStringFragmentFragmentDoc}
${LocalizableImageFragmentFragmentDoc}
${BoothDesignImageButtonMasterElementFragmentFragmentDoc}
${BoothDesignImageButtonRawElementFragmentFragmentDoc}`;
export const BoothDesignImageMasterElementFragmentFragmentDoc = gql`
    fragment BoothDesignImageMasterElementFragment on BoothDesignImageMasterElement {
  ...BoothDesignMasterElementFragment
  image {
    ...LocalizableImageFragment
  }
}
    ${BoothDesignMasterElementFragmentFragmentDoc}
${LocalizableImageFragmentFragmentDoc}`;
export const BoothDesignImageRawElementFragmentFragmentDoc = gql`
    fragment BoothDesignImageRawElementFragment on BoothDesignImageElement {
  ...BoothV2DesignElementFragment
  image {
    ...LocalizableImageFragment
  }
}
    ${BoothV2DesignElementFragmentFragmentDoc}
${LocalizableImageFragmentFragmentDoc}`;
export const BoothDesignImageElementFragmentFragmentDoc = gql`
    fragment BoothDesignImageElementFragment on BoothDesignImageElement {
  ...BoothV2DesignElementFragment
  image {
    ...LocalizableImageFragment
  }
  masterElement {
    ...BoothDesignImageMasterElementFragment
  }
  rawElement {
    ...BoothDesignImageRawElementFragment
  }
}
    ${BoothV2DesignElementFragmentFragmentDoc}
${LocalizableImageFragmentFragmentDoc}
${BoothDesignImageMasterElementFragmentFragmentDoc}
${BoothDesignImageRawElementFragmentFragmentDoc}`;
export const BoothDesignNetworkingWidgetMasterElementFragmentFragmentDoc = gql`
    fragment BoothDesignNetworkingWidgetMasterElementFragment on BoothDesignNetworkingWidgetMasterElement {
  ...BoothDesignMasterElementFragment
}
    ${BoothDesignMasterElementFragmentFragmentDoc}`;
export const BoothDesignNetworkingWidgetRawElementFragmentFragmentDoc = gql`
    fragment BoothDesignNetworkingWidgetRawElementFragment on BoothDesignNetworkingWidgetElement {
  ...BoothV2DesignElementFragment
}
    ${BoothV2DesignElementFragmentFragmentDoc}`;
export const BoothDesignNetworkingWidgetElementFragmentFragmentDoc = gql`
    fragment BoothDesignNetworkingWidgetElementFragment on BoothDesignNetworkingWidgetElement {
  ...BoothV2DesignElementFragment
  masterElement {
    ...BoothDesignImageMasterElementFragment
  }
  rawElement {
    ...BoothDesignImageRawElementFragment
  }
}
    ${BoothV2DesignElementFragmentFragmentDoc}
${BoothDesignImageMasterElementFragmentFragmentDoc}
${BoothDesignImageRawElementFragmentFragmentDoc}`;
export const BoothEditGetBoothDesignDocument = gql`
    query boothEditGetBoothDesign($boothId: ID!) {
  node(id: $boothId) {
    ... on Booth {
      boothDesign {
        id
      }
    }
  }
}
    `;
export const BoothEditGetBoothDesignCanvasDocument = gql`
    query boothEditGetBoothDesignCanvas($boothId: ID!) {
  node(id: $boothId) {
    ... on Booth {
      boothDesignCanvas {
        id
        aspectRatio {
          ...DisplayMeasurementFragment
        }
        maxWidth {
          ...DisplayMeasurementFragment
        }
        positionX {
          ...DisplayMeasurementFragment
        }
        positionY {
          ...DisplayMeasurementFragment
        }
      }
    }
  }
}
    ${DisplayMeasurementFragmentFragmentDoc}`;
export const BoothEditGetBoothDesignCanvasLayersListDocument = gql`
    query boothEditGetBoothDesignCanvasLayersList($boothId: ID!, $locales: [Locale!]!, $after: String) {
  node(id: $boothId) {
    ... on Booth {
      boothDesignCanvas {
        layers(after: $after, first: 10) {
          nodes {
            ...BoothDesignColorFillElementFragment
            ...BoothDesignContentAreaElementFragment
            ...BoothDesignImageButtonElementFragment
            ...BoothDesignImageElementFragment
            ...BoothDesignNetworkingWidgetElementFragment
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
}
    ${BoothDesignColorFillElementFragmentFragmentDoc}
${BoothDesignContentAreaElementFragmentFragmentDoc}
${BoothDesignImageButtonElementFragmentFragmentDoc}
${BoothDesignImageElementFragmentFragmentDoc}
${BoothDesignNetworkingWidgetElementFragmentFragmentDoc}`;
export const BoothEditGetBoothDesignsListDocument = gql`
    query boothEditGetBoothDesignsList($boothId: ID!, $locales: [Locale!]!, $after: String) {
  node(id: $boothId) {
    ... on Booth {
      event {
        boothDesigns(after: $after, first: 100) {
          nodes {
            id
            name {
              ...LocalizableStringFragment
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const BoothEditGetBoothCategoriesListDocument = gql`
    query boothEditGetBoothCategoriesList($boothId: ID!, $after: String) {
  node(id: $boothId) {
    ... on Booth {
      categories(after: $after, first: 30) {
        nodes {
          id
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
}
    `;
export const BoothEditGetEventTaxonomiesListDocument = gql`
    query boothEditGetEventTaxonomiesList($boothId: ID!, $locales: [Locale!]!, $after: String) {
  node(id: $boothId) {
    ... on Booth {
      event {
        boothTaxonomies(after: $after, first: 30) {
          nodes {
            ... on BoothTaxonomy {
              id
              ... on BoothCategory {
                name {
                  ...LocalizableStringFragment
                  ...LocalizableStringFragment
                }
                parentSupercategory {
                  id
                }
              }
              ... on BoothSupercategory {
                name {
                  ...LocalizableStringFragment
                  ...LocalizableStringFragment
                }
              }
              __typename
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const BoothEditGetLegacyDesignElementsDocument = gql`
    query boothEditGetLegacyDesignElements($boothId: ID!, $locales: [Locale!]!, $after: String) {
  node(id: $boothId) {
    ... on Booth {
      designElements(after: $after, first: 30) {
        ...BoothDesignElementsFragment
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
}
    ${BoothDesignElementsFragmentFragmentDoc}`;
export const BoothEditGetContentElementTabDocument = gql`
    query boothEditGetContentElementTab($tabId: ID!, $locales: [Locale!]!) {
  node(id: $tabId) {
    ... on BoothTabContentElement {
      id
      title {
        ...LocalizableStringFragment
      }
      masterElement {
        ... on BoothTabMasterElement {
          masterControlledTitle
          masterControlledBody
        }
      }
      rawElement {
        ... on BoothTabContentElement {
          title {
            ...LocalizableStringFragment
          }
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const BoothEditGetContentElementTabBodyNodesDocument = gql`
    query boothEditGetContentElementTabBodyNodes($tabId: ID!, $locales: [Locale!]!, $after: String) {
  node(id: $tabId) {
    ... on BoothTabContentElement {
      body(after: $after, first: 10) {
        nodes {
          ...BoothContentElementFragment
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
}
    ${BoothContentElementFragmentFragmentDoc}`;
export const BoothDesignColorFillElementUpdateDocument = gql`
    mutation boothDesignColorFillElementUpdate($input: BoothDesignColorFillElementUpdateInput!) {
  boothDesignColorFillElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothDesignContentAreaElementUpdateDocument = gql`
    mutation boothDesignContentAreaElementUpdate($input: BoothDesignContentAreaElementUpdateInput!) {
  boothDesignContentAreaElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothDesignImageButtonElementUpdateDocument = gql`
    mutation boothDesignImageButtonElementUpdate($input: BoothDesignImageButtonElementUpdateInput!) {
  boothDesignImageButtonElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothEditGetRepresentativesListDocument = gql`
    query boothEditGetRepresentativesList($boothId: ID!, $after: String, $deleted: Boolean) {
  node(id: $boothId) {
    ... on Booth {
      representatives(after: $after, first: 30, deleted: $deleted) {
        nodes {
          ...AttendeeFragment
        }
        pageInfo {
          hasNextPage
          endCursor
        }
        totalCount
      }
    }
  }
}
    ${AttendeeFragmentFragmentDoc}`;
export const BoothEditRepresentativeAddDocument = gql`
    mutation boothEditRepresentativeAdd($input: BoothRepresentativeCreateInput!) {
  boothRepresentativeCreate(input: $input) {
    errors {
      message
    }
    success
    representative {
      ...AttendeeFragment
    }
  }
}
    ${AttendeeFragmentFragmentDoc}`;
export const BoothEditRepresentativeDeleteDocument = gql`
    mutation boothEditRepresentativeDelete($input: BoothRepresentativeDeleteInput!) {
  boothRepresentativeDelete(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const DesignEditorCanvasUpdateDocument = gql`
    mutation designEditorCanvasUpdate($input: BoothDesignCanvasMasterElementUpdateInput!) {
  boothDesignCanvasMasterElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const DesignEditorGetboothDesignByIdDocument = gql`
    query designEditorGetboothDesignById($id: ID!, $locales: [Locale!]!) {
  node(id: $id) {
    ... on BoothDesign {
      id
      name {
        ...LocalizableStringFragment
      }
      canvas {
        id
        aspectRatio {
          ...DisplayMeasurementFragment
        }
        height {
          ...DisplayMeasurementFragment
        }
        maxWidth {
          ...DisplayMeasurementFragment
        }
        positionX {
          ...DisplayMeasurementFragment
        }
        positionY {
          ...DisplayMeasurementFragment
        }
        layers {
          nodes {
            id
            ...BoothDesignImageButtonMasterElementFragment
            ...BoothDesignContentAreaMasterElementFragment
            ...BoothDesignImageMasterElementFragment
            ...BoothDesignColorFillMasterElementFragment
            ...BoothDesignNetworkingWidgetMasterElementFragment
          }
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}
${DisplayMeasurementFragmentFragmentDoc}
${BoothDesignImageButtonMasterElementFragmentFragmentDoc}
${BoothDesignContentAreaMasterElementFragmentFragmentDoc}
${BoothDesignImageMasterElementFragmentFragmentDoc}
${BoothDesignColorFillMasterElementFragmentFragmentDoc}
${BoothDesignNetworkingWidgetMasterElementFragmentFragmentDoc}`;
export const DesignEditorGetBoothDesignsListByEventIdDocument = gql`
    query designEditorGetBoothDesignsListByEventId($eventId: ID!, $locales: [Locale!]!, $after: String) {
  node(id: $eventId) {
    ... on Event {
      boothDesigns(after: $after, first: 30) {
        nodes {
          id
          name {
            ...LocalizableStringFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const DesignEditorLayerDeleteByIdDocument = gql`
    mutation designEditorLayerDeleteById($input: BoothDesignMasterElementDeleteInput!) {
  boothDesignMasterElementDelete(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const DesignEditorLayerImageButtonUpdateDocument = gql`
    mutation designEditorLayerImageButtonUpdate($input: BoothDesignImageButtonMasterElementUpdateInput!) {
  boothDesignImageButtonMasterElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const DesignEditorLayerImageButtonCreateDocument = gql`
    mutation designEditorLayerImageButtonCreate($input: BoothDesignImageButtonMasterElementCreateInput!, $locales: [Locale!]!) {
  boothDesignImageButtonMasterElementCreate(input: $input) {
    success
    errors {
      message
    }
    element {
      ...BoothDesignImageButtonMasterElementFragment
    }
  }
}
    ${BoothDesignImageButtonMasterElementFragmentFragmentDoc}`;
export const DesignEditorLayerContentAreaUpdateDocument = gql`
    mutation designEditorLayerContentAreaUpdate($input: BoothDesignContentAreaMasterElementUpdateInput!) {
  boothDesignContentAreaMasterElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const DesignEditorLayerContentAreaCreateDocument = gql`
    mutation designEditorLayerContentAreaCreate($input: BoothDesignContentAreaMasterElementCreateInput!) {
  boothDesignContentAreaMasterElementCreate(input: $input) {
    success
    errors {
      message
    }
    element {
      ...BoothDesignContentAreaMasterElementFragment
    }
  }
}
    ${BoothDesignContentAreaMasterElementFragmentFragmentDoc}`;
export const DesignEditorLayerImageUpdateDocument = gql`
    mutation designEditorLayerImageUpdate($input: BoothDesignImageMasterElementUpdateInput!) {
  boothDesignImageMasterElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const DesignEditorLayerImageCreateDocument = gql`
    mutation designEditorLayerImageCreate($input: BoothDesignImageMasterElementCreateInput!, $locales: [Locale!]!) {
  boothDesignImageMasterElementCreate(input: $input) {
    success
    errors {
      message
    }
    element {
      ...BoothDesignImageMasterElementFragment
    }
  }
}
    ${BoothDesignImageMasterElementFragmentFragmentDoc}`;
export const DesignEditorLayerColorFillUpdateDocument = gql`
    mutation designEditorLayerColorFillUpdate($input: BoothDesignColorFillMasterElementUpdateInput!) {
  boothDesignColorFillMasterElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const DesignEditorLayerColorFillCreateDocument = gql`
    mutation designEditorLayerColorFillCreate($input: BoothDesignColorFillMasterElementCreateInput!, $locales: [Locale!]!) {
  boothDesignColorFillMasterElementCreate(input: $input) {
    success
    errors {
      message
    }
    element {
      ...BoothDesignColorFillMasterElementFragment
    }
  }
}
    ${BoothDesignColorFillMasterElementFragmentFragmentDoc}`;
export const DesignEditorLayerNetworkingWidgetUpdateDocument = gql`
    mutation designEditorLayerNetworkingWidgetUpdate($input: BoothDesignNetworkingWidgetMasterElementUpdateInput!) {
  boothDesignNetworkingWidgetMasterElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const DesignEditorLayerNetworkingWidgetCreateDocument = gql`
    mutation designEditorLayerNetworkingWidgetCreate($input: BoothDesignNetworkingWidgetMasterElementCreateInput!) {
  boothDesignNetworkingWidgetMasterElementCreate(input: $input) {
    success
    errors {
      message
    }
    element {
      ...BoothDesignNetworkingWidgetMasterElementFragment
    }
  }
}
    ${BoothDesignNetworkingWidgetMasterElementFragmentFragmentDoc}`;
export const BoothTierCreateDocument = gql`
    mutation boothTierCreate($input: BoothTierCreateInput!, $locales: [Locale!]!) {
  boothTierCreate(input: $input) {
    boothTier {
      ...BoothTierFragment
    }
    success
    errors {
      message
    }
  }
}
    ${BoothTierFragmentFragmentDoc}`;
export const BoothTierDeleteDocument = gql`
    mutation boothTierDelete($input: BoothTierDeleteInput!) {
  boothTierDelete(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothTierUpdateDocument = gql`
    mutation boothTierUpdate($input: BoothTierUpdateInput!) {
  boothTierUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const EventEditBoothCreateDocument = gql`
    mutation eventEditBoothCreate($input: BoothCreateInput!) {
  boothCreate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const EventEditGetBoothTiersListDocument = gql`
    query eventEditGetBoothTiersList($eventId: ID!, $locales: [Locale!]!, $after: String) {
  node(id: $eventId) {
    ... on Event {
      boothTiers(after: $after, first: 10) {
        nodes {
          id
          name {
            ...LocalizableStringFragment
          }
          nameVisible
          scale {
            ...DisplayMeasurementFragment
          }
          booths(first: 0) {
            totalCount
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}
${DisplayMeasurementFragmentFragmentDoc}`;
export const EventEditGetBoothsExportListByTierIdDocument = gql`
    query eventEditGetBoothsExportListByTierId($tierId: ID!, $locales: [Locale!]!, $after: String, $pageSize: Int!) {
  node(id: $tierId) {
    ... on BoothTier {
      booths(after: $after, first: $pageSize) {
        nodes {
          id
          name {
            ...LocalizableStringFragment
          }
          cmsUrl
          tier {
            name {
              ...LocalizableStringFragment
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const EventEditGetBoothsListDocument = gql`
    query eventEditGetBoothsList($eventId: ID!, $after: String, $pageSize: Int!, $locales: [Locale!]!) {
  node(id: $eventId) {
    ... on Event {
      booths(after: $after, first: $pageSize) {
        nodes {
          id
          name {
            ...LocalizableStringFragment
          }
          cmsUrl
          tier {
            name {
              ...LocalizableStringFragment
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const EventEditGetBoothsListByTierIdDocument = gql`
    query eventEditGetBoothsListByTierId($tierId: ID!, $locales: [Locale!]!, $after: String, $pageSize: Int!) {
  node(id: $tierId) {
    ... on BoothTier {
      booths(after: $after, first: $pageSize) {
        nodes {
          id
          published
          name {
            ...LocalizableStringFragment
          }
          logo {
            ...LocalizableImageFragment
          }
          cmsUrl
          tier {
            id
          }
          boothDesign {
            id
          }
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}
${LocalizableImageFragmentFragmentDoc}`;
export const EventEditGetBoothsListTotalCountDocument = gql`
    query eventEditGetBoothsListTotalCount($eventId: ID!) {
  node(id: $eventId) {
    ... on Event {
      booths {
        totalCount
      }
    }
  }
}
    `;
export const EventEditGetBoothsNameListByIdsDocument = gql`
    query eventEditGetBoothsNameListByIds($boothIds: [ID!]!, $locales: [Locale!]!) {
  nodes(ids: $boothIds) {
    ... on Booth {
      id
      name {
        ...LocalizableStringFragment
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const EventEditSearchBoothDocument = gql`
    query eventEditSearchBooth($eventId: ID!, $keyword: String, $locales: [Locale!]!) {
  node(id: $eventId) {
    ... on Event {
      searchByKeyword(keyword: $keyword) {
        nodes {
          searchable {
            ... on Booth {
              id
              name {
                ...LocalizableStringFragment
              }
            }
          }
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const EventEditGetBoothTaxonomiesListDocument = gql`
    query eventEditGetBoothTaxonomiesList($eventId: ID!, $locales: [Locale!]!, $after: String) {
  node(id: $eventId) {
    ... on Event {
      boothTaxonomies(after: $after) {
        nodes {
          __typename
          id
          name {
            ...LocalizableStringFragment
          }
          parentSupercategory {
            id
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const EventEditGetMasterTabByIdDocument = gql`
    query eventEditGetMasterTabById($tabId: ID!, $locales: [Locale!]!, $after: String) {
  node(id: $tabId) {
    __typename
    id
    ... on BoothTabMasterElement {
      title {
        ...LocalizableStringFragment
      }
      masterControlledBody
      masterControlledTitle
      body(after: $after, first: 10) {
        nodes {
          ...NonQuestionChildFragment
          ... on BoothQuestionMasterElement {
            masterControlledQuestion
            masterControlledAnswer
            question {
              ...LocalizableStringFragment
            }
            answer {
              ...NonQuestionChildFragment
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}
${NonQuestionChildFragmentFragmentDoc}`;
export const EventEditGetMasterTabsListDocument = gql`
    query eventEditGetMasterTabsList($eventId: ID!, $locales: [Locale!]!, $after: String) {
  node(id: $eventId) {
    ... on Event {
      __typename
      id
      rootMasterElement {
        __typename
        id
        tabs(after: $after, first: 10) {
          nodes {
            id
            title {
              values(locales: $locales) {
                locale
                value
              }
            }
            masterControlledTitle
            masterControlledBody
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
        masterControlledTabs
      }
    }
  }
}
    `;
export const BoothDesignElementUpdateDocument = gql`
    mutation boothDesignElementUpdate($input: BoothDesignElementUpdateInput!) {
  boothDesignElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothContentElementDeleteDocument = gql`
    mutation boothContentElementDelete($input: BoothContentElementDeleteInput!) {
  boothContentElementDelete(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothDividerContentElementCreateDocument = gql`
    mutation boothDividerContentElementCreate($input: BoothDividerContentElementCreateInput!) {
  boothDividerContentElementCreate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothDividerContentElementUpdateDocument = gql`
    mutation boothDividerContentElementUpdate($input: BoothDividerContentElementUpdateInput!) {
  boothDividerContentElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothDividerMasterElementCreateDocument = gql`
    mutation boothDividerMasterElementCreate($input: BoothDividerMasterElementCreateInput!) {
  boothDividerMasterElementCreate(input: $input) {
    element {
      ...BoothDividerMasterElementFragment
    }
    success
    errors {
      message
    }
  }
}
    ${BoothDividerMasterElementFragmentFragmentDoc}`;
export const BoothDividerMasterElementUpdateDocument = gql`
    mutation boothDividerMasterElementUpdate($input: BoothDividerMasterElementUpdateInput!) {
  boothDividerMasterElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothFileContentElementCreateDocument = gql`
    mutation boothFileContentElementCreate($input: BoothFileContentElementCreateInput!, $locales: [Locale!]!) {
  boothFileContentElementCreate(input: $input) {
    element {
      ...BoothFileContentElementFragment
    }
    success
    errors {
      message
    }
  }
}
    ${BoothFileContentElementFragmentFragmentDoc}`;
export const BoothFileContentElementUpdateDocument = gql`
    mutation boothFileContentElementUpdate($input: BoothFileContentElementUpdateInput!) {
  boothFileContentElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothFileMasterElementCreateDocument = gql`
    mutation boothFileMasterElementCreate($input: BoothFileMasterElementCreateInput!, $locales: [Locale!]!) {
  boothFileMasterElementCreate(input: $input) {
    element {
      ...BoothFileMasterElementFragment
    }
    success
    errors {
      message
    }
  }
}
    ${BoothFileMasterElementFragmentFragmentDoc}`;
export const BoothFileMasterElementUpdateDocument = gql`
    mutation boothFileMasterElementUpdate($input: BoothFileMasterElementUpdateInput!) {
  boothFileMasterElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothImageContentElementCreateDocument = gql`
    mutation boothImageContentElementCreate($input: BoothImageContentElementCreateInput!, $locales: [Locale!]!) {
  boothImageContentElementCreate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothImageContentElementUpdateDocument = gql`
    mutation boothImageContentElementUpdate($input: BoothImageContentElementUpdateInput!) {
  boothImageContentElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothImageMasterElementCreateDocument = gql`
    mutation boothImageMasterElementCreate($input: BoothImageMasterElementCreateInput!, $locales: [Locale!]!) {
  boothImageMasterElementCreate(input: $input) {
    element {
      ...BoothImageMasterElementFragment
    }
    success
    errors {
      message
    }
  }
}
    ${BoothImageMasterElementFragmentFragmentDoc}`;
export const BoothImageMasterElementUpdateDocument = gql`
    mutation boothImageMasterElementUpdate($input: BoothImageMasterElementUpdateInput!) {
  boothImageMasterElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothMasterElementDeleteDocument = gql`
    mutation boothMasterElementDelete($input: BoothMasterElementDeleteInput!) {
  boothMasterElementDelete(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothQuestionContentElementCreateDocument = gql`
    mutation boothQuestionContentElementCreate($input: BoothQuestionContentElementCreateInput!, $locales: [Locale!]!) {
  boothQuestionContentElementCreate(input: $input) {
    element {
      ...BoothQuestionContentElementFragment
    }
    success
    errors {
      message
    }
  }
}
    ${BoothQuestionContentElementFragmentFragmentDoc}`;
export const BoothQuestionContentElementUpdateDocument = gql`
    mutation boothQuestionContentElementUpdate($input: BoothQuestionContentElementUpdateInput!) {
  boothQuestionContentElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothQuestionMasterElementCreateDocument = gql`
    mutation boothQuestionMasterElementCreate($input: BoothQuestionMasterElementCreateInput!, $locales: [Locale!]!) {
  boothQuestionMasterElementCreate(input: $input) {
    element {
      ...BoothQuestionMasterElementFragment
    }
    success
    errors {
      message
    }
  }
}
    ${BoothQuestionMasterElementFragmentFragmentDoc}`;
export const BoothQuestionMasterElementUpdateDocument = gql`
    mutation boothQuestionMasterElementUpdate($input: BoothQuestionMasterElementUpdateInput!) {
  boothQuestionMasterElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothRichtextContentElementCreateDocument = gql`
    mutation boothRichtextContentElementCreate($input: BoothRichtextContentElementCreateInput!, $locales: [Locale!]!) {
  boothRichtextContentElementCreate(input: $input) {
    element {
      ...BoothRichtextContentElementFragment
    }
    success
    errors {
      message
    }
  }
}
    ${BoothRichtextContentElementFragmentFragmentDoc}`;
export const BoothRichtextContentElementUpdateDocument = gql`
    mutation boothRichtextContentElementUpdate($input: BoothRichtextContentElementUpdateInput!) {
  boothRichtextContentElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothRichtextMasterElementCreateDocument = gql`
    mutation boothRichtextMasterElementCreate($input: BoothRichtextMasterElementCreateInput!, $locales: [Locale!]!) {
  boothRichtextMasterElementCreate(input: $input) {
    element {
      ...BoothRichtextMasterElementFragment
    }
    success
    errors {
      message
    }
  }
}
    ${BoothRichtextMasterElementFragmentFragmentDoc}`;
export const BoothRichtextMasterElementUpdateDocument = gql`
    mutation boothRichtextMasterElementUpdate($input: BoothRichtextMasterElementUpdateInput!) {
  boothRichtextMasterElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothTabContentElementCreateDocument = gql`
    mutation boothTabContentElementCreate($input: BoothTabContentElementCreateInput!) {
  boothTabContentElementCreate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothTabContentElementUpdateDocument = gql`
    mutation boothTabContentElementUpdate($input: BoothTabContentElementUpdateInput!) {
  boothTabContentElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothTabMasterElementCreateDocument = gql`
    mutation boothTabMasterElementCreate($input: BoothTabMasterElementCreateInput!, $locales: [Locale!]!) {
  boothTabMasterElementCreate(input: $input) {
    element {
      id
      title {
        ...LocalizableStringFragment
      }
      masterControlledTitle
      masterControlledBody
    }
    success
    errors {
      message
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const BoothTabMasterElementUpdateDocument = gql`
    mutation boothTabMasterElementUpdate($input: BoothTabMasterElementUpdateInput!) {
  boothTabMasterElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothVideoContentElementCreateDocument = gql`
    mutation boothVideoContentElementCreate($input: BoothVideoContentElementCreateInput!) {
  boothVideoContentElementCreate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothVideoContentElementUpdateDocument = gql`
    mutation boothVideoContentElementUpdate($input: BoothVideoContentElementUpdateInput!) {
  boothVideoContentElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothVideoMasterElementCreateDocument = gql`
    mutation boothVideoMasterElementCreate($input: BoothVideoMasterElementCreateInput!, $locales: [Locale!]!) {
  boothVideoMasterElementCreate(input: $input) {
    element {
      ...BoothVideoMasterElementFragment
    }
    success
    errors {
      message
    }
  }
}
    ${BoothVideoMasterElementFragmentFragmentDoc}`;
export const BoothVideoMasterElementUpdateDocument = gql`
    mutation boothVideoMasterElementUpdate($input: BoothVideoMasterElementUpdateInput!) {
  boothVideoMasterElementUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const ConfirmationEmailResendDocument = gql`
    mutation confirmationEmailResend($input: ConfirmationEmailResendInput!) {
  confirmationEmailResend(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const GroupChatJoinDocument = gql`
    mutation groupChatJoin($input: GroupChatJoinInput!) {
  groupChatJoin(input: $input) {
    success
  }
}
    `;
export const PushNotificationDocument = gql`
    mutation pushNotification($input: PushNotificationInput!) {
  pushNotification(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const UploadFileDocument = gql`
    mutation uploadFile($input: UploadFileInput!) {
  uploadFile(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothUpdateDocument = gql`
    mutation boothUpdate($input: BoothUpdateInput!) {
  boothUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothSupercategoryCreateDocument = gql`
    mutation boothSupercategoryCreate($input: BoothSupercategoryCreateInput!, $locales: [Locale!]!) {
  boothSupercategoryCreate(input: $input) {
    success
    errors {
      message
    }
    boothSupercategory {
      ...BoothTaxonomyFragment
    }
  }
}
    ${BoothTaxonomyFragmentFragmentDoc}`;
export const BoothSupercategoryUpdateDocument = gql`
    mutation boothSupercategoryUpdate($input: BoothSupercategoryUpdateInput!) {
  boothSupercategoryUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothCategoryCreateDocument = gql`
    mutation boothCategoryCreate($input: BoothCategoryCreateInput!, $locales: [Locale!]!) {
  boothCategoryCreate(input: $input) {
    success
    errors {
      message
    }
    boothCategory {
      ...BoothTaxonomyFragment
    }
  }
}
    ${BoothTaxonomyFragmentFragmentDoc}`;
export const BoothCategoryUpdateDocument = gql`
    mutation boothCategoryUpdate($input: BoothCategoryUpdateInput!) {
  boothCategoryUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothTaxonomyDeleteDocument = gql`
    mutation boothTaxonomyDelete($input: BoothTaxonomyDeleteInput!) {
  boothTaxonomyDelete(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothDesignUpdateDocument = gql`
    mutation boothDesignUpdate($input: BoothDesignUpdateInput!) {
  boothDesignUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothRepresentativeUpdateDocument = gql`
    mutation boothRepresentativeUpdate($input: BoothRepresentativeUpdateInput!) {
  boothRepresentativeUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const EventUpdateDocument = gql`
    mutation eventUpdate($input: EventUpdateInput!) {
  eventUpdate(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const BoothDesignCreateDocument = gql`
    mutation boothDesignCreate($input: BoothDesignCreateInput!) {
  boothDesignCreate(input: $input) {
    success
    errors {
      message
    }
    boothDesign {
      id
    }
  }
}
    `;
export const BoothDesignDeleteDocument = gql`
    mutation boothDesignDelete($input: BoothDesignDeleteInput!) {
  boothDesignDelete(input: $input) {
    success
    errors {
      message
      __typename
    }
  }
}
    `;
export const BoothDesignImageButtonMasterElementCreateDocument = gql`
    mutation boothDesignImageButtonMasterElementCreate($input: BoothDesignImageButtonMasterElementCreateInput!, $locales: [Locale!]!) {
  boothDesignImageButtonMasterElementCreate(input: $input) {
    success
    errors {
      message
    }
    element {
      ...BoothDesignImageButtonMasterElementFragment
    }
  }
}
    ${BoothDesignImageButtonMasterElementFragmentFragmentDoc}`;
export const BoothCreateDocument = gql`
    mutation boothCreate($input: BoothCreateInput!, $locales: [Locale!]!) {
  boothCreate(input: $input) {
    booth {
      id
      name {
        ...LocalizableStringFragment
      }
      cmsUrl
    }
    success
    errors {
      message
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const BoothReorderDocument = gql`
    mutation boothReorder($input: BoothReorderInput!) {
  boothReorder(input: $input) {
    success
    errors {
      message
    }
  }
}
    `;
export const GetBoothBasicInfoDocument = gql`
    query getBoothBasicInfo($boothId: ID!, $locales: [Locale!]!) {
  node(id: $boothId) {
    ... on Booth {
      __typename
      id
      hall {
        __typename
        id
      }
      appliedDesignCodename
      meetupExhibitorManagementUrl
      reportToken
      meetupEnabled
      previewUrl
      boothDesign {
        id
      }
      name {
        ...LocalizableStringFragment
      }
      event {
        id
        features
        name {
          ...LocalizableStringFragment
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const GetBoothCategoriesDocument = gql`
    query getBoothCategories($boothId: ID!, $after: String) {
  node(id: $boothId) {
    ... on Booth {
      categories(after: $after, first: 50) {
        nodes {
          __typename
          id
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
}
    `;
export const GetBoothDesignElementsDocument = gql`
    query getBoothDesignElements($boothId: ID!, $locales: [Locale!]!) {
  node(id: $boothId) {
    ... on Booth {
      designElements {
        ...BoothDesignElementsFragment
      }
    }
  }
}
    ${BoothDesignElementsFragmentFragmentDoc}`;
export const GetBoothRootContentElementTabsDocument = gql`
    query getBoothRootContentElementTabs($boothId: ID!, $locales: [Locale!]!) {
  node(id: $boothId) {
    ... on Booth {
      rootContentElement {
        __typename
        tabs {
          nodes {
            id
            title {
              ...LocalizableStringFragment
            }
            masterElement {
              ... on BoothTabMasterElement {
                masterControlledTitle
                masterControlledBody
              }
            }
            rawElement {
              ... on BoothTabContentElement {
                title {
                  ...LocalizableStringFragment
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const GetBoothRootContentElementTabsBodyNodesIdListDocument = gql`
    query getBoothRootContentElementTabsBodyNodesIdList($tabId: ID!, $after: String) {
  node(id: $tabId) {
    ... on BoothTabContentElement {
      body(after: $after, first: 20) {
        nodes {
          id
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
}
    `;
export const GetBoothContentElementByIdDocument = gql`
    query getBoothContentElementById($id: ID!, $locales: [Locale!]!) {
  node(id: $id) {
    ...BoothContentElementFragment
  }
}
    ${BoothContentElementFragmentFragmentDoc}`;
export const GetBoothContentElementsByIdsDocument = gql`
    query getBoothContentElementsByIds($ids: [ID!]!, $locales: [Locale!]!) {
  nodes(ids: $ids) {
    ...BoothContentElementFragment
  }
}
    ${BoothContentElementFragmentFragmentDoc}`;
export const GetBoothLocalesDocument = gql`
    query getBoothLocales($boothId: ID!) {
  node(id: $boothId) {
    ... on Booth {
      event {
        locales
      }
    }
  }
}
    `;
export const GetBoothDesignElementsIdListDocument = gql`
    query getBoothDesignElementsIdList($boothId: ID!, $after: String) {
  node(id: $boothId) {
    ... on Booth {
      designElements(after: $after, first: 10) {
        nodes {
          id
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
}
    `;
export const GetBoothDesignElementDocument = gql`
    query getBoothDesignElement($id: ID!, $locales: [Locale!]!) {
  node(id: $id) {
    ...BoothDesignElementFramgment
  }
}
    ${BoothDesignElementFramgmentFragmentDoc}`;
export const GetBoothDesignElementsByIdsDocument = gql`
    query getBoothDesignElementsByIds($ids: [ID!]!, $locales: [Locale!]!) {
  nodes(ids: $ids) {
    ...BoothDesignElementFramgment
  }
}
    ${BoothDesignElementFramgmentFragmentDoc}`;
export const GetBoothEventTaxonomiesListDocument = gql`
    query getBoothEventTaxonomiesList($boothId: ID!, $after: String, $locales: [Locale!]!) {
  node(id: $boothId) {
    ... on Booth {
      event {
        boothTaxonomies(after: $after, first: 30) {
          nodes {
            ... on BoothTaxonomy {
              id
              ... on BoothCategory {
                name {
                  ...LocalizableStringFragment
                  ...LocalizableStringFragment
                }
                supercategory {
                  id
                }
              }
              ... on BoothSupercategory {
                name {
                  ...LocalizableStringFragment
                  ...LocalizableStringFragment
                }
              }
              __typename
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const GetBoothEventSupercategorySubcategoriesDocument = gql`
    query getBoothEventSupercategorySubcategories($id: ID!, $after: String) {
  node(id: $id) {
    ... on BoothSupercategory {
      id
      subcategories(after: $after, first: 50) {
        nodes {
          id
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
}
    `;
export const GetEventBasicInfoDocument = gql`
    query getEventBasicInfo($eventId: ID!) {
  node(id: $eventId) {
    ... on Event {
      id
      features
      locales
      defaultLocale
      domain
      reportToken
      meetupEventId
    }
  }
}
    `;
export const GetEventDetailsDocument = gql`
    query getEventDetails($eventId: ID!, $locales: [Locale!]!) {
  node(id: $eventId) {
    ... on Event {
      name {
        ...LocalizableStringFragment
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const GetNodeFileFieldDocument = gql`
    query getNodeFileField($objectId: ID!, $fieldName: String!, $locales: [Locale!]!) {
  localizableFileableBy(objectId: $objectId, fieldName: $fieldName) {
    ...LocalizableImageFragment
    ...LocalizableFileFragment
    ...LocalizableVideoFragment
  }
}
    ${LocalizableImageFragmentFragmentDoc}
${LocalizableFileFragmentFragmentDoc}
${LocalizableVideoFragmentFragmentDoc}`;
export const GetBoothEditEventLocalesDocument = gql`
    query getBoothEditEventLocales($boothId: ID!) {
  node(id: $boothId) {
    ... on Booth {
      event {
        defaultLocale
        locales
        domain
      }
    }
  }
}
    `;
export const GetBoothEditBasicDataDocument = gql`
    query getBoothEditBasicData($boothId: ID!, $locales: [Locale!]!) {
  node(id: $boothId) {
    ... on Booth {
      id
      name {
        ...LocalizableStringFragment
      }
      event {
        id
        features
        name {
          ...LocalizableStringFragment
        }
      }
      reportToken
      previewUrl
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const GetBoothEditContentElementTabDocument = gql`
    query getBoothEditContentElementTab($tabId: ID!, $locales: [Locale!]!) {
  node(id: $tabId) {
    ... on BoothTabContentElement {
      id
      title {
        ...LocalizableStringFragment
      }
      masterElement {
        ... on BoothTabMasterElement {
          masterControlledTitle
          masterControlledBody
        }
      }
      rawElement {
        ... on BoothTabContentElement {
          title {
            ...LocalizableStringFragment
          }
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const GetBoothEditContentElementTabBodyNodesDocument = gql`
    query getBoothEditContentElementTabBodyNodes($tabId: ID!, $locales: [Locale!]!, $after: String) {
  node(id: $tabId) {
    ... on BoothTabContentElement {
      body(after: $after) {
        nodes {
          id
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
}
    `;
export const GetBoothEditContentElementDocument = gql`
    query getBoothEditContentElement($id: ID!, $locales: [Locale!]!) {
  node(id: $id) {
    ...BoothContentElementFragment
  }
}
    ${BoothContentElementFragmentFragmentDoc}`;
export const GetBoothEditContentElementTabListDocument = gql`
    query getBoothEditContentElementTabList($boothId: ID!, $locales: [Locale!]!) {
  node(id: $boothId) {
    ... on Booth {
      rootContentElement {
        __typename
        tabs {
          nodes {
            id
            title {
              ...LocalizableStringFragment
            }
            masterElement {
              ... on BoothTabMasterElement {
                masterControlledTitle
                masterControlledBody
              }
            }
            rawElement {
              ... on BoothTabContentElement {
                title {
                  ...LocalizableStringFragment
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const GetBoothEditInfoDocument = gql`
    query getBoothEditInfo($boothId: ID!, $locales: [Locale!]!) {
  node(id: $boothId) {
    ... on Booth {
      logo {
        ...LocalizableImageFragment
      }
      name {
        ...LocalizableStringFragment
      }
      exhibitionName {
        ...LocalizableStringFragment
      }
      website
      description {
        ...LocalizableStringFragment
      }
      boothNumber
      tier {
        name {
          ...LocalizableStringFragment
        }
      }
    }
  }
}
    ${LocalizableImageFragmentFragmentDoc}
${LocalizableStringFragmentFragmentDoc}`;
export const GetBoothIdListByEventIdDocument = gql`
    query getBoothIdListByEventId($eventId: ID!, $after: String) {
  node(id: $eventId) {
    ... on Event {
      booths(after: $after, first: 30) {
        nodes {
          id
        }
        totalCount
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
}
    `;
export const GetBoothRowsDetailByIdsDocument = gql`
    query getBoothRowsDetailByIds($boothIds: [ID!]!, $locales: [Locale!]!) {
  nodes(ids: $boothIds) {
    ... on Booth {
      id
      name {
        ...LocalizableStringFragment
      }
      cmsUrl
      logo {
        ...LocalizableImageFragment
      }
      rowOrder
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}
${LocalizableImageFragmentFragmentDoc}`;
export const GetBoothCountDocument = gql`
    query getBoothCount($eventId: ID!, $locales: [Locale]!) {
  node(id: $eventId) {
    ... on Event {
      booths {
        totalCount
      }
    }
  }
}
    `;
export const GetHallListDocument = gql`
    query getHallList($eventId: ID!, $locales: [Locale!]!) {
  node(id: $eventId) {
    ... on Event {
      halls {
        nodes {
          name {
            ...LocalizableStringFragment
          }
          id
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const GetTierListDocument = gql`
    query getTierList($eventId: ID!, $locales: [Locale!]!) {
  node(id: $eventId) {
    ... on Event {
      boothTiers {
        nodes {
          name {
            ...LocalizableStringFragment
          }
          id
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}`;
export const BoothDesignSelectingGetBoothDesignLayersListByIdDocument = gql`
    query boothDesignSelectingGetBoothDesignLayersListById($id: ID!, $locales: [Locale!]!, $after: String) {
  node(id: $id) {
    ... on BoothDesign {
      canvas {
        layers(after: $after, first: 20) {
          nodes {
            id
            ...BoothDesignImageButtonMasterElementFragment
            ...BoothDesignContentAreaMasterElementFragment
            ...BoothDesignImageMasterElementFragment
            ...BoothDesignColorFillMasterElementFragment
            ...BoothDesignNetworkingWidgetMasterElementFragment
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
}
    ${BoothDesignImageButtonMasterElementFragmentFragmentDoc}
${BoothDesignContentAreaMasterElementFragmentFragmentDoc}
${BoothDesignImageMasterElementFragmentFragmentDoc}
${BoothDesignColorFillMasterElementFragmentFragmentDoc}
${BoothDesignNetworkingWidgetMasterElementFragmentFragmentDoc}`;
export const BoothDesignSelectingGetBoothDesignsNameListDocument = gql`
    query boothDesignSelectingGetBoothDesignsNameList($eventId: ID!, $locales: [Locale!]!, $after: String) {
  node(id: $eventId) {
    ... on Event {
      boothDesigns(after: $after, first: 20) {
        nodes {
          id
          name {
            ...LocalizableStringFragment
          }
          canvas {
            id
            aspectRatio {
              ...DisplayMeasurementFragment
            }
            height {
              ...DisplayMeasurementFragment
            }
            maxWidth {
              ...DisplayMeasurementFragment
            }
            positionX {
              ...DisplayMeasurementFragment
            }
            positionY {
              ...DisplayMeasurementFragment
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
}
    ${LocalizableStringFragmentFragmentDoc}
${DisplayMeasurementFragmentFragmentDoc}`;

export type SdkFunctionWrapper = <T>(action: () => Promise<T>) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = sdkFunction => sdkFunction();
export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    boothEditGetBoothDesign(variables: BoothEditGetBoothDesignQueryVariables): Promise<BoothEditGetBoothDesignQuery> {
      return withWrapper(() => client.request<BoothEditGetBoothDesignQuery>(print(BoothEditGetBoothDesignDocument), variables));
    },
    boothEditGetBoothDesignCanvas(variables: BoothEditGetBoothDesignCanvasQueryVariables): Promise<BoothEditGetBoothDesignCanvasQuery> {
      return withWrapper(() => client.request<BoothEditGetBoothDesignCanvasQuery>(print(BoothEditGetBoothDesignCanvasDocument), variables));
    },
    boothEditGetBoothDesignCanvasLayersList(variables: BoothEditGetBoothDesignCanvasLayersListQueryVariables): Promise<BoothEditGetBoothDesignCanvasLayersListQuery> {
      return withWrapper(() => client.request<BoothEditGetBoothDesignCanvasLayersListQuery>(print(BoothEditGetBoothDesignCanvasLayersListDocument), variables));
    },
    boothEditGetBoothDesignsList(variables: BoothEditGetBoothDesignsListQueryVariables): Promise<BoothEditGetBoothDesignsListQuery> {
      return withWrapper(() => client.request<BoothEditGetBoothDesignsListQuery>(print(BoothEditGetBoothDesignsListDocument), variables));
    },
    boothEditGetBoothCategoriesList(variables: BoothEditGetBoothCategoriesListQueryVariables): Promise<BoothEditGetBoothCategoriesListQuery> {
      return withWrapper(() => client.request<BoothEditGetBoothCategoriesListQuery>(print(BoothEditGetBoothCategoriesListDocument), variables));
    },
    boothEditGetEventTaxonomiesList(variables: BoothEditGetEventTaxonomiesListQueryVariables): Promise<BoothEditGetEventTaxonomiesListQuery> {
      return withWrapper(() => client.request<BoothEditGetEventTaxonomiesListQuery>(print(BoothEditGetEventTaxonomiesListDocument), variables));
    },
    boothEditGetLegacyDesignElements(variables: BoothEditGetLegacyDesignElementsQueryVariables): Promise<BoothEditGetLegacyDesignElementsQuery> {
      return withWrapper(() => client.request<BoothEditGetLegacyDesignElementsQuery>(print(BoothEditGetLegacyDesignElementsDocument), variables));
    },
    boothEditGetContentElementTab(variables: BoothEditGetContentElementTabQueryVariables): Promise<BoothEditGetContentElementTabQuery> {
      return withWrapper(() => client.request<BoothEditGetContentElementTabQuery>(print(BoothEditGetContentElementTabDocument), variables));
    },
    boothEditGetContentElementTabBodyNodes(variables: BoothEditGetContentElementTabBodyNodesQueryVariables): Promise<BoothEditGetContentElementTabBodyNodesQuery> {
      return withWrapper(() => client.request<BoothEditGetContentElementTabBodyNodesQuery>(print(BoothEditGetContentElementTabBodyNodesDocument), variables));
    },
    boothDesignColorFillElementUpdate(variables: BoothDesignColorFillElementUpdateMutationVariables): Promise<BoothDesignColorFillElementUpdateMutation> {
      return withWrapper(() => client.request<BoothDesignColorFillElementUpdateMutation>(print(BoothDesignColorFillElementUpdateDocument), variables));
    },
    boothDesignContentAreaElementUpdate(variables: BoothDesignContentAreaElementUpdateMutationVariables): Promise<BoothDesignContentAreaElementUpdateMutation> {
      return withWrapper(() => client.request<BoothDesignContentAreaElementUpdateMutation>(print(BoothDesignContentAreaElementUpdateDocument), variables));
    },
    boothDesignImageButtonElementUpdate(variables: BoothDesignImageButtonElementUpdateMutationVariables): Promise<BoothDesignImageButtonElementUpdateMutation> {
      return withWrapper(() => client.request<BoothDesignImageButtonElementUpdateMutation>(print(BoothDesignImageButtonElementUpdateDocument), variables));
    },
    boothEditGetRepresentativesList(variables: BoothEditGetRepresentativesListQueryVariables): Promise<BoothEditGetRepresentativesListQuery> {
      return withWrapper(() => client.request<BoothEditGetRepresentativesListQuery>(print(BoothEditGetRepresentativesListDocument), variables));
    },
    boothEditRepresentativeAdd(variables: BoothEditRepresentativeAddMutationVariables): Promise<BoothEditRepresentativeAddMutation> {
      return withWrapper(() => client.request<BoothEditRepresentativeAddMutation>(print(BoothEditRepresentativeAddDocument), variables));
    },
    boothEditRepresentativeDelete(variables: BoothEditRepresentativeDeleteMutationVariables): Promise<BoothEditRepresentativeDeleteMutation> {
      return withWrapper(() => client.request<BoothEditRepresentativeDeleteMutation>(print(BoothEditRepresentativeDeleteDocument), variables));
    },
    designEditorCanvasUpdate(variables: DesignEditorCanvasUpdateMutationVariables): Promise<DesignEditorCanvasUpdateMutation> {
      return withWrapper(() => client.request<DesignEditorCanvasUpdateMutation>(print(DesignEditorCanvasUpdateDocument), variables));
    },
    designEditorGetboothDesignById(variables: DesignEditorGetboothDesignByIdQueryVariables): Promise<DesignEditorGetboothDesignByIdQuery> {
      return withWrapper(() => client.request<DesignEditorGetboothDesignByIdQuery>(print(DesignEditorGetboothDesignByIdDocument), variables));
    },
    designEditorGetBoothDesignsListByEventId(variables: DesignEditorGetBoothDesignsListByEventIdQueryVariables): Promise<DesignEditorGetBoothDesignsListByEventIdQuery> {
      return withWrapper(() => client.request<DesignEditorGetBoothDesignsListByEventIdQuery>(print(DesignEditorGetBoothDesignsListByEventIdDocument), variables));
    },
    designEditorLayerDeleteById(variables: DesignEditorLayerDeleteByIdMutationVariables): Promise<DesignEditorLayerDeleteByIdMutation> {
      return withWrapper(() => client.request<DesignEditorLayerDeleteByIdMutation>(print(DesignEditorLayerDeleteByIdDocument), variables));
    },
    designEditorLayerImageButtonUpdate(variables: DesignEditorLayerImageButtonUpdateMutationVariables): Promise<DesignEditorLayerImageButtonUpdateMutation> {
      return withWrapper(() => client.request<DesignEditorLayerImageButtonUpdateMutation>(print(DesignEditorLayerImageButtonUpdateDocument), variables));
    },
    designEditorLayerImageButtonCreate(variables: DesignEditorLayerImageButtonCreateMutationVariables): Promise<DesignEditorLayerImageButtonCreateMutation> {
      return withWrapper(() => client.request<DesignEditorLayerImageButtonCreateMutation>(print(DesignEditorLayerImageButtonCreateDocument), variables));
    },
    designEditorLayerContentAreaUpdate(variables: DesignEditorLayerContentAreaUpdateMutationVariables): Promise<DesignEditorLayerContentAreaUpdateMutation> {
      return withWrapper(() => client.request<DesignEditorLayerContentAreaUpdateMutation>(print(DesignEditorLayerContentAreaUpdateDocument), variables));
    },
    designEditorLayerContentAreaCreate(variables: DesignEditorLayerContentAreaCreateMutationVariables): Promise<DesignEditorLayerContentAreaCreateMutation> {
      return withWrapper(() => client.request<DesignEditorLayerContentAreaCreateMutation>(print(DesignEditorLayerContentAreaCreateDocument), variables));
    },
    designEditorLayerImageUpdate(variables: DesignEditorLayerImageUpdateMutationVariables): Promise<DesignEditorLayerImageUpdateMutation> {
      return withWrapper(() => client.request<DesignEditorLayerImageUpdateMutation>(print(DesignEditorLayerImageUpdateDocument), variables));
    },
    designEditorLayerImageCreate(variables: DesignEditorLayerImageCreateMutationVariables): Promise<DesignEditorLayerImageCreateMutation> {
      return withWrapper(() => client.request<DesignEditorLayerImageCreateMutation>(print(DesignEditorLayerImageCreateDocument), variables));
    },
    designEditorLayerColorFillUpdate(variables: DesignEditorLayerColorFillUpdateMutationVariables): Promise<DesignEditorLayerColorFillUpdateMutation> {
      return withWrapper(() => client.request<DesignEditorLayerColorFillUpdateMutation>(print(DesignEditorLayerColorFillUpdateDocument), variables));
    },
    designEditorLayerColorFillCreate(variables: DesignEditorLayerColorFillCreateMutationVariables): Promise<DesignEditorLayerColorFillCreateMutation> {
      return withWrapper(() => client.request<DesignEditorLayerColorFillCreateMutation>(print(DesignEditorLayerColorFillCreateDocument), variables));
    },
    designEditorLayerNetworkingWidgetUpdate(variables: DesignEditorLayerNetworkingWidgetUpdateMutationVariables): Promise<DesignEditorLayerNetworkingWidgetUpdateMutation> {
      return withWrapper(() => client.request<DesignEditorLayerNetworkingWidgetUpdateMutation>(print(DesignEditorLayerNetworkingWidgetUpdateDocument), variables));
    },
    designEditorLayerNetworkingWidgetCreate(variables: DesignEditorLayerNetworkingWidgetCreateMutationVariables): Promise<DesignEditorLayerNetworkingWidgetCreateMutation> {
      return withWrapper(() => client.request<DesignEditorLayerNetworkingWidgetCreateMutation>(print(DesignEditorLayerNetworkingWidgetCreateDocument), variables));
    },
    boothTierCreate(variables: BoothTierCreateMutationVariables): Promise<BoothTierCreateMutation> {
      return withWrapper(() => client.request<BoothTierCreateMutation>(print(BoothTierCreateDocument), variables));
    },
    boothTierDelete(variables: BoothTierDeleteMutationVariables): Promise<BoothTierDeleteMutation> {
      return withWrapper(() => client.request<BoothTierDeleteMutation>(print(BoothTierDeleteDocument), variables));
    },
    boothTierUpdate(variables: BoothTierUpdateMutationVariables): Promise<BoothTierUpdateMutation> {
      return withWrapper(() => client.request<BoothTierUpdateMutation>(print(BoothTierUpdateDocument), variables));
    },
    eventEditBoothCreate(variables: EventEditBoothCreateMutationVariables): Promise<EventEditBoothCreateMutation> {
      return withWrapper(() => client.request<EventEditBoothCreateMutation>(print(EventEditBoothCreateDocument), variables));
    },
    eventEditGetBoothTiersList(variables: EventEditGetBoothTiersListQueryVariables): Promise<EventEditGetBoothTiersListQuery> {
      return withWrapper(() => client.request<EventEditGetBoothTiersListQuery>(print(EventEditGetBoothTiersListDocument), variables));
    },
    eventEditGetBoothsExportListByTierId(variables: EventEditGetBoothsExportListByTierIdQueryVariables): Promise<EventEditGetBoothsExportListByTierIdQuery> {
      return withWrapper(() => client.request<EventEditGetBoothsExportListByTierIdQuery>(print(EventEditGetBoothsExportListByTierIdDocument), variables));
    },
    eventEditGetBoothsList(variables: EventEditGetBoothsListQueryVariables): Promise<EventEditGetBoothsListQuery> {
      return withWrapper(() => client.request<EventEditGetBoothsListQuery>(print(EventEditGetBoothsListDocument), variables));
    },
    eventEditGetBoothsListByTierId(variables: EventEditGetBoothsListByTierIdQueryVariables): Promise<EventEditGetBoothsListByTierIdQuery> {
      return withWrapper(() => client.request<EventEditGetBoothsListByTierIdQuery>(print(EventEditGetBoothsListByTierIdDocument), variables));
    },
    eventEditGetBoothsListTotalCount(variables: EventEditGetBoothsListTotalCountQueryVariables): Promise<EventEditGetBoothsListTotalCountQuery> {
      return withWrapper(() => client.request<EventEditGetBoothsListTotalCountQuery>(print(EventEditGetBoothsListTotalCountDocument), variables));
    },
    eventEditGetBoothsNameListByIds(variables: EventEditGetBoothsNameListByIdsQueryVariables): Promise<EventEditGetBoothsNameListByIdsQuery> {
      return withWrapper(() => client.request<EventEditGetBoothsNameListByIdsQuery>(print(EventEditGetBoothsNameListByIdsDocument), variables));
    },
    eventEditSearchBooth(variables: EventEditSearchBoothQueryVariables): Promise<EventEditSearchBoothQuery> {
      return withWrapper(() => client.request<EventEditSearchBoothQuery>(print(EventEditSearchBoothDocument), variables));
    },
    eventEditGetBoothTaxonomiesList(variables: EventEditGetBoothTaxonomiesListQueryVariables): Promise<EventEditGetBoothTaxonomiesListQuery> {
      return withWrapper(() => client.request<EventEditGetBoothTaxonomiesListQuery>(print(EventEditGetBoothTaxonomiesListDocument), variables));
    },
    eventEditGetMasterTabById(variables: EventEditGetMasterTabByIdQueryVariables): Promise<EventEditGetMasterTabByIdQuery> {
      return withWrapper(() => client.request<EventEditGetMasterTabByIdQuery>(print(EventEditGetMasterTabByIdDocument), variables));
    },
    eventEditGetMasterTabsList(variables: EventEditGetMasterTabsListQueryVariables): Promise<EventEditGetMasterTabsListQuery> {
      return withWrapper(() => client.request<EventEditGetMasterTabsListQuery>(print(EventEditGetMasterTabsListDocument), variables));
    },
    boothDesignElementUpdate(variables: BoothDesignElementUpdateMutationVariables): Promise<BoothDesignElementUpdateMutation> {
      return withWrapper(() => client.request<BoothDesignElementUpdateMutation>(print(BoothDesignElementUpdateDocument), variables));
    },
    boothContentElementDelete(variables: BoothContentElementDeleteMutationVariables): Promise<BoothContentElementDeleteMutation> {
      return withWrapper(() => client.request<BoothContentElementDeleteMutation>(print(BoothContentElementDeleteDocument), variables));
    },
    boothDividerContentElementCreate(variables: BoothDividerContentElementCreateMutationVariables): Promise<BoothDividerContentElementCreateMutation> {
      return withWrapper(() => client.request<BoothDividerContentElementCreateMutation>(print(BoothDividerContentElementCreateDocument), variables));
    },
    boothDividerContentElementUpdate(variables: BoothDividerContentElementUpdateMutationVariables): Promise<BoothDividerContentElementUpdateMutation> {
      return withWrapper(() => client.request<BoothDividerContentElementUpdateMutation>(print(BoothDividerContentElementUpdateDocument), variables));
    },
    boothDividerMasterElementCreate(variables: BoothDividerMasterElementCreateMutationVariables): Promise<BoothDividerMasterElementCreateMutation> {
      return withWrapper(() => client.request<BoothDividerMasterElementCreateMutation>(print(BoothDividerMasterElementCreateDocument), variables));
    },
    boothDividerMasterElementUpdate(variables: BoothDividerMasterElementUpdateMutationVariables): Promise<BoothDividerMasterElementUpdateMutation> {
      return withWrapper(() => client.request<BoothDividerMasterElementUpdateMutation>(print(BoothDividerMasterElementUpdateDocument), variables));
    },
    boothFileContentElementCreate(variables: BoothFileContentElementCreateMutationVariables): Promise<BoothFileContentElementCreateMutation> {
      return withWrapper(() => client.request<BoothFileContentElementCreateMutation>(print(BoothFileContentElementCreateDocument), variables));
    },
    boothFileContentElementUpdate(variables: BoothFileContentElementUpdateMutationVariables): Promise<BoothFileContentElementUpdateMutation> {
      return withWrapper(() => client.request<BoothFileContentElementUpdateMutation>(print(BoothFileContentElementUpdateDocument), variables));
    },
    boothFileMasterElementCreate(variables: BoothFileMasterElementCreateMutationVariables): Promise<BoothFileMasterElementCreateMutation> {
      return withWrapper(() => client.request<BoothFileMasterElementCreateMutation>(print(BoothFileMasterElementCreateDocument), variables));
    },
    boothFileMasterElementUpdate(variables: BoothFileMasterElementUpdateMutationVariables): Promise<BoothFileMasterElementUpdateMutation> {
      return withWrapper(() => client.request<BoothFileMasterElementUpdateMutation>(print(BoothFileMasterElementUpdateDocument), variables));
    },
    boothImageContentElementCreate(variables: BoothImageContentElementCreateMutationVariables): Promise<BoothImageContentElementCreateMutation> {
      return withWrapper(() => client.request<BoothImageContentElementCreateMutation>(print(BoothImageContentElementCreateDocument), variables));
    },
    boothImageContentElementUpdate(variables: BoothImageContentElementUpdateMutationVariables): Promise<BoothImageContentElementUpdateMutation> {
      return withWrapper(() => client.request<BoothImageContentElementUpdateMutation>(print(BoothImageContentElementUpdateDocument), variables));
    },
    boothImageMasterElementCreate(variables: BoothImageMasterElementCreateMutationVariables): Promise<BoothImageMasterElementCreateMutation> {
      return withWrapper(() => client.request<BoothImageMasterElementCreateMutation>(print(BoothImageMasterElementCreateDocument), variables));
    },
    boothImageMasterElementUpdate(variables: BoothImageMasterElementUpdateMutationVariables): Promise<BoothImageMasterElementUpdateMutation> {
      return withWrapper(() => client.request<BoothImageMasterElementUpdateMutation>(print(BoothImageMasterElementUpdateDocument), variables));
    },
    boothMasterElementDelete(variables: BoothMasterElementDeleteMutationVariables): Promise<BoothMasterElementDeleteMutation> {
      return withWrapper(() => client.request<BoothMasterElementDeleteMutation>(print(BoothMasterElementDeleteDocument), variables));
    },
    boothQuestionContentElementCreate(variables: BoothQuestionContentElementCreateMutationVariables): Promise<BoothQuestionContentElementCreateMutation> {
      return withWrapper(() => client.request<BoothQuestionContentElementCreateMutation>(print(BoothQuestionContentElementCreateDocument), variables));
    },
    boothQuestionContentElementUpdate(variables: BoothQuestionContentElementUpdateMutationVariables): Promise<BoothQuestionContentElementUpdateMutation> {
      return withWrapper(() => client.request<BoothQuestionContentElementUpdateMutation>(print(BoothQuestionContentElementUpdateDocument), variables));
    },
    boothQuestionMasterElementCreate(variables: BoothQuestionMasterElementCreateMutationVariables): Promise<BoothQuestionMasterElementCreateMutation> {
      return withWrapper(() => client.request<BoothQuestionMasterElementCreateMutation>(print(BoothQuestionMasterElementCreateDocument), variables));
    },
    boothQuestionMasterElementUpdate(variables: BoothQuestionMasterElementUpdateMutationVariables): Promise<BoothQuestionMasterElementUpdateMutation> {
      return withWrapper(() => client.request<BoothQuestionMasterElementUpdateMutation>(print(BoothQuestionMasterElementUpdateDocument), variables));
    },
    boothRichtextContentElementCreate(variables: BoothRichtextContentElementCreateMutationVariables): Promise<BoothRichtextContentElementCreateMutation> {
      return withWrapper(() => client.request<BoothRichtextContentElementCreateMutation>(print(BoothRichtextContentElementCreateDocument), variables));
    },
    boothRichtextContentElementUpdate(variables: BoothRichtextContentElementUpdateMutationVariables): Promise<BoothRichtextContentElementUpdateMutation> {
      return withWrapper(() => client.request<BoothRichtextContentElementUpdateMutation>(print(BoothRichtextContentElementUpdateDocument), variables));
    },
    boothRichtextMasterElementCreate(variables: BoothRichtextMasterElementCreateMutationVariables): Promise<BoothRichtextMasterElementCreateMutation> {
      return withWrapper(() => client.request<BoothRichtextMasterElementCreateMutation>(print(BoothRichtextMasterElementCreateDocument), variables));
    },
    boothRichtextMasterElementUpdate(variables: BoothRichtextMasterElementUpdateMutationVariables): Promise<BoothRichtextMasterElementUpdateMutation> {
      return withWrapper(() => client.request<BoothRichtextMasterElementUpdateMutation>(print(BoothRichtextMasterElementUpdateDocument), variables));
    },
    boothTabContentElementCreate(variables: BoothTabContentElementCreateMutationVariables): Promise<BoothTabContentElementCreateMutation> {
      return withWrapper(() => client.request<BoothTabContentElementCreateMutation>(print(BoothTabContentElementCreateDocument), variables));
    },
    boothTabContentElementUpdate(variables: BoothTabContentElementUpdateMutationVariables): Promise<BoothTabContentElementUpdateMutation> {
      return withWrapper(() => client.request<BoothTabContentElementUpdateMutation>(print(BoothTabContentElementUpdateDocument), variables));
    },
    boothTabMasterElementCreate(variables: BoothTabMasterElementCreateMutationVariables): Promise<BoothTabMasterElementCreateMutation> {
      return withWrapper(() => client.request<BoothTabMasterElementCreateMutation>(print(BoothTabMasterElementCreateDocument), variables));
    },
    boothTabMasterElementUpdate(variables: BoothTabMasterElementUpdateMutationVariables): Promise<BoothTabMasterElementUpdateMutation> {
      return withWrapper(() => client.request<BoothTabMasterElementUpdateMutation>(print(BoothTabMasterElementUpdateDocument), variables));
    },
    boothVideoContentElementCreate(variables: BoothVideoContentElementCreateMutationVariables): Promise<BoothVideoContentElementCreateMutation> {
      return withWrapper(() => client.request<BoothVideoContentElementCreateMutation>(print(BoothVideoContentElementCreateDocument), variables));
    },
    boothVideoContentElementUpdate(variables: BoothVideoContentElementUpdateMutationVariables): Promise<BoothVideoContentElementUpdateMutation> {
      return withWrapper(() => client.request<BoothVideoContentElementUpdateMutation>(print(BoothVideoContentElementUpdateDocument), variables));
    },
    boothVideoMasterElementCreate(variables: BoothVideoMasterElementCreateMutationVariables): Promise<BoothVideoMasterElementCreateMutation> {
      return withWrapper(() => client.request<BoothVideoMasterElementCreateMutation>(print(BoothVideoMasterElementCreateDocument), variables));
    },
    boothVideoMasterElementUpdate(variables: BoothVideoMasterElementUpdateMutationVariables): Promise<BoothVideoMasterElementUpdateMutation> {
      return withWrapper(() => client.request<BoothVideoMasterElementUpdateMutation>(print(BoothVideoMasterElementUpdateDocument), variables));
    },
    confirmationEmailResend(variables: ConfirmationEmailResendMutationVariables): Promise<ConfirmationEmailResendMutation> {
      return withWrapper(() => client.request<ConfirmationEmailResendMutation>(print(ConfirmationEmailResendDocument), variables));
    },
    groupChatJoin(variables: GroupChatJoinMutationVariables): Promise<GroupChatJoinMutation> {
      return withWrapper(() => client.request<GroupChatJoinMutation>(print(GroupChatJoinDocument), variables));
    },
    pushNotification(variables: PushNotificationMutationVariables): Promise<PushNotificationMutation> {
      return withWrapper(() => client.request<PushNotificationMutation>(print(PushNotificationDocument), variables));
    },
    uploadFile(variables: UploadFileMutationVariables): Promise<UploadFileMutation> {
      return withWrapper(() => client.request<UploadFileMutation>(print(UploadFileDocument), variables));
    },
    boothUpdate(variables: BoothUpdateMutationVariables): Promise<BoothUpdateMutation> {
      return withWrapper(() => client.request<BoothUpdateMutation>(print(BoothUpdateDocument), variables));
    },
    boothSupercategoryCreate(variables: BoothSupercategoryCreateMutationVariables): Promise<BoothSupercategoryCreateMutation> {
      return withWrapper(() => client.request<BoothSupercategoryCreateMutation>(print(BoothSupercategoryCreateDocument), variables));
    },
    boothSupercategoryUpdate(variables: BoothSupercategoryUpdateMutationVariables): Promise<BoothSupercategoryUpdateMutation> {
      return withWrapper(() => client.request<BoothSupercategoryUpdateMutation>(print(BoothSupercategoryUpdateDocument), variables));
    },
    boothCategoryCreate(variables: BoothCategoryCreateMutationVariables): Promise<BoothCategoryCreateMutation> {
      return withWrapper(() => client.request<BoothCategoryCreateMutation>(print(BoothCategoryCreateDocument), variables));
    },
    boothCategoryUpdate(variables: BoothCategoryUpdateMutationVariables): Promise<BoothCategoryUpdateMutation> {
      return withWrapper(() => client.request<BoothCategoryUpdateMutation>(print(BoothCategoryUpdateDocument), variables));
    },
    boothTaxonomyDelete(variables: BoothTaxonomyDeleteMutationVariables): Promise<BoothTaxonomyDeleteMutation> {
      return withWrapper(() => client.request<BoothTaxonomyDeleteMutation>(print(BoothTaxonomyDeleteDocument), variables));
    },
    boothDesignUpdate(variables: BoothDesignUpdateMutationVariables): Promise<BoothDesignUpdateMutation> {
      return withWrapper(() => client.request<BoothDesignUpdateMutation>(print(BoothDesignUpdateDocument), variables));
    },
    boothRepresentativeUpdate(variables: BoothRepresentativeUpdateMutationVariables): Promise<BoothRepresentativeUpdateMutation> {
      return withWrapper(() => client.request<BoothRepresentativeUpdateMutation>(print(BoothRepresentativeUpdateDocument), variables));
    },
    eventUpdate(variables: EventUpdateMutationVariables): Promise<EventUpdateMutation> {
      return withWrapper(() => client.request<EventUpdateMutation>(print(EventUpdateDocument), variables));
    },
    boothDesignCreate(variables: BoothDesignCreateMutationVariables): Promise<BoothDesignCreateMutation> {
      return withWrapper(() => client.request<BoothDesignCreateMutation>(print(BoothDesignCreateDocument), variables));
    },
    boothDesignDelete(variables: BoothDesignDeleteMutationVariables): Promise<BoothDesignDeleteMutation> {
      return withWrapper(() => client.request<BoothDesignDeleteMutation>(print(BoothDesignDeleteDocument), variables));
    },
    boothDesignImageButtonMasterElementCreate(variables: BoothDesignImageButtonMasterElementCreateMutationVariables): Promise<BoothDesignImageButtonMasterElementCreateMutation> {
      return withWrapper(() => client.request<BoothDesignImageButtonMasterElementCreateMutation>(print(BoothDesignImageButtonMasterElementCreateDocument), variables));
    },
    boothCreate(variables: BoothCreateMutationVariables): Promise<BoothCreateMutation> {
      return withWrapper(() => client.request<BoothCreateMutation>(print(BoothCreateDocument), variables));
    },
    boothReorder(variables: BoothReorderMutationVariables): Promise<BoothReorderMutation> {
      return withWrapper(() => client.request<BoothReorderMutation>(print(BoothReorderDocument), variables));
    },
    getBoothBasicInfo(variables: GetBoothBasicInfoQueryVariables): Promise<GetBoothBasicInfoQuery> {
      return withWrapper(() => client.request<GetBoothBasicInfoQuery>(print(GetBoothBasicInfoDocument), variables));
    },
    getBoothCategories(variables: GetBoothCategoriesQueryVariables): Promise<GetBoothCategoriesQuery> {
      return withWrapper(() => client.request<GetBoothCategoriesQuery>(print(GetBoothCategoriesDocument), variables));
    },
    getBoothDesignElements(variables: GetBoothDesignElementsQueryVariables): Promise<GetBoothDesignElementsQuery> {
      return withWrapper(() => client.request<GetBoothDesignElementsQuery>(print(GetBoothDesignElementsDocument), variables));
    },
    getBoothRootContentElementTabs(variables: GetBoothRootContentElementTabsQueryVariables): Promise<GetBoothRootContentElementTabsQuery> {
      return withWrapper(() => client.request<GetBoothRootContentElementTabsQuery>(print(GetBoothRootContentElementTabsDocument), variables));
    },
    getBoothRootContentElementTabsBodyNodesIdList(variables: GetBoothRootContentElementTabsBodyNodesIdListQueryVariables): Promise<GetBoothRootContentElementTabsBodyNodesIdListQuery> {
      return withWrapper(() => client.request<GetBoothRootContentElementTabsBodyNodesIdListQuery>(print(GetBoothRootContentElementTabsBodyNodesIdListDocument), variables));
    },
    getBoothContentElementById(variables: GetBoothContentElementByIdQueryVariables): Promise<GetBoothContentElementByIdQuery> {
      return withWrapper(() => client.request<GetBoothContentElementByIdQuery>(print(GetBoothContentElementByIdDocument), variables));
    },
    getBoothContentElementsByIds(variables: GetBoothContentElementsByIdsQueryVariables): Promise<GetBoothContentElementsByIdsQuery> {
      return withWrapper(() => client.request<GetBoothContentElementsByIdsQuery>(print(GetBoothContentElementsByIdsDocument), variables));
    },
    getBoothLocales(variables: GetBoothLocalesQueryVariables): Promise<GetBoothLocalesQuery> {
      return withWrapper(() => client.request<GetBoothLocalesQuery>(print(GetBoothLocalesDocument), variables));
    },
    getBoothDesignElementsIdList(variables: GetBoothDesignElementsIdListQueryVariables): Promise<GetBoothDesignElementsIdListQuery> {
      return withWrapper(() => client.request<GetBoothDesignElementsIdListQuery>(print(GetBoothDesignElementsIdListDocument), variables));
    },
    getBoothDesignElement(variables: GetBoothDesignElementQueryVariables): Promise<GetBoothDesignElementQuery> {
      return withWrapper(() => client.request<GetBoothDesignElementQuery>(print(GetBoothDesignElementDocument), variables));
    },
    getBoothDesignElementsByIds(variables: GetBoothDesignElementsByIdsQueryVariables): Promise<GetBoothDesignElementsByIdsQuery> {
      return withWrapper(() => client.request<GetBoothDesignElementsByIdsQuery>(print(GetBoothDesignElementsByIdsDocument), variables));
    },
    getBoothEventTaxonomiesList(variables: GetBoothEventTaxonomiesListQueryVariables): Promise<GetBoothEventTaxonomiesListQuery> {
      return withWrapper(() => client.request<GetBoothEventTaxonomiesListQuery>(print(GetBoothEventTaxonomiesListDocument), variables));
    },
    getBoothEventSupercategorySubcategories(variables: GetBoothEventSupercategorySubcategoriesQueryVariables): Promise<GetBoothEventSupercategorySubcategoriesQuery> {
      return withWrapper(() => client.request<GetBoothEventSupercategorySubcategoriesQuery>(print(GetBoothEventSupercategorySubcategoriesDocument), variables));
    },
    getEventBasicInfo(variables: GetEventBasicInfoQueryVariables): Promise<GetEventBasicInfoQuery> {
      return withWrapper(() => client.request<GetEventBasicInfoQuery>(print(GetEventBasicInfoDocument), variables));
    },
    getEventDetails(variables: GetEventDetailsQueryVariables): Promise<GetEventDetailsQuery> {
      return withWrapper(() => client.request<GetEventDetailsQuery>(print(GetEventDetailsDocument), variables));
    },
    getNodeFileField(variables: GetNodeFileFieldQueryVariables): Promise<GetNodeFileFieldQuery> {
      return withWrapper(() => client.request<GetNodeFileFieldQuery>(print(GetNodeFileFieldDocument), variables));
    },
    getBoothEditEventLocales(variables: GetBoothEditEventLocalesQueryVariables): Promise<GetBoothEditEventLocalesQuery> {
      return withWrapper(() => client.request<GetBoothEditEventLocalesQuery>(print(GetBoothEditEventLocalesDocument), variables));
    },
    getBoothEditBasicData(variables: GetBoothEditBasicDataQueryVariables): Promise<GetBoothEditBasicDataQuery> {
      return withWrapper(() => client.request<GetBoothEditBasicDataQuery>(print(GetBoothEditBasicDataDocument), variables));
    },
    getBoothEditContentElementTab(variables: GetBoothEditContentElementTabQueryVariables): Promise<GetBoothEditContentElementTabQuery> {
      return withWrapper(() => client.request<GetBoothEditContentElementTabQuery>(print(GetBoothEditContentElementTabDocument), variables));
    },
    getBoothEditContentElementTabBodyNodes(variables: GetBoothEditContentElementTabBodyNodesQueryVariables): Promise<GetBoothEditContentElementTabBodyNodesQuery> {
      return withWrapper(() => client.request<GetBoothEditContentElementTabBodyNodesQuery>(print(GetBoothEditContentElementTabBodyNodesDocument), variables));
    },
    getBoothEditContentElement(variables: GetBoothEditContentElementQueryVariables): Promise<GetBoothEditContentElementQuery> {
      return withWrapper(() => client.request<GetBoothEditContentElementQuery>(print(GetBoothEditContentElementDocument), variables));
    },
    getBoothEditContentElementTabList(variables: GetBoothEditContentElementTabListQueryVariables): Promise<GetBoothEditContentElementTabListQuery> {
      return withWrapper(() => client.request<GetBoothEditContentElementTabListQuery>(print(GetBoothEditContentElementTabListDocument), variables));
    },
    getBoothEditInfo(variables: GetBoothEditInfoQueryVariables): Promise<GetBoothEditInfoQuery> {
      return withWrapper(() => client.request<GetBoothEditInfoQuery>(print(GetBoothEditInfoDocument), variables));
    },
    getBoothIdListByEventId(variables: GetBoothIdListByEventIdQueryVariables): Promise<GetBoothIdListByEventIdQuery> {
      return withWrapper(() => client.request<GetBoothIdListByEventIdQuery>(print(GetBoothIdListByEventIdDocument), variables));
    },
    getBoothRowsDetailByIds(variables: GetBoothRowsDetailByIdsQueryVariables): Promise<GetBoothRowsDetailByIdsQuery> {
      return withWrapper(() => client.request<GetBoothRowsDetailByIdsQuery>(print(GetBoothRowsDetailByIdsDocument), variables));
    },
    getBoothCount(variables: GetBoothCountQueryVariables): Promise<GetBoothCountQuery> {
      return withWrapper(() => client.request<GetBoothCountQuery>(print(GetBoothCountDocument), variables));
    },
    getHallList(variables: GetHallListQueryVariables): Promise<GetHallListQuery> {
      return withWrapper(() => client.request<GetHallListQuery>(print(GetHallListDocument), variables));
    },
    getTierList(variables: GetTierListQueryVariables): Promise<GetTierListQuery> {
      return withWrapper(() => client.request<GetTierListQuery>(print(GetTierListDocument), variables));
    },
    boothDesignSelectingGetBoothDesignLayersListById(variables: BoothDesignSelectingGetBoothDesignLayersListByIdQueryVariables): Promise<BoothDesignSelectingGetBoothDesignLayersListByIdQuery> {
      return withWrapper(() => client.request<BoothDesignSelectingGetBoothDesignLayersListByIdQuery>(print(BoothDesignSelectingGetBoothDesignLayersListByIdDocument), variables));
    },
    boothDesignSelectingGetBoothDesignsNameList(variables: BoothDesignSelectingGetBoothDesignsNameListQueryVariables): Promise<BoothDesignSelectingGetBoothDesignsNameListQuery> {
      return withWrapper(() => client.request<BoothDesignSelectingGetBoothDesignsNameListQuery>(print(BoothDesignSelectingGetBoothDesignsNameListDocument), variables));
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;