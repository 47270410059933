import { Epic } from 'redux-observable';
import {
  filter, ignoreElements, map, mergeMap,
} from 'rxjs/operators';
import {
  concat, merge, of,
} from 'rxjs';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import {
  catchErrorToPopup,
  throwCommonError,
} from 'redux/mutationFailedPipe';
import cmsSelectors from 'redux/cmsSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import produce from 'immer';

const updateBoothEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.fragments.booths.mutations.updateBooth.match),
  mergeMap(({ payload: input }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);

    const originalEventEditFactory = () => eventEditSelectors.selectEventEdit(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.fragments.booths.mutations.updateBooth(),
        ExecutingTaskCommonTag.Mutation],
    });

    const locale = eventEditSelectors.selectEditLocale(state$.value);

    const internalUpdateAction = eventEditActions.internalUpdate(produce(originalEventEditFactory(), ((draft) => {
      const booth = draft.fragments.boothsV2.pagedBoothsList.find((it) => it.id === input.id);
      if (input.published !== undefined) booth.published = input.published;
      if (input.name !== undefined) booth.name = input.name;
      if (input.designId !== undefined) booth.boothDesign = { id: input.designId } as any;
    })));

    const requests = sdk.boothUpdate({ input }).pipe(
      throwCommonError(),
      ignoreElements(),
      catchErrorToPopup(state$.value.intl),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return concat(
      of(internalUpdateAction),
      merge(requests),
    );
  }),
);

export default updateBoothEpic;
