import { filter, map, mergeMap } from 'rxjs/operators';
import createDesignEditorInstanceSelectors
  from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceSelectors';
import {
  concat, defer, Observable, of,
} from 'rxjs';
import createDesignEditorInstanceActions, { rawDesignEditorInstanceActions } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceActions';
import { Epic } from 'redux-observable';
import { CmsSDKObservables } from 'utils/auth';
import { BoothDesignMasterElement } from 'models';
import { ExecutingTask } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceState';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import { LayerElementType } from 'libs/xtra-custom-booth-design/types/DesignEditorInstance';
import cmsSelectors from 'redux/cmsSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';

const createRequest: (layer: BoothDesignMasterElement, boothDesignId: string, dest: number, sdk: CmsSDKObservables) => Observable<any> = (
  layer: BoothDesignMasterElement, boothDesignId: string, dest: number, sdk,
) => {
  const commonInput = {
    elementId: layer.id,
    boothDesignId,
  };

  switch (layer.__typename) {
    case LayerElementType.BoothDesignImageButtonMasterElement:
      return sdk.designEditorLayerImageButtonUpdate({
        input: {
          ...commonInput,
          zOrder: dest,
          hoverStateImage: { values: [] },
          normalStateImage: { values: [] },
        },
      });
    case LayerElementType.BoothDesignColorFillMasterElement:
      return sdk.designEditorLayerColorFillUpdate({ input: { ...commonInput, zOrder: dest } });
    case LayerElementType.BoothDesignImageMasterElement:
      return sdk.designEditorLayerImageUpdate({ input: { ...commonInput, zOrder: dest } });
    case LayerElementType.BoothDesignContentAreaMasterElement:
      return sdk.designEditorLayerContentAreaUpdate({ input: { ...commonInput, zOrder: dest } });
    case LayerElementType.BoothDesignNetworkingWidgetMasterElement:
      return sdk.designEditorLayerNetworkingWidgetUpdate({ input: { ...commonInput, zOrder: dest } });
    default:
      throw new Error('Not implemented');
  }
};

/**
 * Reorder layer epic
 */
const reorderLayerEpic: Epic = (action$, state$) => action$.pipe(
  filter(rawDesignEditorInstanceActions.reorderLayer.match),
  map((action) => action.payload),
  mergeMap(({ instanceDesignId, data }) => {
    const { selectLayers, selectDesign } = createDesignEditorInstanceSelectors(instanceDesignId);
    const { internalUpdateLayers, internalAddSequencedRequest } = createDesignEditorInstanceActions(instanceDesignId);
    const executingTask: ExecutingTask = createExecutingTask({
      name: 'Updating layer',
      progress: -1,
      tags: [ExecutingTaskCommonTag.Mutation],
    });

    const layers = selectLayers(state$.value);
    const newLayers = [...layers];
    const { source, dest } = data;
    newLayers.splice(dest, 0, newLayers.splice(source, 1)[0]);

    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const request = defer(() => createRequest(layers[source], instanceDesignId, dest, sdk)).pipe(
      throwCommonError(),
      map(() => eventEditActions.executingTasks.removeExecutingTasks(executingTask.id)),
      catchErrorToPopup(state$.value.intl),
    );

    return concat(
      of(eventEditActions.executingTasks.upsertExecutingTasks(executingTask)),
      of(internalUpdateLayers(newLayers)),
      of(internalAddSequencedRequest(request)),
    );
  }),
);

export default reorderLayerEpic;
