import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { Fileable, LocalizableFileable, QueryLocalizableFileableByArgs } from 'models';
import { extractByLocale } from 'utils/localizableUtil';
import { interval } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import ImageFileContentPreviewer
  from 'libs/xtra-custom-booth-design/components/shared/XtraDropZoneDialog/FilePreviewer/fileContentPreviewers/ImageFileContentPreviewer';
import NoPreviewFileContentPreviewer
  from 'libs/xtra-custom-booth-design/components/shared/XtraDropZoneDialog/FilePreviewer/fileContentPreviewers/NoPreviewFileContentPreviewer';
import VideoFileContentPreviewer
  from 'libs/xtra-custom-booth-design/components/shared/XtraDropZoneDialog/FilePreviewer/fileContentPreviewers/VideoFileContentPreviewer';
import { useSelector } from 'react-redux';
import cmsSelectors from 'redux/cmsSelectors';

export interface FilePreviewerProps {
  file: Fileable;
  /**
   * Use for automatically refresh the url with the locale
   * Null will disable it
   */
  fileField?: QueryLocalizableFileableByArgs;
  locale?: string;
  direction?: 'column' | 'row';
}

const FilePreviewer: FC<FilePreviewerProps> = ({
  file, fileField, locale, direction = 'column',
}) => {
  const [filePreview, setFilePreview] = useState<Fileable>(file);
  const sdk = useSelector(cmsSelectors.selectAuthorizedSDKObservables);

  useEffect(() => {
    if (fileField != null) {
      // If  file origin props is not empty, and the props is not a "uploading temporary placeholder"
      // then we fetch the preview file every 4 minute
      const disposable = interval(1000 * 60 * 4).pipe(
        mergeMap(() => sdk.getNodeFileField({ ...fileField, locales: [locale] })),
        map((it) => extractByLocale<Fileable>(it.localizableFileableBy as LocalizableFileable, locale)),
      ).subscribe(setFilePreview);

      return () => disposable;
    }

    if (filePreview !== file) setFilePreview(file);
    return undefined;
  }, [locale, fileField, file, filePreview, sdk]);

  const Previewer = useMemo(() => {
    if (filePreview.contentType.startsWith('image/')) return ImageFileContentPreviewer;
    if (filePreview.contentType.startsWith('video/')) return VideoFileContentPreviewer;
    return NoPreviewFileContentPreviewer;
  }, [filePreview.contentType]);

  return (
    <Previewer filePreview={filePreview} direction={direction} />
  );
};

export default FilePreviewer;
