import React, { FC, useContext, useMemo } from 'react';
import {
  FormControl, InputLabel, MenuItem, Select,
} from '@material-ui/core';
import ISO6391 from 'iso-639-1';
import { ContentLocalizationContext } from 'components/localization/ContentLocalizationContext';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const StyledFormControl = styled(FormControl)`
  min-width: 200px;
`;

const ContentLocaleSelector: FC = () => {
  const { locale, setLocale, eventLocales } = useContext(ContentLocalizationContext);

  const selectLocale = useMemo(() => (newLocale: string) => {
    setLocale(newLocale);
  }, [setLocale]);

  const localeFormater = (localeCode: string) => `${ISO6391.getName(localeCode.split('_')[0].split('-')[0])}(${localeCode})`;

  return setLocale && eventLocales && (
    <div>
      <StyledFormControl>
        <InputLabel>
          <FormattedMessage id="common.content_language" defaultMessage="Content Language" />
        </InputLabel>
        <Select value={locale ?? ''} onChange={(e) => selectLocale(e.target.value as string)}>
          {eventLocales.map((availableLocale) => (
            <MenuItem key={availableLocale} value={availableLocale}>{localeFormater(availableLocale)}</MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    </div>
  );
};

export default ContentLocaleSelector;
