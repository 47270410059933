import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const DockerToolContainer = styled.div`
  background: #3d3d3d;
  z-index: 1;
  flex-grow: 0;
  flex-shrink: 0;
  border: 2px #5a5a5a solid;
`;

export const DockerCommonInnerContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
`;
