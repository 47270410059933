// in src/createAdminStore.js
import {
  applyMiddleware, combineReducers, compose, createStore,
} from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createEpicMiddleware } from 'redux-observable';
import { DESIGN_EDITOR_STORE_NAME } from 'libs/xtra-custom-booth-design/redux/designEditorVars';
import designEditorReducer from 'libs/xtra-custom-booth-design/redux/designEditorReducer';
import designEditorEpics from 'libs/xtra-custom-booth-design/redux/designEditorEpics';
import { BOOTH_EDIT_STORE_NAME } from 'admin-data/booth/BoothEdit/redux/boothEditVars';
import boothEditReducer from 'admin-data/booth/BoothEdit/redux/boothEditReducer';
import boothEditActionEpics from 'admin-data/booth/BoothEdit/redux/epics';
import eventEditEpics from 'admin-data/event/EventEdit/redux/epic';
import { EVENT_EDIT_STORE_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import eventEditReducer from 'admin-data/event/EventEdit/redux/eventEditReducer';
import { CMS_STORE_NAME } from 'redux/cmsVars';
import cmsReducer from 'redux/cmsReducer';
import cmsEpics from 'redux/epics';
import webinarReducer from 'admin-data/webinar/redux/webinars';
import trackReducer from 'admin-data/webinar/redux/tracks';
import speakerReducer from 'admin-data/webinar/redux/speakers';
import { intlReducer } from 'react-intl-redux';
import thunk from 'redux-thunk';

export default ({
  history,
}) => {
  const reducer = combineReducers({
    router: connectRouter(history),
    [DESIGN_EDITOR_STORE_NAME]: designEditorReducer,
    [EVENT_EDIT_STORE_NAME]: eventEditReducer,
    [CMS_STORE_NAME]: cmsReducer,
    [BOOTH_EDIT_STORE_NAME]: boothEditReducer,
    webinars: webinarReducer,
    tracks: trackReducer,
    speakers: speakerReducer,
    intl: intlReducer,
  });

  const composeEnhancers = (process.env.NODE_ENV === 'development'
    && typeof window !== 'undefined'
    && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    }))
    || compose;

  const epicMiddleware = createEpicMiddleware();

  const store = createStore(
    reducer,
    { /* set your initial state here */ },
    composeEnhancers(
      applyMiddleware(
        routerMiddleware(history),
        epicMiddleware,
        thunk,
      ),
    ),
  );
  epicMiddleware.run(designEditorEpics);
  epicMiddleware.run(boothEditActionEpics);
  epicMiddleware.run(eventEditEpics);
  epicMiddleware.run(cmsEpics);
  return store;
};
