import { Epic } from 'redux-observable';
import {
  catchError, filter, finalize, first, last, map, mergeMap, retry, switchMap, takeUntil, tap,
} from 'rxjs/operators';
import eventEditBoothsV2Actions
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/eventEditBoothsV2Actions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import {
  concat, defer, from, of,
} from 'rxjs';
import cmsSelectors from 'redux/cmsSelectors';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import {
  BULK_TASKS_PROGRESS_DIALOG_POPUP_KEY,
  BulkTaskProgress,
  BulkTasksProgressDialogData,
  BulkTaskState,
} from 'utils/xtra-popups/popupsTypes/bulkTasksProgressDialogData';
import { ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import cmsActions from 'redux/cmsActions';
import produce from 'immer';
import { Popup } from 'utils/xtra-popups/popup';
import { Booth, BoothCreateInput } from 'models';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import actions from 'redux-form/lib/actions';

const concurrentTasksLimit = 1;

const bulkActionDialogInitialStateFactory = (boothsCreateInputs: BoothCreateInput[]) => {
  const id = uuidv4();
  return ({
    id,
    type: BULK_TASKS_PROGRESS_DIALOG_POPUP_KEY,
    data: {
      title: 'Add booths',
      tasksProgress: boothsCreateInputs.map((input, index) => ({
        id: uuidv4(),
        name: `Create booth (${index + 1}/${boothsCreateInputs.length})`,
        progress: -1,
        tags: [ExecutingTaskCommonTag.Mutation],
        state: BulkTaskState.Waiting,
        error: [],
      } as BulkTaskProgress)),
      finished: false,
      doneAction: eventEditActions.fragments.booths.queries.queryBoothTiersList(),
      cancelAction: cmsActions.popups.createPopups.confirmationDialog(
        'Confirm cancel action', 'Are you sure to cancel the action?',
        cmsActions.popups.bulkTasks.cancel(id),
      ),
    },
  } as Popup<BulkTasksProgressDialogData>);
};

const addBoothsEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.fragments.booths.mutations.addBooths.match),
  mergeMap(({ payload: boothsCreateInputs }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);

    let lastDialog = bulkActionDialogInitialStateFactory(boothsCreateInputs);
    const createUpdateTaskProgressAction = (index: number, state: BulkTaskState, error?: string[]) => {
      lastDialog = produce(lastDialog, (draft) => {
        const taskProgress = draft.data.tasksProgress[index];
        taskProgress.state = state;
        if (error !== undefined) {
          taskProgress.error = error;
        }
      });
      return cmsActions.popups.upsertPopup(lastDialog);
    };

    const cancelAction$ = action$.pipe(
      filter(cmsActions.popups.bulkTasks.cancel.match),
      filter(({ payload: dialogId }) => dialogId === lastDialog.id),
      first(),
    );

    const requests = from(boothsCreateInputs).pipe(
      mergeMap((input, index) => concat(
        defer(() => of(createUpdateTaskProgressAction(index, BulkTaskState.InProgress))),
        sdk.eventEditBoothCreate({ input }).pipe(
          retry(2),
          throwCommonError(),
          map(() => createUpdateTaskProgressAction(index, BulkTaskState.Done)),
          tap({ error: (err) => console.error(err) }),
          catchError((err) => of(createUpdateTaskProgressAction(index, BulkTaskState.Error, [err.message]))),
        ),
      ), concurrentTasksLimit),
      takeUntil(cancelAction$),
    );

    return concat(
      concat(
        requests,
        defer(() => {
          lastDialog = produce(lastDialog, (draft) => {
            draft.data.finished = true;
          });
          return of(cmsActions.popups.upsertPopup(lastDialog));
        }),
      ).pipe(takeUntil(cancelAction$)),
      of(eventEditActions.fragments.booths.queries.queryBoothTiersList()),
    );
  }),
);

export default addBoothsEpic;
