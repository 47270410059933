import DesignEditorTransformDocker from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorTransformDocker';
import React from 'react';
import DesignEditorLayerAttributesDocker from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayerAttributesDocker';
import DesignEditorCanvasToolboxDocker from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorCanvasToolboxDocker';
import DesignEditorLayersDocker from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayersDocker';
import DesignEditorLocaleDocker from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLocaleDocker';
import DesignEditorCanvasAttributesDocker from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorCanvasAttributesDocker';
import { FormattedMessage } from 'react-intl';

export enum DockerTypes {
  CanvasAttributes = 'CanvasAttributes',
  Layers = 'Layers',
  LayerAttributes = 'LayerAttributes',
  Transform = 'Transform',
  Toolbox = 'Toolbox',
  Locale = 'Locale'
}

const dockers = [
  {
    id: DockerTypes.Layers,
    title: 'Layers',
    TitleBarComponent: () => (
      <div><FormattedMessage id="event.design.docker.layers.title" defaultMessage="Layers" /></div>
    ),
    DockerContentComponent: DesignEditorLayersDocker,
  },
  {
    id: DockerTypes.LayerAttributes,
    title: 'Layer Attributes',
    TitleBarComponent: () => (
      <div><FormattedMessage id="event.design.docker.layer_attributes.title" defaultMessage="Layer Attributes" /></div>
    ),
    DockerContentComponent: DesignEditorLayerAttributesDocker,
  },
  {
    id: DockerTypes.Transform,
    title: 'Transform',
    TitleBarComponent: () => (
      <div><FormattedMessage id="event.design.docker.transform.title" defaultMessage="Transform" /></div>
    ),
    DockerContentComponent: DesignEditorTransformDocker,
  },
  {
    id: DockerTypes.Toolbox,
    title: 'Toolbox',
    TitleBarComponent: () => (
      <div><FormattedMessage id="event.design.docker.toolbox.title" defaultMessage="Toolbox" /></div>
    ),
    DockerContentComponent: DesignEditorCanvasToolboxDocker,
  },
  {
    id: DockerTypes.Locale,
    title: 'Locale',
    TitleBarComponent: () => (
      <div><FormattedMessage id="event.design.docker.locale.title" defaultMessage="Locale" /></div>
    ),
    DockerContentComponent: DesignEditorLocaleDocker,
  },
  {
    id: DockerTypes.CanvasAttributes,
    title: 'Canvas Attributes',
    TitleBarComponent: () => (
      <div><FormattedMessage id="event.design.docker.canvas_attributes.title" defaultMessage="Canvas Attributes" /></div>
    ),
    DockerContentComponent: DesignEditorCanvasAttributesDocker,
  },
];
const dockersMap: { [type in DockerTypes]: any } = dockers.reduce((obj, next) => {
  obj[next.id] = next;
  return obj;
}, {}) as any;

export default dockersMap;
