import React, {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
} from '@material-ui/core';
import { Fileable, Image, QueryLocalizableFileableByArgs } from 'models';
import { FormattedMessage } from 'react-intl';
import {
  StyledDropZoneDialogContent,
  StyledXtraDropZoneV2,
} from 'libs/xtra-custom-booth-design/components/shared/XtraDropZoneDialog/style';

export interface XtraDropZoneDialogProps {
  open: boolean;
  onCancel: () => any;
  onUpload: (file: File, preview: Fileable) => any;
  title: string;

  previousFile?: Fileable;
  previousFileField?: QueryLocalizableFileableByArgs;
  locale?: string;

  accept?: string | string[];

  uploadHints?: string;
}

const XtraDropZoneDialog: FC<XtraDropZoneDialogProps> = ({
  open, onCancel, title, onUpload, previousFile, previousFileField, accept, locale, uploadHints,
}) => {
  const [file, setFile] = useState<File>(null);

  const filePreview = useMemo(() => {
    if (file == null) return previousFile;

    const preview: Partial<Fileable> = {
      contentType: file.type,
      fileUrl: URL.createObjectURL(file),
      filename: file.name,
      isUploading: true,
    };
    if (preview.contentType.startsWith('image/')) {
      (preview as Image).originalImageUrl = preview.fileUrl;
    }
    return preview as Fileable;
  }, [file, previousFile]);

  const onUploadButtonClick = useCallback(() => {
    onUpload(file, filePreview);
    setFile(null);
  }, [file, filePreview, onUpload]);

  const onCancelButtonClick = useCallback(() => {
    onCancel();
    setFile(null);
  }, [onCancel]);

  const onDrop = useCallback((uploadingFile: File) => {
    setFile(uploadingFile);
  }, []);

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <StyledDropZoneDialogContent>
          <StyledXtraDropZoneV2
            onUpload={onDrop}
            file={filePreview}
            accept={accept}
            previewDirection="column"
            locale={locale}
            uploadHints={uploadHints}
          />
        </StyledDropZoneDialogContent>
      </DialogContent>

      <DialogActions>
        <Button onClick={onCancelButtonClick} color="primary">
          <FormattedMessage id="common.cancel" defaultMessage="Cancel" />
        </Button>
        <Button disabled={file == null} onClick={onUploadButtonClick} color="primary" variant="contained">
          <FormattedMessage id="common.upload" defaultMessage="Upload" />
        </Button>
      </DialogActions>

    </Dialog>
  );
};

export default XtraDropZoneDialog;
