import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, tap,
} from 'rxjs/operators';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import { localizableFieldDefaultLocalePatcher, mapToSimpleMergeBoothAction, boothEditMutationCompleteObservable } from 'admin-data/booth/BoothEdit/redux/epics/queries/utils';
import { createExecutingTask } from 'utils/xtra-executing-tasks/executingTask';
import { boothEditExecutingTasksTagCreator } from 'admin-data/booth/BoothEdit/redux/boothEditExecutingTasks';
import { concat } from 'rxjs';
import cmsSelectors from 'redux/cmsSelectors';

const queryContentTabsListEpic: Epic = (action$, state$) => action$.pipe(
  filter(boothEditActions.queries.queryContentTabsList.match),
  mergeMap(() => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const boothId = boothEditSelectors.selectBoothId(state$.value);
    const originalBoothFactory = () => boothEditSelectors.selectBooth(state$.value);
    const locale = boothEditSelectors.selectLocale(state$.value);

    const taskFactory = () => createExecutingTask({ tags: [boothEditExecutingTasksTagCreator.queries.queryContentTabsList()] });

    const requests = sdk
      .getBoothEditContentElementTabList({ boothId, locales: [locale] })
      .pipe(
        tap((resp) => localizableFieldDefaultLocalePatcher([locale], resp)),
        map(mapToSimpleMergeBoothAction(originalBoothFactory)),
      );

    return concat(
      boothEditMutationCompleteObservable(action$, state$),
      requests,
    ).pipe(boothEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory));
  }),
);

export default queryContentTabsListEpic;
