import {
  BoothContentElement,
  BoothFileContentElement,
  BoothFileContentElementUpdateInput,
  BoothImageContentElement,
  BoothImageContentElementUpdateInput,
  BoothQuestionContentElement,
  BoothQuestionContentElementUpdateInput,
  BoothRichtextContentElement,
  BoothRichtextContentElementUpdateInput,
  BoothTabContentElement,
  BoothTabContentElementUpdateInput,
  BoothVideoContentElement,
  BoothVideoContentElementUpdateInput,
} from 'models';
import { createUpdateParserBuilder } from 'utils/redux-field-bind/updateParser/updateParserBuilder';

let contentElementUpdateParser;

const questionContentElementUpdateParser = createUpdateParserBuilder<BoothQuestionContentElement, { boothId: string, element: BoothQuestionContentElement }>((builder0) => {
  builder0.handler(((element) => element.answer),
    (state$, attributes, source, value) => contentElementUpdateParser(state$, {
      ...attributes,
      element: attributes.element.answer,
    }, source, value));

  builder0.handler((element) => element.rawElement as BoothQuestionContentElement,
    ((state$, attributes, source, value) => ({
      requestFnProvider: (sdk) => sdk.boothQuestionContentElementUpdate,
      batchIdentifier: `questionContentElementUpdate-${attributes.element.id}`,
      fields: { [source]: value },
      inputModifier: (input: BoothQuestionContentElementUpdateInput) => ({
        ...input,
        boothId: attributes.boothId,
        elementId: attributes.element.id,
      } as BoothQuestionContentElementUpdateInput),
    })));
});

const imageContentElementUpdateParser = createUpdateParserBuilder<BoothImageContentElement, { boothId: string, element: BoothImageContentElement }>((builder0) => {
  builder0.handler(((element) => element.rawElement as BoothImageContentElement),
    ((state$, attributes, source, value) => ({
      requestFnProvider: (sdk) => sdk.boothImageContentElementUpdate,
      batchIdentifier: `imageContentElementUpdate-${attributes.element.id}`,
      fields: { [source]: value },
      inputModifier: (input: BoothImageContentElement) => ({
        ...input,
        boothId: attributes.boothId,
        elementId: attributes.element.id,
      } as BoothImageContentElementUpdateInput),
    })));
});

const videoContentElementUpdateParser = createUpdateParserBuilder<BoothVideoContentElement, { boothId: string, element: BoothVideoContentElement }>((builder0) => {
  builder0.handler(((element) => element.rawElement as BoothVideoContentElement),
    ((state$, attributes, source, value) => ({
      requestFnProvider: (sdk) => sdk.boothVideoContentElementUpdate,
      batchIdentifier: `videoContentElementUpdate-${attributes.element.id}`,
      fields: { [source]: value },
      inputModifier: (input: BoothVideoContentElement) => ({
        ...input,
        boothId: attributes.boothId,
        elementId: attributes.element.id,
      } as BoothVideoContentElementUpdateInput),
    })));
});

const richtextContentElementUpdateParser = createUpdateParserBuilder<BoothRichtextContentElement, { boothId: string, element: BoothRichtextContentElement }>((builder0) => {
  builder0.handler(((element) => element.rawElement as BoothRichtextContentElement),
    ((state$, attributes, source, value) => ({
      requestFnProvider: (sdk) => sdk.boothRichtextContentElementUpdate,
      batchIdentifier: `richtextContentElementUpdate-${attributes.element.id}`,
      fields: { [source]: value },
      inputModifier: (input: BoothRichtextContentElement) => ({
        ...input,
        boothId: attributes.boothId,
        elementId: attributes.element.id,
      } as BoothRichtextContentElementUpdateInput),
    })));
});

const fileContentElementUpdateParser = createUpdateParserBuilder<BoothFileContentElement, { boothId: string, element: BoothFileContentElement }>((builder0) => {
  builder0.handler(((element) => element.rawElement as BoothFileContentElement),
    ((state$, attributes, source, value) => ({
      requestFnProvider: (sdk) => sdk.boothFileContentElementUpdate,
      batchIdentifier: `fileContentElementUpdate-${attributes.element.id}`,
      fields: { [source]: value },
      inputModifier: (input: BoothFileContentElement) => ({
        ...input,
        boothId: attributes.boothId,
        elementId: attributes.element.id,
      } as BoothFileContentElementUpdateInput),
    })));
});

contentElementUpdateParser = createUpdateParserBuilder<BoothTabContentElement, { boothId: string, element: BoothContentElement }>((builder0) => {
  const contentElementUpdateParsers = {
    BoothQuestionContentElement: questionContentElementUpdateParser,
    BoothImageContentElement: imageContentElementUpdateParser,
    BoothRichtextContentElement: richtextContentElementUpdateParser,
    BoothFileContentElement: fileContentElementUpdateParser,
    BoothVideoContentElement: videoContentElementUpdateParser,
  };

  builder0.fallback(((state$, attributes, source, value) => {
    // eslint-disable-next-line no-underscore-dangle
    const parser = contentElementUpdateParsers[attributes.element.__typename];
    // eslint-disable-next-line no-underscore-dangle
    if (parser == null) throw new Error(`No content element update parser for ${attributes.element.__typename}`);

    return parser(state$, attributes, source, value);
  }));
});

const boothRootContentElementTabUpdateParser = createUpdateParserBuilder<BoothTabContentElement, { boothId: string, tabElement: BoothTabContentElement }>((builder0) => {
  builder0.handler((tab) => (tab.rawElement as BoothTabContentElement).title,
    (state$, attributes, source, value) => ({
      requestFnProvider: (sdk) => sdk.boothTabContentElementUpdate,
      batchIdentifier: `boothTabContentElementUpdate-${attributes.tabElement.id}`,
      fields: { [`title.${source}`]: value },
      inputModifier: (input: BoothTabContentElementUpdateInput) => ({
        ...input,
        boothId: attributes.boothId,
        elementId: attributes.tabElement.id,
      } as BoothTabContentElementUpdateInput),
    }));

  builder0.nodesHandlerByIndex(
    (tab) => tab.body.nodes,
    (state$, attributes, index, source, value) => {
      const element = attributes.tabElement.body.nodes[index];
      return contentElementUpdateParser(state$, { ...attributes, element }, source, value);
    },
  );
});

export default boothRootContentElementTabUpdateParser;
