import { Epic } from 'redux-observable';
import {
  catchError, filter, map, mergeMap, skip, takeUntil, tap,
} from 'rxjs/operators';
import eventEditBoothsActions
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/eventEditBoothsActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { Booth, Event } from 'models';
import { concat, defer, of } from 'rxjs';
import { fetchIdListToEntitiesBatched } from 'utils/requestsUtils';
import eventEditBoothsSelectors
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/eventEditBoothsSelectors';
import cmsSelectors from 'redux/cmsSelectors';

const fetchBoothsListEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditBoothsActions.fetchBoothsList.match),
  mergeMap(() => {
    // TODO: if backend search is applied, we need to add filter in state, and get the filter keyword from the state$ here

    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const { locale, event } = eventEditSelectors.selectEventEdit(state$.value);

    const fetchBooths = fetchIdListToEntitiesBatched(
      (after) => sdk.getBoothIdListByEventId({ eventId: event.id, after }),
      (resp) => (resp.node as Event).booths.pageInfo,
      (resp) => (resp.node as Event).booths.nodes,
      30,
      (metas) => sdk.getBoothRowsDetailByIds({ boothIds: metas.map((it) => it.id), locales: [locale] })
        .pipe(map((resp) => resp.nodes as Booth[])),
    );

    return concat(
      of(eventEditBoothsActions.internalUpdateLoadingBooths(true)),
      of(eventEditBoothsActions.internalUpdateBoothsList([])),
      fetchBooths.pipe(
        map((followingBooths) => {
          const currentBooths = eventEditBoothsSelectors.selectBoothsList(state$.value) ?? [];
          return eventEditBoothsActions.internalUpdateBoothsList([...currentBooths, ...followingBooths]);
        }),
      ),
      // make sure even empty booths, it will still update the booths list
      defer(() => of(eventEditBoothsActions.internalUpdateBoothsList(
        eventEditBoothsSelectors.selectBoothsList(state$.value) ?? [],
      ))),
      of(eventEditBoothsActions.internalUpdateLoadingBooths(false)),
    ).pipe(
      takeUntil(action$.pipe(filter(eventEditBoothsActions.fetchBoothsList.match))), // fetch until another fetch started
    );
  }),
);

export default fetchBoothsListEpic;
