import { HintType } from 'utils/xtra-hints/hintType';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const hintEventEditBoothDesignCreateElement: HintType<'hintEventEditBoothDesignCreateElement'> = {
  key: 'hintEventEditBoothDesignCreateElement',
  contentsFactory: () => [
    () => (
      <>
        <FormattedMessage
          id="event.booth_design.hints.create_element_0"
          defaultMessage="Booth Design Builder is a visual editor of booth template."
        />
        <br />
        <FormattedMessage
          id="event.booth_design.hints.create_element_1"
          defaultMessage="To create an element, you can select the element type from toolbox and dragging the area."
        />
        <img
          src="https://cms-virtual-assets.eventxtra.com/hints/event_edit_booth_design_create_element.gif"
          alt="create element"
        />
      </>
    ),
  ],
};

export default hintEventEditBoothDesignCreateElement;
