import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';

const MuiSizeFaIcon = styled((props: FontAwesomeIconProps) => (
  <FontAwesomeIcon {...props} />
))`
  font-size: 24px;
  width: 24px!important;
  height: 24px!important;
  padding: 3px;
`;

export default MuiSizeFaIcon;
