import { Epic, StateObservable } from 'redux-observable';
import { filter, map, mergeMap } from 'rxjs/operators';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import { concat, defer, of } from 'rxjs';
import { Event, GetEventBasicInfoQuery } from 'models';
import _ from 'lodash';
import cmsSelectors from 'redux/cmsSelectors';
import { EVENT_FIRESTORE_API } from 'config';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import { FeaturesToggle } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import eventSettingEditSelectors from 'admin-data/event/EventEdit/redux/eventSettingEditSelectors';

const createFetchEventFeaturesObservable = (domain: string, state$: StateObservable<any>) => {
  const getEventUrl = `${EVENT_FIRESTORE_API}?event_domain=${domain}`;
  const fetchFeatures = defer(() => fetch(getEventUrl));

  return concat(
    of(eventSettingEditActions.internalUpdateLoadingFeatures(true)),
    fetchFeatures.pipe(
      mergeMap((resp) => resp.json() as Promise<{ features: string[], featuresToggle: FeaturesToggle, maxCustomTabs: number }>),
      mergeMap(({ features, featuresToggle, maxCustomTabs }) => {
        let featureToggle = {};
        if (featuresToggle) {
          featureToggle = featuresToggle;
        } else {
          features.forEach((feature) => {
            featureToggle[feature] = true;
          });
        }
        const currentFeatures = eventSettingEditSelectors.selectEventFeatures(state$.value);
        return [
          eventSettingEditActions.internalUpdateFeatures({ ...currentFeatures, ...featureToggle }),
          eventSettingEditActions.setMaxCustomTabs(maxCustomTabs || 2),
        ];
      }),
    ),
    of(eventSettingEditActions.internalUpdateLoadingFeatures(false)),
  );
};

const initializeEventEpic: Epic = ((action$, state$) => action$.pipe(
  filter(eventEditActions.queries.initializeEvent.match),
  mergeMap(({ payload: eventId }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);

    const fetchEventObjectObservable = sdk.getEventBasicInfo({ eventId }).pipe(
      mergeMap((basicInfoResp: GetEventBasicInfoQuery) => {
        if (basicInfoResp.node == null) {
          return of(eventEditActions.internalUpdateIsEventNotFound(true));
        }

        return sdk
          .getEventDetails({ eventId, locales: (basicInfoResp.node as Event).locales })
          .pipe(
            map((detailResp) => _.merge(detailResp.node as Event, basicInfoResp.node as Event) as Event),
            mergeMap((mergedEvent: Event) => {
              const previousLocale = localStorage.getItem('previousContentLocale');
              const locale = mergedEvent.locales.indexOf(previousLocale) === -1 ? mergedEvent.defaultLocale : previousLocale;
              localStorage.setItem('previousContentLocale', locale);

              return concat(
                createFetchEventFeaturesObservable(mergedEvent.domain, state$),
                of(eventEditActions.updateEditLocale(locale)),
                of(eventEditActions.internalUpdateEvent(mergedEvent)),
              );
            }),
          );
      }),
    );

    return concat(
      of(eventEditActions.internalUpdateEvent(null)),
      fetchEventObjectObservable,
    );
  }),
));

export default initializeEventEpic;
