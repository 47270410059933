import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, takeUntil,
} from 'rxjs/operators';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import { concat, defer, of } from 'rxjs';
import eventSettingEditSelectors from 'admin-data/event/EventEdit/redux/eventSettingEditSelectors';
import { Footer } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { EVENT_FIRESTORE_API } from 'config';

const fetchEventSettingFooterEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.fetchFooter.match),
  mergeMap(() => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const getEventUrl = `${EVENT_FIRESTORE_API}?event_domain=${domain}`;
    const fetchFooter = defer(() => fetch(getEventUrl));
    return concat(
      of(eventSettingEditActions.internalUpdateLoadingFooter(true)),
      fetchFooter.pipe(
        mergeMap((resp) => resp.json() as Promise<{ footer: Footer }>),
        map(({ footer }) => {
          const currentFooter = eventSettingEditSelectors.selectEventFooter(state$.value);
          return eventSettingEditActions.internalUpdateFooter({ ...currentFooter, ...footer });
        }),
      ),
      of(eventSettingEditActions.internalUpdateLoadingFooter(false)),
    ).pipe(
      takeUntil(action$.pipe(filter(eventSettingEditActions.fetchFooter.match))), // fetch until another fetch started
    );
  }),
);

export default fetchEventSettingFooterEpic;
