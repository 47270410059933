import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, takeUntil,
} from 'rxjs/operators';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import { concat, defer, of } from 'rxjs';
import eventSettingEditSelectors from 'admin-data/event/EventEdit/redux/eventSettingEditSelectors';
import { GeneralSetting } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { EVENT_FIRESTORE_API } from 'config';

const fetchEventSettingGeneralEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.fetchGeneralSettings.match),
  mergeMap(() => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const getEventUrl = `${EVENT_FIRESTORE_API}?event_domain=${domain}`;
    const fetchGeneralSettings = defer(() => fetch(getEventUrl));
    return concat(
      of(eventSettingEditActions.internalUpdateLoadingGeneralSettings(true)),
      fetchGeneralSettings.pipe(
        mergeMap((resp) => resp.json() as Promise<{ ogTitle: string, ogImageUrl: string, ogDescription: string, googleTagId: string, zendeskId: string, generalSetting: GeneralSetting }>),
        map(({
          ogTitle,
          ogImageUrl,
          ogDescription,
          googleTagId,
          zendeskId,
          generalSetting = {},
        }) => {
          const ComgineedGeneralSetting = {
            ogTitle: generalSetting.ogTitle || ogTitle,
            ogImageUrl: generalSetting.ogImageUrl || ogImageUrl,
            ogDescription: generalSetting.ogDescription || ogDescription,
            googleTagId: generalSetting.googleTagId || googleTagId,
            zendeskId: generalSetting.zendeskId || zendeskId,
          };
          const currentGeneralSetting = eventSettingEditSelectors.selectEventGeneralSetting(state$.value);
          return eventSettingEditActions.internalUpdateGeneralSettings({ ...currentGeneralSetting, ...ComgineedGeneralSetting });
        }),
      ),
      of(eventSettingEditActions.internalUpdateLoadingGeneralSettings(false)),
    ).pipe(
      takeUntil(action$.pipe(filter(eventSettingEditActions.fetchGeneralSettings.match))), // fetch until another fetch started
    );
  }),
);

export default fetchEventSettingGeneralEpic;
