import { Booth } from 'models';
import { LocalStorageKeys } from 'utils/localstorageKeys';

export interface EventEditBoothsV2FragmentState {

  currentTierId: string;

  pageSize: number;
  pageNumber: number;
  pagedBoothsList: Booth[];

  selectedBooths: Record<string, Booth>
}

export const createEventEditBoothsV2FragmentInitialState: (() => EventEditBoothsV2FragmentState) = () => ({
  pagedBoothsList: [],
  selectedBooths: {},
  currentTierId: null,
  pageSize: parseInt(localStorage.getItem(LocalStorageKeys.EventEditBoothsListPageSize), 10) || 10,
  pageNumber: 0,
});
