import { Epic } from 'redux-observable';
import { filter, map, mergeMap } from 'rxjs/operators';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import cmsSelectors from 'redux/cmsSelectors';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import {
  BoothMasterElementDeleteInput, BoothRootMasterElement,
} from 'models';
import produce from 'immer';
import { of } from 'rxjs';
import _ from 'lodash';
import { eventEditExecutingTasksTagCreator } from '../../eventEditExecutingTasks';
import eventEditActions from '../../eventEditActions';

const deleteTabMasterElementEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.mutations.deleteTabMasterElement.match),
  mergeMap(({ payload: elementId }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const eventId = eventEditSelectors.selectEventId(state$.value);
    const originalEventFactory = () => eventEditSelectors.selectEvent(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.mutations.deleteTabMasterElement(),
        ExecutingTaskCommonTag.Mutation],
    });

    const masterElementDeleteInput: BoothMasterElementDeleteInput = {
      eventId,
      elementId,
    };

    const requestAction = sdk.boothMasterElementDelete({ input: masterElementDeleteInput }).pipe(
      throwCommonError(),
      map(() => eventEditActions.internalUpdateEventRootMasterElement(produce(originalEventFactory(), ((draft) => {
        const rootMasterElement: BoothRootMasterElement = {
          ...draft.rootMasterElement,
          tabs: {
            ...draft.rootMasterElement.tabs,
            nodes: draft.rootMasterElement.tabs.nodes.filter(
              (currentNode) => currentNode.id !== elementId,
            ),
          },
        };
        return rootMasterElement;
      })))),
      catchErrorToPopup(state$.value.intl),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return of(eventEditActions.internalAddSequencedRequest(requestAction));
  }),
);

export default deleteTabMasterElementEpic;
