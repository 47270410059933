import { EVENT_EDIT_STORE_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { createSelector, OutputSelector } from 'reselect';
import { parseSelector, SourceSelector } from 'utils/dummyProxy';
import _ from 'lodash';
import { createExecutingTasksSelectors } from 'utils/xtra-popups';
import { EventSettings } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import { EventEditState } from 'admin-data/event/EventEdit/redux/eventEditState';
import { bannerAssetAdapter, bannerAssetContainerAdapter, lobbyTemplateAdapter } from 'admin-data/event/EventEdit/redux/eventSettingEditReducer';

const selectEventEdit = ((state) => state[EVENT_EDIT_STORE_NAME] as EventEditState);
const selectEventSettings = createSelector(selectEventEdit, (eventEdit) => eventEdit.fragments.eventSettings);

const selectEventLandingPage = createSelector(selectEventSettings, (eventSettings) => eventSettings.landing);
const selectLoadingLandingPage = createSelector(selectEventSettings, (eventSettings) => eventSettings.fetchingState.landing);

const selectEventHallPage = createSelector(selectEventSettings, (eventSettings) => eventSettings.hall);
const selectLoadingHallPage = createSelector(selectEventSettings, (eventSettings) => eventSettings.fetchingState.hall);

const selectEventLobbyPage = createSelector(selectEventSettings, (eventSettings) => eventSettings.lobby);
const selectLoadingLobbyPage = createSelector(selectEventSettings, (eventSettings) => eventSettings.fetchingState.lobby);

const selectEventGeneralSetting = createSelector(selectEventSettings, (eventSettings) => eventSettings.generalSetting);
const selectLoadingGeneralSetting = createSelector(selectEventSettings, (eventSettings) => eventSettings.fetchingState.generalSetting);

const selectEventChatroomConfig = createSelector(selectEventSettings, (eventSettings) => eventSettings.chatroomConfig);
const selectLoadingChatroomConfig = createSelector(selectEventSettings, (eventSettings) => eventSettings.fetchingState.chatroomConfig);

const selectEventFeatures = createSelector(selectEventSettings, (eventSettings) => eventSettings.featuresToggle);
const selectLoadingFeatures = createSelector(selectEventSettings, (eventSettings) => eventSettings.fetchingState.featuresToggle);

const selectEventFooter = createSelector(selectEventSettings, (eventSettings) => eventSettings.footer);
const selectLoadingFooter = createSelector(selectEventSettings, (eventSettings) => eventSettings.fetchingState.footer);

const selectEventAppbar = createSelector(selectEventSettings, (eventSettings) => eventSettings.appBarConfig);
const selectLoadingAppbar = createSelector(selectEventSettings, (eventSettings) => eventSettings.fetchingState.appBarConfig);

const selectEventProducts = createSelector(selectEventSettings, (eventSettings) => eventSettings.products);
const selectLoadingProducts = createSelector(selectEventSettings, (eventSettings) => eventSettings.fetchingState.products);

const selectCustomPages = createSelector(selectEventSettings, (eventSettings) => eventSettings.customPage);
const selectPageNames = createSelector(selectEventSettings, (eventSettings) => eventSettings.pageNames);
const selectSortedMenuBarKeys = createSelector(selectEventAppbar, (appBar) => {
  /* eslint-disable no-unused-vars */
  const menuBarOrder = appBar?.itemOrder;
  return Object.entries(menuBarOrder)
    .sort(([_p, prevValue], [_n, nextValue]) => prevValue - nextValue)
    .map(([key, _v]) => key);
});
const selectEventLogos = createSelector(selectEventSettings, (eventSettings) => eventSettings.logos);

const selectExecutingTasks = createSelector(selectEventEdit, (state) => state.executingTasks);

const selectEventSettingField = <T extends any>(source: string | SourceSelector<EventSettings, T>): OutputSelector<any, T, (res: EventSettings) => T> => {
  const rawSource = typeof source === 'function' ? (parseSelector(source)) : source;
  return createSelector(selectEventSettings, (eventSetting) => _.get(eventSetting, rawSource) as T);
};

const {
  selectAll: selectAllBannerAssets,
  selectById: selectBannerAssetById,
  selectEntities: selectBannerAssetEntities,
} = bannerAssetAdapter.getSelectors((s: EventEditState) => s[EVENT_EDIT_STORE_NAME].fragments.eventSettings.bannerAssets);

const {
  selectAll: selectAllBannerAssetContainers,
  selectById: selectBannerAssetContainerById,
  selectEntities: selectBannerAssetContainerEntities,
} = bannerAssetContainerAdapter.getSelectors((s: EventEditState) => s[EVENT_EDIT_STORE_NAME].fragments.eventSettings.bannerAssetContainers);

const selectAllBannerContainerIds = createSelector(selectAllBannerAssetContainers, (bannerContainers) => bannerContainers.map((container) => container.id));

const {
  selectAll: selectAllLobbyTemplates,
  selectById: selectLobbyTemplateById,
  selectEntities: selectLobbyTemplateEntities,
} = lobbyTemplateAdapter.getSelectors((s: EventEditState) => s[EVENT_EDIT_STORE_NAME].fragments.eventSettings.lobbyTemplate);

const selectContainerAssetsByAssetId = (id: string) => createSelector(
  (s: EventEditState) => selectBannerAssetContainerById(s, id),
  selectBannerAssetEntities,
  (container, assetEntities) => container?.bannerAsset?.map((assetId) => assetEntities[assetId])
    ?.filter((asset) => Boolean(asset))
    ?.sort((prevAsset, nextAsset) => (prevAsset?.desktopOrder || 0) - (nextAsset?.desktopOrder || 0))
    || [],
);

const selectNextDesktopOrderInContainer = (id: string) => createSelector(
  selectContainerAssetsByAssetId(id),
  (assets) => (assets.length ? assets[assets.length - 1].desktopOrder + 65536 : 0),
);

const selectMaxCustomTabsNumber = createSelector(
  selectEventSettings,
  (eventSettings) => eventSettings.maxCustomTabs,
);

const eventSettingEditSelectors = {
  selectEventEdit,
  selectEventLandingPage,
  selectEventSettingField,
  selectEventSettings,
  selectEventHallPage,
  selectLoadingHallPage,
  selectEventLobbyPage,
  selectLoadingLobbyPage,
  selectLoadingLandingPage,
  selectEventGeneralSetting,
  selectLoadingGeneralSetting,
  selectEventChatroomConfig,
  selectLoadingChatroomConfig,
  selectEventFeatures,
  selectLoadingFeatures,
  selectEventFooter,
  selectLoadingFooter,
  selectEventAppbar,
  selectLoadingAppbar,
  selectEventProducts,
  selectLoadingProducts,
  selectAllBannerAssets,
  selectBannerAssetById,
  selectBannerAssetEntities,
  selectAllBannerAssetContainers,
  selectAllBannerContainerIds,
  selectBannerAssetContainerById,
  selectBannerAssetContainerEntities,
  selectContainerAssetsByAssetId,
  selectNextDesktopOrderInContainer,
  selectAllLobbyTemplates,
  selectLobbyTemplateById,
  selectLobbyTemplateEntities,
  executingTasks: createExecutingTasksSelectors(selectExecutingTasks),
  selectCustomPages,
  selectPageNames,
  selectSortedMenuBarKeys,
  selectMaxCustomTabsNumber,
  selectEventLogos,
};

export default eventSettingEditSelectors;
