import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import cmsSelectors from 'redux/cmsSelectors';
import {
  CONFIRMATION_DIALOG_POPUP_KEY,
  ConfirmationDialogData,
} from 'utils/xtra-popups/popupsTypes/confirmationDialogData';
import { Popup } from 'utils/xtra-popups/popup';
import XtraConfirmationDialog from 'utils/xtra-popups/components/XtraConfirmationDialog';
import {
  BULK_TASKS_PROGRESS_DIALOG_POPUP_KEY,
  BulkTasksProgressDialogData,
} from 'utils/xtra-popups/popupsTypes/bulkTasksProgressDialogData';
import XtraBulkTasksProgressDialog from 'utils/xtra-popups/components/XtraBulkTasksProgressDialog';

const XtraBulkTasksProgressDialogPopups: FC = () => {
  const popups = useSelector(cmsSelectors.popups.selectPopupsByType(BULK_TASKS_PROGRESS_DIALOG_POPUP_KEY)) as Popup<BulkTasksProgressDialogData>[];
  return (
    <>
      {popups.map((popup) => <XtraBulkTasksProgressDialog popup={popup} key={popup.id} />)}
    </>
  );
};

export default XtraBulkTasksProgressDialogPopups;
