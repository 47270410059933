import { combineEpics } from 'redux-observable';
import fetchBoothsListEpic
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/epic/fetchBoothsListEpic';
import boothCreationInitializeEpic
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/epic/boothCreation/boothCreationInitializeEpic';
import boothCreationCreateEpic
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/epic/boothCreation/boothCreationCreateEpic';
import reorderBoothEpic
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/epic/reorderBoothEpic';

const eventEditBoothsEpics = combineEpics(
  fetchBoothsListEpic,
  boothCreationInitializeEpic,
  boothCreationCreateEpic,
  reorderBoothEpic,
);

export default eventEditBoothsEpics;
