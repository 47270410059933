import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { concat, defer, EMPTY, of } from 'rxjs';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import queryString from 'query-string';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import { BannerAsset } from '../../eventSettingEditState';
import { FIRESTORE_ENDPOINT } from 'config';

const addBannerAssetEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.addBannerAsset.match),
  mergeMap(({ payload: { callback, banner_assets_id, ...requestBody } }) => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const params = { event_domain: domain, banner_assets_id };
    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.fragments.events.mutations.addBannerAsset(),
        ExecutingTaskCommonTag.Mutation,
      ],
    });

    const postUrl = queryString.stringifyUrl({ url: `${FIRESTORE_ENDPOINT}/bannerAsset`, query: params });
    const addBannerAsset = defer(() => fetch(postUrl, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: { 'Content-Type': 'application/json' },
    })).pipe(
      mergeMap((resp) => resp.json() as Promise<BannerAsset>),
      mergeMap((data) => (data?.id
        ? of(eventSettingEditActions.internalAddAsset({
          asset: data,
          bannerContainerId: banner_assets_id,
        }))
        : EMPTY)),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return concat(
      addBannerAsset,
      of(callback),
    );
  }),
);

export default addBannerAssetEpic;
