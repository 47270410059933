import { IntlShape, useIntl } from 'react-intl';
import { ReduxFieldBindValidator } from 'utils/redux-field-bind/reduxFieldBind';
import isEmail, { IsEmailOptions } from 'validator/lib/isEmail';
import isURL, { IsURLOptions } from 'validator/lib/isURL';
import { useMemo } from 'react';

export const combineXtraValidators = (...validators: ReduxFieldBindValidator<any>[]) => ({
  validate: (value) => validators.flatMap((validator) => validator.validate(value)),
} as ReduxFieldBindValidator<any>);

export const xtraValidatorsFactory = (intl: IntlShape) => ({
  notEmpty: (isSpaceOnlyValid = false) => ({
    validate: (value) => (value != null && value !== '' && (isSpaceOnlyValid || value.replaceAll(' ', ''.length > 0)) ? [] : [{
      message: intl.formatMessage({
        id: 'common.validate.cannot_be_empty',
        defaultMessage: 'The value cannot be empty',
      }),
    }]),
  } as ReduxFieldBindValidator<any>),

  /**
   * @param min inclusive min
   * @param max inclusive max
   */
  numberRange: (min?: number, max?: number) => ({
    validate: (value) => {
      if (min != null && max != null) {
        return (value >= min && value <= max ? [] : [{
          message: intl.formatMessage({
            id: 'common.validate.value_must_between',
            defaultMessage: 'The value must between {min} and {max}',
          }, { min, max }),
        }]);
      }
      if (min != null) {
        return (value >= min ? [] : [{
          message: intl.formatMessage({
            id: 'common.validate.value_must_gt_or_eq',
            defaultMessage: 'The value must greater than or equal to {min}',
          }, { min }),
        }]);
      }
      return (value <= max ? [] : [{
        message: intl.formatMessage({
          id: 'common.validate.value_must_lt_or_eq',
          defaultMessage: 'The value must less than or equal to {max}',
        }, { max }),
      }]);
    },
  } as ReduxFieldBindValidator<number>),

  stringFormats: {
    email: (options?: IsEmailOptions) => ({
      validate: (value) => (isEmail(value ?? '', options) ? [] : [{
        message: intl.formatMessage({
          id: 'common.validate.format_need_email',
          defaultMessage: 'The value must be a valid email',
        }),
      }]),
    } as ReduxFieldBindValidator<string>),

    url: (options?: IsURLOptions) => ({
      validate: (value) => (isURL(value ?? '', options) ? [] : [{
        message: intl.formatMessage({
          id: 'common.validate.format_need_url',
          defaultMessage: 'The value must be a valid url',
        }),
      }]),
    } as ReduxFieldBindValidator<string>),
  },
});

export type XtraValidators = ReturnType<typeof xtraValidatorsFactory>

export const useXtraValidators = () => {
  const intl = useIntl();
  return useMemo(() => xtraValidatorsFactory(intl), [intl]);
};
