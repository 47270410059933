import axios from 'axios';
import { defer, merge, of } from 'rxjs';
import { EVENT_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { createStandardEventEditRequestBundle } from 'admin-data/event/EventEdit/redux/createStandardEventEditRequestBundle';
import { ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { map } from 'rxjs/operators';
import { FIRESTORE_ENDPOINT } from 'config';
import { Auditorium, AuditoriumAssetsContainerPosition } from 'extra-models/auditorium';
import { internalUpsertWebinar, selectWebinarById } from 'admin-data/webinar/redux/webinars';

export const initialAuditorium: Partial<Auditorium> = {
  isDefault: false,
  assets: {
    [AuditoriumAssetsContainerPosition.Left]: {
      aspectX: 1,
      aspectY: 4,
      sliderDuration: 5000,
      assetIds: [],
    },
    [AuditoriumAssetsContainerPosition.Center]: {
      aspectX: 5,
      aspectY: 1,
      sliderDuration: 5000,
      assetIds: [],
    },
    [AuditoriumAssetsContainerPosition.Right]: {
      aspectX: 1,
      aspectY: 4,
      sliderDuration: 5000,
      assetIds: [],
    },
  },
  backgroundImage: {
    en: 'https://eventx.io/wp-content/uploads/2020/05/OE9S3V0-scaled.jpg',
  },
};

const bundleEventAuditoriumCreate = createStandardEventEditRequestBundle<string, string>({
  actionName: `${EVENT_EDIT_ACTIONS_NAME}/auditorium/bundleEventAuditoriumCreate`,
  additionalTaskTags: [ExecutingTaskCommonTag.Mutation],
  isSequenced: true,

  requestFactory: ({
    eventDomain, locale, authToken, payload,
  }) => defer(() => axios.post(`${FIRESTORE_ENDPOINT}/events/${eventDomain}/auditoriums`, {
    ...initialAuditorium,
    webinarId: payload,
  }, {}))
    .pipe(map((resp) => resp.data.result.id)),

  requestResponseMapper: ({
    response: createdAuditoriumId, createUpdateEventEditAction, payload: webinarId, state$,
  }) => {
    const internalUpdateWebinar$ = of(internalUpsertWebinar({
      ...selectWebinarById(state$.value, webinarId),
      auditoriumId: createdAuditoriumId,
    }));
    const internalUpdateEditingAuditorium$ = of(createUpdateEventEditAction((eventEditState) => {
      eventEditState.fragments.auditorium.auditoriumEdit.editingAuditorium = {
        ...JSON.parse(JSON.stringify(initialAuditorium)),
        id: createdAuditoriumId,
        webinarId,
      } as Auditorium;
    }));
    return merge(internalUpdateWebinar$, internalUpdateEditingAuditorium$);
  },
});

export default bundleEventAuditoriumCreate;
