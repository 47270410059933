import { Action, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { ExecutingTasksState } from 'utils/xtra-executing-tasks/executingTasksState';
import { Draft } from 'immer';
import { ExecutingTasksActions } from 'utils/xtra-executing-tasks/createExecutingTasksActions';
import { ExecutingTask } from 'utils/xtra-executing-tasks/executingTask';

// eslint-disable-next-line import/prefer-default-export
export const injectExecutingTasksExtraReducer = <S>(
  builder: ActionReducerMapBuilder<S>,
  actions: ExecutingTasksActions,
  stateGetter: (state: Draft<S>, action: Action<ExecutingTasksActions[keyof ExecutingTasksActions]>) => ExecutingTasksState,
) => {
  builder.addCase(actions.upsertExecutingTasks, (state, action) => {
    const tasksState = stateGetter(state, action as any);
    tasksState.tasksIdMap[action.payload.id] = action.payload;
    action.payload.tags?.forEach((tag) => {
      if (tasksState.tasksTagMap[tag] === undefined) tasksState.tasksTagMap[tag] = {};
      tasksState.tasksTagMap[tag][action.payload.id] = action.payload;
    });
  });

  builder.addCase(actions.finishExecutingTasks, (state, action) => {
    const taskId = typeof action.payload.task === 'object' ? (action.payload.task as ExecutingTask).id : action.payload.task;
    const tasksState = stateGetter(state, action as any);
    const task = tasksState.tasksIdMap[taskId];
    delete tasksState.tasksIdMap[taskId];
    task?.tags?.forEach((tag) => {
      delete tasksState.tasksTagMap[tag][taskId];
      if (Object.values(tasksState.tasksTagMap[tag]).length === 0) delete tasksState.tasksTagMap[tag];
    });

    const finishedTask = {
      ...task,
      error: action.payload.error,
    };
    tasksState.finishedTasksIdMap[taskId] = finishedTask;
    finishedTask.tags?.forEach((tag) => {
      if (tasksState.finishedTasksTagMap[tag] === undefined) tasksState.finishedTasksTagMap[tag] = {};
      tasksState.finishedTasksTagMap[tag][taskId] = finishedTask;
    });
  });
};
