import _ from 'lodash';
import { v4 as uuid } from 'uuid';

export interface ExecutingTask {
  id: string;
  name: string;
  progress: number;
  tags?: string[];
}

export interface FinishedTasks extends ExecutingTask {
  error: any[];
}

export enum ExecutingTaskCommonTag {
  Query = 'query',
  Mutation = 'mutation',
}

/**
 * Create an executing task with default property and random id
 */
export const createExecutingTask = (task: Partial<ExecutingTask>) => (_.merge({
  id: uuid(),
  name: 'Unnamed task',
  progress: -1,
  tags: [],
} as ExecutingTask, task) as ExecutingTask);
