import { Popup } from 'utils/xtra-popups/popup';

interface PopupIdMap {
  [id: string]: Popup<any>
}

export interface PopupsState {
  popupsIdMap: PopupIdMap;
  popupsTypeMap: { [type: string]: { [id: string]: null } }
}

export const popupsInitialState: PopupsState = {
  popupsIdMap: {},
  popupsTypeMap: {},
};
