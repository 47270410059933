import { makeStyles, createStyles } from '@material-ui/core/styles';

const errorImageUrl = 'https://spa-assets.eventxtra.com/assets/error_image.svg';

const useSpecialPageStyles = makeStyles((theme) => createStyles({
  background: {
    backgroundImage: `url(${errorImageUrl})`,
    width: '100vw',
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  container: {
    marginTop: theme.spacing(12),
    textAlign: 'center',
  },
  text: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },
  footer: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    height: theme.spacing(3),
    backgroundColor: 'white',
  },
  linkRight: {
    marginLeft: theme.spacing(1),
    fontWeight: 400,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    fill: theme.palette.primary.main,
  },
  noBold: {
    fontWeight: 400,
  },
  link: {
    cursor: 'pointer',
  },
}));

export default useSpecialPageStyles;
