import { Epic } from 'redux-observable';
import {
  bufferTime, debounceTime, filter, groupBy, ignoreElements, map, mergeMap,
} from 'rxjs/operators';
import { BatchedDebouncedUpdateRequest } from 'utils/redux-field-bind/updateParser/updateParserBuilder';
import { ActionCreatorWithOptionalPayload } from '@reduxjs/toolkit';
import { EMPTY, Observable } from 'rxjs';
import _ from 'lodash';
import cmsSelectors from 'redux/cmsSelectors';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import { Action } from 'redux';
import { ExecutingTasksActionsWrapper } from 'utils/xtra-executing-tasks/createExecutingTasksActions';

type UpdateRequestEpicFactory = (
  epicTriggerActionCreator: ActionCreatorWithOptionalPayload<BatchedDebouncedUpdateRequest<any, any>>,
  executingTaskActionsWrapper: ExecutingTasksActionsWrapper,
) => Epic;

const createInternalAddBatchedDebouncedUpdateRequestEpic: UpdateRequestEpicFactory = (
  updateAction, executingTaskActionsWrapper,
) => (action$, state$) => action$.pipe(
  filter(updateAction.match),

  map(({ payload }) => payload),

  groupBy((request) => request.lessDebounce === true),
  mergeMap((requests) => {
    const createRequestsPipe = (groupByDebounce, mergeMapBufferTime) => (observable: Observable<BatchedDebouncedUpdateRequest<any, any>>) => observable.pipe(
      groupBy((update) => update.batchIdentifier, (it) => it, (it) => it.pipe(debounceTime(groupByDebounce))),
      mergeMap((groupped) => groupped.pipe(
        bufferTime(mergeMapBufferTime),
        mergeMap((buffered) => {
          if (buffered.length === 0) return EMPTY;
          // combine all request
          const request: BatchedDebouncedUpdateRequest<any, any> = buffered.reduce((sum, next) => {
            _.merge(sum.fields, next.fields);
            return sum;
          });

          const input = request.inputModifier(Object.entries(request.fields).reduce((inputResult, [source, value]) => {
            _.set(inputResult, source, value);
            return inputResult;
          }, {}));

          const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);

          return request.requestFnProvider(sdk)({ input }).pipe(
            throwCommonError(),
            ignoreElements(),
            catchErrorToPopup(state$.value.intl),
          );
        }),
        executingTaskActionsWrapper,
      ) as Observable<Action>),
    );

    return requests.pipe(requests.key ? createRequestsPipe(50, 5000) : createRequestsPipe(1000, 5000));
  }),
);

export default createInternalAddBatchedDebouncedUpdateRequestEpic;
