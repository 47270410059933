import {
  ChangeEvent, useCallback, useContext, useMemo,
} from 'react';
import DesignEditorInstanceContext
  from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';
import useValueLocaleIndex from 'libs/xtra-custom-booth-design/utils/useValueLocaleIndex';
import { createSelector } from 'reselect';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

export const useLayerLocalizableFieldSource = (layerId: string, source: string) => {
  const { selectors } = useContext(DesignEditorInstanceContext);

  const layerSelector = useMemo(() => selectors.selectLayerById(layerId), [layerId, selectors]);
  const valueSelector = useMemo(() => createSelector(layerSelector, (selectingLayer) => _.get(selectingLayer, source)), [layerSelector, source]);
  const localeIndex = useValueLocaleIndex(useSelector(valueSelector), false);
  return useMemo(() => `${source}.values[${localeIndex}].value`, [localeIndex, source]);
};

export interface FieldBind<T> {
  value: T;
  change: (value: T) => any;
  onChange: (e: ChangeEvent<HTMLInputElement>) => any;
  source: string;
}

const createOnChange = <T extends any>(changeFn: (value: T) => any) => (e: ChangeEvent<HTMLInputElement>) => {
  switch (e.target.type) {
    case 'checkbox':
      changeFn(e.target.checked as T);
      break;
    default:
      changeFn(e.target.value as T);
  }
};

interface LayerFieldBindConfigs<T> {
  afterChange?: (newValue: T) => any
}

export const useLayerFieldBind = <T extends any>(layerId: string, source: string, configs: LayerFieldBindConfigs<T> = {}): FieldBind<T> => {
  const { selectors, actions } = useContext(DesignEditorInstanceContext);
  const layerSelector = useMemo(() => selectors.selectLayerById(layerId), [layerId, selectors]);
  const valueSelector = useMemo(() => createSelector(layerSelector, (selectingLayer) => _.get(selectingLayer, source)), [layerSelector, source]);

  const value = useSelector(valueSelector);

  const dispatch = useDispatch();

  const change = useCallback((newValue: T) => {
    dispatch(actions.updateLayerFields({ layerId, fields: [{ source, value: newValue }] }));
    configs.afterChange?.call(null, newValue);
  }, [actions, configs.afterChange, dispatch, layerId, source]);

  const onChange = useCallback(createOnChange(change), [change]);

  return useMemo(() => ({
    value, change, onChange, source,
  }), [change, onChange, source, value]);
};

export const useReverseBooleanFieldBind = (fieldBind: FieldBind<boolean>): FieldBind<boolean> => {
  const reversedChange = useCallback((value) => fieldBind.change(!value), [fieldBind]);
  return useMemo(() => ({
    value: !fieldBind.value,
    change: reversedChange,
    onChange: createOnChange(reversedChange),
    source: fieldBind.source,
  }), [fieldBind.source, fieldBind.value, reversedChange]);
};
