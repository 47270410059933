import { Fileable, Maybe, Node } from 'models';
import {
  ReduxFieldBind, ReduxFieldBindSetterOptions, ReduxFieldBindValidator, TypeBoundedSource,
} from 'utils/redux-field-bind/reduxFieldBind';
import { useCallback, useMemo, useRef } from 'react';
import mime from 'mime';
import { parseSelector, SourceSelector } from 'utils/dummyProxy';
import { useDispatch, useSelector } from 'react-redux';
import { InputProps, SwitchProps } from '@material-ui/core';
import _ from 'lodash';
import { XtraDropZoneV2Props } from 'components/file-dropzone/XtraDropZoneV2';
import useMemoCompare from 'utils/hooks/useMemoCompare';
import { selectorArrayItemEqFn } from 'utils/selectorUtils';
import { Selector } from 'reselect';
import { Action } from 'redux';
import { FieldUpdate } from 'utils/redux-field-bind/fieldUpdate';
import { UploadFirebaseFileAction } from 'utils/xtra-upload-actions/uploadFirebaseFile';
import { UploadFileAction } from 'utils/xtra-upload-actions/uploadFile';
import { combineXtraValidators } from 'utils/redux-field-bind/validators/xtraValidatorsFactory';

export type FieldsUpdatePayload = {
  fields: FieldUpdate<any>[]
};

export interface ReduxBindAdapter<T, A = any> {
  useField<R>(selector: string | SourceSelector<T, R>, fieldAttributes?: A): ReduxFieldBind<R>

  useLocale(): string;

  useFieldBuilder<R>(selector: string | SourceSelector<T, R> | TypeBoundedSource<R>): ReduxFieldBuilder<R>;

  /**
   * Shorthand of useFieldBuilder(source).useLocalizable().useAsField()
   */
  useLocalizableField<R>(source: string | SourceSelector<T, R>): ReduxFieldBind<ExtractLocalizableType<R>>;

  useLocalizableMapField<R>(source: string | SourceSelector<T, R>): ReduxFieldBind<ExtractLocalizableMapType<R>>;

  fieldSelectorCreator: <R>(source) => Selector<any, R>,
  fieldUpdateActionCreator: (payload: FieldsUpdatePayload) => Action,
}

interface FieldAttributes {
  localizable: boolean;
  masterControllable: boolean;
  masterControlled: boolean;
}

const defaultFieldAttributes: FieldAttributes = {
  localizable: false,
  masterControllable: false,
  masterControlled: false,
};

interface Localizable<T> {
  values?: Maybe<Array<Partial<{ locale: string, value: T }>>>
}

type ExtractLocalizableType<Type> = Type extends Localizable<infer T> ? T : never
type ExtractArrayType<Type> = Type extends Array<infer T> ? T : never
type ExtractNodeId<Type> = Type extends { id: string } ? string : never

type ExtractLocalizableMapType<Type> = Type extends Record<string, infer T> ? T : never

interface MasterControllable<T> {
  rawElement?: T;
}

type ExtractMasterControllableType<Type> = Type extends MasterControllable<infer T> ? T : never

export interface ReduxFieldBuilder<T> {
  /**
   * Use the field as localizable
   * @param specifiedLocale null to follow current locale
   */
  useLocalizable(specifiedLocale?: string): ReduxFieldBuilder<ExtractLocalizableType<T>>;

  useLocalizableMap(specifiedLocale?: string): ReduxFieldBuilder<ExtractLocalizableMapType<T>>;

  useMasterControllable<U>(elementFieldSelector: string | SourceSelector<T, U>): ReduxFieldBuilder<U>;

  useAsField(): ReduxFieldBind<T>

  useAsValue(): T

  // ==
  useNested<R>(nestedSelector: string | SourceSelector<T, R>): ReduxFieldBuilder<R>;

  useNestedAsField<U = T>(nestedSelector: string | SourceSelector<T, U>): ReduxFieldBind<U>

  useNestedAsFieldValue<U = T>(nestedSelector: string | SourceSelector<T, U>): U

  // ==
  useLocalizableAsField(): ReduxFieldBind<ExtractLocalizableType<T>>

  useLocalizableMapAsField(): ReduxFieldBind<ExtractLocalizableMapType<T>>

  useLocalizableAsFieldValue(): ExtractLocalizableType<T>

  // ==
  useNestedLocalizableAsField<U = T>(nestedSelector: string | SourceSelector<T, U>): ReduxFieldBind<ExtractLocalizableType<U>>

  useNestedLocalizableMapAsField<U = T>(nestedSelector: string | SourceSelector<T, U>): ReduxFieldBind<ExtractLocalizableMapType<U>>

  useNestedLocalizableAsFieldValue<U = T>(nestedSelector: string | SourceSelector<T, U>): ExtractLocalizableType<U>

  // ==
  useAsTypeBoundedSource(): TypeBoundedSource<T>

  useAsTypeBoundedSources(): TypeBoundedSource<ExtractArrayType<T>>[]

  useAsNodesTypeBoundedSources(): { nodeId: ExtractNodeId<ExtractArrayType<T>>, typeBoundedSource: TypeBoundedSource<ExtractArrayType<T>> }[]
}

export const useReduxBindAdapter = <X extends any>(
  localeSelector: Selector<any, string>,
  fieldSelectorCreator: <R>(source) => Selector<any, R>,
  fieldUpdateActionCreator: (payload: FieldsUpdatePayload) => Action,
  uploadFileAction?: UploadFileAction,
  uploadFirebaseFileAction?: UploadFirebaseFileAction,
): ReduxBindAdapter<X, FieldAttributes> => useMemo(() => {
    const useLocale = () => useSelector(localeSelector);

    function useField<R>(source: string | SourceSelector<X, R>, fieldAttributes = defaultFieldAttributes, validator?: ReduxFieldBindValidator<R>): ReduxFieldBind<R> {
      const locale = useLocale();

      const rawSource = useMemo(() => parseSelector(source), [source]);
      const selector = useMemo(() => fieldSelectorCreator<R>(rawSource), [rawSource]);

      const dispatch = useDispatch();

      return useMemo(() => {
        const useWithValidators = (...newValidators: ReduxFieldBindValidator<R>[]) => {
          const combinedValidators = useMemo(() => combineXtraValidators(...(validator ? [validator] : []), ...newValidators), []);
          return useField(rawSource, fieldAttributes, combinedValidators);
        };

        const useValue = () => useSelector(selector) as R;

        const useSetter = () => (value: R, options?: ReduxFieldBindSetterOptions) => {
          if (fieldAttributes.localizable) {
            dispatch(fieldUpdateActionCreator({
              fields: [
                {
                  source: rawSource,
                  value,
                  ...options,
                },
                {
                  source: `${rawSource.substring(0, rawSource.lastIndexOf('.'))}.locale`,
                  value: locale,
                  ...options,
                },
              ],
            }));
          } else {
            dispatch(fieldUpdateActionCreator({
              fields: [{
                source: rawSource,
                value,
                ...options,
              }],
            }));
          }
        };

        const useIsReadonly = () => fieldAttributes.masterControlled;

        const useValidationResult = () => {
          const value = useValue();
          return validator?.validate(value) ?? [];
        };

        const useMuiInputProps = <T extends any = string>(
          getConverter?: (value: R) => T,
          setConverter?: (inputValue: T) => R,
        ) => {
          const rawValue = useValue();
          const value = useMemo(() => (getConverter ?? ((it) => (it ?? '') as T))(rawValue), [getConverter, rawValue]);
          const setter = useSetter();

          const latestErrors = useMemo(() => validator?.validate(rawValue) ?? [], [validator, rawValue]);

          // TODO: onChange changed
          const onChange = useCallback((e) => {
            const settingValue = (setConverter ?? ((inputValue) => inputValue as R))(e.target.value);
            const errors = validator?.validate(settingValue);
            setter(settingValue, { skipExternalUpdate: (errors?.length ?? 0) > 0 });
          }, [setConverter, setter, validator]);

          const disabled = useIsReadonly();
          return useMemo(() => ({
            value,
            onChange,
            disabled,
            helperText: latestErrors[0]?.message,
          } as InputProps), [disabled, latestErrors, onChange, value]);
        };

        const useMuiSwitchProps = (
          getConverter?: (value: R) => boolean,
          setConverter?: (inputValue: boolean) => R,
        ) => {
          const muiInputProps = useMuiInputProps(getConverter, setConverter);
          const mappedOnChange = useCallback((e) => muiInputProps.onChange({ target: { value: e.target.checked } } as any), [muiInputProps]);

          return useMemo(() => {
            const { value, ...restRes } = muiInputProps;
            return { ...restRes, checked: value, onChange: mappedOnChange } as unknown as SwitchProps;
          }, [mappedOnChange, muiInputProps]);
        };

        const useXtraDropZoneV2Props = (deletable = true) => {
          const file = useValue() as unknown as Fileable;
          const setter = useSetter();
          const onUpload = useCallback((uploadedFile: File, preview: Fileable) => {
            if (uploadedFile != null) {
              if (uploadFileAction == null) throw new Error('Cannot upload file in reduxBindAdapter without uploadFileAction');
              dispatch(uploadFileAction({
                title: 'Uploading file',
                file: uploadedFile,
                identifier: rawSource,
                callback: ((signedBlobId) => setter(signedBlobId as any, { skipInternalUpdate: true, lessDebounce: true })),
              }));
              setter(preview as any, { skipExternalUpdate: true });
            } else {
              setter(null, { lessDebounce: true });
            }
          }, [setter]);

          const readonly = useIsReadonly();

          return useMemo(() => ({
            file,
            onUpload,
            showDelete: deletable,
            readonly,
          } as XtraDropZoneV2Props), [deletable, file, onUpload, readonly]);
        };

        const useXtraDropZoneFirebaseProps = (eventId: string, deletable = true) => {
          const fileURL = useValue() as unknown as string;
          const setter = useSetter();
          const reader = new FileReader();

          const onUpload = useCallback((uploadedFile: File) => {
            if (uploadFirebaseFileAction == null) throw new Error('Cannot upload firebase file in reduxBindAdapter without uploadFirebaseFileAction');
            if (uploadedFile != null) {
              reader.readAsDataURL(uploadedFile);
              reader.addEventListener('load', () => {
              // convert image file to base64 string
              // setter(reader.result as any, false, true);
              }, false);
              dispatch(
                uploadFirebaseFileAction({
                  title: 'Uploading file to firebase',
                  file: uploadedFile,
                  identifier: rawSource,
                  callback: ((fileUrl) => {
                    setter(fileUrl as any, { lessDebounce: true });
                  }),
                  eventId,
                }),
              );
            } else {
              setter(null, { lessDebounce: true });
            }
          }, [eventId, reader, setter]);

          const fileContentType = mime.getType(fileURL);
          const file: Fileable = {
            contentType: fileContentType,
            fileUrl: fileURL,
            signedBlobId: null,
            filename: fileURL?.split('/')?.pop(),
          };

          return useMemo(() => ({
            fileURL,
            file,
            onUpload,
            showDelete: deletable,
          } as XtraDropZoneV2Props), [fileURL, file, onUpload, deletable]);
        };

        return ({
          source: rawSource,
          typeBoundedSource: { source: rawSource },
          useValue,
          useSetter,
          useIsReadonly,
          useMuiInputProps,
          useWithValidator: useWithValidators,
          useValidationResult,
          useMuiSwitchProps,
          useXtraDropZoneFirebaseProps,
          useXtraDropZoneProps: useXtraDropZoneV2Props,
        });
      }, [dispatch, fieldAttributes, locale, rawSource, selector, validator]);
    }

    function useFieldBuilder<R>(baseSource: string | SourceSelector<X, R> | TypeBoundedSource<R>) {
      function useInnerFieldBuilder<T, R>(selector: string | SourceSelector<T, R>, attributes: FieldAttributes): ReduxFieldBuilder<R> {
        return useMemo(() => {
          const parsedSource = parseSelector(selector);
          return ({
            useLocalizable(specifiedLocale): ReduxFieldBuilder<ExtractLocalizableType<R>> {
              try {
                const currentLocale = useLocale();
                const localizableField = useField<{ values: { locale: string, value: any }[] }>(parsedSource);
                const localizableValue = localizableField.useValue();
                const localeIndex = useMemo(() => localizableValue?.values
                ?.findIndex(({ locale }) => locale === (specifiedLocale ?? currentLocale)) ?? null, [currentLocale, localizableValue?.values, specifiedLocale]);

                const fieldSource = useMemo(() => `${parsedSource}.values[${localeIndex}].value`, [parsedSource, localeIndex]);
                const fieldAttributes = useMemo(() => ({ ...attributes, localizable: true }), []);
                return useInnerFieldBuilder<R, ExtractLocalizableType<R>>(fieldSource, fieldAttributes);
              } catch (e) {
                console.error(`Cannot parse localizable ${parsedSource}`);
                throw e;
              }
            },

            useLocalizableMap(specifiedLocale): ReduxFieldBuilder<ExtractLocalizableMapType<R>> {
              const currentLocale = useLocale();
              const fieldSource = useMemo(() => `${parsedSource}.${specifiedLocale ?? currentLocale}`, [parsedSource, specifiedLocale, currentLocale]);
              const fieldAttributes = useMemo(() => ({ ...attributes, localizable: false }), []);
              return useInnerFieldBuilder<R, ExtractLocalizableMapType<R>>(fieldSource, fieldAttributes);
            },

            useAsTypeBoundedSource(): TypeBoundedSource<R> {
              return useMemo(() => ({ source: parsedSource }), [parsedSource]);
            },

            useAsTypeBoundedSources(): TypeBoundedSource<ExtractArrayType<R>>[] {
              const array = (this.useAsField().useValue() as ExtractArrayType<R>[]);
              const sourceArray = useMemoCompare(
                useMemo(() => (array ?? []).map((ignore, index) => `${parsedSource}[${index}]`), [parsedSource, array]),
                selectorArrayItemEqFn,
              );

              const lastSourceObjMap = useRef({});
              // TODO: Potential memory leak, since the source cache map didn't clear
              // But regenerate map everytime may cause performance issues

              return useMemo(() => {
                const result = sourceArray.map((source) => lastSourceObjMap.current[source] || ({ source })) as TypeBoundedSource<ExtractArrayType<R>>[];
                return result;
              }, [sourceArray]);
            },

            /**
           * Same as useAsTypeBoundedSources, but come with nodeId, probably good for using as key to reduce ui rerender
           */
            useAsNodesTypeBoundedSources(): { nodeId: ExtractNodeId<ExtractArrayType<R>>, typeBoundedSource: TypeBoundedSource<ExtractArrayType<R>> }[] {
              const nodes = (this.useAsField().useValue() as (ExtractArrayType<R> & Node)[]);

              const lastSourceObjMap = useRef<Record<string, TypeBoundedSource<ExtractArrayType<R>>>>({});
              // TODO: Potential memory leak, since the source cache map didn't clear
              // But regenerate map everytime may cause performance issues

              return useMemo(() => {
                const newSourceObjMap: Record<string, TypeBoundedSource<ExtractArrayType<R>>> = {};
                const result = (nodes ?? []).map((node, index) => {
                  const rawSource = `${parsedSource}[${index}]`;
                  newSourceObjMap[rawSource] = lastSourceObjMap.current[rawSource] || { source: rawSource };
                  return ({
                    nodeId: node.id as ExtractNodeId<ExtractArrayType<R>>,
                    typeBoundedSource: newSourceObjMap[rawSource] as TypeBoundedSource<ExtractArrayType<R>>,
                  });
                });
                lastSourceObjMap.current = newSourceObjMap;
                return result;
              }, [parsedSource, nodes]);
            },

            /**
           * @param elementFieldSelector - .rawElement.${elementField}  /   .masterElement.${elementField}
           */
            useMasterControllable<U>(elementFieldSelector: string | SourceSelector<R, U>): ReduxFieldBuilder<U> {
              const elementFieldSource = useMemo(() => parseSelector(elementFieldSelector), [elementFieldSelector]);

              const sourceToName = (source: string) => {
                if (source.endsWith('RichtextRaw')) return source.substring(0, source.indexOf('RichtextRaw'));
                if (source.endsWith('RichtextHtml')) return source.substring(0, source.indexOf('RichtextHtml'));
                return source;
              };

              const elementFieldName = useMemo(() => _.upperFirst(sourceToName(elementFieldSource)), [elementFieldSource]);

              const masterControlled = useField<boolean>(`${parsedSource}.masterElement.masterControlled${elementFieldName}`).useValue();

              const rawElementFieldSource = useMemo(() => `${parsedSource}.rawElement.${elementFieldSource}`, [parsedSource, elementFieldSource]);

              const readonlyElementFieldSource = useMemo(() => `${parsedSource}.${elementFieldSource}`, [parsedSource, elementFieldSource]);

              const fieldAttributes = useMemo(() => ({
                ...attributes,
                masterControllable: true,
                masterControlled,
              }), [masterControlled]);
              return useInnerFieldBuilder(masterControlled ? readonlyElementFieldSource : rawElementFieldSource, fieldAttributes);
            },

            useNested<U>(nestedSelector: string | SourceSelector<R, U>): ReduxFieldBuilder<U> {
              const nestedRawSource = useMemo(() => parseSelector(nestedSelector), [nestedSelector]);
              return useInnerFieldBuilder(`${parsedSource}.${nestedRawSource}`, attributes);
            },

            useAsField(): ReduxFieldBind<R> {
              return useField(parsedSource, attributes);
            },

            useAsValue(): R {
              return this.useAsField().useValue();
            },

            /**
           * useNestedAsField(selector) same as useNested(selector).useAsField
           * @param nestedSelector
           */
            useNestedAsField<U = R>(nestedSelector: string | SourceSelector<R, U>): ReduxFieldBind<U> {
              return this.useNested(nestedSelector).useAsField(parsedSource, attributes);
            },

            useNestedAsFieldValue<U = R>(nestedSelector: string | SourceSelector<R, U>): U {
              return this.useNestedAsField(nestedSelector).useValue();
            },

            useLocalizableAsField(): ReduxFieldBind<ExtractLocalizableType<R>> {
              return this.useLocalizable().useAsField();
            },

            useLocalizableMapAsField(): ReduxFieldBind<ExtractLocalizableMapType<R>> {
              return this.useLocalizableMap().useAsField();
            },

            useLocalizableAsFieldValue(): ExtractLocalizableType<R> {
              return this.useLocalizableAsField().useValue();
            },

            useNestedLocalizableAsField<U = R>(nestedSelector: string | SourceSelector<R, U>): ReduxFieldBind<ExtractLocalizableType<U>> {
              return this.useNested(nestedSelector).useLocalizable().useAsField();
            },

            useNestedLocalizableAsFieldValue<U = T>(nestedSelector: string | SourceSelector<R, U>): ExtractLocalizableType<U> {
              return this.useNestedLocalizableAsField(nestedSelector).useValue();
            },

            useNestedLocalizableMapAsField<U = R>(nestedSelector: string | SourceSelector<R, U>): ReduxFieldBind<ExtractLocalizableMapType<U>> {
              return this.useNested(nestedSelector).useLocalizableMap().useAsField();
            },
          });
        }, [attributes, selector]);
      }

      return useInnerFieldBuilder<X, R>((baseSource as TypeBoundedSource<any>)?.source ?? (baseSource as string | SourceSelector<X, R>), defaultFieldAttributes);
    }

    function useLocalizableField<R>(source: string | SourceSelector<X, R>): ReduxFieldBind<ExtractLocalizableType<R>> {
      return useFieldBuilder(source).useLocalizable().useAsField();
    }

    function useLocalizableMapField<R>(source: string | SourceSelector<X, R>): ReduxFieldBind<ExtractLocalizableMapType<R>> {
      return useFieldBuilder(source).useLocalizableMap().useAsField();
    }

    return ({
      useField, useFieldBuilder, useLocale, useLocalizableField, useLocalizableMapField, fieldSelectorCreator, fieldUpdateActionCreator,
    });
  }, [fieldSelectorCreator, fieldUpdateActionCreator, localeSelector, uploadFileAction, uploadFirebaseFileAction]);
