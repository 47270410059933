import { Epic } from 'redux-observable';
import {
  catchError, filter, first, map, mergeMap, retry, takeUntil, tap,
} from 'rxjs/operators';
import eventEditBoothsV2Actions
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/eventEditBoothsV2Actions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import {
  concat, defer, from, of,
} from 'rxjs';
import cmsSelectors from 'redux/cmsSelectors';
import { throwCommonError } from 'redux/mutationFailedPipe';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import { v4 as uuidv4 } from 'uuid';
import _, { mapValues } from 'lodash';
import {
  BULK_TASKS_PROGRESS_DIALOG_POPUP_KEY, BulkTaskProgress, BulkTasksProgressDialogData, BulkTaskState,
} from 'utils/xtra-popups/popupsTypes/bulkTasksProgressDialogData';
import { ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import cmsActions from 'redux/cmsActions';
import produce from 'immer';
import { Popup } from 'utils/xtra-popups/popup';
import { Booth } from 'models';

const concurrentTasksLimit = 5;

const bulkActionDialogInitialStateFactory = (booths: Booth[], boothIdTaskIdMap: Record<string, string>) => {
  const id = uuidv4();
  return ({
    id,
    type: BULK_TASKS_PROGRESS_DIALOG_POPUP_KEY,
    data: {
      title: 'Bulk update booths',
      tasksProgress: booths.map((booth) => ({
        id: boothIdTaskIdMap[booth.id],
        name: `Update booth ${booth.name?.values?.[0]?.value ?? booth.id}`,
        progress: -1,
        tags: [ExecutingTaskCommonTag.Mutation],
        state: BulkTaskState.Waiting,
        error: [],
      } as BulkTaskProgress)),
      cancellable: true,
      finished: false,
      doneAction: eventEditActions.fragments.booths.queries.queryBoothTiersList(),
      cancelAction: cmsActions.popups.createPopups.confirmationDialog(
        'Confirm cancel action', 'Are you sure to cancel the action?',
        cmsActions.popups.bulkTasks.cancel(id),
      ),
    },
  } as Popup<BulkTasksProgressDialogData>);
};

const selectedBoothsBulkUpdateEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditBoothsV2Actions.selectedBooths.bulk.update.match),
  mergeMap(({ payload: input }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const selectedBooths = Object.values(eventEditSelectors.fragments.booths.selectSelectedBooths(state$.value));

    const boothIdTaskIdMap = _.flow(
      (it: Booth[]) => _.mapKeys(it, (booth) => booth.id),
      (it) => mapValues(it, () => uuidv4()),
    )(selectedBooths);

    let lastDialog = bulkActionDialogInitialStateFactory(selectedBooths, boothIdTaskIdMap);

    const cancelAction$ = action$.pipe(
      filter(cmsActions.popups.bulkTasks.cancel.match),
      filter(({ payload: dialogId }) => dialogId === lastDialog.id),
      first(),
    );

    const createUpdateBoothTaskProgressAction = (boothId: string, state: BulkTaskState, error?: string[]) => {
      lastDialog = produce(lastDialog, (draft) => {
        const taskProgress = draft.data.tasksProgress.find((task) => task.id === boothIdTaskIdMap[boothId]);
        taskProgress.state = state;
        if (error !== undefined) {
          taskProgress.error = error;
        }
      });
      return cmsActions.popups.upsertPopup(lastDialog);
    };

    const requests = from(selectedBooths).pipe(
      mergeMap(({ id: boothId }) => concat(
        defer(() => of(createUpdateBoothTaskProgressAction(boothId, BulkTaskState.InProgress))),
        sdk.boothUpdate({ input: { id: boothId, ...(input as any) } }).pipe(
          retry(2),
          throwCommonError(),
          map(() => createUpdateBoothTaskProgressAction(boothId, BulkTaskState.Done)),
          tap({ error: (err) => console.error(err) }),
          catchError((err) => of(createUpdateBoothTaskProgressAction(boothId, BulkTaskState.Error, [err.message]))),
        ),
      ), concurrentTasksLimit),
      takeUntil(cancelAction$),
    );

    return concat(
      requests,
      defer(() => {
        lastDialog = produce(lastDialog, (draft) => {
          draft.data.finished = true;
        });
        return of(cmsActions.popups.upsertPopup(lastDialog));
      }),
    ).pipe(
      takeUntil(cancelAction$),
    );
  }),
);

export default selectedBoothsBulkUpdateEpic;
