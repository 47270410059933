import {
  BoothContentElement,
  BoothDesignElement,
  BoothDesignElementUpdateInput,
  BoothFileContentElement,
  BoothFileContentElementUpdateInput,
  BoothImageContentElement,
  BoothQuestionContentElement,
  BoothQuestionContentElementUpdateInput,
  BoothRichtextContentElement,
  BoothTabContentElement,
  BoothTabContentElementUpdateInput, BoothUpdateInput,
} from 'models';
import { createUpdateParserBuilder } from 'utils/redux-field-bind/updateParser/updateParserBuilder';

const boothLegacyElementTabUpdateParser = createUpdateParserBuilder<BoothDesignElement, { boothId: string, designElement: BoothDesignElement }>((builder0) => {
  builder0.fallback(((state$, attributes, source, value) => ({
    requestFnProvider: (sdk) => sdk.boothDesignElementUpdate,
    batchIdentifier: `boothDesignElementUpdate-${attributes.designElement.id}`,
    fields: { [source]: value },
    inputModifier: (input: BoothDesignElementUpdateInput) => ({
      ...input,
      boothId: attributes.boothId,
      name: attributes.designElement.name,
      ...(source === 'kind' ? {} : { kind: attributes.designElement.kind }),
    } as BoothDesignElementUpdateInput),
  })));
});

export default boothLegacyElementTabUpdateParser;
