import {
  MouseEvent as ReactMouseEvent, useCallback, useEffect, useState,
} from 'react';

export interface MouseDragProps<T> {
  onMouseMove?: (e: MouseDragEvent<T>) => any
  onDragStop?: () => any
  stopOnMouseUp?: boolean
  /**
   * the state that will be saved when start dragging, optional
   */
  state?: T
  availableButtons?: number[]
}

export interface MouseDragEvent<T> {
  raw?: MouseEvent,
  startDraggingPoint: { x: number, y: number },
  /**
   * offset when comparing with start point
   */
  draggingOffset: { x: number, y: number },
  beforeStartDragState?: T
}

export interface MouseDragReturn<T> {
  startDrag: (position: { x: number, y: number } | MouseEvent | ReactMouseEvent, state?: T) => any,
  stopDrag: () => any
  isDragging: boolean
}

const useMouseDrag = <T extends any>({
  onMouseMove, onDragStop, stopOnMouseUp = true, state = undefined,
}: MouseDragProps<T>): MouseDragReturn<T> => {
  const [isDragging, setIsDragging] = useState(false);
  const [startDraggingPoint, setStartDraggingPoint] = useState<{ x: number, y: number }>(null);

  const [beforeStartDragState, setBeforeStartDragState] = useState<T>(null);

  const startDrag = useCallback((input: { x: number, y: number } | MouseEvent | ReactMouseEvent, newState: T = undefined, availableButtons = [0]) => {
    if ((input as ReactMouseEvent).button !== undefined && availableButtons.indexOf((input as ReactMouseEvent).button) === -1) return;
    setIsDragging(true);
    if (state !== undefined && newState !== undefined) throw new Error('Bad usage, only use either state in useMouseDrag or newState in startDrag');
    if (state !== undefined) setBeforeStartDragState(state);
    if (newState !== undefined) setBeforeStartDragState(newState);

    if ((input as any).clientX !== undefined) {
      const e = input as (MouseEvent | ReactMouseEvent);
      e.stopPropagation();
      e.preventDefault();
      setStartDraggingPoint({ x: e.clientX, y: e.clientY });
    } else {
      const { x, y } = input as { x: number, y: number };
      setStartDraggingPoint({ x, y });
    }
  }, [state]);

  const calculateDraggingOffset = useCallback((latestX, latestY) => ({
    x: latestX - startDraggingPoint.x,
    y: latestY - startDraggingPoint.y,
  }), [startDraggingPoint]);

  const stopDrag = useCallback(() => {
    setIsDragging(false);
    onDragStop?.call(null);
  }, [onDragStop]);

  const internalOnMouseMove = useCallback((e: MouseEvent) => {
    onMouseMove?.call(null, {
      raw: e, startDraggingPoint, draggingOffset: calculateDraggingOffset(e.clientX, e.clientY), beforeStartDragState,
    } as MouseDragEvent<T>);
  }, [beforeStartDragState, calculateDraggingOffset, onMouseMove, startDraggingPoint]);

  const internalOnMouseUp = useCallback((e: MouseEvent) => {
    if (stopOnMouseUp) stopDrag();
  }, [stopDrag, stopOnMouseUp]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', internalOnMouseMove);
      document.addEventListener('mouseup', internalOnMouseUp, { capture: true });
    }
    return () => {
      document.removeEventListener('mousemove', internalOnMouseMove);
      document.removeEventListener('mouseup', internalOnMouseUp, { capture: true });
    };
  });

  return { startDrag, stopDrag, isDragging };
};

export default useMouseDrag;
