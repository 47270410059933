import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, tap,
} from 'rxjs/operators';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import cmsSelectors from 'redux/cmsSelectors';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { createExecutingTask } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import { fetchAllPage } from 'utils/requestsUtils';
import { Event } from 'models';
import {
  localizableFieldDefaultLocalePatcher,
} from 'admin-data/booth/BoothEdit/redux/epics/queries/utils';
import {
  eventEditMutationCompleteObservable,
  mapToSimpleMergeEventAction,
} from 'admin-data/event/EventEdit/redux/epic/utils';
import { concat } from 'rxjs';

const queryMasterTabsListEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.queries.queryMaserTabsList.match),
  mergeMap(() => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const locale = eventEditSelectors.selectEditLocale(state$.value);
    const eventId = eventEditSelectors.selectEventId(state$.value);
    const originalEventFactory = () => eventEditSelectors.selectEvent(state$.value);

    const taskFactory = () => createExecutingTask({ tags: [eventEditExecutingTasksTagCreator.queries.queryMasterTabsList()] });

    const requests = fetchAllPage(
      (after) => sdk.eventEditGetMasterTabsList({ eventId, locales: [locale], after }),
      (resp) => (resp.node as Event).rootMasterElement.tabs.pageInfo,
    ).pipe(
      tap((resp) => localizableFieldDefaultLocalePatcher([locale], resp)),
      map(mapToSimpleMergeEventAction(originalEventFactory)),
    );

    return concat(
      eventEditMutationCompleteObservable(action$, state$), // wait all mutation complete
      requests,
    ).pipe(eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory));
  }),
);

export default queryMasterTabsListEpic;
