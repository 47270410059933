import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import eventEditBoothsActions
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/eventEditBoothsActions';
import { EventEditState } from 'admin-data/event/EventEdit/redux/eventEditState';

const eventEditBoothsExtraReducer = (builder: ActionReducerMapBuilder<EventEditState>) => {
  builder.addCase(eventEditBoothsActions.internalUpdateBoothsList, (state, action) => {
    state.fragments.booths.boothsList = action.payload;
  });

  builder.addCase(eventEditBoothsActions.internalUpdateLoadingBooths, (state, action) => {
    state.fragments.booths.loadingBooths = action.payload;
  });
};

export default eventEditBoothsExtraReducer;
