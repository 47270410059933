import { Epic } from 'redux-observable';
import {
  filter, ignoreElements, mergeMap,
} from 'rxjs/operators';
import {
  concat, of,
} from 'rxjs';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import {
  catchErrorToPopup,
  throwCommonError,
} from 'redux/mutationFailedPipe';
import cmsSelectors from 'redux/cmsSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import produce from 'immer';

const reorderBoothTierEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.fragments.booths.mutations.reorderBoothTier.match),
  mergeMap(({ payload: { id, orderingPosition } }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const eventId = eventEditSelectors.selectEventId(state$.value);

    const originalEventEditFactory = () => eventEditSelectors.selectEventEdit(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.fragments.booths.mutations.reorderBoothTier(),
        ExecutingTaskCommonTag.Mutation],
    });

    const internalReorderAction = eventEditActions.internalUpdate(produce(originalEventEditFactory(), ((draft) => {
      const originalIndex = draft.event.boothTiers.nodes.findIndex((it) => it.id === id);
      draft.event.boothTiers.nodes.splice(orderingPosition, 0, draft.event.boothTiers.nodes.splice(originalIndex, 1)[0]);
    })));

    const requests = sdk.boothTierUpdate({ input: { boothTierId: id, eventId, orderingPosition } }).pipe(
      throwCommonError(),
      ignoreElements(),
      catchErrorToPopup(state$.value.intl),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return concat(
      of(internalReorderAction),
      of(eventEditActions.internalAddSequencedRequest(requests)),
    );
  }),
);

export default reorderBoothTierEpic;
