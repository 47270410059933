import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { concat, defer, EMPTY, of } from 'rxjs';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import { FIRESTORE_ENDPOINT } from 'config';
import { BannerContainerResponse, BannerContainer } from '../../eventSettingEditState';

const createTemplateEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.createLobbyTemplate.match),
  mergeMap(({ payload: { templateId, onCompleteCallback } }) => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.fragments.events.mutations.createLobbyTemplate(),
        ExecutingTaskCommonTag.Mutation,
      ],
    });
    const postBody = { eventDomain: domain, templateId };
    const postUrl = `${FIRESTORE_ENDPOINT}/bannerAssets/template`;
    const createLobbyTemplate = defer(() => fetch(postUrl, {
      method: 'POST',
      body: JSON.stringify(postBody),
      headers: { 'Content-Type': 'application/json' },
    })).pipe(
      mergeMap((resp) => resp.json() as Promise<{ success: boolean; data: BannerContainerResponse[] }>),
      mergeMap(({ success, data }) => {
        const bannerAssetArray = data.flatMap(({ bannerAsset }) => bannerAsset);
        const bannerContainerArray: BannerContainer[] = data.map((bannerContainer) => ({
          ...bannerContainer,
          bannerAsset: bannerContainer.bannerAsset.map((asset) => asset.id),
        }));
        return (success
          ? concat(
            of(eventSettingEditActions.setBannerAssets(bannerAssetArray)),
            of(eventSettingEditActions.setBannerAssetContainers(bannerContainerArray)),
            of(() => onCompleteCallback(bannerContainerArray[0].id)), // Assumes there's always at least one element
          ) : EMPTY);
      }),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );
    return createLobbyTemplate;
  }),
);

export default createTemplateEpic;
