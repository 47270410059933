import { Booth, Event } from 'models';
import {
  eventEditBoothsFragmentInitialState,
  EventEditBoothsFragmentState,
} from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/eventEditBoothsFramgnetState';
import { executingTasksInitialState, ExecutingTasksState } from 'utils/xtra-executing-tasks';
import {
  createEventEditBoothsV2FragmentInitialState,
  EventEditBoothsV2FragmentState,
} from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/eventEditBoothsFramgnetState';
import {
  eventEditAppbarFragmentInitialState,
  eventEditChatroomFragmentInitialState,
  eventEditCustomPageFragmentInitialState,
  eventEditFeaturesFragmentInitialState,
  eventEditFooterFragmentInitialState,
  eventEditGeneralSettingFragmentInitialState,
  eventEditHallFragmentInitialState,
  eventEditLandingFragmentInitialState,
  eventEditLobbyFragmentInitialState,
  eventEditLogosFragmentInitialState,
  eventEditPageNamesFragmentInitialState,
  eventEditProductsFragmentInitialState,
  EventSettings,
} from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import {
  bannerAssetAdapter,
  bannerAssetContainerAdapter,
  lobbyTemplateAdapter,
} from 'admin-data/event/EventEdit/redux/eventSettingEditReducer';
import { Auditorium, AuditoriumAsset, AuditoriumAssetsContainerPosition } from 'extra-models/auditorium';

export interface EventEditState {
  isEventNotFound: boolean,
  event: Event;
  locale: string;
  fragments: {
    booths: EventEditBoothsFragmentState;
    boothsV2: EventEditBoothsV2FragmentState;
    eventSettings: EventSettings,
    auditorium: AuditoriumFragmentState;
    boothLivestream: BoothLivestreamState;
  }
  executingTasks: ExecutingTasksState;
}

interface BoothLivestreamState {
  addSessionDialog?:{
    selectedBoothIdMap?: Record<string, boolean>;
    sessions?: {startTime: string; endTime: string}[];
  }
}

interface AuditoriumFragmentState {
  auditoriumsList: Auditorium[]
  auditoriumAssetsList: AuditoriumAsset[]
  auditoriumEdit: {
    editingAuditorium: Auditorium,
    selectedContainerKey?: AuditoriumAssetsContainerPosition
  }
}

export const eventEditInitialState: EventEditState = {
  isEventNotFound: false,
  event: null,
  locale: null,
  fragments: {
    booths: eventEditBoothsFragmentInitialState,
    boothsV2: createEventEditBoothsV2FragmentInitialState(),
    eventSettings: {
      registrationStartTime: null,
      registrationEndTime: null,
      themeColor: null,
      landing: eventEditLandingFragmentInitialState,
      hall: eventEditHallFragmentInitialState,
      lobby: eventEditLobbyFragmentInitialState,
      bannerAssets: bannerAssetAdapter.getInitialState(),
      bannerAssetContainers: bannerAssetContainerAdapter.getInitialState(),
      lobbyTemplate: lobbyTemplateAdapter.getInitialState(),
      generalSetting: eventEditGeneralSettingFragmentInitialState,
      chatroomConfig: eventEditChatroomFragmentInitialState,
      featuresToggle: eventEditFeaturesFragmentInitialState,
      footer: eventEditFooterFragmentInitialState,
      appBarConfig: eventEditAppbarFragmentInitialState,
      products: eventEditProductsFragmentInitialState,
      customPage: eventEditCustomPageFragmentInitialState,
      pageNames: eventEditPageNamesFragmentInitialState,
      logos: eventEditLogosFragmentInitialState,
      maxCustomTabs: 2,
      fetchingState: {
        lobby: true,
        landing: true,
        hall: true,
        generalSetting: true,
        chatroomConfig: true,
        featuresToggle: true,
        footer: true,
        appBarConfig: true,
        products: true,
        bannerAssets: true,
        lobbyTemplates: true,
        customPage: true,
        pageNames: true,
        logos: true,
      },
      name: {},
      startTime: '',
      endTime: '',
      availableLocales: [],
      features: [],
      liveStream: {
        boothIdLiveStreamPeriodsMap: {},
      },
    },
    auditorium: {
      auditoriumsList: [],
      auditoriumAssetsList: [],
      auditoriumEdit: {
        editingAuditorium: null,
      },
    },
    boothLivestream: {},
  },
  executingTasks: executingTasksInitialState,
};
