import { createAction } from '@reduxjs/toolkit';
import { EVENT_SETTING_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { FieldsUpdatePayload } from 'utils/redux-field-bind/reduxBindAdapter';
import { BatchedDebouncedUpdateRequest } from 'utils/redux-field-bind/updateParser/updateParserBuilder';
import { createExecutingTasksActions } from 'utils/xtra-popups';
import {
  Appbar,
  BannerAsset,
  BannerContainer,
  ChatroomConfig,
  CreateTemplatePayload,
  CustomPageGroup,
  DesktopAssetSortPayload,
  FeaturesToggle,
  HallPage,
  LandingPage,
  Lobby,
  LobbyAPIDeletePayload,
  LobbyAPIPostPayload,
  LobbyAPIPutPayload,
  LobbyTemplate,
  Products,
  SortResponsePayload,
  Logo,
} from './eventSettingEditState';

const eventSettingEditActions = {
  fetchLandingPage: createAction<string>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/fetchLandingPage`),
  fetchHallPage: createAction<string>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/fetchHallPage`),
  fetchLobbyPage: createAction<string>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/fetchLobbyPage`),
  fetchGeneralSettings: createAction<string>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/fetchGeneralSettings`),
  fetchGeneralSettingsV2: createAction<string>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/fetchBasicSettingsV2`),
  fetchChatroomConfig: createAction<string>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/fetchChatroomConfig`),
  fetchFeatures: createAction<string>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/fetchFeatures`),
  fetchFooter: createAction<string>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/fetchFooter`),
  fetchAppbar: createAction<string>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/fetchAppbar`),
  fetchProducts: createAction<string>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/fetchProducts`),
  fetchBannerAssets: createAction<string>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/fetchBannerAssets`),
  fetchLobbyTemplate: createAction<string>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/fetchLobbyTemplate`),
  fetchCustomPage: createAction<string>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/fetchCustomPage`),
  fetchPageNames: createAction<string>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/fetchPageNames`),
  fetchLogos: createAction<string>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/fetchLogos`),

  updateEventSettingFields: createAction<FieldsUpdatePayload>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/updateEventSettingFields`),
  internalUpdateLandingPage: createAction<LandingPage>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateLandingPage`),
  internalUpdateLoadingLanding: createAction<boolean>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateLoadingLanding`),

  internalUpdateHallPage: createAction<HallPage>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateHallPage`),
  internalUpdateLoadingHall: createAction<boolean>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateLoadingHall`),

  internalUpdateLobbyPage: createAction<Lobby>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateLobbyPage`),
  internalUpdateLoadingLobby: createAction<boolean>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateLoadingLobby`),

  internalUpdateGeneralSettings: createAction<Lobby>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateGeneralSettings`),
  internalUpdateLoadingGeneralSettings: createAction<boolean>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateLoadingGeneralSettings`),

  internalUpdateChatroomConfig: createAction<ChatroomConfig>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateChatroomConfig`),
  internalUpdateLoadingChatroomConfig: createAction<boolean>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateLoadingChatroomConfig`),

  internalUpdateFeatures: createAction<FeaturesToggle>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateFeatures`),
  internalUpdateLoadingFeatures: createAction<boolean>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateLoadingFeatures`),

  internalUpdateFooter: createAction<Lobby>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateFooter`),
  internalUpdateLoadingFooter: createAction<boolean>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateLoadingFooter`),

  internalUpdateAppbar: createAction<Appbar>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateAppbar`),
  internalUpdateLoadingAppbar: createAction<boolean>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateLoadingAppbar`),

  internalUpdateProductSetting: createAction<Products>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateProducts`),
  internalUpdateLoadingProductSetting: createAction<boolean>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateLoadingProducts`),

  executingTasks: createExecutingTasksActions(EVENT_SETTING_EDIT_ACTIONS_NAME),

  internalUpdateEventSettingFields: createAction<FieldsUpdatePayload>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateEventSettingFields`),
  internalAddBatchedDebouncedUpdateEventSettingRequestEpic: createAction<BatchedDebouncedUpdateRequest<any, any>>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_addBatchedDebouncedUpdateEventSettingRequest`),

  areBannerAssetsLoading: createAction<boolean>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateBannerAssetLoading`),
  setBannerAssets: createAction<BannerAsset[]>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_setBannerAssets`),
  setBannerAssetContainers: createAction<BannerContainer[]>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_setBannerAssetContainers`),

  deleteBannerAsset: createAction<LobbyAPIDeletePayload>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_deleteBannerAsset`),
  internalDeleteAsset: createAction<LobbyAPIDeletePayload>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_internalDeleteBannerAsset`),

  addBannerAsset: createAction<LobbyAPIPostPayload>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_addBannerAsset`),
  internalAddAsset: createAction<{ asset: BannerAsset, bannerContainerId: string; }>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_internalAddBannerAsset`),

  updateBannerAsset: createAction<LobbyAPIPutPayload>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateBannerAsset`),
  internalUpdateAsset: createAction<BannerAsset>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_internalUpdateBannerAsset`),

  updateBannerAssetSort: createAction<DesktopAssetSortPayload>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateBannerAssetSort`),
  internalUpdateBannerAssetSort: createAction<SortResponsePayload[]>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_internalUpdateBannerAssetSort`),

  areLobbyTemplatesLoading: createAction<boolean>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateLobbyTemplatesLoading`),
  setLobbyTemplates: createAction<LobbyTemplate[]>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_setLobbyTemplate`),
  createLobbyTemplate: createAction<CreateTemplatePayload>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/createLobbyTemplate`),

  customPagesLoading: createAction<boolean>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateCustomPagesLoading`),
  setCustomPages: createAction<CustomPageGroup[]>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_setCustomPages`),
  setMaxCustomTabs: createAction<number>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_setMaxCustomTabs`),

  pageNamesLoading: createAction<boolean>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updatePageNamesLoading`),
  setPageNames: createAction<any>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_setPageNames`),

  setupAuditorium: createAction<any>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/setupAuditorium`),
  setLogos: createAction<Logo[]>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_setLogos`),
  logosLoading: createAction<boolean>(`${EVENT_SETTING_EDIT_ACTIONS_NAME}/_updateLogosLoading`),
};

export default eventSettingEditActions;
