import { combineEpics } from 'redux-observable';
import fetchBoothsListEpic
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/epic/fetchBoothsListEpic';
import queryPagedBoothsListByCurrentTierIdEpic
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/epic/queries/queryPagedBoothsListByCurrentTierIdEpic';
import queryBoothTiersListEpic
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/epic/queries/queryBoothTiersListEpic';
import samePageReorderBoothEpic
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/epic/mutations/samePageReorderBoothEpic';
import reorderBoothTierEpic
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/epic/mutations/reorderBoothTierEpic';
import addBoothTierEpic
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/epic/mutations/addBoothTierEpic';
import directMoveBoothEpic
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/epic/mutations/directMoveBoothEpic';
import updateBoothEpic
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/epic/mutations/updateBoothEpic';
import deleteBoothTierEpic
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/epic/mutations/deleteBoothTierEpic';
import exportAllBoothsEpic
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/epic/mutations/exportAllBoothsEpic';
import addBoothsEpic
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/epic/mutations/addBoothsEpic';
import selectedBoothsBulkUpdateEpic
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/epic/mutations/selectedBoothsBulkUpdateEpic';

const eventEditBoothsV2Epics = combineEpics(
  queryBoothTiersListEpic,

  reorderBoothTierEpic,
  addBoothTierEpic,
  deleteBoothTierEpic,
  directMoveBoothEpic,
  updateBoothEpic,
  addBoothsEpic,

  selectedBoothsBulkUpdateEpic,

  exportAllBoothsEpic,

  queryPagedBoothsListByCurrentTierIdEpic,

  samePageReorderBoothEpic,
);

export default eventEditBoothsV2Epics;
