import React, { FC, useMemo } from 'react';
import createDesignEditorInstanceSelectors, { DesignEditorInstanceSelectors } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceSelectors';
import createDesignEditorInstanceActions, { DesignEditorInstanceActions } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceActions';

interface DesignEditorInstanceContextProps {
  designId: string
  selectors: DesignEditorInstanceSelectors,
  actions: DesignEditorInstanceActions,
}

const DesignEditorInstanceContext = React.createContext<DesignEditorInstanceContextProps>({
  designId: null,
  selectors: null,
  actions: null,
});

export const DesignEditorInstanceContextProvider: FC<{ designId: string }> = ({ children, designId }) => {
  const designEditorInstanceSelectors = useMemo(() => createDesignEditorInstanceSelectors(designId), [designId]);
  const designEditorInstanceActions = useMemo(() => createDesignEditorInstanceActions(designId), [designId]);
  return (
    <DesignEditorInstanceContext.Provider value={{ designId, selectors: designEditorInstanceSelectors, actions: designEditorInstanceActions }}>
      {children}
    </DesignEditorInstanceContext.Provider>
  );
};

export default DesignEditorInstanceContext;
