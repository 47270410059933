import styled from 'styled-components';
import { FormControl } from '@material-ui/core';

// eslint-disable-next-line import/prefer-default-export
export const SelectedLayerInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SelectedLayerInfoIcon = styled.div`
  width: 56px;
  height: 56px;
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DimensionFieldsRow = styled(FormControl)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const DimensionFieldFormControl = styled(FormControl)`
  min-width: 100px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 100px;
  margin: 8px;
`;

export const AspectRatioFieldFormControl = styled(FormControl)`
  margin: 8px;
  width: calc(100% - 16px);
`;
