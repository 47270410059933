/* eslint-disable camelcase */
import {
  createAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { intersection } from 'lodash';
import { FIRESTORE_ENDPOINT } from 'config';
import { Action } from 'redux';
import { Webinar, WebinarKind } from './types';
import { translationsToLocaleMap } from './helpers';
import { selectSpeakerIdByKeyword } from './speakers';
import { selectWebinarTrackIdByKeyword } from './tracks';

export const fetchWebinars = createAsyncThunk(
  'webinar/get',
  async (_: void, { getState }) => {
    const { eventEdit: { event: { domain } } } = (getState() as any);
    const { data } = await axios.get(`${FIRESTORE_ENDPOINT}/webinars`, {
      params: { event_domain: domain || 'virtual.2vanx.com', type: 'cms' },
      headers: { 'vexpo-xtra-cms': 'true' },
    });
    return (data as Webinar[]);
  },
);

export const addWebinar = createAsyncThunk(
  'webinar/add_webinar',
  async (body: any, { getState }) => {
    const { eventEdit: { event: { domain } } } = (getState() as any);
    const { data } = await axios.post(`${FIRESTORE_ENDPOINT}/webinars?event_domain=${domain || 'virtual.2vanx.com'}`, body);
    if (data.success) {
      body.webinar_day = body.webinar_day.date;
      body.translations = translationsToLocaleMap(body.translations);
      body.track = body.track ? body.track : 'none'; // If null, change it back to 'none'
      return ({ ...body, id: data.id } as Webinar);
    }
    return ({} as Webinar);
  },
);

export const updateWebinar = createAsyncThunk(
  'webinar/update_webinar',
  async (body: any, { getState }) => {
    const { eventEdit: { event: { domain } } } = (getState() as any);
    const { data } = await axios.put(`${FIRESTORE_ENDPOINT}/webinars?event_domain=${domain || 'virtual.2vanx.com'}`, body);
    if (data.success) {
      body.webinar_day = body.webinar_day.date;
      body.translations = translationsToLocaleMap(body.translations);
      body.track = body.track ? body.track : 'none'; // If null, change it back to 'none'
      return { id: body.id, changes: ({ ...body } as Webinar) };
    }
    return { id: null, changes: ({} as Webinar) };
  },
);

export const deleteWebinar = createAsyncThunk(
  'webinar/delete_webinar',
  async (id: string, { getState }) => {
    const { eventEdit: { event: { domain } } } = (getState() as any);
    const { data } = await axios.delete(`${FIRESTORE_ENDPOINT}/webinars?event_domain=${domain || 'virtual.2vanx.com'}&id=${id}`);
    if (data.success) {
      return id;
    }
    return '';
  },
);

const reorderWebinarSession = createAsyncThunk(
  'webinar/reorder_session_api',
  async (body: any, { getState }) => {
    const { eventEdit: { event: { domain } } } = (getState() as any);
    const { data } = await axios.put(`${FIRESTORE_ENDPOINT}/webinars/updateSort?event_domain=${domain || 'virtual.2vanx.com'}`, body);
    const [id] = Object.keys(body);
    if (data.success) {
      return { id, changes: { sort: body[id] } };
    }
    throw new Error('Failed to update sort');
  },
);

export const webinarsAdapter = createEntityAdapter<Webinar>();

export const {
  selectEntities: selectWebinarEntities,
  selectAll: selectAllWebinars,
  selectById: selectWebinarById,
} = webinarsAdapter.getSelectors((state: any) => state.webinars);

export const selectLiveWebinars = createSelector(
  selectAllWebinars,
  (webinars) => webinars.filter((webinar) => webinar.kind === WebinarKind.Live)
    .sort((prevWebinar: Webinar, nextWebinar: Webinar) => {
      if (prevWebinar.webinar_day === nextWebinar.webinar_day) {
        return prevWebinar.start_time?.localeCompare(nextWebinar.start_time);
      }
      return prevWebinar.webinar_day?.localeCompare(nextWebinar.webinar_day);
    }),
);

export const selectOnDemandWebinars = createSelector(
  selectAllWebinars,
  (webinars) => webinars.filter((webinar) => webinar.kind === WebinarKind.OnDemand)
    .sort((a: Webinar, b: Webinar) => (a?.sort || 0) - (b?.sort || 0)),
);

const emptyWebinarTranslation = {
  title: '',
  description: '',
};

export const selectWebinarByKeyword = (keyword, locale, mode: WebinarKind) => {
  const lowerCaseKeyword = keyword.toLowerCase();
  return createSelector(
    [
      mode === WebinarKind.Live ? selectLiveWebinars : selectOnDemandWebinars,
      selectSpeakerIdByKeyword(keyword, locale),
      selectWebinarTrackIdByKeyword(keyword, locale),
    ],
    (webinars, speakerIds, trackIds) => webinars.filter((webinar) => {
      const content = webinar.translations?.[locale] || emptyWebinarTranslation;
      return content.title.toLowerCase().includes(lowerCaseKeyword)
        // || content.description.toLowerCase().includes(lowerCaseKeyword)
        || Boolean(intersection(speakerIds, webinar.speakers).length)
        || trackIds.includes(webinar.track);
    }),
  );
};

export const reorderSession = createAsyncThunk(
  'webinar/reorder_session',
  async (movement: number[], { getState, dispatch }) => {
    const [currIdx, destIdx] = movement;
    const onDemandWebinars = selectOnDemandWebinars(getState());
    const { id } = onDemandWebinars[currIdx];
    let newSort = 0;
    if (currIdx > destIdx) {
      newSort = !destIdx ? onDemandWebinars[destIdx].sort - 65536 : Math.floor((onDemandWebinars[destIdx - 1].sort + onDemandWebinars[destIdx].sort) / 2);
    } else if (currIdx < destIdx) {
      newSort = destIdx === onDemandWebinars.length - 1 ? onDemandWebinars[destIdx].sort + 65536 : Math.floor((onDemandWebinars[destIdx].sort + onDemandWebinars[destIdx + 1].sort) / 2);
    } else {
      newSort = onDemandWebinars.length >= 3 ? (onDemandWebinars[currIdx - 1].sort + onDemandWebinars[currIdx + 1].sort) / 2 : onDemandWebinars[currIdx].sort;
    }
    dispatch(reorderWebinarSession({ [id]: newSort }));
    return { id, changes: { sort: newSort } };
  },
);

const speakerReduce = (speakers) => speakers?.reduce((accumulator, speaker) => {
  if (speaker?.webinar_speaker) {
    accumulator.push(speaker.webinar_speaker.id);
  }
  return accumulator;
}, []);

export const internalUpsertWebinar = createAction<Webinar>('webinar/internal_upsert');

export const webinarsSlice = createSlice({
  name: 'webinars',
  initialState: webinarsAdapter.getInitialState({ isLoading: true, isError: false, pendingReorders: 0 }),
  reducers: {
    resetState(state) {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWebinars.pending, (state: any) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWebinars.fulfilled, (state: any, action) => {
      const restructured = (action.payload as Webinar[] || []).reduce((accumulator, webinar) => {
        const restructure = { ...webinar };
        restructure.webinar_day = (webinar.webinar_day as any).date;
        restructure.speakers = speakerReduce(webinar?.speakers);
        restructure.translations = translationsToLocaleMap(restructure?.translations);
        restructure.track = webinar.track ? (webinar.track as any).id : 'none';
        accumulator.push(restructure);
        return accumulator;
      }, []);
      webinarsAdapter.upsertMany(state, restructured || []);
      state.isLoading = false;
    });
    builder.addCase(fetchWebinars.rejected, (state: any) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addWebinar.fulfilled, (state, action: any) => {
      webinarsAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(updateWebinar.fulfilled, (state, action: any) => {
      webinarsAdapter.updateOne(state, action.payload);
    });
    builder.addCase(deleteWebinar.fulfilled, (state, action: any) => {
      webinarsAdapter.removeOne(state, action.payload);
    });
    builder.addCase(reorderSession.pending, (state) => {
      state.pendingReorders += 1;
    });
    builder.addCase(reorderSession.fulfilled, (state, action: any) => {
      webinarsAdapter.updateOne(state, action.payload);
    });
    builder.addCase(reorderWebinarSession.fulfilled, (state) => {
      state.pendingReorders -= 1;
    });
    builder.addCase(reorderWebinarSession.rejected, (state) => {
      state.pendingReorders -= 1;
    });
    builder.addCase(internalUpsertWebinar, (state, action) => {
      webinarsAdapter.upsertOne(state, action.payload);
    });
  },
});

export const {
  resetState,
} = webinarsSlice.actions;

export default webinarsSlice.reducer;
