import { Epic } from 'redux-observable';
import { filter, map, mergeMap } from 'rxjs/operators';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import cmsSelectors from 'redux/cmsSelectors';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import { BoothRootMasterElement, BoothTabMasterElementCreateInput, BoothTabMasterElementCreatePayload } from 'models';
import produce from 'immer';
import { of } from 'rxjs';
import { eventEditExecutingTasksTagCreator } from '../../eventEditExecutingTasks';
import eventEditActions from '../../eventEditActions';

const DUMMY_TAB_LABEL = 'Untitled';

const addTabMasterElementEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.mutations.addTabMasterElement.match),
  mergeMap(() => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const { locales, rootMasterElement: { id: rootMasterElementId } } = eventEditSelectors.selectEvent(state$.value);
    const locale = eventEditSelectors.selectEditLocale(state$.value);
    const eventId = eventEditSelectors.selectEventId(state$.value);
    const originalEventFactory = () => eventEditSelectors.selectEvent(state$.value);

    const defaultTabNames = locales.map((eventLocale) => {
      if (eventLocale === locale) {
        return ({
          locale: eventLocale,
          value: DUMMY_TAB_LABEL,
        });
      }
      return ({
        locale: eventLocale,
        value: null,
      });
    });

    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.mutations.addTabMasterElement(),
        ExecutingTaskCommonTag.Mutation],
    });

    const masterElementCreateInput: BoothTabMasterElementCreateInput = {
      eventId,
      title: {
        values: defaultTabNames,
      },
      parentElementId: rootMasterElementId,
      masterControlledTitle: true,
      masterControlledBody: true,
    };

    const requestAction = sdk.boothTabMasterElementCreate({ input: masterElementCreateInput, locales }).pipe(
      throwCommonError(),
      map((resp) => eventEditActions.internalUpdateEventRootMasterElement(produce(originalEventFactory(), ((draft) => {
        const rootMasterElement: BoothRootMasterElement = {
          ...draft.rootMasterElement,
          tabs: {
            ...draft.rootMasterElement.tabs,
            nodes: draft.rootMasterElement.tabs.nodes.concat((resp.boothTabMasterElementCreate as BoothTabMasterElementCreatePayload).element),
          },
        };
        return rootMasterElement;
      })))),
      catchErrorToPopup(state$.value.intl),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return of(eventEditActions.internalAddSequencedRequest(requestAction));
  }),
);

export default addTabMasterElementEpic;
