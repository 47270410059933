import { Epic } from 'redux-observable';
import { filter, ignoreElements, mergeMap } from 'rxjs/operators';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import { concat, of } from 'rxjs';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { boothEditExecutingTasksTagCreator } from 'admin-data/booth/BoothEdit/redux/boothEditExecutingTasks';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import cmsSelectors from 'redux/cmsSelectors';

const updateBoothDesignEpic: Epic = (action$, state$) => action$.pipe(
  filter(boothEditActions.mutations.updateBoothDesign.match),
  mergeMap(({ payload: designId }) => {
    if (designId == null) throw new Error('Empty design id');
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const boothId = boothEditSelectors.selectBoothId(state$.value);
    const originalBoothFactory = () => boothEditSelectors.selectBooth(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [
        boothEditExecutingTasksTagCreator.mutations.updateBoothDesign(),
        ExecutingTaskCommonTag.Mutation],
    });

    const request = sdk.boothUpdate({ input: { id: boothId, designId } }).pipe(
      throwCommonError(),
      ignoreElements(),
      catchErrorToPopup(state$.value.intl),
      boothEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return concat(
      request,
      of(boothEditActions.queries.queryDesignCanvas()),
    );
  }),
);

export default updateBoothDesignEpic;
