import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import boothUpdateParser from 'admin-data/booth/BoothEdit/redux/updateParsers/boothUpdateParser';
import createUpdateFieldsEpic from 'utils/redux-update-model/createUpdateFieldsEpic';

const updateBoothFieldsEpic = createUpdateFieldsEpic(
  boothEditActions.updateBoothFields,
  boothEditActions.internalUpdateBoothFields,
  boothEditActions.internalAddBatchedDebouncedUpdateBoothRequest,
  (state$, field) => ({
    ...boothUpdateParser(
      state$, {
        boothId: boothEditSelectors.selectBoothId(state$.value),
        originalSource: field.source,
      }, field.source, field.value,
    ),
    lessDebounce: field.lessDebounce,
  }),
);

export default updateBoothFieldsEpic;
