import { filter, map, mergeMap } from 'rxjs/operators';
import createDesignEditorInstanceSelectors
  from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceSelectors';
import { concat, Observable, of } from 'rxjs';
import { BoothDesignButtonActionType, BoothDesignContentType, BoothDesignMasterElement } from 'models';
import { LayerElementType } from 'libs/xtra-custom-booth-design/types/DesignEditorInstance';
import {
  AddLayerPayload,
  rawDesignEditorInstanceActions,
} from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceActions';
import { DesignIdWrappedPayload } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designIdWrappedPayload';
import { Epic } from 'redux-observable';
import { ExecutingTask } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceState';
import { Action } from 'redux';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import _ from 'lodash';
import designEditorActions from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorActions';
import CanvasTools
  from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/DesignEditorInstanceCanvas/canvasTools';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import cmsSelectors from 'redux/cmsSelectors';
import cmsActions from 'redux/cmsActions';
import cmsHintsMap from 'hints';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';

/**
 * Add layer epic
 */
const addLayerEpic: Epic = (action$, state$) => action$.pipe(
  filter(rawDesignEditorInstanceActions.addLayer.match),
  map((action) => action.payload as DesignIdWrappedPayload<AddLayerPayload>),
  mergeMap(({
    instanceDesignId, data: {
      height, width, positionX, positionY, type,
    },
  }) => {
    const { selectLayers, selectDesign, selectLayersIdMap } = createDesignEditorInstanceSelectors(instanceDesignId);
    const boothDesignId = selectDesign(state$.value).id;
    const canvasId = selectDesign(state$.value).canvas.id;
    const existingLayersKeyNameMap = _.keyBy(Object.values(selectLayersIdMap(state$.value)) as BoothDesignMasterElement[], (layer) => (layer as any).keyName);
    let generatedKeyNameSuffix = 1;
    // eslint-disable-next-line no-plusplus,no-empty
    while (existingLayersKeyNameMap[`Layer ${generatedKeyNameSuffix}`] !== undefined && generatedKeyNameSuffix < 10000) generatedKeyNameSuffix++;

    const createLayerCommonFields = () => ({
      boothDesignId,
      height,
      width,
      positionX,
      positionY,
      parentElementId: canvasId,
      zOrder: 0,
      keyName: `Layer ${generatedKeyNameSuffix}`,
    });

    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const { locales } = eventEditSelectors.selectEvent(state$.value);

    const requests: { [type: string]: () => Observable<Partial<BoothDesignMasterElement>> } = {

      [LayerElementType.BoothDesignImageButtonMasterElement]: () => sdk.designEditorLayerImageButtonCreate({
        input: {
          ...createLayerCommonFields(),
          normalStateImage: { values: [] },
          hoverStateImage: { values: [] },
          actionType: BoothDesignButtonActionType.LocalizableExternalLink,
        },
        locales,
      }).pipe(
        throwCommonError(),
        map((resp) => resp.boothDesignImageButtonMasterElementCreate.element),
      ),

      [LayerElementType.BoothDesignContentAreaMasterElement]: () => sdk.designEditorLayerContentAreaCreate({
        input: { ...createLayerCommonFields(), acceptedContentTypes: Object.values(BoothDesignContentType) },
      }).pipe(
        throwCommonError(),
        map((resp) => resp.boothDesignContentAreaMasterElementCreate.element),
      ),

      [LayerElementType.BoothDesignColorFillMasterElement]: () => sdk.designEditorLayerColorFillCreate({
        input: { ...createLayerCommonFields() },
        locales,
      }).pipe(
        throwCommonError(),
        map((resp) => resp.boothDesignColorFillMasterElementCreate.element),
      ),

      [LayerElementType.BoothDesignImageMasterElement]: () => {
        const { keyName, ...input } = createLayerCommonFields(); // no keyName for image master element
        return sdk.designEditorLayerImageCreate({
          input,
          locales,
        }).pipe(
          throwCommonError(),
          map((resp) => resp.boothDesignImageMasterElementCreate.element),
        );
      },

      [LayerElementType.BoothDesignNetworkingWidgetMasterElement]: () => {
        const { keyName, ...input } = createLayerCommonFields();
        return sdk.designEditorLayerNetworkingWidgetCreate({
          input,
        }).pipe(
          throwCommonError(),
          map((resp) => resp.boothDesignNetworkingWidgetMasterElementCreate.element),
        );
      },
    };

    const request = requests[type]().pipe(
      map((element) => element as BoothDesignMasterElement),
    );

    const operation: Observable<Action> = request.pipe(
      mergeMap((element) => {
        const layers = selectLayers(state$.value);

        return concat(
          of(rawDesignEditorInstanceActions.internalUpdateLayers({ instanceDesignId, data: [element, ...layers] })),
          of(rawDesignEditorInstanceActions.updateSelectedLayerIds({ instanceDesignId, data: [element.id] })),
        );
      }),
      catchErrorToPopup(state$.value.intl),
    );

    const executingTask: ExecutingTask = createExecutingTask({
      name: 'Adding layer',
      progress: -1,
      tags: [ExecutingTaskCommonTag.Mutation],
    });

    return concat(
      of(cmsActions.hints.upsertCurrentHint({
        key: cmsHintsMap.hintEventEditBoothDesignTransformReorderElement.key,
        data: null,
      })),
      of(rawDesignEditorInstanceActions.internalAddSequencedRequest({
        instanceDesignId,
        data: concat(
          of(eventEditActions.executingTasks.upsertExecutingTasks(executingTask)),
          operation,
          of(eventEditActions.executingTasks.removeExecutingTasks(executingTask.id)),
        ),
      })),
      of(designEditorActions.changeSelectedCanvasTool(CanvasTools.Normal)),
    );
  }),
);

export default addLayerEpic;
