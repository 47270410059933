import { Route } from 'react-router-dom';
import React from 'react';
import loadable from '@loadable/component';

const EventEdit = loadable(() => import('admin-data/event/EventEdit'));
const BoothEdit = loadable(() => import('admin-data/booth/BoothEdit'));

const routes = [
  <Route path="/booths/:boothId" component={BoothEdit} />,
  <Route path="/events/:eventId" component={EventEdit} />,
];

export default routes;
