import { DesignEditorInstanceState } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceState';
import { KeyCombination } from 'libs/xtra-custom-booth-design/keymap/keyCombination';
import { DesignEditorDockerFramesInfo } from 'libs/xtra-custom-booth-design/types/DesignEditorDockerInfo';
import CanvasTools
  from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/DesignEditorInstanceCanvas/canvasTools';
import {
  BoothDesign, BoothDesignMasterElementConnection, DisplayMeasurementUnit, Event,
} from 'models';
import KeyMapType from 'libs/xtra-custom-booth-design/keymap/keyMapType';
import { tryOrNull } from 'libs/xtra-custom-booth-design/utils/fp';
import { defaultDockersLayout } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/defaultDockersLayout';

export interface DesignEditorState {
  instances: { [designId: string]: DesignEditorInstanceState };
  activeInstanceDesignId?: string;
  keyMap: { [type: string]: KeyCombination[] };
  dockersFrames: DesignEditorDockerFramesInfo;
  selectedCanvasTool: CanvasTools;
  event: Event;

  /**
   * Meta data only, for template list
   */
  boothDesignsList: BoothDesign[];
  boothDesignsListLoading: boolean;
}

export const designEditorInitialState = {
  instances: {},
  event: {} as Event,
  activeInstanceDesignId: null,
  keyMap: {
    [KeyMapType.InstanceCanvasMove]: [{ mainKey: ' ' }],
  },
  dockersFrames: tryOrNull(() => JSON.parse(localStorage.getItem('design-editor/dockersFrames'))) ?? defaultDockersLayout,
  selectedCanvasTool: CanvasTools.Normal,
  boothDesignsList: [],
  boothDesignsListLoading: false,
};
