import React, { useContext, useMemo } from 'react';
import { List, ListItem } from '@material-ui/core';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DesignEditorDockerComponent } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/designEditorDockerEntry';
import DesignEditorInstanceContext
  from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';
import DesignEditorLayersList, { DesignEditorLayerInfo } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayersDocker/DesignEditorLayersList';
import { BoothDesignMasterElement } from 'models';

const DesignEditorLayersDocker: DesignEditorDockerComponent = (props) => {
  const { actions, selectors, designId } = useContext(DesignEditorInstanceContext);
  const layers = useSelector(selectors.selectLayers);

  const layersInfo = useMemo(() => (layers as (BoothDesignMasterElement & { keyName: string })[])
    .map(({ id, keyName }) => ({
      name: keyName ?? id, id,
    } as DesignEditorLayerInfo)), [layers]);

  return (
    <DesignEditorLayersList layersInfo={layersInfo} />
  );
};

export default DesignEditorLayersDocker;
