import { HintType } from 'utils/xtra-hints/hintType';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const hintEventEditWebinarAuditoriumEdit: HintType<'hintEventEditWebinarAuditoriumEdit'> = {
  key: 'hintEventEditWebinarAuditoriumEdit',
  contentsFactory: () => [
    () => (
      <>
        <FormattedMessage
          id="event.webinar.auditorium.hints.edit_0"
          defaultMessage="Webinar auditorium is editable. You can customize the banner for different webinars."
        />
        <br />
        <FormattedMessage
          id="event.webinar.auditorium.hints.edit_1"
          defaultMessage="To edit an auditorium for a webinar, click the edit auditorium icon."
        />
        <img
          width="40%"
          src="https://cms-virtual-assets.eventxtra.com/hints/event_edit_webinar_auditorium_edit.png"
          alt="edit auditorium"
        />
      </>
    ),
  ],
};

export default hintEventEditWebinarAuditoriumEdit;
