import {
  createAsyncThunk, createEntityAdapter, createSlice, createSelector,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { translationsToLocaleMap } from './helpers';
import { WebinarSpeaker } from './types';
import { FIRESTORE_ENDPOINT } from 'config';

export const fetchWebinarSpeakers = createAsyncThunk(
  'webinar/get_speakers',
  async (_: void, { getState }) => {
    const { eventEdit: { event: { domain } } } = (getState() as any);
    const { data } = await axios.get(`${FIRESTORE_ENDPOINT}/speakers`, {
      params: { event_domain: domain || 'virtual.2vanx.com' },
      headers: { 'vexpo-xtra-cms': 'true' },
    });
    return (data as WebinarSpeaker[]);
  },
);

const speakerAdapter = createEntityAdapter<WebinarSpeaker>();

const contactsToMap = (contacts) => contacts.reduce((accumulator, { type, contact }) => ({
  ...accumulator,
  [type]: contact,
}), {});

export const addWebinarSpeaker = createAsyncThunk(
  'webinar/add_speaker',
  async (body: any, { getState }) => {
    const { eventEdit: { event: { domain } } } = (getState() as any);
    const { data } = await axios.post(`${FIRESTORE_ENDPOINT}/speakers?event_domain=${domain || 'virtual.2vanx.com'}`, body);
    if (data.success) {
      body.translations = translationsToLocaleMap(body.translations);
      return ({ ...body, id: data.id } as WebinarSpeaker);
    }
    return ({} as WebinarSpeaker);
  },
);

export const updateWebinarSpeaker = createAsyncThunk(
  'webinar/update_speaker',
  async (body: any, { getState }) => {
    const { eventEdit: { event: { domain } } } = (getState() as any);
    const { data } = await axios.put(`${FIRESTORE_ENDPOINT}/speakers?event_domain=${domain || 'virtual.2vanx.com'}`, body);
    if (data.success) {
      body.translations = translationsToLocaleMap(body.translations);
      return { id: body.id, changes: ({ ...body, ...contactsToMap(body.contacts) } as WebinarSpeaker) };
    }
    return { id: null, changes: ({} as WebinarSpeaker) };
  },
);

export const deleteWebinarSpeaker = createAsyncThunk(
  'webinar/delete_speaker',
  async (id: string, { getState }) => {
    const { eventEdit: { event: { domain } } } = (getState() as any);
    const { data } = await axios.delete(`${FIRESTORE_ENDPOINT}/speakers?event_domain=${domain || 'virtual.2vanx.com'}&id=${id}`);
    if (data.success) {
      return id;
    }
    return '';
  },
);

const reorderWebinarSpeaker = createAsyncThunk(
  'webinar/reorder_speaker_api',
  async (body: any, { getState }) => {
    const { eventEdit: { event: { domain } } } = (getState() as any);
    const { data } = await axios.put(`${FIRESTORE_ENDPOINT}/speakers/updateSort?event_domain=${domain || 'virtual.2vanx.com'}`, body);
    const [id] = Object.keys(body);
    if (data.success) {
      return { id, changes: { sort: body[id] } };
    }
    throw new Error('Failed to update sort');
  },
);

export const {
  selectEntities: selectWebinarSpeakerEntities,
  selectAll: selectAllWebinarSpeakers,
  selectById: selectWebinarSpeakerById,
} = speakerAdapter.getSelectors((state: any) => state.speakers);

export const selectAllWebinarSpeakersInOrder = createSelector(
  selectAllWebinarSpeakers,
  (speakers) => {
    speakers.sort((prevSpeaker, nextSpeaker) => prevSpeaker.sort - nextSpeaker.sort);
    return speakers;
  },
);

const emptySpeakerTranslation = {
  name: '',
  company: '',
  title: '',
  bio: '',
};

export const selectSpeakerByKeyword = (keyword, locale) => {
  const lowerCaseKeyword = keyword.toLowerCase();
  return createSelector(
    selectAllWebinarSpeakersInOrder,
    (speakers: WebinarSpeaker[]) => speakers.filter((speaker) => {
      const content = speaker?.translations?.[locale] || emptySpeakerTranslation;
      return content.name.toLowerCase().includes(lowerCaseKeyword);
      // || content.company.toLowerCase().includes(lowerCaseKeyword)
      // || content.title.toLowerCase().includes(lowerCaseKeyword)
      // || content.bio.toLowerCase().includes(lowerCaseKeyword);
    }),
  );
};

export const selectSpeakerIdByKeyword = (keyword, locale) => {
  const lowerCaseKeyword = keyword.toLowerCase();
  return createSelector(
    selectAllWebinarSpeakersInOrder,
    (speakers: WebinarSpeaker[]) => speakers.filter((speaker) => {
      const content = speaker?.translations?.[locale] || emptySpeakerTranslation;
      return content.name.toLowerCase().includes(lowerCaseKeyword)
      || content.company.toLowerCase().includes(lowerCaseKeyword)
      || content.title.toLowerCase().includes(lowerCaseKeyword)
      || content.bio.toLowerCase().includes(lowerCaseKeyword);
    }).map((speaker) => speaker.id),
  );
};

export const reorderSpeaker = createAsyncThunk(
  'webinar/speaker_reorder',
  async (movement: number[], { getState, dispatch }) => {
    const [currIdx, destIdx] = movement;
    const allSpeakers = selectAllWebinarSpeakersInOrder(getState());
    const { id } = allSpeakers[currIdx];
    let newNumber = 0;
    if (currIdx > destIdx) { // Move Up
      newNumber = !destIdx ? allSpeakers[destIdx].sort - 65536 : Math.floor((allSpeakers[destIdx - 1].sort + allSpeakers[destIdx].sort) / 2);
    } else if (currIdx < destIdx) { // Move down
      newNumber = destIdx === allSpeakers.length - 1 ? allSpeakers[destIdx].sort + 65536 : Math.floor((allSpeakers[destIdx].sort + allSpeakers[destIdx + 1].sort) / 2);
    } else { // Equal
      newNumber = allSpeakers.length >= 3 ? (allSpeakers[currIdx - 1].sort + allSpeakers[currIdx + 1].sort) / 2 : allSpeakers[currIdx].sort;
    }
    dispatch(reorderWebinarSpeaker({ [id]: newNumber }));
    return { id, changes: { sort: newNumber } };
  },
);

export const speakerSlice = createSlice({
  name: 'speaker',
  initialState: speakerAdapter.getInitialState({ isLoading: true, isError: false, isReordering: false }),
  reducers: {
    resetError(state) {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchWebinarSpeakers.pending, (state: any) => {
      state.isLoading = true;
    });
    builder.addCase(fetchWebinarSpeakers.fulfilled, (state: any, action) => {
      const restructured = action.payload.map((payload) => ({
        ...payload,
        translations: translationsToLocaleMap(payload.translations),
        ...contactsToMap(payload.contacts),
      }));
      speakerAdapter.upsertMany(state, restructured || []);
      state.isLoading = false;
    });
    builder.addCase(fetchWebinarSpeakers.rejected, (state: any) => {
      state.isLoading = false;
      state.isError = true;
    });
    builder.addCase(addWebinarSpeaker.fulfilled, (state, action: any) => {
      speakerAdapter.upsertOne(state, action.payload);
    });
    builder.addCase(updateWebinarSpeaker.fulfilled, (state, action: any) => {
      speakerAdapter.updateOne(state, action.payload);
    });
    builder.addCase(deleteWebinarSpeaker.fulfilled, (state, action: any) => {
      speakerAdapter.removeOne(state, action.payload);
    });
    builder.addCase(reorderSpeaker.pending, (state) => {
      state.isReordering = true;
    });
    builder.addCase(reorderSpeaker.fulfilled, (state, action: any) => {
      speakerAdapter.updateOne(state, action.payload);
    });
    builder.addCase(reorderWebinarSpeaker.fulfilled, (state) => {
      state.isReordering = false;
    });
    builder.addCase(reorderWebinarSpeaker.rejected, (state) => {
      state.isReordering = false;
    });
  },
});

export const { resetError } = speakerSlice.actions;
export default speakerSlice.reducer;
