import { Attendee, Booth } from 'models';
import { executingTasksInitialState, ExecutingTasksState } from 'utils/xtra-executing-tasks';
import { EventSettings } from 'admin-data/event/EventEdit/redux/eventSettingEditState';

export interface BoothEditState {
  locale: string;
  booth: Booth
  executingTasks: ExecutingTasksState

  eventSettings: EventSettings

  fragments: {
    representatives: BoothEditRepresentativesState
  }
}

interface BoothEditRepresentativesState {
  addDialog?: {
    open: boolean;
    representative: Partial<Attendee>
  }
}

export const boothEditInitialState = {
  executingTasks: executingTasksInitialState,
  fragments: {
    representatives: {},
  },
} as BoothEditState;
