import {
  concat,
  defer,
  EMPTY,
  from,
  merge,
  Observable,
  ObservableInput,
  ObservedValueOf,
  of,
  OperatorFunction,
  range,
} from 'rxjs';
import {
  bufferCount, concatMap, map, mergeMap, tap, toArray,
} from 'rxjs/operators';
import _, { sortBy } from 'lodash';
import { PageInfo } from 'models';

export function collateMap<T, O extends ObservableInput<any>>(
  project: (value: T, index: number) => O, // Observable<Booth>
  concurrent?: number,
): OperatorFunction<T, ObservedValueOf<O>> {
  const indexMappedBuffer: { [index: number]: ObservedValueOf<O>[] } = {};
  let nextIndex = 0;

  function concatAllBufferedSeqValue(): Observable<ObservedValueOf<O>> {
    if (indexMappedBuffer[nextIndex] === undefined) return EMPTY;
    // eslint-disable-next-line no-plusplus
    return concat(from(indexMappedBuffer[nextIndex++]), concatAllBufferedSeqValue());
  }

  return mergeMap<T, O>((value, index) => from(project(value as T, index)).pipe(
    toArray(),
    tap((result) => {
      indexMappedBuffer[index] = result;
    }),
    mergeMap(() => concatAllBufferedSeqValue()),
  ) as unknown as O, concurrent);
}

/**
 * This function will concat the array props instead of merge the props inside the array items
 */
const mergeObjPropAndConcatArr = (object, ...source) => _.mergeWith(object, ...source, (obj, src) => {
  if (_.isArray(obj)) {
    if (!_.isArray(src)) throw new Error(`Cannot merge an array with non-array property, dest: ${JSON.stringify(obj)} , source: ${JSON.stringify(src)}`);
    return (obj as any[]).concat(src);
  }
  return undefined;
});

/**
 * @param takeFirst How many item should be fetched, remind that it may fetch excess items
 */
export const fetchAllEntitiesByOffset = <T extends any = any, E extends any = any>(
  fetchFn: (after: string, windowSize: number) => Observable<T>,
  totalCountFn: (resp: T) => number,
  entitiesFn: (resp: T)=>E[],
  offset: number,
  takeFirst: number,
  windowSize = 30,
  concurrent = 1,
): Observable<E> => fetchFn(`offset:${offset}`, windowSize).pipe(mergeMap((firstResp) => {
    const totalCount = totalCountFn(firstResp);
    const remaining = Math.max(0, Math.min(totalCount - offset, takeFirst) - windowSize);

    const followingWindowsAmount = Math.ceil(remaining / windowSize);

    return concat(
      of(firstResp),
      range(1, followingWindowsAmount).pipe(
        collateMap((windowIndex) => fetchFn(`offset:${offset + windowIndex * windowSize}`, windowSize), concurrent),
      ),
    ).pipe(
      mergeMap((resp) => entitiesFn(resp)),
    );
  }));

export const fetchAllPage = <T extends any>(fetchFn: (after?: string) => Observable<T>, pageInfoFn: (resp: T) => PageInfo): Observable<T> => {
  function recursiveFetchAllPage(after?: string): Observable<T> {
    return after == null
      ? of({} as T)
      : defer(() => fetchFn(after).pipe(
        mergeMap((resp) => {
          const pageInfo = pageInfoFn(resp);
          const following = pageInfo.hasNextPage
            ? recursiveFetchAllPage(pageInfo.endCursor)
            : of({} as T);

          return following.pipe(map((next) => mergeObjPropAndConcatArr(resp, next)));
        }),
      ));
  }

  return fetchFn(undefined).pipe(mergeMap((resp) => {
    const pageInfo = pageInfoFn(resp);
    return recursiveFetchAllPage(pageInfo.hasNextPage ? pageInfo.endCursor : null).pipe(map((next) => mergeObjPropAndConcatArr(resp, next)));
  }));
};

// eslint-disable-next-line import/prefer-default-export
export const fetchAllEntities = <T extends any, E extends any>(
  fetchFn: (after?: string) => Observable<T>,
  pageInfoFn: (resp: T) => PageInfo,
  entitiesFn: (resp: T) => E[],
): Observable<E> => {
  const recursiveFetchAllEntities = (after?: string): Observable<E> => (after === null
    ? EMPTY
    : defer(() => fetchFn(after).pipe(
      mergeMap((resp) => {
        const pageInfo = pageInfoFn(resp);
        const following = recursiveFetchAllEntities(pageInfo.hasNextPage ? pageInfo.endCursor : null);
        const entities = entitiesFn(resp);

        return concat(from(entities), following);
      }),
    )));

  return recursiveFetchAllEntities();
};

/**
 * Firstly, fetch the whole id list
 * then, concatMap the ids into multiple request for querying node
 */
export const fetchIdListToEntities = <I extends any, K, E>(
  fetchIdListFn: (after?: string) => Observable<I>, idListPageInfoFn: (resp: I) => PageInfo,
  respToListFn: (resp: I) => K[],
  fetchEntityDetailFn: (entity: K) => Observable<E>,
): Observable<E> => fetchAllPage(fetchIdListFn, idListPageInfoFn)
    .pipe(mergeMap((resp) => from(respToListFn(resp)).pipe(mergeMap(fetchEntityDetailFn))));

/**
 * Firstly, fetch the whole id list
 * then split into multiple group and batch them
 * then, concatMap the ids into multiple request for querying node
 */
export const fetchIdListToEntitiesBatched = <I extends any, K, E = K>(
  fetchIdListFn: (after?: string) => Observable<I>, idListPageInfoFn: (resp: I) => PageInfo,
  entitiesMetaFn: (resp: I) => K[],
  batchNumber: number,
  fetchEntitiesDetailFn: (entitiesMeta: K[]) => Observable<E[]>,
  parallel = true,
): Observable<E[]> => fetchAllEntities(fetchIdListFn, idListPageInfoFn, entitiesMetaFn)
    .pipe(
      bufferCount(batchNumber),
      parallel
        ? collateMap((batchedEntitiesMeta) => fetchEntitiesDetailFn(batchedEntitiesMeta))
        : concatMap((batchedEntitiesMeta) => fetchEntitiesDetailFn(batchedEntitiesMeta)),
    );
