import React, { useMemo } from 'react';
import { Tooltip } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import { tryOrNull } from 'libs/xtra-custom-booth-design/utils/fp';
import { useIntl } from 'react-intl';
import { cond, stubTrue } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const useMuiUrlValidationWarning = (value: string) => {
  const intl = useIntl();
  return useMemo(() => {
    const urlObject = tryOrNull(() => new URL(value));

    const message = cond([
      [() => value == null || value === '', () => null],
      [() => urlObject?.protocol == null, () => intl.formatMessage({
        id: 'hints.external_link_need_protocol',
        defaultMessage: 'An external link should start with protocol (e.g. http:// or https://)',
      })],
      [stubTrue, () => null],
    ])(null);

    return message == null ? {} : {
      endAdornment: (
        <Tooltip title={message} arrow>
          <Warning />
        </Tooltip>
      ),
    };
  }, [intl, value]);
};
