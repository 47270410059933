import { Epic } from 'redux-observable';
import { filter, ignoreElements, mergeMap } from 'rxjs/operators';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import cmsSelectors from 'redux/cmsSelectors';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import { concat, Observable, of } from 'rxjs';
import produce from 'immer';

const reorderCategoryEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.mutations.reorderCategory.match),
  mergeMap(({ payload: { id, sameParentOrderingPosition } }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const eventId = eventEditSelectors.selectEventId(state$.value);
    const originalEventFactory = () => eventEditSelectors.selectEvent(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.mutations.reorderCategory(),
        ExecutingTaskCommonTag.Mutation],
    });

    const category = originalEventFactory().boothTaxonomies.nodes.find((it) => it.id === id);
    const parentId = category.parentSupercategory?.id;

    const sameParentCategoryIndexEntries = originalEventFactory().boothTaxonomies.nodes
      .map((taxonomy, index) => ({ taxonomy, index })) // zip with index
      .filter((it) => it.taxonomy.parentSupercategory?.id === parentId);

    // If after nothing, mean move to the top of the same category
    // else, move to after the dest category (index + 1)
    const internalOrderingPosition = sameParentCategoryIndexEntries[sameParentOrderingPosition].index;
    //
    const internalReorderAction = eventEditActions.internalUpdateEvent(produce(originalEventFactory(), ((draft) => {
      const originalIndex = draft.boothTaxonomies.nodes.findIndex(({ id: taxonomyId }) => taxonomyId === id);
      draft.boothTaxonomies.nodes.splice(internalOrderingPosition, 0, draft.boothTaxonomies.nodes.splice(originalIndex, 1)[0]);
    })));

    const requests = ((() => (category.__typename === 'BoothSupercategory'
      ? sdk.boothSupercategoryUpdate({
        input: { supercategoryId: id, eventId, orderingPosition: sameParentOrderingPosition },
      }) : sdk.boothCategoryUpdate({
        input: { categoryId: id, eventId, orderingPosition: sameParentOrderingPosition },
      })))() as Observable<any>).pipe(
      throwCommonError(),
      ignoreElements(),
      catchErrorToPopup(state$.value.intl),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return concat(
      of(internalReorderAction),
      of(eventEditActions.internalAddSequencedRequest(requests)),
    );
  }),
);

export default reorderCategoryEpic;
