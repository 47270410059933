import { createAction } from '@reduxjs/toolkit';
import { Popup } from 'utils/xtra-popups/popup';
import { Action } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import { CONFIRMATION_DIALOG_POPUP_KEY, ConfirmationDialogData } from 'utils/xtra-popups/popupsTypes/confirmationDialogData';
import { BULK_TASKS_PROGRESS_DIALOG_POPUP_KEY, BulkTasksProgressDialogData } from 'utils/xtra-popups/popupsTypes/bulkTasksProgressDialogData';
import { ReactNode } from 'react';

// eslint-disable-next-line import/prefer-default-export
/**
 * @T Extra info data on action
 */
export const createPopupsActions = (actionPrefix) => {
  const upsertPopup = createAction<Popup<any>>(`${actionPrefix}/upsertPopup`);

  return ({
    upsertPopup,
    removePopup: createAction<string>(`${actionPrefix}/removePopup`),

    bulkTasks: {
      upsert: (popupId: string, data: BulkTasksProgressDialogData) => upsertPopup({
        id: popupId,
        type: BULK_TASKS_PROGRESS_DIALOG_POPUP_KEY,
        data,
      }),

      cancel: createAction<string>(`${actionPrefix}/bulkTasks/cancel`),
    },

    createPopups: {
      confirmationDialog: (title: string, description: ReactNode, confirmAction?: Action, cancelAction?: Action) => upsertPopup({
        id: uuidv4(),
        type: CONFIRMATION_DIALOG_POPUP_KEY,
        data: {
          title,
          description,
          confirmAction,
          cancelAction,
        } as ConfirmationDialogData,
      }),
      snackbar: (message: string) => upsertPopup({
        id: uuidv4(),
        type: 'SnackBar',
        data: message,
      }),
    },
  });
};

export type PopupsActions = ReturnType<typeof createPopupsActions>;
