import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, takeUntil,
} from 'rxjs/operators';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import { concat, defer, of } from 'rxjs';
import _uniq from 'lodash/uniq';
import { FeaturesToggle } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import eventSettingEditSelectors from 'admin-data/event/EventEdit/redux/eventSettingEditSelectors';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { EVENT_FIRESTORE_API } from 'config';

const fetchEventSettingFeaturesEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.fetchFeatures.match),
  mergeMap(() => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const getEventUrl = `${EVENT_FIRESTORE_API}?event_domain=${domain}`;
    const fetchFeatures = defer(() => fetch(getEventUrl));
    return concat(
      of(eventSettingEditActions.internalUpdateLoadingFeatures(true)),
      fetchFeatures.pipe(
        mergeMap((resp) => resp.json() as Promise<{ features: string[], featuresToggle: FeaturesToggle, maxCustomTabs: number }>),
        mergeMap(({ features, featuresToggle, maxCustomTabs }) => {
          let featureToggle = {};
          if (featuresToggle) {
            featureToggle = featuresToggle;
          } else {
            features.forEach((feature) => {
              featureToggle[feature] = true;
            });
          }
          const currentFeatures = eventSettingEditSelectors.selectEventFeatures(state$.value);
          return [
            eventSettingEditActions.internalUpdateFeatures({ ...currentFeatures, ...featureToggle }),
            eventSettingEditActions.setMaxCustomTabs(maxCustomTabs || 2),
          ];
        }),
      ),
      of(eventSettingEditActions.internalUpdateLoadingFeatures(false)),
    ).pipe(
      takeUntil(action$.pipe(filter(eventSettingEditActions.fetchFeatures.match))), // fetch until another fetch started
    );
  }),
);

export default fetchEventSettingFeaturesEpic;
