import React, { FC, useCallback, useContext } from 'react';
import {
  Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel,
} from '@material-ui/core';
import { FormattedMessage, useIntl } from 'react-intl';
import SystemLocaleField from 'components/popups/dialogs/settings-dialog/SystemLocaleField';
import { useDispatch, useSelector } from 'react-redux';
import cmsSelectors from 'redux/cmsSelectors';
import cmsActions from 'redux/cmsActions';
import styled from 'styled-components';
import { StyledFormControl } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayerAttributesDocker/styles';

interface SettingsDialogProps {
  open: boolean;
  onClose: () => any;
}

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-top: 8px;
`;

const SettingsDialog: FC<SettingsDialogProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();

  const hideAllHints = useSelector(cmsSelectors.hints.selectHideAllHints);

  const updateHideAllHints = useCallback((e, checked) => {
    dispatch(cmsActions.hints.updateHideAllHints(checked));
  }, [dispatch]);

  const intl = useIntl();

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <FormattedMessage
          id="settings.title"
          description="Settings"
          defaultMessage="Settings"
        />
      </DialogTitle>
      <DialogContent>
        <SystemLocaleField />
        <StyledFormControlLabel
          control={<Checkbox checked={hideAllHints} onChange={updateHideAllHints} color="primary" />}
          label={intl.formatMessage({ id: 'settings.hide_all_hints', defaultMessage: 'Hide all hints' })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <FormattedMessage
            id="settings.done"
            description="Done"
            defaultMessage="Done"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SettingsDialog;
