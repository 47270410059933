import { Action, createAction } from '@reduxjs/toolkit';
import { EVENT_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import {
  Booth, BoothCreateInput, BoothTierUpdateInput, BoothUpdateInput, DisplayMeasurementInput,
} from 'models';
import { EventEditBoothsFragmentBoothCreation } from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/eventEditBoothsFramgnetState';

const ACTIONS_PREFIX = `${EVENT_EDIT_ACTIONS_NAME}/boothsv2`;

export interface ReorderBoothPayload {
  /**
   * Source booth index
   */
  source: number;
  orderingPosition: number;
}

const eventEditBoothsV2Actions = {

  queries: {
    queryBoothTiersList: createAction(`${ACTIONS_PREFIX}/queries/queryBoothTiersList`),
    queryPagedBoothsListByCurrentTierId: createAction(`${ACTIONS_PREFIX}/queries/queryPagedBoothsListByCurrentTierId`),
  },
  mutations: {
    samePageReorderBooth: createAction<{ id: string, pageIndex: number }>(`${ACTIONS_PREFIX}/mutations/samePageReorderBooth`),
    directMoveBooth: createAction<{ id: string, tierId?: string, orderingPosition?: number }>(`${ACTIONS_PREFIX}/mutations/directMoveBooth`),
    updateBooth: createAction<BoothUpdateInput>(`${ACTIONS_PREFIX}/mutations/updateBooth`),

    reorderBoothTier: createAction<{ id: string, orderingPosition: number }>(`${ACTIONS_PREFIX}/mutations/reorderBoothTier`),
    addBoothTier: createAction(`${ACTIONS_PREFIX}/mutations/addBoothTier`),
    deleteBoothTier: createAction<string>(`${ACTIONS_PREFIX}/mutations/deleteBoothTier`),

    addBooths: createAction<BoothCreateInput[]>(`${ACTIONS_PREFIX}/mutations/addBooths`),
    addBoothsCancel: createAction<string>(`${ACTIONS_PREFIX}/mutations/addBoothsCancel`),
  },

  selectedBooths: {
    clear: createAction(`${ACTIONS_PREFIX}/selectedBooths/clear`),
    toggleSelectedBooths: createAction<Booth[]>(`${ACTIONS_PREFIX}/selectedBooths/toggleSelectedBooths`),

    bulk: {
      update: createAction<Partial<BoothUpdateInput>>(`${ACTIONS_PREFIX}/selectedBooths/bulk/update`),
    },
  },

  boothCreation: {
    initialize: createAction(`${ACTIONS_PREFIX}/boothCreation/initialize`),
    close: createAction(`${ACTIONS_PREFIX}/boothCreation/close`),

    create: createAction(`${ACTIONS_PREFIX}/boothCreation/create`),

    updateCreation: createAction<EventEditBoothsFragmentBoothCreation>(`${ACTIONS_PREFIX}/boothCreation/updateCreation`),
  },

  setPageNumber: createAction<number>(`${ACTIONS_PREFIX}/setPageNumber`),
  setPageSize: createAction<number>(`${ACTIONS_PREFIX}/setPageSize`),
  setCurrentTierId: createAction<string>(`${ACTIONS_PREFIX}/setCurrentTierId`),

  exportAllBooths: createAction(`${ACTIONS_PREFIX}/exportAllBooths`),
  exportAllBoothsCancel: createAction(`${ACTIONS_PREFIX}/exportAllBoothsCancel`),

  resetInitialState: createAction(`${ACTIONS_PREFIX}/resetInitialState`),

  internalUpdateBoothsList: createAction<Booth[]>(`${ACTIONS_PREFIX}/_updateBoothsList`),
  internalUpdateLoadingBooths: createAction<boolean>(`${ACTIONS_PREFIX}/_updateLoadingBooths`),
};

export default eventEditBoothsV2Actions;
