import { ToolboxItem } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/DesignEditorInstanceCanvas/canvasTools';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  FC, ReactElement, useCallback, useMemo,
} from 'react';
import { StyledToolToggleButton } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorCanvasToolboxDocker/style';
import { Tooltip } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import designEditorSelectors from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorSelectors';
import designEditorActions from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorActions';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

interface DesignEditorToolboxIconButtonProps {
  toolboxItem: ToolboxItem
}

const StyledFaIcon = styled(FontAwesomeIcon)`
  font-size: 1.5em;
`;

const ToolboxIcon: FC<{ Icon: (() => ReactElement) | IconDefinition }> = ({ Icon }) => {
  const IconComponent = Icon as () => ReactElement;
  return (
    ((Icon as any).icon !== undefined)
      ? <StyledFaIcon icon={Icon as unknown as IconDefinition} />
      : (
        <span className="fa-layers fa-fw fa-2x" style={{ fontSize: '1.5em' }}>
          <IconComponent />
        </span>
      )
  );
};

const DesignEditorToolboxIconButton: FC<DesignEditorToolboxIconButtonProps> = ({ toolboxItem }) => {
  const selectedTool = useSelector(designEditorSelectors.selectSelectedCanvasTool);
  const selected = useMemo(() => selectedTool === toolboxItem.tool, [selectedTool, toolboxItem.tool]);
  const dispatch = useDispatch();
  const onClick = useCallback(() => {
    dispatch(designEditorActions.changeSelectedCanvasTool(toolboxItem.tool));
  }, [dispatch, toolboxItem.tool]);

  return (
    <Tooltip title={toolboxItem.title}>
      <span>
        <StyledToolToggleButton selected={selected} onClick={onClick} disabled={toolboxItem.disabled}>
          <ToolboxIcon Icon={toolboxItem.Icon} />
        </StyledToolToggleButton>
      </span>
    </Tooltip>
  );
};

export default DesignEditorToolboxIconButton;
