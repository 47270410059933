import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, tap,
} from 'rxjs/operators';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import { localizableFieldDefaultLocalePatcher, mapToSimpleMergeBoothAction, boothEditMutationCompleteObservable } from 'admin-data/booth/BoothEdit/redux/epics/queries/utils';
import { createExecutingTask } from 'utils/xtra-executing-tasks/executingTask';
import { boothEditExecutingTasksTagCreator } from 'admin-data/booth/BoothEdit/redux/boothEditExecutingTasks';
import { fetchAllPage } from 'utils/requestsUtils';
import { Booth } from 'models';
import { concat, merge } from 'rxjs';
import cmsSelectors from 'redux/cmsSelectors';

const queryBoothInfoEpic: Epic = (action$, state$) => action$.pipe(
  filter(boothEditActions.queries.queryBoothInfo.match),
  mergeMap(() => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const boothId = boothEditSelectors.selectBoothId(state$.value);
    const locale = boothEditSelectors.selectLocale(state$.value);
    const originalBoothFactory = () => boothEditSelectors.selectBooth(state$.value);

    const taskFactory = () => createExecutingTask({ tags: [boothEditExecutingTasksTagCreator.queries.queryBoothInfo()] });

    const infoRequest = sdk
      .getBoothEditInfo({ boothId, locales: [locale] })
      .pipe(
        tap((resp) => localizableFieldDefaultLocalePatcher([locale], resp)),
        map(mapToSimpleMergeBoothAction(originalBoothFactory)),
      );

    const eventTaxonomiesRequest = fetchAllPage(
      (after) => sdk.boothEditGetEventTaxonomiesList({ boothId, locales: [locale], after }),
      (resp) => (resp.node as Booth).event.boothTaxonomies.pageInfo,
    ).pipe(
      map(mapToSimpleMergeBoothAction(originalBoothFactory)),
    );

    const boothCategoriesRequest = fetchAllPage(
      (after) => sdk.boothEditGetBoothCategoriesList({ boothId, after }),
      (resp) => (resp.node as Booth).categories.pageInfo,
    ).pipe(
      map(mapToSimpleMergeBoothAction(originalBoothFactory)),
    );

    const requests = merge(
      infoRequest,
      eventTaxonomiesRequest,
      boothCategoriesRequest,
    );

    return concat(
      boothEditMutationCompleteObservable(action$, state$),
      requests,
    ).pipe(boothEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory));
  }),
);

export default queryBoothInfoEpic;
