import React, { FC, useState } from 'react';

export const ContentLocalizationContext = React.createContext({
  locale: 'en',
  setLocale: null,
  eventLocales: ['en'],
  defaultLocale: 'en',
});

export const ContentLocalizationContextProvider: FC<{ defaultLocale: string, eventLocales: string[] }> = ({ children, defaultLocale = 'en', eventLocales = ['en'] }) => {
  const previousLocale = localStorage.getItem('previousContentLocale');
  const [locale, _setLocale] = useState(previousLocale != null && eventLocales.indexOf(previousLocale) !== -1 ? previousLocale : defaultLocale);

  const setLocale = (newLocale) => {
    _setLocale(newLocale);
    localStorage.setItem('previousContentLocale', newLocale);
  };

  return (
    <ContentLocalizationContext.Provider value={{
      locale, setLocale, eventLocales, defaultLocale,
    }}
    >
      {children}
    </ContentLocalizationContext.Provider>
  );
};
