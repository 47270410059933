import {
  BoothTabMasterElement,
  BoothTabMasterElementUpdateInput,
} from 'models';
import {
  createUpdateParserBuilder,
} from 'utils/redux-field-bind/updateParser/updateParserBuilder';
import eventMasterElementUpdateParser
  from 'admin-data/event/EventEdit/redux/updateParsers/masterTab/eventMasterElementUpdateParser';

const eventRootMasterElementTabUpdateParser = createUpdateParserBuilder<BoothTabMasterElement, { eventId: string, tabMasterElement: BoothTabMasterElement }>((builder0) => {
  builder0.fallback(
    (state$, attributes, source, value) => ({
      requestFnProvider: (sdk) => sdk.boothTabMasterElementUpdate,
      batchIdentifier: `boothTabMasterElementUpdate-${attributes.tabMasterElement.id}`,
      fields: { [source]: value },
      inputModifier: (input: BoothTabMasterElementUpdateInput) => ({
        ...input,
        eventId: attributes.eventId,
        elementId: attributes.tabMasterElement.id,
      } as BoothTabMasterElementUpdateInput),
    }),
  );

  builder0.nodesHandlerByIndex((tab) => tab.body.nodes,
    (state$, attributes, index, source, value) => {
      const masterElement = attributes.tabMasterElement.body.nodes[index];
      return eventMasterElementUpdateParser(state$, {
        ...attributes,
        masterElement,
      }, source, value);
    });
});

export default eventRootMasterElementTabUpdateParser;
