import { filter, map, mergeMap } from 'rxjs/operators';
import { BoothDesignCreateInput } from 'models';
import designEditorActions from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorActions';
import { Epic } from 'redux-observable';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import cmsSelectors from 'redux/cmsSelectors';

/**
 * Create design epic
 */
const createDesignEpic: Epic = (action$, state$) => action$.pipe(
  filter(designEditorActions.createDesign.match),
  map((action) => action.payload),
  // do request to fetch the design design
  mergeMap((input: BoothDesignCreateInput) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    return sdk.boothDesignCreate({ input }).pipe(
      throwCommonError(),
      map((resp) => resp.boothDesignCreate.boothDesign.id),
      map((it) => designEditorActions.openDesign(it)),
      catchErrorToPopup(state$.value.intl),
    );
  }),
);

export default createDesignEpic;
