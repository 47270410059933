import React, { FC, useCallback, useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@material-ui/core';
import { Popup } from 'utils/xtra-popups/popup';
import { ConfirmationDialogData } from 'utils/xtra-popups/popupsTypes/confirmationDialogData';
import { useDispatch } from 'react-redux';
import { PopupsActions } from 'utils/xtra-popups/createPopupsActions';
import cmsActions from 'redux/cmsActions';

export interface XtraConfirmationDialogProps {
  popup: Popup<ConfirmationDialogData>
}

const XtraConfirmationDialog: FC<XtraConfirmationDialogProps> = ({ popup: { id, data } }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const onCancel = useCallback(() => {
    if (data.cancelAction != null) dispatch(data.cancelAction);
    setOpen(false);
    setTimeout(() => dispatch(cmsActions.popups.removePopup(id)), 1000);
  }, [data.cancelAction, dispatch, id]);

  const onConfirm = useCallback(() => {
    if (data.confirmAction != null) dispatch(data.confirmAction);
    setOpen(false);
    setTimeout(() => dispatch(cmsActions.popups.removePopup(id)), 1000);
  }, [data.confirmAction, dispatch, id]);

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{data.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {data.description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default XtraConfirmationDialog;
