import { Epic } from 'redux-observable';
import addLayerEpic from 'libs/xtra-custom-booth-design/redux/design-editor-instance/epics/addLayerEpic';
import deleteLayerByIdEpic from 'libs/xtra-custom-booth-design/redux/design-editor-instance/epics/deleteLayerByIdEpic';
import internalAddSequencedRequestEpic from 'libs/xtra-custom-booth-design/redux/design-editor-instance/epics/internalAddSequencedRequestEpic';
import reorderLayerEpic from 'libs/xtra-custom-booth-design/redux/design-editor-instance/epics/reorderLayerEpic';
import updateLayerEpic from 'libs/xtra-custom-booth-design/redux/design-editor-instance/epics/updateLayerEpic';
import updateLayerFieldsEpic from 'libs/xtra-custom-booth-design/redux/design-editor-instance/epics/updateLayerFieldsEpic';
import internalAddDebouncedRequestEpic from 'libs/xtra-custom-booth-design/redux/design-editor-instance/epics/internalAddDebouncedRequestEpic';
import updateCanvasEpic from 'libs/xtra-custom-booth-design/redux/design-editor-instance/epics/updateCanvasEpic';
import queryDesignEpic from 'libs/xtra-custom-booth-design/redux/design-editor-instance/epics/queryDesignEpic';

const designEditorInstanceActionEpics: Epic[] = [
  addLayerEpic,
  deleteLayerByIdEpic,
  internalAddSequencedRequestEpic,
  internalAddDebouncedRequestEpic,
  reorderLayerEpic,
  updateLayerEpic,
  updateLayerFieldsEpic,
  updateLayerEpic,
  updateCanvasEpic,
  queryDesignEpic,
  // TODO update layer queue request
];

export default designEditorInstanceActionEpics;
