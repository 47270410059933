import { createAction, PayloadActionCreator } from '@reduxjs/toolkit';
import { Observable } from 'rxjs';
import { DESIGN_EDITOR_ACTION_PREFIX } from 'libs/xtra-custom-booth-design/redux/designEditorVars';
import { Action } from 'redux';
import {
  CanvasViewConfig, DesignEditorInstanceToolConfig,
  ExecutingTask,
} from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceState';
import { BoothDesignCanvasMasterElement, BoothDesignMasterElement, DisplayMeasurement } from 'models';
import { LayerElementType } from 'libs/xtra-custom-booth-design/types/DesignEditorInstance';
import { DesignIdWrappedPayload } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designIdWrappedPayload';

export interface DebouncedRequest {
  /**
   * Same key will only keep the lastest request after debounce, null if never omit
   */
  debounceKey: string;
  /**
   * Factory that create request observable and return actions
   */
  requestFactory: () => Observable<Action>;
}

export interface ReorderLayerPayload {
  source: number;
  dest: number;
}

export interface AddLayerPayload {
  type: LayerElementType
  positionX: DisplayMeasurement;
  positionY: DisplayMeasurement;
  height: DisplayMeasurement;
  width: DisplayMeasurement;
}

export type UpdateLayerFieldsPayload = {
  layerId: string;
  fields: {
    /**
     * The source path based on layer object
     */
    source: string
    value: any
  }[];
};

export const rawDesignEditorInstanceActions = {
  /**
   * Add layer by typename
   */
  addLayer: createAction<DesignIdWrappedPayload<AddLayerPayload>>(`${DESIGN_EDITOR_ACTION_PREFIX}/instance/addLayer`),
  deleteLayerById: createAction<DesignIdWrappedPayload<string>>(`${DESIGN_EDITOR_ACTION_PREFIX}/instance/deleteLayerById`),
  reorderLayer: createAction<DesignIdWrappedPayload<ReorderLayerPayload>>(`${DESIGN_EDITOR_ACTION_PREFIX}/instance/reorderLayer`),
  updateSelectedLayerIds: createAction<DesignIdWrappedPayload<string[]>>(`${DESIGN_EDITOR_ACTION_PREFIX}/instance/updateSelectedLayers`),
  updateCanvasViewConfig: createAction<DesignIdWrappedPayload<Omit<CanvasViewConfig, 'heightScale'>>>(`${DESIGN_EDITOR_ACTION_PREFIX}/instance/updateCanvasViewConfig`),
  updateCanvas: createAction<DesignIdWrappedPayload<Partial<BoothDesignCanvasMasterElement>>>(`${DESIGN_EDITOR_ACTION_PREFIX}/instance/updateCanvas`),
  updateLayer: createAction<DesignIdWrappedPayload<BoothDesignMasterElement>>(`${DESIGN_EDITOR_ACTION_PREFIX}/instance/updateLayer`),
  updateLayerFields: createAction<DesignIdWrappedPayload<UpdateLayerFieldsPayload>>(`${DESIGN_EDITOR_ACTION_PREFIX}/instance/updateLayerFields`),
  updateLayers: createAction<DesignIdWrappedPayload<BoothDesignMasterElement[]>>(`${DESIGN_EDITOR_ACTION_PREFIX}/instance/updateLayers`),
  updateToolConfig: createAction<DesignIdWrappedPayload<DesignEditorInstanceToolConfig>>(`${DESIGN_EDITOR_ACTION_PREFIX}/instance/updateToolConfig`),
  queryDesign: createAction<DesignIdWrappedPayload<undefined>>(`${DESIGN_EDITOR_ACTION_PREFIX}/instance/queryDesign`),

  // Internal actions, should be called by epic
  /**
   * The requests that will run sequentially
   * useful for add/delete request
   */
  internalAddSequencedRequest: createAction<DesignIdWrappedPayload<Observable<Action>>>(`${DESIGN_EDITOR_ACTION_PREFIX}/instance/_addSequencedRequest`),
  /**
   * The requests that will be debounce
   * the request should do null checking to avoid the object be deleted before save request debounced
   * useful for sync object properties request
   */
  internalAddDebouncedRequest: createAction<DesignIdWrappedPayload<DebouncedRequest>>(`${DESIGN_EDITOR_ACTION_PREFIX}/instance/_addDebouncedRequest`),
  internalUpdateLayer: createAction<DesignIdWrappedPayload<BoothDesignMasterElement>>(`${DESIGN_EDITOR_ACTION_PREFIX}/instance/_updateLayer`),
  internalUpdateCanvas: createAction<DesignIdWrappedPayload<BoothDesignCanvasMasterElement>>(`${DESIGN_EDITOR_ACTION_PREFIX}/instance/_updateCanvas`),
  internalUpdateLayers: createAction<DesignIdWrappedPayload<BoothDesignMasterElement[]>>(`${DESIGN_EDITOR_ACTION_PREFIX}/instance/_updateLayers`),
};

// ================================================================================
// Raw Actions -> designId Embedded Actions mapper
// ================================================================================

type RawDesignEditorInstanceActions = typeof rawDesignEditorInstanceActions
type extractActionPayloadGeneric<Type> = Type extends PayloadActionCreator<infer P, infer T> ? P : never
type extractActionType<Type> = Type extends PayloadActionCreator<infer P, infer T> ? T : never
type extractRawDesignGeneric<Type> = Type extends DesignIdWrappedPayload<infer T> ? T : never

export type DesignEditorInstanceActions = {
  [K in keyof RawDesignEditorInstanceActions]: PayloadActionCreator<extractRawDesignGeneric<extractActionPayloadGeneric<RawDesignEditorInstanceActions[K]>>, extractActionType<RawDesignEditorInstanceActions[K]>>
}

const createDesignEditorInstanceActions: (instanceDesignId: string) => DesignEditorInstanceActions = (instanceDesignId) => Object
  .entries(rawDesignEditorInstanceActions).reduce((mappedObj, [key, func]) => {
    mappedObj[key] = (payloadData) => (func)({
      instanceDesignId,
      data: payloadData,
    });
    return mappedObj;
  }, {}) as DesignEditorInstanceActions;

export default createDesignEditorInstanceActions;
