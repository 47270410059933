// eslint-disable-next-line import/prefer-default-export
export const CMS_GENERAL_FILE_SIZE_LIMIT = 39 * 1024 * 1024;
export const CMS_GENERAL_VIDEO_SIZE_LIMIT = 2048 * 1024 * 1024;

export const CMS_PRODUCT_API_ENDPOINT = process.env.REACT_APP_ENV === 'production' ? 'https://vexpo-product-api.eventxtra.com' : 'https://vexpo-product-api.2vanx.com';

export const CMS_AVAILABLE_MERGE_TAGS = [
  'booth_number',
  'booth_name',
  'booth_website',
  'booth_description',
  'exhibition_name',
];
/* eslint-disable import/prefer-default-export */
export const API_ENDPOINT = process.env.REACT_APP_ENV === 'production' ? 'https://api-virtual.eventxtra.com/graphql' : 'https://api-virtual.2vanx.com/graphql';
export const Integrations = {
  PRODUCT_PAGE: process.env.REACT_APP_ENV === 'production' ? 'https://vexpo-product.eventxtra.com' : 'https://vexpo-product.2vanx.com',
  REPORT_PAGE: 'https://vexpo-statistic.eventxtra.com/rt/',
};
export const EVENT_FIRESTORE_API = process.env.REACT_APP_ENV === 'production' ? 'https://vexpo-event-api.eventxtra.com/events' : 'https://vexpo-event-api.2vanx.com/events';
export const FIRESTORE_ENDPOINT = process.env.REACT_APP_ENV === 'production' ? 'https://vexpo-event-api.eventxtra.com' : 'https://vexpo-event-api.2vanx.com';
export const INITIATE_UPLOAD_ENDPOINT = 'https://ancient-queen-b6c1.eventxtra.workers.dev';
export const IS_PRODUCTION = process.env.REACT_APP_ENV === 'production';

export const SPA_DEFAULT_THEME_COLOR = '#0CA58B';
export const CHAT_API_BEARER = process.env.REACT_APP_ENV === 'production' ? 'Bearer m4hd5BQyCYzcYEJCQY9sskYN' : 'Bearer Ndpd35jSp?xEmjei#Rm9KX#R';

export const AVAILABLE_LOCALES = {
  en: 'English',
  ja: '日本語',
  'id-ID': 'Indonesian',
  'zh-Hans': '简体中文',
  'zh-Hant': '繁體中文',
  ko: '한국어',
  es: 'Español',
  fr: 'Français',
  vi: 'Tiếng Việt',
  ru: 'русский',
  th: 'Thai',
};
