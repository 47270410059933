import { ActionReducerMapBuilder } from '@reduxjs/toolkit';
import { EventEditState } from 'admin-data/event/EventEdit/redux/eventEditState';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import { createEventEditBoothsV2FragmentInitialState } from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/eventEditBoothsFramgnetState';
import { LocalStorageKeys } from 'utils/localstorageKeys';

const eventEditBoothsV2ExtraReducer = (builder: ActionReducerMapBuilder<EventEditState>) => {
  builder.addCase(eventEditActions.fragments.booths.resetInitialState, ((state, action) => {
    state.fragments.boothsV2 = createEventEditBoothsV2FragmentInitialState();
  }));

  builder.addCase(eventEditActions.fragments.booths.setPageNumber, ((state, action) => {
    state.fragments.boothsV2.pageNumber = action.payload;
  }));

  builder.addCase(eventEditActions.fragments.booths.setPageSize, ((state, action) => {
    state.fragments.boothsV2.pageSize = action.payload;
    localStorage.setItem(LocalStorageKeys.EventEditBoothsListPageSize, `${action.payload}`);
  }));

  builder.addCase(eventEditActions.fragments.booths.setCurrentTierId, ((state, action) => {
    state.fragments.boothsV2.pageNumber = 0;
    state.fragments.boothsV2.currentTierId = action.payload;
  }));

  builder.addCase(eventEditActions.fragments.booths.selectedBooths.clear, ((state, action) => {
    state.fragments.boothsV2.selectedBooths = {};
  }));

  builder.addCase(eventEditActions.fragments.booths.selectedBooths.toggleSelectedBooths, ((state, action) => {
    action.payload.forEach((booth) => {
      if (state.fragments.boothsV2.selectedBooths[booth.id] == null) {
        state.fragments.boothsV2.selectedBooths[booth.id] = booth;
      } else {
        delete state.fragments.boothsV2.selectedBooths[booth.id];
      }
    });
  }));

  builder.addCase(eventEditActions.fragments.booths.boothCreation.updateCreation, (state, action) => {
    state.fragments.booths.boothCreation = action.payload;
  });

  builder.addCase(eventEditActions.fragments.booths.boothCreation.close, (state, action) => {
    state.fragments.booths.boothCreation.show = false;
  });
};

export default eventEditBoothsV2ExtraReducer;
