import { createAction } from '@reduxjs/toolkit';
import { DESIGN_EDITOR_ACTION_PREFIX } from 'libs/xtra-custom-booth-design/redux/designEditorVars';
import { DesignEditorInstanceState } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceState';
import CanvasTools from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/DesignEditorInstanceCanvas/canvasTools';
import {
  BoothDesign, BoothDesignCreateInput, Event, LocalizableString,
} from 'models';
import { createUploadTasksActions } from 'utils/xtra-upload-tasks/createUploadTasksActions';

export interface UpdateDockerFrameDockerIdsPayload {
  dockerFrameId: string;
  dockersIds: string[];
}

export interface UpdateDockerFrameInfoDataPayload {
  dockerFrameId: string;
  data: any;
}

export interface RenameDesignPayload {
  designId: string;
  name: LocalizableString;
}

const designEditorActions = {
  setEvent: createAction<Event>(`${DESIGN_EDITOR_ACTION_PREFIX}/setEvent`),
  /**
   * Create a new design template and open a design editor instance
   */
  createDesign: createAction<BoothDesignCreateInput>(`${DESIGN_EDITOR_ACTION_PREFIX}/createDesign`),
  /**
   * Open a design editor by design id
   */
  openDesign: createAction<string>(`${DESIGN_EDITOR_ACTION_PREFIX}/openDesign`),
  /**
   * Delete a design by design id
   */
  deleteDesign: createAction<string>(`${DESIGN_EDITOR_ACTION_PREFIX}/deleteDesign`),
  /**
   * Rename a design
   */
  renameDesign: createAction<RenameDesignPayload>(`${DESIGN_EDITOR_ACTION_PREFIX}/renameDesign`),
  /**
   * Close a design editor by design id
   */
  closeDesign: createAction<string>(`${DESIGN_EDITOR_ACTION_PREFIX}/closeDesign`),
  loadDesignsList: createAction(`${DESIGN_EDITOR_ACTION_PREFIX}/loadDesignsList`),
  /**
   * Set active instance by design id
   */
  setActiveInstance: createAction<string>(`${DESIGN_EDITOR_ACTION_PREFIX}/setActiveDesign`),
  /**
   * Set active instance by design id
   */
  updateDockerFrameDockerIds: createAction<UpdateDockerFrameDockerIdsPayload>(`${DESIGN_EDITOR_ACTION_PREFIX}/updateDockerFrameDockerIds`),
  /**
   * Set active instance by design id
   */
  updateDockerFrameInfoData: createAction<UpdateDockerFrameInfoDataPayload>(`${DESIGN_EDITOR_ACTION_PREFIX}/updateDockerFrameInfoData`),

  changeSelectedCanvasTool: createAction<CanvasTools>(`${DESIGN_EDITOR_ACTION_PREFIX}/changeSelectedTool`),

  uploading: createUploadTasksActions(DESIGN_EDITOR_ACTION_PREFIX),

  // Internal actions for epic, should not be called directly
  internalUpsertEditorInstance: createAction<DesignEditorInstanceState>(`${DESIGN_EDITOR_ACTION_PREFIX}/_upsertInstance`),
  internalRemoveEditorInstance: createAction<string>(`${DESIGN_EDITOR_ACTION_PREFIX}/_removeInstance`),
  internalUpdateDesignsList: createAction<BoothDesign[]>(`${DESIGN_EDITOR_ACTION_PREFIX}/_updateDesignsList`),
  internalUpdateDesignsListLoading: createAction<boolean>(`${DESIGN_EDITOR_ACTION_PREFIX}/_updateDesignsListLoading`),
};

export default designEditorActions;
