import { Epic } from 'redux-observable';
import {
  filter, ignoreElements, map, mergeMap,
} from 'rxjs/operators';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import cmsSelectors from 'redux/cmsSelectors';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import produce from 'immer';
import { concat, of } from 'rxjs';
import { BoothMasterElementType } from 'admin-data/event/EventEdit/typenameEnums';
import { BoothMasterElement, BoothQuestionMasterElement } from 'models';

const deleteTabBodyMasterElementEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.mutations.deleteTabBodyMasterElement.match),
  mergeMap(({ payload: { id, tabId } }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const eventId = eventEditSelectors.selectEventId(state$.value);
    const originalEventFactory = () => eventEditSelectors.selectEvent(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.mutations.deleteTabBodyMasterElement(),
        ExecutingTaskCommonTag.Mutation],
    });

    const internalDeleteAction = eventEditActions.internalUpdateEvent(produce(originalEventFactory(), ((draft) => {
      const tab = draft.rootMasterElement.tabs.nodes.find((it) => it.id === tabId);
      const index = tab.body.nodes.findIndex((element) => element.id === id);
      tab.body.nodes.splice(index, 1);
    })));

    const requests = sdk.boothMasterElementDelete({
      input: { eventId, elementId: id },
    }).pipe(
      throwCommonError(),
      ignoreElements(),
      catchErrorToPopup(state$.value.intl),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return concat(
      of(internalDeleteAction),
      of(eventEditActions.internalAddSequencedRequest(requests)),
    );
  }),
);

export default deleteTabBodyMasterElementEpic;
