import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, tap,
} from 'rxjs/operators';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import cmsSelectors from 'redux/cmsSelectors';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { createExecutingTask } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import { fetchAllPage } from 'utils/requestsUtils';
import { BoothTabMasterElement } from 'models';
import {
  localizableFieldDefaultLocalePatcher,
} from 'admin-data/booth/BoothEdit/redux/epics/queries/utils';
import {
  eventEditMutationCompleteObservable,
} from 'admin-data/event/EventEdit/redux/epic/utils';
import _ from 'lodash';
import { concat } from 'rxjs';
import produce from 'immer';

const queryMasterTabByIdEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.queries.queryMasterTabById.match),
  mergeMap(({ payload: tabId }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const locale = eventEditSelectors.selectEditLocale(state$.value);

    const taskFactory = () => createExecutingTask({ tags: [eventEditExecutingTasksTagCreator.queries.queryMasterTabById()] });

    const requests = fetchAllPage(
      (after) => sdk.eventEditGetMasterTabById({ tabId, locales: [locale], after }),
      (resp) => (resp.node as BoothTabMasterElement).body.pageInfo,
    ).pipe(
      tap((resp) => localizableFieldDefaultLocalePatcher([locale], resp)),
      map((tab) => {
        const updatedEvent = produce(eventEditSelectors.selectEvent(state$.value), (draft) => {
          const originalIndex = draft.rootMasterElement.tabs.nodes.findIndex((findingTab) => findingTab.id === tabId);
          if (originalIndex === -1) throw new Error(`Tab list is not fetched or the list do not contains tab ${tabId}, cannot determine the tab order`);
          _.merge(draft.rootMasterElement.tabs.nodes[originalIndex], tab.node);
        });
        return eventEditActions.internalUpdateEvent(updatedEvent);
      }),
    );

    return concat(
      eventEditMutationCompleteObservable(action$, state$), // wait all mutation complete
      requests,
    ).pipe(eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory));
  }),
);

export default queryMasterTabByIdEpic;
