import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import { Booth, BoothRepresentativeUpdateInput, BoothUpdateInput } from 'models';
import { BatchedDebouncedUpdateRequest, createUpdateParserBuilder } from 'utils/redux-field-bind/updateParser/updateParserBuilder';
import boothRootContentElementTabUpdateParser from 'admin-data/booth/BoothEdit/redux/updateParsers/boothRootContentElementsUpdateParser';
import boothLegacyElementTabUpdateParser from 'admin-data/booth/BoothEdit/redux/updateParsers/boothLegacyDesignElementsUpdateParser';
import boothDesignLayersUpdateParser from 'admin-data/booth/BoothEdit/redux/updateParsers/boothDesignLayersUpdateParser';

const boothUpdateParser = createUpdateParserBuilder<Booth, { boothId: string }>((builder0) => {
  // For all common field under booth
  builder0.fallback(((state$, attributes, source, value) => ({
    requestFnProvider: (sdk) => sdk.boothUpdate,
    batchIdentifier: `boothUpdate-${attributes.boothId}`,
    fields: { [source]: value },
    inputModifier: (input: BoothUpdateInput) => ({ ...input, id: attributes.boothId }),
  })));

  builder0.handler(
    ((booth) => booth.categories.nodes),
    (state$, attributes, source, value) => {
      const currentCategoriesIds = boothEditSelectors.selectBooth(state$.value).categories.nodes.map(({ id }) => id);
      return {
        requestFnProvider: (sdk) => sdk.boothUpdate,
        batchIdentifier: `boothUpdate-${attributes.boothId}`,
        fields: { categories: currentCategoriesIds },
        inputModifier: (input: BoothUpdateInput) => ({ ...input, id: attributes.boothId }),
      } as BatchedDebouncedUpdateRequest<any, any>;
    },
  );

  // For all field inside booth.rootContentElement.tabs.nodes[index]
  builder0.nodesHandlerByIndex(
    (booth) => booth.rootContentElement.tabs.nodes,
    ((state$, attributes, index, source, value) => {
      const tabElement = boothEditSelectors.selectBooth(state$.value).rootContentElement.tabs.nodes[index];
      return boothRootContentElementTabUpdateParser(state$, {
        ...attributes,
        tabElement,
      }, source, value);
    }),
  );

  // Legacy design element
  builder0.nodesHandlerByIndex(
    (booth) => booth.designElements.nodes,
    ((state$, attributes, index, source, value) => {
      const designElement = boothEditSelectors.selectBooth(state$.value).designElements.nodes[index];
      return boothLegacyElementTabUpdateParser(state$, {
        ...attributes,
        designElement,
      }, source, value);
    }),
  );

  // for all field inside booth.boothDesignCanvas.layers.nodes[index]
  builder0.nodesHandlerByIndex(
    (booth) => booth.boothDesignCanvas.layers.nodes,
    ((state$, attributes, index, source, value) => {
      const designElement = boothEditSelectors.selectBooth(state$.value).boothDesignCanvas.layers.nodes[index];
      return boothDesignLayersUpdateParser(state$, {
        ...attributes,
        designElement,
      }, source, value);
    }),
  );

  builder0.nodesHandlerByIndex(
    (booth) => booth.representatives.nodes,
    ((state$, attributes, index, source, value) => {
      const representative = boothEditSelectors.selectBooth(state$.value).representatives.nodes[index];
      return {
        requestFnProvider: (sdk) => sdk.boothRepresentativeUpdate,
        batchIdentifier: `boothRepresentatives-${attributes.boothId}`,
        fields: { [source]: value },
        inputModifier: (input: BoothRepresentativeUpdateInput) => ({
          ...input,
          boothId: attributes.boothId,
          representativeId: representative.id,
        } as BoothRepresentativeUpdateInput),
      } as BatchedDebouncedUpdateRequest<any, any>;
    }),
  );
});

export default boothUpdateParser;
