import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, takeUntil,
} from 'rxjs/operators';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import { concat, defer, of } from 'rxjs';
import { EventSettings } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import produce from 'immer';
import { EVENT_FIRESTORE_API } from 'config';

const fetchLandingPageEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.fetchLandingPage.match),
  mergeMap(() => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const getEventUrl = `${EVENT_FIRESTORE_API}?event_domain=${domain}`;
    const fetchLandingPage = defer(() => fetch(getEventUrl));
    const originalEventEditFactory = () => eventEditSelectors.selectEventEdit(state$.value);

    return concat(
      of(eventSettingEditActions.internalUpdateLoadingLanding(true)),
      fetchLandingPage.pipe(
        mergeMap((resp) => resp.json() as Promise<Partial<EventSettings>>),
        map(({ landing, registrationStartTime, registrationEndTime }) => eventEditActions.internalUpdate(produce(originalEventEditFactory(), (draft) => {
          draft.fragments.eventSettings.landing = landing;
          draft.fragments.eventSettings.registrationStartTime = registrationStartTime;
          draft.fragments.eventSettings.registrationEndTime = registrationEndTime;
        }))),
      ),
      of(eventSettingEditActions.internalUpdateLoadingLanding(false)),
    ).pipe(
      takeUntil(action$.pipe(filter(eventSettingEditActions.fetchLandingPage.match))), // fetch until another fetch started
    );
  }),
);

export default fetchLandingPageEpic;
