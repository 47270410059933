import {
  Booth, BoothDesign, BoothTier, Hall,
} from 'models';

export interface EventEditBoothsFragmentBoothCreationConfigs {
  availableHalls: Hall[];
  availableBoothTiers: BoothTier[];
  availableDesigns: string[];

  hallId: string;
  boothTierId: string;
  appliedDesignCodename?: string;
  design?: BoothDesign;
  amount: number
}

export interface EventEditBoothsFragmentBoothCreation {
  show: boolean;
  configs: EventEditBoothsFragmentBoothCreationConfigs;
  booths: Booth[];
  boothsCreateProgress: {
    total: number;
    created: number;
  }
}

export interface EventEditBoothsFragmentState {
  /**
   * Fetched booths
   */
  boothsList: Booth[]
  loadingBooths: boolean

  boothCreation: EventEditBoothsFragmentBoothCreation
}

export const eventEditBoothsFragmentInitialState: EventEditBoothsFragmentState = {
  boothsList: [],
  loadingBooths: true,
  boothCreation: null,
};
