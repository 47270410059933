import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap,
} from 'rxjs/operators';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import { concat, defer, of } from 'rxjs';
import { CustomPageGroup } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { EVENT_FIRESTORE_API } from 'config';

const fetchEventCustomPageEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.fetchCustomPage.match),
  mergeMap(() => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const fetchUrl = `${EVENT_FIRESTORE_API}?event_domain=${domain}`;
    const fetchCustomPages = defer(() => fetch(fetchUrl))
      .pipe(
        mergeMap((resp) => resp.json() as Promise<{ customPage: CustomPageGroup[] }>),
        map(({ customPage }) => eventSettingEditActions.setCustomPages((customPage || []))),
      );
    return concat(
      of(eventSettingEditActions.customPagesLoading(true)),
      fetchCustomPages,
      of(eventSettingEditActions.customPagesLoading(false)),
    );
  }),
);

export default fetchEventCustomPageEpic;
