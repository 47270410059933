import React, { useCallback, useState } from 'react';
import { FileContentPreviewer } from 'libs/xtra-custom-booth-design/components/shared/XtraDropZoneDialog/FilePreviewer/fileContentPreviewers/fileContentPreviewer';
import { Link } from '@material-ui/core';
import {
  ContentPreviewerContainer, FileInfoContainer, FileNameContainer, PreviewArea,
} from 'libs/xtra-custom-booth-design/components/shared/XtraDropZoneDialog/FilePreviewer/style';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const StyledVideo = styled.video`
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-height: inherit;
`;

const VideoFileContentPreviewer: FileContentPreviewer = ({ filePreview, direction }) => {
  const [dimensions, setDimensions] = useState(null);
  const [duration, setDuration] = useState<string>(null);

  const onVideoLoad = useCallback((e) => {
    setDimensions(`${e.target.videoWidth} x ${e.target.videoHeight}`);
    if (e.target.duration != null) {
      const second = Math.floor(e.target.duration % 60);
      const minute = Math.floor(e.target.duration / 60);
      setDuration(`${minute < 10 ? '0' : ''}${minute} : ${second}`);
    }
  }, []);

  return (
    <ContentPreviewerContainer direction={direction}>
      <PreviewArea>
        <StyledVideo onLoadedMetadata={onVideoLoad} controls>
          <source src={filePreview.fileUrl} type={filePreview.contentType} />
        </StyledVideo>
      </PreviewArea>
      <FileInfoContainer>
        <table>
          <tr>
            <th>
              <FormattedMessage id="common.file_name" defaultMessage="File Name" />
              :
            </th>
            <td>
              <Link color="primary" target="_blank" href={filePreview.fileUrl}>
                <FileNameContainer>
                  {filePreview?.filename}
                </FileNameContainer>
              </Link>
            </td>
          </tr>
          <tr>
            <th>
              <FormattedMessage id="shared.dropzone.preview.video_resolution" defaultMessage="Video Resolution" />
              :
            </th>
            <td>{dimensions}</td>
          </tr>
          <tr>
            <th>
              <FormattedMessage id="shared.dropzone.preview.video_duration" defaultMessage="Video Duration" />
              :
            </th>
            <td>{duration}</td>
          </tr>
        </table>
      </FileInfoContainer>
    </ContentPreviewerContainer>
  );
};

export default VideoFileContentPreviewer;
