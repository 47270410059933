import { createSlice } from '@reduxjs/toolkit';
import { CMS_STORE_NAME } from 'redux/cmsVars';
import { injectPopupsExtraReducer } from 'utils/xtra-popups/injectPopupsExtraReducer';
import cmsActions from 'redux/cmsActions';
import { cmsInitialState } from 'redux/cmsState';
import { injectHintsExtraReducer } from 'utils/xtra-hints/injectHintsExtraReducer';

const cmsSlice = createSlice({
  name: CMS_STORE_NAME,
  initialState: cmsInitialState,
  reducers: {},
  extraReducers: (builder) => {
    injectPopupsExtraReducer(builder, cmsActions.popups, (state) => state.popups);

    builder.addCase(cmsActions.internalUpdateTranslations, (state, action) => {
      state.translations = action.payload;
    });

    builder.addCase(cmsActions.setSDKAccount, (state, action) => {
      state.sdkAccount = action.payload;
    });

    builder.addCase(cmsActions.updateAuthRole, (state, action) => {
      state.auth.role = action.payload;
    });

    builder.addCase(cmsActions.updateSettingDialogOpen, (state, action) => {
      state.settingDialogOpen = action.payload;
    });

    injectHintsExtraReducer(builder, cmsActions.hints, (state) => state.hints, 'cmsHints');
  },
});

const cmsReducer = cmsSlice.reducer;
export default cmsReducer;
