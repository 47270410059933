import { ignoreElements, map, mergeMap } from 'rxjs/operators';
import createDesignEditorInstanceSelectors from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceSelectors';
import { concat, EMPTY, of } from 'rxjs';
import { rawDesignEditorInstanceActions } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceActions';
import { DesignIdWrappedPayload } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designIdWrappedPayload';
import { Epic } from 'redux-observable';
import designEditorSelectors from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorSelectors';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import { ExecutingTask } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceState';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import { v4 as uuidv4 } from 'uuid';
import cmsSelectors from 'redux/cmsSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';

/**
 * Delete layer epic
 */
const deleteLayerByIdEpic: Epic = (action$, state$) => action$
  .ofType(rawDesignEditorInstanceActions.deleteLayerById).pipe(
    map((action) => action.payload as DesignIdWrappedPayload<string>),
    mergeMap(({ instanceDesignId, data: layerId }) => {
      const eventId = designEditorSelectors.selectDesignEditor(state$.value).event.id;

      const { selectLayers, selectSelectedLayersId } = createDesignEditorInstanceSelectors(instanceDesignId);

      const selectedLayersId = selectSelectedLayersId(state$.value);
      const layers = selectLayers(state$.value);
      const newLayers = layers.filter((layer) => layer.id !== layerId);
      const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);

      const request = sdk.designEditorLayerDeleteById({ input: { elementId: layerId, eventId } }).pipe(
        throwCommonError(),
        ignoreElements(),
        catchErrorToPopup(state$.value.intl),
      );

      const selectedLayerIndex = selectedLayersId.indexOf(layerId);

      const executingTask: ExecutingTask = createExecutingTask({
        name: 'Deleting layer',
        progress: -1,
        tags: [ExecutingTaskCommonTag.Mutation],
      });

      return concat(
        selectedLayerIndex !== -1 ? of(rawDesignEditorInstanceActions.updateSelectedLayerIds(
          { instanceDesignId, data: [...selectedLayersId.filter((id) => id !== layerId)] },
        )) : EMPTY,
        of(rawDesignEditorInstanceActions.internalUpdateLayers({ instanceDesignId, data: newLayers })),
        of(eventEditActions.executingTasks.upsertExecutingTasks(executingTask)),
        request,
        of(eventEditActions.executingTasks.removeExecutingTasks(executingTask.id)),
      );
    }),
  );

export default deleteLayerByIdEpic;
