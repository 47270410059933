import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, reduce, tap, toArray,
} from 'rxjs/operators';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import { CmsSDKObservables } from 'utils/auth';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import {
  localizableFieldDefaultLocalePatcher,
  mapToSimpleMergeBoothAction, boothEditMutationCompleteObservable,
} from 'admin-data/booth/BoothEdit/redux/epics/queries/utils';
import { concat, merge, of } from 'rxjs';
import { fetchAllPage } from 'utils/requestsUtils';
import { BoothContentElement, BoothTabContentElement } from 'models';
import produce from 'immer';
import _ from 'lodash';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { boothEditExecutingTasksTagCreator } from 'admin-data/booth/BoothEdit/redux/boothEditExecutingTasks';
import cmsSelectors from 'redux/cmsSelectors';

const fetchBoothTabBody = (sdk: CmsSDKObservables, locale: string, tabId: string) => fetchAllPage(
  (after) => sdk.boothEditGetContentElementTabBodyNodes({ tabId, locales: [locale], after }),
  (resp) => (resp.node as BoothTabContentElement).body.pageInfo,
).pipe(
  map((resp) => (resp.node as BoothTabContentElement)),
);

const queryContentTabEpic: Epic = (action$, state$) => action$.pipe(
  filter(boothEditActions.queries.queryContentTabById.match),
  mergeMap(({ payload: tabId }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const locale = boothEditSelectors.selectLocale(state$.value);

    const taskFactory = () => createExecutingTask({ tags: [boothEditExecutingTasksTagCreator.queries.queryContentTab(tabId)] });

    const requests = fetchBoothTabBody(sdk, locale, tabId)
      .pipe(
        tap((resp) => localizableFieldDefaultLocalePatcher([locale], resp)),
        map((tab) => {
          const updatedBooth = produce(boothEditSelectors.selectBooth(state$.value), (draft) => {
            const originalIndex = draft.rootContentElement.tabs.nodes.findIndex((findingTab) => findingTab.id === tabId);
            if (originalIndex === -1) throw new Error(`Tab list is not fetched or the list do not contains tab ${tabId}, cannot determine the tab order`);
            _.merge(draft.rootContentElement.tabs.nodes[originalIndex], tab);
          });
          return boothEditActions.internalUpsertBooth(updatedBooth);
        }),
      );

    return concat(
      boothEditMutationCompleteObservable(action$, state$),
      requests,
    ).pipe(boothEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory));
  }),
);

export default queryContentTabEpic;
