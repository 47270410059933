import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, tap,
} from 'rxjs/operators';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import { mapToSimpleMergeBoothAction, boothEditMutationCompleteObservable } from 'admin-data/booth/BoothEdit/redux/epics/queries/utils';
import { createExecutingTask } from 'utils/xtra-executing-tasks/executingTask';
import { boothEditExecutingTasksTagCreator } from 'admin-data/booth/BoothEdit/redux/boothEditExecutingTasks';
import { fetchAllPage } from 'utils/requestsUtils';
import { Booth, BoothDesignContentAreaElement } from 'models';
import { concat, merge, of } from 'rxjs';
import produce from 'immer';
import cmsSelectors from 'redux/cmsSelectors';

const defaultContentAreaTypePatcher = ({ node }) => {
  (node as Booth).boothDesignCanvas.layers.nodes
    .filter((it) => it.__typename === 'BoothDesignContentAreaElement')
    .forEach((it: BoothDesignContentAreaElement) => {
      // eslint-disable-next-line prefer-destructuring
      if (it.contentType == null) it.contentType = it.acceptedContentTypes[0];
    });
};

const queryDesignCanvasEpic: Epic = (action$, state$) => action$.pipe(
  filter(boothEditActions.queries.queryDesignCanvas.match),
  mergeMap(() => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const boothId = boothEditSelectors.selectBoothId(state$.value);
    const locale = boothEditSelectors.selectLocale(state$.value);
    const originalBoothFactory = () => boothEditSelectors.selectBooth(state$.value);

    const taskFactory = () => createExecutingTask({ tags: [boothEditExecutingTasksTagCreator.queries.queryDesignCanvasLayersList()] });

    const designRequest = sdk.boothEditGetBoothDesign({ boothId }).pipe(map(mapToSimpleMergeBoothAction(originalBoothFactory)));

    const canvasRequest = sdk.boothEditGetBoothDesignCanvas({ boothId }).pipe(map(mapToSimpleMergeBoothAction(originalBoothFactory)));

    const layersRequest = fetchAllPage(
      (after) => sdk.boothEditGetBoothDesignCanvasLayersList({ boothId, locales: [locale], after }),
      (resp) => (resp.node as Booth).boothDesignCanvas.layers.pageInfo,
    ).pipe(
      tap(defaultContentAreaTypePatcher),
      map(mapToSimpleMergeBoothAction(originalBoothFactory)),
    );

    const requests = merge(
      designRequest,
      canvasRequest,
      layersRequest,
    );

    return concat(
      boothEditMutationCompleteObservable(action$, state$),
      of(boothEditActions.internalUpsertBooth(produce(originalBoothFactory(), (booth) => {
        if (booth.boothDesignCanvas?.layers?.nodes != null) booth.boothDesignCanvas.layers.nodes = [];
      }))),
      requests,
    ).pipe(boothEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory));
  }),
);

export default queryDesignCanvasEpic;
