import { createSlice } from '@reduxjs/toolkit';
import { EVENT_EDIT_STORE_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventEditBoothsExtraReducer
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsFragment/redux/eventEditBoothsExtraReducer';
import { injectExecutingTasksExtraReducer } from 'utils/xtra-executing-tasks';
import eventSettingEditExtraReducer from 'admin-data/event/EventEdit/redux/eventSettingEditReducer';
import _ from 'lodash';
import eventEditBoothsV2ExtraReducer
  from 'admin-data/event/EventEdit/EventEditFragments/booth/EventEditBoothsV2Fragment/redux/eventEditBoothsV2ExtraReducer';
import { eventEditInitialState } from 'admin-data/event/EventEdit/redux/eventEditState';

const eventEditSlice = createSlice({
  name: EVENT_EDIT_STORE_NAME,
  initialState: eventEditInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(eventEditActions.updateEditLocale, ((state, action) => {
      state.locale = action.payload;
      localStorage.setItem('previousContentLocale', action.payload);
    }));

    builder.addCase(eventEditActions.internalResetInitialState, (() => eventEditInitialState));

    builder.addCase(eventEditActions.internalUpdateIsEventNotFound, ((state, action) => {
      state.isEventNotFound = action.payload;
    }));

    builder.addCase(eventEditActions.internalUpdate, (state, action) => action.payload);

    builder.addCase(eventEditActions.internalUpdateEvent, ((state, action) => {
      state.event = action.payload;
    }));

    builder.addCase(eventEditActions.internalUpdateEventRootMasterElement, ((state, action) => {
      state.event.rootMasterElement = action.payload;
    }));

    builder.addCase(eventEditActions.internalUpdateEventFields, (state, action) => {
      action.payload.fields.forEach((field) => {
        _.set(state.event, field.source, field.value);
      });
    });

    builder.addCase(eventEditActions.internalUpdateFields, (state, action) => {
      action.payload.fields.forEach((field) => {
        _.set(state, field.source, field.value);
      });
    });

    injectExecutingTasksExtraReducer(builder, eventEditActions.executingTasks, (state) => state.executingTasks);

    eventEditBoothsExtraReducer(builder);
    eventSettingEditExtraReducer(builder);
    eventEditBoothsV2ExtraReducer(builder);
  },
});

const eventEditReducer = eventEditSlice.reducer;
export default eventEditReducer;
