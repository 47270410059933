import axios from 'axios';
import { defer, from, of } from 'rxjs';
import { EVENT_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { createStandardEventEditRequestBundle } from 'admin-data/event/EventEdit/redux/createStandardEventEditRequestBundle';
import { ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { map, mergeMap } from 'rxjs/operators';
import { Auditorium, AuditoriumAsset, AuditoriumAssetsContainerPosition } from 'extra-models/auditorium';
import { FIRESTORE_ENDPOINT } from 'config';
import _ from 'lodash';

interface AuditoriumAssetCreatePayload {
  auditoriumContainerKey: AuditoriumAssetsContainerPosition,
  asset: Omit<AuditoriumAsset, 'id'>
}

interface Response {
  asset: AuditoriumAsset;
  updatedContainerAssets: AuditoriumAsset[];
  auditoriumId: string;
}

const bundleEventWebinarAuditoriumAssetCreate = createStandardEventEditRequestBundle<AuditoriumAssetCreatePayload, Response>({
  actionName: `${EVENT_EDIT_ACTIONS_NAME}/auditorium/asset/bundleEventAuditoriumAssetCreate`,
  additionalTaskTags: [ExecutingTaskCommonTag.Mutation],
  isSequenced: true,

  requestFactory: ({
    eventDomain, locale, authToken, payload, eventEditFactory,
  }) => {
    const {
      id: auditoriumId,
      backgroundImage,
      isDefault,
      assets,
    } = eventEditFactory().fragments.auditorium.auditoriumEdit.editingAuditorium;
    const getTargetContainerAssets = () => (eventEditFactory().fragments.auditorium.auditoriumEdit.editingAuditorium.assets[payload.auditoriumContainerKey] as any).assets;

    const containerAssets = (assets[payload.auditoriumContainerKey] as any).assets;
    const desktopOrder = (_.maxBy(containerAssets, (maxOrderAsset: any) => maxOrderAsset.desktopOrder)?.desktopOrder ?? -1) + 1;
    const mobileOrder = (_.maxBy(containerAssets, (maxOrderAsset: any) => maxOrderAsset.mobileOrder)?.mobileOrder ?? -1) + 1;
    return defer(() => axios.post(`${FIRESTORE_ENDPOINT}/events/${eventDomain}/auditoriums/asset`, {
      ...payload.asset,
      desktopOrder,
      mobileOrder,
    }, {})).pipe(
      map((resp) => resp.data.result as AuditoriumAsset),
      mergeMap(({ id: assetId }: { id: string }) => {
        const asset = {
          ...payload.asset,
          id: assetId,
          desktopOrder,
          mobileOrder,
        };
        return from(
          axios.patch(`${FIRESTORE_ENDPOINT}/events/${eventDomain}/auditoriums/${auditoriumId}`, {
            id: auditoriumId,
            backgroundImage,
            assets: ({
              [payload.auditoriumContainerKey]: {
                assetIds: [...getTargetContainerAssets().map((it) => it.id), asset.id],
              },
            }),
            isDefault,
          } as Auditorium, {}),
        ).pipe(
          map(() => ({ asset, updatedContainerAssets: [...getTargetContainerAssets(), asset], auditoriumId })),
        );
      }),
    );
  },

  requestResponseMapper: ({
    response, createUpdateEventEditAction, payload,
  }) => of(createUpdateEventEditAction((eventEditState) => {
    if (eventEditState.fragments.auditorium.auditoriumEdit.editingAuditorium.id === response.auditoriumId) {
      (eventEditState.fragments.auditorium.auditoriumEdit.editingAuditorium.assets[payload.auditoriumContainerKey] as any).assets = response.updatedContainerAssets;
    }
    eventEditState.fragments.auditorium.auditoriumAssetsList.push(response.asset);
  })),
});

export default bundleEventWebinarAuditoriumAssetCreate;
