import React, {
  FC, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cmsSelectors from 'redux/cmsSelectors';
import { IntlProvider, updateIntl } from 'react-intl-redux';

export const CMSUILocalizationContext = React.createContext({
  locale: 'en',
  setLocale: null,
});
const CMSUILocalizationContextProvider: FC = ({ children }) => {
  const availableLocales = useSelector(cmsSelectors.selectAvailableUILocales);
  const getInitialLocale = () => {
    const lastLocale = localStorage.getItem('systemLocale');
    return lastLocale == null || !availableLocales.includes(lastLocale)
      ? (navigator.languages.find((it) => availableLocales.indexOf(it) !== -1) ?? 'en')
      : lastLocale;
  };
  const [locale, setLocale] = useState(getInitialLocale);

  const setPersistentLocale = (newLocale) => {
    localStorage.setItem('systemLocale', newLocale);
    setLocale(newLocale);
  };

  const translationSelector = useMemo(() => cmsSelectors.selectUITranslation(locale), [locale]);
  const translations = useSelector(translationSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateIntl({
      locale,
      messages: translations,
    }));
  }, [dispatch, locale, translations]);

  return (
    <CMSUILocalizationContext.Provider value={{ locale, setLocale: setPersistentLocale }}>
      <IntlProvider locale={locale} messages={translations}>
        {children}
      </IntlProvider>
    </CMSUILocalizationContext.Provider>
  );
};

export default CMSUILocalizationContextProvider;
