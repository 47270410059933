import React, { useContext } from 'react';
import { DesignEditorDockerComponent } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/designEditorDockerEntry';
import Scrollbars from 'react-custom-scrollbars';
import DesignEditorScrollbarThumb from 'libs/xtra-custom-booth-design/components/shared/DesignEditorScrollbarThumb';
import DesignEditorTransformDimensions
  from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorTransformDocker/DesignEditorTransformDimensions';
import DesignEditorSelectedLayersInfo
  from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorTransformDocker/DesignEditorSelectedLayersInfo';
import DesignEditorAspectRatioSettings
  from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorTransformDocker/DesignEditorAspectRatioSettings';
import { useSelector } from 'react-redux';
import DesignEditorInstanceContext
  from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';
import { selectorArrayItemEqFn } from 'utils/selectorUtils';

const DesignEditorTransformDocker: DesignEditorDockerComponent = (props) => {
  const { actions, selectors, designId } = useContext(DesignEditorInstanceContext);
  const selected = useSelector(selectors.selectSelectedLayersId, selectorArrayItemEqFn).length >= 1;

  return (
    <Scrollbars style={{ height: 300, overflowX: 'hidden' }} renderThumbVertical={DesignEditorScrollbarThumb}>
      <DesignEditorSelectedLayersInfo />
      {selected && <DesignEditorTransformDimensions />}
      {selected && <DesignEditorAspectRatioSettings />}
    </Scrollbars>
  );
};

export default React.memo(DesignEditorTransformDocker);
