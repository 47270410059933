import { createSelector, Selector } from 'reselect';
import { ExecutingTasksState } from 'utils/xtra-executing-tasks/executingTasksState';

// eslint-disable-next-line import/prefer-default-export
export const createExecutingTasksSelectors = (selector: Selector<any, ExecutingTasksState>) => {
  const selectExecutingTasksIdMap = createSelector(selector, (state) => state.tasksIdMap);
  const selectExecutingTasksTagMap = createSelector(selector, (state) => state.tasksTagMap);
  const selectFinishedTasksIdMap = createSelector(selector, (state) => state.finishedTasksIdMap);
  const selectFinishedTasksTagMap = createSelector(selector, (state) => state.finishedTasksTagMap);

  return {
    selectExecutingTasks: createSelector(selectExecutingTasksIdMap, (tasksIdMap) => Object.values(tasksIdMap)),
    selectExecutingTasksByTag: (tag) => createSelector(selectExecutingTasksTagMap, (tasksTagMap) => Object.values(tasksTagMap[tag] ?? {})),
    selectHasExecutingTasksByTag: (tag) => createSelector(selectExecutingTasksTagMap, (tasksTagMap) => Object.values(tasksTagMap[tag] ?? {}).length > 0),

    selectFinishedTasks: createSelector(selectFinishedTasksIdMap, (finishedTasksIdMap) => Object.values(finishedTasksIdMap)),
    selectFinishedTasksByTag: (tag) => createSelector(selectFinishedTasksTagMap, (finishedTasksTagMap) => Object.values(finishedTasksTagMap[tag] ?? {})),
    selectHasFinishedTasksByTag: (tag) => createSelector(selectFinishedTasksTagMap, (finishedTasksTagMap) => Object.values(finishedTasksTagMap[tag] ?? {}).length > 0),
  };
};

export type ExecutingTasksSelectors = ReturnType<typeof createExecutingTasksSelectors>
