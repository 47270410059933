import { Epic } from 'redux-observable';
import {
  filter, map, mergeMap, takeUntil,
} from 'rxjs/operators';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import { concat, defer, of } from 'rxjs';
import { Appbar, FeaturesToggle } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { EVENT_FIRESTORE_API } from 'config';

interface MaintenanceLogic {
  key: string;
  deps: boolean[];
}

const generateMaintenanceLogic = (featuresToggle: FeaturesToggle, chatroomConfig: any, customPage: any) => {
  const customPageLogic = (customPage || []).map((pageGroup) => ({
    key: pageGroup.name, deps: [featuresToggle.multipleCustomPages],
  }));
  return ([
    { key: 'lobby', deps: [] },
    { key: 'speakers', deps: [featuresToggle.speakersPage] },
    { key: 'products', deps: [featuresToggle.productPage] },
    { key: 'participants', deps: [!featuresToggle.hideParticipantPage] },
    { key: 'hall', deps: [!featuresToggle.hideHall] },
    { key: 'webinar', deps: [!featuresToggle.hideWebinar] },
    { key: 'public-chat', deps: [featuresToggle.publicChatroom, !chatroomConfig.isChatroomDisabled, chatroomConfig.publicChatrooms?.length] },
    { key: 'networking-lounge', deps: [featuresToggle.networkingLounge] },
    ...customPageLogic,
  ] as MaintenanceLogic[]);
};

const fetchEventSettingAppbarEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.fetchAppbar.match),
  mergeMap(() => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const getEventUrl = `${EVENT_FIRESTORE_API}?event_domain=${domain}`;
    const fetchAppbar = defer(() => fetch(getEventUrl));
    return concat(
      of(eventSettingEditActions.internalUpdateLoadingAppbar(true)),
      fetchAppbar.pipe(
        mergeMap((resp) => resp.json() as Promise<{ appBarConfig: Appbar, featuresToggle: FeaturesToggle, customPage: any, chatroomConfig: any }>),
        map(({
          appBarConfig,
          featuresToggle,
          customPage,
          chatroomConfig,
        }) => {
          const maintenanceLogic = generateMaintenanceLogic(featuresToggle, chatroomConfig, customPage);
          maintenanceLogic.forEach(({ key, deps }) => {
            const allDepsTrue = deps.every((dep) => dep);
            if (!allDepsTrue) {
              delete appBarConfig.itemOrder[key];
            } else if (key in appBarConfig.itemOrder === false) {
              const currentNumItems = Object.keys(appBarConfig.itemOrder).length;
              appBarConfig.itemOrder[key] = currentNumItems + 1;
            }
          });
          return eventSettingEditActions.internalUpdateAppbar(appBarConfig);
        }),
      ),
      of(eventSettingEditActions.internalUpdateLoadingAppbar(false)),
    ).pipe(
      takeUntil(action$.pipe(filter(eventSettingEditActions.fetchAppbar.match))), // fetch until another fetch started
    );
  }),
);

export default fetchEventSettingAppbarEpic;
