import {
  createStandardRequestBundle,
  StandardRequestBundleOptions,
  StandardRequestMapperResource,
  useRequestDispatchHook,
} from 'utils/xtra-request-bundle/createStandardRequestBundle';
import { CmsSDKObservables, getAuthToken } from 'utils/auth';
import { WritableDraft } from 'immer/dist/types/types-external';
import { EventEditState } from 'admin-data/event/EventEdit/redux/eventEditState';
import { Action } from 'redux';
import cmsSelectors from 'redux/cmsSelectors';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import produce from 'immer';

export type StandardEventEditRequestBundleOptions<P, R, T, M = StandardEventEditRequestResponseMapperResource> = Omit<StandardRequestBundleOptions<P, R, T, M>, 'executingTasksSelectorsProvider' | 'executingTasksActionsProvider' | 'sequencedRequestActionProvider'>;

export interface StandardEventEditRequestResponseMapperResource extends StandardRequestMapperResource {
  sdk: CmsSDKObservables;
  locale: string;
  eventId: string;
  eventDomain: string;
  eventEditFactory: () => EventEditState;
  createUpdateEventEditAction: (content: (draft: WritableDraft<EventEditState>) => void) => Action;
  authToken: string;
}

export const createStandardEventEditRequestBundle = <P = void, R = any, T extends string = string, >(options: StandardEventEditRequestBundleOptions<P, R, T>) => {
  const createExtraResource = <S extends StandardRequestMapperResource>(resources: S) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(resources.state$.value);
    const locale = eventEditSelectors.selectEditLocale(resources.state$.value);
    const eventId = eventEditSelectors.selectEventId(resources.state$.value);
    const eventDomain = eventEditSelectors.selectEvent(resources.state$.value).domain;
    const eventEditFactory = () => eventEditSelectors.selectEventEdit(resources.state$.value);
    const createUpdateEventEditAction = (content: (draft: WritableDraft<EventEditState>) => void) => eventEditActions.internalUpdate(produce(eventEditFactory(), content) as EventEditState);
    const authToken = getAuthToken(cmsSelectors.selectSDKAccount(resources.state$.value));

    return ({
      ...resources, eventId, locale, sdk, createUpdateEventEditAction, eventDomain, authToken, eventEditFactory,
    });
  };

  const bundle = createStandardRequestBundle<P, R, T>({
    ...options,
    executingTasksSelectorsProvider: () => eventEditSelectors.executingTasks,
    executingTasksActionsProvider: () => eventEditActions.executingTasks,
    requestFactory: ((resources) => options.requestFactory(createExtraResource(resources))),
    requestResponseMapper: options.requestResponseMapper == null ? null : ((resources) => options.requestResponseMapper(createExtraResource(resources))),
    preRequestActionsObservableFactory: options.preRequestActionsObservableFactory == null ? null : ((resources) => options.preRequestActionsObservableFactory(createExtraResource(resources))),
    sequencedRequestActionProvider: () => eventEditActions.internalAddSequencedRequest,
  });
  return bundle;
};
