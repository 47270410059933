import { Epic } from 'redux-observable';
import {
  filter, first, map, mergeMap, takeUntil,
} from 'rxjs/operators';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import cmsSelectors from 'redux/cmsSelectors';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import { createExecutingTask } from 'utils/xtra-executing-tasks/executingTask';
import { boothEditExecutingTasksTagCreator } from 'admin-data/booth/BoothEdit/redux/boothEditExecutingTasks';
import { boothEditMutationCompleteObservable } from 'admin-data/booth/BoothEdit/redux/epics/queries/utils';
import { fetchAllPage } from 'utils/requestsUtils';
import { Booth } from 'models';
import { concat, of } from 'rxjs';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import produce from 'immer';

const queryRepresentativesListEpic: Epic = (action$, state$) => action$.pipe(
  filter(boothEditActions.queries.queryRepresentativesList.match),
  mergeMap(() => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const boothId = boothEditSelectors.selectBoothId(state$.value);
    const locale = boothEditSelectors.selectLocale(state$.value);
    const originalBoothFactory = () => boothEditSelectors.selectBooth(state$.value);

    const taskFactory = () => createExecutingTask({ tags: [boothEditExecutingTasksTagCreator.queries.queryRepresentativesList()] });

    const getRequest = (deleted) => fetchAllPage(
      (after) => sdk.boothEditGetRepresentativesList({ boothId, after, deleted }),
      (resp) => (resp.node as Booth).representatives.pageInfo,
    ).pipe(
      throwCommonError(),
      map((resp) => boothEditActions.internalUpsertBooth(produce(originalBoothFactory(), (draft) => {
        if (draft.representatives == null) draft.representatives = {} as any;
        if (draft.representatives.nodes == null) draft.representatives.nodes = [];
        draft.representatives.nodes.push(...(resp.node as Booth).representatives.nodes);
      }))),
      catchErrorToPopup(state$.value.intl),
      takeUntil(action$.pipe(filter(boothEditActions.queries.queryRepresentativesList.match), first())),
      boothEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return concat(
      boothEditMutationCompleteObservable(action$, state$),
      of(boothEditActions.internalUpsertBooth(produce(originalBoothFactory(), (draft) => {
        if (draft.representatives?.nodes != null) {
          draft.representatives.nodes = [];
        }
      }))),
      getRequest(false),
    );
  }),
);

export default queryRepresentativesListEpic;
