import {
  BoothDesignColorFillElement,
  BoothDesignColorFillElementUpdateInput,
  BoothDesignContentAreaElement,
  BoothDesignContentAreaElementUpdateInput,
  BoothDesignImageButtonElement,
  BoothDesignImageButtonElementUpdateInput,
  BoothV2DesignElement,
} from 'models';
import { createUpdateParserBuilder } from 'utils/redux-field-bind/updateParser/updateParserBuilder';

const boothDesignContentAreaElement = createUpdateParserBuilder<BoothDesignContentAreaElement, { boothId: string, designElement: BoothDesignContentAreaElement }>((builder0) => {
  builder0.fallback(((state$, attributes, source, value) => ({
    requestFnProvider: (sdk) => sdk.boothDesignContentAreaElementUpdate,
    batchIdentifier: `contentAreaElementUpdate-${attributes.designElement.id}`,
    fields: { [source]: value },
    inputModifier: (input: BoothDesignContentAreaElementUpdateInput) => ({
      ...input,
      // always send content type if not changing type to ensure contentType: null will be mutate
      ...(source === 'contentType' ? {} : { contentType: attributes.designElement.contentType }),
      boothId: attributes.boothId,
      elementId: attributes.designElement.id,
    } as BoothDesignContentAreaElementUpdateInput),
  })));
});

const boothDesignColorFillElement = createUpdateParserBuilder<BoothDesignColorFillElement, { boothId: string, designElement: BoothDesignColorFillElement }>((builder0) => {
  builder0.fallback(((state$, attributes, source, value) => ({
    requestFnProvider: (sdk) => sdk.boothDesignColorFillElementUpdate,
    batchIdentifier: `colorFillElementUpdate-${attributes.designElement.id}`,
    fields: { [source]: value },
    inputModifier: (input: BoothDesignColorFillElementUpdateInput) => ({
      ...input,
      boothId: attributes.boothId,
      elementId: attributes.designElement.id,
    } as BoothDesignColorFillElementUpdateInput),
  })));
});

const boothDesignImageButtonElement = createUpdateParserBuilder<BoothDesignImageButtonElement, { boothId: string, designElement: BoothDesignImageButtonElement }>((builder0) => {
  builder0.fallback(((state$, attributes, source, value) => ({
    requestFnProvider: (sdk) => sdk.boothDesignImageButtonElementUpdate,
    batchIdentifier: `imageButtonElementUpdate-${attributes.designElement.id}`,
    fields: { [source]: value },
    inputModifier: (input: BoothDesignImageButtonElementUpdateInput) => ({
      ...input,
      boothId: attributes.boothId,
      elementId: attributes.designElement.id,
    } as BoothDesignImageButtonElementUpdateInput),
  })));
});

const boothDesignLayersUpdateParser = createUpdateParserBuilder<BoothV2DesignElement, { boothId: string, designElement: BoothV2DesignElement }>((builder0) => {
  const designElementUpdateParsers = {
    BoothDesignImageButtonElement: boothDesignImageButtonElement,
    BoothDesignColorFillElement: boothDesignColorFillElement,
    BoothDesignContentAreaElement: boothDesignContentAreaElement,
  };

  builder0.fallback(((state$, attributes, source, value) => {
    const parser = designElementUpdateParsers[attributes.designElement.__typename];

    if (parser == null) throw new Error(`No design v2 element update parser for ${attributes.designElement.__typename}`);

    return parser(state$, attributes, source, value);
  }));
});

export default boothDesignLayersUpdateParser;
