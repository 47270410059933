import { createAction } from '@reduxjs/toolkit';
import { Component } from 'react';
import { HintType } from 'utils/xtra-hints/hintType';

export type ExtractHintsTypeData<T> = T extends HintType<any, infer K> ? K : never

export interface ShowHintsPayload<T extends Record<string, HintType>, K extends keyof T = any> {
  key: K;
  data: ExtractHintsTypeData<T[K]>;
  page?: number;
}

export const createHintsPayload = <T extends any>(hintsType: HintType<string, T>, data: T) => ({
  key: hintsType.key,
  data,
});

export const createHintsAction = <T extends Record<string, HintType>>(actionPrefix) => ({
  upsertCurrentHint: createAction<ShowHintsPayload<T>>(`${actionPrefix}/upsertCurrentHint`),
  updateHideAllHints: createAction<boolean>(`${actionPrefix}/updateHideAllHints`),
});

export type HintsAction = ReturnType<typeof createHintsAction>;
