import {
  concatMap, groupBy, map, mergeMap,
} from 'rxjs/operators';
import { Observable, timer } from 'rxjs';
import { rawDesignEditorInstanceActions } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designEditorInstanceActions';
import { DesignIdWrappedPayload } from 'libs/xtra-custom-booth-design/redux/design-editor-instance/designIdWrappedPayload';
import { Epic } from 'redux-observable';
import { Action } from 'redux';

const internalAddSequencedRequestEpic: Epic = (action$, state$) => action$
  .ofType(rawDesignEditorInstanceActions.internalAddSequencedRequest).pipe(
    map((action) => action.payload as DesignIdWrappedPayload<Observable<Action>>),
    groupBy((payload) => payload.instanceDesignId, (it) => it, () => timer(10000)),
    mergeMap((instanceGroups) => instanceGroups.pipe(
      concatMap((instanceGroup) => instanceGroup.data),
    )),
  );

export default internalAddSequencedRequestEpic;
