import { createUpdateParserBuilder } from 'utils/redux-field-bind/updateParser/updateParserBuilder';
import { EventEditState } from 'admin-data/event/EventEdit/redux/eventEditState';
import eventUpdateParser from 'admin-data/event/EventEdit/redux/updateParsers/eventUpdateParser';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { concat, from } from 'rxjs';
import { EVENT_FIRESTORE_API, FIRESTORE_ENDPOINT } from 'config';
import { Auditorium, AuditoriumAssetsContainerPosition } from 'extra-models/auditorium';
import webinarEditingAuditoriumAssetContainerUpdateParser
  from 'admin-data/event/EventEdit/redux/updateParsers/webinar/auditorium/webinarEditingAuditoriumAssetContainerUpdateParser';
import axios from 'axios';

const eventEditStateUpdateParser = createUpdateParserBuilder<EventEditState, { eventId: string }>((builder0) => {
  builder0.fallback(((state$, attributes, source, value) => {
    throw new Error(`Not supported field ${source}`);
  }));

  builder0.handler((state) => state.event, eventUpdateParser);

  builder0.ignore((state) => state.fragments.boothLivestream.addSessionDialog);

  builder0.ignore((state) => state.fragments.auditorium.auditoriumEdit.selectedContainerKey);

  builder0.handler((state) => state.fragments.auditorium.auditoriumEdit.editingAuditorium.backgroundImage,
    (state$, attributes, source, value) => {
      const { event: { domain }, fragments } = eventEditSelectors.selectEventEdit(state$.value);
      const { editingAuditorium } = fragments.auditorium.auditoriumEdit;

      return {
        requestFnProvider: (_) => ({ input }) => from(
          axios.patch(`${FIRESTORE_ENDPOINT}/events/${domain}/auditoriums/${editingAuditorium.id}`,
            input as Partial<Auditorium>, {}),
        ),
        fields: {
          backgroundImage: {
            [source]: value,
          },
        },
        batchIdentifier: 'eventSetting-webinarAuditoriumBackground',
        inputModifier: (input) => input,
      };
    });

  builder0.handler((state) => state.fragments.auditorium.auditoriumEdit.editingAuditorium.assets,
    (state$, attributes, source, value) => {
      const containerKey = source.split('.')[0] as AuditoriumAssetsContainerPosition;
      return webinarEditingAuditoriumAssetContainerUpdateParser(state$, {
        ...attributes,
        containerKey,
      }, source.slice(containerKey.length + 1), value);
    });

  builder0.handler((state) => state.fragments.eventSettings, (state$, attributes, source, value) => ({
    requestFnProvider: (_) => ({ input }) => {
      const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);

      return from(fetch(`${EVENT_FIRESTORE_API}?event_domain=${domain}`, {
        body: JSON.stringify(input),
        headers: {
          'content-type': 'application/json',
        },
        method: 'PUT',
      }));
    },
    batchIdentifier: 'eventSettingRequest',
    fields: {
      [source]: value,
    },
    inputModifier: (input) => input,
  }));
});

export default eventEditStateUpdateParser;
