import axios from 'axios';
import { defer, of } from 'rxjs';
import { EVENT_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { eventEditMutationCompleteObservable } from 'admin-data/event/EventEdit/redux/epic/utils';
import { createStandardEventEditRequestBundle } from 'admin-data/event/EventEdit/redux/createStandardEventEditRequestBundle';
import { map } from 'rxjs/operators';
import { AuditoriumAsset } from 'extra-models/auditorium';
import { FIRESTORE_ENDPOINT } from 'config';
import _ from 'lodash';

const bundleEventWebinarAuditoriumAssetGetList = createStandardEventEditRequestBundle<void, AuditoriumAsset[]>({
  actionName: `${EVENT_EDIT_ACTIONS_NAME}/auditorium/asset/bundleEventAuditoriumAssetGetList`,
  preConditionObservableFactory: eventEditMutationCompleteObservable,

  requestFactory: ({
    eventDomain, locale, authToken,
  }) => defer(() => axios.get(`${FIRESTORE_ENDPOINT}/events/${eventDomain}/auditoriums/asset`, {}))
    .pipe(map((resp) => resp.data.result)),

  requestResponseMapper: ({
    response, createUpdateEventEditAction,
  }) => of(createUpdateEventEditAction((eventEditState) => {
    eventEditState.fragments.auditorium.auditoriumAssetsList = response;
  })),
});

export default bundleEventWebinarAuditoriumAssetGetList;
