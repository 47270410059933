import { combineEpics } from 'redux-observable';
import fetchTranslationsEpic from 'redux/epics/fetchTranslationsEpic';
import bulkTasksCancelEpicFactory from 'utils/xtra-popups/epics/bulkTasksCancelEpic';
import cmsActions from 'redux/cmsActions';
import cmsSelectors from 'redux/cmsSelectors';

const cmsEpics = combineEpics(
  fetchTranslationsEpic,
  bulkTasksCancelEpicFactory(cmsActions.popups, cmsSelectors.popups),
);

export default cmsEpics;
