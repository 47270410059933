import { Epic } from 'redux-observable';
import {
  filter, ignoreElements, map, mergeMap,
} from 'rxjs/operators';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import cmsSelectors from 'redux/cmsSelectors';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditExecutingTasksTagCreator } from 'admin-data/event/EventEdit/redux/eventEditExecutingTasks';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import produce from 'immer';
import { concat, Observable, of } from 'rxjs';
import { BoothMasterElementType } from 'admin-data/event/EventEdit/typenameEnums';

const reorderTabBodyMasterElementEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventEditActions.mutations.reorderTabBodyMasterElement.match),
  mergeMap(({ payload: { id, tabId, orderingPosition } }) => {
    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const eventId = eventEditSelectors.selectEventId(state$.value);
    const originalEventFactory = () => eventEditSelectors.selectEvent(state$.value);

    const requestFactories = {
      [BoothMasterElementType.BoothRichtextMasterElement]: sdk.boothRichtextMasterElementUpdate,
      [BoothMasterElementType.BoothVideoMasterElement]: sdk.boothVideoMasterElementUpdate,
      [BoothMasterElementType.BoothQuestionMasterElement]: sdk.boothQuestionMasterElementUpdate,
      [BoothMasterElementType.BoothImageMasterElement]: sdk.boothImageMasterElementUpdate,
      [BoothMasterElementType.BoothFileMasterElement]: sdk.boothFileMasterElementUpdate,
      [BoothMasterElementType.BoothDividerMasterElement]: sdk.boothDividerMasterElementUpdate,
    };

    const taskFactory = () => createExecutingTask({
      tags: [
        eventEditExecutingTasksTagCreator.mutations.reorderTabBodyMasterElement(),
        ExecutingTaskCommonTag.Mutation],
    });

    const typename = originalEventFactory().rootMasterElement.tabs.nodes
      .find((it) => it.id === tabId).body.nodes
      .find((it) => it.id === id).__typename;

    const internalReorderAction = eventEditActions.internalUpdateEvent(produce(originalEventFactory(), ((draft) => {
      const tab = draft.rootMasterElement.tabs.nodes.find((it) => it.id === tabId);
      const originalIndex = tab.body.nodes.findIndex((element) => element.id === id);
      tab.body.nodes.splice(orderingPosition, 0, tab.body.nodes.splice(originalIndex, 1)[0]);
    })));

    const requests = requestFactories[typename]({
      input: { eventId, elementId: id, orderingPosition },
    }).pipe(
      throwCommonError(),
      ignoreElements(),
      catchErrorToPopup(state$.value.intl),
      eventEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );

    return concat(
      of(internalReorderAction),
      of(eventEditActions.internalAddSequencedRequest(requests)),
    );
  }),
);

export default reorderTabBodyMasterElementEpic;
