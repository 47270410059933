import { Epic } from 'redux-observable';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import createInternalAddBatchedDebouncedUpdateRequestEpic
  from 'utils/redux-update-model/createInternalAddBatchedDebouncedUpdateRequestEpic';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';

const internalAddBatchedDebouncedUpdateBoothRequestEpic: Epic = createInternalAddBatchedDebouncedUpdateRequestEpic(
  boothEditActions.internalAddBatchedDebouncedUpdateBoothRequest,
  boothEditActions.executingTasks.wrapWithExecutingTaskAction(() => createExecutingTask({
    name: 'Update booth',
    tags: [ExecutingTaskCommonTag.Mutation],
  })),
);

export default internalAddBatchedDebouncedUpdateBoothRequestEpic;
