import React, { FC, useEffect, useState } from 'react';
import {
  Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import useBoothEditReduxBindAdapter from 'admin-data/booth/BoothEdit/redux/useBoothEditReduxBindAdapter';
import { useDispatch, useSelector } from 'react-redux';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import cmsSelectors from 'redux/cmsSelectors';

interface TemplateSelectorProps {
  open: boolean
  onClose: () => any,
  onChanged: () => any
}

export const getAvailableDesigns = async (eventId: string) => {
  const response = await fetch(`https://cms-virtual-static.eventxtra.com/events/${eventId}`);
  if (response.status === 200) {
    const { templates: eventTemplates } = await response.json();
    return eventTemplates;
  }
  return [];
};

const BoothLegacyTemplateSelector: FC<TemplateSelectorProps> = ({ open, onClose, onChanged }) => {
  const boothField = useBoothEditReduxBindAdapter();
  const { useValue: useDeisgnCodename, useSetter: useDesignCodenameSetter } = boothField.useFieldBuilder((booth) => booth.appliedDesignCodename).useAsField();
  const designCodename = useDeisgnCodename();
  const setDesignCodename = useDesignCodenameSetter();

  const setDesignElements = boothField.useFieldBuilder((booth) => booth.designElements.nodes).useAsField().useSetter();

  const [newDesignCodename, setNewDesignCodename] = useState(designCodename);
  const [availableDesigns, setAvailableDesigns] = useState(null);

  const eventId = useSelector(boothEditSelectors.selectBoothEvent).id;

  useEffect(() => {
    const storeAvailableDesigns = async () => {
      const eventDesigns = await getAvailableDesigns(eventId);
      setAvailableDesigns(eventDesigns);
    };
    storeAvailableDesigns();
  }, [eventId]);

  const id = boothField.useField((booth) => booth.id).useValue();

  const locale = useSelector(boothEditSelectors.selectLocale);

  const sdk = useSelector(cmsSelectors.selectAuthorizedSDKObservables);

  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    if (!open) {
      setNewDesignCodename(designCodename);
    }
  }, [designCodename, open]);

  const dispatch = useDispatch();

  const onUpdate = () => {
    setUpdating(true);
    sdk.boothUpdate({ input: { id, designCodename: newDesignCodename } })
      .subscribe((result) => {
        dispatch(boothEditActions.queries.queryLegacyDesignElements());
        setDesignCodename(newDesignCodename, { skipExternalUpdate: true });
        setUpdating(false);
        onChanged();
        onClose();
      });
  };

  const onCancel = () => {
    if (!updating) onClose();
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>
        <FormattedMessage
          id="booth.design.template"
          description="Select Template"
          defaultMessage="Select Template"
        />
      </DialogTitle>
      <DialogContent>
        {
          (updating || !availableDesigns) ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <CircularProgress />
              {updating && (
                <div>
                  <FormattedMessage
                    id="booth.design.updating_template"
                    description="Updating template..."
                    defaultMessage="Updating template..."
                  />
                </div>
              )}
            </Box>
          ) : (
            <FormControl fullWidth>
              <InputLabel>
                <FormattedMessage
                  id="booth.design.template"
                  description="Design Template dialog title"
                  defaultMessage="Select Template"
                />
              </InputLabel>
              <Select
                value={newDesignCodename}
                onChange={(e) => setNewDesignCodename(e.target.value as string)}
              >
                {availableDesigns.map((code) => <MenuItem value={code} key={code}>{code}</MenuItem>)}
              </Select>
            </FormControl>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" disabled={updating}>
          <FormattedMessage
            id="common.cancel"
            description="Cancel"
            defaultMessage="Cancel"
          />
        </Button>
        <Button onClick={onUpdate} color="primary" variant="contained" disabled={updating}>
          <FormattedMessage
            id="common.apply"
            description="Apply changes"
            defaultMessage="Apply"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BoothLegacyTemplateSelector;
