import { defer, Observable } from 'rxjs';
import { tryOrNull } from 'libs/xtra-custom-booth-design/utils/fp';
import { map, mergeMap } from 'rxjs/operators';

// eslint-disable-next-line import/prefer-default-export
export const fetchImageAspectRatio = (url) => new Observable<number>((subscriber) => {
  const img = new Image();
  img.src = url;
  img.onload = () => {
    subscriber.next(tryOrNull(() => img.width / img.height) ?? 0);
    subscriber.complete();
  };
  img.onerror = (err) => subscriber.error(err);
});

// https://stackoverflow.com/questions/6850276/how-to-convert-dataurl-to-file-object-in-javascript
export const srcToFile = (src, fileName, mimeType) => defer(() => fetch(src)).pipe(
  mergeMap((res) => res.arrayBuffer()),
  map((buf) => new File([buf], fileName, { type: mimeType })),
);
