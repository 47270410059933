import { Epic } from 'redux-observable';
import {
  catchError, filter, map, mergeMap,
} from 'rxjs/operators';
import cmsActions from 'redux/cmsActions';
import { mapKeys, mapValues } from 'lodash';
import { of } from 'rxjs';
import localTranslation from './localTranslation.json';

const TRANSLATION_MD5_KEY = 'previousTranslationMD5';
const TRANSLATION_CACHE_KEY = 'previousTranslationCache';

const fetchTranslationsEpic: Epic = (action$, state$) => action$.pipe(
  filter(cmsActions.fetchTranslations.match),
  mergeMap(() => {
    const previousMD5 = localStorage.getItem(TRANSLATION_MD5_KEY);
    return previousMD5 == null
      ? fetch('https://api.oneskyapp.com/2/string/output?api-key=HMxdVdTCrWk25d4g4lfQDMUsQVQPSMOq&platform-id=367717')
      : fetch(`https://api.oneskyapp.com/2/string/output?api-key=HMxdVdTCrWk25d4g4lfQDMUsQVQPSMOq&platform-id=367717&md5=${previousMD5}`);
  }),
  mergeMap((resp) => resp.json()),
  map((resp) => {
    if (!resp.translation || Object.keys(resp.translation).length === 0) {
      localStorage.setItem(TRANSLATION_CACHE_KEY, JSON.stringify(localTranslation));
    } else if (resp.response !== 'up-to-date') {
      const translations = mapValues(
        resp.translation['en.json'],
        (messages) => mapKeys(messages as any, (value, key) => key.slice(0, -1).slice(1)),
      );
      localStorage.setItem(TRANSLATION_CACHE_KEY, JSON.stringify(translations));
      localStorage.setItem(TRANSLATION_MD5_KEY, resp.md5);
    }
    return cmsActions.internalUpdateTranslations(JSON.parse(localStorage.getItem(TRANSLATION_CACHE_KEY)));
  }),
  catchError((err) => {
    console.error('Failed to fetch translations', err);
    return of(cmsActions.internalUpdateTranslations({}));
  }),
);

export default fetchTranslationsEpic;
