import { Action } from 'redux';

export interface ConfirmationDialogData {
  title: string,
  description: string,
  confirmAction?: Action,
  cancelAction?: Action,
}

export const CONFIRMATION_DIALOG_POPUP_KEY = 'ConfirmationDialog';
