import { Epic } from 'redux-observable';
import { filter, map, mergeMap } from 'rxjs/operators';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import cmsSelectors from 'redux/cmsSelectors';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import produce from 'immer';
import { Attendee } from 'models';
import { createExecutingTask, ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';

const confirmAddContentTabBodyElementEpic: Epic = (action$, state$) => action$.pipe(
  filter(boothEditActions.mutations.confirmAddBoothRepresentative.match),
  mergeMap(() => {
    const { representative } = boothEditSelectors.selectBoothEditState(state$.value).fragments.representatives.addDialog;

    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);
    const boothId = boothEditSelectors.selectBoothId(state$.value);
    const originalBoothEditFactory = () => boothEditSelectors.selectBoothEditState(state$.value);

    const taskFactory = () => createExecutingTask({
      tags: [ExecutingTaskCommonTag.Mutation],
    });

    const {
      firstName, lastName, company, title, email,
    } = representative;

    return sdk.boothEditRepresentativeAdd({
      input: {
        boothId, firstName, lastName, company, title, email,
      },
    }).pipe(
      throwCommonError(),
      map((resp) => boothEditActions.internalUpdate(produce(originalBoothEditFactory(), (boothEdit) => {
        boothEdit.booth.representatives.nodes.unshift(resp.boothRepresentativeCreate.representative as Attendee);
      }))),
      catchErrorToPopup(state$.value.intl),
      boothEditActions.executingTasks.wrapWithExecutingTaskAction(taskFactory),
    );
  }),
);

export default confirmAddContentTabBodyElementEpic;
