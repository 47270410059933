import React, { useCallback, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DesignEditorDockerComponent } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/designEditorDockerEntry';
import DesignEditorInstanceContext from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';
import {
  Checkbox, FormControlLabel, Input, InputLabel,
} from '@material-ui/core';
import { useLayerFieldBind } from 'libs/xtra-custom-booth-design/utils/useLayerFieldBind';
import { TreeView } from '@material-ui/lab';
import { ChevronRight, ExpandMore } from '@material-ui/icons';
import { StyledFormControl, StyledTreeItem } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayerAttributesDocker/styles';
import { BoothDesignContentType, Image } from 'models';
import { FormattedMessage, useIntl } from 'react-intl';

const acceptableContentTypes = [
  {
    type: BoothDesignContentType.Image,
    title: 'Image',
  },
  {
    type: BoothDesignContentType.Pdf,
    title: 'PDF',
  },
  {
    type: BoothDesignContentType.Richtext,
    title: 'Richtext',
  },
  {
    type: BoothDesignContentType.Video,
    title: 'Video',
  },
];

/** @see BoothDesignContentAreaMasterElement */
const DesignEditorContentAreaMasterElementAttributesSetting: DesignEditorDockerComponent = (props) => {
  const { actions, selectors, designId } = useContext(DesignEditorInstanceContext);
  const selectedLayersId = useSelector(selectors.selectSelectedLayersId);
  if (selectedLayersId.length !== 1) throw new Error('Selected layer must be 1');
  const layerId = useMemo(() => selectedLayersId[0], [selectedLayersId]);

  /** @see string */
  const keyName = useLayerFieldBind<string>(layerId, 'keyName');

  /** @see Array<BoothDesignContentType> */
  const acceptedContentTypes = useLayerFieldBind<Array<BoothDesignContentType>>(layerId, 'acceptedContentTypes');

  const intl = useIntl();

  const toggleAcceptedContentType = useCallback((type) => {
    const newAcceptedContentTypes = [...acceptedContentTypes.value];
    const togglingItemIndex = newAcceptedContentTypes.indexOf(type);
    if (togglingItemIndex !== -1) newAcceptedContentTypes.splice(togglingItemIndex, 1);
    else newAcceptedContentTypes.push(type);
    acceptedContentTypes.change(newAcceptedContentTypes);
  }, [acceptedContentTypes]);

  const [expanded, setExpanded] = React.useState<string[]>(['acceptedContentTypes']);
  const handleToggle = (event: React.ChangeEvent, nodeIds: string[]) => {
    setExpanded(nodeIds);
  };

  return (
    <>
      <StyledFormControl fullWidth>
        <InputLabel>
          <FormattedMessage id="event.design.common.layer_name" defaultMessage="Layer Name" />
        </InputLabel>
        <Input color="primary" value={keyName.value} onChange={keyName.onChange} />
      </StyledFormControl>

      <TreeView
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
        expanded={expanded}
        onNodeToggle={handleToggle}
      >

        <StyledTreeItem
          nodeId="acceptedContentTypes"
          label={intl.formatMessage({ id: 'event.design.tool.content_area.accepted_content_types', defaultMessage: 'Accepted Content Types' })}
        >
          {
            acceptableContentTypes.map(({ type, title }) => (
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={acceptedContentTypes.value.indexOf(type) !== -1}
                    onChange={() => toggleAcceptedContentType(type)}
                  />
                )}
                label={title}
              />
            ))
          }

        </StyledTreeItem>
      </TreeView>
    </>
  );
};

export default React.memo(DesignEditorContentAreaMasterElementAttributesSetting);
