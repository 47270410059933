import React, { FC, useContext, useMemo } from 'react';
import DesignEditorInstanceContext from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';
import { useSelector } from 'react-redux';
import { faLayerGroup, faMousePointer, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { SelectedLayerInfoContainer, SelectedLayerInfoIcon } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorTransformDocker/style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIntl } from 'react-intl';

const DesignEditorSelectedLayersInfo: FC<any> = () => {
  const { actions, selectors, designId } = useContext(DesignEditorInstanceContext);

  const selectedLayers = useSelector(selectors.selectSelectedLayers);
  const intl = useIntl();

  const selectedObjectInfo = useMemo(() => {
    if (selectedLayers.length === 0) {
      return { icon: faMousePointer, desc: 'Nothing selected' };
    }
    if (selectedLayers.length === 1) {
      const selectedLayer = selectedLayers[0];
      return {
        icon: faStickyNote,
        desc: `${selectedLayer.__typename}: ${(selectedLayer as any)?.keyName ?? selectedLayer.id}`,
      };
    }
    return {
      icon: faLayerGroup,
      desc: intl.formatMessage({
        id: 'event.design.tool.transform.selected_layers',
        defaultMessage: 'Selected {amount} layers',
      }, { amount: selectedLayers.length }),
    };
  }, [intl, selectedLayers]);

  return (
    <SelectedLayerInfoContainer>
      <SelectedLayerInfoIcon>
        <FontAwesomeIcon icon={selectedObjectInfo.icon} size="lg" />
      </SelectedLayerInfoIcon>
      <div>
        {selectedObjectInfo.desc}
      </div>
    </SelectedLayerInfoContainer>
  );
};

export default React.memo(DesignEditorSelectedLayersInfo);
