import { LocalizableMap } from 'admin-data/event/EventEdit/redux/eventSettingEditState';

export interface AuditoriumAsset {
  id: string;
  autoPlay?: boolean;
  chatroomId?: string;
  chatroomName?: string;
  desktopOrder?: number;
  mobileOrder?: number;
  redirectExternal?: boolean,
  redirectUrl?: LocalizableMap<string>;
  src?: LocalizableMap<string>;
  type: 'IMAGE' | 'VIDEO' | 'ZENDESK' | 'LINK' | 'IFRAME';
  videoPreviewSrc?: LocalizableMap<string>;
}

export enum AuditoriumAssetsContainerPosition {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export interface PositionalAssetData {
  aspectX: number;
  aspectY: number;
  sliderDuration: number;
  assetIds?: string[];
  assets?: AuditoriumAsset[];
}

export interface Auditorium {
  id: string;
  webinarId: string | null;
  assets: Record<string, PositionalAssetData>;
  backgroundImage: LocalizableMap<string>;
  isDefault: boolean;
  name: string;
}
