/* eslint-disable camelcase */
import { EntityState } from '@reduxjs/toolkit';

export interface LocalizableMap<T> {
  [locale: string]: T;
}

export enum EnabledComponentState {
  SHOW = 'SHOW',
  REMOVE = 'REMOVE',
  HIDE = 'HIDE'
}

/** Alias for HTML Color Code, will be a hex code or any valid attribute for `color` in CSS */
type HTMLColorCode = string;

export interface LandingPage {
  backgroundImgSrc: string;
  backgroundVideoSrc?: string;
  bottomButtonColor?: HTMLColorCode;
  buttonTexts: {
    register: LocalizableMap<string>;
    registered: LocalizableMap<string>;
  };
  dateFormat: string;
  enabledComponents: {
    countdown: EnabledComponentState;
    eventTitle: EnabledComponentState;
    eventDate: EnabledComponentState;
    eventTime: EnabledComponentState;
    registerButton: EnabledComponentState;
    registeredButton: EnabledComponentState;
  };
  postEventMessage: LocalizableMap<string>;
  fullWidth: boolean;
  header: {
    alt: string;
    imageSrc: string;
    maxWidth: string;
    width: string;
  };
  redirectTo?: LocalizableMap<string>;
  richTextField?: LocalizableMap<string>;
  richTextFields?: string[];
  regLink: string;
  opacity: number;
  overrideBG?: boolean;
  mobileBreakpoint?: number;
  backgroundMobileImgSrc?: string;
  themeColor: HTMLColorCode;
  timeFormat: string;
  timeZone: string;
  topButtonColor?: string;
  zoneAbbr: string;
}

export interface HallData {
  hallBackground: LocalizableMap<string>;
  hallName: string;
  redirectUrl: LocalizableMap<string>;
  label: LocalizableMap<string>;
}

export interface HallPage {
  backgroundImgSrc?: string;
  backgroundVideoSrc?: string;
  simpleBoothPreview?: boolean;
  popOver?: {
    chat: LocalizableMap<string>;
    boothGreeting: LocalizableMap<string>;
    mailTo: LocalizableMap<string>;
    meetup: LocalizableMap<string>;
    videoCall: LocalizableMap<string>;
  };
  multipleHall?: HallData[];
}

export interface GeneralSetting {
  ogTitle?: string,
  ogImageUrl?: string,
  ogDescription?: string,
  googleTagId?: string,
  zendeskId?: string,
}

export interface LobbyData {
  videoSrc: string;
  videoPreviewSrc: string;
}

export enum MediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export interface Lobby {
  videoSrc?: string;
  videoPreviewSrc?: string;
  mobileHallConfig?: MobileLobbySetting;
  mobileWebinarConfig?: MobileLobbySetting;
  mobileParticipantConfig?: MobileLobbySetting;
  mobileProductPageConfig?: MobileLobbySetting;
  mobileSpeakersPageConfig?: MobileLobbySetting;
  backgroundType?: MediaType;
  templateId?: string;
}

export interface Appbar {
  itemOrder: Record<string, number>;
}

export interface MobileLobbySetting {
  backgroundImage: LocalizableMap<string>;
  showLink: boolean;
  showText: boolean;
}

export interface Products {
  hideProductFilter?: boolean;
  disableListChat?: boolean;
  disableDetailMoreProducts?: boolean;
  disableDetailMeetup?: boolean;
  disableDetailBoothLink?: boolean;
  disableDetailChat?: boolean;
  sortKey?: string;
  showProductsFromUnpublishedBooths?: boolean;
}

export interface MobileLobbySettingData {
  backgroundImage: string;
  showLink: boolean;
  showText: boolean;
}

export interface PageNames {
  [pageKey: string]: LocalizableMap<string>;
}

export interface Logo {
  height: number;
  src: LocalizableMap<string>;
  redirectUrl: LocalizableMap<string>;
  redirectExternal: boolean;
}

export interface LiveStreamSetting {
  boothIdLiveStreamPeriodsMap: Record<string, { startTime: string; endTime: string }[]>
}

export interface EventSettings {
  themeColor: HTMLColorCode;
  landing: LandingPage;
  registrationStartTime: string;
  registrationEndTime: string;
  hall: HallPage;
  lobby: Lobby;
  generalSetting: GeneralSetting;
  chatroomConfig: ChatroomConfig;
  featuresToggle: FeaturesToggle;
  bannerAssets: EntityState<BannerAsset>;
  bannerAssetContainers: EntityState<BannerContainer>;
  lobbyTemplate: EntityState<LobbyTemplate>;
  footer: Footer;
  appBarConfig: Appbar;
  customPage: CustomPageGroup[];
  products: Products;
  name: LocalizableMap<string>;
  startTime: string;
  endTime: string;
  availableLocales: string[];
  /** @deprecated */
  features: string[];
  pageNames: PageNames;
  maxCustomTabs: number;
  logos: Logo[];
  liveStream: LiveStreamSetting;
  fetchingState: {
    landing: boolean;
    hall: boolean;
    lobby: boolean;
    generalSetting: boolean;
    chatroomConfig: boolean;
    featuresToggle: boolean;
    footer: boolean;
    appBarConfig: boolean;
    products: boolean;
    bannerAssets: boolean;
    lobbyTemplates: boolean;
    customPage: boolean;
    pageNames: boolean;
    logos: boolean;
  }
}

export interface ChatroomConfig {
  isBlockingOtherExhibitors?: boolean;
  isAnnouncementDisabled?: boolean;
  isAutoJoinMsgDisabled?: boolean;
  isBoothChatroomDisabled?: boolean;
  isChatroomDisabled?: boolean;
  isFileUploadDisabled?: boolean;
  isGroupVideoCallDisabled?: boolean;
  isContactCardDisabled?: boolean;
  isPersonalVideoCallDisabled?: boolean;
  isGroupMemberListDisabled?: boolean;
  hideChatHistoryDateTime?: string;
  isSendCVEnabled?: boolean;
  publicChatrooms?: PublicChatroom[];
  enableChatroomPeriods: ChatroomPeriods[];
  enableGroupVideoCallPeriods: ChatroomPeriods[];
}

export interface ChatroomPeriods {
  to?: string;
  from?: string;
}

export interface PublicChatroom {
  id: string;
  label: LocalizableMap<string>;
}

export interface FeaturesToggle {
  productPage?: boolean;
  removeFooter?: boolean;
  bookmarks?: boolean;
  boothPreview?: boolean;
  singleUserSession?: boolean;
  cmsBanner?: boolean;
  hideParticipantPage?: boolean;
  hideAttendees?: boolean;
  groupVideoCall?: boolean;
  hideHall?: boolean;
  hideWebinar?: boolean;
  useCachedCMSData?: boolean;
  multipleCustomPages?: boolean;
  showSelectedLocaleOnTopBar?: boolean;
  multipleHall?: boolean;
  hideWebinarTimeLine?: boolean;
  speakersPage?: boolean;
  allowWebinarOverrun?: boolean;
  boothTiers?: boolean;
  publicChatroom?: boolean;
  internalQA?: boolean;
  hideExhibitorCMSRepresentativesFragment?: boolean;
  hideExhibitorChangeBoothTemplateButton?: boolean;
  showExhibitorReport?: boolean;
  showExhibitorDashboard?: boolean;
  hideWebinarTracks?: boolean;
  auditoriumWebinar?: boolean;
  exhibitorZendeskSuppport?: boolean;
  liveStream?: boolean;
  boothVideoAutoplay?: boolean;
  boothTabVideoAutoplay?: boolean;
  networkingLounge?: boolean;
  webinarSlido?: boolean;
}

export interface Footer {
  header?: string;
  backgroundColor?: string;
  textColor?: string;
  logos?: LocalizableMap<FooterLogo[]>;
}

export interface FooterLogo {
  src?: string;
  alt?: string;
  width?: string;
}

export enum CustomPageAction {
  'Open in New Tab' = 'NEWTAB',
  'Open Within Site' = 'INLINE'
}

export enum CustomPageKind {
  Website = 'WEBSITE',
  HTML = 'HTML'
}

export enum CustomPageDisplay {
  'Dropdown' = 'DROPDOWN',
  'Top Bar' = 'TOPBAR'
}

export interface CustomPage {
  action: {
    desktop: CustomPageAction;
    mobile: CustomPageAction;
  };
  customHtml: LocalizableMap<string> | null;
  kind: CustomPageKind;
  name: string;
  pageTitle: LocalizableMap<string>;
  path: LocalizableMap<string>;
  src: LocalizableMap<string> | null;
  buttonName: string;
  disableWebTitlePattern: boolean;
  removeFooter: boolean;
}

export interface CustomPageGroup {
  display: CustomPageDisplay;
  name: string;
  pages: CustomPage[];
  title: LocalizableMap<string>;
}

export enum BannerAssetType {
  IFRAME = 'IFRAME',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  LINK = 'LINK',
  ZENDESK = 'ZENDESK',
  EMPTY = 'NONE',
}

export interface BannerAsset {
  id: string;
  desktopOrder: number;
  mobileOrder: number;
  redirectExternal: boolean;
  chatroomName: string;
  chatroomId: string;
  autoPlay: boolean;
  type: BannerAssetType;
  redirectUrl: LocalizableMap<string>;
  videoPreviewSrc: LocalizableMap<string>;
  src: LocalizableMap<string>;
}

export interface BannerContainerTemplate {
  id: string;
  bottom: number;
  isTv: boolean;
  tvType: number;
  height: number;
  left: number;
  right: number;
  skewX: number;
  skewY: number;
  top: number;
  width: number;
  rotateY: number;
  name: string;
  translateX: number;
  translateY: number;
}

export type BannerContainerResponse = BannerContainerTemplate & { bannerAsset: BannerAsset[]; };
export type BannerContainer = BannerContainerTemplate & { bannerAsset: string[]; };

export interface LobbyAPIDeletePayload {
  banner_assets_id: string;
  banner_asset_id: string;
}

export interface LobbyAPIPostPayload {
  banner_assets_id: string;
  desktopOrder: number;
  mobileOrder: number;
  redirectExternal: boolean;
  chatroomName: string;
  chatroomId: string;
  autoPlay: boolean;
  type: BannerAssetType;
  redirectUrl: LocalizableMap<string>;
  videoPreviewSrc: LocalizableMap<string>;
  src: LocalizableMap<string>;
  callback: () => void;
}

export interface LobbyAPIPutPayload {
  eventDomain?: string;
  bannerAssetsId: string;
  bannerAssetId: string;
  updateItem: BannerAsset;
  callback: () => void;
}

export interface CreateTemplatePayload {
  templateId: string;
  onCompleteCallback: (containerId: string) => void;
}

export interface AssetSortPayload {
  sourceIndex: number;
  destIndex: number;
}

export type DesktopAssetSortPayload = AssetSortPayload & { containerId: string; };

export interface SortResponsePayload {
  id: string;
  sort: number;
}

export interface BannerTemplate {
  bottom: number;
  isTv: boolean;
  tvType: number;
  height: number;
  left: number;
  right: number;
  skewX: number;
  skewY: number;
  top: number;
  width: number;
  rotateY: number;
  name: string;
  translateX: number;
  translateY: number;
}

export interface LobbyTemplate {
  id: string;
  background: string;
  name: string;
  bannerTemplates: BannerContainer[];
  published: boolean;
}

export interface CreateTemplatePayload {
  templateId: string;
  onCompleteCallback: (containerId: string) => void;
}

export const eventEditLandingFragmentInitialState: LandingPage = {
  backgroundImgSrc: null,
  buttonTexts: {
    register: {},
    registered: {},
  },
  dateFormat: null,
  enabledComponents: {
    countdown: EnabledComponentState.SHOW,
    eventTitle: EnabledComponentState.SHOW,
    eventDate: EnabledComponentState.SHOW,
    eventTime: EnabledComponentState.SHOW,
    registerButton: EnabledComponentState.SHOW,
    registeredButton: EnabledComponentState.SHOW,
  },
  postEventMessage: null,
  fullWidth: false,
  header: {
    alt: null,
    imageSrc: null,
    maxWidth: null,
    width: null,
  },
  regLink: null,
  opacity: null,
  themeColor: null,
  timeFormat: null,
  timeZone: null,
  zoneAbbr: null,
};

export const eventEditHallFragmentInitialState: HallPage = {};

export const eventEditLobbyFragmentInitialState: Lobby = {};

export const eventEditGeneralSettingFragmentInitialState: GeneralSetting = {};

export const eventEditChatroomFragmentInitialState: ChatroomConfig = {
  enableChatroomPeriods: [],
  enableGroupVideoCallPeriods: [],
};

export const eventEditFooterFragmentInitialState: Footer = {};

export const eventEditAppbarFragmentInitialState: Appbar = {
  itemOrder: {},
};

export const eventEditProductsFragmentInitialState: Products = {};

export const eventEditFeaturesFragmentInitialState: FeaturesToggle = {
  productPage: false,
  removeFooter: false,
  bookmarks: false,
  boothPreview: false,
  singleUserSession: false,
  cmsBanner: false,
  hideParticipantPage: false,
  hideAttendees: false,
  groupVideoCall: false,
  hideHall: false,
  hideWebinar: false,
  useCachedCMSData: false,
  multipleCustomPages: false,
  showSelectedLocaleOnTopBar: false,
  multipleHall: false,
  speakersPage: false,
  allowWebinarOverrun: false,
  boothTiers: false,
  publicChatroom: false,
  internalQA: false,
  showExhibitorReport: false,
  showExhibitorDashboard: false,
  hideWebinarTracks: false,
  auditoriumWebinar: false,
  exhibitorZendeskSuppport: false,
  boothVideoAutoplay: true,
  boothTabVideoAutoplay: true,
};

export const eventEditCustomPageFragmentInitialState: CustomPageGroup[] = [];

export const eventEditPageNamesFragmentInitialState: PageNames = {};

export const eventEditLogosFragmentInitialState: Logo[] = [];
