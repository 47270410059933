import React, { FC, useContext } from 'react';
import { DockerCommonInnerContent } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/style';
import { DimensionFieldsRow } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorTransformDocker/style';
import DesignEditorTransformDimensionField
  from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorTransformDocker/DesignEditorTransformDimensionField';

const DesignEditorTransformDimensions: FC<any> = () => (
  <DockerCommonInnerContent>
    <DimensionFieldsRow>
      <DesignEditorTransformDimensionField position="a" dimension="x" label="x1" />
      <DesignEditorTransformDimensionField position="a" dimension="y" label="y1" />
    </DimensionFieldsRow>
    <DimensionFieldsRow>
      <DesignEditorTransformDimensionField position="b" dimension="x" label="x2" />
      <DesignEditorTransformDimensionField position="b" dimension="y" label="y2" />
    </DimensionFieldsRow>
  </DockerCommonInnerContent>
);

export default React.memo(DesignEditorTransformDimensions);
