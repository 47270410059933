import { useCallback, useContext, useMemo } from 'react';
import { ContentLocalizationContext } from 'components/localization/ContentLocalizationContext';

interface Localizable<T> {
  values?: { value?: T, locale: string }[]
}

/**
 * Get the locale value index in the localizable
 * Make sure .values is not null and the locale is exist
 * @param localizable
 * @param throwIfEmpty
 */
const useValueLocaleIndex = <T extends any>(localizable: Localizable<T>, throwIfEmpty = true) => {
  const { locale } = useContext(ContentLocalizationContext);

  return useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    if (!(localizable as any).__typename?.startsWith('Localizable')) throw new Error('Not localizable value or missing __typename, are you forget to fetch it?');
    if (localizable.values == null) throw new Error('localizable values is null, are you forget to patch the default values?');

    const found = localizable.values.findIndex((entry) => entry.locale === locale);
    if (found === -1) {
      if (throwIfEmpty) throw new Error(`values do not have locale:${locale}, are you forget to patch the default value?`);
    }
    return found;
  }, [locale, localizable, throwIfEmpty]);
};

export const useValueLocaleIndexedReducer = <T extends any>(localizable: Localizable<T>, localeIndex) => useCallback((newValue: T) => {
  const cloned = [...localizable.values];
  cloned[localeIndex] = { ...cloned[localeIndex], value: newValue };
  return {
    ...localizable,
    values: cloned,
  };
}, [localeIndex, localizable]);

export default useValueLocaleIndex;
