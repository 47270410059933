import { Epic } from 'redux-observable';
import { filter, mergeMap, map } from 'rxjs/operators';
import eventSettingEditActions from 'admin-data/event/EventEdit/redux/eventSettingEditActions';
import { concat, defer, of } from 'rxjs';
import { Logo } from 'admin-data/event/EventEdit/redux/eventSettingEditState';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import { EVENT_FIRESTORE_API } from 'config';

const fetchEventLogoEpic: Epic = (action$, state$) => action$.pipe(
  filter(eventSettingEditActions.fetchLogos.match),
  mergeMap(() => {
    const { event: { domain } } = eventEditSelectors.selectEventEdit(state$.value);
    const fetchUrl = `${EVENT_FIRESTORE_API}?event_domain=${domain}`;
    console.log(fetchUrl);
    const fetchLogos = defer(() => fetch(fetchUrl))
      .pipe(
        mergeMap((resp) => resp.json() as Promise<{ logos: Logo[] }>),
        map(({ logos }) => eventSettingEditActions.setLogos(logos)),
      );
    return concat(
      of(eventSettingEditActions.logosLoading(true)),
      fetchLogos,
      of(eventSettingEditActions.logosLoading(false)),
    );
  }),
);

export default fetchEventLogoEpic;
