import React, {
  FC, MouseEvent, useCallback, useContext, useState,
} from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import DesignEditorInstanceContext from 'libs/xtra-custom-booth-design/components/DesignEditor/DesignEditorInstanceContext';
import { StyledLayersList } from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayersDocker/style';
import DesignEditorLayersListItem from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/DesignEditorLayersDocker/DesignEditorLayersListItem';
import DesignEditorScrollbarThumb from 'libs/xtra-custom-booth-design/components/shared/DesignEditorScrollbarThumb';
import { selectorArrayItemEqFn } from 'utils/selectorUtils';

export interface DesignEditorLayerInfo {
  id: string;
  name: string;
}

interface DesignEditorLayersListProps {
  layersInfo: DesignEditorLayerInfo[];
}

const DesignEditorLayersList: FC<DesignEditorLayersListProps> = ({ layersInfo }) => {
  const dispatch = useDispatch();
  const { actions, selectors, designId } = useContext(DesignEditorInstanceContext);
  const selectedLayerIds = useSelector(selectors.selectSelectedLayersId, selectorArrayItemEqFn);

  const onDragEnd = (result: DropResult) => {
    if (result.destination != null) dispatch(actions.reorderLayer({ source: result.source.index, dest: result.destination.index }));
  };

  const [lastNonShiftSelectedLayersId, setLastNonShiftSelectedLayersId] = useState<string>(null);
  const [lastShiftAddSelectedLayerIds, setLastShiftAddSelectedLayerIds] = useState<string[]>(null);

  const resetLastNonShift = useCallback((nonShiftSelectedLayerId: string) => {
    setLastNonShiftSelectedLayersId(nonShiftSelectedLayerId);
    setLastShiftAddSelectedLayerIds(null);
  }, []);

  const onClickLayer = useCallback((e: MouseEvent, clickedLayerId: string) => {
    if (e.button !== 0) return;
    const isClickedOnSelectedLayer = selectedLayerIds.indexOf(clickedLayerId) !== -1;
    if (e.shiftKey) {
      const selectFromLayerId = lastNonShiftSelectedLayersId || selectedLayerIds[0];
      if (selectFromLayerId === clickedLayerId) return;
      const selectFromLayerPosition = selectFromLayerId == null ? null : layersInfo.findIndex((layer) => layer.id === selectFromLayerId);
      const selectToLayerPosition = layersInfo.findIndex((layer) => layer.id === clickedLayerId);
      if (selectFromLayerPosition != null) {
        const selectingLayers = selectFromLayerPosition < selectToLayerPosition
          ? layersInfo.slice(selectFromLayerPosition, selectToLayerPosition + 1)
          : layersInfo.slice(selectToLayerPosition, selectFromLayerPosition + 1);
        const addingSelectedLayerIds = selectingLayers.map((layer) => layer.id)
          .filter((selectingLayerId) => !selectedLayerIds.some((selectedLayerId) => selectingLayerId === selectedLayerId));

        let newSelectedLayersId = [...selectedLayerIds];
        if (lastShiftAddSelectedLayerIds != null) {
          const deselectingLayers = lastShiftAddSelectedLayerIds.filter((lastAdded) => !addingSelectedLayerIds.some((addingLayer) => lastAdded === addingLayer));
          newSelectedLayersId = newSelectedLayersId.filter((selected) => !deselectingLayers.some((deselect) => deselect !== selected));
        }
        newSelectedLayersId = [...newSelectedLayersId, ...addingSelectedLayerIds];
        dispatch(actions.updateSelectedLayerIds([...newSelectedLayersId]));
      } else {
        resetLastNonShift(clickedLayerId);
        if (!isClickedOnSelectedLayer) {
          dispatch(actions.updateSelectedLayerIds([...selectedLayerIds, clickedLayerId]));
        }
      }
    } else if (e.ctrlKey || e.metaKey) {
      resetLastNonShift(clickedLayerId);
      dispatch(isClickedOnSelectedLayer
        ? actions.updateSelectedLayerIds(selectedLayerIds.filter((selected) => selected !== clickedLayerId))
        : actions.updateSelectedLayerIds([...selectedLayerIds, clickedLayerId]));
    } else {
      resetLastNonShift(clickedLayerId);
      dispatch(actions.updateSelectedLayerIds([clickedLayerId]));
    }
  }, [actions, dispatch, lastNonShiftSelectedLayersId, lastShiftAddSelectedLayerIds, layersInfo, resetLastNonShift, selectedLayerIds]);

  const onContextMenu = useCallback((e) => e.preventDefault(), []);

  return (
    <Scrollbars style={{ height: 400 }} renderThumbVertical={DesignEditorScrollbarThumb}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <StyledLayersList
              {...provided.droppableProps}
              ref={provided.innerRef}
              dense
              onContextMenu={onContextMenu}
            >
              {layersInfo.map((layer, index) => (
                <DesignEditorLayersListItem layer={layer} index={index} onClickLayer={onClickLayer} />
              ))}
              {provided.placeholder}
            </StyledLayersList>
          )}
        </Droppable>
      </DragDropContext>
    </Scrollbars>
  );
};

export default React.memo(DesignEditorLayersList, (prev, next) => (
  prev.children === next.children
  && JSON.stringify(prev.layersInfo) === JSON.stringify(next.layersInfo)
));
