import { HintType } from 'utils/xtra-hints/hintType';
import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

const hintEventEditBoothDesignTransformReorderElement: HintType<'hintEventEditBoothDesignTransformReorderElement'> = {
  key: 'hintEventEditBoothDesignTransformReorderElement',
  contentsFactory: () => [
    () => (
      <>
        <FormattedMessage
          id="event.booth_design.hints.transform_element_0"
          defaultMessage="You have just created an element!"
        />
        <br />
        <FormattedMessage
          id="event.booth_design.hints.transform_element_1"
          defaultMessage="The element support transformation by using drag and drop. To enter the transform mode, select a layer from the layers list, and then:"
        />
        <ul>
          <li>
            <FormattedMessage
              id="event.booth_design.hints.transform_element_resize"
              defaultMessage="Resize: Drag the control points"
            />
          </li>
          <li>
            <FormattedMessage
              id="event.booth_design.hints.transform_element_move"
              defaultMessage="Move: Drag the rectangle"
            />
          </li>
        </ul>
        <img
          src="https://cms-virtual-assets.eventxtra.com/hints/event_edit_booth_design_transform_element.gif"
          alt="transform element"
        />
      </>
    ),
    () => (
      <>
        <FormattedMessage
          id="event.booth_design.hints.reorder_element_0"
          defaultMessage="Different elements ordering will have different visual result, you can reorder the elements by drag and drop in the layers list."
        />
        <img
          src="https://cms-virtual-assets.eventxtra.com/hints/event_edit_booth_design_reorder_element.gif"
          alt="reorder element"
        />
      </>
    ),
  ],
};

export default hintEventEditBoothDesignTransformReorderElement;
