import { concat, defer, of } from 'rxjs';
import { filter, first, ignoreElements } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { Booth, Event } from 'models';
import produce from 'immer';
import _ from 'lodash';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import eventEditSelectors from '../eventEditSelectors';

export const mapToSimpleMergeEventAction = (eventFactory: () => Event) => ({ node }) => eventEditActions.internalUpdateEvent(produce(eventFactory(), ((draft) => _.merge(draft, node as Event))));

export const eventEditMutationCompleteObservable = (action$: ActionsObservable<any>, state$: StateObservable<any>) => defer(() => concat(of(state$.value), state$).pipe(
  filter((state) => !eventEditSelectors.executingTasks.selectHasExecutingTasksByTag(ExecutingTaskCommonTag.Mutation)(state)),
  first(),
  ignoreElements(),
));
