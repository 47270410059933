import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import { createPopupsActions, PopupsActions } from 'utils/xtra-popups/createPopupsActions';
import { CreatePopupsSelectors } from 'utils/xtra-popups/createPopupsSelectors';
import { EMPTY, of } from 'rxjs';
import produce from 'immer';
import { BulkTasksProgressDialogData } from 'utils/xtra-popups/popupsTypes/bulkTasksProgressDialogData';
import { Popup } from 'utils/xtra-popups/popup';

const bulkTasksCancelEpicFactory: (actions: PopupsActions, selectors: CreatePopupsSelectors) => Epic = (actions, selectors) => (action$, state$) => action$.pipe(
  filter(actions.bulkTasks.cancel.match),
  mergeMap(({ payload: popupId }) => {
    const dialog = selectors.selectPopupsIdMap(state$.value)[popupId] as Popup<BulkTasksProgressDialogData>;
    if (dialog != null) {
      return of(actions.upsertPopup(produce(dialog, (draft) => {
        draft.data.cancelled = true;
      })));
    }
    return EMPTY;
  }),
);

export default bulkTasksCancelEpicFactory;
