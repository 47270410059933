import { createSelector } from 'reselect/src';
import { DesignEditorState } from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorState';
import { DESIGN_EDITOR_STORE_NAME } from 'libs/xtra-custom-booth-design/redux/designEditorVars';
import { DesignEditorDockerFrameInfo } from 'libs/xtra-custom-booth-design/types/DesignEditorDockerInfo';
import dockersMap from 'libs/xtra-custom-booth-design/components/DesignEditorInstance/docker-tools/dockersMap';

const selectDesignEditor = (state) => (state[DESIGN_EDITOR_STORE_NAME] as DesignEditorState);
const selectDesignEditorDesignsList = createSelector(selectDesignEditor, (editor) => editor.boothDesignsList);
const selectDesignInstancesIdMap = createSelector(selectDesignEditor, (editor) => editor.instances);
const selectDesignInstancesIds = createSelector(selectDesignInstancesIdMap, (idMap) => Object.keys(idMap));
const selectDesignInstancesIdNameList = createSelector(selectDesignInstancesIdMap, (idMap) => Object.keys(idMap).map((id) => ({
  id,
  name: idMap[id].design?.name?.values[0]?.value ?? id,
})));
const selectDesignInstances = createSelector(selectDesignInstancesIdMap, (idMap) => Object.values(idMap));
const selectDesignInstanceByDesignId = (designId: string) => createSelector(selectDesignInstancesIdMap, (idMap) => idMap[designId]);
const selectActiveDesignId = createSelector(selectDesignEditor, (editor) => editor.activeInstanceDesignId ?? null);
const selectKeyMap = createSelector(selectDesignEditor, (editor) => editor.keyMap);
const selectDockerFrames = createSelector(selectDesignEditor, (editor) => editor.dockersFrames);
const selectDockerFrameById = (frameId: string) => createSelector(selectDockerFrames, (layout) => layout[frameId] as DesignEditorDockerFrameInfo);
const selectDockerFrameDockersById = (frameId: string) => createSelector(selectDockerFrameById(frameId),
  (frame) => frame.dockerIds.map((dockerId) => dockersMap[dockerId]));
const selectSelectedCanvasTool = createSelector(selectDesignEditor, (editor) => editor.selectedCanvasTool);

const designEditorSelectors = {
  selectDesignEditor,
  selectDesignInstancesIdMap,
  selectDesignInstancesIdNameList,
  selectDesignInstances,
  selectDesignEditorDesignsList,
  selectDesignInstanceByDesignId,
  selectActiveDesignId,
  selectKeyMap,
  selectDesignInstancesIds,
  selectDockerFrames,
  selectDockerFrameById,
  selectDockerFrameDockersById,
  selectSelectedCanvasTool,
};

export type DesignEditorSelectors = typeof designEditorSelectors;

export default designEditorSelectors;
