import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';
import designEditorActions from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorActions';
import designEditorSelectors from 'libs/xtra-custom-booth-design/redux/design-editor/designEditorSelectors';
import { concat, EMPTY, of } from 'rxjs';
import { catchErrorToPopup, throwCommonError } from 'redux/mutationFailedPipe';
import eventEditActions from 'admin-data/event/EventEdit/redux/eventEditActions';
import { v4 as uuidv4 } from 'uuid';
import { ExecutingTask } from 'utils/xtra-executing-tasks';
import eventEditSelectors from 'admin-data/event/EventEdit/redux/eventEditSelectors';
import cmsSelectors from 'redux/cmsSelectors';

const deleteDesignEpic: Epic = (action$, state$) => action$.pipe(
  filter(designEditorActions.deleteDesign.match),
  mergeMap(({ payload: boothDesignId }) => {
    const eventId = eventEditSelectors.selectEvent(state$.value).id;

    const sdk = cmsSelectors.selectAuthorizedSDKObservables(state$.value);

    const request = sdk.boothDesignDelete({ input: { eventId, boothDesignId } }).pipe(
      throwCommonError(),
      mergeMap(() => {
        const isOpened = designEditorSelectors.selectDesignInstanceByDesignId(boothDesignId)(state$.value) != null;
        return isOpened ? of(designEditorActions.closeDesign(boothDesignId)) : EMPTY;
      }),
      catchErrorToPopup(state$.value.intl),
    );

    const task: ExecutingTask = {
      id: uuidv4(),
      name: 'Delete booth design',
      progress: -1,
    };

    return concat(
      of(eventEditActions.executingTasks.upsertExecutingTasks(task)),
      request,
      of(eventEditActions.executingTasks.removeExecutingTasks(task.id)),
      of(designEditorActions.loadDesignsList()),
    );
  }),
);

export default deleteDesignEpic;
