import { tryOrNull } from 'libs/xtra-custom-booth-design/utils/fp';

export interface ShowingHintState {
  key: string
  page: number
  data: any
}

export interface HintsState {
  showingHint?: ShowingHintState;
  hideAllHints: boolean;
  hideTemporaryHintKeys: { [key: string]: true };
}

export const hintsInitialStateFactory: (localStoragePrefix: string) => HintsState = (localStoragePrefix) => ({
  showingHint: null,
  hideAllHints: localStorage.getItem(`${localStoragePrefix}/hideAllHints`) === 'true',
  hideTemporaryHintKeys: {},
});
