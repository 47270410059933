import axios from 'axios';
import { defer, merge, of } from 'rxjs';
import { EVENT_EDIT_ACTIONS_NAME } from 'admin-data/event/EventEdit/redux/eventEditVars';
import { createStandardEventEditRequestBundle } from 'admin-data/event/EventEdit/redux/createStandardEventEditRequestBundle';
import { mergeMap } from 'rxjs/operators';
import { FIRESTORE_ENDPOINT } from 'config';
import { AuditoriumAsset } from 'extra-models/auditorium';
import { ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';
import { eventEditMutationCompleteObservable } from 'admin-data/event/EventEdit/redux/epic/utils';
import _ from 'lodash';

interface AuditoriumAssetReorderActionPayload {
  assetId: string
  isMobileOrder: boolean;
  destination: {
    rowOrderSort: number,
  }
}

const bundleEventWebinarAuditoriumAssetReorder = createStandardEventEditRequestBundle<AuditoriumAssetReorderActionPayload, any>({
  actionName: `${EVENT_EDIT_ACTIONS_NAME}/auditorium/asset/bundleEventAuditoriumAssetReorder`,
  additionalTaskTags: [ExecutingTaskCommonTag.Mutation],
  preConditionObservableFactory: eventEditMutationCompleteObservable,
  isSequenced: true,

  preRequestActionsObservableFactory: ({
    payload, createUpdateEventEditAction,
  }) => of(createUpdateEventEditAction((eventEdit) => {
    const { auditoriumEdit } = eventEdit.fragments.auditorium;
    const container = auditoriumEdit.editingAuditorium.assets[eventEdit.fragments.auditorium.auditoriumEdit.selectedContainerKey];
    const simulateSortedAssets = _.sortBy(container.assets, (asset:AuditoriumAsset) => (payload.isMobileOrder ? asset.mobileOrder : asset.desktopOrder));

    const fromIndex = simulateSortedAssets.findIndex((asset) => asset.id === payload.assetId);
    const toIndex = payload.destination.rowOrderSort;
    const movingAsset = simulateSortedAssets.splice(fromIndex, 1)[0];
    simulateSortedAssets.splice(toIndex, 0, movingAsset);

    simulateSortedAssets.forEach((asset, index) => {
      if (payload.isMobileOrder) {
        asset.mobileOrder = index;
      } else {
        asset.desktopOrder = index;
      }
    });
  })),

  requestFactory: ({
    eventDomain, eventEditFactory, payload,
  }) => defer(() => {
    const eventEdit = eventEditFactory();
    const { auditoriumEdit } = eventEdit.fragments.auditorium;
    const container = auditoriumEdit.editingAuditorium.assets[eventEdit.fragments.auditorium.auditoriumEdit.selectedContainerKey];
    return merge(container.assets.map((asset) => ({ asset }))).pipe(
      mergeMap(({ asset }) => axios.patch(
        `${FIRESTORE_ENDPOINT}/events/${eventDomain}/auditoriums/asset/${asset.id}`,
        (payload.isMobileOrder ? { mobileOrder: asset.mobileOrder } : { desktopOrder: asset.desktopOrder }) as Partial<AuditoriumAsset>,
      )),
    );
  }),
});

export default bundleEventWebinarAuditoriumAssetReorder;
