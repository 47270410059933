import { Booth } from 'models';
import { boothEditActions } from 'admin-data/booth/BoothEdit/redux/boothEditActions';
import _ from 'lodash';
import produce from 'immer';
import { concat, defer, of } from 'rxjs';
import { filter, first, ignoreElements } from 'rxjs/operators';
import { ActionsObservable, StateObservable } from 'redux-observable';
import boothEditSelectors from 'admin-data/booth/BoothEdit/redux/boothEditSelectors';
import { ExecutingTaskCommonTag } from 'utils/xtra-executing-tasks/executingTask';

export const mapToSimpleMergeBoothAction = (boothFactory: () => Booth) => ({ node }) => boothEditActions.internalUpsertBooth(produce(boothFactory(), ((draft) => _.merge(draft, node as Booth))));

export const localizableFieldDefaultLocalePatcher = (eventLocales: string[], object: any) => {
  if (object?.__typename?.startsWith('Localizable')) {
    const existingLocales = (object.values as Array<{ locale: string, value: any }>).map(({ locale }) => locale);
    const missingLocales = eventLocales.filter((neededLocale) => existingLocales.indexOf(neededLocale) === -1);
    missingLocales.forEach((locale) => {
      object.values[object.values.size] = { locale };
    });
  }
  if (object != null && typeof object === 'object') Object.values(object).forEach((propValue) => localizableFieldDefaultLocalePatcher(eventLocales, propValue));
};

export const boothEditMutationCompleteObservable = (action$: ActionsObservable<any>, state$: StateObservable<any>) => defer(() => concat(of(state$.value), state$).pipe(
  filter((state) => !boothEditSelectors.executingTasks.selectHasExecutingTasksByTag(ExecutingTaskCommonTag.Mutation)(state)),
  first(),
  ignoreElements(),
));
